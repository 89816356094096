import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ArticleESDto } from '../models/entities/article.dto';
import { FrontpageManufacturerDto } from '../models/entities/frontpage-manufacturer.dto';
import { CommonContentWebBundleShared } from '../models/shared/common-content.shared';
import { ProductDto } from '../models/entities/product.dto';
import { BannerDto } from '../models/entities/banner.dto';
import { SolutionESDto } from '../models/entities/solution.dto';
import { HomepageDto } from '../models/entities/homepage.dto';

export enum HomepageWebEndpointPaths {
    data = '/api/v1/web/homepage/data',
}

export interface HomepageWebEndpoint {
    data(): Promise<HomepageDataResponse> | Observable<HomepageDataResponse>;
}

export class HomepageDataResponse {
    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    aboutCompany: string;

    @ApiModelProperty({
        type: ArticleESDto,
        isArray: true,
    })
    articles: Array<ArticleESDto>;

    @ApiModelProperty({
        type: ArticleESDto,
        isArray: true,
    })
    reviews: Array<ArticleESDto>;

    @ApiModelProperty({
        type: SolutionESDto,
        isArray: true,
    })
    solutions: Array<SolutionESDto>;

    @ApiModelProperty({
        type: ProductDto,
        isArray: true,
    })
    productSales: Array<ProductDto>;

    @ApiModelProperty({
        type: ProductDto,
        isArray: true,
    })
    productHits: Array<ProductDto>;

    @ApiModelProperty({
        type: ProductDto,
        isArray: true,
    })
    productNew: Array<ProductDto>;

    @ApiModelProperty({
        type: FrontpageManufacturerDto,
        isArray: true,
    })
    brands: Array<FrontpageManufacturerDto>;

    @ApiModelProperty({
        type: BannerDto,
        isArray: true,
    })
    banners: Array<BannerDto>;

    @ApiModelProperty({
        type: BannerDto,
        isArray: true,
    })
    bannersAdditional: Array<BannerDto>;

    @ApiModelProperty()
    settings: HomepageDto;

    @ApiModelProperty()
    contentBundle: CommonContentWebBundleShared.Bundle;
}
