import { IsInt, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductSearchRequest, ProductSearchResponse } from '../endpoints/product.endpoint';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ManufacturerDto, ManufacturerListDto } from '../models/entities/manufacturer.dto';

export const SALES_WEB_DEFAULT_PAGE_SIZE = 15;

export enum SalesWebEndpointPaths {
    sales = '/api/v1/web/favorite/sales',
    products = '/api/v1/web/favorite/products',
}

export interface SalesWebEndpoint {
    sales(request: SalesWebRequest, req?: any): Promise<SalesWebResponse> | Observable<SalesWebResponse>;
    products(request: SalesProductsWebRequest): Promise<SalesProductsWebResponse> | Observable<SalesProductsWebResponse>;
}

export class SalesCategoryWebDto {
    @ApiModelProperty()
    total: number;

    @ApiModelProperty()
    category: ProductCategoryListDto;

    @ApiModelProperty({
        type: ManufacturerListDto,
        isArray: true,
    })
    manufacturers: Array<ManufacturerListDto>;
}

export class SalesWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    category?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    manufacturer?: string;
}

export class SalesWebResponse {
    @ApiModelProperty({
        required: false,
    })
    category?: SalesCategoryWebDto;

    @ApiModelProperty({
        required: false,
    })
    manufacturer?: ManufacturerDto;

    @ApiModelProperty({
        required: false,
        type: SalesCategoryWebDto,
        isArray: true,
    })
    categories?: Array<SalesCategoryWebDto>;
}

export class SalesProductsWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    category?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    manufacturer?: string;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    esSearchRequest?: ProductSearchRequest;
}

export class SalesProductsWebResponse {
    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty({
        required: false,
    })
    esResponse?: ProductSearchResponse;
}
