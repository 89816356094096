import { IsNotEmpty, IsObject, IsString, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { LocalSessionDTO } from '../models/entities/local-session.dto';

export enum LocalSessionWebEndpointPaths {
    get = '/api/v1/web/local-session/get',
    set = '/api/v1/web/local-session/set',
    destroy = '/api/v1/web/local-session/destroy',
}

export interface LocalSessionWebEndpoint {
    get(request: LocalSessionGetWebRequest): Promise<LocalSessionGetWebResponse> | Observable<LocalSessionGetWebResponse>;
    set(request: LocalSessionSetWebRequest): Promise<void> | Observable<void>;
    destroy(request: LocalSessionDestroyWebRequest): Promise<void> | Observable<void>;
}

export class LocalSessionSetWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    id: string;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    session: LocalSessionDTO;
}

export class LocalSessionGetWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    id: string;
}

export class LocalSessionGetWebResponse {
    @ApiModelProperty()
    id: string;

    @ApiModelProperty()
    dateTouched: string;

    @ApiModelProperty()
    session: LocalSessionDTO;
}

export class LocalSessionDestroyWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    id: string;
}
