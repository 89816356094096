import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsNotEmpty } from 'class-validator';

export namespace RedirectShared {
    export enum SortBy {
        Id = 'id',
        url = 'url',
        replaceUrl = 'replaceUrl',
    }

    export enum SortDirection {
        Asc = 'ASC',
        Desc = 'DESC',
    }

    export class SortQuery {
        @IsEnum(SortBy)
        @IsNotEmpty()
        @ApiModelProperty()
        field: SortBy;

        @IsEnum(SortDirection)
        @IsNotEmpty()
        @ApiModelProperty()
        direction: SortDirection;
    }
}
