import { IsInt, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductSearchRequest, ProductSearchResponse } from '../endpoints/product.endpoint';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ManufacturerDto, ManufacturerListDto } from '../models/entities/manufacturer.dto';

export const DISCOUNTED_WEB_DEFAULT_PAGE_SIZE = 15;

export enum DiscountedWebEndpointPaths {
    discounted = '/api/v1/web/favorite/discounted',
    products = '/api/v1/web/favorite/discounted/products',
}

export interface DiscountedWebEndpoint {
    discounted(request: DiscountedWebRequest, req?: any): Promise<DiscountedWebResponse> | Observable<DiscountedWebResponse>;
    products(request: DiscountedProductsWebRequest): Promise<DiscountedProductsWebResponse> | Observable<DiscountedProductsWebResponse>;
}

export class DiscountedCategoryWebDto {
    @ApiModelProperty()
    total: number;

    @ApiModelProperty()
    category: ProductCategoryListDto;

    @ApiModelProperty({
        type: ManufacturerListDto,
        isArray: true,
    })
    manufacturers: Array<ManufacturerListDto>;
}

export class DiscountedWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    category?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    manufacturer?: string;
}

export class DiscountedWebResponse {
    @ApiModelProperty({
        required: false,
    })
    category?: DiscountedCategoryWebDto;

    @ApiModelProperty({
        required: false,
    })
    manufacturer?: ManufacturerDto;

    @ApiModelProperty({
        required: false,
        type: DiscountedCategoryWebDto,
        isArray: true,
    })
    categories?: Array<DiscountedCategoryWebDto>;
}

export class DiscountedProductsWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    category?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    manufacturer?: string;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    esSearchRequest?: ProductSearchRequest;
}

export class DiscountedProductsWebResponse {
    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty({
        required: false,
    })
    esResponse?: ProductSearchResponse;
}
