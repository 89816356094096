/* eslint-disable @angular-eslint/no-output-native */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, input, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

interface ModalRequest {
    count$: Observable<number>;
}

interface ModalResponse {
    doSubmit?: boolean;
    doReset?: boolean;
}

interface State {
    title: string;
    count: number;
}

export { ModalRequest as ProductFiltersPreviewComponentModalRequest };
export { ModalResponse as ProductFiltersPreviewComponentModalResponse };

@Component({
    templateUrl: './product-filters-preview.component.html',
    styleUrls: ['./product-filters-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFiltersPreviewComponent implements OnInit, OnDestroy {
    public modalRequest = input<ModalRequest>();

    @Output('close') closeEvent: EventEmitter<ModalResponse> = new EventEmitter<ModalResponse>();

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public state = signal<State>({
        count: 0,
        title: this.t('found_0'),
    });

    constructor(
        private readonly i18n: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.modalRequest().count$.pipe(
            distinctUntilChanged(),
            takeUntil(this.ngOnDestroy$),
        ).subscribe((count) => {
            this.state.update((prev) => ({
                ...prev,
                count,
                title: this.i18n.instant((() => {
                    const lastDigit = count.toString()[count.toString().length - 1];

                    if (['0', '5', '6', '7', '8', '9'].includes(lastDigit) || (count >= 10 && count <= 19)) {
                        return this.t('found_3');
                    } else if (['1'].includes(lastDigit)) {
                        return this.t('found_1');
                    } else {
                        return this.t('found_2');
                    }
                })(), {
                    count,
                }),
            }));
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `product_shared.components.product_filters_preview.${input}`;
    }

    close(): void {
        this.closeEvent.emit({});
    }

    submit(): void {
        this.closeEvent.emit({
            doSubmit: true,
        });
    }

    reset(): void {
        this.closeEvent.emit({
            doReset: true,
        });
    }
}
