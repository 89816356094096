<div class='c'>
  <div class='c-preview'>
    <app-shared-product-card-assets-preview [maxImageWidth]='80'></app-shared-product-card-assets-preview>
  </div>
  <div class='c-product'>
    <div class='c-product-title'><span>{{ product().title }}</span></div>
    <div class='c-product-rating'>
      <app-shared-product-card-assets-rating [withRating]='false' [withReviews]='true'
        [withIsAvailableOnDepot]='true'></app-shared-product-card-assets-rating>
    </div>
    <div class='c-product-footer'>
      <div class='c-product-price'>
        <app-shared-product-card-assets-price variant='tiny'></app-shared-product-card-assets-price>
      </div>
    </div>
  </div>
</div>
