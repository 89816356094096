import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CommonContentWebBundleShared } from '../models/shared/common-content.shared';
import { PagesShared } from '../models/shared/pages.shared';

export enum PrivacyWebEndpointPaths {
    privacy = '/api/v1/web/privacy',
}

export interface PrivacyWebEndpoint {
    privacy(): Promise<PrivacyWebResponse> | Observable<PrivacyWebResponse>;
}

export class PrivacyWebResponse {
    @ApiModelProperty()
    dto: PagesShared.PrivacyAgreementPage;

    @ApiModelProperty()
    bundle: CommonContentWebBundleShared.Bundle;
}
