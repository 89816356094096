import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsOptional, IsPositive, IsString } from 'class-validator';
import { SolutionCategoryDto } from '../models/entities/solution-category.dto';
import { ArticleESDto } from '../models/entities/article.dto';
import { CommonContentWebBundleShared } from '../models/shared/common-content.shared';
import { SolutionESDto } from '../models/entities/solution.dto';

export enum SolutionWebEndpointPaths {
    solutions = '/api/v1/web/solutions',
    solution = '/api/v1/web/solution',
}

export interface SolutionWebEndpoint {
    solutions(): Promise<SolutionsPageWebResponse> | Observable<SolutionsPageWebResponse>;
    solution(request: SolutionPageWebRequest, req?: any): Promise<SolutionPageWebResponse> | Observable<SolutionPageWebResponse>;
}

export class SolutionsPageWebResponse {
    @ApiModelProperty({
        type: SolutionESDto,
        isArray: true,
    })
    solutions: Array<SolutionESDto>;

    @ApiModelProperty({
        type: SolutionCategoryDto,
        isArray: true,
    })
    solutionCategories: Array<SolutionCategoryDto>;

    @ApiModelProperty({
        type: SolutionESDto,
        isArray: true,
    })
    latestSolutions: Array<SolutionESDto>;

    @ApiModelProperty({
        type: ArticleESDto,
        isArray: true,
    })
    latestArticles: Array<ArticleESDto>;
}

export class SolutionPageWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    preview?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsInt()
    @IsPositive()
    @IsOptional()
    solutionId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    articleCategoryUri?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    solutionUri?: string;
}

export class SolutionPageWebResponse {
    @ApiModelProperty()
    solution: SolutionESDto;

    @ApiModelProperty()
    solutionCategory: SolutionCategoryDto;

    @ApiModelProperty({
        type: SolutionESDto,
        isArray: true,
    })
    solutionsInCategory: Array<SolutionESDto>;

    @ApiModelProperty({
        type: SolutionESDto,
        isArray: true,
    })
    latestSolutions: Array<SolutionESDto>;

    @ApiModelProperty()
    contentBundle: CommonContentWebBundleShared.Bundle;
}
