import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO, SourceDefinition } from '../shared/common.shared';

export class PageMetadataDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  url: string;

  @ApiModelProperty()
  metadata: PageMetadataSEO;

  @ApiModelProperty()
  urlSource: SourceDefinition;
}
