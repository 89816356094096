export const interidSiteWebFeatureCompareI18n: any = {
    compares: {
        components: {
            compares: {
                title: 'Сравнение товаров',
                empty: 'В сравнении пока нет товаров',
                category: 'Категория',
                manufacturer: 'Производитель',
                all: 'Все параметры',
                diff: 'Различающиеся',
            },
        },
    },
};
