import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { VacancyDto } from '../models/entities/vacancy.dto';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '../core/crud';

export enum VacancyEndpointPaths {
    create = '/api/v1/vacancies/create',
    edit = '/api/v1/vacancies/edit',
    delete = '/api/v1/vacancies/delete',
    list = '/api/v1/vacancies/list',
    setSortOrder = '/api/v1/vacancies/setSortOrder',
}

export interface VacancyEndpoint {
    create(request: CreateVacancyRequest): Promise<VacancyDto> | Observable<VacancyDto>;
    edit(request: EditVacancyRequest): Promise<VacancyDto> | Observable<VacancyDto>;
    delete(request: DeleteVacancyRequest): Promise<void> | Observable<void>;
    list(request: ListVacanciesRequest): Promise<ListVacanciesResponse> | Observable<ListVacanciesResponse>;
    setSortOrder(request: SetSortOrderVacancyRequest): Promise<SetSortOrderVacancyResponse> | Observable<SetSortOrderVacancyResponse>;
}

export class VacancyEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    salary: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    description: string;

    @ApiModelProperty()
    tags?: Array<string>;
}

export class CreateVacancyRequest extends VacancyEntityBody {
}

export class EditVacancyRequest extends VacancyEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteVacancyRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListVacanciesRequest extends CrudListRequestDto<VacancyDto> {
}

export class ListVacanciesResponse extends CrudListResponseDto<VacancyDto> {
    @ApiModelProperty({
        type: () => VacancyDto,
        isArray: true
    })
    items: Array<VacancyDto>;
}

export class SetSortOrderVacancyRequest extends CrudSetSortOrderRequestDto {
}

export class SetSortOrderVacancyResponse extends CrudSetSortOrderResponseDto<VacancyDto> {
    @ApiModelProperty({
        type: () => VacancyDto,
    })
    entity: VacancyDto;

    @ApiModelProperty({
        type: () => VacancyDto,
        isArray: true
    })
    affected: Array<VacancyDto>;
}
