import { IsArray, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ArticleESDto } from '../entities/article.dto';

export namespace ArticleShared {
    export enum ArticleStatus {
        NotPublished,
        Published,
    }
}

export namespace ArticleSearchShared {
    export enum Shape {
        List = 'List',
        WebArticlesList = 'WebArticlesList',
        WebArticlesNewsList = 'WebArticlesNewsList',
        WebArticlesSearch = 'WebArticlesSearch',
        WebArticle = 'WebArticle',
    }

    export enum SortBy {
        ElasticScore = '_score',
        Id = 'id',
        DatePublished = 'DatePublished',
        DateCreatedAt = 'DateCreatedAt',
        LikesCount = 'LikesCount',
        Hits = 'Hits'
    }

    export enum SortDirection {
        Asc = 'asc',
        Desc = 'desc',
    }

    export class CountRequest {
        @IsString()
        @IsOptional()
        queryString?: string;
    }

    export class SearchRequest {
        @IsString()
        @IsOptional()
        queryString?: string;

        @IsEnum(Shape)
        @IsOptional()
        @ApiModelProperty()
        shape?: Shape;

        @IsEnum(SortBy)
        @IsOptional()
        @ApiModelProperty()
        sort?: SortBy;

        @IsEnum(SortDirection)
        @IsOptional()
        @ApiModelProperty()
        sortDirection?: SortDirection;

        @IsPositive()
        @IsInt()
        @IsNotEmpty()
        @ApiModelProperty()
        limit: number;

        @IsInt()
        @IsNotEmpty()
        @ApiModelProperty()
        offset: number;

        @IsPositive({ each: true })
        @IsInt({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        ids?: Array<number>;

        @IsPositive({ each: true })
        @IsInt({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        excludeIds?: Array<number>;

        @IsPositive({ each: true })
        @IsInt({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        categoryIds?: Array<number>;

        @IsString({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        categorySlugs?: Array<string>;

        @IsPositive({ each: true })
        @IsInt({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        tagIds?: Array<number>;

        @IsString({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        tags?: Array<string>;

        @IsString({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        notInTags?: Array<string>;

        @IsPositive({ each: true })
        @IsInt({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        authorIds?: Array<number>;

        @IsString({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        authors?: Array<string>;

        @IsString()
        @IsOptional()
        @ApiModelProperty()
        slug?: string;
    }

    export class SearchResponse {
        @ApiModelProperty()
        total: number;

        @ApiModelProperty({
            type: ArticleESDto,
            isArray: true,
        })
        articles: Array<ArticleESDto>;
    }

    export class Stats {
        articleCategories: Array<{
            id: number;
            count: number;
        }>;
    }
}
