export const interidSiteWebCoreAuthI18n = {
    auth: {
        shared: {
            components: {
                auth_sign_in: {
                    title: 'Вход',
                    fields: {
                        email: 'E-mail',
                        password: 'Пароль',
                    },
                    actions: {
                        forgot: 'Забыли пароль?',
                        submit: 'Войти',
                        register: 'Зарегистрироваться',
                        close: 'Закрыть',
                    },
                },
                auth_register: {
                    title: 'Регистрация',
                    fields: {
                        fullName: 'ФИО / Компания / ИП',
                        email: 'E-mail',
                        password: 'Пароль',
                        repeat: 'Повторите пароль',
                    },
                    actions: {
                        signIn: 'Вернуться к авторизации',
                        submit: 'Зарегистрироваться',
                        close: 'Закрыть',
                    },
                    repeat_fail: 'Пароли не совпадают',
                    success: 'На ваш адрес электронный почты отправлена ссылка для подтверждения аккаунта',
                },
                auth_forgot_password: {
                    title: 'Восстановление пароля',
                    success: 'На почту отправлена ссылка для востановления доступа к аккаунту',
                    fields: {
                        email: 'E-mail',
                    },
                    actions: {
                        signIn: 'Вернуться к авторизации',
                        submit: 'Отправить ссылку для восстановления',
                        close: 'Закрыть',
                    },
                },
                auth_restore_password: {
                    title: 'Восстановление пароля',
                    repeat_fail: 'Пароли не совпадают',
                    success: 'Пароль сброшен',
                    fields: {
                        password: 'Пароль',
                        repeat: 'Повторите пароль',
                    },
                    actions: {
                        submit: 'Сбросить пароль',
                        close: 'Закрыть',
                    },
                },
            },
            resolvers: {
                auth_confirm_email_resolver: {
                    success: 'Ваш аккаунт подтвержден',
                },
            },
        },
    },
};
