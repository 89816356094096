import { Injectable } from '@angular/core';
import { InteridApiErrorDto, isApiErrorResponse } from '@interid/interid-site-shared';
import { MessagesService } from './messages.service';

@Injectable({
    providedIn: 'root',
})
export class ApiErrorHandlerService {
    constructor(
        private readonly messages: MessagesService,
    ) {
    }

    handle(response: any): void {
        if (response.status) {
            if ('code' in response.error) {
                const errResponse: InteridApiErrorDto = response.error;

                this.messages.error({
                    message: `__errors.${errResponse.code}`,
                    translate: true,
                });
            } else if ('error' in response.error && 'message' in response.error) {
                const property: string = response.error.message[0].property;

                this.messages.error({
                    message: '__validation.v_validation_api',
                    translate: true,
                    translateParams: {
                        field: property,
                    },
                });
            } else if ('message' in response.error) {
                this.messages.error({
                    message: response.error.message,
                    translate: false,
                });
            } else {
                this.messages.httpError();
            }
        } else if (isApiErrorResponse(response)) {
            this.messages.error({
                message: response.message,
                translate: false,
            });
        } else if (typeof response === 'string') {
            this.messages.error({
                message: response,
                translate: false,
            });
        } else {
            this.messages.httpError();
        }
    }
}

