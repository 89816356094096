import { AttachmentDto } from './attachment.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ContentDTO } from '../shared/common-content.shared';
import { PageMetadataSEO } from '../shared/common.shared';
import { ProductMarketplaceShared, ProductSaleShared } from '../shared/product.shared';

export enum ProductCategoryTemplate {
    default,
    lvl3,
}

export enum FilterScreen {
    Main = 'main',
    MobileLvl1 = 'mobileLvl1',
    MobileLvl2 = 'mobileLvl2'
}

export enum FiltersType {
    Manufactures = 'manufactures',
    Dicts = 'dicts',
    DictsMany = 'dictsMany',
    BooleanParams = 'booleanParams',
    ProductSeries = 'productSeries'
}

export class ProductAnnotationDescriptionParameter {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;
}

export class ProductCategorySaleDto {
    @ApiModelProperty()
    strategy: ProductSaleShared.ProductSaleStrategyPair;

    @ApiModelProperty()
    badge: string;

    @ApiModelProperty()
    badgeForCard: string;

    @ApiModelProperty()
    dateFrom?: string;

    @ApiModelProperty()
    dateTo?: string;
}

export class ProductCategoryDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty()
    parent?: number;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    menuTitle: string;

    @ApiModelProperty()
    alt: string;

    @ApiModelProperty()
    slug: string;

    @ApiModelProperty({
        type: () => ContentDTO,
    })
    description: ContentDTO;

    @ApiModelProperty({
        type: () => ContentDTO,
    })
    descriptionFooter: ContentDTO;

    @ApiModelProperty()
    productAnnotationDescriptionDivider: string;

    @ApiModelProperty()
    usefulProductCategories: Array<number>;

    @ApiModelProperty()
    productAnnotationDescriptionParameters: Array<ProductAnnotationDescriptionParameter>;

    @ApiModelProperty()
    image: AttachmentDto;

    @ApiModelProperty()
    icon?: AttachmentDto;

    @ApiModelProperty()
    menuIcon?: AttachmentDto;

    @ApiModelProperty()
    iconMobile?: AttachmentDto;

    @ApiModelProperty({
        type: () => PageMetadataSEO,
    })
    seo: PageMetadataSEO;

    @ApiModelProperty()
    hasSale: boolean;

    @ApiModelProperty()
    sale?: ProductCategorySaleDto;

    @ApiModelProperty()
    supplierPPId?: string;

    @ApiModelProperty()
    exportToYandexMarket?: ProductMarketplaceShared.YandexMarket.ExportRequest;

    @ApiModelProperty()
    exportToGoogle?: ProductMarketplaceShared.Google.ExportRequest;

    @ApiModelProperty()
    exportToPP?: ProductMarketplaceShared.PP.ExportRequest;

    @ApiModelProperty()
    isExportedToYandexMarket: boolean;

    @ApiModelProperty()
    isDisplayInCatalogMenu: boolean;

    @ApiModelProperty()
    makeDescriptionAnnotationForProduct: boolean;

    @ApiModelProperty()
    isExportedToGoogleMarket: boolean;

    @ApiModelProperty()
    template?: ProductCategoryTemplate;
}

export class ProductCategoryListDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    isDisplayInCatalogMenu: boolean;
 
    @ApiModelProperty()
    menuTitle: string;

    @ApiModelProperty()
    alt: string;

    @ApiModelProperty()
    parent?: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty()
    image?: AttachmentDto;

    @ApiModelProperty()
    icon?: AttachmentDto;

    @ApiModelProperty()
    iconMobile?: AttachmentDto;

    @ApiModelProperty()
    menuIcon?: AttachmentDto;

    @ApiModelProperty()
    slug?: string;

    @ApiModelProperty()
    template?: ProductCategoryTemplate;
}
