import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-shared-dialog-close',
    templateUrl: './dialog-close.component.html',
    styleUrls: ['./dialog-close.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCloseComponent {
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output('close') closeEvent: EventEmitter<void> = new EventEmitter<void>();

    t(input: string): string {
        return `shared.components.dialog_close.${input}`;
    }

    close(): void {
        this.closeEvent.emit();
    }
}
