
@if (state().message.isSiteMessageEnabled) {
  <div class="c" [ngStyle]="ngStyle">
    <div class="c-message" [innerHTML]="state.message.siteMessage | trustHtml"></div>
    <div class="c-close">
      <div class="icon icon-16x16px" (click)="hide()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="fill" [ngStyle]="iconNgStyle" d="M6.66667 8L0.266667 1.51111C0.0888889 1.33333 0 1.15556 0 0.888889C0 0.622222 0.0888889 0.444444 0.266667 0.266667C0.444444 0.0888889 0.8 0 1.06667 0C1.33333 0 1.6 0.0888889 1.77778 0.266667L8 6.57778L14.2222 0.266667C14.4 0.0888889 14.6667 0 14.9333 0C15.2 0 15.4667 0.0888889 15.6444 0.266667C15.8222 0.444444 16 0.622222 16 0.888889C16 1.15556 15.9111 1.33333 15.7333 1.51111L9.33333 8L15.8222 14.5778C15.9111 14.7556 16 14.9333 16 15.1111C16 15.3778 15.9111 15.5556 15.7333 15.7333C15.5556 15.9111 15.2889 16 14.9333 16C14.5778 16 14.4 15.9111 14.2222 15.7333L8 9.33333L1.77778 15.7333C1.6 15.9111 1.33333 16 1.06667 16C0.8 16 0.533333 15.9111 0.355556 15.7333C0.177778 15.5556 0 15.3778 0 15.1111C0 14.9333 0.0888889 14.7556 0.177778 14.5778L6.66667 8Z" fill="#8D96B9"></path>
        </svg>
      </div>
    </div>
  </div>
}