import { IsOptional, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from '../entities/attachment.dto';

export namespace AccountRequisitesShared {
    export type RequisitesDefinition = { type: RequisitesType.Legal; payload: LegalRequisitesPayload } | { type: RequisitesType.Real; payload: RealRequisitesPayload } | { type: RequisitesType.File; payload: FileRequisitesPayload };

    export enum RequisitesType {
        Legal = 'legal',
        Real = 'real',
        File = 'file',
    }

    export enum CustomerType {
        Person = 'person',
        Company = 'company',
        None = 'none'
    }

    export enum PaymentMethod {
        None = 'none',
        Cash = 'cash',
        Cashless = 'cashless',
        Sbp = 'sbp'
    }

    export class LegalRequisitesPayload {
        @IsOptional()
        @ApiModelProperty()
        @IsString()
        companyName: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        city: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        legalAddress: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        realAddress: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        bankName: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        bankBIK: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        bankAccount: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        bankCorrespondingAccount: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        inn: string;

        @IsOptional()
        @ApiModelProperty()
        @IsString()
        kpp: string;
    }

    export class RealRequisitesPayload {
        @IsString()
        @IsOptional()
        @ApiModelProperty()
        deliveryAddress: string;
    }

    export type FileRequisitesPayload = AttachmentDto;
}
