import { ProductCategoryListDto } from '../entities/product-category.dto';

export function getRootProductCategoryId(categoryId: number, productCategories: Array<ProductCategoryListDto>): number {
  let next = productCategories.find((c) => c.id === categoryId);

  while (next.parent) {
    next = productCategories.find((c) => c.id === next.parent);
  }

  return next.id;
}
