import { Routes } from '@angular/router';
import { AppLayoutComponent, AppLayoutResolve, AppNotFoundComponent } from '@interid/interid-site-web/core-app';
import { AuthConfirmEmailResolver, AuthResetPasswordResolver, VerifyJwtGuard } from '@interid/interid-site-web/core-auth';
import { LocalSessionResolve } from '@interid/interid-site-web/core-session';
import { RouteGuard } from '@interid/interid-site-web/core';

export const appRoutes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        resolve: {
            app: AppLayoutResolve,
            _confirm_email: AuthConfirmEmailResolver,
            _reset_password: AuthResetPasswordResolver,
            _local_session: LocalSessionResolve,
        },
        canActivateChild: [
            VerifyJwtGuard,
            RouteGuard,
        ],
        children: [
            {
                path: '',
                loadChildren: () => import('@interid/interid-site-web/feature-homepage').then((m) => m.InteridSiteWebFeatureHomepageModule),
            },
            {
                path: 'account',
                loadChildren: () => import('@interid/interid-site-web/feature-account').then((m) => m.InteridSiteWebFeatureAccountModule),
            },
            {
                path: 'about',
                loadChildren: () => import('@interid/interid-site-web/feature-about').then((m) => m.InteridSiteWebFeatureAboutModule),
            },
            {
                path: 'delivery',
                loadChildren: () => import('@interid/interid-site-web/feature-delivery').then((m) => m.InteridSiteWebFeatureDeliveryModule),
            },
            {
                path: 'contacts',
                redirectTo: 'about/contacts'
            },
            {
                path: 'privacy',
                loadChildren: () => import('@interid/interid-site-web/feature-privacy').then((m) => m.InteridSiteWebFeaturePrivacyModule),
            },
            {
                path: 'payment-methods',
                loadChildren: () => import('@interid/interid-site-web/feature-payment-methods').then((m) => m.InteridSiteWebFeaturePaymentMethodsModule),
            },
            {
                path: 'purchase-returns',
                loadChildren: () => import('@interid/interid-site-web/feature-purchase-returns').then((m) => m.InteridSiteWebFeaturePurchaseReturnsModule),
            },
            {
                path: 'articles',
                loadChildren: () => import('@interid/interid-site-web/feature-articles').then((m) => m.InteridSiteWebFeatureArticlesModule),
            },
            {
                path: 'solutions',
                loadChildren: () => import('@interid/interid-site-web/feature-solutions').then((m) => m.InteridSiteWebFeatureSolutionsModule),
            },
            {
                path: 'purchase',
                loadChildren: () => import('@interid/interid-site-web/feature-purchase').then((m) => m.InteridSiteWebFeaturePurchaseModule),
            },
            {
                path: 'categories',
                loadChildren: () => import('@interid/interid-site-web/feature-catalog').then((m) => m.InteridSiteWebFeatureCatalogModule),
            },
            {
                path: 'compares',
                loadChildren: () => import('@interid/interid-site-web/feature-compares').then((m) => m.InteridSiteWebFeatureComparesModule),
            },
            {
                path: 'favorites',
                loadChildren: () => import('@interid/interid-site-web/feature-favorites').then((m) => m.InteridSiteWebFeatureFavoritesModule),
            },
            {
                path: 'viewed',
                loadChildren: () => import('@interid/interid-site-web/feature-viewed').then((m) => m.InteridSiteWebFeatureViewedModule),
            },
            {
                path: 'vendors',
                loadChildren: () => import('@interid/interid-site-web/feature-vendor').then((m) => m.InteridSiteWebFeatureVendorModule),
            },
            {
                path: 'dictionary',
                loadChildren: () => import('@interid/interid-site-web/feature-dictionary').then((m) => m.InteridSiteWebFeatureDictionaryModule),
            },
            {
                path: 'product-reviews',
                loadChildren: () => import('@interid/interid-site-web/feature-product-reviews').then((m) => m.InteridSiteWebFeatureProductReviewsModule),
            },
            {
                path: 'kupit',
                loadChildren: () => import('@interid/interid-site-web/feature-product').then((m) => m.InteridSiteWebFeatureProductModule),
                data: {
                    reuse: false
                }
            },
            {
                path: 'sales',
                loadChildren: () => import('@interid/interid-site-web/feature-sales').then((m) => m.InteridSiteWebFeatureSalesModule),
            },
            {
                path: 'discounted',
                loadChildren: () => import('@interid/interid-site-web/feature-discounted').then((m) => m.InteridSiteWebFeatureDiscountedModule),
            },
            {
                path: 'marking-kits',
                loadChildren: () => import('@interid/interid-site-web/feature-markingkits').then((m) => m.InteridSiteWebFeatureMarkingkitsModule),
            },
            {
                path: 'search',
                loadChildren: () => import('@interid/interid-site-web/feature-search').then((m) => m.InteridSiteWebFeatureSearchModule),
            },
            {
                path: '**',
                component: AppNotFoundComponent,
            },
        ]
    }
];
