import { Injectable, RendererFactory2, ViewEncapsulation, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LinkService {
    private addedTags: Array<any> = [];

    constructor(
        private rendererFactory: RendererFactory2,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
        @Inject(DOCUMENT) private document,
        private readonly router: Router,
    ) {
        this.subscribeRemoveAllLinkTags();
    }

    private subscribeRemoveAllLinkTags(): void {
        this.router.events.pipe(
            filter((e) => e instanceof NavigationStart),
        ).subscribe(() => {
            this.deleteAllTags();
        });
    }

    addTag(tag: LinkDefinition) {
        try {
            const renderer = this.rendererFactory.createRenderer(this.document, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {},
            });

            const link = renderer.createElement('link');
            const head = this.document.head;

            if (head) {
                Object.keys(tag).forEach((prop: string) => {
                    return renderer.setAttribute(link, prop, tag[prop]);
                });

                renderer.appendChild(head, link);

                this.addedTags.push(link);
            }
        } catch (e) {
            console.error('Error within linkService : ', e);
        }
    }

    deleteAllTags(): void {
        const head = this.document.head;

        const renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {},
        });

        for (const tag of this.addedTags) {
            renderer.removeChild(head, tag);
        }

        this.addedTags = [];
    }

    deleteAllTagsOfRel(rel: string): void {
        const head = this.document.head;

        const renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {},
        });

        for (const tag of this.addedTags) {
            if (tag.rel === rel) {
                renderer.removeChild(head, tag);
            }
        }

        this.addedTags = this.addedTags.filter((tag) => tag.rel !== rel);
    }
}

export declare type LinkDefinition = {
    charset?: string;
    crossorigin?: string;
    href?: string;
    zhreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    sizes?: string;
    target?: string;
    type?: string;
} & {
    [prop: string]: string;
};

