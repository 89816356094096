<span class="c">
  <div class="c-data">
    <div class="message"><span>{{data.message}}</span></div>
    <div class="c-basket" [style]="{display: data.type === 'basket' ? 'flex' : 'none'}" (click)="toBasket()">
      <div class="count"><span>{{data.count }}</span></div>
      <img loading="lazy" src="/assets/images/u_shopping-cart.svg" /><span>Корзина</span>
    </div>
    <div class="c-compare" [style]="{display: data.type === 'compare' ? 'flex' : 'none'}" (click)="toCompare()">
      <div class="count"><span>{{data.count }}</span></div>
      <img loading="lazy" src="/assets/images/u_analytics.svg" /> <span>Сравнение</span>
    </div>
    <div class="c-favorite" [style]="{display: data.type === 'favorite' ? 'flex' : 'none'}" (click)="toFavorite()">
      <div class="count"><span>{{data.count }}</span></div>
      <img loading="lazy" src="/assets/images/u_heart-alt.svg" /> <span>Избранное</span>
    </div>
    <div class="c-close" (click)="close()">
      <img src="/assets/images/Union.svg" loading="lazy" />
    </div>
  </div>
</span>
