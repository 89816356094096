import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { CreateProductReviewLikeRequest, DeleteProductReviewLikeRequest, ListProductReviewLikeRequest, ListProductReviewLikeResponse, ProductReviewsLikesWebEndpoint, ProductReviewsLikesWebEndpointPaths, UpdateProductReviewLikeRequest } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebProductReviewsLikesDataAccess implements ProductReviewsLikesWebEndpoint {
    constructor(private readonly http: InteridDataAccessService) {}
    list(request: ListProductReviewLikeRequest): Observable<ListProductReviewLikeResponse> {
        return this.http.post(ProductReviewsLikesWebEndpointPaths.list, request);
    }
    like(request: CreateProductReviewLikeRequest): Observable<ListProductReviewLikeResponse> {
        return this.http.post(ProductReviewsLikesWebEndpointPaths.like, request);
    }
    setType(request: UpdateProductReviewLikeRequest): Observable<ListProductReviewLikeResponse> {
        return this.http.post(ProductReviewsLikesWebEndpointPaths.setType, request);
    }
    unlike(request: DeleteProductReviewLikeRequest): Observable<ListProductReviewLikeResponse> {
        return this.http.post(ProductReviewsLikesWebEndpointPaths.unlike, request);
    }
}
