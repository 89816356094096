import { IsInt, IsNotEmpty, IsObject, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ArticleCategoryDto } from '../models/entities/article-category.dto';
import { PageMetadataSEO } from '../models/shared/common.shared';
import { ContentDTO } from '../models/shared/common-content.shared';
import { CrudSetSortOrderRequestDto } from '../core/crud';

export enum ArticleCategoryEndpointPaths {
    list = '/api/v1/article-categories/listArticleCategories',
    create = '/api/v1/article-categories/createArticleCategory',
    edit = '/api/v1/article-categories/editArticleCategory',
    delete = '/api/v1/article-categories/deleteArticleCategory',
    setSortOrder = '/api/v1/article-categories/setSortOrder',
    getById = '/api/v1/article-categories/getById',
}

export interface ArticleCategoryEndpoint {
    create(request: CreateArticleCategoryRequest): Promise<ArticleCategoryDto> | Observable<ArticleCategoryDto>;
    edit(request: EditArticleCategoryRequest): Promise<ArticleCategoryDto> | Observable<ArticleCategoryDto>;
    delete(request: DeleteArticleCategoryRequest): Promise<void> | Observable<void>;
    list(): Promise<ListArticleCategoriesResponse> | Observable<ListArticleCategoriesResponse>;
    setSortOrder(request: SetSortOrderArticleCategoryRequest): Promise<void> | Observable<void>;
    getById(request: GetByIdArticleCategoryRequest): Promise<ArticleCategoryDto> | Observable<ArticleCategoryDto>;
}

export class ArticleCategoryEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    slug: string;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    contents: ContentDTO;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    contentsFooter: ContentDTO;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    seo: PageMetadataSEO;
}

export type ListArticleCategoriesResponse = Array<ArticleCategoryDto>;

export class CreateArticleCategoryRequest extends ArticleCategoryEntityBody {
}

export class EditArticleCategoryRequest extends ArticleCategoryEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteArticleCategoryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderArticleCategoryRequest extends CrudSetSortOrderRequestDto {}

export class GetByIdArticleCategoryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
