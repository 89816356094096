import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output, signal, viewChild } from '@angular/core';
import { SearchAutocompleteWebResponse } from '@interid/interid-site-shared';
import { SearchComponent } from '@interid/interid-site-web/ui-shared-search';

interface State {
    lastSearchQuery?: string;
    lastSearchResults?: SearchAutocompleteWebResponse;
}

@Component({
    selector: 'app-header-mobile-search',
    templateUrl: './app-header-mobile-search.component.html',
    styleUrls: ['./app-header-mobile-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderMobileSearchComponent implements AfterViewInit {
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output('close') closeEvent: EventEmitter<void> = new EventEmitter<void>();

    public search = viewChild<SearchComponent>('search')
    public state = signal<State>({});

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.search && this.search().focus) {
                this.search().focus();
            }
        });
    }

    close(): void {
        this.closeEvent.emit();
    }

    onSearchQuery($event: string | undefined): void {
        this.state.update((prev) => ({
            ...prev,
            lastSearchQuery: $event,
        }));
    }

    onSearchResults($event: SearchAutocompleteWebResponse): void {
        this.state.update((prev) => ({
            ...prev,
            lastSearchResults: $event,
        }));
    }

    get hasSearchResults(): boolean {
        return !! this.state().lastSearchResults
            && (
                this.state().lastSearchResults.articles.length > 0
                || this.state().lastSearchResults.manufacturers.length > 0
                || this.state().lastSearchResults.products.length > 0
                || this.state().lastSearchResults.productCategories.length > 0
            );
    }
}
