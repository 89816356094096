import { Injectable } from '@angular/core';
import { ParamsShared, ProductESDto, ProductParamsDto } from '@interid/interid-site-shared';
import { DEFAULT_MAX_COMMON_PARAMS } from '@interid/interid-site-web/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductCardSource } from '../product-card.source';

const DEFAULT_MAX_PARAMS = DEFAULT_MAX_COMMON_PARAMS;

@Injectable()
export class ProductCardParamsHelper {
    private _maxParams: number = DEFAULT_MAX_PARAMS;

    constructor(private readonly i18n: TranslateService, private readonly source: ProductCardSource) {}

    get product(): ProductESDto {
        return this.source.product;
    }

    set maxParams(maxParams: number) {
        this._maxParams = maxParams;
    }

    get maxParams(): number {
        return this._maxParams;
    }

    get hasProductParams(): boolean {
        return !!this.product.productParams;
    }

    get hasCardProductBagesParams(): boolean {
        return !!this.cardProductBagesParams && this.cardProductBagesParams.length !== 0;
    }

    get cardProductBagesParams(): Array<ProductParamsDto> {
        return this.product?.productParams?.filter((p) => p.displayDictionaryIcon && p.paramsName !== 'Гарантия');
    }

    get cardProductParams(): Array<ProductParamsDto> {
        let filtered = this.product.productParams.filter((p) => p.displayInProductCard);

        filtered.sort((a, b) => a.sortOrder - b.sortOrder);

        if (this.maxParams && filtered.length > this.maxParams) {
            filtered = filtered.slice(0, this.maxParams);
        }

        return filtered;
    }

    get paramsAreLong(): boolean {
        const TRIGGER = 60;

        return this.cardProductParams.some((param) => {
            return param.paramsName.length + param.staticValue?.length > TRIGGER;
        });
    }

    booleanParamDisplayValue(productParams: ProductParamsDto): string {
        return ParamsShared.allAvailableTruthyBooleanValue.includes(productParams.staticValue) ? '__models.BooleanYesNo.Yes' : '__models.BooleanYesNo.No';
    }

    isBooleanParam(productParams: ProductParamsDto): boolean {
        return productParams.paramsType === ParamsShared.ParamsType.Boolean;
    }

    isDictParam(productParams: ProductParamsDto): boolean {
        return productParams.paramsType === ParamsShared.ParamsType.Dict;
    }

    isDictManyParam(productParams: ProductParamsDto): boolean {
        return productParams.paramsType === ParamsShared.ParamsType.DictMany;
    }

    paramDisplayValue(productParam: ProductParamsDto): string {
        if (this.isBooleanParam(productParam)) {
            return this.i18n.instant(this.booleanParamDisplayValue(productParam));
        } else if (this.isDictParam(productParam)) {
            return this.dictParamDisplayValue(productParam);
        } else if (this.isDictManyParam(productParam)) {
            return this.dictManyParamDisplayValue(productParam);
        } else {
            return productParam.staticValue || '';
        }
    }

    dictManyParamDisplayValue(productParams: ProductParamsDto): string {
        return productParams.dictManyStaticValue;
    }

    dictParamDisplayValue(productParams: ProductParamsDto): string {
        return productParams.dictValue;
    }
}
