import { Injectable } from '@angular/core';
import { ProductESDto } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ProductCardSource } from '../product-card.source';
import { FavoriteService } from '../../services/favorite.service';

@Injectable()
export class ProductCardFavoritesHelper {
    constructor(
        private readonly i18n: TranslateService,
        private readonly source: ProductCardSource,
        private readonly favoriteService: FavoriteService,
    ) {
    }

    get product(): ProductESDto {
        return this.source.product;
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    toggleFavorites(): void {
        if (!! this.product && this.product.inFavorites) {
            this.favoriteService.removeProductFromFavorite({
                productId: this.product.id,
                productTitle: this.product.title,
            }).subscribe(() => {
                if (this.product) {
                    this.source.patchProduct({
                        inFavorites: false,
                    });
                }
            });
        } else {
            this.favoriteService.addProductToFavorite({
                productId: this.product.id,
                productCategoryId: this.product.productCategory.id,
                productTitle: this.product.title,
            }).subscribe(() => {
                if (this.product) {
                    this.source.patchProduct({
                        inFavorites: true,
                    });
                }
            });
        }
    }

    get labelFavorite(): string {
        return !! this.product && this.product.inFavorites
            ? this.i18n.instant(this.t('inFavorites'))
            : this.i18n.instant(this.t('favorite'));
    }

    get isInFavorites$(): Observable<boolean> {
        return this.source.product$.pipe(
            map((product) => !! product && product.inFavorites),
        );
    }
}
