import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsInt, IsNotEmpty, IsObject, IsPositive, ValidateNested } from 'class-validator';
import { ProductMarketplaceShared } from '../models/shared/product.shared';
import { Observable } from 'rxjs';

export enum ProductIntegrationsMarketplacesEndpointPaths {
    exportToMarketplaces = '/api/v1/product/exportToMarketplaces',
    runExportToMarketplaces = '/api/v1/product/runExportToMarketplaces',
}

export interface ProductIntegrationsMarketplacesEndpoint {
    exportToMarketplaces(request: ProductExportToMarketplacesRequest): Promise<void> | Observable<void>;
    runExportToMarketplaces(): Promise<void> | Observable<void>;
}

export class ProductExportToMarketplacesRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    ids: Array<number>;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    exportRequest?: ProductMarketplaceShared.ExportRequest;
}
