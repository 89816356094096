import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

type H1 = string | undefined;

export function isH1NotEmpty(input: any): boolean {
    return input !== undefined && (input || '').toString().trim().length > 0;
}

@Injectable({
    providedIn: 'root',
})
export class H1Service {
    private _currentH1: BehaviorSubject<H1> = new BehaviorSubject<H1>(undefined);

    constructor(
        readonly router: Router,
    ) {
        router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
        ).subscribe(() => this._currentH1.next(undefined));
    }

    setH1(h1: string): void {
        this._currentH1.next(h1);
    }

    get h1(): H1 {
        return this._currentH1.getValue();
    }

    get h1$(): Observable<H1> {
        return this._currentH1.asObservable();
    }

    get hasH1(): boolean {
        return isH1NotEmpty(this.h1);
    }
}
