import { ApiModelProperty } from "@nestjs/swagger/dist/decorators/api-model-property.decorator";
import { Observable } from "rxjs";
import { SystemTaskDto } from "../models/entities/system-task.dto";
import { SystemTaskShared } from "../models/shared/system-task.shared";

export enum ProductIntegrationsXmlEndpointPaths {
    importAccessoriesFromXML = '/api/v1/product-tools/importAccessoriesFromXML',
}

export interface ProductIntegrationsXmlEndpoint {
    importAccessoriesFromXML(uploadedFile: any): Promise<ProductToolsImportAccessoriesFromXMLResponse> | Observable<ProductToolsImportAccessoriesFromXMLResponse>;
}

export class ProductToolsImportAccessoriesFromXMLResponse {
    @ApiModelProperty()
    systemTask: SystemTaskDto<SystemTaskShared.TaskType.ProductImportAccessories, SystemTaskShared.MultiEntriesResponse>;
}
