import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SourceDefinition } from '../shared/common.shared';

export class RedirectDto {

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  url: string;

  @ApiModelProperty()
  replaceUrl: string;

  @ApiModelProperty()
  urlSource: SourceDefinition;

  @ApiModelProperty()
  replaceUrlSource: SourceDefinition;

  @ApiModelProperty()
  resolve?: Array<RedirectDto>;
}
