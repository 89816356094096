<app-shared-dialog-close (close)="close()"></app-shared-dialog-close>
<div class="c">
  <div class="c-group">
    <div class="c-title">
      <span>Выберите ваш город</span>
    </div>
    <div class="c-form" [formGroup]="state().form">
      <app-ngx-dadata-city (geolocation)="geolocation()" (cityClear)="cityClear()" [inputId]="'modalCitySearch'" (selected)="onCitySelected($event)" [styleDefault]="false" (selectedData)="onAddressSelectedData()" [placeholder]="'Город'" [config]="configCity"></app-ngx-dadata-city>
    </div>
    <div class="c-cities">
      @for (city of cities; track trackById(i, city); let i = $index) {
        <div class="c-city" [ngClass]="{ 'is-default': i === 0 || i === 1 }">
          <button (click)="selectCity(city)" [disabled]="state.disabled">
            <span>{{ city.variants.nominative }}</span>
          </button>
        </div>
      }
    </div>
  </div>
</div>
