/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, ElementRef, HostListener, Inject, signal, viewChild } from '@angular/core';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AttachmentDto } from '@interid/interid-site-shared';

interface ModalRequest {
    startWith: AttachmentDto;
    images: Array<AttachmentDto>;
    withoutControls?: boolean;
    withoutMaxWidth?: boolean;
    closeOnImageClick?: boolean;
    title?: string;
}

interface State {
    current: AttachmentDto;
    images: Array<AttachmentDto>;
    index: number;
    isClosed: boolean;
    showVideo: boolean;
}

interface ImageNgStyle {
    'max-width': string;
    cursor: string;
}

export { ModalRequest as ImageGalleryModalComponentModalRequest };

@Component({
    templateUrl: './image-gallery-modal.component.html',
    styleUrls: ['./image-gallery-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageGalleryModalComponent {
    public previewVideoRef = viewChild<ElementRef>('previewVideo');

    protected videoMIMETypes = ['video/webm', 'video/ogg', 'video/mp4'];
    
    public state = signal<State>({
        current: this.modalRequest.startWith,
        images: [...this.modalRequest.images],
        index: 0,
        isClosed: false,
        showVideo: false,
    });

    public imageNgStyle = signal<ImageNgStyle>({
        'max-width': this.modalRequest.withoutMaxWidth ? 'none' : '720px',
        cursor: this.modalRequest.closeOnImageClick ? 'pointer' : 'auto',
    });

    public currentImageUrl = computed<SafeUrl>(() => {
        return this.domSanitizer.bypassSecurityTrustUrl(this.state().current.videoForPreview
        ? this.state().current.videoForPreview.url
        : this.state().current.url);        
    });

    get hasPrev(): boolean {
        return !this.modalRequest.withoutControls;
    }

    get hasNext(): boolean {
        return !this.modalRequest.withoutControls;
    }

    @HostListener('document:keyup.arrowleft')
    onDocumentKeyUpLeft(): void {
        if (this.state().images.length <= 1) {
            return;
        }

        this.prevImage();
    }

    @HostListener('document:keyup.arrowright')
    onDocumentKeyUpRight(): void {
        if (this.state().images.length <= 1) {
            return;
        }

        this.nextImage();
    }

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) readonly modalRequest: ModalRequest,
        private readonly matDialogRef: MatDialogRef<ImageGalleryModalComponent>
    ) {}

    isVideo(mimeType: string) {
        return this.videoMIMETypes.includes(mimeType);
    }

    nextImage(): void {
        if (this.state().images.length <= 1) {
            return;
        }

        const currentIndex = this.state().images.findIndex((image) => image.id === this.state().current.id);

        if (currentIndex === this.state().images.length - 1 || currentIndex === -1) {
            this.state.update((prev) => ({
                ...prev,
                current: this.state().images[0],
                index: 0,
            }));
        } else {
            this.state.update((prev) => ({
                ...prev,
                current: this.state().images[currentIndex + 1],
                index: currentIndex + 1,
            }));
        }

        if (this.isVideo(this.state().current.mimeType)) this.previewVideoRef()?.nativeElement?.load();
    }

    prevImage(): void {
        if (this.state().images.length <= 1) {
            return;
        }

        const currentIndex = this.state().images.findIndex((image) => image.id === this.state().current.id);

        if (currentIndex <= 0) {
            this.state.update((prev) => ({
                ...prev,
                current: this.state().images[this.state().images.length - 1],
                index: this.state().images.length - 1,
            }));
        } else {
            this.state.update((prev) => ({
                ...prev,
                current: this.state().images[currentIndex - 1],
                index: currentIndex - 1,
            }));
        }

        if (this.isVideo(this.state().current.mimeType)) this.previewVideoRef()?.nativeElement?.load();
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }

    isSelected(image: AttachmentDto): boolean {
        return this.state().current.id === image.id || this.state()?.current?.previewForVideo?.id === image.id || this.state()?.current?.videoForPreview?.id === image.id;
    }

    selectImage($event: MouseEvent, image: AttachmentDto): void {
        if ($event.altKey || $event.metaKey || $event.ctrlKey || $event.shiftKey) {
            return;
        }

        $event.preventDefault();

        const currentIndex = this.state().images.findIndex((image) => image.id === this.state().current.id);

        this.state.update((prev) => ({
            ...prev,
            current: image,
            showVideo: false,
            index: currentIndex,
        }));

        if (this.isVideo(image.mimeType)) this.previewVideoRef()?.nativeElement?.load();
    }

    imageUrl(image: AttachmentDto): string {
        return image.url;
    }

    beforeClosed(): void {
        this.state.update((prev) => ({
            ...prev,
            isClosed: true,
        }));

        this.cdr.markForCheck();
    }

    close(): void {
        this.beforeClosed();

        setTimeout(() => {
            this.matDialogRef.close();
        });
    }
}
