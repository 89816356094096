const PAGE_REGEX = /^(.*)\/page\/(\d+)$/;

export const urlWithoutPagePostfix = (input: string) => {
  if (! input) {
    return '';
  }

  const pageMatch = input.match(PAGE_REGEX);

  if (pageMatch && pageMatch.length === 3) {
    const parsedPage = parseInt(pageMatch[2], 10);

    if (! isNaN(parsedPage) && Math.floor(parsedPage) === parsedPage && parsedPage > 0) {
      return pageMatch[1];
    }
  } else {
    return input;
  }
};
