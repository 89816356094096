import { Observable } from 'rxjs';
import { IsArray, IsEmail, IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, MaxLength, MinLength, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountAccessShared } from '../models/shared/account-access.shared';
import { AccountShared } from '../models/shared/account.shared';
import { AccountDto } from '../models/entities/account.dto';

export enum AccountAdminEndpointPaths {
    registerAdminAccount = '/api/v1/account/registerAdminAccount',
    listAdminAccounts = '/api/v1/account/listAdminAccounts',
    assignAdminAccess = '/api/v1/account/assignAdminAccess',
    revokeAdminAccess = '/api/v1/account/revokeAdminAccess',
    setAdminAccess = '/api/v1/account/setAdminAccess',
    getAccountByEmail = '/api/v1/account/getAccountByEmail',
    deleteAccountById = '/api/v1/account/deleteAccountById',
    confirmPhoneAccountById = '/api/v1/account/confirmPhoneAccountById',
    confirmEmailAccountById = '/api/v1/account/confirmEmailAccountById',
}

export interface AccountAdminEndpoint {
    registerAdminAccount(request: RegisterAdminAccountRequest): Promise<RegisterAdminAccountResponse> | Observable<RegisterAdminAccountResponse>;
    listAdminAccounts(request: ListAdminAccountsRequest): Promise<ListAdminAccountsResponse> | Observable<ListAdminAccountsResponse>;
    assignAdminAccess(request: AssignAdminAccessRequest): Promise<void> | Observable<void>;
    revokeAdminAccess(request: RevokeAdminAccessRequest): Promise<void> | Observable<void>;
    setAdminAccess(request: SetAccessRequest): Promise<SetAccessResponse> | Observable<SetAccessResponse>;
    getAccountByEmail(request: GetAccountByEmailRequest): Promise<GetAccountByEmailResponse> | Observable<GetAccountByEmailResponse>;
    deleteAccountById(request: DeleteAccountByIdRequest): Promise<void> | Observable<void>;
    confirmPhoneAccountById(request: ConfirmPhoneAccountByIdRequest): Promise<ConfirmPhoneAccountByIdResponse> | Observable<ConfirmPhoneAccountByIdResponse>;
    confirmEmailAccountById(request: ConfirmEmailAccountByIdRequest): Promise<ConfirmEmailAccountByIdResponse> | Observable<ConfirmEmailAccountByIdResponse>;
}

export class RegisterAdminAccountRequest {
    @IsString()
    @ApiModelProperty()
    @IsNotEmpty()
    fullName: string;

    @IsEmail()
    @IsString()
    @ApiModelProperty()
    @IsNotEmpty()
    email: string;

    @MinLength(AccountShared.V_PASSWORD_MIN)
    @MaxLength(AccountShared.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    password?: string;
}

export type RegisterAdminAccountResponse = AccountDto;

export class ListAdminAccountsRequest {
    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    limit: number;

    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    offset: number;

    @IsEnum(AccountShared.AccountRole)
    @IsOptional()
    @ApiModelProperty()
    role?: AccountShared.AccountRole;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    search?: string;
}

export class ListAdminAccountsResponse {
    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    total: number;

    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    @ApiModelProperty()
    accounts: Array<AccountDto>;
}

export class RevokeAdminAccessRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export class AssignAdminAccessRequest {
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    email: string;
}

export class SetAccessRequest {
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    email: string;

    @IsArray()
    @IsEnum(AccountAccessShared.AccessNode, {
        each: true,
    })
    @IsNotEmpty()
    @ApiModelProperty()
    accessNodes: Array<AccountAccessShared.AccessNode>;
}

export type SetAccessResponse = AccountDto;

export class GetAccountByEmailRequest {
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    email: string;
}

export type GetAccountByEmailResponse = AccountDto;

export class DeleteAccountByIdRequest {
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    id: number;
}

export class ConfirmPhoneAccountByIdRequest {
    @IsPositive()
    @IsInt()
    @ApiModelProperty()
    @IsNotEmpty()
    id: number;
}

export type ConfirmPhoneAccountByIdResponse = AccountDto;

export class ConfirmEmailAccountByIdRequest {
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    id: number;
}

export type ConfirmEmailAccountByIdResponse = AccountDto;
