import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { AppBootstrapWebEndpoint, AppBootstrapWebEndpointPaths, AppBootstrapWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebAppBootstrapDataAccess implements AppBootstrapWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    getBootstrap(): Observable<AppBootstrapWebResponse> {
        return this.http.get(AppBootstrapWebEndpointPaths.appBootstrap);
    }
}
