import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { AttachmentDto, DeliveriesWebRequest, PurchaseFlowCreateOrderResponse, PurchaseFlowCreateOrderWebRequest, PurchaseFlowDeliveriesWebResponse, PurchaseFlowOrderCostsWebRequest, PurchaseFlowOrderCostsWebResponse, PurchaseFlowProductsWebRequest, PurchaseFlowProductsWebResponse, PurchaseFlowWebEndpoint, PurchaseFlowWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebPurchaseFlowDataAccess implements PurchaseFlowWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    products(request: PurchaseFlowProductsWebRequest): Observable<PurchaseFlowProductsWebResponse> {
        return this.http.post(PurchaseFlowWebEndpointPaths.products, request);
    }

    deliveries(request: DeliveriesWebRequest): Observable<PurchaseFlowDeliveriesWebResponse> {
        return this.http.post(PurchaseFlowWebEndpointPaths.deliveries, request);
    }

    uploadRequisites(file: any): Observable<AttachmentDto> {
        const formData = new FormData();

        formData.append('file', file);

        return this.http.post(PurchaseFlowWebEndpointPaths.uploadRequisites, formData);
    }

    orderCosts(request: PurchaseFlowOrderCostsWebRequest): Observable<PurchaseFlowOrderCostsWebResponse> {
        return this.http.post(PurchaseFlowWebEndpointPaths.orderCosts, request);
    }

    createOrder(request: PurchaseFlowCreateOrderWebRequest): Observable<PurchaseFlowCreateOrderResponse> {
        return this.http.post(PurchaseFlowWebEndpointPaths.createOrder, request);
    }
}
