export namespace ParamsShared {
  export enum ParamsType {
    Dict = 'dict',
    DictMany = 'dictMany',
    String = 'string',
    Number = 'number',
    Float = 'float',
    Boolean = 'boolean',
  }

  export enum DescriptionAnnotationForProductType {
    None = 'none',
    Value = 'value',
    TitleAndValue = 'titleAndValue'
  }

  export const allDescriptionAnnotationForProductTypes = [
    DescriptionAnnotationForProductType.None,
    DescriptionAnnotationForProductType.Value,
    DescriptionAnnotationForProductType.TitleAndValue
  ];

  export const allParamsTypes = [
    ParamsType.Dict,
    ParamsType.DictMany,
    ParamsType.String,
    ParamsType.Number,
    ParamsType.Float,
    ParamsType.Boolean,
  ];

  export const allAvailableTruthyBooleanValue = [1, 1, true, '1', 'true', 'yes', 'True', 'Yes', 'да', 'Да'];
  export const allAvailableFalseBooleanValue = [0, 0, false, '0', 'false', 'no', 'False', 'No', 'нет', 'Нет'];

  export const allAvailableBooleanValue = [
    ...allAvailableTruthyBooleanValue,
    ...allAvailableFalseBooleanValue,
  ];
}
