/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, input, Input, Output, signal } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DadataConfig, DadataConfigDefault, DadataSuggestion, DadataType } from '@interid/interid-site-web/core';

@Component({
    selector: 'app-ngx-dadata-items',
    templateUrl: './purchase-basket-autocomplete-items.component.html',
    styleUrls: ['./purchase-basket-autocomplete-items.component.scss'],
})
export class NgxDadataItemsComponent {
    public daDataSuggestion = input<DadataSuggestion[]>([]);
    public config = input<DadataConfig>(DadataConfigDefault);
    public type = input(DadataType.address);

    @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();

    public data = signal(this.daDataSuggestion());

    onClick(item: DadataSuggestion) { 
        this.data.set([]);
        this.itemClick.emit(item);
    }
}
