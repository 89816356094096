import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, retryWhen, take } from 'rxjs/operators';
import { AppSessionAddToFavoritesRequest, AppSessionRemoveFavoritesRequest, AppSessionService } from '@interid/interid-site-web/core-session';
import { MessagesService, genericRetryStrategy } from '@interid/interid-site-web/core';

@Injectable({
    providedIn: 'root',
})
export class FavoriteService {
    constructor(
        private readonly appSession: AppSessionService,
        private readonly messages: MessagesService,
    ) {
    }

    private t(input: string): string {
        return `product.shared.services.favorite.${input}`;
    }

    addProductToFavorite(request: AppSessionAddToFavoritesRequest): Observable<void> { 
        return combineLatest([this.appSession.addToFavorites(request), this.appSession.numFavorites$]).pipe( 
            retryWhen(genericRetryStrategy()),
            take(1),
            map(([response, count]) => {
                this.messages.basketCompare({
                    translate: true,
                    message: this.t('added'),
                    translateParams: {
                        title: request.productTitle,
                    },
                }, 'favorite', count);

                return response;
            })
        );
    }

    removeProductFromFavorite(request: AppSessionRemoveFavoritesRequest): Observable<void> { 
        return combineLatest([this.appSession.deleteFromFavorites(request), this.appSession.numFavorites$]).pipe(
            retryWhen(genericRetryStrategy()), 
            take(1),
            map(([response, count]) => {
                this.messages.basketCompare({
                    translate: true,
                    message: this.t('delete'),
                    translateParams: {
                        title: request.productTitle,
                    },
                }, 'favorite', count); 
            })
        );
    }
}
