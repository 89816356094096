import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentComponent } from './components/content/content.component';
import { ContentBlockHtmlComponent } from './components/content-block-html/content-block-html.component';
import { ContentBlockProductsComponent } from './components/content-block-products/content-block-products.component';
import { ContentBlockListComponent } from './components/content-block-list/content-block-list.component';
import { ContentBlockYoutubeComponent } from './components/content-block-youtube/content-block-youtube.component';
import { ContentBlockPhotosComponent } from './components/content-block-photos/content-block-photos.component';
import { ContentBlockFilesComponent } from './components/content-block-files/content-block-files.component';
import { ContentBlockManufacturersComponent } from './components/content-block-manufacturers/content-block-manufacturers.component';
import { ContentBlockFeaturesComponent } from './components/content-block-features/content-block-features.component';
import { ContentBlockHintComponent } from './components/content-block-hint/content-block-hint.component';
import { ContentBlockSimpleTableComponent } from './components/content-block-simple-table/content-block-simple-table.component';
import { ContentBlockPhoneCallRequestFormComponent } from './components/content-block-phone-call-request-form/content-block-phone-call-request-form.component';
import { ContentBlockOrderConstructorComponent } from './components/content-block-order-constructor/content-block-order-constructor.component';
import { ContentBlockCostTableComponent } from './components/content-block-cost-table/content-block-cost-table.component';
import { ContentBlockListDeliveryOptionsComponent } from './components/content-block-list-delivery-options/content-block-list-delivery-options.component';
import { ContentBlockSimpleImageTableComponent } from './components/content-block-simple-image-table/content-block-simple-image-table.component';
import { InteridSiteWebUiSharedModule } from '@interid/interid-site-web/ui-shared';
import { InteridSiteWebUiSharedProductCardsModule } from '@interid/interid-site-web/ui-shared-product-cards';
import { ContentBlockAboutCompanyCertificatesComponent } from './components/content-block-about-company-certificates/content-block-about-company-certificates.component';
import { ContentBlockAboutCompanyAdvantagesComponent } from './components/content-block-about-company-advantages/content-block-about-company-advantages.component';
import { ContentBlockAboutCompanyHeaderComponent } from './components/content-block-about-company-header/content-block-about-company-header.component';
import { ContentBlockAboutCompanyActivitiesComponent } from './components/content-block-about-company-activities/content-block-about-company-activities.component';
import { ContentBlockPhotoGalleryComponent } from './components/content-block-photo-gallery/content-block-photo-gallery.component';

const CONTENT_SHARED_COMPONENTS = [
    ContentComponent,
];

const CONTENT_BLOCK_COMPONENTS = [
    ContentBlockHtmlComponent,
    ContentBlockProductsComponent,
    ContentBlockListComponent,
    ContentBlockYoutubeComponent,
    ContentBlockPhotosComponent,
    ContentBlockPhotoGalleryComponent,
    ContentBlockFilesComponent,
    ContentBlockManufacturersComponent,
    ContentBlockAboutCompanyCertificatesComponent,
    ContentBlockAboutCompanyAdvantagesComponent,
    ContentBlockAboutCompanyHeaderComponent,
    ContentBlockAboutCompanyActivitiesComponent,
    ContentBlockFeaturesComponent,
    ContentBlockHintComponent,
    ContentBlockSimpleTableComponent,
    ContentBlockPhoneCallRequestFormComponent,
    ContentBlockOrderConstructorComponent,
    ContentBlockCostTableComponent,
    ContentBlockListDeliveryOptionsComponent,
    ContentBlockSimpleImageTableComponent,
];

@NgModule({
  imports: [
    CommonModule,
    InteridSiteWebUiSharedModule,
    InteridSiteWebUiSharedProductCardsModule,
  ],
    declarations: [
        ...CONTENT_SHARED_COMPONENTS,
        ...CONTENT_BLOCK_COMPONENTS,
    ],
    exports: [
        ...CONTENT_SHARED_COMPONENTS,
    ],
})
export class InteridSiteWebUiSharedContentModule {
}
