import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  NgZone,
  OnDestroy,
  Renderer2,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { WS_SLIDER_COMPONENT, WS_SLIDER_THUMB_COMPONENT } from '../../common/ws-slider.constants';
import { IWsSliderThumbComponent, IWsBaseSliderDirective, IWsSlider } from '../../common/ws-slider.interfaces';
import { WsThumbEnum } from '../../common/ws-slider.enum';

@Component({
  selector: 'ws-slider-thumb',
  standalone: true,
  imports: [CommonModule],
  exportAs: 'wsSliderThumb',
  templateUrl: './ws-slider-thumb.component.html',
  styleUrl: './ws-slider-thumb.component.less',
  host: {
    'class': 'ws-slider--thumb',
    '(pointermove)': 'onPointerMove()',
  },
  providers: [
    {
    provide: WS_SLIDER_THUMB_COMPONENT, useExisting: WsSliderThumbComponent
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WsSliderThumbComponent implements IWsSliderThumbComponent, OnDestroy {
  private readonly ngZone = inject(NgZone);
  private readonly renderer = inject(Renderer2);
  private readonly elementRef = inject(ElementRef);
  private slider = inject<IWsSlider>(WS_SLIDER_COMPONENT);

  /** Used to remove event listeners (Renderer2). */
  private unListen: (() => void)[] = [];

  /** The slider input corresponding to this slider thumb. */
  private sliderInput: IWsBaseSliderDirective | undefined;

  /** The native html element of the slider input corresponding to this thumb. */
  private sliderInputEl: HTMLInputElement | undefined;

  /** Whether the slider thumb is currently being hovered. */
  public isHovered = false;

  /** Indicates which slider thumb this input corresponds to. */
  public thumbPosition = input<WsThumbEnum>(WsThumbEnum.END);

  /** Whether the slider thumb is currently being pressed. */
  public isActive = false;

  /** Whether the value indicator tooltip is visible. */
  public isValueIndicatorVisible = false;

  /** The host native HTML input element. */
  public hostElement: HTMLElement;

  /** The slider thumb knob. */
  public knob = viewChild('knob', { read: ElementRef<HTMLElement> });

  constructor(
  ) {
    this.hostElement = this.elementRef.nativeElement;

    afterNextRender(() => {
      this.sliderInput = this.slider.getInput(this.thumbPosition());
      this.sliderInputEl = this.sliderInput?.hostElement;
      const input = this.sliderInputEl;

      this.ngZone.runOutsideAngular(() => {
        if (input) {
          this.unListen.push(this.renderer.listen(input, 'pointermove', this.onPointerMove));
          this.unListen.push(this.renderer.listen(input, 'pointerdown', this.onDragStart));
          this.unListen.push(this.renderer.listen(input, 'pointerup', this.onDragEnd));
          this.unListen.push(this.renderer.listen(input, 'pointerleave', this.onMouseLeave));
          this.unListen.push(this.renderer.listen(input, 'focus', this.onFocus));
          this.unListen.push(this.renderer.listen(input, 'blur', this.onBlur));
        }
      });
    });
  }

  ngOnDestroy() {
    const input = this.sliderInputEl;

    if (input) {
      this.unListen.forEach(func => func());
    }
  }

  private onPointerMove = (event: PointerEvent): void => {
    if (this.sliderInput?.isFocused) {
      return;
    }

    const rect = this.hostElement.getBoundingClientRect();
    const isHovered = this.slider.isCursorOnSliderThumb(event, rect);
    this.isHovered = isHovered;
  };

  private onMouseLeave = (): void => {
    this.isHovered = false;
  };

  private onFocus = (): void => {
    this.hostElement.classList.add('ws-slider--thumb--focused');
  };

  private onBlur = (): void => {
    // Happens when the user tabs away while still dragging a thumb.
    // Happens when the user tabs away from a thumb but their cursor is still over it.
    this.hostElement.classList.remove('ws-slider--thumb--focused');
  };

  private onDragStart = (event: PointerEvent): void => {
    if (event.button !== 0) {
      return;
    }
  };

  private onDragEnd = (): void => {
    this.isActive = false;
  };

  /** Gets the native HTML element of the slider thumb knob. */
  getKnob(): HTMLElement {
    return this.knob()?.nativeElement;
  }
}
