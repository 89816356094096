<div [ngClass]="cNgClass">
  <div class="c-items">
    @if (isUL) {
      <ul [ngClass]="listNgClass">
        @for (row of payload().items; track row; let rowIndex = $index) {
          <li>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { row: row, rowIndex: rowIndex }"></ng-container>
          </li>
        }
      </ul>
    }
    @if (isOL) {
      <ol [ngClass]="listNgClass">
        @for (row of payload().items; track row; let rowIndex = $index) {
          <li>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { row: row, rowIndex: rowIndex }"></ng-container>
          </li>
        }
      </ol>
    }
    @if (isMarketplaces) {
      <div [ngClass]="listNgClass">
        @for (row of payload().items; track row; let rowIndex = $index) {
          <div class="item">
            <span [innerHTML]="row.text | trustHtml"> </span>
          </div>
        }
      </div>
    }
  </div>
</div>
<ng-template #itemTemplate let-row="row" let-rowIndex="rowIndex">
  <div class="item">
    <div class="item-text">
      <div [innerHTML]="row.text | trustHtml"></div>
    </div>
  </div>
</ng-template>
