import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthSignInComponentModalResponse } from '../components/auth-sign-in/auth-sign-in.component';
import { AuthSignInComponent } from '../components/auth-sign-in/auth-sign-in.component';
import { AuthRegisterComponent } from '../components/auth-register/auth-register.component';
import { AuthForgotPasswordComponent } from '../components/auth-forgot-password/auth-forgot-password.component';
import { defaultModalConfig } from '@interid/interid-site-web/core';

@Injectable({
    providedIn: 'root',
})
export class AuthModalsService {
    constructor(
        private readonly matDialog: MatDialog,
    ) {
    }

    authSignInModal(): MatDialogRef<AuthSignInComponent, AuthSignInComponentModalResponse> {
        return this.matDialog.open(AuthSignInComponent, {
            ...defaultModalConfig,
        });
    }

    authRegisterModal(): void {
        this.matDialog.open(AuthRegisterComponent, {
            ...defaultModalConfig,
        });
    }

    authForgotPasswordModal(): void {
        this.matDialog.open(AuthForgotPasswordComponent, {
            ...defaultModalConfig,
        });
    }
}
