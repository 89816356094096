import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductCardHelper, ProductCardVariant } from '../../helpers/product-card.helper';

type BooleanYesNo = 'yes' | 'no';

@Component({
    selector: 'app-shared-product-card-assets-price',
    templateUrl: './product-card-price.component.html',
    styleUrls: ['./product-card-price.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // TODO: Investigate why OnPush does not work here
})
export class ProductCardPriceComponent {
    @Input() variant: ProductCardVariant = 'default';
    @Input() flexPrices: BooleanYesNo = 'yes';
    @Input() highlight: BooleanYesNo = 'yes';
    @Input() withTradePrice: BooleanYesNo = 'no';

    constructor(
        public readonly helper: ProductCardHelper,
    ) {
    }

    t(input: string): string {
        return `product_shared.assets.product_card_price.${input}`;
    }

    get ngClass(): any {
        return [
            `flex-prices-${this.flexPrices}`,
            `variant-${this.variant}`,
            `highlight-${this.highlight}`,
        ];
    }
}
