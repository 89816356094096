import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class ProductEntryBasketDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  price: string;
}

export class ProductBasketDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  dateCreatedAt: string;

  @ApiModelProperty()
  product: ProductEntryBasketDto;

  @ApiModelProperty()
  amount: number;

  @ApiModelProperty()
  price: string;
}
