import { IsArray, IsNotEmpty, IsObject, IsOptional, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from '../entities/attachment.dto';
import { SiteColors } from '../util/site-colors';
import { ViewBreakpointsShared } from './view-breakpoints.shared';
import type { ProductDto } from '../entities/product.dto';
import type { ProductSearchRequest } from '../../endpoints/product.endpoint';
import type { SolutionListDto, SolutionESDto } from '../entities/solution.dto';
import type { ManufacturerListDto } from '../entities/manufacturer.dto';
import type { SourceDefinition } from './common.shared';
import type { DeliveryDto } from '../entities/delivery.dto';

export function isContentDTOEmpty(contentDTO: ContentDTO): boolean {
    return ! contentDTO || contentDTO.blocks.length === 0 || (contentDTO.blocks[0].pair.type === ContentBlock.HTML && contentDTO.blocks[0].pair.payload.contents.trim().length === 0);
}

export class ContentDTO {
    @ApiModelProperty()
    DTO_VERSION: number;

    @ApiModelProperty()
    sid: string;

    @ApiModelProperty({
        type: () => ContentBlockDto,
        isArray: true,
    })
    blocks: Array<ContentBlockDto>;

    @ApiModelProperty()
    enableNavigation: boolean;

    @ApiModelProperty()
    navigationTitle?: string;

    @ApiModelProperty()
    navigation: Array<CommonContentNavigationShared.NavigationDto>;
}

export class ContentBlockDto {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    sid: string;

    @IsOptional()
    @ApiModelProperty()
    @IsString()
    title?: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsObject()
    pair: ContentBlockPair;
}

export type ContentBlockPair =
    { type: ContentBlock.HTML; payload: ContentBlockHTML } |
    { type: ContentBlock.SimpleTable; payload: ContentBlockSimpleTable } |
    { type: ContentBlock.YouTube; payload: ContentBlockYouTube } |
    { type: ContentBlock.Photos; payload: ContentBlockPhotos } |
    { type: ContentBlock.Files; payload: ContentBlockFiles } |
    { type: ContentBlock.PhoneCallRequestForm; payload: ContentBlockPhoneCallRequestForm } |
    { type: ContentBlock.Manufacturers; payload: ContentBlockManufacturers } |
    { type: ContentBlock.Features; payload: ContentBlockFeatures } |
    { type: ContentBlock.OrderConstructor; payload: ContentBlockOrderConstructor } |
    { type: ContentBlock.AboutCompanyCertificates; payload: ContentBlockAboutCompanyCertificates } |
    { type: ContentBlock.PhotoGallery; payload: ContentBlockPhotoGallery } |
    { type: ContentBlock.AboutCompanyAdvantages; payload: ContentBlockAboutCompanyAdvantages } |
    { type: ContentBlock.AboutCompanyHeader; payload: ContentBlockAboutCompanyHeader } |
    { type: ContentBlock.AboutCompanyActivities; payload: ContentBlockAboutCompanyActivities } |
    { type: ContentBlock.CostTable; payload: ContentBlockCostTable } |
    { type: ContentBlock.Products; payload: ContentBlockProducts } |
    { type: ContentBlock.Hint; payload: ContentBlockHint } |
    { type: ContentBlock.ListDeliveryOptions; payload: {} } |
    { type: ContentBlock.SimpleImageTable; payload: ContentBlockSimpleImageTable } |
    { type: ContentBlock.List; payload: ContentBlockList }
    ;

export class ContentBlockHTML {
    @ApiModelProperty()
    contents: string;
}

export class ContentBlockTable {
    @ApiModelProperty()
    displayHeader: boolean;

    @ApiModelProperty()
    columns: Array<CommonContentTableShared.Column>;

    @ApiModelProperty()
    data: Array<Array<string>>;

    @ApiModelProperty()
    footer: string;
}

export class ContentBlockSimpleTable {
    @ApiModelProperty()
    data: Array<[string, string]>;

    @ApiModelProperty()
    footer?: string | undefined;
}

export class ContentBlockYouTube {
    @ApiModelProperty()
    url: string;

    @ApiModelProperty()
    youtubeId: string;
}

export class ContentBlockPhotos {
    @ApiModelProperty()
    images: Array<AttachmentDto>;

    @ApiModelProperty()
    heightInPixels?: number;

    @ApiModelProperty()
    widthInPixels?: number;
}

export class ContentBlockPhotoGallery {
    @ApiModelProperty()
    images: Array<AttachmentDto>; 
}

export class ContentBlockFiles {
    @ApiModelProperty()
    files: Array<AttachmentDto>;
}

export class ContentBlockAboutCompanyCertificates {
    @ApiModelProperty()
    images: Array<AttachmentDto>;
}

export class ContentBlockAboutCompanyCategories { 
    @ApiModelProperty()
    items: Array<ContentBlockAboutCompanyCategoriesShared.Item>;
}

export class ContentBlockAboutCompanyAdvantages { 
    @ApiModelProperty()
    items: Array<ContentBlockAboutCompanyAdvantagesShared.Item>;
}

export class AboutCompanyBanner {
    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    subtitle: string;

    @ApiModelProperty()
    linktext: string;
}

export class ContentBlockAboutCompanyHeader { 
    @ApiModelProperty()
    items: Array<ContentBlockAboutCompanyHeaderShared.Item>;
}

export class ContentBlockAboutCompanyActivities {
    @ApiModelProperty()
    items: Array<ContentBlockAboutCompanyActivitiesShared.Item>;

    @ApiModelProperty()
    header?: string;
}

export class ContentBlockPhoneCallRequestForm {
}

export class ContentBlockManufacturers {
    @ApiModelProperty()
    items: Array<ContentBlockManufacturersShared.Item>;
}

export class ContentBlockFeatures {
    @ApiModelProperty()
    layout: ContentBlockFeaturesShared.Layout;

    @ApiModelProperty()
    items: Array<ContentBlockFeaturesShared.Item>;
}

export class ContentBlockOrderConstructor {
    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    cover: AttachmentDto;

    @ApiModelProperty()
    groups: Array<ContentBlockOrderConstructorShared.Group>;
}

export class ContentBlockCostTable {
    @ApiModelProperty()
    items: Array<ContentBlockCostTableShared.Item>;
}

export class ContentBlockHint {
    @ApiModelProperty()
    contents: string;

    @ApiModelProperty()
    backgroundColor: SiteColors;

    @ApiModelProperty()
    textColor: SiteColors;
}

export class ContentBlockProducts {
    @ApiModelProperty()
    product: Partial<ProductDto>;

    @ApiModelProperty()
    productCardTitle: string;

    @ApiModelProperty()
    categoryCardTitle: string;

    @ApiModelProperty()
    categoryProductSearchRequest: ProductSearchRequest;

    @ApiModelProperty()
    solution: SolutionListDto;

    @ApiModelProperty()
    inverseOrder: boolean;
}

export class ContentBlockSimpleImageTable {
    @ApiModelProperty()
    items: Array<ContentBlockSimpleImageTableShared.Item>;

    @ApiModelProperty()
    footer?: string;
}

export class ContentBlockList {
    @ApiModelProperty()
    style: ContentBlockListShared.Style;

    @ApiModelProperty()
    items: Array<ContentBlockListShared.Item>;
}

export enum ContentBlock {
    HTML = 'HTML',
    SimpleTable = 'SimpleTable',
    YouTube = 'YouTube',
    Photos = 'Photos',
    PhotoGallery = 'PhotoGallery',
    Files = 'Files',
    PhoneCallRequestForm = 'PhoneCallRequestForm',
    Manufacturers = 'Manufacturers',
    Features = 'Features',
    OrderConstructor = 'OrderConstructor',
    CostTable = 'CostTable',
    Hint = 'Hint',
    Products = 'Products',
    ListDeliveryOptions = 'ListDeliveryOptions',
    SimpleImageTable = 'SimpleImageTable',
    List = 'List',
    AboutCompanyCertificates = 'AboutCompanyCertificates',
    AboutCompanyAdvantages = 'AboutCompanyAdvantages',
    AboutCompanyActivities = 'AboutCompanyActivities',
    AboutCompanyHeader = 'AboutCompanyHeader',
}

export namespace CommonContentShared {
    export const SID_LENGTH = 6;
    export const DTO_VERSION = 1;

    export const DEFAULT_BLOCK_PHOTOS_WIDTH = 320;
    export const DEFAULT_BLOCK_PHOTOS_HEIGHT = 240;

    export function sid(): string {
        const characters = 'abcdef0123456789';
        const charactersLength = characters.length;

        let result = '';

        for (let i = 0; i < SID_LENGTH; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    export function content(
        options: {
            blocks?: Array<ContentBlockDto>;
            enableNavigation?: boolean;
            navigation?: Array<CommonContentNavigationShared.NavigationDto>;
        } = {},
    ): ContentDTO {
        return {
            DTO_VERSION,
            sid: sid(),
            blocks: options.blocks || [],
            enableNavigation: options.enableNavigation || false,
            navigationTitle: undefined,
            navigation: options.navigation || [],
        };
    }

    export function emptyContent(): ContentDTO {
        return content({
            blocks: [],
            navigation: [],
        });
    }

    export function isEmpty(contentDTO: ContentDTO): boolean {
        return isContentDTOEmpty(contentDTO);
    }

    export const allContentBlocks: Array<ContentBlock> = Object.values(ContentBlock);
}

export namespace CommonContentNavigationShared {
    export class NavigationDto {
        @ApiModelProperty()
        @IsString()
        @IsNotEmpty()
        sid: string;

        @ApiModelProperty({
            required: false,
        })
        @IsString()
        @IsOptional()
        parentSid?: string;

        @IsString()
        @IsNotEmpty()
        @ApiModelProperty()
        relatedBlockSid: string;

        @ApiModelProperty()
        @IsString()
        @IsNotEmpty()
        title: string;

        @ApiModelProperty()
        @IsNotEmpty()
        @IsString()
        alt: string;

        @ApiModelProperty()
        @IsString()
        @IsNotEmpty()
        linkTag: string;
    }
}

export namespace CommonContentTableShared {
    export type ColumnWidth = 'auto' | number;

    export enum ColumnAlign {
        Left = 'Left',
        Center = 'Center',
        Right = 'Right',
    }

    export class Column {
        sid: string;
        title: string;
        configurations: Array<ColumnConfiguration>;
    }

    export class ColumnConfiguration {
        width: ColumnWidth;
        align: ColumnAlign;
        breakpoint: ViewBreakpointsShared.ViewBreakpoints;
    }
}

export namespace ContentBlockAboutCompanyCategoriesShared {
    export class Item {
        icon: AttachmentDto;
        title: string;
    } 
}

export namespace ContentBlockAboutCompanyAdvantagesShared {
    export class Item {
        icon: AttachmentDto;
        title: string;
        subtitle: string;
        description: string;
    } 
}

export namespace ContentBlockAboutCompanyHeaderShared {
    export class Item { 
        title: string;
        subtitle: string;
    } 
}

export namespace ContentBlockAboutCompanyActivitiesShared {
    export class Item {
        icon: AttachmentDto;
        title: string;
        subtitle: string;
    } 
}

export namespace ContentBlockFeaturesShared {
    export class Item {
        icon: AttachmentDto;
        title: string;
        description: string;
    }

    export enum Layout {
        Horizontal = 'horizontal',
        HorizontalV2 = 'horizontalV2',
        Vertical = 'Vertical',
        VerticalV2 = 'VerticalV2',
    }
}

export namespace ContentBlockOrderConstructorShared {
    export class Group {
        fieldName: string;
        nullTitle: string;
        products: Array<Product>;
    }

    export class Product {
        title: string;
        price: string;
        amount: string;
        product: Partial<ProductDto>;
    }
}

export namespace ContentBlockCostTableShared {
    export class Item {
        title: string;
        rows: Array<Row>;
    }

    export class Row {
        title?: string;
        description?: string;
        amount?: string;
        price?: string;
        relatedProduct?: Partial<ProductDto>;
    }
}

export namespace ContentBlockManufacturersShared {
    export class Item {
        link: ItemLink;
        manufacturer: ManufacturerListDto;
    }

    export class ItemLink {
        linkUrl: string;
        linkUrlSource: SourceDefinition;
        linkText: string;
        linkAlt: string;
    }
}

export namespace ContentBlockSimpleImageTableShared {
    export class Item {
        image: AttachmentDto;
        description: string;
    }
}

export namespace ContentBlockListShared {
    export class Item {
        text: string;
    }

    export enum Style {
        Dots = 'Dots',
        Marketplaces = 'Marketplaces',
        Marks = 'Marks',
        Digits = 'Digits'
    }

    export const allStyles: Array<Style> = Object.values(Style);
}

export namespace CommonContentWebBundleShared {
    class BlockBundleApiDocs {
        @ApiModelProperty()
        sid: string;

        @ApiModelProperty({
            type: 'string',
        })
        type: ContentBlock;

        @ApiModelProperty()
        payload: any;
    }

    export type BlockBundle =
        { type: ContentBlock.Products; sid: string; payload: ProductsBundle } |
        { type: ContentBlock.ListDeliveryOptions; sid: string; payload: DeliveriesBundle }
    ;

    export type BundlePayloads = ProductsBundle | DeliveriesBundle;

    export class ProductsBundle {
        @ApiModelProperty()
        product?: ProductDto;

        @ApiModelProperty()
        categoryProducts?: Array<ProductDto>;

        @ApiModelProperty()
        solution?: SolutionESDto;
    }

    export class DeliveriesBundle {
        @ApiModelProperty()
        deliveries: Array<DeliveryDto>;
    }

    export class Bundle {
        @ApiModelProperty({
            type: BlockBundleApiDocs,
            isArray: true,
        })
        @IsObject({ each: true })
        @IsArray()
        @IsNotEmpty()
        bundles: Array<BlockBundle>;
    }
}
