import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ContentDTO } from '../shared/common-content.shared';
import { Link } from '../shared/common.shared';
import { AttachmentDto } from './attachment.dto';
import { SiteColors } from '../util/site-colors';

export class HomepageDto {
  @ApiModelProperty()
  siteMessageId?: string;

  @ApiModelProperty()
  isSiteMessageEnabled: boolean;

  @ApiModelProperty({
    type: 'string',
    enum: Object.values(SiteColors),
  })
  siteMessageBgColor: SiteColors;

  @ApiModelProperty({
    isArray: true,
    type: 'string',
    enum: Object.values(SiteColors),
  })
  siteMessageTextColor: SiteColors;

  @ApiModelProperty()
  siteMessage: string;

  @ApiModelProperty({
    type: () => ContentDTO,
  })
  contents: ContentDTO;

  @ApiModelProperty({
    nullable: true,
  })
  solutionTopLeftId?: number | undefined;

  @ApiModelProperty({
    nullable: true,
  })
  solutionTopRightId?: number | undefined;

  @ApiModelProperty({
    nullable: true,
  })
  solutionBottomLeftId?: number | undefined;

  @ApiModelProperty({
    nullable: true,
  })
  solutionBottomRightId?: number | undefined;

  @ApiModelProperty({
    isArray: true,
  })
  productCategoryIds: Array<number>;

  @ApiModelProperty({
    isArray: true,
  })
  linkBlocks: Array<HomepageLinkBlock>;
}

export class HomepageLinkBlock {
  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  alt: string;

  @ApiModelProperty()
  cover: AttachmentDto;

  @ApiModelProperty()
  link: Link;
}
