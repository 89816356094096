import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RouteShared } from '@interid/interid-site-shared';

@Injectable({
    providedIn: 'root',
})
export class ResolvedRouteService {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
    private _current$: BehaviorSubject<RouteShared.RouteResolveResult> = new BehaviorSubject<RouteShared.RouteResolveResult | undefined>(undefined);

    get current$(): Observable<RouteShared.RouteResolveResult> {
        return this._current$.pipe(
            filter((n) => !! n),
        );
    }

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
    get current(): RouteShared.RouteResolveResult | undefined {
        return this._current$.getValue();
    }

    set current(newCurrent: RouteShared.RouteResolveResult) {
        this._current$.next(newCurrent);
    }
}
