import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { UISelectOption, UISelectOptions, uiSelectValueToLabel } from '../ui-select.models';

type Context = 'overlay' | 'modal';

interface State<T = any> {
    selected: T | Array<T>;
    options: UISelectOptions<T>;
}

@Component({
    selector: 'app-ui-select-options',
    templateUrl: './ui-select-options.component.html',
    styleUrls: ['./ui-select-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UISelectOptionsComponent<T = any> implements OnInit, OnDestroy, AfterViewInit {
    @Input() multiple: boolean;
    @Input() context: Context = 'overlay';
    @Input() fitWithElement: ElementRef<HTMLDivElement>;
    @Input() options: UISelectOptions<T>;
    @Input() selected: T | Array<T>;
    @Input() labels: UISelectOptions<T>;
    @Input() singleselect: boolean;

    @ViewChild('container') containerRef: ElementRef<HTMLDivElement>;

    @Output('submit') submitEvent: EventEmitter<T | Array<T>> = new EventEmitter<Array<T> | T>();

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        selected: [],
        options: [],
    };

    constructor(private readonly i18n: TranslateService) {}

    ngOnInit(): void {
        this.state = {
            ...this.state,
            options: this.options,
            selected: [...(Array.isArray(this.selected) ? this.selected : [this.selected])],
        };
    }

    ngAfterViewInit(): void {
        if (this.fitWithElement) {
            interval(50)
                .pipe(startWith(0), takeUntil(this.ngOnDestroy$))
                .subscribe(() => {
                    if (this.containerRef && this.containerRef.nativeElement && this.fitWithElement && this.fitWithElement.nativeElement) {
                        this.containerRef.nativeElement.style.minWidth = `${this.fitWithElement.nativeElement.getBoundingClientRect().width}px`;
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `shared.components.ui_select.ui_select_options.${input}`;
    }

    get ngClass(): any {
        return [`context-${this.context}`];
    }

    valueToLabel(value: any): string {
        return uiSelectValueToLabel(this.i18n, this.options, this.labels, value);
    }

    isValueCurrent(value: any): boolean{
        return Array.isArray(this.selected) ? this.selected.some((v) => v === value) : this.selected === value;
    }

    isValueSelected(value: any): boolean {
        return Array.isArray(this.state.selected) ? this.state.selected.some((v) => v === value) : this.state.selected === value;
    }

    select(option: UISelectOption<T>): void {
        if (this.singleselect) {
            if (this.isValueSelected(option.value)) {
                this.state = {
                    ...this.state,
                    selected: [],
                };
            } else {
                this.state = {
                    ...this.state,
                    selected: [option.value],
                };
            }
        } else if (this.multiple) {
            if (this.isValueSelected(option.value)) {
                this.state = {
                    ...this.state,
                    selected: this.state.selected.filter((opt) => opt !== option.value),
                };
            } else {
                this.state = {
                    ...this.state,
                    selected: [...this.state.selected, option.value],
                };
            }
        } else {
            this.state = {
                ...this.state,
                selected: [option],
            };

            this.submit();
        }
    }

    submit(): void {
        if (this.singleselect) {
            this.submitEvent.emit(this.state.selected.length ? this.state.selected[0] : undefined);
        } else if (this.multiple) {
            this.submitEvent.emit(this.state.selected.length ? this.state.selected.map((opt) => opt.value) : []);
        } else {
            this.submitEvent.emit(this.state.selected.length ? this.state.selected[0].value : undefined);
        }
    }
}
