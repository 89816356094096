import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class TagDto {
  static LAST_DTO_VERSION = '1';

  @ApiModelProperty()
  DTO_VERSION: string = TagDto.LAST_DTO_VERSION;

  @ApiModelProperty()
  id: number;


  @ApiModelProperty()
  sortOrder: number;


  @ApiModelProperty()
  title: string;
}
