import { IsEnum, IsNotEmpty, IsNumber, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export namespace CurrencyShared {
  export const mapCodeToSymbol: Array<{ code: string; symbol: string }> = [
    { code: 'RUB', symbol: '₽' },
    { code: 'USD', symbol: '$' },
    { code: 'EUR', symbol: '€' },
    { code: 'GBP', symbol: '£' },
    { code: 'UAH', symbol: '₴' },
    { code: 'BYN', symbol: 'р.' },
    { code: 'KZT', symbol: '₸' },
  ];

  export const allSymbols = mapCodeToSymbol.map((e) => e.symbol);

  export const mapCodeToYandexMarketCurrencyCode: Array<{ code: string; yandexMarketCurrencyCode: string }> = [
    { code: 'RUR', yandexMarketCurrencyCode: 'RUR' },
    { code: 'RUB', yandexMarketCurrencyCode: 'RUR' },
    { code: 'USD', yandexMarketCurrencyCode: 'USD' },
    { code: 'EUR', yandexMarketCurrencyCode: 'EUR' },
    { code: 'GBP', yandexMarketCurrencyCode: 'GBP' },
    { code: 'UAH', yandexMarketCurrencyCode: 'UAH' },
    { code: 'BYN', yandexMarketCurrencyCode: 'BYN' },
    { code: 'KZT', yandexMarketCurrencyCode: 'KZT' },
  ];

  export const isBaseCurrencyForYandexMarket = (yandexMarketCurrencyCode: string) => {
    return [
      'RUR',
      'RUB',
      'BYN',
      'UAH',
      'KZT',
    ].includes(yandexMarketCurrencyCode);
  };

  export const defaultYandexMarketSettingsForCurrency: (code: string) => YandexMarketSettings = (code: string) => {
    const isBase = isBaseCurrencyForYandexMarket(code);

    if (isBase) {
      return {
        currencyCode: code,
        plusPercents: undefined,
        rate: YandexRateVariant.Base,
      };
    } else {
      return {
        currencyCode: code,
        plusPercents: 0,
        rate: YandexRateVariant.CBRF,
      };
    }
  };

  export enum YandexRateVariant {
    Base = 'Base',
    CMS = 'CMS',
    CBRF = 'CBRF',
    NBU = 'NBU',
    NBK = 'NBK',
    CB = 'CB',
  }

  export const allYandexRateVariants: Array<YandexRateVariant> = Object.values(YandexRateVariant);

  export const allYandexRateVariantsForForeignCurrency: Array<YandexRateVariant> = [
    YandexRateVariant.CMS,
    YandexRateVariant.CBRF,
    YandexRateVariant.CBRF,
    YandexRateVariant.NBU,
    YandexRateVariant.NBK,
    YandexRateVariant.CB,

  ];
  export const allYandexRateVariantsForOwnCurrency: Array<YandexRateVariant> = [
    YandexRateVariant.Base,
  ];

  export class YandexMarketSettings {
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    currencyCode: string;

    @IsEnum(YandexRateVariant)
    @IsNotEmpty()
    @ApiModelProperty()
    rate: YandexRateVariant;

    @IsNumber()
    @IsNotEmpty()
    @ApiModelProperty()
    plusPercents: number;
  }
}
