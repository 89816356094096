import { Injectable } from '@angular/core';
import { ProductESDto } from '@interid/interid-site-shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompareService } from '../../services/compares.service';
import { ProductCardSource } from '../product-card.source';

@Injectable()
export class ProductCardCompareHelper {
    constructor(
        private readonly i18n: TranslateService,
        private readonly source: ProductCardSource,
        private readonly compareService: CompareService,
    ) {}

    get product(): ProductESDto {
        return this.source.product;
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    toggleCompares(): void {
        if (! this.product) {
            return;
        }

        if (this.source.product.inCompares) {
            this.compareService.removeProductFromCompare({
                productId: this.product.id,
                productTitle: this.product.title,
            }).subscribe(() => {
                this.source.patchProduct({
                    inCompares: false,
                });
            });
        } else {
            this.compareService.addProductToCompare({
                productId: this.product.id,
                productTitle: this.product.title,
            }).subscribe(() => {
                this.source.patchProduct({
                    inCompares: true,
                });
            });
        }
    }

    get labelCompare(): string {
        return !! this.product && this.product.inCompares
            ? this.i18n.instant(this.t('inCompares'))
            : this.i18n.instant(this.t('compare'));
    }

    get isInCompares$(): Observable<boolean> {
        return this.source.product$.pipe(
            map((product) => !! product && product.inCompares),
        );
    }
}
