<div class="c">
  <div class="c-box" (click)="phoneCallRequest()">
    <div class="c-box-quotation">
      <div class="c-box-quotation-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m9 15 6-6" stroke="#1269FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M9.5 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" fill="#1269FF" stroke="#1269FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M5 7.2A2.2 2.2 0 0 1 7.2 5h1a2.2 2.2 0 0 0 1.55-.64l.7-.7a2.2 2.2 0 0 1 3.12 0l.7.7c.412.41.97.64 1.55.64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .581.23 1.139.64 1.55l.7.7a2.2 2.2 0 0 1 0 3.12l-.7.7a2.2 2.2 0 0 0-.64 1.55v1a2.2 2.2 0 0 1-2.2 2.2h-1a2.2 2.2 0 0 0-1.55.64l-.7.7a2.202 2.202 0 0 1-3.12 0l-.7-.7a2.2 2.2 0 0 0-1.55-.64h-1a2.2 2.2 0 0 1-2.2-2.2v-1a2.2 2.2 0 0 0-.64-1.55l-.7-.7a2.2 2.2 0 0 1 0-3.12l.7-.7A2.2 2.2 0 0 0 5 8.2v-1" stroke="#1269FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <span> Получить коммерческое предложение </span>
    </div>
  </div>
  @if (resolvedData()?.product?.files?.length > 0) {
    <div class="c-box __hide-app-wide __hide-app-desktop __hide-app-tablet" (click)="instructionsAndDrivers()">
      <div class="c-box-quotation">
        <div class="c-box-quotation-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.168 2.061a13.1 13.1 0 0 0-2.17-.19 12.94 12.94 0 0 0-7 2.05 12.94 12.94 0 0 0-7-2 13.1 13.1 0 0 0-2.17.19 1 1 0 0 0-.83 1v12a1 1 0 0 0 1.17 1 10.899 10.899 0 0 1 8.25 1.91l.12.07h.11a.91.91 0 0 0 .7 0h.11l.12-.07a10.9 10.9 0 0 1 8.25-2.02 1 1 0 0 0 1.17-1v-12a1 1 0 0 0-.83-.94Zm-10.17 13.29a12.87 12.87 0 0 0-6-1.48h-1v-10a8.34 8.34 0 0 1 1 0 10.86 10.86 0 0 1 6 1.8v9.68Zm9-1.44h-1a12.87 12.87 0 0 0-6 1.48v-9.72a10.86 10.86 0 0 1 6-1.8 8.34 8.34 0 0 1 1 0v10.04Zm1.17 4.15a13.098 13.098 0 0 0-2.17-.19 12.94 12.94 0 0 0-7 2.05 12.94 12.94 0 0 0-7-2.05c-.728.003-1.454.067-2.17.19a1 1 0 0 0-.83 1.15 1 1 0 0 0 1.17.79 10.9 10.9 0 0 1 8.25 1.91 1 1 0 0 0 1.16 0 10.9 10.9 0 0 1 8.25-1.91 1 1 0 0 0 1.17-.79 1 1 0 0 0-.83-1.15Z" fill="#1269FF" /></svg>
        </div>
        <span> Инструкции и Драйверы </span>
      </div>
    </div>
  }
</div>
