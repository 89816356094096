import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CommonContentWebBundleShared, ContentDTO, isContentDTOEmpty } from '@interid/interid-site-shared';

export interface AdditionalTextPayload {
    content: ContentDTO;
    bundle: CommonContentWebBundleShared.Bundle;
}

export type AdditionalText = AdditionalTextPayload | undefined;

@Injectable({
    providedIn: 'root',
})
export class AdditionalTextService {
    private _additionalTextTop: BehaviorSubject<AdditionalText> = new BehaviorSubject<AdditionalText>(undefined);
    private _additionalTextBottom: BehaviorSubject<AdditionalText> = new BehaviorSubject<AdditionalText>(undefined);

    constructor(
        readonly router: Router,
    ) {
        router.events.pipe(
            filter((e) => e instanceof NavigationStart),
        ).subscribe(() => {
            this._additionalTextTop.next(undefined);
            this._additionalTextBottom.next(undefined);
        });
    }

    set additionalTextTop(content: AdditionalTextPayload) {
        this._additionalTextTop.next(content);
    }

    set additionalTextBottom(content: AdditionalTextPayload) {
        this._additionalTextBottom.next(content);
    }

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
    get additionalTextTop(): AdditionalText {
        return this._additionalTextTop.getValue();
    }

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
    get additionalTextBottom(): AdditionalText {
        return this._additionalTextBottom.getValue();
    }

    get hasAdditionalTextTop(): boolean {
        return this.additionalTextTop !== undefined && ! isContentDTOEmpty(this.additionalTextTop.content);
    }

    get hasAdditionalTextBottom(): boolean {
        return this.additionalTextBottom !== undefined && ! isContentDTOEmpty(this.additionalTextBottom.content);
    }
}
