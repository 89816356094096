export enum Application {
  API = 'api',
  CMS = 'cms',
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android',
}

export const clientApplications: Array<Application> = [
  Application.WEB,
  Application.IOS,
  Application.ANDROID,
];

export const allowedApplicationsForAdministrators: Array<Application> = [
  Application.API,
  Application.CMS,
];

export function applicationToClientApplication(input: Application): Application {
  return clientApplications.includes(input)
    ? input
    : Application.WEB;
}

export const DEFAULT_APPLICATION = Application.WEB;
