import { InjectionToken } from '@angular/core';
import { Request, Response } from 'express';

// eslint-disable-next-line
// @ts-ignore
export const REQUEST = new InjectionToken<Request>('REQUEST');

// eslint-disable-next-line
// @ts-ignore
export const RESPONSE = new InjectionToken<Response>('RESPONSE');
