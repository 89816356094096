import { APP_ID, Inject, NgModule, PLATFORM_ID, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { NavigationEnd, Router, RouteReuseStrategy, RouterModule, ROUTER_CONFIGURATION } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent, InteridSiteWebCoreAppModule } from '@interid/interid-site-web/core-app';
import { appRoutes } from './app.routes';
import { InteridSiteWebUiSharedModule } from '@interid/interid-site-web/ui-shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InteridSiteWebCoreHttpModule } from '@interid/interid-site-web/core-http';
import { environment } from '../environments/environment';
import { RouterConfiguration, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { EnvironmentWebService, GlobalBrowserScrollService, interidSiteWebI18n } from '@interid/interid-site-web/core';
import { isPlatformBrowser } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { InteridDataAccessModule } from '@interid/interid-site-data-access/common';
import { CustomRouteReuseStrategy } from './router-strategy';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        RouterModule.forRoot(appRoutes, new RouterConfiguration()),
        InteridDataAccessModule.forRoot({
            injects: [],
            useFactory: () => ({
                apiEndpointUrl: environment.webApiEndpoint,
            }),
        }),
        InteridSiteWebCoreHttpModule,
        InteridSiteWebCoreAppModule,
        InteridSiteWebUiSharedModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideClientHydration(withHttpTransferCacheOptions({
            includePostRequests: true
        })),
        RouterConfiguration,
        { provide: APP_ID,  useValue: 'interid-web' },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha },
        { provide: ROUTER_CONFIGURATION, useExisting: RouterConfiguration },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy,
        },
    ],
})
export class AppModule {
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly env: EnvironmentWebService,
        private readonly translate: TranslateService,
        private readonly viewBreakpointsService: ViewBreakpointsService,
        private readonly router: Router,
        private readonly session: AppSessionService,
        private readonly globalBrowserScroll: GlobalBrowserScrollService
    ) {
        this.destroyInitialLoader();

        this.initEnv();
        this.initTranslateModule();
        this.initViewBreakpoints();
        this.initSession();
        this.initGlobalBrowserScroll();
    }

    private initEnv(): void {
        this.env.current = environment;
    }

    private initSession(): void {
        this.session.init();
    }

    private destroyInitialLoader(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(
                    filter((e) => e instanceof NavigationEnd),
                    take(1)
                )
                .subscribe(() => {
                    if (window && window.document && window.document.getElementById) {
                        const initialLoader = window.document.getElementById('interidInitialLoader');

                        if (initialLoader && initialLoader.remove) {
                            initialLoader.remove();
                        }
                    }
                });
        }
    }

    private initTranslateModule(): void {
        this.translate.setDefaultLang('ru');
        this.translate.setTranslation('ru', interidSiteWebI18n, true);
    }

    private initViewBreakpoints(): void {
        this.viewBreakpointsService.watch();
    }

    private initGlobalBrowserScroll(): void {
        if (isPlatformBrowser(this.platformId) && document && document.body) {
            this.globalBrowserScroll.enabled$.subscribe((enabled) => {
                document.body.style.overflow = enabled ? 'auto' : 'hidden';
            });
        }
    }
}
