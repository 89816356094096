import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum ArticleLikesWebEndpointPaths {
    save = '/api/v1/web/article-likes/save', 
    getByArticleId = '/api/v1/web/article-likes/getByArticleId', 
}

export interface ArticleLikesWebEndpoint {
    save(request: ArticleLikesWebRequest, req?: any): Promise<ArticleLikesWebResponse> | Observable<ArticleLikesWebResponse>;
    getByArticleId(request: ArticleLikesWebRequest, req?: any): Promise<ArticleLikesWebResponse> | Observable<ArticleLikesWebResponse>;
}

export class ArticleLikesWebRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    articleId: number;
}

export class ArticleLikesWebResponse {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    articleId: number;

    @ApiModelProperty()
    authorId?: number;

    @ApiModelProperty()
    likesCount: number;
}
 
 