import { IsArray, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, Max, Min } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ES_MAX_SEARCH_RESULTS_SIZE } from '../constants/es.constants';
import { ParamsReferenceDto } from '../entities/params-reference.dto';

export namespace ParamsReferenceSearchShared {
  export enum Shape {
    Tooltip = 'Tooltip',
  }

  export class SearchRequest {
    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    offset: number;

    @IsPositive()
    @IsInt()
    @Min(1)
    @Max(ES_MAX_SEARCH_RESULTS_SIZE)
    @IsNotEmpty()
    @ApiModelProperty()
    limit: number;

    @IsEnum(Shape)
    @IsOptional()
    @ApiModelProperty()
    shape?: Shape;

    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray({ each: true })
    @IsOptional({ each: true })
    productCategoryIds?: Array<number>;

    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray({ each: true })
    @IsOptional({ each: true })
    manufacturerIds?: Array<number>;
  }

  export interface SearchResponse {
    total: number;
    paramsReferences: Array<ParamsReferenceDto>;
  }
}
