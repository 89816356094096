import { ChangeDetectionStrategy, Component, input, Input, OnChanges, signal, SimpleChanges } from '@angular/core';

type Error = undefined | 'required' | 'email';

@Component({
    selector: 'app-ui-errors',
    templateUrl: './ui-errors.component.html',
    styleUrls: ['./ui-errors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIErrorsComponent implements OnChanges {
    public errors = input();

    public error = signal<Error | undefined>(undefined);

    t(input: string): string {
        return `shared.components.ui_errors.${input}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.errors()) {
            if (this.errors()['required']) {
                this.error.set('required');
            } else if (this.errors()['email']) {
                this.error.set('email');
            }
        } else {
            this.error.set(undefined);
        }
    }
}
