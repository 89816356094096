import { Injectable } from '@angular/core';
import { CommonReplaceUrlExports, routerLinkBuildFromDefinitionHelper, SourceDefinition, UrlBuilderBuildOptions, UrlBuilderRouterLinkDefinition, urlBuildFromDefinitionHelper, withTrailingSlash } from '@interid/interid-site-shared';
import * as qs from 'querystring';
import * as _ from 'underscore';

@Injectable({
    providedIn: 'root',
})
export class UrlBuilderService {
    private replaces: Array<CommonReplaceUrlExports> = [];

    import(replaces: Array<CommonReplaceUrlExports>): void {
        this.replaces = _.uniq([...this.replaces, ...replaces], (r) => r.url);
    }

    uri(uri: string): string {
        const replaceExport = this.replaces.find((r) => r.url === uri);

        return replaceExport
            ? replaceExport.replaceUrl
            : uri;
    }

    urlLink(
        urlSource: SourceDefinition,
        options?: UrlBuilderBuildOptions,
    ): string {
        const url = urlBuildFromDefinitionHelper(urlSource, options);
        const replaceExport = this.replaces.find((r) => r.url === url);

        return replaceExport
            ? withTrailingSlash(replaceExport.replaceUrl)
            : url;
    }

    routerLink(
        urlSource: SourceDefinition,
        options?: UrlBuilderBuildOptions,
    ): UrlBuilderRouterLinkDefinition {
        const originalRoute = routerLinkBuildFromDefinitionHelper(urlSource, options);

        if (Object.keys(originalRoute.queryParams).length > 0) {
            const queryString = qs.encode(originalRoute.queryParams);
            const url = `${originalRoute.route}?${queryString}`;

            const replaceExport = this.replaces.find((r) => r.url === url);

            if (replaceExport) {
                const replaceQueryParams = qs.parse(replaceExport.replaceUrl);

                return Object.keys(replaceQueryParams).length
                    ? { route: replaceExport.replaceUrl, queryParams: replaceQueryParams }
                    : { route: replaceExport.replaceUrl };
            } else {
                return originalRoute;
            }
        } else {
            const url = Array.isArray(originalRoute.route)
                ? originalRoute.route.join('/')
                : originalRoute.route;

            const replaceExport = this.replaces.find((r) => r.url === url);

            return replaceExport
                ? { route: [withTrailingSlash(replaceExport.replaceUrl)] }
                : originalRoute;
        }
    }
}
