export const interidSiteWebFeatureContentI18n: any = {
    content: {
        components: {
            content: {
                viewMore: 'Развернуть описание',
                hideMore: 'Скрыть описание'
            },
        },
    },
};
