export const interidSiteWebCoreViewedI18n = {
    viewed: {
        shared: {
            components: {
                viewed_list: {
                    title: 'Вы недавно смотрели',
                    empty: 'Пока нет товаров',
                    allCategories: 'Все категории'
                },
            },
        },
    },
};
