import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductQuestionShared } from '../shared/product-question.shared';

export class ProductQuestionLikeDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  type: ProductQuestionShared.LikeType;

  @ApiModelProperty()
  authorAccountId: number;

  @ApiModelProperty()
  productQuestionId: number;
}