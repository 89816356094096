import { Injectable } from '@angular/core';
import { ProductESDto } from '@interid/interid-site-shared';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductCardSource } from '../product-card.source';
import { BasketService } from '../../services/basket.service';
import { AppSessionBasketEntry } from '@interid/interid-site-web/core-session';

@Injectable()
export class ProductCardBasketHelper {
    constructor(private readonly source: ProductCardSource, private readonly i18n: TranslateService, private readonly basketService: BasketService) {}

    get product(): ProductESDto {
        return this.source.product;
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    addToBasket(): void {
        this.basketService
            .addProductToBasket({
                amount: this.product.priceStep ? parseFloat(this.product.priceStep) : 1,
                productId: this.product.id,
            })
            .pipe(
                tap(() => {
                    if (this.product) {
                        this.source.patchProduct({
                            inBasket: (this.product.inBasket || 0) + 1,
                        });
                    }
                })
            )
            .subscribe();
    }

    addToBasket$(): Observable<AppSessionBasketEntry> {
        return this.basketService
            .addProductToBasket({
                amount: this.product.priceStep ? parseFloat(this.product.priceStep) : 1,
                productId: this.product.id,
            })
            .pipe(
                tap(() => {
                    if (this.product) {
                        this.source.patchProduct({
                            inBasket: (this.product.inBasket || 0) + 1,
                        });
                    }
                })
            );
    }

    setBasketAmount(amount: number): Observable<AppSessionBasketEntry | void> {
        return this.basketService
            .setProductToBasket({
                setAmount: amount,
                productId: this.product.id,
            })
            .pipe(
                tap(() => {
                    if (this.product) {
                        this.source.patchProduct({
                            inBasket: amount,
                        });
                    }
                })
            );
    }

    get labelBasket(): string {
        return this.product.inBasket ? this.i18n.instant(this.t('inBasket'), { amount: this.product.inBasket }) : this.i18n.instant(this.t('addToBasket'));
    }

    get labelBasketShort(): string {
        return this.product.inBasket ? this.i18n.instant(this.t('inBasketShort'), { amount: this.product.inBasket }) : this.i18n.instant(this.t('addToBasketShort'));
    }
}
