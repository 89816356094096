import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductCategorySeoLinkShared } from '../shared/product-category-seo-link.shared';
import { SourceDefinition } from '../shared/common.shared';
import { SortOrderShared } from '../shared/sort-order.shared';

export class ProductCategorySeoLinkDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  isEnabled: boolean;

  @ApiModelProperty()
  isDisplayInCatalogMenu: boolean;

  @ApiModelProperty()
  productCategoryId: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  alt: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  source: SourceDefinition;

  @ApiModelProperty()
  placement: ProductCategorySeoLinkShared.Placement;
}
