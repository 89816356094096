import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { LocalSessionDestroyWebRequest, LocalSessionGetWebRequest, LocalSessionGetWebResponse, LocalSessionSetWebRequest, LocalSessionWebEndpoint, LocalSessionWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebLocalSessionDataAccess implements LocalSessionWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    get(request: LocalSessionGetWebRequest): Observable<LocalSessionGetWebResponse> {
        return this.http.post(LocalSessionWebEndpointPaths.get, request);
    }

    set(request: LocalSessionSetWebRequest): Observable<void> {
        return this.http.post(LocalSessionWebEndpointPaths.set, request);
    }

    destroy(request: LocalSessionDestroyWebRequest): Observable<void> {
        return this.http.post(LocalSessionWebEndpointPaths.destroy, request);
    }
}
