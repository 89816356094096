import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountShared, AccountSettingsShared } from '../shared/account.shared';
import { AccountAccessShared } from '../shared/account-access.shared';

export class AccountDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  email: string;

  @ApiModelProperty()
  isEmailVerified: boolean;

  @ApiModelProperty()
  isPhoneVerified: boolean;

  @ApiModelProperty()
  isCookiePolicyAccepted: boolean;

  @ApiModelProperty()
  phone: string;

  @ApiModelProperty()
  fullName: string;

  @ApiModelProperty()
  deliveryAddress: string;

  @ApiModelProperty()
  role: AccountShared.AccountRole;

  @ApiModelProperty()
  accessNodes: Array<AccountAccessShared.AccessNode>;

  @ApiModelProperty()
  cityId: number;

  @ApiModelProperty()
  settings: AccountSettingsShared.AccountSettingsDto;
}

export class AccountWebDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  email: string;

  @ApiModelProperty()
  isEmailVerified: boolean;

  @ApiModelProperty()
  isPhoneVerified: boolean;
  
  @ApiModelProperty()
  isCookiePolicyAccepted: boolean;

  @ApiModelProperty()
  phone: string;

  @ApiModelProperty()
  fullName: string;

  @ApiModelProperty()
  deliveryAddress: string;
}
