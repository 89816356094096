import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SortOrderShared } from '../shared/sort-order.shared';

export class ParamsCategoryDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;
  
  @ApiModelProperty()
  title: string;
}

export class ParamsCategoryListDto {
  @ApiModelProperty()
  id: number;
 
  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  title: string;
}
