import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductCategoryDto } from './product-category.dto';

export class FrontpageCategoryDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  productCategory: ProductCategoryDto;
}
