export const interidSiteWebCoreBreadcrumbsI18n = {
    __breadcrumbs: {
        index: 'Главная',
        about: 'О компании',
        privacy: 'Политика конфиденциальности',
        contacts: 'Контакты',
        delivery: 'Доставка, оплата, условия обмена и возврата товара',
        articles: 'Блог',
        solutions: 'Услуги и решения',
        catalog: 'Каталог',
        sales: 'Акции',
        discounted: 'Уцененный товар',
        markingkits: 'Комплект',
        dictionary: 'Словарь терминов',
        search: 'Результаты поиска “{{ queryString }}”',
        not_found: 'Страница не найдена',
        favorites: 'Избранное',
        viewed: 'Вы недавно смотрели',
        compares: 'Сравнение товаров',
        vendor: 'Производитель',
    },
};
