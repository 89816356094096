import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { JwtPayload, LOCAL_SESSION_DOCUMENT_COOKIE } from '@interid/interid-site-shared';
import { AppBusEvent, AppBusService } from './app-bus.service';
import { LocalStorageService } from './local-storage.service';
import { DocumentCookieService } from './document-cookie.service';

export type JWT = string;

export class NoJwtAvailableError extends Error {
    constructor() {
        super('JWT is not available yet');
    }
}

const LS_KEY_JWT = 'jwt';
const LS_KEY_JWT_PAYLOAD = 'jwt_payload';

const DC_KEY_JWT = 'jwt';
const DC_KEY_JWT_PAYLOAD = 'jwt_payload';

@Injectable({
    providedIn: 'root',
})
export class JwtService {
    private _jwt$: BehaviorSubject<JWT | undefined> = new BehaviorSubject<JWT | undefined>(undefined);
    private _jwtPayload$: BehaviorSubject<JwtPayload | undefined> = new BehaviorSubject<JwtPayload | undefined>(undefined);
    private _jwtVerified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly appBus: AppBusService,
        private readonly localStorage: LocalStorageService,
        private readonly documentCookie: DocumentCookieService,
    ) {
        this.bootstrapJwtFromLocalStorage();
        this.bootstrapJwtFromDocumentCookie();
    }

    bootstrapJwtFromLocalStorage(): void {
        try {
            if (isPlatformBrowser(this.platformId)) {
                const jwt = this.localStorage.get(LS_KEY_JWT);
                const jwtPayload = this.localStorage.get(LS_KEY_JWT_PAYLOAD);

                if (jwt && jwtPayload) {
                    this._jwtPayload$.next(JSON.parse(jwtPayload)); // Warning: DO NOT swap it
                    this._jwt$.next(jwt);
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }

    bootstrapJwtFromDocumentCookie(): void {
        try {
            if (this.documentCookie.check(DC_KEY_JWT) && this.documentCookie.check(DC_KEY_JWT_PAYLOAD)) {
                const jwt = this.documentCookie.get(DC_KEY_JWT);
                const jwtPayload = this.documentCookie.get(DC_KEY_JWT_PAYLOAD);

                if (jwt && jwtPayload) {
                    this._jwtPayload$.next(JSON.parse(jwtPayload)); // Warning: DO NOT swap it
                    this._jwt$.next(jwt);
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }

    get jwt(): JWT {
        if (! this.hasJwt()) {
            throw new NoJwtAvailableError();
        }

        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
        return this._jwt$.getValue();
    }

    get jwt$(): Observable<JWT | undefined> {
        return this._jwt$.asObservable();
    }

    get jwtVerified$(): Observable<boolean> {
        return this._jwtVerified$.asObservable();
    }

    get jwtPayload(): JwtPayload {
        if (! this.hasJwt()) {
            throw new NoJwtAvailableError();
        }

        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
        return this._jwtPayload$.getValue();
    }

    get jwtPayload$(): Observable<JwtPayload | undefined> {
        return this._jwtPayload$.asObservable();
    }

    setJwt(jwt: string, jwtPayload: JwtPayload): void {
        this._jwt$.next(jwt);
        this._jwtPayload$.next(jwtPayload);

        if (isPlatformBrowser(this.platformId)) {
            this.localStorage.set(LS_KEY_JWT, jwt);
            this.localStorage.set(LS_KEY_JWT_PAYLOAD, JSON.stringify(jwtPayload));
        }

        if (isPlatformBrowser(this.platformId)) {
            this.documentCookie.set(DC_KEY_JWT, jwt);
            this.documentCookie.set(DC_KEY_JWT_PAYLOAD, JSON.stringify(jwtPayload));
            this.documentCookie.delete(LOCAL_SESSION_DOCUMENT_COOKIE);
        }

        this.appBus.emit({
            type: AppBusEvent.SignedIn,
        });
    }

    hasJwt(): boolean {
        return this._jwt$.getValue() !== undefined;
    }

    markJwtAsVerified(): void {
        this._jwtVerified$.next(true);
    }

    markJwtAsUnverifiedOrOutdated(): void {
        this.destroy();
    }

    destroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.localStorage.set(LS_KEY_JWT, '');
            this.localStorage.set(LS_KEY_JWT_PAYLOAD, '');
        }

        this.documentCookie.delete(DC_KEY_JWT);
        this.documentCookie.delete(DC_KEY_JWT_PAYLOAD);

        this._jwt$.next(undefined);
        this._jwtPayload$.next(undefined);
        this._jwtVerified$.next(false);

        this.appBus.emit({
            type: AppBusEvent.SignedOut,
        });
    }
}
