import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-shared-loading-circle',
    templateUrl: './loading-circle.component.html',
    styleUrls: ['./loading-circle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingCircleComponent {
}
