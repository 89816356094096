import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CommonContentWebBundleShared } from '../models/shared/common-content.shared';
import { AboutDto } from '../models/entities/about.dto';

export enum AboutWebEndpointPaths {
    about = '/api/v1/web/about',
}

export interface AboutWebEndpoint {
    about(): Promise<AboutResponse> | Observable<AboutResponse>;
}

export class AboutResponse {
    @ApiModelProperty()
    dto: AboutDto;

    @ApiModelProperty()
    contentBundle: CommonContentWebBundleShared.Bundle;
}
