import { IsArray, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Min } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ImportantEventShared } from '../models/shared/important-event.shared';
import { ImportantEventDto } from '../models/entities/important-event.dto';

export enum ImportantEventEndpointPaths {
    list = '/api/v1/important-events/list',
}

export interface ImportantEventEndpoint {
    list(request: ImportantEventListRequest): Promise<ImportantEventListResponse> | Observable<ImportantEventListResponse>;
}

export class ImportantEventListRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(ImportantEventShared.Type),
        required: false,
    })
    @IsEnum(ImportantEventShared.Type, { each: true })
    @IsArray()
    @IsOptional()
    types?: Array<ImportantEventShared.Type>;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    dateFrom?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    dateTo?: string;
}

export class ImportantEventListResponse {
    @ApiModelProperty({
        type: ImportantEventDto,
        isArray: true,
    })
    entities: Array<ImportantEventDto>;

    @ApiModelProperty()
    total: number;
}
