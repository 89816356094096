import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsEmail, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryDto } from '../models/entities/delivery.dto';
import { AttachmentDto } from '../models/entities/attachment.dto';
import { DeliveryCostShared } from '../models/shared/delivery-cost.shared';
import { ProductESDto } from '../models/entities/product.dto';
import { AccountRequisitesShared } from '../models/shared/account-requisites.shared'; 

export enum PurchaseFlowWebEndpointPaths {
    products = '/api/v1/web/purchase-flow/products',
    deliveries = '/api/v1/web/purchase-flow/deliveries',
    uploadRequisites = '/api/v1/web/purchase-flow/uploadRequisites',
    getRequisites = '/api/v1/web/purchase-flow/getRequisites',
    orderCosts = '/api/v1/web/purchase-flow/orderCosts',
    createOrder = '/api/v1/web/purchase-flow/createOrder',
}

export interface PurchaseFlowWebEndpoint {
    products(request: PurchaseFlowProductsWebRequest): Promise<PurchaseFlowProductsWebResponse> | Observable<PurchaseFlowProductsWebResponse>;
    deliveries(request: DeliveriesWebRequest): Promise<PurchaseFlowDeliveriesWebResponse> | Observable<PurchaseFlowDeliveriesWebResponse>;
    uploadRequisites(file?: File | any): Promise<AttachmentDto> | Observable<AttachmentDto>;
    orderCosts(request: PurchaseFlowOrderCostsWebRequest): Promise<PurchaseFlowOrderCostsWebResponse> | Observable<PurchaseFlowOrderCostsWebResponse>;
    createOrder(request: PurchaseFlowCreateOrderWebRequest, ...args: any): Promise<PurchaseFlowCreateOrderResponse> | Observable<PurchaseFlowCreateOrderResponse>;
}

export class DeliveriesWebRequest {
    @ApiModelProperty() 
    @IsNotEmpty()
    hostname: string;
}

export class PurchaseFlowProductsWebRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;
}

export class PurchaseFlowProductsWebResponse {
    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    products: Array<ProductESDto>;
}

export class PurchaseFlowDeliveriesWebResponse {
    @ApiModelProperty({
        type: DeliveryDto,
        isArray: true,
    })
    variants: Array<DeliveryDto>;
}

export class PurchaseFlowOrderCostsWebRequestEntry {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    price: string;
}

export class PurchaseFlowOrderCostsWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    deliveryCostId?: number;

    @ApiModelProperty({
        type: PurchaseFlowOrderCostsWebRequestEntry,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    basket: Array<PurchaseFlowOrderCostsWebRequestEntry>;
}

export class PurchaseFlowOrderCostsWebResponse {
    @ApiModelProperty()
    canPerformPurchase: boolean;

    @ApiModelProperty()
    deliveryCost?: string | undefined;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DeliveryCostShared.CostType),
    })
    deliveryCostType?: DeliveryCostShared.CostType;

    @ApiModelProperty()
    productsCost: string;

    @ApiModelProperty({
        required: false,
    })
    productTaxesCost?: string;

    @ApiModelProperty()
    totalCost: string;
}

export class PurchaseFlowCreateOrderWebRequestEntry {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    price: string;
}

export class PurchaseFlowCreateOrderWebRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    deliveryCostId: number;

    @ApiModelProperty({
        type: PurchaseFlowCreateOrderWebRequestEntry,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    basket: Array<PurchaseFlowCreateOrderWebRequestEntry>;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    fullName: string;

    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    paymentMethodType?: AccountRequisitesShared.PaymentMethod;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    phone: string;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    requisitesAttachmentId?: number;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    deliveryAddress?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    entrance?: string;
 
    @ApiModelProperty()
    @IsString()
    @IsOptional()
    floor?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    room?: string;

    @ApiModelProperty() 
    customerType?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    deliveryCompanies?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    comment: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    subscribeToEmail: boolean;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    requisites?: AccountRequisitesShared.LegalRequisitesPayload;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    roistat?: string;

    @ApiModelProperty()
    sessionId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    createdfromurl?: string;
}

export class PurchaseFlowCreateOrderProductPurchasedEntry {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    price: string;
 
    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    manufacturerTitle: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    productCategoryTitle: string;
}

export class PurchaseFlowCreateOrderResponse {
    @ApiModelProperty()
    orderId: string;

    @ApiModelProperty({
        type: PurchaseFlowCreateOrderProductPurchasedEntry,
        isArray: true,
    })
    productsPurchased: Array<PurchaseFlowCreateOrderProductPurchasedEntry>;
}
