import { Injectable } from '@angular/core';
import { AnalyticsEndpoint, AnalyticsEndpointPaths, GoodsCategoryRequest, GoodsCategoryResponse, ProviderSetsResponse, ProvidersResponse, ReportsCategoryStatisticsRequest, ReportsCategoryStatisticsResponse } from '@interid/interid-site-shared';
import { InteridAnalyticsDataAccessService } from '@interid/interid-site-data-access/common';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridCmsAnalyticsDataAccess implements AnalyticsEndpoint {
    constructor(
        private readonly http: InteridAnalyticsDataAccessService,
    ) {}

    reportsCategoryStatistics(request: ReportsCategoryStatisticsRequest): Observable<ReportsCategoryStatisticsResponse> {
        return this.http.post(AnalyticsEndpointPaths.reportsCategoryStatistics, request);
    } 

    providerSets(): Observable<ProviderSetsResponse> {
        return this.http.get(AnalyticsEndpointPaths.providerSets);
    } 

    providers(): Observable<ProvidersResponse> {
        return this.http.get(AnalyticsEndpointPaths.providers);
    } 

    goodsCategory(request: GoodsCategoryRequest): Observable<GoodsCategoryResponse> {
        return this.http.post(AnalyticsEndpointPaths.goodsCategory, request);
    } 
}
