import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { BonusListWebRequest, BonusListWebResponse, BonusWebEndpoint, BonusWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebBonusDataAccess implements BonusWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    list(request: BonusListWebRequest): Observable<BonusListWebResponse> {
        return this.http.post(BonusWebEndpointPaths.list, request);
    }
}
