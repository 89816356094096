import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductCardHelper } from '../../helpers/product-card.helper';

@Component({
    selector: 'app-shared-product-card-assets-preview',
    templateUrl: './product-card-preview.component.html',
    styleUrls: ['./product-card-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardPreviewComponent {
    @Input() maxImageWidth: number;

    constructor(
        public readonly helper: ProductCardHelper,
    ) {
    }
}
