import { IsInt, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductSearchRequest, ProductSearchResponse } from '../endpoints/product.endpoint';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ManufacturerDto, ManufacturerListDto } from '../models/entities/manufacturer.dto';

export const MARKINGKITS_WEB_DEFAULT_PAGE_SIZE = 15;

export enum MarkingkitsWebEndpointPaths {
    markingkits = '/api/v1/web/favorite/markingkits',
    products = '/api/v1/web/favorite/markingkits/products',
}

export interface MarkingkitsWebEndpoint {
    markingkits(request: MarkingkitsWebRequest, req?: any): Promise<MarkingkitsWebResponse> | Observable<MarkingkitsWebResponse>;
    products(request: MarkingkitsProductsWebRequest): Promise<MarkingkitsProductsWebResponse> | Observable<MarkingkitsProductsWebResponse>;
}

export class MarkingkitsCategoryWebDto {
    @ApiModelProperty()
    total: number;

    @ApiModelProperty()
    category: ProductCategoryListDto;

    @ApiModelProperty({
        type: ManufacturerListDto,
        isArray: true,
    })
    manufacturers: Array<ManufacturerListDto>;
}

export class MarkingkitsWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    category?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    manufacturer?: string;
}

export class MarkingkitsWebResponse {
    @ApiModelProperty({
        required: false,
    })
    category?: MarkingkitsCategoryWebDto;

    @ApiModelProperty({
        required: false,
    })
    manufacturer?: ManufacturerDto;

    @ApiModelProperty({
        required: false,
        type: MarkingkitsCategoryWebDto,
        isArray: true,
    })
    categories?: Array<MarkingkitsCategoryWebDto>;
}

export class MarkingkitsProductsWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    category?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    manufacturer?: string;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    esSearchRequest?: ProductSearchRequest;
}

export class MarkingkitsProductsWebResponse {
    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty({
        required: false,
    })
    esResponse?: ProductSearchResponse;
}
