import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { RouteShared, RouteWebEndpoint, RouteWebEndpointPaths, RouteWebRequest } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebRouteDataAccess implements RouteWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    route(request: RouteWebRequest): Observable<RouteShared.RouteResolveResult> {
        return this.http.post(RouteWebEndpointPaths.route, request);
    }
}
