import { Observable } from 'rxjs';
import { IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsString, MaxLength, MinLength, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { JwtPayload } from '../common';
import { AccountSettingsShared, AccountShared } from '../models/shared/account.shared';
import { AccountDto } from '../models/entities/account.dto';

export enum AuthEndpointPaths {
    auth = '/api/v1/auth/auth',
    verify = '/api/v1/auth/verify',
    refreshToken = '/api/v1/auth/refreshToken',
    invalidateToken = '/api/v1/auth/invalidateToken',
    invalidateAllTokens = '/api/v1/auth/invalidateAllTokens',
}

// TODO: Fix endpoints
export interface AuthEndpoint {
    auth(request: AuthRequest, ...args: unknown[]): Promise<AuthResponse> | Observable<AuthResponse>;
    verify(request: VerifyRequest): Promise<VerifyResponse> | Observable<VerifyResponse>;
    invalidateToken(request: InvalidateTokenRequest): Promise<void> | Observable<void>;
    invalidateAllTokens(): Promise<void> | Observable<void>;
}

export class AuthRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    @IsEmail()
    email: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    @MinLength(AccountShared.V_PASSWORD_MIN)
    @MaxLength(AccountShared.V_PASSWORD_MAX)
    password: string;
}

export class AuthResponse {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    jwt: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @ValidateNested()
    jwtPayload: JwtPayload;

    @IsNotEmpty()
    @ApiModelProperty()
    @ValidateNested()
    accountSettings: AccountSettingsShared.AccountSettingsDto;
}

export class VerifyRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    jwt: string;
}

export class VerifyResponse {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsBoolean()
    success: boolean;

    @IsOptional()
    @ApiModelProperty()
    @ValidateNested()
    account?: AccountDto;
}

export class InvalidateTokenRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    jwt: string;
}
