import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlockFiles } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-files',
    templateUrl: './content-block-files.component.html',
    styleUrls: ['./content-block-files.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockFilesComponent {
    @Input() payload: ContentBlockFiles;
}
