import { IsArray, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Max, Min } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ES_MAX_SEARCH_RESULTS_SIZE } from '../models/constants/es.constants';
import { ProductESDto } from '../models/entities/product.dto';
import { ViewedDto } from '../models/entities/viewed.dto';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';

export enum ViewedEndpointWebPaths {
    add = '/api/v1/web/viewed/add',
    list = '/api/v1/web/viewed/list',
    destroy = '/api/v1/web/viewed/destroy',
    listProducts = '/api/v1/web/viewed/listProducts',
}

export interface ViewedWebEndpoint {
    add(request: ViewedAddRequest): Promise<ViewedDto> | Observable<ViewedDto>;
    list(request: ViewedListRequest): Promise<ViewedListResponse> | Observable<ViewedListResponse>;
    listProducts(request: ViewedListProductsRequest): Promise<Array<ProductESDto>> | Observable<Array<ProductESDto>>;
    destroy(): Promise<void> | Observable<void>;
}

export class ViewedAddRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;
}
  
export class ViewedListRequest {
    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @Min(1)
    @Max(ES_MAX_SEARCH_RESULTS_SIZE)
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    category?: string;
}

export class ViewedListCategoryDTO {
    @ApiModelProperty()
    category: ProductCategoryListDto;

    @ApiModelProperty()
    total: number;
}

export class ViewedListResponse {
    @ApiModelProperty()
    request: ViewedListRequest;

    @ApiModelProperty()
    total: number;

    @ApiModelProperty({
        type: ViewedDto,
        isArray: true,
    })
    viewed: Array<ViewedDto>;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    products: Array<ProductESDto>;

    @ApiModelProperty({
        type: ViewedListCategoryDTO,
        isArray: true,
    })
    productCategories: Array<ViewedListCategoryDTO>;
}
 
export class ViewedListProductsRequest {
    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;
}