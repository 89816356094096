import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { AppSessionService } from '@interid/interid-site-web/core-session';

const COUNTERS_KEY = '___user_counters';

@Injectable({
    providedIn: 'root',
})
export class UserCountersHttpInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
        private readonly appSession: AppSessionService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((response: HttpResponse<any>) => {
                if ((response instanceof HttpResponse) && !! response.body && !! response.body[COUNTERS_KEY]) {
                    this.router.events.pipe(
                        filter((e) => e instanceof NavigationEnd),
                        take(1),
                    ).subscribe(() => this.appSession.updateCounters(response.body[COUNTERS_KEY]));
                }
            }) as any,
        );
    }
}
