import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { ProductReviewShared } from '../models/shared/product-review.shared';
import { ProductReviewLikeDto } from '../models/entities/product-review-like.dto';

export enum ProductReviewsLikesWebEndpointPaths {
    list = '/api/v1/web/product-reviews-likes/list',
    like = '/api/v1/web/product-reviews-likes/like',
    setType = '/api/v1/web/product-reviews-likes/setType',
    unlike = '/api/v1/web/product-reviews-likes/unlike'
}

export interface ProductReviewsLikesWebEndpoint {
    like(request: CreateProductReviewLikeRequest): Promise<ListProductReviewLikeResponse> | Observable<ListProductReviewLikeResponse>;
    list(request: ListProductReviewLikeRequest): Promise<ListProductReviewLikeResponse> | Observable<ListProductReviewLikeResponse>;
    setType(request: UpdateProductReviewLikeRequest): Promise<ListProductReviewLikeResponse> | Observable<ListProductReviewLikeResponse>;
    unlike(request: DeleteProductReviewLikeRequest): Promise<ListProductReviewLikeResponse> | Observable<ListProductReviewLikeResponse>;
}

export class ListProductReviewLikeResponse {
    @ApiModelProperty()
    likes: Array<ProductReviewLikeDto>;
}

export class ListProductReviewLikeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productReviewId: number;
}

export class CreateProductReviewLikeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productReviewId: number;

    @ApiModelProperty()
    type: ProductReviewShared.LikeType;
}

export class UpdateProductReviewLikeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productReviewId: number;

    @ApiModelProperty()
    type: ProductReviewShared.LikeType;
}

export class DeleteProductReviewLikeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productReviewId: number;
}