<div class="c">
  @if (hasSiteMessage()) {
    <div class="c-message c-message-desktop" #message>
      <app-message></app-message>
    </div>
  }
  <div class="c-header">
    @if (layout() === 'wide' || layout() === 'desktop') {
      <app-header-wide></app-header-wide>
    }
    @if (layout() === 'mobile' || layout() === 'tablet') {
      <app-header-mobile></app-header-mobile>
    }
    @if (layout() === 'mobile') {
      <app-floatbar-mobile></app-floatbar-mobile>
    }
  </div>
  @if (hasSiteMessage()) {
    <div class="c-message c-message-mobile" #message>
      <app-message></app-message>
    </div>
  }
  <div class="c-contents">
    <router-outlet></router-outlet>
  </div>
  <div class="c-footer">
    @if (layout() !== 'mobile') {
      <app-footer-wide></app-footer-wide>
    }
    @if (layout() === 'mobile') {
      <app-footer-mobile></app-footer-mobile>
    }
  </div>
</div>