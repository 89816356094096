import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { FrontpageManufacturerDto } from '../models/entities/frontpage-manufacturer.dto';
import { CrudSetSortOrderRequestDto } from '../core/crud';
import { Observable } from 'rxjs';

export enum FrontpageManufacturerEndpointPaths {
    list = '/api/v1/frontpage-manufacturers/listFrontpage',
    create = '/api/v1/popula-manufacturers/createFrontpage',
    edit = '/api/v1/frontpage-manufacturers/editFrontpage',
    delete = '/api/v1/frontpage-manufacturers/deleteFrontpage',
    setSortOrder = '/api/v1/frontpage-manufacturers/setSortOrder',
}

export interface FrontpageManufacturerEndpoint {
    create(request: CreateFrontpageManufacturerRequest): Promise<FrontpageManufacturerDto> | Observable<FrontpageManufacturerDto>;
    edit(request: EditFrontpageManufacturerRequest): Promise<FrontpageManufacturerDto> | Observable<FrontpageManufacturerDto>;
    delete(request: DeleteFrontpageManufacturerRequest): Promise<void> | Observable<void>;
    list(request: ListFrontpageManufacturersRequest): Promise<ListFrontpageManufacturersResponse> | Observable<ListFrontpageManufacturersResponse>;
    setSortOrder(request: SetSortOrderFrontpageManufacturerRequest): Promise<void> | Observable<void>;
}

export class FrontpageManufacturerEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    manufacturerId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    imageAttachmentId: number;
}

export class ListFrontpageManufacturersRequest {
}

export type ListFrontpageManufacturersResponse = Array<FrontpageManufacturerDto>;

export class CreateFrontpageManufacturerRequest extends FrontpageManufacturerEntityBody {
}

export class EditFrontpageManufacturerRequest extends FrontpageManufacturerEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteFrontpageManufacturerRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderFrontpageManufacturerRequest extends CrudSetSortOrderRequestDto {}
