import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';
import { ProductQuestionShared } from '../shared/product-question.shared';

export class ProductQuestionPayload {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  categoryId: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  preview: AttachmentDto;
}

export class ProductQuestionDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  product: ProductQuestionPayload;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  processedAt?: string;

  @ApiModelProperty()
  responsedAt?: string;

  @ApiModelProperty()
  status: ProductQuestionShared.QuestionStatus;

  @ApiModelProperty({
    required: false,
  })
  author?: string;

  @ApiModelProperty()
  authorAccountId?: number;

  @ApiModelProperty({
    required: false,
  })
  email?: string;

  @ApiModelProperty()
  isAnonymous?: boolean;

  @ApiModelProperty()
  contents: string;

  @ApiModelProperty()
  response: string;
}
