import { MatDialogConfig } from '@angular/material/dialog';
import { OverlayConfig } from '@angular/cdk/overlay';

export const DEFAULT_MESSAGES_DURATION = 5000;
export const DEFAULT_MAX_COMMON_PARAMS = 5;

export const defaultModalConfig: MatDialogConfig = {
    panelClass: '__app-modal',
    backdropClass: '__app-modal-backdrop',
    closeOnNavigation: true,
    disableClose: true,
};

export const defaultOverlayConfig: OverlayConfig = {
    panelClass: '__app-overlay',
    backdropClass: '__app-overlay-backdrop',
};

export const defaultCityOverlayConfig: OverlayConfig = {
    panelClass: '__app-city-overlay',
    backdropClass: '__app-overlay-backdrop',
};
