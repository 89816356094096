import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsObject, IsPositive, IsString, Min, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ContentDTO } from '../models/shared/common-content.shared';
import { ArticleBlockDto, ArticleDto, ArticleListDto } from '../models/entities/article.dto';
import { PageMetadataSEO } from '../models/shared/common.shared';

export enum ArticleEndpointPaths {
    list = '/api/v1/articles/listArticles',
    listAll = '/api/v1/articles/listAllArticles',
    create = '/api/v1/articles/createArticle',
    edit = '/api/v1/articles/editArticle',
    delete = '/api/v1/articles/deleteArticle',
    publish = '/api/v1/articles/publishArticle',
    moveToDraft = '/api/v1/articles/moveToDraftArticle',
    getById = '/api/v1/articles/getById',
}

export interface ArticleEndpoint {
    create(request: CreateArticleRequest): Promise<ArticleDto> | Observable<ArticleDto>;
    edit(request: EditArticleRequest): Promise<ArticleDto> | Observable<ArticleDto>;
    delete(request: DeleteArticleRequest): Promise<void> | Observable<ArticleDto>;
    list(request: ListArticlesRequest): Promise<ListArticlesResponse> | Observable<ListArticlesResponse>;
    listAll(): Promise<ListAllArticlesResponse> | Observable<ListAllArticlesResponse>;
    publish(request: PublishArticleRequest): Promise<ArticleDto> | Observable<ArticleDto>;
    moveToDraft(request: MoveToDraftRequest): Promise<ArticleDto> | Observable<ArticleDto>;
    getById(request: GetByIdArticleRequest): Promise<ArticleDto> | Observable<ArticleDto>;
}

export class ArticleEntityRequestBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    articleCategoryId: number;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    articleAuthorIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    relatedProductCategoryIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    relatedProductIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    tagIds: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    alt: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uri: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    annotation: string;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    coverAttachmentId: number;

    @ApiModelProperty()
    @ValidateNested()
    @IsNotEmpty()
    contents: ContentDTO;

    @ApiModelProperty({
        type: ArticleBlockDto,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    blocks: Array<ArticleBlockDto>;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    enableComments: boolean;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    seo: PageMetadataSEO;
}

export class ListArticlesRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    articleCategoryId: number;
}

export class ListArticlesResponse {
    @ApiModelProperty()
    total: number;

    @ApiModelProperty({
        type: ArticleDto,
        isArray: true,
    })
    entities: Array<ArticleDto>;
}

export type ListAllArticlesResponse = Array<ArticleListDto>;

export class CreateArticleRequest extends ArticleEntityRequestBody {
}

export class EditArticleRequest extends ArticleEntityRequestBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteArticleRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class PublishArticleRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class MoveToDraftRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class GetByIdArticleRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

