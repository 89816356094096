import { SystemTaskDto } from '../../../../models/entities/system-task.dto';
import { SystemTaskShared } from '../../../../models/shared/system-task.shared';
import { Observable } from 'rxjs';

export enum SitemapEndpointPaths {
    runExport = '/api/v2/sitemap/runExport',
}

export interface SitemapEndpoint {
    runExport(): Promise<SiteMapRunExportResponse> | Observable<SiteMapRunExportResponse>;
}

export type SiteMapRunExportResponse = SystemTaskDto<SystemTaskShared.TaskType.SiteMap, SystemTaskShared.SimpleResponse>;
