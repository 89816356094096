<div class="c">
  <div class="c-sort-and-view">
    @if (shouldDisplaySeoLinks() && categoryTopSeoLinks() && (categoryId() || isVendorPage())) {
      <div class="c-header-links">
        <app-shared-catalog-category-seo-links
          [manufacturer]="manufacturer()"
          [isVendorPage]="isVendorPage()"
          [seoLinks]="categoryTopSeoLinks()"
          [categoryId]="categoryId()">
        </app-shared-catalog-category-seo-links>
      </div>
    }
    @if (shouldDisplaySeoLinks() && categoryTopSeoLinks() && (categoryId() || isVendorPage())) {
      <div class="c-delimiter"></div>
    }
    <app-shared-product-list-sort-and-view
      (filterScreen)="filterScreenEvent.emit($event)"
      [searchRequest]="searchRequest()"
      [blacklistSort]="blacklistSort()"
      (updateSearchRequest)="updateSearchRequestEvent.emit($event)">
    </app-shared-product-list-sort-and-view>
  </div>
  <div class="c-products">
    @if (products().length > 0) {
      <div class="products-list" [ngClass]="productListNgClass()">
        @for (product of products(); track trackById($index, product)) {
          <div class="product">
            @if (isCardView()) {
              <app-shared-product-card-g
                [compareTopbarVariant]="'default'"
                [withParametersBadges]="true"
                [variant]="'tiny'"
                [product]="product"
                [withBadges]="true"
                [withParams]="true"
                [withRating]="true"
                [withReviews]="true"
                [withBasket]="true">
              </app-shared-product-card-g>
            }
            @if (isListView()) {
              @if (isMobile() === false) {
                <app-shared-product-card-c
                  [product]="product"
                  [withBadges]="true"
                  [withParams]="true"
                  [withRating]="true"
                  [withReviews]="true"
                  [withBasket]="true">
                </app-shared-product-card-c>
              }
              @if (isMobile() === true) {
                <app-shared-product-card-c-mobile
                  [product]="product"
                  [withBadges]="true"
                  [withParams]="true"
                  [withRating]="true"
                  [withReviews]="true"
                  [withBasket]="true">
                </app-shared-product-card-c-mobile>
              }
            }
          </div>
        }
      </div>
    }
    @if (products().length === 0) {
      <div class="no-products">
        <div class="message">
          <span>{{ t('no_products') | translate }}</span>
        </div>
      </div>
    }
  </div>
  @if (hasMoreToLoad()) {
    <div class="c-show-more">
      <app-shared-ui-load-more (click)="loadMoreEvent.emit()" [disabled]="isLoading()"></app-shared-ui-load-more>
    </div>
  }
  <div class="c-paginator">
    <app-shared-paginator
      [page]="paginatorPage()"
      [pageSize]="paginatorPageSize()"
      [total]="paginatorTotal()"
      [urlGenerator]="paginatorUrlGenerator()"
      [canonicalUrlGenerator]="paginatorCanonicalUrlGenerator()"
      (next)="paginatorNextEvent.emit($event)"
      [shouldFollowUrl]="paginatorShouldFollowUrl()">
    </app-shared-paginator>
  </div>
</div>
