import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { MarkingkitsWebEndpoint, MarkingkitsWebRequest, MarkingkitsWebResponse, MarkingkitsWebEndpointPaths, MarkingkitsProductsWebRequest, MarkingkitsProductsWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebMarkingkitsDataAccess implements MarkingkitsWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    markingkits(request: MarkingkitsWebRequest): Observable<MarkingkitsWebResponse> {
        return this.http.post(MarkingkitsWebEndpointPaths.markingkits, request);
    }

    products(request: MarkingkitsProductsWebRequest): Observable<MarkingkitsProductsWebResponse> {
        return this.http.post(MarkingkitsWebEndpointPaths.products, request);
    }
}
