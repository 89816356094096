import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { MyOrdersListWebRequest, MyOrdersListWebResponse, MyOrdersViewWebRequest, MyOrdersViewWebResponse, MyOrdersWebEndpoint, MyOrdersWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebMyOrdersDataAccess implements MyOrdersWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    list(request: MyOrdersListWebRequest): Observable<MyOrdersListWebResponse> {
        return this.http.post(MyOrdersWebEndpointPaths.list, request);
    }

    view(request: MyOrdersViewWebRequest): Observable<MyOrdersViewWebResponse> {
        return this.http.post(MyOrdersWebEndpointPaths.view, request);
    }
}
