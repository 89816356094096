import { EnvironmentWeb } from '@interid/interid-site-web/core';

export const environment: EnvironmentWeb = {
    enableAngularDevelopmentMode: false,
    enableAngularRouterDebug: false,
    adminUrl: 'https://cms.interid.ru',
    webApiEndpoint: 'https://api.interid.ru',
    attachmentEndpoint: 'https://assets.interid.ru/uploaded',
    baseUrl: 'https://interid.ru',
    baseDomain: 'interid.ru',
    regionBaseDomain: 'interid.ru',
    baseDomainProtocol: 'https',
    enableSwitchCityDomain: true,
    enableSSRCache: true,
    ymId: 13196761,
    recaptcha: '6LeZojspAAAAAF6T-OTsJYXP6_TZ-qe1i0oKIcPU',
};
