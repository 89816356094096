<div class="c">
  <div class="c-menu-bar __app-fluid-container" [ngStyle]="{'position': (screen$ | async) !== 'none' ? 'fixed' : 'relative' }">
    <div class="c-menu-bar-left">
      <div class="icon" (click)="openSelectDialog()">
        <svg width="100%" height="100%" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.502 2.165a6.667 6.667 0 0 0-6.667 6.667c0 4.5 5.875 9.583 6.125 9.8a.833.833 0 0 0 1.083 0c.292-.217 6.125-5.3 6.125-9.8a6.667 6.667 0 0 0-6.666-6.667Zm0 14.708c-1.775-1.666-5-5.258-5-8.041a5 5 0 1 1 10 0c0 2.783-3.225 6.383-5 8.041Zm0-11.375a3.333 3.333 0 1 0 0 6.667 3.333 3.333 0 0 0 0-6.667Zm0 5a1.667 1.667 0 1 1 0-3.334 1.667 1.667 0 0 1 0 3.334Z" fill="#253238" /></svg>
      </div>
      <div class="icon-search" (click)="openCatalogMenu($event)"><img src="/assets/images/menu_search.svg" /></div>
    </div>
    <div class="c-menu-bar-center">
      <a class="c-menu-bar-logo" [routerLink]="['/']" (click)="close()">
        <div class="icon icon-101x24px">
          <svg width="121" height="30" viewBox="0 0 121 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.651 1.574a.924.924 0 0 1 1.184.887v26.591H0V3.626c0-.411.271-.773.665-.888l3.986-1.164Zm5.614 13.913c0-2.473-.064-4.551-.156-6.345h5.08l.268 2.758h.134a6.695 6.695 0 0 1 5.814-3.207c3.853 0 6.724 2.736 6.724 8.588v11.772h-5.856v-11.04c0-2.57-.847-4.32-2.963-4.32-1.623 0-2.583 1.181-3.006 2.31-.155.404-.197.964-.197 1.555v11.488h-5.835V15.487h-.007ZM37.368 3.73a.924.924 0 0 1 1.184.886V9.15h4.184v4.552h-4.184v7.22c0 2.407.578 3.513 2.314 3.513.804 0 1.2-.045 1.736-.164l.042 4.694c-.734.284-2.16.515-3.76.515-1.892 0-3.45-.687-4.432-1.719-1.072-1.18-1.622-3.094-1.622-5.897v-8.17h-2.498v-4.55h2.498V5.75c0-.41.27-.772.665-.887l3.873-1.134Zm12.5 17.355c.155 2.57 2.582 3.797 5.298 3.797 2.004 0 3.606-.284 5.186-.853l.776 4.201c-1.912.852-4.275 1.27-6.815 1.27-6.393 0-10.047-3.916-10.047-10.142 0-5.052 2.963-10.643 9.51-10.643 6.082 0 8.375 5 8.375 9.933 0 1.061-.113 2.003-.197 2.452l-12.086-.058v.043Zm6.879-4.253c0-1.51-.621-4.036-3.316-4.036-2.448 0-3.45 2.407-3.606 4.036h6.922Zm8.353-1.128c0-2.923-.042-4.836-.155-6.563h4.263c.408 0 .745.318.77.725l.181 2.93h.156c.96-2.922 3.231-4.155 5.08-4.155.536 0 .804.044 1.227.12v5.83c-.466-.068-.91-.165-1.58-.165-2.16 0-3.606 1.225-4.008 3.139a9.188 9.188 0 0 0-.113 1.39v10.098h-5.835v-13.35h.014Zm21.088-.837c-.096-.752-.144-1.127-.027-1.419a1.2 1.2 0 0 1 .532-.603c.274-.154.653-.154 1.41-.154h3.455c.76 0 1.14 0 1.415.154.24.135.428.349.53.606.118.292.068.669-.03 1.422L91.626 28.95h-3.639l-1.8-14.082Zm10.9-12.847c2.159-.382 4.967-.614 7.881-.614 4.967 0 8.198.964 10.646 2.923 2.696 2.122 4.389 5.493 4.389 10.359 0 5.262-1.806 8.872-4.276 11.114-2.737 2.407-6.928 3.535-12.001 3.535-3.027 0-5.235-.209-6.66-.403V2.019h.02Zm5.834 22.362c.487.12 1.334.12 2.047.12 5.348.045 8.84-3.065 8.84-9.627 0-5.71-3.161-8.73-8.22-8.73-1.27 0-2.159.12-2.652.24V24.39h-.015v-.008Z" fill="#253238" />
            <circle cx="89.843" cy="5.189" r="3.741" fill="#1269FF" />
          </svg>
        </div>
      </a>
    </div>
    <div class="c-menu-bar-right">
      <a href="mailto:info@interid.ru">
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.333 0A3.333 3.333 0 0 0 0 3.333v.335l10 5.385L20 3.67v-.337A3.333 3.333 0 0 0 16.667 0H3.333ZM20 5.562l-9.605 5.171a.833.833 0 0 1-.79 0L0 5.562v7.771a3.334 3.334 0 0 0 3.333 3.334h13.334A3.333 3.333 0 0 0 20 13.333V5.562Z" fill="#1269FF" /></svg>
      </a>
      <a href="tel:+74956737023">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#a)"><path d="M7.375 1.535c.54.332.766 1.047.531 1.668l-1.101 2.89c-.207.548-.723.88-1.266.821l-1.664-.18a10.727 10.727 0 0 0 0 6.536l1.664-.18c.543-.059 1.059.273 1.266.82l1.101 2.887c.239.621.012 1.336-.53 1.668l-2.204 1.36c-.484.3-1.094.21-1.488-.215-4.91-5.31-4.91-13.91 0-19.22A1.177 1.177 0 0 1 5.176.177l2.203 1.36h-.004Zm8.531-.215A11.228 11.228 0 0 1 20 10a11.24 11.24 0 0 1-4.09 8.68.937.937 0 1 1-1.195-1.445A9.356 9.356 0 0 0 18.125 10a9.356 9.356 0 0 0-3.41-7.234.937.937 0 1 1 1.195-1.445h-.004Zm-2.515 2.79A7.483 7.483 0 0 1 16.25 10a7.49 7.49 0 0 1-2.856 5.89.938.938 0 0 1-1.16-1.472A5.62 5.62 0 0 0 14.375 10a5.62 5.62 0 0 0-2.14-4.418.938.938 0 0 1 1.16-1.473h-.004Zm-2.555 2.773a3.753 3.753 0 0 1 .004 6.23.937.937 0 1 1-1.047-1.554 1.874 1.874 0 0 0 0-3.117.937.937 0 1 1 1.047-1.555l-.004-.004Z" fill="#69A042" /></g>
          <defs>
            <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
          </defs>
        </svg>
      </a>
    </div>
  </div>
  @if ((shouldDisplayMenuContent$ | async) === true) {
    <div class="c-menu-contents">
      @if ((screen$ | async) === 'main') {
        <app-header-mobile-main (close)="close()" (city)="goToCityScreen()"></app-header-mobile-main>
      }
      @if ((screen$ | async) === 'catalog') {
        <app-header-mobile-catalog (close)="close()"></app-header-mobile-catalog>
      }
      @if ((screen$ | async) === 'search') {
        <app-header-mobile-search (close)="close()"></app-header-mobile-search>
      }
    </div>
  }
</div>
