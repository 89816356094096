export const interidSiteWebFeatureMarkingkitsI18n = {
    markingkits: {
        components: {
            markingkits: {
                title: 'Комплект',
                category: 'Категория'
            },
        },
    },
};
