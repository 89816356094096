<form [formGroup]="sliderControls">
  <div class="price-slider-value-container">
    <div class="ws-input-text-field ws-input-text-field__floating">
      <input
        id="from"
        type="text"
        maxlength="10"
        class="ws-text-field__input"
        [class.text-field__warning]="warnRangeMin()"
        [disabled]="disabled()"
        formControlName="valueStart"
        (input)="onInput($event, 'start')"
        (keypress)="numberOnly($event)"
      />
      <label for="from" class="ws-text-field__label"><span>{{ t('price.min') | translate }}</span></label>
    </div>
    <div class="ws-input-text-field ws-input-text-field__floating ">
      <input
        id="to"
        type="text"
        maxlength="10"
        class="ws-text-field__input"
        [class.text-field__warning]="warnRangeMax()"
        [disabled]="disabled()"
        formControlName="valueEnd"
        (input)="onInput($event, 'end')"
        (keypress)="numberOnly($event)"
      />
      <label for="to" class="ws-text-field__label"><span>{{ t('price.max') | translate }}</span></label>
    </div>
  </div>
  <div class="price-slider-container">
    @if (! hideSlider()) {
      <ws-slider
        [min]="options().min"
        [max]="options().max"
        [step]="options().step"
        [disabled]="disabled()"
      >
        <input
          wsSliderStart
          #rangeSliderTo
          formControlName="sliderStart"
          (valueChange)="onChange()"
          (input)="onInputSlider($event, 'start')"
          (dragStart)="dragStart()"
        />
        <input
          wsSliderEnd
          #rangeSliderFrom
          formControlName="sliderEnd"
          (valueChange)="onChange()"
          (input)="onInputSlider($event, 'end')"
          (dragStart)="dragEnd()"
        />
      </ws-slider>
    }
  </div>
</form>