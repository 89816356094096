import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class FavoriteDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  productId: number;

  @ApiModelProperty()
  dateCreatedAt: string;
}
