import { IsInt, IsNotEmpty, IsPositive, IsString, Min } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SystemTaskShared } from '../models/shared/system-task.shared';
import { SystemTaskDto } from '../models/entities/system-task.dto';

export enum SystemTasksEndpointPaths {
    list = '/api/v1/system-tasks/list',
    get = '/api/v1/system-tasks/get',
    run = '/api/v1/system-tasks/run',
    interrupt = '/api/v1/system-tasks/interrupt',
    delete = '/api/v1/system-tasks/delete',
}

export interface SystemTasksEndpoint {
    list(request: SystemTaskListRequest): Promise<SystemTaskListResponse> | Observable<SystemTaskListResponse>;
    getTask<T extends SystemTaskShared.TaskType, R extends SystemTaskShared.Response>(request: SystemTaskGetRequest): Promise<SystemTasksGetResponse<T, R>> | Observable<SystemTasksGetResponse<T, R>>;
    run<T extends SystemTaskShared.TaskType, R extends SystemTaskShared.Response>(request: SystemTaskRunRequest): Promise<SystemTasksRunResponse<T, R>> | Observable<SystemTasksRunResponse<T, R>>;
    interrupt<T extends SystemTaskShared.TaskType, R extends SystemTaskShared.Response>(request: SystemTaskInterruptRequest): Promise<SystemTasksInterruptResponse<T, R>> | Observable<SystemTasksInterruptResponse<T, R>>;
    delete(request: SystemTaskDeleteRequest): Promise<void> | Observable<void>;
}

export class SystemTaskGetRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    taskId: SystemTaskShared.TaskId;
}

export type SystemTasksGetResponse<T extends SystemTaskShared.TaskType, R extends SystemTaskShared.Response> = SystemTaskDto<T, R>;

export class SystemTaskListRequest {
    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;
}

export class SystemTaskListResponse {
    @ApiModelProperty()
    total: number;

    @ApiModelProperty({
        type: SystemTaskDto,
        isArray:  true,
    })
    tasks: Array<SystemTaskDto>;
}

export class SystemTaskRunRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    taskId: SystemTaskShared.TaskId;
}

export type SystemTasksRunResponse<T extends SystemTaskShared.TaskType, R extends SystemTaskShared.Response> = SystemTaskDto<T, R>;

export class SystemTaskInterruptRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    taskId: SystemTaskShared.TaskId;
}

export type SystemTasksInterruptResponse<T extends SystemTaskShared.TaskType, R extends SystemTaskShared.Response> = SystemTaskDto<T, R>;


export class SystemTaskDeleteRequest {
    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    taskId: SystemTaskShared.TaskId;
}
