import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO, SourceDefinition } from '../shared/common.shared';
import { ArticleCategoryDto } from './article-category.dto';
import { ContentDTO } from '../shared/common-content.shared';
import { AttachmentDto } from './attachment.dto';
import { ArticleAuthorDto } from './article-author.dto';
import { TagDto } from './tag.dto';
import { ProductCategoryDto } from './product-category.dto';
import { ProductDto } from './product.dto';
import { ArticleShared } from '../shared/article.shared';

export class ArticleListDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  isPublished: boolean;

  @ApiModelProperty()
  articleCategoryId: number;

  @ApiModelProperty()
  articleCategoryUri: string;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  dateCreatedAt: string;

  @ApiModelProperty()
  numComments: number;

  @ApiModelProperty()
  cover: AttachmentDto;
}

export class ArticleBlockDto {
  @ApiModelProperty()
  sid: string;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  uriSource: SourceDefinition;

  @ApiModelProperty()
  image: AttachmentDto;
}

export class ArticleDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  dateCreatedAt: string;

  @ApiModelProperty()
  dateUpdatedAt: string;

  @ApiModelProperty()
  datePublishedAt: string;

  @ApiModelProperty()
  numComments: number;

  @ApiModelProperty()
  hits: number;

  @ApiModelProperty()
  likesCount: number;

  @ApiModelProperty()
  articleAuthors: Array<ArticleAuthorDto>;

  @ApiModelProperty()
  articleTags: Array<TagDto>;

  @ApiModelProperty()
  articleCategory: ArticleCategoryDto;

  @ApiModelProperty()
  relatedProductCategories: Array<ProductCategoryDto>;

  @ApiModelProperty()
  relatedProducts: Array<ProductDto>;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  alt: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  annotation: string;

  @ApiModelProperty()
  cover: AttachmentDto;

  @ApiModelProperty({
    type: () => ContentDTO,
  })
  contents: ContentDTO;

  @ApiModelProperty()

  blocks: Array<ArticleBlockDto>;

  @ApiModelProperty()
  status: ArticleShared.ArticleStatus;

  @ApiModelProperty()
  enableComments: boolean;

  @ApiModelProperty({
    type: () => PageMetadataSEO,
  })
  seo: PageMetadataSEO;
}

export class ArticleESDto extends ArticleDto {

  @ApiModelProperty()
  numComments: number;

  @ApiModelProperty()
  articleCategoryTitle: string;
}
