import { ElementRef, Injectable, ViewContainerRef } from '@angular/core';
import { PaginatorService } from '../paginator.service';
import { Overlay } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { PaginatorPopupComponent } from './paginator-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewBreakpointsService } from '@interid/interid-site-web/core';
import { ViewBreakpointsShared } from '@interid/interid-site-shared';
import { defaultModalConfig, defaultOverlayConfig } from '@interid/interid-site-web/core';

interface OpenRequest {
    vcr: ViewContainerRef;
    trigger: ElementRef<HTMLDivElement>;
    onPage: (page: number) => void;
}

@Injectable()
export class PaginatorPopupService {
    constructor(
        public readonly service: PaginatorService,
        private readonly overlay: Overlay,
        private readonly matDialog: MatDialog,
        private readonly viewBreakpoints: ViewBreakpointsService,
    ) {
    }

    open(request: OpenRequest): void {
        if ([ViewBreakpointsShared.Layout.Desktop, ViewBreakpointsShared.Layout.Wide, ViewBreakpointsShared.Layout.Tablet].includes(this.viewBreakpoints.currentLayout)) {
            this.openOverlay(request);
        } else {
            this.openMatDialog(request);
        }
    }

    openOverlay(request: OpenRequest): void {
        const detach$: Subject<void> = new Subject<void>();

        const overlay = this.overlay.create({
            ...defaultOverlayConfig,
            positionStrategy: this.overlay.position()
                .flexibleConnectedTo(request.trigger)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'top',
                        offsetX: 0,
                        offsetY: -10,
                        overlayX: 'center',
                        overlayY: 'bottom',
                    },
                ]),
            scrollStrategy: this.overlay.scrollStrategies
                .reposition({
                    autoClose: true,
                    scrollThrottle: 0,
                }),
            hasBackdrop: true,
            disposeOnNavigation: true,
        });

        const componentPortal = new ComponentPortal(PaginatorPopupComponent, request.vcr);
        const overlayRef = overlay.attach(componentPortal);

        overlayRef.instance.nextEvent.pipe(
            takeUntil(detach$),
        ).subscribe((page) => {
            request.onPage(page);
        });

        overlayRef.instance.closeEvent.pipe(
            takeUntil(detach$),
        ).subscribe(() => {
            detach$.next();
            overlay.detach();
        });

        overlay.backdropClick().pipe(
            takeUntil(detach$),
        ).subscribe(() => {
            detach$.next();
            overlay.detach();
        });
    }

    openMatDialog(request: OpenRequest): void {
        const detach$: Subject<void> = new Subject<void>();

        const matDialogRef = this.matDialog.open(PaginatorPopupComponent, {
            ...defaultModalConfig,
            viewContainerRef: request.vcr,
        });

        matDialogRef.componentInstance.closeEvent.pipe(
            takeUntil(detach$),
        ).subscribe(() => matDialogRef.close());

        matDialogRef.componentInstance.nextEvent.pipe(
            takeUntil(detach$),
        ).subscribe((page) => request.onPage(page));

        matDialogRef.afterClosed().pipe(
            takeUntil(detach$),
        ).subscribe(() => detach$.next());
    }
}
