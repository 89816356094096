import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductTransactionShared } from '../shared/product-transaction.shared';
import { SystemTaskShared } from '../shared/system-task.shared';
import { SystemTaskDto } from './system-task.dto';
import { AccountDto } from './account.dto';

export class ProductTransactionDto {

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  dateCreatedAt: string;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty({
    type: 'string',
    enum: Object.values(ProductTransactionShared.Status),
  })
  status: ProductTransactionShared.Status;

  @ApiModelProperty({
    type: () => AccountDto,
    isArray: true,
  })
  collaborators: Array<AccountDto>;

  @ApiModelProperty()
  systemTask?: SystemTaskDto<SystemTaskShared.TaskType.CommitProductTransaction | SystemTaskShared.TaskType.RollbackProductTransaction, SystemTaskShared.MultiEntriesResponse>;
}
