import { ModuleWithProviders, NgModule } from '@angular/core';
import { InteridDataAccessConfig } from './interid-site-data-access-common.config';

interface ForRootOptions {
    injects: Array<any>;
    useFactory: (...args: Array<any>) => InteridDataAccessConfig;
}

export { ForRootOptions as BazaNgEndpointModuleForRootOptions };

// @dynamic
@NgModule({
    providers: [],
})
export class InteridDataAccessModule
{
    static forRoot(options: ForRootOptions): ModuleWithProviders<InteridDataAccessModule> {
        return {
            ngModule: InteridDataAccessModule,
            providers: [{
                provide: InteridDataAccessConfig,
                useFactory: (...injects: any[]) => options.useFactory(...injects),
            }],
        };
    }
}
