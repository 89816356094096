import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { PaginatorService } from './../paginator.service';

@Component({
    templateUrl: './paginator-popup.component.html',
    styleUrls: ['./paginator-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorPopupComponent {
    @Output('next') nextEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output('close') closeEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public readonly service: PaginatorService,
    ) {
    }

    i18n(key: string): string {
        return `shared.components.paginator.${key}`;
    }

    get pages(): Array<number> {
        const result: Array<number> = [];

        for (let i = 1; i <= this.service.maxPage; i++) {
            result.push(i);
        }

        return result;
    }

    isActive(page: number): boolean {
        return this.service.state.page === page;
    }

    goPage($event: MouseEvent, page: number): void {
        if (! this.service.state.shouldFollowUrl) {
            $event.preventDefault();
            $event.stopPropagation();

            this.service.state = {
                ...this.service.state,
                page,
            };

            this.nextEvent.next(page);
            this.closeEvent.emit();
        }
    }

    close(): void {
        this.closeEvent.emit();
    }
}
