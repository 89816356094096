import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export namespace Product1CShared {
  export class InvalidUUID {
    @ApiModelProperty()
    product: string;

    @ApiModelProperty()
    uuid: string;
  }

  export class InvalidManufacturer extends InvalidUUID{
    @ApiModelProperty()
    manufacturer: string;
  }

  export class InvalidProductCategoryId extends InvalidUUID{
    @ApiModelProperty()
    productCategoryId: number;
  }

  export class ValidatioResults {
    @ApiModelProperty()
    isValid: boolean;

    @ApiModelProperty({
      required: false,
    })
    invalidUUIDs: Array<InvalidUUID>;

    @ApiModelProperty({
      required: false,
    })
    invalidManufacturers: Array<InvalidManufacturer>;

    @ApiModelProperty({
      required: false,
    })
    invalidProductCategoryIds: Array<InvalidProductCategoryId>;
  }
}
