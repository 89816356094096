import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class VacancyDto {
  static LAST_DTO_VERSION = '1';

  @ApiModelProperty()
  DTO_VERSION: string = VacancyDto.LAST_DTO_VERSION;

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  salary: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  tags: Array<string>;
}
