import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';
import { CurrencyDto } from './currency.dto';

export class BillDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  orderId: number;

  @ApiModelProperty()
  attachment: AttachmentDto;

  @ApiModelProperty()
  amount: number;

  @ApiModelProperty()
  currency: CurrencyDto;
}
