import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ArticleHitWebRequest, ArticlesPageResponse, ArticlesPagesRequest, ArticlesTagsResponse, ArticleWebEndpoint, ArticleWebEndpointPaths, ArticleWebGetByIdRequest, ArticleWebGetByIdResponse, ArticleWebGetBySlugRequest, ArticleWebGetBySlugResponse, ArticleWebSearchRequest, ArticleWebSearchResponse, CreateArticleReviewRequest } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebArticleDataAccess implements ArticleWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    tags(): Observable<ArticlesTagsResponse> {
        return this.http.post(ArticleWebEndpointPaths.tags);
    }

    search(request: ArticleWebSearchRequest): Observable<ArticleWebSearchResponse> {
        return this.http.post(ArticleWebEndpointPaths.search, request);
    }

    getById(request: ArticleWebGetByIdRequest): Observable<ArticleWebGetByIdResponse> {
        return this.http.post(ArticleWebEndpointPaths.getById, request);
    }

    getBySlug(request: ArticleWebGetBySlugRequest): Observable<ArticleWebGetBySlugResponse> {
        return this.http.post(ArticleWebEndpointPaths.getBySlug, request);
    }

    articlesPage(request: ArticlesPagesRequest): Observable<ArticlesPageResponse> {
        return this.http.post(ArticleWebEndpointPaths.articlesPage, request);
    }

    comment(request: CreateArticleReviewRequest): Observable<void> {
        return this.http.post(ArticleWebEndpointPaths.comment, request);
    }

    hit(request: ArticleHitWebRequest): Observable<void> {
        return this.http.post(ArticleWebEndpointPaths.hit, request);
    }
}
