import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { RoistatQuotationRequestContext } from '../models/shared/roistat.shared';

export enum QuotationRequestWebEndpointPaths {
    send = '/api/v1/web/quotation-request/send',
}

export interface QuotationRequestWebEndpoint {
    send(request: QuotationRequestSendRequest, ...args: any): Promise<QuotationRequestSendResponse> | Observable<QuotationRequestSendResponse>;
}

export class QuotationRequestSendResponse {
    @ApiModelProperty()
    id?: number;
}

export class QuotationRequestSendRequest {
    @ApiModelProperty()
    @IsOptional()
    createdFromURL?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    phone: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    name?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    email?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    message?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    inn?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(RoistatQuotationRequestContext),
        required: false,
    })
    @IsEnum(RoistatQuotationRequestContext)
    @IsString()
    @IsOptional()
    context?: RoistatQuotationRequestContext;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    roistat?: string;

    @ApiModelProperty()
    sessionId?: number;

    @ApiModelProperty()
    productId?: number;
}
