import { Injectable } from '@angular/core';
import { ProductESDto } from '@interid/interid-site-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { InteridWebProductReviewsDataAccess } from '@interid/interid-site-data-access/web';
import { ApiErrorHandlerService, MessagesService } from '@interid/interid-site-web/core';
import { ProductCardSource } from '../product-card.source';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable()
export class ProductCardReviewHelper {
    constructor(
        private readonly source: ProductCardSource,
        private readonly productReviewEndpoint: InteridWebProductReviewsDataAccess,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly messagesService: MessagesService,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
    ) {
    }

    get product(): ProductESDto {
        return this.source.product;
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    get hasRating(): boolean {
        return this.product.rating !== undefined && this.product.ratingCount > 0;
    }

    sendProductReview(request: {
        rating: number;
        negative: string;
        positive: string;
        contents: string;
        author?: string;
        email?: string;
        isAnonymous: boolean;
        contentAttachmentIds?: Array<number>;
    }): Observable<void> {
        return this.recaptchaV3Service.execute('QuotationRequest').pipe(
            switchMap((recaptchaV3Token) => this.productReviewEndpoint.review(
                request.isAnonymous
                    ? {
                        rating: request.rating,
                        productId: this.product.id,
                        negative: request.negative,
                        positive: request.positive,
                        contents: request.contents,
                        isAnonymous: true,
                        contentAttachmentIds: request.contentAttachmentIds || [],
                    }
                    : {
                        rating: request.rating,
                        productId: this.product.id,
                        author: request.author,
                        email: request.email
                            ? request.email
                            : undefined,
                        negative: request.negative,
                        positive: request.positive,
                        contents: request.contents,
                        isAnonymous: false,
                        contentAttachmentIds: request.contentAttachmentIds || [],
                    }, recaptchaV3Token
            ).pipe(
                catchError((err) => {
                    this.errorHandler.handle(err);

                    return throwError(err);
                }),
                map(() => undefined),
                tap(() => this.messagesService.info({
                    message: this.t('sendReviewSuccess'),
                    translate: true,
                })),
            ))
        );
    }
}
