import { Observable } from 'rxjs';
import { IsBoolean, IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, Min, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SystemTaskShared } from '../models/shared/system-task.shared';
import { Source, SourceDefinition } from '../models/shared/common.shared';
import { SystemTaskDto } from '../models/entities/system-task.dto';
import { RedirectDto } from '../models/entities/redirect.dto';
import { RedirectShared } from '../models/shared/redirect.shared';

export enum RedirectEndpointPaths {
    listRedirects = '/api/v1/redirects/listRedirects',
    createRedirect = '/api/v1/redirects/createRedirect',
    editRedirect = '/api/v1/redirects/editRedirect',
    deleteRedirect = '/api/v1/redirects/deleteRedirect',
    startCleanUp = '/api/v1/redirects/startCleanUp',
    resolve = '/api/v1/redirects/resolve',
}

export interface RedirectEndpoint {
    createRedirect(request: CreateRedirectRequest): Promise<RedirectDto> | Observable<RedirectDto>;
    editRedirect(request: EditRedirectRequest): Promise<RedirectDto> | Observable<RedirectDto>;
    deleteRedirect(request: DeleteRedirectRequest): Promise<void> | Observable<void>;
    listRedirects(request: ListRedirectsRequest): Promise<ListRedirectsResponse> | Observable<ListRedirectsResponse>;
    startCleanUp(): Promise<StartCleanUpResponse> | Observable<StartCleanUpResponse>;
    resolve(request: RedirectResolveRequest): Promise<RedirectResolveResponse> | Observable<RedirectResolveResponse>;
}

export class RedirectEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    url: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    canonicalUrl?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsObject()
    @IsOptional()
    canonicalUrlSource?: SourceDefinition;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    replaceUrl: string;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    urlSource: SourceDefinition;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    replaceUrlSource: SourceDefinition;
}

export class ListRedirectsRequest {
    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    search?: string;

    @ApiModelProperty({
        description: 'Strict search',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    searchExactly?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    sort?: RedirectShared.SortQuery;

    @ApiModelProperty({
        required: false,
        type: 'string',
        enum: Object.values(Source),
    })
    @IsEnum(Source)
    @IsOptional()
    urlType?: Source;
}

export class ListRedirectsResponse {
    @ApiModelProperty()
    entities: Array<RedirectDto>;

    @ApiModelProperty()
    total: number;
}

export class CreateRedirectRequest extends RedirectEntityBody {
}

export class EditRedirectRequest extends RedirectEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteRedirectRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type StartCleanUpResponse = SystemTaskDto<SystemTaskShared.TaskType.RedirectsCleanUp, SystemTaskShared.MultiEntriesResponse>;

export class RedirectResolveRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    url: string;
}

export class RedirectResolveResponse {
    redirects: Array<RedirectDto>;
}
