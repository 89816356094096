import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { CreateProductQuestionLikeRequest, DeleteProductQuestionLikeRequest, ListProductQuestionLikeRequest, ListProductQuestionLikeResponse, ProductQuestionsLikesWebEndpoint, ProductQuestionsLikesWebEndpointPaths, UpdateProductQuestionLikeRequest } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebProductQuestionsLikesDataAccess implements ProductQuestionsLikesWebEndpoint {
    constructor(private readonly http: InteridDataAccessService) {}
    list(request: ListProductQuestionLikeRequest): Observable<ListProductQuestionLikeResponse> {
        return this.http.post(ProductQuestionsLikesWebEndpointPaths.list, request);
    }
    like(request: CreateProductQuestionLikeRequest): Observable<ListProductQuestionLikeResponse> {
        return this.http.post(ProductQuestionsLikesWebEndpointPaths.like, request);
    }
    setType(request: UpdateProductQuestionLikeRequest): Observable<ListProductQuestionLikeResponse> {
        return this.http.post(ProductQuestionsLikesWebEndpointPaths.setType, request);
    }
    unlike(request: DeleteProductQuestionLikeRequest): Observable<ListProductQuestionLikeResponse> {
        return this.http.post(ProductQuestionsLikesWebEndpointPaths.unlike, request);
    }
}
