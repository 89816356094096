import { IsArray, IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductReviewDto } from '../entities/product-review.dto';

export namespace ProductReviewShared {
    export enum ReviewStatus {
        NotProcessed = 'NotProcessed',
        Approved = 'Approved',
        Rejected = 'Rejected',
    }

    export class SearchFilterRequest {
        @IsEnum(ReviewStatus, { each: true })
        @IsArray()
        @IsNotEmpty()
        @ApiModelProperty()
        statuses: Array<ReviewStatus>;

        @IsInt({ each: true })
        @IsPositive({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        productIds?: Array<number>;

        @IsInt({ each: true })
        @IsPositive({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        productCategoryIds?: Array<number>;
    }

    export enum LikeType {
        Positive = 'Positive',
        Negative = 'Negative'
    }

    export enum SearchSortField {
        ElasticScore = '_score',
        Id = 'Id',
        DateCreatedAt = 'DateCreatedAt',
        DateProcessedAt = 'DateProcessedAt',
        Product = 'Product',
        Author = 'Author',
        Status = 'Status',
    }

    export enum SearchSortDirection {
        Asc = 'Asc',
        Desc = 'Desc',
    }

    export class SearchSort {
        @IsEnum(SearchSortField)
        @IsNotEmpty()
        @ApiModelProperty()
        field: SearchSortField;

        @IsEnum(SearchSortDirection)
        @IsNotEmpty()
        @ApiModelProperty()
        direction: SearchSortDirection;
    }

    export class CountRequest {
        @IsString()
        @IsOptional()
        @ApiModelProperty()
        queryString?: string;
    }

    export class SearchRequest {
        @ApiModelProperty()
        @IsString()
        @IsOptional()
        queryString?: string;

        @ApiModelProperty()
        @ValidateNested()
        @IsObject()
        @IsOptional()
        filters?: SearchFilterRequest;

        @ApiModelProperty()
        @ValidateNested({ each: true })
        @IsObject({ each: true })
        @IsArray()
        @IsOptional()
        sort?: Array<SearchSort>;

        @ApiModelProperty()
        @IsInt()
        @IsNotEmpty()
        offset: number;

        @ApiModelProperty()
        @IsInt()
        @IsPositive()
        @IsNotEmpty()
        limit: number;
    }

    export class SearchResponse {
        @ApiModelProperty({
            type: ProductReviewDto,
            isArray: true,
        })
        productReviews: Array<ProductReviewDto>;

        @ApiModelProperty()
        totalSearchResults: number;
    }

    export class Stats {
        productCategories: Array<{
            id: number;
            count: number;
        }>;
    }
}
