import { IsInt, IsNotEmpty, IsOptional, IsPositive, Min } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductBasketDto, ProductEntryBasketDto } from '../models/entities/product-basket.dto';

export enum ProductBasketWebEndpointPaths {
    add = '/api/v1/web/product-basket/add',
    remove = '/api/v1/web/product-basket/remove',
    update = '/api/v1/web/product-basket/update',
    clear = '/api/v1/web/product-basket/clear',
    getBasket = '/api/v1/web/product-basket/get-basket',
    getProductEntryDto = '/api/v1/web/product-basket/get-product-entry-dto',
}

export interface ProductBasketWebEndpoint {
    add(request: ProductBasketAddRequest, ...args: unknown[]): Promise<Array<ProductBasketDto>> | Observable<Array<ProductBasketDto>>;
    remove(request: ProductBasketRemoveRequest): Promise<Array<ProductBasketDto>> | Observable<Array<ProductBasketDto>>;
    update(request: ProductBasketUpdateRequest): Promise<ProductBasketUpdateResponse> | Observable<ProductBasketUpdateResponse>;
    clear(): Promise<void> | Observable<void>;
    getBasket(): Promise<ProductBasketGetBasketResponse> | Observable<ProductBasketGetBasketResponse>;
    getProductEntryDto(request: ProductBasketGetProductEntryDtoRequest): Promise<ProductEntryBasketDto> | Observable<ProductEntryBasketDto>;
}

export class ProductBasketAddRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amount: number;
}

export class ProductBasketRemoveRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;
}

export class ProductBasketUpdateRequest {
    @ApiModelProperty()
    @IsPositive()
    @Min(1)
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    setAmount?: number;
}

export class ProductBasketUpdateResponse {
    @ApiModelProperty()
    productBaskets: Array<ProductBasketDto>;

    @ApiModelProperty()
    numProductsInBasket: number;
}

export class ProductBasketGetBasketResponse {
    @ApiModelProperty({
        type: ProductEntryBasketDto,
        isArray: true,
    })
    products: Array<ProductEntryBasketDto>;

    @ApiModelProperty({
        type: ProductBasketDto,
        isArray: true,
    })
    productBasket: Array<ProductBasketDto>;
}

export class ProductBasketGetProductEntryDtoRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;
}
