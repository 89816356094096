<div class='c' [ngClass]='ngClass'>
  @if (isBadgeEnabled("out-of-production")) {
    @if (helper.product.flags.isOutOfProduction) {
      <div class='badge badge-is-out-of-production'
        title='{{ t("badge.isOutOfProduction.alt") | translate }}'>
        <span>{{ t("badge.isOutOfProduction.title") | translate }}</span>
      </div>
    }
  }
  @if (isBadgeEnabled("discount")) {
    @if (helper.price.hasPercentsDiscount) {
      <div class='badge badge-percents-discount'>
        <div class='percents'><span>{{ '-' + helper.price.percentsDiscount }}</span></div>
        <div class='icon'>
          <svg width='13' height='13' viewbox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M10.1 3.09994L3.09998 10.0999' stroke='white' stroke-width='2' stroke-linecap='round'
            stroke-linejoin='round'></path>
            <path
              d='M3.60004 5.60001C4.70461 5.60001 5.60004 4.70458 5.60004 3.60001C5.60004 2.49544 4.70461 1.60001 3.60004 1.60001C2.49547 1.60001 1.60004 2.49544 1.60004 3.60001C1.60004 4.70458 2.49547 5.60001 3.60004 5.60001Z'
            stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'></path>
            <path
              d='M9.60004 11.5999C10.7046 11.5999 11.6 10.7045 11.6 9.59994C11.6 8.49537 10.7046 7.59994 9.60004 7.59994C8.49547 7.59994 7.60004 8.49537 7.60004 9.59994C7.60004 10.7045 8.49547 11.5999 9.60004 11.5999Z'
            stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'></path>
          </svg>
        </div>
      </div>
    }
  }
  @if (isBadgeEnabled("sale")) {
    @if (helper.price.hasSale) {
      <div class='badge badge-sale'
        [title]='helper.product.sale.badge || helper.product.sale.badgeForCard'>
        <span>{{ helper.product.sale.badgeForCard }}</span>
      </div>
    }
  }
  @if (isBadgeEnabled("new")) {
    @if (helper.product.flags.isNew) {
      <div class='badge badge-new' title='{{ t("badge.isNew.alt") | translate }}'>
        <span>{{ t("badge.isNew.title") | translate }}</span>
      </div>
    }
  }
  @if (isBadgeEnabled("hit")) {
    @if (helper.product.flags.isHit) {
      <div class='badge badge-hit' title='{{ t("badge.isHit.alt") | translate }}'>
        <span>{{ t("badge.isHit.title") | translate }}</span>
      </div>
    }
  }
  @if (isBadgeEnabled("is-available-depot")) {
    @if (helper.product.flags.isAvailableVendorDepot || helper.product.flags.isAvailableDepot) {
      <div class='badge badge-depot'
        title='{{ t("badge.isAvailableDepot.alt") | translate }}'>
        <span>{{ t("badge.isAvailableDepot.title") | translate }}</span>
      </div>
    }
  }
  @if (analogBage) {
    <div class='badge badge-analog' title='{{ t("badge.analogBadge.alt") | translate }}'>
      <span>{{ t("badge.analogBadge.title") | translate }}</span>
    </div>
  }
  @if (cheapestBage) {
    <div class='badge badge-analog' title='ДЕШЕВЛЕ'>
      <span>ДЕШЕВЛЕ</span>
    </div>
  }
</div>