import { ChangeDetectionStrategy, Component, EventEmitter, Injector, OnInit, Output, inject, model, signal } from '@angular/core';
import { CityDto } from '@interid/interid-site-shared';
import { AppBootstrapDataService, extractPhone } from '@interid/interid-site-web/core';
import { connectToSignal } from '@interid/interid-site-web/ui-signals';

interface State {
    mobilePhone: string;
    hrefMobilePhone: string;
    multichannelPhone: string;
    hrefMultichannelPhone: string;
    phoneForOrders: string;
    hrefPhoneForOrders: string;
}

@Component({
    templateUrl: './app-header-desktop-city.component.html',
    styleUrls: ['./app-header-desktop-city.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderDesktopCityComponent implements OnInit {
    private readonly appBootstrap$ = inject(AppBootstrapDataService);
    private readonly injector = inject(Injector);

    @Output() confirmCityEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() selectCityEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

    public city = model<CityDto | undefined>(undefined);
    public disableConfirmButton = model(false);

    public state = signal<State>({
        mobilePhone: '',
        hrefMobilePhone: '',
        multichannelPhone: '',
        hrefMultichannelPhone: '',
        phoneForOrders: '',
        hrefPhoneForOrders: '',
    });

    ngOnInit(): void {
        connectToSignal(
            this.state,
            this.appBootstrap$.data$,
            (prev, next) => ({
                ...prev,
                mobilePhone: next.constants.mobilePhone,
                hrefMobilePhone: `tel:${extractPhone(next.constants.mobilePhone)}`,
                multichannelPhone: next.constants.multichannelPhone,
                hrefMultichannelPhone: `tel:${extractPhone(next.constants.multichannelPhone)}`,
                phoneForOrders: next.constants.phoneForOrders,
                hrefPhoneForOrders: `tel:${extractPhone(next.constants.phoneForOrders)}`,
            }),
            this.injector
        );
    }

    close() {
        this.closeEvent.emit(undefined);
    }

    confirmCity(): void {
        this.confirmCityEvent.emit(undefined);
    }

    selectCity(): void {
        this.selectCityEvent.emit(undefined);
    }
}
