import { Injectable, OnDestroy } from '@angular/core';
import { ProductESDto, Source } from '@interid/interid-site-shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProductCardParamsHelper } from './product-card/product-card-params.helper';
import { ProductCardReviewHelper } from './product-card/product-card-review.helper';
import { ProductCardBasketHelper } from './product-card/product-card-basket.helper';
import { ProductCardSource } from './product-card.source';
import { ProductCardFavoritesHelper } from './product-card/product-card-favorites.helper';
import { ProductCardCompareHelper } from './product-card/product-card-compare.helper';
import { ProductCardPriceHelper } from './product-card/product-card-price.helper';
import { UrlBuilderService } from '@interid/interid-site-web/core';
import { ProductCardQuestionHelper } from './product-card/product-card-question.helper';
import { ProductCardDeliveryHelper } from './product-card/product-card-delivery.helper';

export type ProductCardVariant = 'default' | 'small' | 'tiny' | 'big' | 'big-mobile';
export type ProductCardViewmode = 'list' | 'card';
export type CompareTopbarVariant = 'none' | 'default' | 'simple';

@Injectable()
export class ProductCardHelper implements OnDestroy {
    private _nextProduct$: Subject<void> = new Subject<void>();

    private _product$: BehaviorSubject<ProductESDto> = new BehaviorSubject<ProductESDto>(undefined);

    constructor(
        private readonly urlBuilder: UrlBuilderService,
        public readonly source: ProductCardSource,
        public readonly params: ProductCardParamsHelper,
        public readonly reviews: ProductCardReviewHelper,
        public readonly questions: ProductCardQuestionHelper,
        public readonly basket: ProductCardBasketHelper,
        public readonly favorite: ProductCardFavoritesHelper,
        public readonly delivery: ProductCardDeliveryHelper,
        public readonly compare: ProductCardCompareHelper,
        public readonly price: ProductCardPriceHelper,
    ) {
    }

    ngOnDestroy(): void {
        this._nextProduct$.next();
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    withProduct(product: ProductESDto): void {
        this._nextProduct$.next();
        this._product$.next(product);
        this.source.withProduct(product);
    }

    get product(): ProductESDto {
        return this.source.product;
    }

    get product$(): Observable<ProductESDto> {
        return this._product$.asObservable();
    }

    get productRouterLink(): any {
        return this.urlBuilder.urlLink({
            type: Source.Product,
            payload: {
                productId: this.product.id,
            },
        });
    }

    get isAvailable(): boolean {
        return !! this.product;
    }
}
