import * as url from 'url';
import { withTrailingSlash } from './with-trailing-slash.function';
import { replaceStringMultiple } from './replace-string-multiple.function';

export function urlPostprocess(input: string): string {
  const parsed = url.parse(input);

  return parsed.query
    ? withTrailingSlash(`${parsed.pathname}?${replaceStringMultiple(parsed.query, '%2C', ',')}`)
    : withTrailingSlash(`${parsed.pathname}`);
}
