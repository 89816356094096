import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ParamsCategoryWebEndpointPaths, ParamsWebCategoryEndpoint, ParamsWebCategoryListAllResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebParamsCategoryDataAccess implements ParamsWebCategoryEndpoint {
    constructor(private readonly http: InteridDataAccessService) {}

    listParamsCategories(): Observable<ParamsWebCategoryListAllResponse> {
        return this.http.post(ParamsCategoryWebEndpointPaths.listParamsCategories);
    }
}
