import { IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountRequisitesDto } from '../models/entities/account-requisites.dto';
import { AccountRequisitesShared } from '../models/shared/account-requisites.shared';

export enum AccountRequisitesEndpointPaths {
    setAccountRequisites = '/api/v1/account-requisites/set',
    getAccountRequisites = '/api/v1/account-requisites/get',
}

export interface AccountRequisitesEndpoint {
    setAccountRequisites(request: AccountRequisitesSetRequest): Promise<AccountRequisitesDto> | Observable<AccountRequisitesDto>;
    getAccountRequisites(request: AccountRequisitesGetRequest): Promise<AccountRequisitesDto> | Observable<AccountRequisitesDto>;
}

export class AccountRequisitesEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    accountId?: number;

    @IsObject()
    @IsNotEmpty()
    @ApiModelProperty()
    definition: AccountRequisitesShared.RequisitesDefinition;
}

export class AccountRequisitesSetRequest extends AccountRequisitesEntityBody {
}

export class AccountRequisitesGetRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    accountId?: number;
}
