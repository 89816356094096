<div class='c' [ngClass]='ngClass()'>
  <div class='c-loading'>
    <app-shared-loading [loading]='isLoading()' />
  </div>
  
  @if (! state().isAppOnline) {
    <div class='c-app-is-offline'>
      <span translate>app.modules.components.app.app_offline</span>
    </div>
  }

  <router-outlet />
</div>
