import { TranslateService } from '@ngx-translate/core';

export type UISelectOptions<T = any> = Array<UISelectOption<T>>;

export interface UISelectOption<T = any> {
    value: T;
    label: string;
    translate?: boolean;
    translateParams?: any;
}

export function uiSelectValueToLabel(i18n: TranslateService, options: UISelectOptions, labels: UISelectOptions, value: any): string {
    const option = Array.isArray(options) ? options.find((opt) => opt.value === value) : options;

    let label;
    if (labels && labels?.length > 0) label = Array.isArray(labels) ? labels.find((opt) => opt.value === value) : labels;

    if (label) {
        return label.translate ? i18n.instant(label.label, label.translateParams) : label.label;
    } else if (option) {
        return option.translate ? i18n.instant(option.label, option.translateParams) : option.label;
    } else {
        return value !== null && value !== undefined ? value.toString() : '–';
    }
}
