import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { urlBuildFromDefinitionHelper, Source } from '@interid/interid-site-shared';
import { AppBootstrapDataService, EnvironmentWebService } from '@interid/interid-site-web/core';
import { AppLayoutResolveData, APP_LAYOUT_RESOLVE_KEY } from '@interid/interid-site-web/core-app';
import { Subject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';

export interface Link {
    url?: string;
    title: string;
    type: string;
}

interface State {
    links: Array<Link>;
}

@Component({
    selector: 'app-shared-product-favorite-share',
    templateUrl: './product-favorite-share.component.html',
    styleUrls: ['./product-favorite-share.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFavoriteShareComponent implements OnInit, OnDestroy {
    @Output() confirmCityEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() selectCityEvent: EventEmitter<void> = new EventEmitter<void>();
    @Input() productId: number;
    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        links: [],
    };

    constructor(private clipboard: Clipboard, private readonly env: EnvironmentWebService, private readonly activatedRoute: ActivatedRoute, private readonly cdr: ChangeDetectorRef, private readonly appBootstrap$: AppBootstrapDataService) {}

    ngOnInit(): void {
        this.state = {
            ...this.state,
            links: [
                { url: 'https://t.me/share/?url=', title: 'Telegram', type: 'telegram' },
                { url: 'https://vk.com/share.php?url=', title: 'ВКонтакте', type: 'vk' },
                { url: 'https://api.whatsapp.com/send?text=', title: 'WhatsApp', type: 'whatsapp' },
                { title: 'Скопировать ссылку', type: 'link' },
            ],
        };
    }

    get resolvedData(): AppLayoutResolveData {
        return this.activatedRoute.snapshot.data[APP_LAYOUT_RESOLVE_KEY];
    }

    clickLink(link) {
        const productPage = urlBuildFromDefinitionHelper({
            type: Source.Product,
            payload: {
                productId: this.productId,
            },
        });
        const uri = `${this.env.current.baseUrl}${productPage}`;

        switch (link.type) {
            case 'link': {
                this.clipboard.copy(uri);
                break;
            }

            default: {
                if (window && window.open) {
                    window.open(`${link.url}${uri}`, '_blank');
                }
                break;
            }
        }

        this.close();
    }

    close() {
        this.closeEvent.emit(undefined);
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `app.components.app_header_desktop_city.${input}`;
    }
}
