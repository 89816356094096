
<div class="c">
  <div class="c-pages">@for (page of pages; track page) {
    <a class="c-button c-page ___disable-user-select" [ngClass]="{ 'is-active': isActive(page) }" [routerLink]="(service.routerLink$(page) | async).route" [queryParams]="(service.routerLink$(page) | async).queryParams">
      <button (click)="goPage($event, page)" matRipple="matRipple"><span>{{ page }}</span></button></a>
    }</div>
    <div class="c-close">
      <app-shared-ui-button (click)="close()" buttonStyle="stroked"><span>{{ i18n('close') | translate }}</span></app-shared-ui-button>
    </div>
  </div>
