import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, PLATFORM_ID, Signal, inject, signal } from '@angular/core';
import { CityDto, Source, } from '@interid/interid-site-shared';
import { AppBootstrapDataService, EnvironmentWebService, UrlBuilderService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { APP_LAYOUT_RESOLVE_KEY, AppLayoutResolveData } from '../app-layout/app-layout.resolve';
import { Request } from 'express';
import { REQUEST } from '@interid/interid-site-web/ui-shared';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { signalFromRouteData } from '@interid/interid-site-web/ui-signals';

const NUM_COLUMNS = 3;

interface ProductCategoryLink {
    id: number;
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    link: any;
}

interface State {
    categories: Array<Array<ProductCategoryLink>>;
    city: CityDto;
}

@Component({
    selector: 'app-footer-wide',
    templateUrl: './app-footer-wide.component.html',
    styleUrls: ['./app-footer-wide.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterWideComponent implements OnInit {
    private readonly platformId = inject(PLATFORM_ID);
    private readonly request: Request = inject(REQUEST, { optional: true });
    protected viewBreakpointsService = inject(ViewBreakpointsService);
    protected env = inject(EnvironmentWebService);
    protected appBootstrap = inject(AppBootstrapDataService);
    protected urlBuilder = inject(UrlBuilderService);

    public state = signal<State>({
        categories: [[], [], []],
        city: new CityDto()
    });

    public layout = toSignal(this.viewBreakpointsService.currentLayout$.pipe(
        distinctUntilChanged(),
        map((next) => next)
    ), { requireSync: true });

    public resolvedData: Signal<AppLayoutResolveData> = signalFromRouteData(APP_LAYOUT_RESOLVE_KEY);

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.state.update((prev) => ({
            ...prev,
            city: this.appBootstrap.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find(x=>x.isDefault)
        }));

        const categories = this.resolvedData().popularProductCategories;
        const categoriesPerColumn = Math.ceil(categories.length / 3);

        for (let column = 0; column < NUM_COLUMNS; column++) {
            for (let n = 0; n < categoriesPerColumn; n++) {
                const next = categories[column * categoriesPerColumn + n];

                if (next) {
                    this.state().categories[column].push({
                        id: next.id,
                        title: next.title,
                        link: this.urlBuilder.urlLink({
                            type: Source.Catalog,
                            payload: {
                                productCategoryId: next.id,
                            },
                        }),
                    });
                }
            }
        }
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }
}
