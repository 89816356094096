import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const escapeHtml = (unsafe: string) => {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/'/g, '&quot;')
        .replace(/'/g, '&#039;');
};

@Pipe({
    name: 'rtfMultiline',
})
export class RtfMultilinePipe implements PipeTransform {
    constructor(
        private readonly domSanitizer: DomSanitizer,
    ) {
    }

    transform(value: any, ...args: any[]): any {
        const lines = value
            .split('\n')
            .filter((v: any) => v.trim().length > 0)
            .map((l: any) => escapeHtml(l))
            .map((l: any) => this.domSanitizer.sanitize(SecurityContext.HTML, l))
            .join('</p><p>');

        return this.domSanitizer.bypassSecurityTrustHtml(`<p>${lines}</p>`);
    }
}
