export const interidSiteWebFeatureAccountI18n = {
    account: {
        _: {
            models: {
                BonusType: {
                    customIncome: 'Начисление баллов',
                    customExpense: 'Списание баллов',
                    orderExpense: 'Списание в заказе {{ source.payload.orderId }}',
                    productReview: 'Отзыв к товару "{{ source.payload.product }}"',
                    articleReview: 'Комментарий к статье "{{ source.payload.article }}"',
                    orderIncome: 'Начисление за покупку товаров',
                    yandexMarket: 'Отзыв в Яндекс.Маркете',
                },
                OrderStatus: {
                    NotProcessed: '',
                    InProgress: 'В процессе',
                    Cancelled: 'Отменен',
                    Processed: 'Обработан',
                },
            },
        },
        components: {
            account_menu: {
                category_personal: 'Личная информация',
                profile: 'Профиль',
                requisites: 'Реквизиты юр. лица',
                bonus: 'Бонусные баллы',
                category_orders: 'Заказы',
                orders: 'Мои заказы',
                bills: 'Электронные чеки',
                purchases: 'Купленные товары',
                category_activity: 'Активность на сайте',
                favorites: 'Избранное',
                reviews: 'Мои отзывы',
                marks: 'Мои оценки',
            },
            account_profile: {
                blocks: {
                    profile: {
                        title: 'Профиль',
                        fullName: 'Ваше имя',
                        phone: 'Телефон',
                        deliveryAddress: 'Адрес доставки',
                        submit: 'Сохранить',
                        success: 'Профиль обновлен',
                    },
                    passwd: {
                        title: 'Изменить пароль',
                        old: 'Старый пароль',
                        new: 'Новый пароль',
                        repeat: 'Повторите пароль',
                        submit: 'Изменить пароль',
                        repeat_fail: 'Пароли не совпадают',
                        success: 'Пароль обновлен',
                    },
                    sign_out: {
                        title: 'Выход',
                        signOutThisDevice: 'Выйти с этого устройства',
                        signOutAllDevices: 'Выйти со всех устройств',
                    },
                },
            },
            account_requisites: {
                title: 'Реквизиты юр. лица',
                empty: 'Не заполнено',
                actions: {
                    edit: 'Редактировать',
                    submit: 'Сохранить',
                    cancel: 'Отмена',
                },
                fields: {
                    type: 'Тип',
                    companyName: 'Наименование организации',
                    city: 'Город',
                    legalAddress: 'Юридический адрес',
                    realAddress: 'Фактический адрес',
                    bankName: 'Наименование банка',
                    bankBIK: 'БИК',
                    bankAccount: 'Расчетный счет',
                    bankCorrespondingAccount: 'Корреспондентский счет',
                    inn: 'ИНН',
                    kpp: 'КПП',
                },
            },
            account_bonus: {
                title: 'Бонусные баллы',
                history: 'История операций',
                sum: 'Доступно баллов',
                date: 'Дата',
                operation: 'Операция',
                amount: 'Сумма',
                empty: 'У вас нет доступных баллов',
            },
            account_reviews: {
                title: 'Мои отзывы',
                review: 'Представитель «Inter ID»',
                empty: 'Вы еще не оставляли отзывов к товарам',
            },
            account_purchases: {
                title: 'Купленные товары',
                empty: 'Вы еще не создавали заказы на сайте',
            },
            account_bills: {
                title: 'Электронные чеки',
                date: 'Дата',
                order: 'Номер заказа',
                amount: 'Сумма',
                download: 'Электронный чек',
                download_link: 'Скачать',
                empty: 'У вас нет электронных чеков',
            },
            account_orders: {
                title: 'Мои заказы',
                empty: 'Вы еще не оставляли заказов на сайте',
                date: 'Дата',
                order: 'Номер заказа',
                amount: 'Сумма',
                delivery: 'Способ доставки',
            },
            account_orders_view: {
                title: 'Мои заказы',
                order: 'Заказ',
                contents: 'Состав заказа',
                bill: 'Скачать электронный чек',
                recipient: 'Получатель',
                deliveryAddress: 'Адрес доставки',
                delivery: 'Способ доставки',
                items: 'шт',
            },
        },
    },
};
