import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppBootstrapWebResponse } from '@interid/interid-site-shared';
import { AppBusEvent, AppBusService } from './app-bus.service';
import { InteridWebAppBootstrapDataAccess } from '@interid/interid-site-data-access/web';

@Injectable({
    providedIn: 'root',
})
export class AppBootstrapDataService
{
    private _data$: BehaviorSubject<AppBootstrapWebResponse> = new BehaviorSubject<AppBootstrapWebResponse>(undefined);

    constructor(
        private readonly appBus: AppBusService,
        private readonly endpoint: InteridWebAppBootstrapDataAccess,
    ) {}

    set data(newData: AppBootstrapWebResponse) {
        this._data$.next(newData);

        if (newData.accountSettings) {
            this.appBus.emit({
                type: AppBusEvent.AccountSettingsFetched,
                payload: {
                    settings: newData.accountSettings,
                },
            });
        }
    }

    get data(): AppBootstrapWebResponse {
        return this._data$.getValue();
    }

    get data$(): Observable<AppBootstrapWebResponse> {
        return this._data$.asObservable();
    }

    set patchData(mergeData: Partial<AppBootstrapWebResponse>) {
        this._data$.next({
            ...this.data,
            ...mergeData,
        });
    }

    refresh(): Observable<AppBootstrapWebResponse> {
        return this.endpoint.getBootstrap().pipe(
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
            tap((next) => this.data = next),
        );
    }
}
