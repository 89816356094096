import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

enum Screen {
    None = 'none',
    Main = 'main',
    Catalog = 'catalog',
    Search = 'search',
    City = 'city',
}

@Injectable()
export class AppMenuStateService {
    constructor() {}

    private _menu$: BehaviorSubject<Screen> = new BehaviorSubject<Screen>(Screen.None);

    switchMenuState(screen: Screen): void {
        this._menu$.next(screen);
    }

    get current$(): Observable<Screen> {
        return this._menu$.asObservable();
    }

    get current(): Screen {
        return this._menu$.getValue();
    }
}
