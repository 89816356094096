import { Observable } from 'rxjs';
import { IsArray, IsEmail, IsInt, IsNotEmpty, IsObject, IsPositive, IsString, Max, Min, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ArticleReviewDto } from '../models/entities/article-review.dto';

export enum ArticleReviewEndpointPaths {
    create = '/api/v1/article-review/create',
    delete = '/api/v1/article-review/delete',
    approve = '/api/v1/article-review/approve',
    reject = '/api/v1/article-review/reject',
    reply = '/api/v1/article-review/reply',
    list = '/api/v1/article-review/list',
}

export interface ArticleReviewEndpoint {
    create(request: CreateArticleReviewRequest): Promise<ArticleReviewDto> | Observable<ArticleReviewDto>;
    delete(request: DeleteArticleReviewRequest): Promise<void> | Observable<void>;
    approve(request: ApproveArticleReviewRequest): Promise<ArticleReviewDto> | Observable<ArticleReviewDto>;
    reject(request: RejectArticleReviewRequest): Promise<ArticleReviewDto> | Observable<ArticleReviewDto>;
    reply(request: ReplyArticleReviewRequest): Promise<ArticleReviewDto> | Observable<ArticleReviewDto>;
    list(request: ListArticleReviewRequest): Promise<ListArticleReviewResponse> | Observable<ListArticleReviewResponse>;
}


export class ArticleHitWebRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class CreateArticleReviewRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    articleId: number;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    @Min(1)
    @Max(5)
    rating: number;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    author: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    @IsEmail()
    email: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    contents: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsArray()
    @IsInt({ each: true })
    @IsPositive({ each: true })
    contentAttachmentIds: Array<number>;
}

export class DeleteArticleReviewRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    id: number;
}

export class ApproveArticleReviewRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    id: number;
}

export class RejectArticleReviewRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    id: number;
}

export class ReplyArticleReviewRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    id: number;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    response: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsArray()
    @IsInt({ each: true })
    @IsPositive({ each: true })
    responseAttachmentIds: Array<number>;
}

export class ListArticleReviewRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    limit: number;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    offset: number;
}

export class ListArticleReviewResponse {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsArray()
    @IsObject({ each: true })
    @ValidateNested({ each: true })
    entities: Array<ArticleReviewDto>;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    total: number;
}
