import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { EnvironmentWebService } from '@interid/interid-site-web/core';
import { ProductCardHelper } from '../../helpers/product-card.helper';

export class StaticBage {
    iconPath: string;
    tooltip: string;
}

@Component({
    selector: 'app-shared-product-card-assets-parameters-badges',
    templateUrl: './product-card-parameters-badges.component.html',
    styleUrls: ['./product-card-parameters-badges.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardParametersBadgeComponent implements OnInit {
    @Input() product: any;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private readonly env: EnvironmentWebService, public readonly helper: ProductCardHelper) {}

    mouseDown = false;
    startX: number;
    scrollLeft: number;
    slider;

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.slider = document.querySelector<HTMLElement>('.c-bages');
        }
    }

    trackByIconPath(input: StaticBage): string {
        return input.iconPath;
    }

    dictManyStaticBagesArray(input: Array<string> | undefined): Array<StaticBage> {
        return (
            input?.map((x) => {
                const line = x.split('\t');
                return { iconPath: line[0], tooltip: line[1] };
            }) ?? []
        );
    }

    url(dictIconPath) {
        return !dictIconPath ? undefined : `${this.env.current.attachmentEndpoint}/${dictIconPath}`;
    }

    startDragging(e: { pageX: number }, flag: any, el: { offsetLeft: number; scrollLeft: any }) {
        this.mouseDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
    }
    stopDragging(e: any, flag: any) {
        this.mouseDown = false;
    }
    moveEvent(e: { preventDefault: () => void; pageX: number }, el: { offsetLeft: number; scrollLeft: number }) {
        e.preventDefault();
        if (!this.mouseDown) {
            return;
        }
        const x = e.pageX - el.offsetLeft;
        const scroll = x - this.startX;
        el.scrollLeft = this.scrollLeft - scroll;
    }
}
