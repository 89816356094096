import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ParamsShared } from '../shared/params.shared';
import { ParamsCategoryDto } from './params-category.dto';

export class ProductParamsDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  productId: number;

  @ApiModelProperty()
  paramsId: number;

  @ApiModelProperty()
  paramsCategory?: ParamsCategoryDto;

  @ApiModelProperty()
  paramsType: ParamsShared.ParamsType;

  @ApiModelProperty()
  pcIds: Array<number>;

  @ApiModelProperty()
  paramsName: string;

  @ApiModelProperty()
  displayInProductDetails: boolean;

  @ApiModelProperty()
  displayInProductCard: boolean;

  @ApiModelProperty()
  displayInSearchFilters: boolean;

  @ApiModelProperty()
  notDisplayInCharacteristics: boolean;

  @ApiModelProperty()
  similarProduct: boolean;

  @ApiModelProperty()
  displayInFilters: boolean;

  @ApiModelProperty()
  displayInModifications: boolean;

  @ApiModelProperty()
  displayDictionaryIcon: boolean;

  @ApiModelProperty()
  isExportedToMarketplaceParams: boolean;

  @ApiModelProperty()
  staticValue: string;

  @ApiModelProperty()
  staticValuesArray: Array<string>;

  @ApiModelProperty()
  dictManyStaticValue: string;

  @ApiModelProperty()
  dictManyStaticValueArray: Array<string>;

  @ApiModelProperty()
  dictValue?: string;

  @ApiModelProperty()
  dictValueSortOrder?: number;

  @ApiModelProperty()
  dictValueId?: number;

  @ApiModelProperty()
  dictColorHex?: string;

  @ApiModelProperty()
  dictIconPath?: string;

  @ApiModelProperty()
  dictTooltip?: string;

  @ApiModelProperty()
  dictManyStaticBagesArray: Array<string>;
}
