import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DictionaryShared } from '../shared/dictionary.shared';
import { DictionaryValueDto } from './dictionary-value.dto';

export class DictionaryDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  parentId: number;

  @ApiModelProperty()
  title: string;
  
  @ApiModelProperty()
  type: DictionaryShared.Type;

  @ApiModelProperty()
  isDisplayed: boolean;
}

export class DictionaryWithValuesDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  parentId: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  isDisplayed: boolean;

  @ApiModelProperty()
  values: Array<DictionaryValueDto>; 
}

export class DictionaryListDto {

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  parentId: number;

  @ApiModelProperty()
  title: string;
}
