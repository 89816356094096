
<app-shared-dialog-close (click)="close()"></app-shared-dialog-close>
<div class="c">
  @if (state().screen === 'form') {
    <div class="c-screen c-screen-form" [formGroup]="state().form">
      <div class="c-title"><span>{{ t('form.title') | translate }}</span></div>
      <div class="c-form">
        <div class="c-form-field">
          <app-shared-ui-input formControlName="name" leftIcon="person" placeholder="{{ t('form.name') | translate }}" (enter)="ngSubmit()" (escape)="close()"></app-shared-ui-input>
        </div>
        <div class="c-form-field">
          <app-shared-ui-input formControlName="phone" leftIcon="phone" placeholder="{{ t('form.phone') | translate }}" (enter)="ngSubmit()" (escape)="close()"></app-shared-ui-input>
        </div>
        <div class="c-form-field">
          <app-shared-ui-input formControlName="email" leftIcon="email" placeholder="{{ t('form.email') | translate }}" (enter)="ngSubmit()" (escape)="close()"></app-shared-ui-input>
        </div>
        <div class="c-form-field">
          <app-shared-ui-textarea formControlName="message" leftIcon="message"  [height]='120' placeholder="{{ t('form.message') | translate }}" (enter)="ngSubmit()" (escape)="close()"></app-shared-ui-textarea>
        </div>
      </div>
      <div class="c-submit">
        <app-shared-ui-button (click)="ngSubmit()" [disabled]="state().form.disabled || ! state().form.valid"><span>{{ t('form.submit') | translate }}</span></app-shared-ui-button>
      </div>
      <div class="c-agree"><span>{{ t('form.agree') | translate }}</span></div>
    </div>
  }
  @if (state().screen === 'success') {
    <div class="c-screen c-screen-success">
      <div class="c-box">
        <div class="c-icon">
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40" cy="40" r="40" fill="#0049FF"></circle>
            <path d="M59.4142 28.2981C58.6332 27.517 57.367 27.517 56.5858 28.2981L32.6246 52.2596L23.4143 43.0493C22.6332 42.2682 21.367 42.2683 20.5858 43.0493C19.8047 43.8303 19.8047 45.0965 20.5858 45.8776L31.2104 56.502C31.9911 57.283 33.2583 57.2825 34.0388 56.502L59.4142 31.1265C60.1953 30.3455 60.1953 29.0792 59.4142 28.2981Z" fill="white"></path>
          </svg>
        </div>
        <div class="c-title">
          <div class="c-title-l1"><span>{{ t('success.thanks') | translate }}</span></div>
          <div class="c-title-l2"><span>{{ t('success.sent') | translate }}</span></div>
        </div>
        <div class="c-response"><span>{{ t('success.response') | translate }}</span></div>
      </div>
    </div>
  }
</div>
