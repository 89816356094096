export const interidSiteWebFeatureArticleI18n: any = {
    articles: {
        components: {
            articles: {
                title: 'Блог',
                loadMore: 'Показать еще',
                noResults: 'Ничего не найдено',
                noResultsDesc: 'По вашему запросу не найдено статей',
            },
            articles_menu: {
                tags: 'Поиск по тегам',
                authors: 'Авторы',
            },
            articles_entity: {
                reviews_0: '{{ count }} комментариев',
                reviews_1: '{{ count }} комментарий',
                reviews_2: '{{ count }} комментария',
                agree: 'Нажимая кнопку «Подписаться», я подтверждаю согласие на',
                agreeLinkText: 'обработку персональных данных',
                tags: 'Теги:',
                share: 'Поделиться:',
                comments: 'Комментарии',
                more: 'Также будет интересно',
                preview: 'Предпросмотр: эта статья еще не опубликована на сайте',
                response: 'Представитель «Inter ID»',
                subscribed: 'Вы подписаны на рассылку.',
            },
            articles_comment_form: {
                success: 'Комментарий отправлен на модерацию',
                title: 'Добавить комментарий',
                privacy: 'Нажимая кнопку отправить, вы даете согласие на обработку персональных данных',
                fields: {
                    message: 'Введите текст комментария',
                    name: 'Имя',
                    email: 'E-mail',
                },
                actions: {
                    submit: 'Отправить',
                },
            },
        },
    },
};
