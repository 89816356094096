export const interidSiteWebFeatureSearchI18n = {
    search: {
        components: {
            search_products: {
                title: {
                    title_1: 'По запросу “{{ query }}” найден {{ count }} товар',
                    title_2: 'По запросу “{{ query }}” найдено {{ count }} товаров',
                    title_3: 'По запросу “{{ query }}” найдено {{ count }} товара',
                },
                params: {
                    manufacturer: {
                        title: 'Производитель',
                    },
                    category: {
                        title: 'Категория',
                    },
                    actions: {
                        submit: 'Найти',
                        reset: 'Сбросить',
                    },
                },
            },
            search_articles: {
                title: {
                    title_1: 'По запросу “{{ query }}” найдена {{ count }} статья',
                    title_2: 'По запросу “{{ query }}” найдено {{ count }} статей',
                    title_3: 'По запросу “{{ query }}” найдена {{ count }} статья',
                },
                no_annotation: '(Описание отсутствует...)',
            },
            search_product_reviews: {
                title: {
                    title_1: 'По запросу “{{ query }}” найден {{ count }} комментарий',
                    title_2: 'По запросу “{{ query }}” найдено {{ count }} комментариев',
                    title_3: 'По запросу “{{ query }}” найден {{ count }} комментарий',
                },
            },
            search_article_reviews: {
                title: {
                    title_1: 'По запросу “{{ query }}” найден {{ count }} комментарий',
                    title_2: 'По запросу “{{ query }}” найдено {{ count }} комментариев',
                    title_3: 'По запросу “{{ query }}” найден {{ count }} комментарий',
                },
                go_to_article: 'К статье',
            },
            search_no_results: {
                title: 'По запросу “{{ queryString }}” ничего не найдено',
                message: 'Попробуйте изменить запрос',
            },
            search_tabs_navigation: {
                tabs: {
                    products: 'Товары',
                    articles: 'Статьи',
                    productReviews: 'Комментарии к товарам',
                    articleReviews: 'Комментарии',
                },
            },
        },
    },
};
