import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { CatalogProductsWebResponse, CatalogUsedProductSeriesDto, CatalogWebResponse, ManufacturerListDto, ProductCategorySeoLinkDto, ProductSearchRequest, Source, UrlBuilderRouterLinkDefinition, urlRemoveBlacklistQp, urlWithoutPagePostfix, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UrlBuilderService, ViewBreakpointsService } from '@interid/interid-site-web/core';

export const CATALOG_RESOLVE_KEY = 'catalog';

interface State {
    brakepoint: ViewBreakpointsShared.ViewBreakpoints;
    viewMore: boolean;
}

export interface CatalogResolveData {
    categoryId: number;
    esRequest: ProductSearchRequest;
    category: CatalogWebResponse;
    products: CatalogProductsWebResponse;
    currentProductSeries?: CatalogUsedProductSeriesDto;
    currentManufacturer?: ManufacturerListDto;
}

@Component({
    selector: 'app-shared-catalog-category-seo-links',
    templateUrl: './catalog-category-seo-links.component.html',
    styleUrls: ['./catalog-category-seo-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogCategorySeoLinksComponent implements OnInit, OnDestroy {
    @ViewChild('container') containerRef: ElementRef<HTMLDivElement>;

    @Input() seoLinks: Array<any>;
    @Input() categoryId?: number;
    @Input() manufacturer?: any;
    @Input() isVendorPage: boolean;

    public state: State = {
        brakepoint: ViewBreakpointsShared.ViewBreakpoints.XS,
        viewMore: false,
    };

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    constructor(private readonly cdr: ChangeDetectorRef, private readonly vbr: ViewBreakpointsService, private readonly router: Router, private readonly location: Location, private readonly activatedRoute: ActivatedRoute, private readonly urlBuilder: UrlBuilderService) {}

    get isTablet() {
        switch (this.state.brakepoint) { 
            case ViewBreakpointsShared.ViewBreakpoints.LG: {
                return true;
            }

            default:
                return false;
        }
    }

    viewMoreClick() {
        this.seoLinks = this.seoLinks.map((x) => {
            x.valuesHidden = false;
            return x;
        });
        this.state = {
            ...this.state,
            viewMore: true,
        };
    }

    ngOnInit(): void {
        this.seoLinks = this.seoLinks.map((x) => {
            x.valuesHidden = true;
            return x;
        });

        this.vbr.currentBreakpoint$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((next) => {
            this.state.brakepoint = next;
            this.cdr.markForCheck();
        });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe(() => {
                if (this.containerRef && this.containerRef.nativeElement) {
                    this.containerRef.nativeElement.scrollLeft = 0;
                }
            });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    get resolvedData(): CatalogResolveData {
        return this.activatedRoute.snapshot.data[CATALOG_RESOLVE_KEY];
    }

    get sortedSeoLinks(): Array<ProductCategorySeoLinkDto> {
        return [...this.seoLinks.filter((link) => this.isActive(link)), ...this.seoLinks.filter((link) => !this.isActive(link))];
    }

    routerLink(link: ProductCategorySeoLinkDto): UrlBuilderRouterLinkDefinition {
 
        if (this.isActive(link)) {
            if (this.isVendorPage){
                return this.urlBuilder.routerLink({
                    type: Source.Manufacturer,
                    payload: {
                        vendorUrl: this.manufacturer.uri,
                    },
                });
            }
            else {
                return this.urlBuilder.routerLink({
                    type: Source.Catalog,
                    payload: {
                        productCategoryId: this.categoryId,
                    },
                });
            }
        } else {
            return this.urlBuilder.routerLink(link.source, {
                origUrl: link.uri,
            });
        }
    }

    isActive(link: ProductCategorySeoLinkDto): boolean {
        const found = this.isVendorPage ? this.seoLinks.find((r) => r.id === link.id) : this.resolvedData.category.categorySeoLinksRouteResolves.find((r) => r.id === link.id);
 
        let url = found ? found.uri : link.uri;

        if (!url.startsWith('/') && !(url.startsWith('http://') || url.startsWith('https://'))) {
            url = '/' + url;
        }

        return urlRemoveBlacklistQp(urlWithoutPagePostfix(this.location.path())) === urlRemoveBlacklistQp(urlWithoutPagePostfix(url));
    }

    trackById<T = number | string>(index: number, input: { id: T }): T {
        return input.id;
    }
}
