import { ApiModelProperty } from "@nestjs/swagger/dist/decorators/api-model-property.decorator";
import { SystemTaskDto } from "../models/entities/system-task.dto";
import { SystemTaskShared } from "../models/shared/system-task.shared";
import { Observable } from 'rxjs';

export enum ProductIntegrationsYandexEndpointPaths {
    importYandexXML = '/api/v1/product-tools/importYandexXML',
}

export interface ProductIntegrationsYandexEndpoint {
    importYandexXML(uploadedFile: any): Promise<ProductToolsImportYandexXMLResponse> | Observable<ProductToolsImportYandexXMLResponse>;
}

export class ProductToolsImportYandexXMLResponse {
    @ApiModelProperty({
        type: SystemTaskDto,
    })
    systemTask: SystemTaskDto<SystemTaskShared.TaskType.ProductImportYandexXML, SystemTaskShared.MultiEntriesResponse>;
}
