import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, retryWhen, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApiErrorHandlerService, AppBootstrapDataService, EnvironmentWebService, genericRetryStrategy, MessagesService } from '@interid/interid-site-web/core';
import { InteridWebEmailSubscriptionsDataAccess } from '@interid/interid-site-data-access/web';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppSessionService } from '@interid/interid-site-web/core-session';

interface FormValue {
    email: string;
}

interface CategoryPicture {
    url: string;
    categoryId: number;
    title: string;
}

interface State {
    form: UntypedFormGroup;
    images: Array<CategoryPicture>;
}

@Component({
    selector: 'app-shared-email-subscribe-a',
    templateUrl: './email-subscribe-a.component.html',
    styleUrls: ['./email-subscribe-a.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailSubscribeAComponent implements OnDestroy, OnInit {
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state = signal<State>({
        form: this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        }),
        images: [
            {
                categoryId: 373,
                title: '',
                url: '/assets/images/homepage/subscribe-banner/1.svg',
            },
            {
                categoryId: 25,
                title: '',
                url: '/assets/images/homepage/subscribe-banner/2.svg',
            },
            {
                categoryId: 7,
                title: '',
                url: '/assets/images/homepage/subscribe-banner/3.svg',
            },
            {
                categoryId: 1215,
                title: '',
                url: '/assets/images/homepage/subscribe-banner/4.svg',
            },
            {
                categoryId: 3,
                title: '',
                url: '/assets/images/homepage/subscribe-banner/5.svg',
            },
            {
                categoryId: 829,
                title: '',
                url: '/assets/images/homepage/subscribe-banner/6.svg',
            },
            {
                categoryId: 43,
                title: '',
                url: '/assets/images/homepage/subscribe-banner/7.svg',
            },
        ],
    });

    get formValue(): FormValue {
        return this.state().form.value;
    }

    constructor(@Inject(PLATFORM_ID) private readonly platformId: Object, private readonly appBootstrap: AppBootstrapDataService, private readonly fb: UntypedFormBuilder, private readonly env: EnvironmentWebService, private readonly messages: MessagesService, private readonly errorHandler: ApiErrorHandlerService, private readonly endpoint: InteridWebEmailSubscriptionsDataAccess, private readonly appSession: AppSessionService) {}

    ngOnInit(): void {
        this.appBootstrap.data$.pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$)).subscribe((next) => {
            this.state.update((prev) => ({
                ...prev,
                images: this.state().images.map((x) => {
                    x.title = next.productCategories.find((a) => a.id == x.categoryId).title;
                    return x;
                }),
            }));
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `shared.components.email_subscribe_a.${input}`;
    }

    ngSubmit(): void {
        if (!this.state().form.valid) {
            return;
        }

        const email = this.formValue.email;

        this.state().form.disable();

        this.endpoint
            .subscribe({
                email,
            })
            .pipe(retryWhen(genericRetryStrategy()), takeUntil(this.ngOnDestroy$))
            .subscribe({
                next: () => {
                    if (isPlatformBrowser(this.platformId)) {
                        if (window && window['gtag']) {
                            window['gtag']('event', 'submit', {
                                event_category: 'forms',
                                event_label: 'news',
                            });
                        }

                        if (window && window['ym'] && this.env.current.ymId) {
                            window['ym'](this.env.current.ymId, 'reachGoal', 'news');
                        }
                    }

                    this.messages.success({
                        message: this.t('subscribed'),
                        translate: true,
                    });

                    this.appSession.markAccountAsSubscribed();
                },
                error: (err) => {
                    this.errorHandler.handle(err);

                    this.state().form.enable();
                }
            });
    }
}
