import { PageMetadataSEO } from './common.shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export namespace RouteShared {
    export class RouteReplaceCommand {
        @ApiModelProperty()
        technicalUri: string;

        @ApiModelProperty()
        browserUri: string;
    }

    export class RouteResolveResult {
        @ApiModelProperty()
        originalUrl: string;

        @ApiModelProperty({
            required: false,
        })
        canonicalUrl?: string;

        @ApiModelProperty({
            required: false,
        })
        replaceWith?: RouteReplaceCommand;

        @ApiModelProperty({
            required: false,
        })
        seo?: PageMetadataSEO;
    }
}
