import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { QuotationRequestShared } from '../shared/quotation-request.shared';
import { AccountDto } from './account.dto';
import { ProductDto } from './product.dto';

export class QuotationRequestDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  product: ProductDto;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  createdFromURL: string;

  @ApiModelProperty()
  assignedTo: AccountDto;

  @ApiModelProperty()
  phone: string;

  @ApiModelProperty()
  inn: string;

  @ApiModelProperty()
  name?: string;

  @ApiModelProperty()
  email?: string;

  @ApiModelProperty()
  message?: string;

  @ApiModelProperty()
  status: QuotationRequestShared.Status;

  @ApiModelProperty()
  statusHistory: Array<QuotationRequestShared.StatusHistoryEntry>;
}
