import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { IsArray, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ArticleCategoryDto } from '../models/entities/article-category.dto';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ManufacturerListDto } from '../models/entities/manufacturer.dto';
import { ProductESDto } from '../models/entities/product.dto';
import { ArticleESDto } from '../models/entities/article.dto';
import { ProductSearchFiltersRequest, ProductSearchRequest, ProductSearchSortRequest } from '../endpoints/product.endpoint';
import { ArticleReviewDto } from '../models/entities/article-review.dto';
import { ProductReviewDto } from '../models/entities/product-review.dto';

export enum SearchWebEndpointPaths {
    stats = '/api/v1/web/search/stats',
    autocomplete = '/api/v1/web/search/autocomplete',
    searchProducts = '/api/v1/web/search/searchProducts',
    searchProductReviews = '/api/v1/web/search/searchProductReviews',
    searchArticles = '/api/v1/web/search/searchArticles',
    searchArticleReviews = '/api/v1/web/search/searchArticleReviews',
}

export interface SearchWebEndpoint {
    stats(request: SearchStatsWebRequest, req?: any): Promise<SearchStatsWebResponse> | Observable<SearchStatsWebResponse>;
    autocomplete(request: SearchAutocompleteWebRequest, req?: any): Promise<SearchAutocompleteWebResponse> | Observable<SearchAutocompleteWebResponse>;
    searchProducts(request: SearchProductWebRequest, req?: any): Promise<SearchProductWebResponse> | Observable<SearchProductWebResponse>;
    searchProductReviews(request: SearchProductReviewsWebRequest, req?: any): Promise<SearchProductReviewsWebResponse> | Observable<SearchProductReviewsWebResponse>;
    searchArticles(request: SearchArticlesWebRequest, req?: any): Promise<SearchArticlesWebResponse> | Observable<SearchArticlesWebResponse>;
    searchArticleReviews(request: SearchArticleReviewsWebRequest, req?: any): Promise<SearchArticleReviewsWebResponse> | Observable<SearchArticleReviewsWebResponse>;
}

export namespace SearchWebDTO {
    export class View {
        @ApiModelProperty()
        total: number;

        @ApiModelProperty()
        page: number;

        @ApiModelProperty()
        pageSize: number;
    }

    export class ProductCategoryStats {
        @ApiModelProperty()
        category: ProductCategoryListDto;

        @ApiModelProperty()
        count: number;
    }

    export class ManufacturersStats {
        @ApiModelProperty()
        manufacturer: ManufacturerListDto;

        @ApiModelProperty()
        count: number;
    }

    export class ArticleCategoryStats {
        @ApiModelProperty()
        category: ArticleCategoryDto;

        @ApiModelProperty()
        count: number;
    }
}

export class SearchStatsWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    queryString: string;
}

export class SearchStatsWebResponse {
    @ApiModelProperty()
    products: number;

    @ApiModelProperty()
    articles: number;

    @ApiModelProperty()
    articleReviews: number;

    @ApiModelProperty()
    productReviews: number;
}

export class SearchAutocompleteWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    searchQuery: string;
}

export class SearchAutocompleteWebResponse {
    @ApiModelProperty()
    hasResults: boolean;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    products: Array<ProductESDto>;

    @ApiModelProperty({
        type: ArticleESDto,
        isArray: true,
    })
    articles: Array<ArticleESDto>;

    @ApiModelProperty({
        type: ProductCategoryListDto,
        isArray: true,
    })
    productCategories: Array<ProductCategoryListDto>;

    @ApiModelProperty({
        type: ManufacturerListDto,
        isArray: true,
    })
    manufacturers: Array<ManufacturerListDto>;
}

export class SearchProductWebRequest {
    @ApiModelProperty({
        required: false,
    }) 
    queryString?: string;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    filters?: ProductSearchFiltersRequest;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsOptional()
    sort?: Array<ProductSearchSortRequest>;

    @ApiModelProperty({
        required: false,
    })
    pageSize?: number;

    @ApiModelProperty({
        required: false,
    })
    page?: number;
}

export class SearchProductWebResponse {
    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty()
    searchQuery: ProductSearchRequest;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    products: Array<ProductESDto>;

    @ApiModelProperty({
        type: SearchWebDTO.ManufacturersStats,
        isArray: true,
    })
    manufacturers: Array<SearchWebDTO.ManufacturersStats>;

    @ApiModelProperty({
        type: SearchWebDTO.ProductCategoryStats,
        isArray: true,
    })
    categories: Array<SearchWebDTO.ProductCategoryStats>;

    @ApiModelProperty()
    view: SearchWebDTO.View;
}

export class SearchArticleReviewsWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    queryString: string;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    articleCategoryId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;
}

export class SearchArticleReviewsWebResponse {
    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty({
        type: ArticleReviewDto,
        isArray: true,
    })
    articleReviews: Array<ArticleReviewDto>;

    @ApiModelProperty()
    view: SearchWebDTO.View;

    @ApiModelProperty({
        type: SearchWebDTO.ArticleCategoryStats,
        isArray: true,
    })
    categories: Array<SearchWebDTO.ArticleCategoryStats>;
}

export class SearchProductReviewsWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    queryString: string;

    @ApiModelProperty({
        required: false,
    })
    productCategoryId?: number;

    @ApiModelProperty({
        required: true,
    })
    pageSize: number;

    @ApiModelProperty({
        required: false,
    })
    page?: number;
}

export class SearchProductReviewsWebResponse {
    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty({
        type: ProductReviewDto,
        isArray: true,
    })
    productReviews: Array<ProductReviewDto>;

    @ApiModelProperty()
    view: SearchWebDTO.View;

    @ApiModelProperty({
        type: SearchWebDTO.ProductCategoryStats,
        isArray: true,
    })
    categories: Array<SearchWebDTO.ProductCategoryStats>;
}

export class SearchArticlesWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    queryString: string;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    articleCategoryId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;
}

export class SearchArticlesWebResponse {
    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty({
        type: ArticleESDto,
        isArray: true,
    })
    articles: Array<ArticleESDto>;

    @ApiModelProperty({
        type: SearchWebDTO.ArticleCategoryStats,
        isArray: true,
    })
    categories: Array<SearchWebDTO.ArticleCategoryStats>;

    @ApiModelProperty()
    view: SearchWebDTO.View;
}
