import * as qs from 'querystring';
import { withoutSlashes } from '../functions/without-slashes.util';

export namespace UrlGenerator {
    export function generateProductSearchURL(queryString: string, esQuerySerialized: string): string {
        return `/search/products?${qs.encode({
            q: queryString,
            ...qs.decode(esQuerySerialized),
        })}`;
    }

    export function generateArticlesSearchURL(queryString: string, articleCategoryId?: number): string {
        const qp: any = {
            q: queryString,
            c: articleCategoryId,
        };

        return `/search/articles?${qs.encode(qp)}`;
    }

    export function generateArticleReviewsSearchURL(queryString: string, articleCategoryId?: number): string {
        const qp: any = {
            q: queryString,
            c: articleCategoryId,
        };

        return `/search/article-reviews?${qs.encode(qp)}`;
    }

    export function generateProductReviewsSearchURL(queryString: string, productCategoryId?: number): string {
        const qp: any = {
            q: queryString,
            c: productCategoryId,
        };

        return `/search/product-reviews?${qs.encode(qp)}`;
    }

    export function generateCatalogQueryURL(categoryId: number | undefined, esQuerySerialized: string, page?: number): string {
        let baseUri = categoryId
            ? `/categories/index/id/${categoryId}`
            : `/categories`;

        if (page && page > 1) {
            baseUri = `${baseUri}/page/${page}`;
        }

        return esQuerySerialized
            ? `${baseUri}?${esQuerySerialized}`
            : baseUri;
    }

    export function generateProductUrl(productId: number, productUri?: string): string {
        return productUri
            ? `/kupit/${productId}-${productUri}`
            : `/kupit/${productId}`;
    }

    export function generateArticlesUrl(): string {
        return '/articles';
    }

    export function generateSolutionsUrl(): string {
        return '/solutions';
    }

    export function generateArticleUrl(articleId: number): string {
        return `/articles/details/id/${articleId}`;
    }

    export function generateArticleUrlWithSlug(articleCategoryUri: string, articleUri: string): string {
        return `/articles/${withoutSlashes(articleCategoryUri)}/${withoutSlashes(articleUri)}`;
    }

    export function generateManufacturerUrl(vendorUrl: string, categoryUrl?: string, options?: {
        page?: number;
        pageSize?: number;
        esQuerySerialized?: string;
    }): string {
        let baseUri = categoryUrl
            ? `/vendors/${vendorUrl}/${categoryUrl}`
            : `/vendors/${vendorUrl}`;

        if (options && options.page && options.page > 1) {
            baseUri = `${baseUri}/page/${options.page}`;
        }

        return options && options.esQuerySerialized
            ? `${baseUri}?${options.esQuerySerialized}`
            : baseUri;
    }

    export function generateSolutionUrl(solutionId: number): string {
        return `/solutions/details/id/${solutionId}`;
    }

    export function generateSolutionUrlWithSlug(articleCategoryUri: string, solutionUri: string): string {
        return `/solutions/${articleCategoryUri}/${solutionUri}`;
    }

    export function generateSolutionCategoryUrl(articleCategoryUri: string): string {
        return `/solutions/${articleCategoryUri}`;
    }

    export function generateDictionaryUrl(category: string): string {
        return `/dictionary/${category}`;
    }

    export function generateArticleCategoryUrl(articleCategoryUri?: string, page?: number): string {
        const baseUrl = articleCategoryUri
            ? `/articles/category/${articleCategoryUri}`
            : '/articles';

        return page
            ? `${baseUrl}?page=${page}`
            : baseUrl;
    }

    export function generateSalesUrl(productCategorySlug?: string, esQuerySerialized?: string): string {
        const baseUri = productCategorySlug
            ? `/sales/${productCategorySlug}`
            : `/sales`;

        return esQuerySerialized
            ? `${baseUri}?${esQuerySerialized}`
            : baseUri;
    }

    export function generateDiscountedUrl(productCategorySlug?: string, esQuerySerialized?: string): string {
        const baseUri = productCategorySlug
            ? `/discounted/${productCategorySlug}`
            : `/discounted`;

        return esQuerySerialized
            ? `${baseUri}?${esQuerySerialized}`
            : baseUri;
    }

    export function generateMarkingkitsUrl(productCategorySlug?: string, esQuerySerialized?: string): string {
        const baseUri = productCategorySlug
            ? `/marking-kits/${productCategorySlug}`
            : `/marking-kits`;

        return esQuerySerialized
            ? `${baseUri}?${esQuerySerialized}`
            : baseUri;
    }
}
