import { Observable, throwError, timer } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { apiOnlineStatusSubject } from '../subjects/api-online-status.subject';
import { isApiErrorResponse } from '@interid/interid-site-shared';

export const REPORT_OFFLINE_AFTER_ATTEMPTS_COUNT = 5;

export const genericRetryStrategy = ({
     retryDuration = 1000,
     excludedStatusCodes = [],
 }: {
    retryDuration?: number,
    excludedStatusCodes?: Array<number>,
} = {
    retryDuration: 1000,
}) => (attempts: Observable<any>) => {
    let countAttempts = 0;

    return attempts.pipe(
        mergeMap((error) => {
            if (! ('status' in error && error.status > 0)) {
                countAttempts++;
            }

            if (countAttempts === REPORT_OFFLINE_AFTER_ATTEMPTS_COUNT) {
                apiOnlineStatusSubject.next(false);
            }

            if (error.status) {
                if (! excludedStatusCodes.find(e => e === error.status)) {
                    return throwError(error);
                }
            } else if (isApiErrorResponse(error)) {
                const apiError = (error as {
                    code: string;
                    message: string;
                });

                return throwError(apiError);
            }

            return timer(retryDuration);
        }),
        finalize(() => {
            apiOnlineStatusSubject.next(true);
        }),
    );
};
