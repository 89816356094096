import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ProductSearchFiltersRequest, ProductSearchSortRequest } from './product.endpoint';
import { ProductExcelRow } from '../models/entities/product-excel.dto';
import { SystemTaskDto } from '../models/entities/system-task.dto';
import { SystemTaskShared } from '../models/shared/system-task.shared';

export enum ProductIntegrationsExcelEndpointPaths {
    exportToExcel = '/api/v1/product-excel/exportToExcel',
    importFromExcel = '/api/v1/product-excel/importFromExcel',
    productParamsOfCategory = '/api/v1/product-excel/productParamsOfCategory',
}

export interface ProductIntegrationsExcelEndpoint {
    exportToExcel(request: ProductIntegrationsExportToExcelRequest): Promise<ProductIntegrationsExportToExcelResponse> | Observable<ProductIntegrationsExportToExcelResponse>;
    importFromExcel(uploadedFile: any): Promise<ProductIntegrationsImportFromExcelResponse> | Observable<ProductIntegrationsImportFromExcelResponse>;
    productParamsOfCategory(request: ProductIntegrationsExcelProductParamsOfCategoryRequest): Promise<ProductIntegrationsExcelProductParamsOfCategoryResponse> | Observable<ProductIntegrationsExcelProductParamsOfCategoryResponse>;
}

export enum ProductIntegrationsExcelErrorCodes {
    ProductImportFromExcelInvalidColumn = 'ProductImportFromExcelInvalidColumn',
    ProductImportFromExcelInvalidField = 'ProductImportFromExcelInvalidField',
    ProductImportFromExcelNoIdField = 'ProductImportFromExcelNoIdField',
}

export class ProductIntegrationsExportToExcelRequest {
    @ApiModelProperty()
    @ValidateNested()
    @IsNotEmpty()
    filters: ProductSearchFiltersRequest;

    @ApiModelProperty({
        type: ProductSearchSortRequest,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsOptional()
    sort?: Array<ProductSearchSortRequest>;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
    })
    @IsString({ each: true })
    @IsArray()
    @IsNotEmpty()
    fields: Array<keyof ProductExcelRow>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    paramIds: Array<number>;
}

export class ProductIntegrationsExportToExcelResponse {
    @ApiModelProperty({
        type: SystemTaskDto,
    })
    systemTask: SystemTaskDto<SystemTaskShared.TaskType.ProductsExportToExcel, SystemTaskShared.MultiEntriesWithFileResponse>;
}

export class ProductIntegrationsImportFromExcelResponse {
    @ApiModelProperty({
        type: SystemTaskDto,
    })
    systemTask: SystemTaskDto<SystemTaskShared.TaskType.ProductsImportFromExcel, SystemTaskShared.MultiEntriesResponse>;
}

export class ProductIntegrationsExcelProductParamsOfCategoryRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productCategoryIds: Array<number>;
}

export class ProductIntegrationsExcelProductParam {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    isProductCategoryParam: boolean;
}

export class ProductIntegrationsExcelProductParamsOfCategoryResponse {
    @ApiModelProperty({
        type: ProductIntegrationsExcelProductParam,
        isArray: true,
    })
    params: Array<ProductIntegrationsExcelProductParam>;
}

