import { ProductESDto } from '../entities/product.dto';
import { ProductReviewDto } from '../entities/product-review.dto';
import { ArticleReviewDto } from '../entities/article-review.dto';
import { ArticleListDto } from '../entities/article.dto';
import { OrderDto } from '../entities/order.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export namespace BonusShared {
  export enum BonusSourceType {
    CustomIncome = 'customIncome',
    CustomExpense = 'customExpense',
    OrderExpense = 'orderExpense',
    ProductReview = 'productReview',
    ArticleReview = 'articleReview',
    OrderIncome = 'orderIncome',
    YandexMarket = 'yandexMarket',
  }

  export class BonusSourceRequest {
      @ApiModelProperty({
          type: 'string',
          enum: Object.values(BonusSourceType),
      })
      type: BonusSourceType;

      @ApiModelProperty({
          type: 'object',
      })
      payload: any;
  }

  export type BonusSource =
    { type: BonusSourceType.CustomIncome } |
    { type: BonusSourceType.CustomExpense } |
    { type: BonusSourceType.OrderExpense; payload: { orderId: number } } |
    { type: BonusSourceType.ProductReview; payload: { productReviewId: number; product: string } } |
    { type: BonusSourceType.ArticleReview; payload: { articleReviewId: number; article: string } } |
    { type: BonusSourceType.OrderIncome; payload: { orderId: number } } |
    { type: BonusSourceType.YandexMarket }
  ;

  export type BonusAssets =
    { type: BonusSourceType.OrderExpense; payload: { order: OrderDto } } |
    { type: BonusSourceType.ProductReview; payload: { productReview: ProductReviewDto; product: ProductESDto } } |
    { type: BonusSourceType.ArticleReview; payload: { articleReview: ArticleReviewDto; article: ArticleListDto } } |
    { type: BonusSourceType.OrderIncome; payload: { order: OrderDto } };

  export const bonusesMultiplierMap: Array<{
    type: BonusSourceType;
    multiplier: number;
  }> = [
    { type: BonusSourceType.CustomIncome, multiplier: 1 },
    { type: BonusSourceType.CustomExpense, multiplier: -1 },
    { type: BonusSourceType.OrderExpense, multiplier: -1 },
    { type: BonusSourceType.ProductReview, multiplier: 1 },
    { type: BonusSourceType.ArticleReview, multiplier: 1 },
    { type: BonusSourceType.OrderIncome, multiplier: 1 },
    { type: BonusSourceType.YandexMarket, multiplier: 1 },
  ];
}
