import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ArticleCategoryDto } from '../models/entities/article-category.dto';
import { ArticleHitWebRequest, CreateArticleReviewRequest } from '../endpoints/article-review.endpoint';
import { ArticleESDto } from '../models/entities/article.dto';
import { CommonContentWebBundleShared } from '../models/shared/common-content.shared';
import { ArticleAuthorDto } from '../models/entities/article-author.dto';
import { TagDto } from '../models/entities/tag.dto';
import { ArticleReviewDto } from '../models/entities/article-review.dto';
import { ArticleSearchShared } from '../models/shared/article.shared';
import { SolutionESDto } from '../models/entities/solution.dto';
import { ProductESDto } from '../models/entities/product.dto';

export enum ArticleWebEndpointPaths {
    search = '/api/v1/web/article/search',
    getById = '/api/v1/web/article/getById',
    getBySlug = '/api/v1/web/article/getBySlug',
    articlesPage = '/api/v1/web/article/articlesPage',
    comment = '/api/v1/web/article/comment',
    hit = '/api/v1/web/article/hit',
    tags = '/api/v1/web/article/tags',
}

export interface ArticleWebEndpoint {
    tags(): Promise<ArticlesTagsResponse> | Observable<ArticlesTagsResponse>;
    search(request: ArticleWebSearchRequest, req?: any): Promise<ArticleWebSearchResponse> | Observable<ArticleWebSearchResponse>;
    getById(request: ArticleWebGetByIdRequest, req?: any): Promise<ArticleWebGetByIdResponse> | Observable<ArticleWebGetByIdResponse>;
    getBySlug(request: ArticleWebGetBySlugRequest, req?: any): Promise<ArticleWebGetBySlugResponse> | Observable<ArticleWebGetBySlugResponse>;
    articlesPage(request: ArticlesPagesRequest, req?: any): Promise<ArticlesPageResponse> | Observable<ArticlesPageResponse>;
    comment(request: CreateArticleReviewRequest, req?: any): Promise<void> | Observable<void>;
    hit(request: ArticleHitWebRequest): Promise<void> | Observable<void>;
}

export class ArticleWebSearchRequest extends ArticleSearchShared.SearchRequest {}

export class ArticleWebSearchResponse extends ArticleSearchShared.SearchResponse {}

export class ArticleWebGetByIdRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    preview?: string;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ArticleWebGetByIdResponse {
    @ApiModelProperty()
    article: ArticleESDto;

    @ApiModelProperty({
        type: ArticleReviewDto,
        isArray: true,
    })
    articleReviews: Array<ArticleReviewDto>;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    articleProducts: Array<ProductESDto>;

    @ApiModelProperty()
    bundle: CommonContentWebBundleShared.Bundle;
}

export class ArticleNavigationItem {
    @ApiModelProperty()
    id: string;

    @ApiModelProperty()
    title: string;
}

export class ArticleNavigation {
    @ApiModelProperty()
    items: Array<ArticleNavigationItem>;

    @ApiModelProperty()
    enableNavigation: boolean;
}

export class ArticleWebGetBySlugRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    preview?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    articleUri: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    articleCategoryUri: string;
}

export class ArticleWebGetBySlugResponse {
    @ApiModelProperty()
    article: ArticleESDto;

    @ApiModelProperty({
        type: ArticleReviewDto,
        isArray: true,
    })
    articleReviews: Array<ArticleReviewDto>;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    articleProducts: Array<ProductESDto>;

    @ApiModelProperty()
    bundle: CommonContentWebBundleShared.Bundle;
}

export class ArticlesPagesRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    articleCategoryId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    articleCategoryUri?: string;
}

export class ArticleTagDto {
    inSolutionCategories: Array<number>;
    inArticleCategories: Array<number>;
    inArticleIds: Array<number>;
    inSolutionIds: Array<number>;
    tag: TagDto;
}

export class ArticlesTagsResponse {
    @ApiModelProperty({
        type: ArticleTagDto,
        isArray: true,
    })
    articleTags: Array<ArticleTagDto>;
}

export class ArticlesPageResponseCategory {
    @ApiModelProperty()
    dto: ArticleCategoryDto;

    @ApiModelProperty()
    numArticles: number;
}

export class ArticlesPageResponse {
    @ApiModelProperty({
        type: ArticleAuthorDto,
        isArray: true,
    })
    articleAuthors: Array<ArticleAuthorDto>;

    @ApiModelProperty({
        type: ArticlesPageResponseCategory,
        isArray: true,
    })
    articleCategories: Array<ArticlesPageResponseCategory>;

    @ApiModelProperty({
        type: TagDto,
        isArray: true,
    })
    articleTags: Array<TagDto>;

    @ApiModelProperty({
        type: SolutionESDto,
        isArray: true,
    })
    latestSolutions: Array<SolutionESDto>;

    @ApiModelProperty()
    bundles: CommonContentWebBundleShared.Bundle;
}
