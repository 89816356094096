import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ImportantEventShared, ImportantEventsSharedJSONMetadata } from '../shared/important-event.shared';

export class ImportantEventDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  level: ImportantEventShared.Level;

  @ApiModelProperty()
  type: ImportantEventShared.Type;

  @ApiModelProperty()
  jsonMetadata: ImportantEventsSharedJSONMetadata.JSONMetadata;
}
