export const withTrailingSlash = (input: string) => {
  if (! input) {
    return '';
  }

  const sInput = (input || '').toString();

  return sInput[0] === '/'
    ? sInput
    : `/${sInput}`;
};
