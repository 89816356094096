import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { FavoriteAddRequest, FavoriteBulkAddRequest, FavoriteDeleteRequest, FavoriteDto, FavoriteEndpointWebPaths, FavoriteListProductsRequest, FavoriteListRequest, FavoriteListResponse, FavoriteWebEndpoint, ProductESDto } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebFavoriteDataAccess implements FavoriteWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    add(request: FavoriteAddRequest): Observable<Array<FavoriteDto>> {
        return this.http.post(FavoriteEndpointWebPaths.add, request);
    }

    addBulk(request: FavoriteBulkAddRequest): Observable<void> {
        return this.http.post(FavoriteEndpointWebPaths.addBulk, request);
    }

    delete(request: FavoriteDeleteRequest): Observable<Array<FavoriteDto>> {
        return this.http.post(FavoriteEndpointWebPaths.delete, request);
    }

    list(request: FavoriteListRequest): Observable<FavoriteListResponse> {
        return this.http.post(FavoriteEndpointWebPaths.list, request);
    }

    listProducts(request: FavoriteListProductsRequest): Observable<Array<ProductESDto>> {
        return this.http.post(FavoriteEndpointWebPaths.listProducts, request);
    }

    destroy(): Observable<void> {
        return this.http.post(FavoriteEndpointWebPaths.destroy);
    }
}
