import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RouterStateSnapshot } from '@angular/router';
import { NotFoundService } from './not-found.service';

@Injectable({
    providedIn: 'root',
})
export class AppErrorHandlerService {
    constructor(
        private readonly notFound: NotFoundService,
    ) {
    }

    pipe<T>(options: {
        withNavigation: boolean;
        state?: RouterStateSnapshot;
    } = {
        withNavigation: true,
    }): (input: Observable<T>) => Observable<T> {
        return (input: Observable<T>) => input.pipe(
            catchError((err) => {
                if (options.withNavigation) {
                    this.notFound.goToNotFound(options.state);
                }

                return throwError(err);
            }),
        );
    }
}
