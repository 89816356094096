import { Observable } from 'rxjs';
import { IsArray, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, IsObject, ValidateNested, IsEmail, IsBoolean } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryCostShared } from '../models/shared/delivery-cost.shared';
import { AccountRequisitesDto } from '../models/entities/account-requisites.dto';
import { OrderShared } from '../models/shared/order.shared';
import { OrderDto } from '../models/entities/order.dto';
import { AccountRequisitesShared } from '../models/shared/account-requisites.shared';

export enum OrderEndpointPaths {
    list = '/api/v1/order/list',
    getById = '/api/v1/order/getById',
    create = '/api/v1/order/create',
    edit = '/api/v1/order/edit',
    delete = '/api/v1/order/delete',
    setStatus = '/api/v1/order/setStatus',
    assign = '/api/v1/order/assign',
    unassign = '/api/v1/order/unassign',
    calculateDeliveryCost = '/api/v1/order/calculateDeliveryCost',
    calculateProductsCost = '/api/v1/order/calculateProductsCost',
    calculateProductTaxesCost = '/api/v1/order/calculateProductTaxesCost',
    calculateOrderCost = '/api/v1/order/calculateOrderCost',
}

export interface OrderEndpoint {
    list(request: OrderListRequest): Promise<OrderListResponse> | Observable<OrderListResponse>;
    getById(request: OrderGetByIdRequest): Promise<OrderDto> | Observable<OrderDto>;
    create(request: OrderCreateRequest): Promise<OrderDto> | Observable<OrderDto>;
    edit(request: OrderEditRequest): Promise<OrderDto> | Observable<OrderDto>;
    delete(request: OrderDeleteRequest): Promise<void> | Observable<void>;
    setStatus(request: OrderSetStatusRequest): Promise<OrderDto> | Observable<OrderDto>;
    assign(request: OrderAssignRequest): Promise<OrderDto> | Observable<OrderDto>;
    unassign(request: OrderUnassignRequest): Promise<OrderDto> | Observable<OrderDto>;
    calculateDeliveryCost(request: OrderCalculateDeliveryCostRequest): Promise<OrderCalculateDeliveryCostResponse> | Observable<OrderCalculateDeliveryCostResponse>;
    calculateProductsCost(request: OrderCalculateProductsCostRequest): Promise<OrderCalculateProductsCostResponse> | Observable<OrderCalculateProductsCostResponse>;
    calculateProductTaxesCost(request: OrderCalculateProductTaxesCostRequest): Promise<OrderCalculateProductTaxesCostResponse> | Observable<OrderCalculateProductTaxesCostResponse>;
    calculateOrderCost(request: OrderCalculateOrderCostRequest): Promise<OrderCalculateOrderCostResponse> | Observable<OrderCalculateOrderCostResponse>;
}

export class OrderListRequest {
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    ids?: Array<number>;

    @ApiModelProperty()
    @IsEnum(OrderShared.Status, {
        each: true,
    })
    @IsArray()
    @IsOptional()
    statuses?: Array<OrderShared.Status>;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    search?: string;
}

export class OrderListResponse {
    @ApiModelProperty()
    total: number;

    @ApiModelProperty({
        type: OrderDto,
        isArray: true,
    })
    entities: Array<OrderDto>;
}

export class OrderGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class OrderBodyProductEntry {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    amount: number;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    price: string;
}

export class OrderProductTaxesEntry {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty({
        required: false,
    })
    taxes?: number | undefined;
}

export class OrderEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    accountId?: number;

    @ApiModelProperty()
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    products: Array<OrderBodyProductEntry>;

    @ApiModelProperty()
    paymentMethodType?: AccountRequisitesShared.PaymentMethod;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    deliveryCostId: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    deliveryTitle: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    autoCalculateOrderCost?: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    deliveryCost?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    orderCost?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    name?: string;

    @ApiModelProperty()
    @IsEmail()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    phone: string;

    @ApiModelProperty()
    @IsInt({ each: true })
    @IsPositive({ each: true })
    @IsArray()
    @IsOptional()
    documentAttachmentIds?: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    deliveryAddress: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    entrance: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    customerType: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    floor: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    room: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    deliveryCompanies: string;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsOptional()
    accountRequisites?: AccountRequisitesDto;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    comment: string;
}

export class OrderCreateRequest extends OrderEntityBody {
}

export class OrderEditRequest extends OrderEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class OrderDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class OrderSetStatusRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsEnum(OrderShared.Status)
    @IsNotEmpty()
    newStatus: OrderShared.Status;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    comment: string;
}

export class OrderAssignRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsEmail()
    @IsNotEmpty()
    accountEmail: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    comment: string;
}

export class OrderUnassignRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class OrderCalculateDeliveryCostRequest {
    @ApiModelProperty()
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsOptional()
    products?: Array<OrderBodyProductEntry>;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    deliveryCostId?: number;
}

export class OrderCalculateDeliveryCostResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DeliveryCostShared.CostType),
    })
    type: DeliveryCostShared.CostType;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    canBeApplied: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    mustInputManually: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    result?: string;
}

export class OrderCalculateProductsCostRequest {
    @ApiModelProperty()
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsOptional()
    products?: Array<OrderBodyProductEntry>;
}

export class OrderCalculateProductsCostResponse {
    @ApiModelProperty()
    result: string;
}

export class OrderCalculateProductTaxesCostRequest {
    @ApiModelProperty()
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsOptional()
    products?: Array<OrderBodyProductEntry>;
}

export class OrderCalculateProductTaxesCostResponse {
    @ApiModelProperty({
        required: false,
    })
    productsTaxes?: Array<OrderProductTaxesEntry>;

    @ApiModelProperty({
        required: false,
    })
    taxesSum?: string | undefined;
}

export class OrderCalculateOrderCostRequest {
    @ApiModelProperty()
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsOptional()
    products?: Array<OrderBodyProductEntry>;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    deliveryCost?: string;
}

export class OrderCalculateOrderCostResponse {
    @ApiModelProperty()
    result: string;
}
