import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { REQUEST } from '@interid/interid-site-web/ui-shared';

@Injectable({
    providedIn: 'root',
})
export class HostHeaderHttpInterceptor implements HttpInterceptor {
    constructor(private readonly injector: Injector, @Inject(PLATFORM_ID) private platformId: Object) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (isPlatformServer(this.platformId)) {
            const newReq = req.clone();
            const expressReq = this.injector.get<Request>(REQUEST);

            newReq.headers.set('X-Host', expressReq.hostname);

            return next.handle(newReq);
        } else {
            return next.handle(
                req.clone({
                    headers: req.headers.set('X-Host', window.location.hostname),
                })
            );
        }
    }
}
