import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutCommonComponent } from './layouts/layout-common/layout-common.component';
import { AttachmentImageComponent } from './components/attachment-image/attachment-image.component';
import { BlockerComponent } from './components/blocker/blocker.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DialogCloseComponent } from './components/dialog-close/dialog-close.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { FluidContainerMobileComponent } from './components/fluid-container-mobile/fluid-container-mobile.component';
import { ImageGalleryModalComponent } from './components/image-gallery-modal/image-gallery-modal.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingCircleComponent } from './components/loading-circle/loading-circle.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PhoneCallRequestComponent } from './components/phone-call-request/phone-call-request.component';
import { PhoneCallRequestModalComponent } from './components/phone-call-request-modal/phone-call-request-modal.component';
import { PhoneCallRequestV2Component } from './components/phone-call-request-v2/phone-call-request-v2.component';
import { ShareWithSocialNetworksComponent } from './components/share-with-social-networks/share-with-social-networks.component';
import { StarsRatingComponent } from './components/stars-rating/stars-rating.component';
import { UIAutocompleteComponent } from './components/ui-autocomplete/ui-autocomplete.component';
import { UIButtonComponent } from './components/ui-button/ui-button.component';
import { UICheckboxComponent } from './components/ui-checkbox/ui-checkbox.component';
import { UICheckboxColorComponent } from './components/ui-checkbox-color/ui-checkbox-color.component';
import { UIErrorsComponent } from './components/ui-errors/ui-errors.component';
import { UIInputComponent } from './components/ui-input/ui-input.component';
import { UIInputIntegerComponent } from './components/ui-input-integer/ui-input-integer.component';
import { UILoadMoreButtonComponent } from './components/ui-load-more-button/ui-load-more-button.component';
import { UIProgressSimpleComponent } from './components/ui-progress-simple/ui-progress-simple.component';
import { UIRadioButtonComponent } from './components/ui-radio-button/ui-radio-button.component';
import { UISelectComponent } from './components/ui-select/ui-select.component';
import { UIStarsRatingComponent } from './components/ui-stars-rating/ui-stars-rating.component';
import { UITextareaComponent } from './components/ui-textarea/ui-textarea.component';
import { YoutubePlayerFlexComponent } from './components/youtube-player-flex/youtube-player-flex.component';
import { InteridSiteWebUiSharedMaterialModule } from './interid-site-web-ui-shared-material.module';
import { UIConfirmBoxComponent } from './components/ui-confirm-box/ui-confirm-box.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { EmailHrefPipe } from './pipes/email-href.pipe';
import { H1Pipe } from './pipes/h1.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { PhoneHrefPipe } from './pipes/phone-href.pipe';
import { ReplaceUriPipe } from './pipes/replace-uri.pipe';
import { RtfMultilinePipe } from './pipes/rtf-multiline.pipe';
import { RuDatePipe } from './pipes/ru-date.pipe';
import { RuDateFromPipe } from './pipes/ru-date-from.pipe';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';
import { TrustResourceUrlPipe } from './pipes/trust-resource-url.pipe';
import { TrustUrlPipe } from './pipes/trust-url.pipe';
import { UISelectOptionsComponent } from './components/ui-select/ui-select-options/ui-select-options.component';
import { UISelectOptionsModalComponent } from './components/ui-select/ui-select-options-modal/ui-select-options-modal.component';
import { PaginatorPopupComponent } from './components/paginator/paginator-popup/paginator-popup.component';
import { EmailSubscribeAComponent } from './components/email-subscribe-a/email-subscribe-a.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { RemoveExtension } from './pipes/filename-extension.pipe';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { MenuForCustomersComponent } from './components/menu-for-customers/menu-for-customers.component';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { ProductRequestModalComponent } from './components/product-request-modal/product-request-modal.component';
import { ProductRequestComponent } from './components/product-request/product-request.component';
import { NgxDadataCityComponent } from './components/purchase-basket-autocomplete-city/purchase-basket-autocomplete-city.component';
import { NgxDadataItemsComponent } from './components/purchase-basket-autocomplete-items/purchase-basket-autocomplete-items.component';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { WsSliderModule } from './components/ui-slider/ws-slider.module';

const SHARED_COMPONENTS = [
    LayoutCommonComponent,
    AttachmentImageComponent,
    BlockerComponent,
    MenuForCustomersComponent,
    BreadcrumbsComponent,
    ContactUsComponent,
    DialogCloseComponent,
    EmailSubscribeAComponent,
    EmptyStateComponent,
    FluidContainerMobileComponent,
    ImageGalleryModalComponent,
    LoadingComponent,
    LoadingCircleComponent,
    PaginatorComponent,
    PaginatorPopupComponent,
    PhoneCallRequestComponent,
    PhoneCallRequestModalComponent,
    PhoneCallRequestV2Component,
    ProductRequestComponent,
    ProductRequestModalComponent,
    ShareWithSocialNetworksComponent,
    StarsRatingComponent,
    UIAutocompleteComponent,
    UIButtonComponent,
    UICheckboxComponent,
    UICheckboxColorComponent,
    UIConfirmBoxComponent,
    UIErrorsComponent,
    UIInputComponent,
    UIInputIntegerComponent,
    UILoadMoreButtonComponent,
    UIProgressSimpleComponent,
    UIRadioButtonComponent,
    UISelectComponent,
    UISelectOptionsComponent,
    UISelectOptionsModalComponent,
    UIStarsRatingComponent,
    UITextareaComponent,
    YoutubePlayerFlexComponent,
    NgxDadataCityComponent,
    NgxDadataItemsComponent,
];

const SHARED_PIPES = [
    EmailHrefPipe,
    H1Pipe,
    KeysPipe,
    MoneyPipe,
    PhoneHrefPipe,
    ReplaceUriPipe,
    RtfMultilinePipe,
    RuDatePipe,
    ShortDatePipe,
    FileSizePipe,
    RemoveExtension,
    RuDateFromPipe,
    TrustHtmlPipe,
    TrustResourceUrlPipe,
    TrustUrlPipe,
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forChild([]),
        InteridSiteWebUiSharedMaterialModule,
        TranslateModule.forChild(),
        PinchZoomModule,
        RecaptchaV3Module,
        WsSliderModule,
    ],
    declarations: [
        ...SHARED_COMPONENTS,
        ...SHARED_PIPES,
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        InteridSiteWebUiSharedMaterialModule,
        RouterModule,
        WsSliderModule,
        ...SHARED_COMPONENTS,
        ...SHARED_PIPES,
    ],
})
export class InteridSiteWebUiSharedModule {
}
