export const interidSiteWebFeatureSaleI18n = {
    sales: {
        components: {
            sales: {
                title: 'Акции',
                category: 'Категория'
            },
        },
    },
};
