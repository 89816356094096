export const interidSiteWebFeatureSolutionsI18n = {
    solutions: {
        components: {
            solutions: {
                title: 'Услуги и решения',
                articles: 'Статьи',
                allArticles: 'Все статьи',
            },
            solutions_entity: {
                preview: 'Предпросмотр: эта статья еще не опубликована на сайте',
            },
        },
    },
};
