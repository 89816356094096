import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryDto } from '../models/entities/delivery.dto';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto } from '../core/crud';

export enum DeliveryEndpointPaths {
    createDelivery = '/api/v1/deliveries/create',
    editDelivery = '/api/v1/deliveries/edit',
    setSortOrder = '/api/v1/deliveries/setSortOrder',
    deleteDelivery = '/api/v1/deliveries/delete',
    listDeliveries = '/api/v1/deliveries/list',
}

export interface DeliveryEndpoint {
    create(request: CreateDeliveryRequest): Promise<DeliveryDto> | Observable<DeliveryDto>;
    edit(request: EditDeliveryRequest): Promise<DeliveryDto> | Observable<DeliveryDto>;
    setSortOrder(request: SetSortOrderDeliveryRequest): Promise<void> | Observable<void>;
    delete(request: DeleteDeliveryRequest): Promise<void> | Observable<void>;
    list(request: ListDeliveriesRequest): Promise<ListDeliveriesResponse> | Observable<ListDeliveriesResponse>;
}

export class DeliveryEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsOptional()
    additionally: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    shortDescription: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    description: string;

    @ApiModelProperty()
    @IsNumber()
    @IsOptional()
    minBasketCost: number;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    cityIds: Array<number>;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    shouldRequestDeliveryAddress: boolean;
}

export class CreateDeliveryRequest extends DeliveryEntityBody {
}

export class EditDeliveryRequest extends DeliveryEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteDeliveryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListDeliveriesRequest extends CrudListRequestDto<DeliveryDto> {
}

export class ListDeliveriesResponse extends CrudListResponseDto<DeliveryDto> {
    @ApiModelProperty({
        type: DeliveryDto,
        isArray: true,
    })
    items: Array<DeliveryDto>;
}

export class SetSortOrderDeliveryRequest extends CrudSetSortOrderRequestDto {}

