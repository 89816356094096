import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Link } from '../shared/common.shared';
import { AttachmentDto } from './attachment.dto';
import { SortOrderShared } from '../shared/sort-order.shared';

export class BannerDto implements SortOrderShared.SortableEntity {

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  link: Link;

  @ApiModelProperty()
  image: AttachmentDto;

  @ApiModelProperty()
  mobileImage: AttachmentDto;

  @ApiModelProperty()
  catalogImage: AttachmentDto;

  @ApiModelProperty()
  productCategorId: number;

  @ApiModelProperty()
  class?: string;

  @ApiModelProperty()
  locationIsMain: boolean;

  @ApiModelProperty()
  locationIsMainAdditional: boolean;

  @ApiModelProperty()
  locationIsCatalog: boolean;
}
