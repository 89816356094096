<div class='c'>
  @if (helper.reviews.hasRating) {
    <div class='c-left'>
      @if (withRating) {
        <div class='c-rating-stars'>
          <app-shared-stars-rating [current]='helper.product.rating'></app-shared-stars-rating>
        </div>
      }
      @if (withReviews) {
        <div class='c-rating-desc'><span>{{ helper.product.ratingCount | appProductReviews }}</span>
      </div>
    }
  </div>
}
<div class='c-right'>
  @if (withIsAvailableOnDepot && (helper.product.flags.isAvailableDepot || helper.product.flags.isAvailableVendorDepot)) {
    <div class='c-is-available'
      [ngClass]='isAvailableNgClass' matTooltip="{{ t('isAvailableOnVendorDepot') | translate }}"
      [matTooltipDisabled]='! helper.product.flags.isAvailableVendorDepot || helper.product.flags.isAvailableDepot'>
      <div class='c-is-available-icon'>
        <div class='icon icon-16x16px'>
          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fill-rule='evenodd' clip-rule='evenodd'
            d='M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14ZM10.3 5.3L7 8.6L5.7 7.3C5.3 6.9 4.7 6.9 4.3 7.3C3.9 7.7 3.9 8.3 4.3 8.7L6.3 10.7C6.5 10.9 6.7 11 7 11C7.3 11 7.5 10.9 7.7 10.7L11.7 6.7C12.1 6.3 12.1 5.7 11.7 5.3C11.3 4.9 10.7 4.9 10.3 5.3Z'></path>
          </svg>
        </div>
      </div>
      <div class='c-is-available-text'>@if (helper.product.flags.isAvailableDepot) {
        <span
        >{{ t('isAvailable') | translate }}</span>
        }@if (helper.product.flags.isAvailableVendorDepot && ! helper.product.flags.isAvailableDepot) {
        <span
        >{{ t('isAvailable') | translate }}</span>
      }
    </div>
  </div>
}
</div>
</div>
