import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';
import { ManufacturerDto } from './manufacturer.dto';
import { SortOrderShared } from '../shared/sort-order.shared';

export class FrontpageManufacturerDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  manufacturer: ManufacturerDto;

  @ApiModelProperty()
  image: AttachmentDto;
}
