import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsObject, IsPositive, Max, Min, ValidateNested } from 'class-validator';
import { SystemTaskDto } from '../models/entities/system-task.dto';
import { Product1CShared } from '../models/shared/product-1c.shared';
import { SystemTaskShared } from '../models/shared/system-task.shared';
import { Observable } from 'rxjs';
import { ProductUuidDto } from '../models/entities/product-uuids.dto';

export enum ProductIntegrations1cEndpointPaths {
    importFrom1C = '/api/v1/product/importFrom1C',
    validateImportFrom1C = '/api/v1/product/validateImportFrom1C',
    exportUUIDS = '/api/v1/product-tools/exportUUIDS',
}

export interface ProductIntegrations1cEndpoint {
    validateImportFrom1C(file: File | any): Promise<ProductValidateImportFrom1CResponse> | Observable<ProductValidateImportFrom1CResponse>;
    importFrom1C(file: File | any): Promise<ProductImportFrom1CResponse> | Observable<ProductImportFrom1CResponse>;
    exportUUIDS(request: ProductToolExportUUIDsRequest): Promise<ProductToolsExportUUIDsResponse> | Observable<ProductToolsExportUUIDsResponse>;
}

export class ProductValidateImportFrom1CResponse {
    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    results: Product1CShared.ValidatioResults;
}

export class ProductImportFrom1CResponse {
    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    systemTask: SystemTaskDto<SystemTaskShared.TaskType.ProductImportFrom1C, SystemTaskShared.MultiEntriesResponse>;
}

export class ProductToolExportUUIDsRequest {
    @ApiModelProperty()
    @Min(0)
    @Max(100)
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;
}

export class ProductToolsExportUUIDsResponse {
    @ApiModelProperty({
        type: ProductUuidDto,
        isArray: true,
    })
    items: Array<ProductUuidDto>;

    @ApiModelProperty()
    total: number;
}
