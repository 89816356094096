<div class="__app-content content-block-html" [ngClass]="{'is-article': isArticle()}">
  <div [ngClass]="{ 'content-block-html-cutoff': useCutoff() && state().shouldBeCutted }" #contents>
    @if (useCutoff() && state().shouldBeCutted) {
      <div class="content-block-html-shadow"></div>
    }
    <div [innerHTML]="html$ | async | trustHtml"></div>
  </div>
  @if (useCutoff() && state().shouldBeCutted) {
    <div class="content-block-html-read-more" (click)="viewMore()"><span>{{ t('viewMore') | translate }}</span></div>
  }
  @if (useCutoff() && !state().shouldBeCutted) {
    <div class="content-block-html-read-more" (click)="viewMore()"><span>{{ t('hideMore') | translate }}</span></div>
  }
</div>
