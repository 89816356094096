import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

const KEY_PREFIX = 'interid_web';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private _fallback: any = {};

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: Object,
    ) {
    }

    get(key: string): string {
        const prefixedKey = this.getPrefixedKey(key);

        if (isPlatformBrowser(this.platformId)) {
            return window.localStorage.getItem(prefixedKey) as string;
        } else {
            return this._fallback[prefixedKey];
        }
    }

    set(key: string, value: string): void {
        const prefixedKey = this.getPrefixedKey(key);

        if (isPlatformBrowser(this.platformId)) {
            window.localStorage.setItem(prefixedKey, value);
        } else {
            return this._fallback[prefixedKey];
        }
    }

    remove(key: string): void {
        const prefixedKey = this.getPrefixedKey(key);

        if (isPlatformBrowser(this.platformId)) {
            window.localStorage.setItem(prefixedKey, null as any);
        } else {
            delete this._fallback[prefixedKey];
        }
    }

    private getPrefixedKey(key: string) {
        return `${KEY_PREFIX}-${key}`;
    }
}
