@if (helper.params.hasCardProductBagesParams) {
  <div class="c">
    <div class="c-bages ___hide-mobile-scroll" [attr.data-bages-product-id]="helper.product.id" #elemt (mousedown)="startDragging($event, false, elemt)" (mouseup)="stopDragging($event, false)" (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, elemt)" data-keen-slider-clickable>
      @for (params of helper.params.cardProductBagesParams; track params) {
        @if (params.paramsType === 'dict') {
          @if (params.dictIconPath) {
            <div class="c-bages-bage" [matTooltip]="params.dictTooltip">
              <img loading="lazy" [src]="url(params.dictIconPath)" />
            </div>
          }
        }
        @if (params.paramsType === 'dictMany') {
          @for (bage of dictManyStaticBagesArray(params.dictManyStaticBagesArray); track trackByIconPath($index, bage)) {
            @if (bage.iconPath) {
              <div class="c-bages-bage" [matTooltip]="bage.tooltip">
                <img loading="lazy" [src]="url(bage.iconPath)" />
              </div>
            }
          }
        }
      }
    </div>
  </div>
}
