
<div class="__app-fluid-container" [ngClass]="ngClassFluidContainer">
  <div class="c" [ngClass]="ngClass">
    @if (withBreadcrumbs && breadcrumbs) {
      <div class="c-breadcrumbs">
        <app-shared-breadcrumbs [definition]="breadcrumbs"></app-shared-breadcrumbs>
      </div>
    }
    @if (withTitle) {
      <h1 class="c-title"><span>{{ title }}</span></h1>
    }
    <div class="c-contents">
      @if (withMenu) {
        <div class="c-contents-menu">
          <ng-content select="[layout-section=menu]"></ng-content>
        </div>
      }
      <div class="c-contents-contents">
        <ng-content select="[layout-section=contents]"></ng-content>
      </div>
    </div>
  </div>
</div>
