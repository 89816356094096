import { CommonUrlBuilderShared, Source, SourceDefinition } from '../shared/common.shared';
import { ProductEsSearchUrlGeneratorHelper } from './product-es-search-url-generator.helper';
import SourceCatalogQueryPayload = CommonUrlBuilderShared.SourceCatalogQueryPayload;
import SourceDictionaryPayload = CommonUrlBuilderShared.SourceDictionaryPayload;
import SourceProductSearchQueryPayload = CommonUrlBuilderShared.SourceProductSearchQueryPayload;

export function urlDetectDefinition(url: string): SourceDefinition {
  if (url.match(/\/articles\/(details|edit|index)\/id\/(\d+)/)) {
    const matches = url.match(/\/articles\/(details|edit|index)\/id\/(\d+)/);

    if (matches && matches[2]) {
      const id = parseInt(matches[2], 10);

      return {
        type: Source.Article,
        payload: {
          articleId: id,
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/articles\/([a-zA-Z\d\-_]*)\/([a-zA-Z\d\-_]*)/)) {
    const matches = url.match(/\/articles\/([a-zA-Z\d\-_]*)\/([a-zA-Z\d\-_]*)/);

    if (matches && matches[1] && matches[2]) {
      return {
        type: Source.Article,
        payload: {
          articleUri: matches[2],
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/articles\/([a-zA-Z\d\-_]*)/)) {
    const matches = url.match(/\/articles\/([a-zA-Z\d\-_]*)/);

    if (matches && matches[1]) {
      return {
        type: Source.ArticleCategory,
        payload: {
          articleCategoryUri: matches[1],
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/(product|products)\/(details|edit|index)\/id\/(\d+)/)) {
    const matches = url.match(/\/(product|products)\/(details|edit|index)\/id\/(\d+)/);

    if (matches && matches[3]) {
      const id = parseInt(matches[3], 10);

      return {
        type: Source.Product,
        payload: {
          productId: id,
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/kupit\/(\d+)/)) {
    const matches = url.match(/\/kupit\/(\d+)/);

    if (matches && matches[1]) {
      const id = parseInt(matches[1], 10);

      return  {
        type: Source.Product,
        payload: {
          productId: id,
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/vendors\/([a-zA-Z\d\-_]*)/)) {
    const matches = url.match(/\/vendors\/([a-zA-Z\d\-_]*)/);

    if (matches && matches[1]) {
      const vendorUrl = matches[1];

      return {
        type: Source.Manufacturer,
        payload: {
            vendorUrl,
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/estimates\/(details|edit|index)\/id\/(\d+)/)) {
    const matches = url.match(/\/estimates\/(details|edit|index)\/id\/(\d+)/);

    if (matches && matches[2]) {
      const id = parseInt(matches[2], 10);

      return {
        type: Source.Solution,
        payload: {
          solutionId: id,
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/solutions\/([a-zA-Z\d\-_]*)\/([a-zA-Z\d\-_]*)/)) {
    const matches = url.match(/\/solutions\/([a-zA-Z\d\-_]*)\/([a-zA-Z\d\-_]*)/);

    if (matches && matches[1] && matches[2]) {
      return {
        type: Source.Solution,
        payload: {
          solutionUri: matches[2],
          solutionCategoryUri: matches[1],
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/solutions\/([a-zA-Z\d\-_]*)/)) {
    const matches = url.match(/\/solutions\/([a-zA-Z\d\-_]*)/);

    if (matches && matches[1]) {
      return {
        type: Source.SolutionCategory,
        payload: {
          solutionCategoryUri: matches[1],
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/categories\/(details|edit|index)\/id\/(\d+)/)) {
    const matches = url.match(/\/categories\/(details|edit|index)\/id\/(\d+)/);

    if (matches && matches[2]) {
      const id = parseInt(matches[2], 10);

      const urlHelper = new ProductEsSearchUrlGeneratorHelper();

      urlHelper.parseFromUrlRequest({
        url: url,
      });

      const esQuery = urlHelper.toProductESSearchObject();

      const payload: SourceCatalogQueryPayload = {
        productCategoryId: id,
      };

      if (Object.keys(esQuery).length > 0) {
        payload.esQuery = esQuery;
      }

      if (
        esQuery
        && esQuery.filters
        && Array.isArray(esQuery.filters.productCategoryIds)
        && esQuery.filters.productCategoryIds.length === 1
        && esQuery.filters.productCategoryIds[0] === payload.productCategoryId
      ) {
        delete esQuery.filters.productCategoryIds;
      }

      return {
        type: Source.Catalog,
        payload,
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/dictionary\/([a-zA-Z\d\-_]*)/)) {
    const matches = url.match(/\/dictionary\/([a-zA-Z\d\-_]*)/);

    if (matches && matches[1]) {
      const category = matches[1];

      const payload: SourceDictionaryPayload = {
        category,
      };

      return {
        type: Source.Dictionary,
        payload,
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/sales\/([a-zA-Z\d\-_]*)/)) {
    const matches = url.match(/\/sales\/([a-zA-Z\d\-_]*)/);

    if (matches && matches[1]) {
      return {
        type: Source.Sales,
        payload: {
          productCategoryUri: matches[1],
        },
      };
    } else {
      return {
        type: Source.Uri,
      };
    }
  } else if (url.match(/\/sales/)) {
    return {
      type: Source.Sales,
      payload: {},
    };
  } else {
    return {
      type: Source.Uri,
    };
  }
}
