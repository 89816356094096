import { ChangeDetectionStrategy, Component, Inject, Injectable } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { DEFAULT_MESSAGES_DURATION } from '../constants/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { Router } from '@angular/router';

export interface MessagesRequest {
    message: string;
    translate?: boolean;
    translateParams?: any;
    duration?: number;
}

@Injectable({
    providedIn: 'root',
})
export class MessagesService {
    constructor(readonly appSessionService: AppSessionService, private readonly translate: TranslateService, readonly matSnackbar: MatSnackBar) {}

    httpError(): void {
        this.error({
            message: '_.http_error',
            translate: true,
        });
    }

    success(request: MessagesRequest): void {
        this.message(request, 'success');
    }

    error(request: MessagesRequest): void {
        this.message(request, 'error');
    }

    info(request: MessagesRequest): void {
        this.message(request, 'info');
    }

    basketCompare(request: MessagesRequest, type: 'basket' | 'compare' | 'favorite', count): void {
        this.basketCompareMessage(request, type, count);
    }

    cookie(request: MessagesRequest): void {
        this.cookieMessage(request);
    }

    warning(request: MessagesRequest): void {
        this.message(request, 'warning');
    }

    validationError(request: MessagesRequest): void {
        this.validationMessage(request);
    }

    private validationMessage(request: MessagesRequest): void {
        this.matSnackbar.openFromComponent(SnackBarValidationComponent, {
            duration: request.duration ?? DEFAULT_MESSAGES_DURATION,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: `__app-message-info`,
            data: { ...request, message: request.message },
        });
    }

    private basketCompareMessage(request: MessagesRequest, type: 'basket' | 'compare' | 'favorite', count) {
        const message = request.translate ? this.translate.instant(request.message, request.translateParams) : request.message;

        this.matSnackbar.openFromComponent(SnackBarBasketCompareComponent, {
            duration: request.duration ?? DEFAULT_MESSAGES_DURATION,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: `__app-message-info`,
            data: { ...request, message, type, count: count },
        });
    }

    private cookieMessage(request: MessagesRequest): void {
        this.matSnackbar.openFromComponent(SnackBarCookieComponent, {
            duration: 0,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: `__app-message-info`,
            data: request,
        });
    }

    private message(request: MessagesRequest, className: string): void {
        const message = request.translate ? this.translate.instant(request.message, request.translateParams) : request.message;

        this.matSnackbar.openFromComponent(SnackBarInfoComponent, {
            duration: request.duration ?? DEFAULT_MESSAGES_DURATION,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: `__app-message-${className}`,
            data: { ...request, message: message },
        });
    }
}

@Component({
    templateUrl: '../components/snack-bar-validation/snack-bar-component-snack.html',
    styleUrls: ['../components/snack-bar-info/snack-bar-component-snack.scss', '../components/snack-bar-validation/snack-bar-component-snack.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarValidationComponent {
    constructor(private snackBarRef: MatSnackBarRef<SnackBarValidationComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

    close() {
        this.snackBarRef.dismiss();
        event.preventDefault();
    }
}

@Component({
    templateUrl: '../components/snack-bar-basket-compare/snack-bar-component-snack.html',
    styleUrls: ['../components/snack-bar-info/snack-bar-component-snack.scss', '../components/snack-bar-basket-compare/snack-bar-component-snack.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarBasketCompareComponent {
    constructor(private readonly router: Router, private snackBarRef: MatSnackBarRef<SnackBarBasketCompareComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

    toFavorite(){
        this.router.navigate(['/favorites'], {});
    }

    toCompare() {
        this.router.navigate(['/compares'], {});
    }

    toBasket() {
        this.router.navigate(['/purchase/basket'], {});
    }

    close() {
        this.snackBarRef.dismiss();
        event.preventDefault();
    }
}

@Component({
    templateUrl: '../components/snack-bar-info/snack-bar-component-snack.html',
    styleUrls: ['../components/snack-bar-info/snack-bar-component-snack.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarInfoComponent {
    constructor(private snackBarRef: MatSnackBarRef<SnackBarInfoComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

    close() {
        this.snackBarRef.dismiss();
        event.preventDefault();
    }
}

@Component({
    templateUrl: '../components/snack-bar-cookie/snack-bar-component-snack.html',
    styleUrls: ['../components/snack-bar-info/snack-bar-component-snack.scss', '../components/snack-bar-cookie/snack-bar-component-snack.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarCookieComponent {
    constructor(readonly appSession: AppSessionService, private snackBarRef: MatSnackBarRef<SnackBarCookieComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

    close() {
        this.snackBarRef.dismiss();
        this.appSession.markCookiePolicyAccepted();
        event.preventDefault();
    }
}
