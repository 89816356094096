import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { ProductQuestionShared } from '../models/shared/product-question.shared';
import { ProductQuestionLikeDto } from '../models/entities/product-question-like.dto';

export enum ProductQuestionsLikesWebEndpointPaths {
    list = '/api/v1/web/product-questions-likes/list',
    like = '/api/v1/web/product-questions-likes/like',
    setType = '/api/v1/web/product-questions-likes/setType',
    unlike = '/api/v1/web/product-questions-likes/unlike'
}

export interface ProductQuestionsLikesWebEndpoint {
    like(request: CreateProductQuestionLikeRequest): Promise<ListProductQuestionLikeResponse> | Observable<ListProductQuestionLikeResponse>;
    list(request: ListProductQuestionLikeRequest): Promise<ListProductQuestionLikeResponse> | Observable<ListProductQuestionLikeResponse>;
    setType(request: UpdateProductQuestionLikeRequest): Promise<ListProductQuestionLikeResponse> | Observable<ListProductQuestionLikeResponse>;
    unlike(request: DeleteProductQuestionLikeRequest): Promise<ListProductQuestionLikeResponse> | Observable<ListProductQuestionLikeResponse>;
}

export class ListProductQuestionLikeResponse {
    @ApiModelProperty()
    likes: Array<ProductQuestionLikeDto>;
}

export class ListProductQuestionLikeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productQuestionId: number;
}

export class CreateProductQuestionLikeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productQuestionId: number;

    @ApiModelProperty()
    type: ProductQuestionShared.LikeType;
}

export class UpdateProductQuestionLikeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productQuestionId: number;

    @ApiModelProperty()
    type: ProductQuestionShared.LikeType;
}

export class DeleteProductQuestionLikeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productQuestionId: number;
}