import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Environments } from '../../environments';

export enum StatusLive {
    OK = 'OK',
}

export class StatusDto {
    @ApiModelProperty({
        description: 'Live status (OK)',
        enum: Object.values(StatusLive),
    })
    live: StatusLive;

    @ApiModelProperty({
        description: 'Memory usage (MB)',
    })
    memoryUsageMB: number;

    @ApiModelProperty({
        description: 'Client IP',
    })
    clientIp: string;

    @ApiModelProperty({
        description: 'Git version (GIT_VERSION)'
    })
    gitVersion: string;

    @ApiModelProperty({
        description: 'Project version (GIT_VERSION)'
    })
    apiVersion: string;

    @ApiModelProperty({
        description: 'Project version (GIT_VERSION)',
        isArray: true,
        type: 'string',
    })
    supportedApiVersions: Array<string>;

    @ApiModelProperty({
        description: 'Current API environment',
        type: 'string',
        enum: Object.values(Environments),
    })
    indEnvironment: Environments;

    @ApiModelProperty({
        description: 'Is production environment?',
    })
    isProductionEnvironment: boolean;

    @ApiModelProperty({
        description: 'Is E2E environment?',
    })
    isE2EEnvironment: boolean;
}
