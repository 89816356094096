import { Injectable } from '@angular/core';
import { BillListWebRequest, BillListWebResponse, BillWebEndpoint, BillWebEndpointPaths } from '@interid/interid-site-shared';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebBillDataAccess implements BillWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    list(request: BillListWebRequest): Observable<BillListWebResponse> {
        return this.http.post(BillWebEndpointPaths.list, request);
    }
}
