import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class NotFoundService {
    constructor(
        private readonly router: Router,
        private readonly location: Location,
    ) {
    }

    notFoundErrorHandler(error: any): void {
        this.goToNotFound();
    }

    goToNotFound(state?: RouterStateSnapshot): void {
        this.router.navigateByUrl('/not-found', {
            skipLocationChange: true,
        }).then(() => {

            if (state) {
                this.location.replaceState(state.url);
            }
        });
    }
}
