import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, Min, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ProductSeriesDto } from './../models/entities/product-series.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum ProductSeriesEndpointPaths {
    create = '/api/v1/product-series/create',
    edit = '/api/v1/product-series/edit',
    delete = '/api/v1/product-series/delete',
    list = '/api/v1/product-series/list',
}

export interface ProductSeriesEndpoint {
    create(request: ProductSeriesCreateRequest): Promise<ProductSeriesDto> | Observable<ProductSeriesDto>;
    edit(request: ProductSeriesEditRequest): Promise<ProductSeriesDto> | Observable<ProductSeriesDto>;
    delete(request: ProductSeriesDeleteRequest): Promise<void> | Observable<void>;
    list(request: ProductSeriesListRequest): Promise<ProductSeriesListResponse> | Observable<ProductSeriesListResponse>;
}

class ProductSeriesFlagsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabledForFilters: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabledForSearch: boolean;
}

export class ProductSeriesEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    description: string;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;


    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    relatedForManufacturers: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    relatedForProductCategories: Array<number>;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    flags: ProductSeriesFlagsEntityBody;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    iconAttachmentId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    imageAttachmentId?: number;
}

export class ProductSeriesCreateRequest extends ProductSeriesEntityBody {
}

export class ProductSeriesEditRequest extends ProductSeriesEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductSeriesDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductSeriesListRequest {
    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    ids?: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    search?: string;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    productIds?: Array<number>;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productCategoryId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    manufacturerId?: number;
}

export class ProductSeriesListResponse {
    @ApiModelProperty()
    total: number;

    @ApiModelProperty({
        type: ProductSeriesDto,
        isArray: true,
    })
    productSeries: Array<ProductSeriesDto>;
}
