import { CrudListPaginatorDto } from './crud-list-paginator.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class CrudListResponseDto<T> {
    @ApiModelProperty({
        isArray: true,
    })
    items: Array<T>;

    @ApiModelProperty()
    pager: CrudListPaginatorDto;

    @ApiModelProperty({
        required: false,
    })
    maxSortOrder?: number;
}
