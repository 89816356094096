import { Injectable } from '@angular/core';
import { AccountSettingsShared, PurchaseFlowCreateOrderResponse } from '@interid/interid-site-shared';
import { Observable, Subject } from 'rxjs';

export enum AppBusEvent {
    SignedIn,
    SignedOut,
    AccountSettingsFetched,
    FavoriteAdded,
    FavoriteRemoved,
    IsOnSalesRoute,
    IsOutOfSalesRoute,
    IsOnDiscountedRoute,
    IsOutOfDiscountedRoute,
    IsOnMarkingkitsRoute,
    IsOutOfMarkingkitsRoute,
    OrderCreated,
    SetSearchQueryString,
    DocumentVisible,
    OpenSignIn,
}

type AppBusEvents =
    { type: AppBusEvent.SignedIn } |
    { type: AppBusEvent.SignedOut } |
    { type: AppBusEvent.AccountSettingsFetched, payload: { settings: AccountSettingsShared.AccountSettingsDto } } |
    { type: AppBusEvent.IsOnSalesRoute } |
    { type: AppBusEvent.IsOutOfSalesRoute } |
    { type: AppBusEvent.IsOnDiscountedRoute } |
    { type: AppBusEvent.IsOutOfDiscountedRoute } |
    { type: AppBusEvent.IsOnMarkingkitsRoute } |
    { type: AppBusEvent.IsOutOfMarkingkitsRoute } |
    { type: AppBusEvent.FavoriteAdded } |
    { type: AppBusEvent.FavoriteRemoved } |
    { type: AppBusEvent.OrderCreated, payload: { response: PurchaseFlowCreateOrderResponse } } |
    { type: AppBusEvent.SetSearchQueryString, payload: { queryString: string } } |
    { type: AppBusEvent.DocumentVisible } |
    { type: AppBusEvent.OpenSignIn }
;

@Injectable({
    providedIn: 'root',
})
export class AppBusService {
    private _events$: Subject<AppBusEvents> = new Subject<AppBusEvents>();

    get events$(): Observable<AppBusEvents> {
        return this._events$.asObservable();
    }

    emit(event: AppBusEvents): void {
        this._events$.next(event);
    }
}
