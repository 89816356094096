import { AccountSettingsShared, productsDefaultSortOrder, ProductSearchSortRequest, ProductShared } from '@interid/interid-site-shared';
import ProductSearchSortDirection = ProductShared.ProductSearchSortDirection;
import ProductSearchSort = ProductShared.ProductSearchSort;
import ViewMode = AccountSettingsShared.ViewMode;

export { ViewMode as ProductListViewMode };

export enum ProductListSort {
    Popular = 'Popular',
    PriceDesc = 'PriceDesc',
    PriceAsc = 'PriceAsc',
    DatePublished = 'DatePublished', 
    HasSale = 'HasSale',
    HasDiscounted = 'HasDiscounted',
    HasMarkingkits = 'HasMarkingkits',
    Commented = 'Commented',
}

export const productListSortPresets: Array<{ sort: ProductListSort; sortOrders: Array<ProductSearchSortRequest> }> = [
    {
        sort: ProductListSort.Popular,
        sortOrders: [
            ...productsDefaultSortOrder,
        ],
    },
    {
        sort: ProductListSort.PriceDesc,
        sortOrders: [
            {
                field: ProductSearchSort.Price,
                direction: ProductSearchSortDirection.Desc,
            },
        ],
    },
    {
        sort: ProductListSort.PriceAsc,
        sortOrders: [
            {
                field: ProductSearchSort.Price,
                direction: ProductSearchSortDirection.Asc,
            },
        ],
    },
    {
        sort: ProductListSort.DatePublished,
        sortOrders: [
            {
                field: ProductSearchSort.IsNew,
                direction: ProductSearchSortDirection.Desc,
            },
            {
                field: ProductSearchSort.DatePublished,
                direction: ProductSearchSortDirection.Desc,
            },
        ],
    }, 
    {
        sort: ProductListSort.HasSale,
        sortOrders: [
            {
                field: ProductSearchSort.HasSale,
                direction: ProductSearchSortDirection.Desc,
            },
            {
                field: ProductSearchSort.DatePublished,
                direction: ProductSearchSortDirection.Desc,
            },
        ],
    }, 
    {
        sort: ProductListSort.Commented,
        sortOrders: [
            {
                field: ProductSearchSort.Comments,
                direction: ProductSearchSortDirection.Desc,
            },
            {
                field: ProductSearchSort.DatePublished,
                direction: ProductSearchSortDirection.Desc,
            },
        ],
    },
];
