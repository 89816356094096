import { IsArray, IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SolutionESDto } from '../entities/solution.dto';

export namespace SolutionSearchShared {
  export enum Shape {
    List = 'List',
    Web = 'Web',
    WebFull = 'WebFull',
    WebList = 'WebList',
    WebCard = 'WebCard',
    WebForContentBlockProducts = 'WebForContentBlockProducts',
  }

  export enum SortBy {
    ElasticScore = '_score',
    Id = 'id',
    DatePublished = 'DatePublished',
  }

  export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
  }

  export class SearchRequest {
    @IsEnum(Shape)
    @IsOptional()
    @ApiModelProperty()
    shape?: Shape;

    @IsEnum(SortBy)
    @IsOptional()
    @ApiModelProperty()
    sort?: SortBy;

    @IsEnum(SortDirection)
    @IsOptional()
    @ApiModelProperty()
    sortDirection?: SortDirection;

    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    limit: number;

    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    offset: number;

    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    @ApiModelProperty()
    ids?: Array<number>;

    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    @ApiModelProperty()
    categoryIds?: Array<number>;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    slug?: string;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    categorySlug?: string;
  }

  export class SearchResponse {
    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    total: number;

    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    @ApiModelProperty()
    solutions: Array<SolutionESDto>;
  }
}
