<div class="c">
  <nav class="c-nav ___hide-mobile-scroll">
    <a class="c-nav-link" [routerLink]="['/delivery']" routerLinkActive="is-active">
      <div class="box"><span>Доставка</span></div>
    </a>
    <a class="c-nav-link" [routerLink]="['/payment-methods']" routerLinkActive="is-active">
      <div class="box"><span>Способы оплаты</span></div>
    </a>
    <a class="c-nav-link" [routerLink]="['/purchase-returns']" routerLinkActive="is-active">
      <div class="box"><span>Возврат товара</span></div>
    </a>
    <a class="c-nav-link" [routerLink]="['/privacy']" routerLinkActive="is-active">
      <div class="box"><span>Политика конфиденциальности</span></div>
    </a>
  </nav>
</div>
