<div class="frame-2141">
  <img loading="lazy" class="active" [src]="active.url" [ngStyle]="cImgNgStyle" />

  <div class="frame-1769" [ngStyle]="cPhotosNgStyle" #container (mousedown)="startDragging($event, false, container)" (mouseup)="stopDragging($event, false)" (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, container)">
    @for (photo of payload().images; track trackByAttachmentId(photo)) {
      <div class="frame-993" (click)="changeActive(photo)">
        <img loading="lazy" [src]="photo.url" [ngStyle]="cImgNgStyle" />
      </div>
    }
  </div>
</div>
