import { Observable } from 'rxjs';
import { IsBoolean, IsInt, IsNotEmpty, IsNumber, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CurrencyShared } from '../models/shared/currency.shared';
import { CurrencyCBRDto, CurrencyDto } from '../models/entities/currency.dto';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';

export enum CurrencyEndpointPaths {
    createCurrency = '/api/v1/currencies/create',
    editCurrency = '/api/v1/currencies/edit',
    deleteCurrency = '/api/v1/currencies/delete',
    listCurrencies = '/api/v1/currencies/list',
    listAvailableCurrencies = '/api/v1/currencies/listAvailable',
    syncCurrencyWithCBR = '/api/v1/currencies/syncCurrencyWithCBR',
    syncCurrenciesWithCBR = '/api/v1/currencies/syncCurrenciesWithCBR',
}

export interface CurrencyEndpoint {
    createCurrency(request: CreateCurrencyRequest): Promise<CurrencyDto> | Observable<CurrencyDto>;
    editCurrency(request: EditCurrencyRequest): Promise<CurrencyDto> | Observable<CurrencyDto>;
    deleteCurrency(request: DeleteCurrencyRequest): Promise<void> | Observable<void>;
    listCurrencies(request: ListCurrenciesRequest): Promise<ListCurrenciesResponse> | Observable<ListCurrenciesResponse>;
    listAvailableCurrencies(): Promise<ListAvailableCurrenciesResponse> | Observable<ListAvailableCurrenciesResponse>;
    syncCurrencyWithCBR(request: SyncCurrencyWithCBRRequest): Promise<CurrencyDto> | Observable<CurrencyDto>;
    syncCurrenciesWithCBR(): Promise<SyncCurrenciesWithCBRResponse> | Observable<SyncCurrenciesWithCBRResponse>;
}

export class CurrencyEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    code: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    symbol: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    syncCBR?: string;

    @ApiModelProperty()
    @IsNumber()
    @IsNotEmpty()
    exchangeRate: number;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isForeign: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    enableYandexMarket: boolean;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsOptional()
    yandexMarketSettings?: CurrencyShared.YandexMarketSettings;
}

export class CreateCurrencyRequest extends CurrencyEntityBody {
}

export class EditCurrencyRequest extends CurrencyEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteCurrencyRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListCurrenciesRequest extends CrudListRequestDto<CurrencyDto> {}

export class ListCurrenciesResponse extends CrudListResponseDto<CurrencyDto> {
    @ApiModelProperty({
        type: CurrencyDto,
        isArray: true,
    })
    items: Array<CurrencyDto>;
}

export type ListAvailableCurrenciesResponse = Array<CurrencyCBRDto>;

export type SyncCurrenciesWithCBRResponse = Array<CurrencyDto>;

export class SyncCurrencyWithCBRRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

