export enum AuthErrorCodes {
  AccountEmailIsNotVerified = 'AccountEmailIsNotVerified',
  AccountNotFound = 'AccountNotFound',
  InvalidCredentials = 'InvalidCredentials',
  InvalidJwt = 'InvalidJwt',
  AuthSessionIsNotAvailable = 'AuthSessionIsNotAvailable',
  AdminRoleIsRequired = 'AdminRoleIsRequired',
}

export const authErrorCodesI18n = {
    [AuthErrorCodes.AccountEmailIsNotVerified]: 'Ваш аккаунт не подтвержден. Пройдите по ссылке, которые мы отправили вам на ваш запрос о регистрации',
    [AuthErrorCodes.AccountNotFound]: 'Пользователь с таким E-Mail не найден',
    [AuthErrorCodes.InvalidCredentials]: 'Пароль не подходит',
    [AuthErrorCodes.InvalidJwt]: 'Ваша сессия устарела. Зайдите еще раз',
    [AuthErrorCodes.AuthSessionIsNotAvailable]: 'Ваша сессия устарела. Зайдите еще раз',
    [AuthErrorCodes.AdminRoleIsRequired]: 'Требуются права администратора',
};
