import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ElementRef, Injector, InputSignal, OnInit, PLATFORM_ID, Renderer2, inject, signal, viewChild } from '@angular/core';
import { AppBootstrapDataService, AppBusEvent, AppBusService, defaultOverlayConfig } from '@interid/interid-site-web/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppHeaderDesktopCategoriesComponent } from '../app-header-desktop-categories/app-header-desktop-categories.component';
import { Overlay } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
import { connectToSignal } from '@interid/interid-site-web/ui-signals';
import { toSignal } from '@angular/core/rxjs-interop';

interface State {
    areCategoriesOpened: boolean;
    isOnSalesRoute: boolean;
}

@Component({
    selector: 'app-header-desktop-menu',
    templateUrl: './app-header-desktop-menu.component.html',
    styleUrls: ['./app-header-desktop-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderDesktopMenuComponent implements OnInit {
    private readonly appBootstrap = inject(AppBootstrapDataService);
    private platformId = inject(PLATFORM_ID);
    private readonly injector = inject(Injector);
    private readonly appBus = inject(AppBusService);
    private readonly overlay = inject(Overlay);
    private readonly renderer = inject(Renderer2);

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public attachCategoriesRef = viewChild<ElementRef<HTMLDivElement>>('attachCategories');
    public isBrowser = signal<boolean>(false);

     public state = signal<State>({
        areCategoriesOpened: false,
        isOnSalesRoute: false,
     });

    ngOnInit(): void {
        this.isBrowser.set(isPlatformBrowser(this.platformId));

        connectToSignal(
            this.state,
            this.appBus.events$.pipe(
                filter((event) => event.type === AppBusEvent.IsOnSalesRoute),
                takeUntil(this.ngOnDestroy$)
            ),
            (prev) => ({
                ...prev,
                isOnSalesRoute: true,
            }),
            this.injector
        );

        connectToSignal(
            this.state,
            this.appBus.events$.pipe(
                filter((event) => event.type === AppBusEvent.IsOutOfSalesRoute),
                takeUntil(this.ngOnDestroy$)
            ),
            (prev) => ({
                ...prev,
                isOnSalesRoute: false,
            }),
            this.injector
        );
    }

    public discountedStats = toSignal(this.appBootstrap.data$.pipe(
        map((x) => x.discountedStats)
    ));

    openCategories(): void {
        if (this.attachCategoriesRef && this.attachCategoriesRef().nativeElement) {
            const detach$: Subject<void> = new Subject<void>();

            this.state.update((prev) => ({
                ...prev,
                areCategoriesOpened: true,
            }));

            const overlay = this.overlay.create({
                ...defaultOverlayConfig,
                panelClass: '__app-desktop-header-categories-overlay',
                positionStrategy: this.overlay
                    .position()
                    .flexibleConnectedTo(this.attachCategoriesRef())
                    .withPositions([
                        {
                            originX: 'start',
                            originY: 'bottom',
                            overlayX: 'start',
                            overlayY: 'top',
                        },
                    ]),
                scrollStrategy: this.overlay.scrollStrategies.reposition({
                    autoClose: true,
                    scrollThrottle: 0,
                }),
                hasBackdrop: true,
                backdropClass: '__app-desktop-header-categories-overlay-backdrop',
            });

            this.renderer.setStyle(document.body, 'overflow', 'hidden');
            this.renderer.setStyle(document.body, 'padding-right', '17px');

            const componentPortal = new ComponentPortal(AppHeaderDesktopCategoriesComponent);
            const overlayRef = overlay.attach(componentPortal);

            overlayRef.instance.fitToContainer = this.attachCategoriesRef as InputSignal<ElementRef<HTMLDivElement>>;

            overlayRef.instance.closeEvent.pipe(takeUntil(detach$)).subscribe(() => {
                this.state.update((prev) => ({
                    ...prev,
                    areCategoriesOpened: false,
                }));
                detach$.next();
                overlay.detach();
            });

            overlay
                .backdropClick()
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe(() => {
                    this.renderer.setStyle(document.body, 'overflow', 'auto');
                    this.renderer.setStyle(document.body, 'padding-right', '0px');

                    this.state.update((prev) => ({
                        ...prev,
                        areCategoriesOpened: false,
                    }));
                    detach$.next();
                    overlay.detach();
                });
        }
    }
}
