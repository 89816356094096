import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BreadcrumbsDefinitions } from '../../components/breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-shared-layout-common',
    templateUrl: './layout-common.component.html',
    styleUrls: ['./layout-common.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutCommonComponent {
    @Input() title = '';
    @Input() breadcrumbs: BreadcrumbsDefinitions = { nodes: [] };

    @Input() withBreadcrumbs = true;
    @Input() withTitle = true;
    @Input() withMenu = true;
    @Input() withBottomPadding = true;
    @Input() withMobileBottomPadding = true;

    @Input() withoutMobileSidePaddings = false;
    @Input() withoutTabletSidePaddings = false;

    get ngClass(): any {
        return {
            'with-menu': this.withMenu,
            'with-bottom-padding': this.withBottomPadding,
            'with-mobile-bottom-padding': this.withMobileBottomPadding,
            'without-mobile-side-paddings': this.withoutMobileSidePaddings,
        };
    }

    get ngClassFluidContainer(): any {
        return {
            '__mobile-disable-side-padding': this.withoutMobileSidePaddings,
            '__tablet-disable-side-padding': this.withoutTabletSidePaddings,
        };
    }
}

