export namespace DeliveryCostShared {
  export enum CostType {
    None = 'None',
    Free = 'Free',
    Paid = 'Paid',
    Individual = 'Individual',
  }

  export const allCostTypes = Object.values(CostType);

  export type CostVariant =
    { type: CostType.None; payload: NonePayload } |
    { type: CostType.Free; payload: FreePayload } |
    { type: CostType.Paid; payload: PaidPayload } |
    { type: CostType.Individual; payload: IndividualPayload }
    ;

  export class NonePayload {
  }

  export class FreePayload {
    triggerFrom: string;
    costWithoutTrigger: string;
  }

  export class PaidPayload {
    costs: string;
  }

  export class IndividualPayload {}
}
