export const interidSiteWebFeaturePurchaseI18n = {
    purchase: {
        components: {
            purchase: {},
            purchase_basket: {
                title: 'Корзина',
                message: 'Внимательно просмотрите список товаров, выбранных для заказа. Если все данные верны, нажмите кнопку «Перейти к оформлению».',
                clear: {
                    button: 'Очистить',
                    success: 'Ваша корзина полностью очищена',
                    confirm: {
                        title: 'Подтверждение',
                        message: 'Вы действительно хотите удалить все товары из корзины?',
                        proceed: 'Продолжить',
                        reject: 'Отмена',
                    },
                },
                update: 'Обновить',
                delivery: 'Доставка',
                total: 'Всего к оплате',
                delivery_map_header1: 'Пункт выдачи заказа',
                delivery_map_header2: 'Пункт выдачи СДЭК в вашем городе',
                delivery_variant: 'Способ доставки',
                delivery_variant_select: 'Выберите способ доставки',
                delivery_individual: 'Индивидуально',
                with_taxes: 'В т.ч. НДС 20%',
                no_taxes: 'Без НДС',

                auth: 'Авторизуйтесь',
                auth_message: 'или заполните следующие поля',
                fill_fields: 'Заполните следующие поля',
                required: 'Поля, помеченные звездочкой, обязательны для заполнения.',
                fullName: 'Ваше имя',
                email: 'E-mail',
                phone: 'Телефон',
                requisites: 'Реквизиты',
                requisites_desc: 'Можете приложить свои реквизиты, если Вы являетесь юр. лицом, мы выставим Вам счёт',
                requisites_button: 'Прикрепить файл',
                requisites_select: 'Файл не выбран',
                delivery_address: 'Адрес доставки',
                comment: 'Комментарии',
                agree: 'Согласен с условиями обработки персональных данных в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных»',
                subscribe: 'Подписаться на рассылку выгодных предложений по электронной почте',
                submit: 'Оформить заказ',
                order_costs_products: 'Товаров',
                order_costs_delivery: 'Доставка',
                order_costs_total: 'Всего на сумму',
                order_costs_nds: 'В т.ч. НДС {{ nds }}%',
                delivery_free: 'Бесплатно',
                goToBasket: 'Вернуться в корзину',
                please_agree: 'Необходимо подтвердить свое согласия с условиями обработки',
                delete_requisites_file: 'Удалить файл с реквизитами',
                edit_requisites: 'Редактировать',
                save_requisites: 'Сохранить реквизиты',
                cancel_requisites: 'Отмена',
                account_requisites_form: {
                    title: 'Реквизиты юр. лица',
                    empty: 'Не заполнено',
                    actions: {
                        edit: 'Редактировать',
                        submit: 'Сохранить',
                        cancel: 'Отмена',
                    },
                    fields: {
                        type: 'Тип',
                        companyName: 'Наименование организации',
                        city: 'Город',
                        legalAddress: 'Юридический адрес',
                        realAddress: 'Фактический адрес',
                        bankName: 'Наименование банка',
                        bankBIK: 'БИК',
                        bankAccount: 'Расчетный счет',
                        bankCorrespondingAccount: 'Корреспондентский счет',
                        inn: 'ИНН',
                        kpp: 'КПП',
                    },
                    short: {
                        legalAddress: 'Юридический адрес',
                        realAddress: 'Фактический адрес',
                        bankBIK: 'БИК',
                        bankAccount: 'Р/с',
                        bankCorrespondingAccount: 'К/с',
                        inn: 'ИНН',
                        kpp: 'КПП',
                    },
                },
            },
            purchase_stats: {
                products: 'Товаров',
                delivery: 'Доставка',
                total: 'Всего на сумму',
                nds: 'В т.ч. НДС 20%',
            },
            purchase_empty: {
                title: 'Корзина пуста',
                message: 'Воспользуйтесь нашим каталогом для поиска товаров',
            },
            purchase_complete: {
                title_l1: 'Спасибо за ваш выбор! ',
                title_l2: 'Заказ №{{ orderId }} успешно оформлен.',
                message_l1: 'В ближайшее время наш менеджер свяжется с вами для подтверждения заказа.',
                message_l2: 'Будьте на связи. Мы не заставим вас долго ждать!',
                message_l3: 'Для того, чтобы не забыть, как к нам добраться или связаться с нами в случае вопросов по заказу, добавьте наш сайт к себе в закладки.',
            },
        },
    },
};
