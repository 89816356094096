import { HomepageDto } from '../models/entities/homepage.dto';
import { Observable } from 'rxjs';

export enum HomepageEndpointPaths {
    get = '/api/v1/homepage/get',
    set = '/api/v1/homepage/set',
}

export interface HomepageEndpoint {
    getHomepage(): Promise<HomepageGetResponse> | Observable<HomepageGetResponse>;
    setHomepage(request: HomepageSetRequest): Promise<HomepageSetResponse> | Observable<HomepageGetResponse>;
}

export type HomepageGetResponse = HomepageDto;

export type HomepageSetRequest = HomepageDto;
export type HomepageSetResponse = HomepageDto;
