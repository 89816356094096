import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppErrorHandlerService, JwtService } from '@interid/interid-site-web/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InteridWebAuthDataAccess } from '@interid/interid-site-data-access/web';

@Injectable({
    providedIn: 'root',
})
export class IsSignedInGuard  {
    constructor(
        private readonly appErrorHandler: AppErrorHandlerService,
        private readonly router: Router,
        private readonly jwtService: JwtService,
        private readonly endpoint: InteridWebAuthDataAccess,
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (! this.jwtService.hasJwt()) {
            return this.router.parseUrl('/');
        }

        return this.endpoint.verify({
            jwt: this.jwtService.jwt,
        }).pipe(
            map(() => true),
            catchError((err) => of(this.router.parseUrl('/'))),
            this.appErrorHandler.pipe(),
        );
    }
}
