import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'appProductReviews',
})
export class ProductReviewsPipe implements PipeTransform {
    constructor(
        private readonly i18n: TranslateService,
    ) {
    }

    transform(value: any, ...args: any[]): any {
        const intCount = parseInt(value, 10);

        if (isNaN(intCount) || intCount < 0) {
            return '';
        } else {
            const count = intCount.toString();
            const lastDigit = count[count.length - 1];

            if (count === '0') {
                return this.t('no_reviews', intCount);
            } else if (count === '1') {
                return this.t('reviews_1', intCount);
            } else if (['11', '12', '13', '14', '15', '16', '17', '18', '19'].includes(count)) {
                return this.t('reviews_0', intCount);
            } else if (['2', '3', '4'].includes(lastDigit)) {
                return this.t('reviews_2', intCount);
            } else if (['5', '6', '7', '8', '9', '0'].includes(lastDigit)) {
                return this.t('reviews_0', intCount);
            }
        }
    }

    t(input: string, count: number): string {
        return this.i18n.instant(`product_shared.pipes.product_reviews.${input}`, { count });
    }
}
