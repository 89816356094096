import { Observable } from 'rxjs';
import { PagesShared } from '../models/shared/pages.shared';

export enum ContactsWebEndpointPaths {
    contacts = '/api/v1/web/contacts',
}

export interface ContactsWebEndpoint {
    contacts(): Promise<ContactsWebResponse> | Observable<ContactsWebResponse>;
}

export class ContactsWebResponse extends PagesShared.ContactsPage {
}
