import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';

export enum MindboxEndpointPaths {
    request = '/api/v1/mindbox/request',
}

export interface MindboxEndpoint {
    request(request: MindboxWebsiteRequest, req?: any): Promise<void> | Observable<void>;
}

export class MindboxRequest {
    @ApiModelProperty()
    uuid: string;
}

export class MindboxWebsiteRequest extends MindboxRequest {
    @ApiModelProperty()
    body: any;
    
    @ApiModelProperty()
    operation: string;
}
 
export class MindboxWebsiteResponse {

}

 