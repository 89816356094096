import { IsArray, IsBoolean, IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, Min, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductTransactionShared } from '../models/shared/product-transaction.shared';
import { SystemTaskShared } from '../models/shared/system-task.shared';
import { ProductTransactionEntryDto } from '../models/entities/product-transaction-entry.dto';
import { ProductTransactionDto } from '../models/entities/product-transaction.dto';
import { SystemTaskDto } from '../models/entities/system-task.dto';
import { ProductEntityBody } from './product.endpoint';

export enum ProductTransactionEndpointPaths {
    create = '/api/v1/product-transaction/create',
    edit = '/api/v1/product-transaction/edit',
    delete = '/api/v1/product-transaction/delete',
    list = '/api/v1/product-transaction/list',
    getById = '/api/v1/product-transaction/getById',
    addEntry = '/api/v1/product-transaction/addEntry',
    addEntryByUUID = '/api/v1/product-transaction/addEntryByUUID',
    addIsAvailableOnDepotEntry = '/api/v1/product-transaction/addIsAvailableOnDepotEntry',
    addIsAvailableOnVendorDepotEntry = '/api/v1/product-transaction/addIsAvailableVendorDepotEntry',
    addEntries = '/api/v1/product-transaction/addEntries',
    patchEntry = '/api/v1/product-transaction/patchEntry',
    deleteEntry = '/api/v1/product-transaction/deleteEntry',
    listEntries = '/api/v1/product-transaction/listEntries',
    getEntryById = '/api/v1/product-transaction/getEntryById',
    validate = '/api/v1/product-transaction/validate',
    commit = '/api/v1/product-transaction/commit',
    rollback = '/api/v1/product-transaction/rollback',
    interrupt = '/api/v1/product-transaction/interrupt',
}

export interface ProductTransactionEndpoint {
    create(request: ProductTransactionCreateRequest): Promise<ProductTransactionDto> | Observable<ProductTransactionDto>;
    edit(request: ProductTransactionEditRequest): Promise<ProductTransactionDto> | Observable<ProductTransactionDto>;
    delete(request: ProductTransactionDeleteRequest): Promise<void> | Observable<void>;
    list(request: ProductTransactionListRequest): Promise<ProductTransactionListResponse> | Observable<ProductTransactionListResponse>;
    getById(request: ProductTransactionGetByIdRequest): Promise<ProductTransactionDto> | Observable<ProductTransactionDto>;
    addEntry(request: ProductTransactionAddEntryRequest): Promise<ProductTransactionEntryDto> | Observable<ProductTransactionEntryDto>;
    addEntryByUUID(request: ProductTransactionAddEntryByUUIDRequest): Promise<ProductTransactionEntryDto> | Observable<ProductTransactionEntryDto>;
    addIsAvailableOnDepotEntry(request: ProductTransactionAddIsAvailableOnDepotRequest): Promise<ProductTransactionEntryDto | undefined> | Observable<ProductTransactionEntryDto | undefined>;
    addIsAvailableOnVendorDepotEntry(request: ProductTransactionAddIsAvailableOnVendorDepotRequest): Promise<ProductTransactionEntryDto | undefined> | Observable<ProductTransactionEntryDto | undefined>;
    addEntries(request: ProductTransactionAddEntriesRequest): Promise<Array<ProductTransactionEntryDto>> | Observable<Array<ProductTransactionEntryDto>>;
    patchEntry(request: ProductTransactionPatchEntryRequest): Promise<ProductTransactionEntryDto> | Observable<ProductTransactionEntryDto>;
    deleteEntry(request: ProductTransactionDeleteEntryRequest): Promise<void> | Observable<void>;
    listEntries(request: ProductTransactionListEntriesRequest): Promise<ProductTransactionListEntriesResponse> | Observable<ProductTransactionListEntriesResponse>;
    getEntryById(request: ProductTransactionEntryGetByIdRequest): Promise<ProductTransactionEntryDto> | Observable<ProductTransactionEntryDto>;
    commit(request: ProductTransactionCommitRequest): Promise<ProductTransactionCommitResponse> | Observable<ProductTransactionCommitResponse>;
    validate(request: ProductTransactionValidateRequest): Promise<ProductTransactionValidateResponse> | Observable<ProductTransactionValidateResponse>;
    rollback(request: ProductTransactionRollbackRequest): Promise<ProductTransactionRollbackResponse> | Observable<ProductTransactionRollbackResponse>;
    interrupt(request: ProductTransactionInterruptRequest): Promise<ProductTransactionInterruptResponse> | Observable<ProductTransactionInterruptResponse>;
}

export class ProductTransactionCreateRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    title?: string;
}

export class ProductTransactionEditRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    title?: string;
}

export class ProductTransactionDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductTransactionListRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty({
        required: false,
        type: 'string',
        enum: Object.values(ProductTransactionShared.Status),
    })
    @IsEnum(ProductTransactionShared.Status)
    @IsOptional()
    status?: ProductTransactionShared.Status;
}

export class ProductTransactionListResponse {
    @ApiModelProperty({
        type: ProductTransactionDto,
        isArray: true,
    })
    entities: Array<ProductTransactionDto>;

    @ApiModelProperty()
    total: number;
}

export class ProductTransactionGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductTransactionAddEntryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    transactionId: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productId?: number;

    @ApiModelProperty({
        description: 'Partial of ProductEntityBody',
        type: ProductEntityBody,
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    updateRequest?: Partial<ProductEntityBody>;
}

export class ProductTransactionAddEntryByUUIDRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    transactionId: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uuid: string;

    @ApiModelProperty({
        description: 'Partial of ProductEntityBody',
        type: ProductEntityBody,
    })
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    updateRequest: Partial<ProductEntityBody>;
}

export class ProductTransactionAddIsAvailableOnVendorDepotRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    transactionId: number;

    @ApiModelProperty()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty()
    quantity?: string;
}

export class ProductTransactionAddIsAvailableOnDepotRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    transactionId: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uuid: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isAvailableOnDepot: boolean;

    @ApiModelProperty()
    @IsOptional()
    interIdAvailable?: number;

    @ApiModelProperty()
    @IsOptional()
    interIdTotalAmount?: number;
}

export class ProductTransactionAddEntriesRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    transactionId: number;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;
}

export class ProductTransactionPatchEntryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        type: ProductEntityBody,
        description: 'Partial of ProductEntityBody',
    })
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    updateRequest: Partial<ProductEntityBody>;

    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    isUpdatedInProductFormCms?: boolean;
}

export class ProductTransactionDeleteEntryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductTransactionListEntriesRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    transactionId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;
}

export class ProductTransactionEntryGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductTransactionListEntriesResponse {
    @ApiModelProperty({
        type: ProductTransactionEntryDto,
        isArray: true,
    })
    entities: Array<ProductTransactionEntryDto>;

    @ApiModelProperty()
    total: number;
}

export class ProductTransactionCommitRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductTransactionCommitResponse {
    @ApiModelProperty({
        type: SystemTaskDto,
    })
    task: SystemTaskDto<SystemTaskShared.TaskType.CommitProductTransaction, SystemTaskShared.MultiEntriesResponse>;
}

export class ProductTransactionValidateRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductTransactionValidateResponse {
    @ApiModelProperty()
    canCommit: boolean;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    invalidEntryIds: Array<number>;
}

export class ProductTransactionRollbackRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductTransactionRollbackResponse {
    @ApiModelProperty({
        type: SystemTaskDto,
    })
    task: SystemTaskDto<SystemTaskShared.TaskType.RollbackProductTransaction, SystemTaskShared.MultiEntriesResponse>;
}

export class ProductTransactionInterruptRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductTransactionInterruptResponse {
    @ApiModelProperty({
        type: SystemTaskDto,
    })
    task: SystemTaskDto<SystemTaskShared.TaskType.CommitProductTransaction | SystemTaskShared.TaskType.RollbackProductTransaction, SystemTaskShared.MultiEntriesResponse>;
}
