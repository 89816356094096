
<div class="c">
  <div class="c-container" #viewPlayerContainer>
    @if (isBrowser()) {
      @if (isAvailable$ | async) {
        <youtube-player [videoId]="videoId()" [width]="width$ | async" [height]="height$ | async"></youtube-player>
      }
    }
  </div>
</div>
