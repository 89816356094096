import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductTransactionEntryShared } from '../shared/product-transaction-entry.shared';
import { ProductDto } from './product.dto';
import { ProductEntityBody } from '../../endpoints/product.endpoint';

export class ProductTransactionEntryDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  transactionId: number;

  @ApiModelProperty()
  product: ProductDto;

  @ApiModelProperty()
  isUpdatedInProductFormCms: boolean;

  @ApiModelProperty()
  updateRequest: Partial<ProductEntityBody>;

  @ApiModelProperty()
  status: ProductTransactionEntryShared.Status;
}
