export const interidSiteWebFeatureContactsI18n = {
    contacts: {
        components: {
            contacts: {
                title: 'Контакты',
                requisites: 'Наши реквизиты',
            },
        },
    },
};
