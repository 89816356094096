import { NgModule } from '@angular/core';
import { ProductReviewsPipe } from './pipes/product-reviews.pipe';
import { ProductCardCComponent } from './components/product-card-c/product-card-c.component';
import { ProductCardFComponent } from './components/product-card-f/product-card-f.component';
import { ProductCardParamsComponent } from './assets/product-card-params/product-card-params.component';
import { ProductCardPriceComponent } from './assets/product-card-price/product-card-price.component';
import { ProductCardPreviewComponent } from './assets/product-card-preview/product-card-preview.component';
import { ProductCardRatingComponent } from './assets/product-card-rating/product-card-rating.component';
import { ProductCardFavoriteCompareComponent } from './assets/product-card-favorite-compare/product-card-favorite-compare.component';
import { ProductCardFavoriteCompareTopbarComponent } from './assets/product-card-favorite-compare-topbar/product-card-favorite-compare-topbar.component';
import { ProductCardBuyButtonComponent } from './assets/product-card-buy-button/product-card-buy-button.component';
import { ProductCardOneClickBuyComponent } from './assets/product-card-one-click-buy/product-card-one-click-buy.component';
import { ProductReviewComponent } from './assets/product-review/product-review.component';
import { InteridSiteWebUiSharedModule } from '@interid/interid-site-web/ui-shared';
import { ProductCardGComponent } from './components/product-card-g/product-card-g.component';
import { ProductCardBadgesAComponent } from './assets/product-card-badges-a/product-card-badges-a.component';
import { ProductCardBadgesBComponent } from './assets/product-card-badges-b/product-card-badges-b.component';
import { ProductCardFavoriteCompareAComponent } from './assets/product-card-favorite-compare-a/product-card-favorite-compare-a.component';
import { ProductCardFavoriteCompareBComponent } from './assets/product-card-favorite-compare-b/product-card-favorite-compare-b.component';
import { ProductCardPriceAComponent } from './assets/product-card-price-a/product-card-price-a.component';
import { ProductCardRatingAComponent } from './assets/product-card-rating-a/product-card-rating-a.component';
import { ProductCardParamsAComponent } from './assets/product-card-params-a/product-card-params-a.component';
import { ProductAnalogsModalComponent } from './assets/product-analogs-modal/product-analogs-modal.component';
import { ProductCardParametersBadgeComponent } from './assets/product-card-parameters-badges/product-card-parameters-badges.component';
import { ProductFavoriteShareComponent } from './assets/product-favorite-share/product-favorite-share.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ProductCardCMobileComponent } from './components/product-card-c-mobile/product-card-c-mobile.component';
import { ProductQuestionsPipe } from './pipes/product-questions.pipe';
import { ProductQuestionComponent } from './assets/product-question/product-question.component';

const PRODUCT_PIPES_SHARED = [ProductReviewsPipe, ProductQuestionsPipe];

const PRODUCT_COMPONENTS_SHARED = [
    ProductCardCComponent, 
    ProductCardCMobileComponent, 
    ProductCardFComponent, 
    ProductCardParamsComponent, 
    ProductCardPreviewComponent, 
    ProductCardPriceComponent, 
    ProductCardRatingComponent, 
    ProductCardFavoriteCompareComponent, 
    ProductCardFavoriteCompareTopbarComponent, 
    ProductCardBuyButtonComponent, 
    ProductCardOneClickBuyComponent, 
    ProductReviewComponent, 
    ProductQuestionComponent, 
    ProductFavoriteShareComponent, 
    ProductAnalogsModalComponent, 
    ProductCardGComponent, 
    ProductCardBadgesAComponent, 
    ProductCardRatingAComponent, 
    ProductCardFavoriteCompareAComponent, 
    ProductCardParamsAComponent, 
    ProductCardParametersBadgeComponent, 
    ProductCardBadgesBComponent, 
    ProductCardPriceAComponent, 
    ProductCardFavoriteCompareBComponent];
@NgModule({
    imports: [InteridSiteWebUiSharedModule, ClipboardModule],
    declarations: [...PRODUCT_PIPES_SHARED, ...PRODUCT_COMPONENTS_SHARED],
    exports: [...PRODUCT_PIPES_SHARED, ...PRODUCT_COMPONENTS_SHARED],
})
export class InteridSiteWebUiSharedProductCardsModule {}
