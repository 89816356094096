import { ProductDto } from '../../../../models/entities/product.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class ProductRevisionDto {
  @ApiModelProperty({
    description: 'ID',
  })
  id: number;

  @ApiModelProperty({
    description: 'Created At',
  })
  dateCreatedAt: string;

  @ApiModelProperty({
    description: 'Reason (why/who updated)',
  })
  reason: string;

  @ApiModelProperty({
    description: 'Product DTO',
  })
  productDTO: ProductDto;
}
