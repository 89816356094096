<div class='c'>
  <div class='c-items'>
    <table>
      <tbody>
        @for (row of payload().items; track row) {
          <tr>
            <td class='image'>
              <div class='preview-container'>
                <app-shared-attachment-image [attachment]='row.image' [maxImageWidth]='520'></app-shared-attachment-image>
              </div>
            </td>
            <td class='title'>
              <div [innerHTML]='row.description | trustHtml'></div>
            </td>
          </tr>
        }
      </tbody>
    </table>
    @if (payload().footer) {
      <div class='c-footer'><span>{{ payload().footer }}</span></div>
    }
  </div>
</div>
