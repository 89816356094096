import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, inject, signal, viewChild } from '@angular/core';
import { Router } from '@angular/router';
import { getChildProductCategoryIds, ProductCategoryListDto, ProductCategorySeoLinkDto, Source } from '@interid/interid-site-shared';
import { AppBootstrapDataService, UrlBuilderService } from '@interid/interid-site-web/core';

interface State {
    productCategories: Array<ProductCategoryListDto>;
    selectedCategory?: ProductCategoryListDto;
    productCategorySeoLinks: Array<ProductCategorySeoLinkDto>;
    isFocused: boolean;
}

@Component({
    selector: 'app-header-mobile-catalog',
    templateUrl: './app-header-mobile-catalog.component.html',
    styleUrls: ['./app-header-mobile-catalog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderMobileCatalogComponent implements OnInit {
    private readonly appBootstrap = inject(AppBootstrapDataService);
    private readonly urlBuilder = inject(UrlBuilderService);
    private readonly router = inject(Router);

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output('close') closeEvent: EventEmitter<void> = new EventEmitter<void>();

    public queryStringRef = viewChild<ElementRef>('queryString')

    public state = signal<State>({
        productCategories: [],
        productCategorySeoLinks: [],
        isFocused: false,
    });

    constructor() {}

    get queryString(): string {
        return this.queryStringRef()?.nativeElement?.value;
    }

    get hasQueryString(): boolean {
        return this.queryStringRef().nativeElement && (this.queryString || '').toString().trim().length > 0;
    }

    onKeyUpEnter(): void {
        this.navigateToSearch();
    }

    hasChilds(category: ProductCategoryListDto) {
        return this.state().productCategories.some((x) => x.parent == category.id) || this.state().productCategorySeoLinks.some((x) => x.productCategoryId == category.id);
    }

    selectCategory(category: ProductCategoryListDto) {
        if (this.hasChilds(category)) {
            this.state.update((prev) => ({
                ...prev,
                selectedCategory: category,
            }));
        } else {
            this.router.navigate([this.productCategoryLink(category)]);
        }
    }

    selectSeoLink(seolink: ProductCategorySeoLinkDto) {
        const route = this.urlBuilder.routerLink(seolink.source, {
            origUrl: seolink.uri,
        });

        this.router.navigate(route.route, {
            queryParams: route.queryParams,
        });

        this.close();
    }

    onInputBlur(): void {
        this.state.update((prev) => ({
            ...prev,
            isFocused: false,
        }));
    }

    onInputFocus(): void {
        this.state.update((prev) => ({
            ...prev,
            isFocused: true,
        }));
    }

    toCategory(category: ProductCategoryListDto) {
        this.router.navigate([this.productCategoryLink(category)]);
    }

    toParent(category: ProductCategoryListDto) {
        const parent = this.appBootstrap.data.productCategories.find((x) => x.id == category?.parent);

        this.state.update((prev) => ({
            ...prev,
            selectedCategory: parent,
        }));
    }
 
    navigateToSearch(): void {
        if (!this.hasQueryString) {
            return;
        }

        const route = this.urlBuilder.routerLink({
            type: Source.ProductSearch,
            payload: {
                queryString: this.queryString,
            },
        });

        this.router.navigate(route.route, {
            queryParams: route.queryParams,
        });

        this.close();
    }

    ngOnInit(): void {
        this.state.update((prev) => ({
            ...prev,
            productCategories: this.appBootstrap.data.productCategories,
            productCategorySeoLinks: this.appBootstrap.data.productCategorySeoLinks,
        }));
    }

    get productCategories(): Array<ProductCategoryListDto> {
        if (!this.state().selectedCategory) return this.rootProductCategories;
        else
            return getChildProductCategoryIds(this.state().selectedCategory.id, this.state().productCategories)
                .map((id) => this.state().productCategories.find((pc) => pc.id === id))
                .filter((x) => x.id !== this.state().selectedCategory.id);
    }

    get rootProductCategories(): Array<ProductCategoryListDto> {
        return this.state().productCategories.filter((pc) => pc.parent === undefined && pc.isDisplayInCatalogMenu).sort((a, b) => a.sortOrder - b.sortOrder);
    }

    get categoriesSeoLinks(): Array<ProductCategorySeoLinkDto> {
        if (!this.state().selectedCategory) return;

        return this.state().productCategorySeoLinks.filter((pc) => pc.productCategoryId === this.state().selectedCategory.id && pc.isDisplayInCatalogMenu).sort((a, b) => a.sortOrder - b.sortOrder);
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }

    productCategoryLink(productCategory: ProductCategoryListDto): string {
        return this.urlBuilder.urlLink({
            type: Source.Catalog,
            payload: {
                productCategoryId: productCategory.id,
            },
        });
    }

    close(): void {
        this.closeEvent.emit();
    }
}
