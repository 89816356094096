import { Observable } from 'rxjs';
import { SystemTaskDto } from '../../../models/entities/system-task.dto';
import { SystemTaskShared } from '../../../models/shared/system-task.shared';

export enum SsrCacheEndpointPaths {
    destroy = '/api/v2/ssr-cache/destroy',
    updateFromSiteMapXml = '/api/v2/ssr-cache/updateFromSiteMapXml',
}

export interface SsrCacheEndpoint {
    destroy(): Promise<void> | Observable<void>;
    updateFromSiteMapXml(): Promise<SsrCacheUpdateFromSiteMapXmlResponse> | Observable<SsrCacheUpdateFromSiteMapXmlResponse>;
}

export type SsrCacheUpdateFromSiteMapXmlResponse = SystemTaskDto<SystemTaskShared.TaskType.SsrCacheUpdateFromSiteMap, SystemTaskShared.MultiEntriesResponse>;
