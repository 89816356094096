import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewBreakpointsShared } from '@interid/interid-site-shared';
import { defaultModalConfig, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { AppModalSelectCityComponent } from './app-modal-select-city.component';

@Injectable()
export class AppModalSelectCityService {
    constructor(private readonly matDialog: MatDialog, private readonly vbs: ViewBreakpointsService) {}

    openSelectCityDialog(): void {
        if (this.vbs.currentLayout == ViewBreakpointsShared.Layout.Mobile) {
            this.matDialog.open(AppModalSelectCityComponent, {
                ...defaultModalConfig,
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100%',
                width: '100%'
            });
        } else {
            this.matDialog.open(AppModalSelectCityComponent, {
                ...defaultModalConfig,
                disableClose: false,
            });
        }
    }
}
