import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryCostDto } from './delivery-cost.dto';
import { SortOrderShared } from '../shared/sort-order.shared';
import { CityDto } from './city.dto';

export class DeliveryDto implements SortOrderShared.SortableEntity {
  static LAST_DTO_VERSION = '1';

  @ApiModelProperty()
  DTO_VERSION: string = DeliveryDto.LAST_DTO_VERSION;

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  isEnabled: boolean;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  title: string;
  
  @ApiModelProperty()
  additionally: string;

  @ApiModelProperty()
  shortDescription: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  cities: Array<CityDto>;

  @ApiModelProperty()
  deliveryCosts: Array<DeliveryCostDto>;

  @ApiModelProperty()
  shouldRequestDeliveryAddress: boolean;

  @ApiModelProperty() 
  minBasketCost: number;
}
