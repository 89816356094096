import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO, SourceDefinition } from '../shared/common.shared';
import { ContentDTO } from '../shared/common-content.shared';
import { AttachmentDto } from './attachment.dto';
import { SolutionCategoryDto } from './solution-category.dto';
import { TagDto } from './tag.dto';
import { SortOrderShared } from '../shared/sort-order.shared';
import { ProductCategoryDto } from './product-category.dto';
import { ProductDto } from './product.dto';

export class SolutionBlockDto {
  @ApiModelProperty()
  sid: string;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  uriSource: SourceDefinition;

  @ApiModelProperty()
  image: AttachmentDto;
}

export class SolutionListDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  solutionCategoryId: number;

  @ApiModelProperty()
  articleCategoryUri: string;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  isPublished: boolean;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  category: string;

  @ApiModelProperty()
  cover: AttachmentDto;
}

export class SolutionDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  dateCreated: string;

  @ApiModelProperty()
  dateUpdatedAt: string;

  @ApiModelProperty()
  datePublished?: string | undefined;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  isPublished: boolean;

  @ApiModelProperty()
  solutionCategory: SolutionCategoryDto;

  @ApiModelProperty()
  solutionTags: Array<TagDto>;

  @ApiModelProperty()
  relatedProductCategories: Array<ProductCategoryDto>;

  @ApiModelProperty()
  relatedProducts: Array<ProductDto>;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  alt: string;

  @ApiModelProperty()
  annotation?: string;

  @ApiModelProperty()
  description?: string;

  @ApiModelProperty({
    type: () => ContentDTO,
  })
  contents: ContentDTO;

  @ApiModelProperty()
  cover: AttachmentDto;

  @ApiModelProperty()
  totalCost: string;

  @ApiModelProperty()
  blocks: Array<SolutionBlockDto>;

  @ApiModelProperty({
    type: () => PageMetadataSEO,
  })
  seo: PageMetadataSEO;
}

export class SolutionESDto extends SolutionDto {
  @ApiModelProperty()
  solutionCategoryTitle: string;
}
