import { Injectable } from '@angular/core';
import { CityDto, ProductESDto } from '@interid/interid-site-shared';
import { ProductCardSource } from '../product-card.source';

export class DeliveriesList {
    deliveries: Array<Deliveries>;
    available: string;
    availableColor: string;
}

export class Deliveries {
    title: string;
    additionally: string;
}

@Injectable()
export class ProductCardDeliveryHelper {
    constructor(private readonly source: ProductCardSource) {}

    get product(): ProductESDto {
        return this.source.product;
    }

    listDeliveriesB(city: CityDto): DeliveriesList {
        if (this.product.flags.isAvailableDepot && city.isDefault) {
            return {
                availableColor: '#69A042',
                available: 'В наличии',
                deliveries: [
                    { title: 'Самовывоз, сегодня - бесплатно', additionally: '' },
                    { title: 'Курьером, 1-3 дня - от 890 руб.', additionally: '' },
                ],
            };
        } else if (this.product.flags.isAvailableDepot && !city.isDefault) {
            return {
                availableColor: '#69A042',
                available: 'В наличии',
                deliveries: [{ title: 'Транспортной компанией, 3-7 дней', additionally: '' }],
            };
        } else if (this.product.flags.isAvailableVendorDepot && city.isDefault) {
            return {
                availableColor: '#69A042',
                available: 'В наличии',
                deliveries: [
                    { title: 'Самовывоз, завтра - бесплатно', additionally: '' },
                    { title: 'Курьером, 2-4 дня - от 890 руб.', additionally: '' },
                ],
            };
        } else if (this.product.flags.isAvailableVendorDepot && !city.isDefault) {
            return {
                availableColor: '#69A042',
                available: 'В наличии',
                deliveries: [{ title: 'Транспортной компанией, 3-7 дней', additionally: '' }],
            };
        } else if (!this.product.flags.isAvailable && !this.product.flags.isAvailableDepot && !this.product.flags.isAvailableVendorDepot) {
            return {
                availableColor: '#253238',
                available: 'Наличие уточняйте у менеджера',
                deliveries: [],
            };
        } else {
            return undefined;
        }
    }
}
