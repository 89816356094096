
<div class="c">
  <div class="c-line"></div>

  <div class="c-nav">
    <div class="c-nav-close" (click)="close()">
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M.435 17.74a1.568 1.568 0 0 1-.002-2.166L6.52 9.222.433 2.87A1.568 1.568 0 0 1 .435.704L.486.651a1.524 1.524 0 0 1 2.205 0l7.19 7.487a1.57 1.57 0 0 1 0 2.168l-7.19 7.488a1.526 1.526 0 0 1-2.205 0l-.051-.054Z" fill="#90A4AF" />
        <path d="M17.565.704c.58.603.58 1.563.002 2.166L11.48 9.222l6.086 6.352a1.569 1.569 0 0 1-.002 2.166l-.051.054a1.525 1.525 0 0 1-2.204 0l-7.19-7.488a1.568 1.568 0 0 1 0-2.168L15.31.651a1.524 1.524 0 0 1 2.204 0l.051.053Z" fill="#90A4AF" />
      </svg>
    </div>

    <div class="c-nav-item">
      <a [routerLink]="['/solutions']" (click)="close()"
        ><span translate>app.components.app_header_mobile_main.main_menu.solutions</span></a
        >
      </div>
      <div class="c-nav-item">
        <a [routerLink]="['/delivery']" (click)="close()"
          ><span translate>app.components.app_header_mobile_main.main_menu.delivery</span></a
          >
        </div>
        <div class="c-nav-item">
          <a [routerLink]="['/articles']" (click)="close()"
            ><span translate>app.components.app_header_mobile_main.main_menu.articles</span></a
            >
          </div>
          <div class="c-nav-item">
            <a [routerLink]="['/about']" (click)="close()"
              ><span translate>app.components.app_header_mobile_main.main_menu.about</span></a
              >
            </div>
            <div class="c-nav-item">
              <a [routerLink]="['/about/vacancies']" (click)="close()"
                ><span translate>app.components.app_header_mobile_main.main_menu.vacancies</span>
                @if (vacancies() && vacancies() !== 0) {
                  <div class="counter">
                    <div class="numbers">
                      <span>{{ vacancies() }}</span>
                    </div>
                  </div>
                }
              </a>
            </div>
            <div class="c-nav-item">
              <a [routerLink]="['/contacts']" (click)="close()"
                ><span translate>app.components.app_header_mobile_main.main_menu.contacts</span></a
                >
              </div>
            </div>
            <div class="c-contacts">
              <div class="header">
                <span> Звоните и пишите нам </span>
              </div>

              <div class="c-contacts-item">
                <span>
                  {{ appBootstrap().constants.workTime }}
                </span>
              </div>

              <div class="c-contacts-item">
                <a [href]="state().city?.variants?.phone | phoneHref"
                  ><span>
                  {{ state().city?.variants?.phone }}
                </span></a
                >
              </div>

              <div class="c-contacts-item">
                <a href="tel:88001002120"><span> 8 (800) 100-21-20 </span></a>
              </div>

              <div class="c-contacts-item">
                <a href="mailto:info@interid.ru"><span> info&#64;interid.ru </span></a>
              </div>
            </div>

            <div class="c-social">
              <div class="header">
                <span> Соцсети </span>
              </div>
              <div class="items">
                @if (appBootstrap().constants.telegram) {
                  <a class="item" [href]="appBootstrap().constants.telegram | trustUrl">
                    <div class="icon">
                      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y=".147" width="32" height="32" rx="16" fill="#27A6E5" />
                        <path d="M22.4 10.229 19.996 22.78s-.337.87-1.26.453l-5.548-4.405-.026-.013c.75-.697 6.56-6.107 6.814-6.352.393-.38.149-.606-.307-.32l-8.584 5.645-3.31-1.153s-.522-.192-.572-.61c-.05-.418.588-.644.588-.644l13.5-5.484s1.109-.505 1.109.33Z" fill="#FEFEFE" />
                      </svg>
                    </div>
                    <span> Telegram </span>
                  </a>
                }

                @if (appBootstrap().constants.whatsapp) {
                  <a class="item" [href]="appBootstrap().constants.whatsapp | trustUrl">
                    <div class="icon">
                      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y=".147" width="32" height="32" rx="16" fill="#48C95F" />
                        <path d="M22.341 9.797a8.343 8.343 0 0 0-5.912-2.45c-4.624 0-8.37 3.733-8.37 8.342 0 1.458.41 2.916 1.112 4.141L8 24.147l4.449-1.167a8.56 8.56 0 0 0 3.98.992c4.625 0 8.371-3.733 8.371-8.342-.059-2.158-.878-4.258-2.459-5.833Zm-1.873 8.867c-.175.466-.995.933-1.405.991-.35.059-.82.059-1.287-.058-.293-.117-.703-.233-1.171-.467-2.107-.875-3.454-2.975-3.57-3.15-.118-.116-.879-1.108-.879-2.158 0-1.05.527-1.517.702-1.75.176-.233.41-.233.586-.233h.41c.117 0 .292-.059.468.35.176.408.585 1.458.644 1.516a.37.37 0 0 1 0 .35c-.059.117-.117.234-.234.35-.117.117-.234.292-.293.35-.117.117-.234.234-.117.409.117.233.527.875 1.17 1.458.82.7 1.464.933 1.698 1.05.234.117.351.058.468-.058.118-.117.527-.584.644-.817.117-.233.293-.175.469-.117.175.059 1.229.584 1.405.7.234.117.35.175.41.234.058.175.058.583-.118 1.05Z" fill="#fff" />
                      </svg>
                    </div>
                    <span> WhatsApp </span>
                  </a>
                }
              </div>
            </div>

            @if (state().city?.isDefault) {
              <div class="c-contacts-item c-contacts-work-time">
                <div class="c-ci-phone">
                  <div class="c-ci-phone-right">@if (appBootstrap().constants.whatsapp) {
                    <a class="icon icon-28x28px" [href]="appBootstrap().constants.whatsapp | trustUrl"> </a>
                    }@if (appBootstrap().constants.telegram) {
                    <a class="icon icon-28x28px" [href]="appBootstrap().constants.telegram | trustUrl" target="_blank"> </a>
                  }</div>
                </div>
              </div>
            }
          </div>
