import { ContentDTO } from './common-content.shared';

export namespace RegistryShared {
  export enum RegistryType {
    String = 'string',
    JSON = 'json',
    Email = 'email',
    Phone = 'phone',
    Text = 'text',
    Html = 'html',
    RichContent = 'rich-content',
    Integer = 'integer',
    Double = 'double',
    Boolean = 'boolean',
    Cron = 'cron',
    Article = 'article',
  }

  export abstract class RegistryBaseNode<T extends RegistryType, V> {
    name: string;
    type: T;
    public: boolean;
    exportToWeb?: boolean;
    hiddenFromList: boolean;
    value?: V;
    defaults?: V;
  }

  export class RegistryNodeString extends RegistryBaseNode<RegistryType.String, string>{}
  export class RegistryNodeJSON<T> extends RegistryBaseNode<RegistryType.JSON, T>{}
  export class RegistryNodeEmail extends RegistryBaseNode<RegistryType.Email, string>{}
  export class RegistryNodePhone extends RegistryBaseNode<RegistryType.Phone, string>{}
  export class RegistryNodeText extends RegistryBaseNode<RegistryType.Text, string>{}
  export class RegistryNodeHtml extends RegistryBaseNode<RegistryType.Html, string> {}
  export class RegistryNodeRichContent extends RegistryBaseNode<RegistryType.RichContent, ContentDTO> {}
  export class RegistryNodeInteger extends RegistryBaseNode<RegistryType.Integer, number> {}
  export class RegistryNodeDouble extends RegistryBaseNode<RegistryType.Double, number> {}
  export class RegistryNodeBoolean extends RegistryBaseNode<RegistryType.Boolean, boolean> {}
  export class RegistryNodeCron extends RegistryBaseNode<RegistryType.Cron, string> {}
  export class RegistryNodeArticle extends RegistryBaseNode<RegistryType.Article, RegistryNodeArticleValue> {}

  export class RegistryNodeArticleValue {
    articleId: number | undefined;
    articleTitle: string | undefined;
  }

  export type RegistryNode =
      RegistryNodeString
    | RegistryNodeJSON<any>
    | RegistryNodeEmail
    | RegistryNodePhone
    | RegistryNodeText
    | RegistryNodeHtml
    | RegistryNodeInteger
    | RegistryNodeDouble
    | RegistryNodeBoolean
    | RegistryNodeCron
    | RegistryNodeRichContent
    | RegistryNodeArticle;

  export class RegistrySchema {
    [key: string]: RegistrySchema | RegistryNode;
  }
}
