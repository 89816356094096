import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ProductESDto } from '../models/entities/product.dto';
import { ProductCompareDto } from '../models/entities/product-compare.dto';

export enum ProductCompareEndpointPaths {
    include = '/api/v1/product-compare/add',
    exclude = '/api/v1/product-compare/exclude',
    list = '/api/v1/product-compare/list',
}

export interface ProductCompareEndpoint {
    include(request: ProductCompareIncludeRequest): Promise<Array<ProductCompareDto>> | Observable<Array<ProductCompareDto>>;
    exclude(request: ProductCompareExcludeRequest): Promise<Array<ProductCompareDto>> | Observable<Array<ProductCompareDto>>;
    list(): Promise<ProductCompareListResponse> | Observable<ProductCompareListResponse>;
}

export class ProductCompareIncludeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;
}

export class ProductCompareExcludeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;
}

export class ProductCompareListResponse {
    @ApiModelProperty({
        type: ProductCompareDto,
        isArray: true,
    })
    items: Array<ProductCompareDto>;

    @ApiModelProperty({
        type: ProductCategoryListDto,
        isArray: true,
    })
    categories: Array<ProductCategoryListDto>;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    products: Array<ProductESDto>;
}
