import { Observable } from 'rxjs';
import { IsArray, IsNotEmpty, IsObject, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { RegistryShared } from '../models/shared/registry.shared';

export enum RegistryEndpointPaths {
    currentSchema = '/api/v1/registry/currentSchema',
    updateSchemaRecord = '/api/v1/registry/updateSchemaRecord',
    getSchemaRecord = '/api/v1/registry/getSchemaRecord',
    getSchemaRecordMany = '/api/v1/registry/getSchemaRecordMany',
}

export interface RegistryEndpoint {
    currentSchema(): Promise<CurrentSchemaResponse> | Observable<CurrentSchemaResponse>;
    updateSchemaRecord(request: UpdateSchemaRecordRequest): Promise<UpdateSchemaRecordResponse> | Observable<UpdateSchemaRecordResponse>;
    getSchemaRecord<T extends RegistryShared.RegistryNode = RegistryShared.RegistryNode>(request: GetSchemaRecordRequest): Promise<GetSchemaRecordResponse<T>> | Observable<GetSchemaRecordResponse<T>>;
    getSchemaRecordMany<T extends RegistryShared.RegistryNode = RegistryShared.RegistryNode>(request: GetSchemaRecordManyRequest): Promise<GetSchemaRecordManyResponse<T>> | Observable<GetSchemaRecordManyResponse<T>>;
}

export type CurrentSchemaResponse = RegistryShared.RegistrySchema;

export class GetSchemaRecordRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    path: string;
}

export class GetSchemaRecordResponse<T extends RegistryShared.RegistryNode> {
    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    node: T;
}

export class GetSchemaRecordManyRequest {
    @ApiModelProperty()
    @IsString({ each: true })
    @IsArray()
    @IsNotEmpty()
    paths: Array<string>;
}

export class GetSchemaRecordManyResponse<T extends RegistryShared.RegistryNode> {
    @ApiModelProperty()
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    nodes: Array<T>;
}

export class UpdateSchemaRecordRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    path: string;

    @ApiModelProperty()
    @IsNotEmpty()
    value: any;
}

export type UpdateSchemaRecordResponse = RegistryShared.RegistryNode;
