<span class="c">
  <div class="c-data">
    <div class="message">
      <span>Заполните обязательное поле {{data.message}} <span class="required">*</span></span>
    </div>
    <div class="c-close" (click)="close()">
      <img src="/assets/images/Union.svg" loading="lazy" />
    </div>
  </div>
</span>
