import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { QuotationRequestWebEndpoint, QuotationRequestSendRequest, QuotationRequestWebEndpointPaths, QuotationRequestSendResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebQuotationRequestDataAccess implements QuotationRequestWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    send(request: QuotationRequestSendRequest, recaptchaV3Token: string): Observable<QuotationRequestSendResponse> {
        return this.http.post(QuotationRequestWebEndpointPaths.send, request, {
            headers: {
                'recaptcha': recaptchaV3Token,
            },
        });
    }
}
