import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Link } from '../../../../models/shared/common.shared';

export class HttpRedirectDto {
  @ApiModelProperty({
    description: 'ID',
  })
  id: number;

  @ApiModelProperty({
    description: 'URL (redirect from)',
  })
  url: Link;

  @ApiModelProperty({
    description: 'URL (Redirect To)',
  })
  redirectTo: Link;
}
