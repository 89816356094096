export function getProductCategoryPath<T extends { id: number; parent?: number }>(categoryId: number, categories: Array<T>): Array<T> {
  const result: Array<T> = [];

  let next = categoryId;

  while (next) {
    const found = categories.find((c) => c.id === next);

    if (found) {
      result.push(found);

      next = found.parent;
    } else {
      next = undefined;
    }
  }

  return result;
}
