@if (!!dto() && dto().blocks.length > 0) {
  <div class="c">
    @if (!isMarketplacePage) {
      @for (block of dto().blocks; track trackByBlockSID($index, block)) {
        <div class="c-block">
          @if (block.title) {
            <div class="c-block-header __app-fluid-container-control-margin-bottom">
              <h2 [id]="block.sid">{{ block.title }}</h2>
            </div>
          }
          <div class="c-block-contents">
            @if (block.pair.type === 'HTML') {
              <app-content-block-html [useCutoff]="useHtmlCutoff()" [payload]="block.pair.payload" [isArticle]="isArticle()"></app-content-block-html>
            }
            @if (block.pair.type === 'SimpleTable') {
              <app-content-block-simple-table [payload]="block.pair.payload"></app-content-block-simple-table>
            }
            @if (block.pair.type === 'YouTube') {
              <app-content-block-youtube [payload]="block.pair.payload"></app-content-block-youtube>
            }
            @if (block.pair.type === 'Photos') {
              <app-content-block-photos [payload]="block.pair.payload"></app-content-block-photos>
            }
            @if (block.pair.type === 'PhotoGallery') {
              <app-content-block-photo-gallery [payload]="block.pair.payload"></app-content-block-photo-gallery>
            }
            @if (block.pair.type === 'Files') {
              <app-content-block-files [payload]="block.pair.payload"></app-content-block-files>
            }
            @if (block.pair.type === 'AboutCompanyCertificates') {
              <div class="about-company-block certificates">
                <app-content-block-about-company-certificates [payload]="block.pair.payload"></app-content-block-about-company-certificates>
              </div>
            }
            @if (block.pair.type === 'AboutCompanyAdvantages') {
              <div class="about-company-block advantages">
                <app-content-block-about-company-advantages [payload]="block.pair.payload"></app-content-block-about-company-advantages>
              </div>
            }
            @if (block.pair.type === 'AboutCompanyActivities') {
              <div class="about-company-block activities">
                <app-content-block-about-company-activities [payload]="block.pair.payload"></app-content-block-about-company-activities>
              </div>
            }
            @if (block.pair.type === 'AboutCompanyHeader') {
              <div class="about-company-block">
                <app-content-block-about-company-header [payload]="block.pair.payload"></app-content-block-about-company-header>
              </div>
            }
            @if (block.pair.type === 'PhoneCallRequestForm') {
              <app-content-block-phone-call-request-form [payload]="block.pair.payload"></app-content-block-phone-call-request-form>
            }
            @if (block.pair.type === 'Manufacturers') {
              <app-content-block-manufacturers [payload]="block.pair.payload"></app-content-block-manufacturers>
            }
            @if (block.pair.type === 'Features') {
              <app-content-block-features [payload]="block.pair.payload"></app-content-block-features>
            }
            @if (block.pair.type === 'OrderConstructor') {
              <app-content-block-order-constructor [payload]="block.pair.payload"></app-content-block-order-constructor>
            }
            @if (block.pair.type === 'CostTable') {
              <app-content-block-cost-table [payload]="block.pair.payload"></app-content-block-cost-table>
            }
            @if (block.pair.type === 'Hint') {
              <app-content-block-hint [payload]="block.pair.payload"></app-content-block-hint>
            }
            @if (block.pair.type === 'Products') {
              <app-content-block-products [payload]="block.pair.payload" [bundle]="bundleFor(block)"></app-content-block-products>
            }
            @if (block.pair.type === 'ListDeliveryOptions') {
              <app-content-block-list-delivery-options [bundle]="bundleFor(block)"></app-content-block-list-delivery-options>
            }
            @if (block.pair.type === 'SimpleImageTable') {
              <app-content-block-simple-image-table [payload]="block.pair.payload"></app-content-block-simple-image-table>
            }
            @if (block.pair.type === 'List') {
              <app-content-block-list [payload]="block.pair.payload" [isArticle]="isArticle()"></app-content-block-list>
            }
          </div>
        </div>
      }
    }
  </div>
}

@if (isMarketplacePage) {
  <div class="c-marketplaces-header __app-fluid-container-control-margin-bottom">
    <span>Аккредитованы на площадках</span>
  </div>
  <div class="c-marketplaces __app-fluid-container-padding-bottom">
    @for (block of dto().blocks; track trackByBlockSID($index, block)) {
      <div class="column">
        <div class="header __app-fluid-container-control-margin-bottom">
          <span [id]="block.sid">{{ block.title }}</span>
        </div>
        <app-content-block-list [payload]="block.pair.payload"></app-content-block-list>
      </div>
    }
  </div>
}
