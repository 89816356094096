import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppBootstrapDataService, AppBusEvent, AppBusService, defaultModalConfig, EnvironmentWebService, extractPhone, JwtService, MessagesService } from '@interid/interid-site-web/core';
import { AppBootstrapWebResponse, CityDto, JwtPayload, RoistatPhoneCallRequestContext, RoistatQuotationRequestContext, Source, urlDetectDefinition } from '@interid/interid-site-shared';
import { PhoneCallRequestModalComponent, PhoneCallRequestModalRequest } from '@interid/interid-site-web/ui-shared';
import { AuthSignInComponent } from '@interid/interid-site-web/core-auth';
import { APP_LAYOUT_RESOLVE_KEY, AppLayoutResolveData } from '../app-layout/app-layout.resolve';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { REQUEST } from '@interid/interid-site-web/ui-shared';
import { AppModalSelectCityService } from '../app-modal-select-city/app-modal-select-city.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { QuotationRequestModalComponent, QuotationRequestModalRequest } from '@interid/interid-site-web/ui-shared-product';

interface State {
    city: CityDto;
    remoteAddressCity: CityDto;
    isOnSalesRoute: boolean;
    hrefPhone: string;
    hrefEmail: string;
}

@Component({
    selector: 'app-header-wide',
    templateUrl: './app-header-wide.component.html',
    styleUrls: ['./app-header-wide.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderWideComponent implements OnInit, OnDestroy {
    @ViewChild('attachPhones') attachPhonesRef: ElementRef<HTMLDivElement>;
    @ViewChild('attachCity') attachCityRef: ElementRef<HTMLDivElement>;
    @ViewChild('attachCategories') attachCategoriesRef: ElementRef<HTMLDivElement>;
    @ViewChild('emailForOrders') emailForOrdersRef: ElementRef<HTMLSpanElement>;

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        city: new CityDto(),
        remoteAddressCity: new CityDto(),
        isOnSalesRoute: false,
        hrefPhone: `tel:${extractPhone(this.resolvedData.constants.phoneForOrders)}`,
        hrefEmail: `mailto:${this.resolvedData.constants.emailForOrders}`,
    };

    constructor(
        private messagesService: MessagesService,
        private readonly clipboard: Clipboard,
        private readonly route: Router,
        private readonly appModalSelectCityService: AppModalSelectCityService,
        private readonly env: EnvironmentWebService,
        @Optional() @Inject(REQUEST) protected request: Request,
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly cdr: ChangeDetectorRef,
        private readonly appSession: AppSessionService,
        private readonly appBus: AppBusService,
        private readonly jwtService: JwtService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly matDialog: MatDialog,
        private readonly appBootstrap: AppBootstrapDataService,
        private readonly renderer: Renderer2,
        @Inject(DOCUMENT) private _document: Document) {}

    hideOthers(event, target) {
        const div = event.target.closest('div');
        const copy = div.querySelector('.website-tooltiptext > span.copy');
        copy.textContent = 'Скопировано';

        const all = document.querySelectorAll(`.website-tooltiptext:not(${target}) > span.copy`);

        for (let i = 0; i < all.length; ++i) {
            all[i].textContent = 'Скопировать';
        }
    }

    get vacancies$(): Observable<number> {
        return this.appBootstrap.data$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((x) => x.vacancies)
        );
    }

    get slogan$(): Observable<string> {
        return this.appBootstrap.data$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((x) => x.constants.slogan)
        );
    }

    copyEmailForOrders(event) {
        this.hideOthers(event, '#copy-tooltip-3');

        const span = this.emailForOrdersRef.nativeElement;

        if (span) {
            this.clipboard.copy(span.innerText);

            this.messagesService.info({
                message: 'Скопированно в буфер обмена',
                translate: false,
            });
        }
    }

    get appBootstrap$(): Observable<AppBootstrapWebResponse> {
        return this.appBootstrap.data$;
    }

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.state = {
            ...this.state,
            city: this.appBootstrap.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find((x) => x.isDefault),
        };

        if (!isPlatformServer(this.platformId)) this.cdr.markForCheck();

        this.jwtService.jwt$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => this.cdr.markForCheck());

        this.appBus.events$
            .pipe(
                filter((event) => event.type === AppBusEvent.IsOnSalesRoute),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe(() => {
                this.state = {
                    ...this.state,
                    isOnSalesRoute: true,
                };

                this.cdr.markForCheck();
            });

        this.appBus.events$
            .pipe(
                filter((event) => event.type === AppBusEvent.IsOutOfSalesRoute),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe(() => {
                this.state = {
                    ...this.state,
                    isOnSalesRoute: false,
                };

                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `app.components.app_header_desktop.${input}`;
    }

    get resolvedData(): AppLayoutResolveData {
        return this.activatedRoute.snapshot.data[APP_LAYOUT_RESOLVE_KEY];
    }

    get isSignedId(): boolean {
        return this.jwtService.hasJwt();
    }

    get jwtPayload$(): Observable<JwtPayload> {
        return this.jwtService.jwtPayload$;
    }

    get favoritesCount$(): Observable<number> {
        return this.appSession.numFavorites$;
    }

    get basketCount$(): Observable<number> {
        return this.appSession.numProductsInBasket$;
    }

    get comparesCount$(): Observable<number> {
        return this.appSession.numCompares$;
    }

    openSelectDialog(): void {
        this.appModalSelectCityService.openSelectCityDialog();
    }

    get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    signIn(): void {
        this.matDialog.open(AuthSignInComponent, {
            ...defaultModalConfig,
        });
    }

    quotationRequest(): void {
        this.matDialog.open(QuotationRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            data: {
                context: RoistatQuotationRequestContext.Header,
            } as QuotationRequestModalRequest,
        });
    }

    phoneCallRequest(): void {
        const urlSource = urlDetectDefinition(this.route.routerState.snapshot.url);

        this.matDialog.open(PhoneCallRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            data: {
                context: RoistatPhoneCallRequestContext.Header,
                productId: urlSource.type == Source.Product ? urlSource?.payload.productId : undefined,
            } as PhoneCallRequestModalRequest,
        });
    }
}
