import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsEmail, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Max, Min } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductQuestionDto } from '../models/entities/product-question.dto';
import { ProductQuestionShared } from '../models/shared/product-question.shared';

export enum ProductQuestionEndpointPaths {
    create = '/api/v1/product-question/create',
    edit = '/api/v1/product-question/edit',
    delete = '/api/v1/product-question/delete',
    approve = '/api/v1/product-question/approve',
    reject = '/api/v1/product-question/reject',
    reply = '/api/v1/product-question/reply',
    list = '/api/v1/product-question/list',
}

export interface ProductQuestionEndpoint {
    create(request: CreateProductQuestionRequest): Promise<ProductQuestionDto> | Observable<ProductQuestionDto>;
    edit(request: EditProductQuestionRequest): Promise<ProductQuestionDto> | Observable<ProductQuestionDto>;
    delete(request: DeleteProductQuestionRequest): Promise<void> | Observable<void>;
    approve(request: ApproveProductQuestionRequest): Promise<ProductQuestionDto> | Observable<ProductQuestionDto>;
    reject(request: RejectProductQuestionRequest): Promise<ProductQuestionDto> | Observable<ProductQuestionDto>;
    reply(request: ReplyProductQuestionRequest): Promise<ProductQuestionDto> | Observable<ProductQuestionDto>;
    list(request: ListProductQuestionRequest): Promise<ListProductQuestionResponse> | Observable<ListProductQuestionResponse>;
}

export class ProductQuestionEntityBody {
 
    @ApiModelProperty()
    @IsString()
    author: string;

    @ApiModelProperty()
    @IsString()
    email: string;

    @ApiModelProperty()
    @IsString()
    positive: string;

    @ApiModelProperty()
    @IsString()
    negative: string;

    @ApiModelProperty()
    @IsString()
    contents: string;
}

export class EditProductQuestionRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    date?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    author?: string;

    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsOptional()
    email?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    contents: string;
}


export class CreateProductQuestionRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    author?: string;

    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsOptional()
    email?: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    isAnonymous?: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    contents: string;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    contentAttachmentIds: Array<number>;
}

export class DeleteProductQuestionRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ApproveProductQuestionRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class RejectProductQuestionRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ReplyProductQuestionRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    response: string;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    responseAttachmentIds: Array<number>;
}

export type ListProductQuestionRequest = ProductQuestionShared.SearchRequest;
export type ListProductQuestionResponse = ProductQuestionShared.SearchResponse;
