import { Injectable } from '@angular/core';
import { CreateCurrencyRequest, CurrencyDto, CurrencyEndpoint, CurrencyEndpointPaths, DeleteCurrencyRequest, EditCurrencyRequest, ListAvailableCurrenciesResponse, ListCurrenciesRequest, ListCurrenciesResponse, SyncCurrenciesWithCBRResponse, SyncCurrencyWithCBRRequest } from '@interid/interid-site-shared';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridCmsCurrencyDataAccess implements CurrencyEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    createCurrency(request: CreateCurrencyRequest): Observable<CurrencyDto> {
        return this.http.post(CurrencyEndpointPaths.createCurrency, request);
    }

    editCurrency(request: EditCurrencyRequest): Observable<CurrencyDto> {
        return this.http.post(CurrencyEndpointPaths.editCurrency, request);
    }

    deleteCurrency(request: DeleteCurrencyRequest): Observable<void> {
        return this.http.post(CurrencyEndpointPaths.deleteCurrency, request);
    }

    listCurrencies(request: ListCurrenciesRequest): Observable<ListCurrenciesResponse> {
        return this.http.post(CurrencyEndpointPaths.listCurrencies, request);
    }

    listAvailableCurrencies(): Observable<ListAvailableCurrenciesResponse> {
        return this.http.post(CurrencyEndpointPaths.listAvailableCurrencies);
    }

    syncCurrencyWithCBR(request: SyncCurrencyWithCBRRequest): Observable<CurrencyDto> {
        return this.http.post(CurrencyEndpointPaths.syncCurrencyWithCBR, request);
    }

    syncCurrenciesWithCBR(): Observable<SyncCurrenciesWithCBRResponse> {
        return this.http.post(CurrencyEndpointPaths.syncCurrenciesWithCBR);
    }
}
