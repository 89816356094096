<div class="c" #container>
  <div class="c-blocks">
    <!-- цена от до -->
    @if (withPriceRange() && usedPriceRange() && usedPriceRange().priceRangeMin !== null && usedPriceRange().priceRangeMax !== null) {
      <div class="c-block price-range">
        <div class="c-header price-range-header">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('price.title') | translate }}</span>
            </div>
          </div>
        </div>
        @if (state().isPriceBlockEnabled) {
          <div class="c-contents">
            <app-filters-slider
              [(valueMin)]="sliderValue"
              [(valueMax)]="sliderValueHigh"
              [options]="sliderOptions()"
              (sliderChange)="sliderChange($event)"
              [hideSlider]="! isBrowser()"
            />
          </div>
        }
      </div>
    }

    <!-- производители -->
    @if (withManufacturers() && hasManufacturers()) {
      <div class="c-block">
        <div class="c-header">
          <div class="c-header-title" (click)="toggleManufacturersBlock()">
            <span>{{ t('manufacturers.title') | translate }}</span>
          </div>
          <div class="c-header-right">
            @if (filtersManufacturers()?.length && filtersManufacturers().length > 0) {
              <div class="c-header-right-reset" (click)="resetManufacturersEvent.emit()">
                <span>
                  {{ t('reset') | translate }}
                </span>
              </div>
            }
            <div class="c-header-toggle" (click)="toggleManufacturersBlock()">
              <div class="icon icon-primary-dark-hover">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.168 1.028a1 1 0 0 0 0 1.41l3.54 3.59-3.54 3.54a1 1 0 1 0 1.42 1.41l4.24-4.24a1 1 0 0 0 0-1.42l-4.24-4.29a1 1 0 0 0-1.42 0Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        @if (filtersManufacturers()?.length && filtersManufacturers().length > 0) {
          <div class="c-block-reset-chips">
            @for (manufacturer of filtersManufacturers(); track trackById($index, manufacturer)) {
              <div class="c-block-reset" (click)="resetManufacturer(manufacturer.id)">
                <span>{{ manufacturer.title }}</span>
                <div class="c-block-reset-icon">
                  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.94 4.003 2.867-2.86A.67.67 0 0 0 7.86.196L5 3.063 2.14.196a.67.67 0 0 0-.947.947l2.867 2.86-2.867 2.86a.667.667 0 0 0 .217 1.092.667.667 0 0 0 .73-.146L5 4.943l2.86 2.866a.667.667 0 0 0 1.093-.217.667.667 0 0 0-.146-.73L5.94 4.003Z" fill="#1269FF" /></svg>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }

    <!-- параметры - словари, словари множества -->
    @if (withParams() && hasDictParams()) {
      @for (dict of state().formsDictParams; track trackById($index, dict)) {
        <ng-container [formGroup]="dict.form">
          @if (colorHasNoHex(dict)) {
            <div class="c-block">
              <div class="c-header">
                <div class="c-header-title" (click)="toggleDictBlock(dict.paramId)">
                  <span>{{ dict.paramTitle }}</span>
                </div>
                <div class="c-header-right">
                  @if (paramsReferencesHelper.hasParamsReference(dict.paramId)) {
                    <a class="c-header-help" #helpElement [title]="paramsReferencesHelper.getParamsReference(dict.paramId).alt" [href]="dictionaryRouterLink() | trustUrl">
                      <div class="icon icon-primary-dark-hover icon-20x20px" (click)="$event.stopPropagation(); $event.preventDefault(); paramsReferencesHelper.openParamsReference(dict.paramId, helpElement)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#a)"><path d="M9.29 13.29a1.578 1.578 0 0 0-.12.15.76.76 0 0 0-.09.18.64.64 0 0 0-.06.18 1.36 1.36 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 11 14a1 1 0 0 0-1.71-.71ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 10 7a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.18A3 3 0 0 0 10 5Z" fill="#B0BFC6" /></g>
                          <defs>
                            <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
                          </defs>
                        </svg>
                      </div>
                    </a>
                  }
                  @if (withFiltersParamsReset(dict.paramId)) {
                    <div class="c-header-right-reset" (click)="resetParam(dict.paramId)">
                      <span>
                        {{ t('reset') | translate }}
                      </span>
                    </div>
                  }
                  <div class="c-header-toggle" (click)="toggleDictBlock(dict.paramId)">
                    <div class="icon icon-primary-dark-hover">
                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.168 1.028a1 1 0 0 0 0 1.41l3.54 3.59-3.54 3.54a1 1 0 1 0 1.42 1.41l4.24-4.24a1 1 0 0 0 0-1.42l-4.24-4.29a1 1 0 0 0-1.42 0Z" fill="#B0BFC6" /></svg>
                    </div>
                  </div>
                </div>
              </div>
              @if (filtersParams()?.length && filtersParams().length > 0) {
                <div class="c-block-reset-chips">
                  @for (paramsValue of filtersParams(); track trackById($index, paramsValue)) {
                    @if (dict.paramId === paramsValue.paramId) {
                      <div class="c-block-reset" (click)="resetParamsValue(dict.paramId, paramsValue.id)">
                        <span>{{ paramsValue.title }}</span>
                        <div class="c-block-reset-icon">
                          <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.94 4.003 2.867-2.86A.67.67 0 0 0 7.86.196L5 3.063 2.14.196a.67.67 0 0 0-.947.947l2.867 2.86-2.867 2.86a.667.667 0 0 0 .217 1.092.667.667 0 0 0 .73-.146L5 4.943l2.86 2.866a.667.667 0 0 0 1.093-.217.667.667 0 0 0-.146-.73L5.94 4.003Z" fill="#1269FF" /></svg>
                        </div>
                      </div>
                    }
                  }
                </div>
              }
            </div>
          }
        </ng-container>
      }
    }

    <!-- серии товаров -->
    @if (withProductSeries() && hasProductSeries()) {
      <div class="c-block">
        <div class="c-header">
          <div class="c-header-title" (click)="toggleProductSeriesBlock()">
            <span>{{ t('series.title') | translate }}</span>
          </div>
          <div class="c-header-right">
            @if (filtersSeries()?.length && filtersSeries().length > 0) {
              <div class="c-header-right-reset" (click)="resetProductSeriesEvent.emit()">
                <span>
                  {{ t('reset') | translate }}
                </span>
              </div>
            }
            <div class="c-header-toggle" (click)="toggleProductSeriesBlock()">
              <div class="icon icon-primary-dark-hover">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.168 1.028a1 1 0 0 0 0 1.41l3.54 3.59-3.54 3.54a1 1 0 1 0 1.42 1.41l4.24-4.24a1 1 0 0 0 0-1.42l-4.24-4.29a1 1 0 0 0-1.42 0Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        @if (filtersSeries()?.length && filtersSeries().length > 0) {
          <div class="c-block-reset-chips">
            @for (series of filtersSeries(); track trackById($index, series)) {
              <div class="c-block-reset" (click)="resetSeries(series.id)">
                <span>{{ series.title }}</span>
                <div class="c-block-reset-icon">
                  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.94 4.003 2.867-2.86A.67.67 0 0 0 7.86.196L5 3.063 2.14.196a.67.67 0 0 0-.947.947l2.867 2.86-2.867 2.86a.667.667 0 0 0 .217 1.092.667.667 0 0 0 .73-.146L5 4.943l2.86 2.866a.667.667 0 0 0 1.093-.217.667.667 0 0 0-.146-.73L5.94 4.003Z" fill="#1269FF" /></svg>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
    <div class="c-submit">
      <div class="c-submit-button" (click)="ngSubmit()">
        <span>
          {{ t('submit') | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
