import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { AttachmentDto } from '../models/entities/attachment.dto';

export enum DevFeedbackWebEndpointPaths {
    create = '/api/v1/web/dev-feedback/create',
    upload = '/api/v1/web/dev-feedback/upload',
}

export interface DevFeedbackWebEndpoint {
    create(request: DevFeedbackWebCreateRequest): Promise<void> | Observable<void>;
    upload(file: File | any): Promise<AttachmentDto> | Observable<AttachmentDto>;
}

export class DevFeedbackWebEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        default: 'Regular'
    })
    @IsOptional()
    type?: string = 'Regular'

    @ApiModelProperty()
    @IsOptional()
    productPartNumber?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    description: string;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    fileIds: Array<number>;
}

export class DevFeedbackWebCreateRequest extends DevFeedbackWebEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsOptional()
    author: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    authorEmail: string;
}
