export const interidSiteWebFeatureDeliveryI18n = {
    delivery: {
        components: {
            delivery: {
                title: 'Доставка, оплата, условия обмена и возврата товара',
                costs: {
                    None: '',
                    Free: 'при сумме заказа от <strong>{{ triggerFrom }}</strong> ₽',
                    Paid: '<strong>{{ costs }} ₽</strong>',
                    Individual: '<strong>Тарифицируется индивидуально</strong>',
                },
            },
        },
    },
};
