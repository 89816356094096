import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { SalesProductsWebRequest, SalesProductsWebResponse, SalesWebEndpoint, SalesWebEndpointPaths, SalesWebRequest, SalesWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebSalesDataAccess implements SalesWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    sales(request: SalesWebRequest): Observable<SalesWebResponse> {
        return this.http.post(SalesWebEndpointPaths.sales, request);
    }

    products(request: SalesProductsWebRequest): Observable<SalesProductsWebResponse> {
        return this.http.post(SalesWebEndpointPaths.products, request);
    }
}
