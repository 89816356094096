import { AccountAccessShared } from './account-access.shared';

export namespace ImportantEventShared
{
  export enum Level {
    Minor = 'Minor',
    Major = 'Major',
    Critical = 'Critical',
  }

  export enum Type {
    AccountAdminAdded = 'AccountAdminAdded',
    AccountAdminAssigned = 'AccountAdminAssigned',
    AccountAdminRevoked = 'AccountAdminRevoked',
    AccountUpdatePermissions = 'AccountUpdatePermissions',
    AccountAccessSystemRootAssigned = 'AccountAccessSystemRootAssigned',
    AccountAccessSystemRootRevoked = 'AccountAccessSystemRootRevoked',
    CurrencySyncedWithCBR = 'CurrencySyncedWithCBR',
    CurrenciesSyncedWithCBR = 'CurrenciesSyncedWithCBR',
    ProductMinimalPriceViolation = 'ProductMinimalPriceViolation',
    EmailSubscribed = 'EmailSubscribed',
    EmailUnsubscribed = 'EmailUnsubscribed',
    ProductReviewCreated = 'ProductReviewCreated',
    ProductReviewUpdated = 'ProductReviewUpdated',
    ProductReviewApproved = 'ProductReviewApproved',
    ProductReviewRejected = 'ProductReviewRejected',
    ProductReviewDeleted = 'ProductReviewDeleted',
    ProductReviewReplyed = 'ProductReviewReplyed',
    ProductQuestionCreated = 'ProductQuestionCreated',
    ProductQuestionUpdated = 'ProductQuestionUpdated',
    ProductQuestionApproved = 'ProductQuestionApproved',
    ProductQuestionRejected = 'ProductQuestionRejected',
    ProductQuestionDeleted = 'ProductQuestionDeleted',
    ProductQuestionReplyed = 'ProductQuestionReplyed',
    ArticleReviewCreated = 'ArticleReviewCreated',
    ArticleReviewApproved = 'ArticleReviewApproved',
    ArticleReviewRejected = 'ArticleReviewRejected',
    ArticleReviewDeleted = 'ArticleReviewDeleted',
    ArticleReviewReplied = 'ArticleReviewReplied',
  }
}

export namespace ImportantEventsSharedJSONMetadata {
  export type JSONMetadata =
    AccountAdminAdded |
    AccountAdminAssigned |
    AccountAdminRevoked |
    AccountAccessSystemRootAssigned |
    AccountAccessSystemRootRevoked |
    AccountUpdatePermissions |
    CurrencySyncedWithCBR |
    CurrenciesSyncedWithCBR |
    ProductMinimalPriceViolation |
    EmailSubscribed |
    EmailUnsubscribed |
    ProductReviewCreated |
    ProductReviewUpdated |
    ProductReviewApproved |
    ProductReviewRejected |
    ProductReviewDeleted |
    ProductReviewReplyed |
    ProductQuestionCreated |
    ProductQuestionUpdated |
    ProductQuestionApproved |
    ProductQuestionRejected |
    ProductQuestionDeleted |
    ProductQuestionReplyed |
    ArticleReviewCreated |
    ArticleReviewApproved |
    ArticleReviewRejected |
    ArticleReviewDeleted |
    ArticleReviewReplied
  ;

  export class AccountAdminAdded {
    accountId: number;
    accountEmail: string;
  }

  export class AccountAdminAssigned {
    accountId: number;
    accountEmail: string;
  }

  export class AccountAdminRevoked {
    accountId: number;
    accountEmail: string;
  }

  export class AccountAccessSystemRootAssigned {
    accountId: number;
    accountEmail: string;
  }

  export class AccountAccessSystemRootRevoked {
    accountId: number;
    accountEmail: string;
  }

  export class AccountUpdatePermissions {
    accountId: number;
    accountEmail: string;
    oldPermissions: Array<AccountAccessShared.AccessNode>;
    newPermissions: Array<AccountAccessShared.AccessNode>;
  }

  export class CurrencySyncedWithCBR {
    currency: string;
    previousExchangeRate: number;
    newExchangeRate: number;
  }

  export class CurrenciesSyncedWithCBR {
    updates: Array<{
      currency: string;
      previousExchangeRate: number;
      newExchangeRate: number;
    }>;
  }

  export class ProductMinimalPriceViolation {
    productId: number;
    productTitle: string;
    price: string;
    basePrice: string;
    basePriceCurrency: string;
    minimalPrice: string;
  }

  export class EmailSubscribed {
    email: string;
  }

  export class EmailUnsubscribed {
    email: string;
  }

  export class ProductReviewCreated {
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    negative: string;
    positive: string;
    contents: string;
  }

  export class ProductReviewUpdated {
    id: number;
    date: string;
    author: string;
    productTitle: string;
    email: string;
    negative: string;
    positive: string;
    contents: string;
  }

  export class ProductReviewApproved {
    id: number;
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    negative: string;
    positive: string;
    contents: string;
  }

  export class ProductReviewRejected {
    id: number;
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    negative: string;
    positive: string;
    contents: string;
  }

  export class ProductReviewDeleted {
    id: number;
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    negative: string;
    positive: string;
    contents: string;
  }

  export class ProductReviewReplyed {
    id: number;
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    negative: string;
    positive: string;
    contents: string;
    response: string;
  }

  export class ProductQuestionCreated {
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    contents: string;
  }

  export class ProductQuestionUpdated {
    id: number;
    date: string;
    author: string;
    productTitle: string;
    email: string;
    contents: string;
  }

  export class ProductQuestionApproved {
    id: number;
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    contents: string;
  }

  export class ProductQuestionRejected {
    id: number;
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    contents: string;
  }

  export class ProductQuestionDeleted {
    id: number;
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    contents: string;
  }

  export class ProductQuestionReplyed {
    id: number;
    productId: number;
    productTitle: string;
    author: string;
    email: string;
    contents: string;
    response: string;
  }

  export class ArticleReviewCreated {
    articleId: number;
    articleTitle: string;
    author: string;
    email: string;
    contents: string;
  }

  export class ArticleReviewApproved {
    id: number;
    articleId: number;
    articleTitle: string;
    author: string;
    email: string;
    contents: string;
  }

  export class ArticleReviewRejected {
    id: number;
    articleId: number;
    articleTitle: string;
    author: string;
    email: string;
    contents: string;
  }

  export class ArticleReviewDeleted {
    id: number;
    articleId: number;
    articleTitle: string;
    author: string;
    email: string;
    contents: string;
  }

  export class ArticleReviewReplied {
    id: number;
    articleId: number;
    articleTitle: string;
    author: string;
    email: string;
    contents: string;
    response: string;
  }
}

export type ImportantEventsSharedPair =
  { type: ImportantEventShared.Type.AccountAdminAdded; jsonMetadata: ImportantEventsSharedJSONMetadata.AccountAdminAdded } |
  { type: ImportantEventShared.Type.AccountAdminAssigned; jsonMetadata: ImportantEventsSharedJSONMetadata.AccountAdminAssigned } |
  { type: ImportantEventShared.Type.AccountAdminRevoked; jsonMetadata: ImportantEventsSharedJSONMetadata.AccountAdminRevoked } |
  { type: ImportantEventShared.Type.AccountAccessSystemRootAssigned; jsonMetadata: ImportantEventsSharedJSONMetadata.AccountAccessSystemRootAssigned } |
  { type: ImportantEventShared.Type.AccountAccessSystemRootRevoked; jsonMetadata: ImportantEventsSharedJSONMetadata.AccountAccessSystemRootRevoked } |
  { type: ImportantEventShared.Type.AccountUpdatePermissions; jsonMetadata: ImportantEventsSharedJSONMetadata.AccountUpdatePermissions } |
  { type: ImportantEventShared.Type.CurrencySyncedWithCBR; jsonMetadata: ImportantEventsSharedJSONMetadata.CurrencySyncedWithCBR } |
  { type: ImportantEventShared.Type.CurrenciesSyncedWithCBR; jsonMetadata: ImportantEventsSharedJSONMetadata.CurrenciesSyncedWithCBR } |
  { type: ImportantEventShared.Type.ProductMinimalPriceViolation; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductMinimalPriceViolation } |
  { type: ImportantEventShared.Type.EmailSubscribed; jsonMetadata: ImportantEventsSharedJSONMetadata.EmailSubscribed } |
  { type: ImportantEventShared.Type.EmailUnsubscribed; jsonMetadata: ImportantEventsSharedJSONMetadata.EmailUnsubscribed } |
  { type: ImportantEventShared.Type.ProductReviewCreated; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductReviewCreated } |
  { type: ImportantEventShared.Type.ProductReviewUpdated; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductReviewUpdated } |
  { type: ImportantEventShared.Type.ProductReviewApproved; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductReviewApproved } |
  { type: ImportantEventShared.Type.ProductReviewRejected; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductReviewRejected } |
  { type: ImportantEventShared.Type.ProductReviewDeleted; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductReviewDeleted } |
  { type: ImportantEventShared.Type.ProductReviewReplyed; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductReviewReplyed } |
  { type: ImportantEventShared.Type.ProductQuestionCreated; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductQuestionCreated } |
  { type: ImportantEventShared.Type.ProductQuestionUpdated; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductQuestionUpdated } |
  { type: ImportantEventShared.Type.ProductQuestionApproved; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductQuestionApproved } |
  { type: ImportantEventShared.Type.ProductQuestionRejected; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductQuestionRejected } |
  { type: ImportantEventShared.Type.ProductQuestionDeleted; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductQuestionDeleted } |
  { type: ImportantEventShared.Type.ProductQuestionReplyed; jsonMetadata: ImportantEventsSharedJSONMetadata.ProductQuestionReplyed } |
  { type: ImportantEventShared.Type.ArticleReviewCreated; jsonMetadata: ImportantEventsSharedJSONMetadata.ArticleReviewCreated } |
  { type: ImportantEventShared.Type.ArticleReviewApproved; jsonMetadata: ImportantEventsSharedJSONMetadata.ArticleReviewApproved } |
  { type: ImportantEventShared.Type.ArticleReviewRejected; jsonMetadata: ImportantEventsSharedJSONMetadata.ArticleReviewRejected } |
  { type: ImportantEventShared.Type.ArticleReviewDeleted; jsonMetadata: ImportantEventsSharedJSONMetadata.ArticleReviewDeleted } |
  { type: ImportantEventShared.Type.ArticleReviewReplied; jsonMetadata: ImportantEventsSharedJSONMetadata.ArticleReviewReplied }
;
