@if (current()) {
  <div class="stars">
    @for (star of state().stars; track star) {
      <div class="star">
        @if (star === 'Full') {
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.83 7.73a.81.81 0 0 1 .27.376.811.811 0 0 1-.26.828l-3.353 3.247.812 4.61a.812.812 0 0 1-.804.966.756.756 0 0 1-.373-.098l-4.14-2.175-4.155 2.167a.812.812 0 0 1-1.177-.868l.812-4.61-3.353-3.247a.812.812 0 0 1-.202-.812.812.812 0 0 1 .657-.552l4.618-.682 2.07-4.188a.812.812 0 0 1 1.461 0l2.07 4.197 4.618.673a.811.811 0 0 1 .43.169Z" fill="#F58020" /></svg>
        }
        @if (star === 'Half') {
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" xml:space="preserve">
            <path style="stroke: #f58020; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: none; fill-rule: nonzero; opacity: 1" vector-effect="non-scaling-stroke" d="m5.95-1.23-.013.01-.012.011-2.682 2.597-.19.184.046.262.649 3.688v.007a.149.149 0 0 1-.06.15h-.001a.15.15 0 0 1-.088.03h-.003a.102.102 0 0 1-.051-.015l-.007-.003-.006-.004L.22 3.947l-.232-.122-.232.121-3.324 1.734-.004.002a.149.149 0 0 1-.156-.01.15.15 0 0 1-.06-.15l.002-.007.29-1.646.359-2.042.046-.26-.19-.186-2.68-2.592a.15.15 0 0 1-.037-.144.15.15 0 0 1 .12-.097h.002l3.695-.545.26-.039.115-.235 1.658-3.35.002-.004a.15.15 0 0 1 .269 0v.003L1.78-2.264l.116.235.26.038 3.695.54.015.001.015.001a.15.15 0 0 1 .12.084.15.15 0 0 1-.051.135z" stroke-linecap="round" transform="translate(7.2 7.02)" />
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: #f58020; fill-rule: nonzero; opacity: 1" vector-effect="non-scaling-stroke" d="M2.952 4.395-.372 6.13a.648.648 0 0 1-.942-.695l.65-3.688L-3.346-.85a.65.65 0 0 1-.162-.65.65.65 0 0 1 .525-.441l3.695-.546 1.656-3.35a.65.65 0 0 1 1.169 0z" transform="translate(4.22 7.01)" />
          </svg>
        }
        @if (star === 'None') {
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.202 17.324.064-.136a.312.312 0 0 1-.125-.312l.001-.007.812-4.61.046-.261-.19-.185L2.46 8.57a.312.312 0 0 1-.077-.305.312.312 0 0 1 .251-.208h.003l4.618-.682.26-.038.116-.235L9.7 2.914l.001-.004a.312.312 0 0 1 .561 0l.002.003 2.07 4.197.116.235.26.038 4.618.674.015.002.015.001c.06.006.117.028.165.065.34-.437.34-.437 0 0a.313.313 0 0 1 .096.127.312.312 0 0 1-.104.3l-.012.011-.012.012-3.352 3.247-.191.184.046.261.789 4.602-.016-.068-.003-.025c.011.059.048.227.026.282l.235.102-.235-.102a.312.312 0 0 1-.108.138l-.002.002a.312.312 0 0 1-.184.059h-.003a.255.255 0 0 1-.126-.033l-.007-.004-.006-.003-4.14-2.175-.232-.122-.232.12-3.672 1.916-.483.252-.003.002a.312.312 0 0 1-.328-.022Z" stroke="#f58020"/></svg>
        }
      </div>
    }
  </div>
}
@if (!current()) {
  <div class="stars">
    @for (number of [0]; track number) {
      <div class="star">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m17.337 8.006.013.002.013.001a.363.363 0 0 1 .307.228.362.362 0 0 1-.12.355l-.012.01-.01.01-3.353 3.246-.17.166.04.234.812 4.61.001.007a.362.362 0 0 1-.02.203l.417.166-.416-.166a.362.362 0 0 1-.126.16l-.002.001a.363.363 0 0 1-.214.07h-.003a.307.307 0 0 1-.152-.04l-.011-.007-4.14-2.175-.208-.11-.208.11-4.156 2.167-.002.001a.363.363 0 0 1-.526-.388v-.007l.812-4.61.042-.234-.171-.166-3.35-3.244a.363.363 0 0 1-.094-.167l-.435.11.435-.11a.363.363 0 0 1 .004-.19.363.363 0 0 1 .293-.243h.002l4.619-.682.232-.034.104-.21 2.07-4.189.002-.003a.363.363 0 0 1 .653 0l.002.003 2.07 4.196.104.211.233.034 4.619.674Z" stroke="#B0BFC6" stroke-width=".897" /></svg>
      </div>
    }
  </div>
}
