/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, input, Input } from '@angular/core';
import { ContentBlockList, ContentBlockListShared } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-list',
    templateUrl: './content-block-list.component.html',
    styleUrls: ['./content-block-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockListComponent {
    public payload = input<ContentBlockList>();
    public isArticle = input(false);

    get listNgClass(): any {
        return [`style-${this.payload().style}`, this.isArticle() ? 'is-article' : ''];
    }

    get cNgClass(): any {
        return [this.isMarketplaces ? 'c' : 'c padding'];
    }

    get isMarketplaces(): boolean {
        return [
            ContentBlockListShared.Style.Marketplaces,
        ].includes(this.payload().style);
    }

    get isUL(): boolean {
        return [
            ContentBlockListShared.Style.Dots,
            ContentBlockListShared.Style.Marks,
        ].includes(this.payload().style);
    }

    get isOL(): boolean {
        return [
            ContentBlockListShared.Style.Digits,
        ].includes(this.payload().style);
    }
}
