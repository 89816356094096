import { ComponentRef, ElementRef, Inject, Injectable, InjectionToken, input, InputSignal, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject, Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { ProductFiltersPreviewComponent, ProductFiltersPreviewComponentModalResponse } from '../../product-filters-preview/product-filters-preview.component';

const OFFSET = 20;
const EXPECTED_POPUP_HEIGHT = 165;

interface PopupRequest {
    count: number;
    vcr: ViewContainerRef;
    flexibleConnectedTo: ElementRef<HTMLElement>;
    onReset?: () => void;
    onSubmit?: () => void;
}

@Injectable()
export class ProductFiltersPreviewService {
    private _overlayRef: OverlayRef;
    private _componentRef: ComponentRef<ProductFiltersPreviewComponent>;
    private _count$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private _destroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly overlay: Overlay,
        @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
        private readonly router: Router,
    ) {
    }

    popup(request: PopupRequest): void {
        if (! this.hasPopup) {
            this.createPopup(request);
        }

        this._count$.next(request.count);
    }

    get hasPopup(): boolean {
        return !! this._componentRef;
    }

    private createPopup(request: PopupRequest): void {
        this._count$ = new BehaviorSubject<number>(0);

        const offsetX = (() => {
            if (isPlatformBrowser(this.platformId)) {
                const elemOffsetLeft = request.flexibleConnectedTo.nativeElement.offsetLeft;
                const elemBRWidth = request.flexibleConnectedTo.nativeElement.getBoundingClientRect().width;

                return elemOffsetLeft + elemBRWidth + OFFSET;
            } else {
                return 0;
            }
        })();

        const offsetY = (() => {
            if (isPlatformBrowser(this.platformId)) {
                const elemOffsetTop = request.flexibleConnectedTo.nativeElement.offsetTop;
                const windowHeight = window && window.innerHeight;

                return ((windowHeight - elemOffsetTop) / 2) + (EXPECTED_POPUP_HEIGHT / 2);
            } else {
                return 0;
            }
        })();

        const destroy$ = new Subject<void>();

        const overlayRef = this.overlay.create({
            hasBackdrop: false,
            disposeOnNavigation: true,
            positionStrategy: this.overlay.position()
                .global()
                .top(`${offsetY}px`)
                .left(`${offsetX}px`),
        });

        const componentPortal = new ComponentPortal(ProductFiltersPreviewComponent);
        const componentRef = overlayRef.attach(componentPortal);

        componentRef.instance.modalRequest = input({
            count$: this._count$.asObservable(),
        }) as InputSignal<any>;

        componentRef.onDestroy(() => {
            this.onDestroyPopup();
        });

        componentRef.instance.closeEvent.pipe(
            takeUntil(this._destroy$),
        ).subscribe((modalResponse: ProductFiltersPreviewComponentModalResponse) => {
            destroy$.next();

            this.destroyPopup();

            if (modalResponse.doReset && request.onReset) {
                request.onReset();
            }

            if (modalResponse.doSubmit && request.onSubmit) {
                request.onSubmit();
            }
        });

        this.router.events.pipe(
            filter((e) => e instanceof NavigationStart),
            take(1),
            takeUntil(destroy$),
        ).subscribe(() => overlayRef.dispose());

        this._componentRef = componentRef;
        this._overlayRef = overlayRef;
    }

    destroyPopup(): void {
        if (this.hasPopup) {
            this._overlayRef.dispose();
        }
    }

    private onDestroyPopup(): void {
        this._componentRef = undefined;
        this._overlayRef = undefined;
        this._destroy$.next();
    }
}
