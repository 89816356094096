
<div class="c">
  <div class="c-icon">
    <div class="icon icon-80x80px">
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M57.9563 46.9094C57.664 46.6164 57.266 46.4516 56.8516 46.4516C56.8357 46.4547 56.2351 46.4211 55.7468 46.9094L54.6427 48.0135L51.0526 44.424C58.975 33.3187 57.7702 17.9995 48.0137 8.24361C37.0237 -2.74638 19.2344 -2.74821 8.24318 8.24361C-2.74681 19.233 -2.74864 37.0229 8.24318 48.0141C17.9752 57.7468 33.2883 58.9956 44.423 51.0531L48.0131 54.6431L46.909 55.7473C46.2992 56.357 46.2986 57.347 46.909 57.9574L66.6624 77.7089C69.7147 80.7631 74.6555 80.765 77.7085 77.7096C80.7627 74.6578 80.7639 69.7164 77.7085 66.6634L57.9563 46.9094ZM50.2226 52.4337L46.8772 49.0883C47.266 48.7398 47.6463 48.3822 48.0137 48.0141C48.3817 47.6467 48.7394 47.2664 49.0879 46.8783L52.4332 50.2236L50.2226 52.4337ZM10.4527 45.8046C0.683365 36.036 0.682144 20.223 10.4527 10.4531C20.2213 0.683792 36.0343 0.682571 45.8042 10.4531C55.5515 20.1998 55.601 36.0091 45.8042 45.8046C35.897 55.7131 20.0883 55.4409 10.4527 45.8046ZM56.8516 50.2236L61.2699 54.6425L54.6421 61.2703L50.2232 56.852L56.8516 50.2236ZM75.499 75.5001C73.668 77.333 70.7047 77.3336 68.8725 75.5001L56.8516 63.4798L63.4794 56.852L75.4997 68.8735C77.3319 70.7039 77.3338 73.6672 75.499 75.5001Z" fill="white"></path>
          <path d="M43.596 12.663C35.0517 4.11865 21.2095 4.11377 12.6616 12.6617C4.13495 21.1902 4.13495 35.0671 12.6616 43.5962C21.2108 52.1448 35.045 52.1454 43.5948 43.5962C52.1232 35.0671 52.1238 21.1908 43.596 12.663ZM41.3853 41.3861C34.058 48.7134 22.2001 48.7152 14.871 41.3867C7.56268 34.0759 7.56268 22.1814 14.871 14.8706C22.1946 7.54761 34.0605 7.54578 41.3865 14.8724C48.6961 22.182 48.6955 34.0759 41.3853 41.3861Z" fill="white"></path>
          <path d="M27.0664 30.7617C27.0664 29.668 27.1992 28.7969 27.4648 28.1484C27.7305 27.5 28.2148 26.8633 28.918 26.2383C29.6289 25.6055 30.1016 25.0938 30.3359 24.7031C30.5703 24.3047 30.6875 23.8867 30.6875 23.4492C30.6875 22.1289 30.0781 21.4688 28.8594 21.4688C28.2812 21.4688 27.8164 21.6484 27.4648 22.0078C27.1211 22.3594 26.9414 22.8477 26.9258 23.4727H23.5273C23.543 21.9805 24.0234 20.8125 24.9688 19.9688C25.9219 19.125 27.2188 18.7031 28.8594 18.7031C30.5156 18.7031 31.8008 19.1055 32.7148 19.9102C33.6289 20.707 34.0859 21.8359 34.0859 23.2969C34.0859 23.9609 33.9375 24.5898 33.6406 25.1836C33.3438 25.7695 32.8242 26.4219 32.082 27.1406L31.1328 28.043C30.5391 28.6133 30.1992 29.2812 30.1133 30.0469L30.0664 30.7617H27.0664ZM26.7266 34.3594C26.7266 33.8359 26.9023 33.4062 27.2539 33.0703C27.6133 32.7266 28.0703 32.5547 28.625 32.5547C29.1797 32.5547 29.6328 32.7266 29.9844 33.0703C30.3438 33.4062 30.5234 33.8359 30.5234 34.3594C30.5234 34.875 30.3477 35.3008 29.9961 35.6367C29.6523 35.9727 29.1953 36.1406 28.625 36.1406C28.0547 36.1406 27.5938 35.9727 27.2422 35.6367C26.8984 35.3008 26.7266 34.875 26.7266 34.3594Z" fill="white"></path>
        </g>
        <defs>
          <clippath id="clip0">
            <rect width="80" height="80" fill="white"></rect>
          </clippath>
        </defs>
      </svg>
    </div>
  </div>
  <div class="c-contents">
    <div class="c-contents-box">
      <div class="c-cb-title"><span>{{ t('title') | translate }}</span></div>
      <div class="c-cb-button">
        <button matRipple="matRipple" (click)="contactUs()"><span>{{ t('button') | translate }}</span></button>
      </div>
    </div>
  </div>
</div>