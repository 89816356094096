/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-output-native */
import { ChangeDetectionStrategy, Component, computed, ElementRef, EventEmitter, Inject, input, OnChanges, OnDestroy, OnInit, Output, PLATFORM_ID, signal, SimpleChanges, viewChild, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'underscore';
import { CommonContentWebBundleShared, DictionaryShared, FilterScreen, FiltersType, ManufacturerListDto, naturalCompare, ParamsReferenceDto, ParamsShared, ProductCategoryListDto, ProductCategoryTemplate, ProductFiltersNavigateToParamsRequest, ProductSearchFiltersRequest, ProductSearchRequest, Source } from '@interid/interid-site-shared';
import { takeUntil } from 'rxjs/operators';
import ParamsType = ParamsShared.ParamsType;
import { UrlBuilderService } from '@interid/interid-site-web/core';
import { isPlatformBrowser } from '@angular/common';
import { ProductFiltersParamsReferenceService } from '../../services/product-filters-params-reference.service';
import { ProductFiltersPreviewService } from '../../services/product-filters-preview.service';
import { SliderOptions } from '../product-filters-slider/product-filters-slider';

const TWO_COLUMN_STRING_LENGTH = 10;

export interface ProductsFiltersSetPayloadRequest {
    payload: Payload;
}

export interface ProductFiltersSetPriceRangeRequest {
    priceRangeMin: string;
    priceRangeMax: string;
}

export interface ProductFiltersSetManufacturersRequest {
    manufacturerIds: Array<number>;
}

export interface ProductFiltersSetProductSeriesRequest {
    productSeriesIds: Array<number>;
}

export interface ProductFiltersSetParamsRequest {
    requests: Array<ProductFiltersSetParamsRequestEntry>;
    resetParams?: Array<number>;
}

export interface ProductFiltersSetParamsRequestEntry {
    paramId: number;
    paramStaticValue: Array<string> | string;
}

export interface ProductFiltersResetSpecificParamsRequest {
    paramIds: Array<number>;
}

interface CatalogUsedPriceRangeDTO {
    priceRangeMin: string;
    priceRangeMax: string;
}

interface CatalogUsedParamsDTO {
    id: number;
    sort: number;
    type: ParamsShared.ParamsType;
    title: string;
    dictType: DictionaryShared.Type;
}

interface CatalogUsedDictValuesDTO {
    id: number;
    sort: number;
    paramId: number;
    title: string;
    dictColorHex: string;
}

interface CatalogUsedProductSeriesDto {
    id: number;
    title: string;
    manufacturer: string;
}

interface DictParam {
    id: number;
    paramId: number;
    paramTitle: string;
    isBlockEnabled: boolean;
    form: UntypedFormGroup;
    dictValues: Array<CatalogUsedDictValuesDTO>;
    dictType: DictionaryShared.Type;
    valuesHidden: boolean;
}

interface ProductSeries {
    title: string;
    form: UntypedFormGroup;
    valuesHidden: boolean;
}

interface Payload {
    hasAnyFilters: boolean;
    esSearchFilterQuery: ProductSearchFiltersRequest;
    priceRangeMin: string;
    priceRangeMax: string;
    template: ProductCategoryTemplate;
    manufacturerIds: Array<number>;
    productSeriesIds: Array<number>;
    booleanParamIds: Array<number>;
    dictValues: Array<{
        paramId: number;
        paramStaticValue: Array<string> | string;
    }>;
}

type WithCountRequest = (payload: Payload) => Observable<{
    count: number;
}>;

type ManufacturerUrlGenerator = (request: { manufacturerId: number }) => string;

interface State {
    formPriceRange: UntypedFormGroup;
    formManufacturers: UntypedFormGroup;
    formBooleanParams: UntypedFormGroup;
    formsProductSeries: Array<ProductSeries>;
    formsDictParams: Array<DictParam>;
    isManufacturersBlockEnabled: boolean;
    isBooleanBlockEnabled: boolean;
    isProductSeriesBlockEnabled: boolean;
    isPriceBlockEnabled: boolean;
}

export { Payload as ProductFiltersComponentPayload };
export { WithCountRequest as ProductFiltersComponentWithCountRequest };
export { ManufacturerUrlGenerator as ProductFiltersComponentManufacturerUrlGenerator };

@Component({
    selector: 'app-shared-product-filters-mobile-lvl1',
    templateUrl: './product-filters-mobile-lvl1.component.html',
    styleUrls: ['./product-filters-mobile-lvl1.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ProductFiltersParamsReferenceService, ProductFiltersPreviewService],
})
export class ProductFiltersMobileLvl1Component implements OnChanges, OnDestroy, OnInit {
    private ngOnDestroy$: Subject<void> = new Subject<void>();
    @Output('change') changeEvent: EventEmitter<Payload> = new EventEmitter<Payload>();

    @Output('setPriceRange') setPriceRangeEvent: EventEmitter<ProductFiltersSetPriceRangeRequest> = new EventEmitter<ProductFiltersSetPriceRangeRequest>();

    @Output('setManufacturers') setManufacturersEvent: EventEmitter<ProductFiltersSetManufacturersRequest> = new EventEmitter<ProductFiltersSetManufacturersRequest>();
    @Output('setProductSeries') setProductSeriesEvent: EventEmitter<ProductFiltersSetProductSeriesRequest> = new EventEmitter<ProductFiltersSetProductSeriesRequest>();
    @Output('setParams') setParamsEvent: EventEmitter<ProductFiltersSetParamsRequest> = new EventEmitter<ProductFiltersSetParamsRequest>();
    @Output('navigateToParams') navigateToParamsEvent: EventEmitter<ProductFiltersNavigateToParamsRequest> = new EventEmitter<ProductFiltersNavigateToParamsRequest>();

    @Output('resetPriceRange') resetPriceRangeEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('resetManufacturers') resetManufacturersEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('resetSpecificManufacturer') resetSpecificManufacturerEvent: EventEmitter<ProductFiltersSetManufacturersRequest> = new EventEmitter<ProductFiltersSetManufacturersRequest>();
    @Output('resetSpecificProductSeries') resetSpecificProductSeriesEvent: EventEmitter<ProductFiltersSetProductSeriesRequest> = new EventEmitter<ProductFiltersSetProductSeriesRequest>();

    @Output('resetProductSeries') resetProductSeriesEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('resetSpecificParams') resetSpecificParamsEvent: EventEmitter<ProductFiltersResetSpecificParamsRequest> = new EventEmitter<ProductFiltersResetSpecificParamsRequest>();

    @Output('submit') submitEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('updateSearchRequest') updateSearchRequestEvent: EventEmitter<ProductSearchRequest> = new EventEmitter<ProductSearchRequest>();

    private ngOnChanges$: Subject<void> = new Subject<void>();
    private nextChange$: Subject<void> = new Subject<void>();

    public containerRef = viewChild<ElementRef<HTMLDivElement>>('container');
    public inputPriceRangeMin = viewChild<ElementRef<HTMLInputElement>>('priceRangeMin');
    public inputPriceRangeMax = viewChild<ElementRef<HTMLInputElement>>('priceRangeMax');

    public searchRequest = input<ProductSearchRequest>();
    public category = input<ProductCategoryListDto>();
    public usedPriceRange = input<CatalogUsedPriceRangeDTO>({ priceRangeMin: '0', priceRangeMax: '0' });
    public usedManufacturers = input<Array<ManufacturerListDto>>([]);
    public usedParams = input<Array<CatalogUsedParamsDTO>>([]);
    public usedDictValues = input<Array<CatalogUsedDictValuesDTO>>([]);
    public usedProductSeries = input<Array<CatalogUsedProductSeriesDto>>([]);
    public paramsReferences = input<Array<ParamsReferenceDto>>([]);
    public contentBundle = input<CommonContentWebBundleShared.Bundle>();

    public withPriceRange = input(false);
    public withManufacturers = input(true);
    public withParams = input(false);
    public withProductSeries = input(false);

    public withCountPreview = input(false);
    public withCountRequest = input<WithCountRequest>();

    public filtersManufacturers = input<Array<any>>();
    public filtersSeries = input<Array<any>>();
    public filtersParams = input<Array<any>>();

    public manufacturerUrlGenerator = input<ManufacturerUrlGenerator>((request) =>
        this.urlBuilder.urlLink({
            type: Source.Catalog,
            payload: {
                productCategoryId: this.categoryId,
                esQuery: {
                    filters: {
                        manufacturerIds: [request.manufacturerId],
                    },
                },
            },
        }
    ));

    public isBrowser = signal(false);

    public sliderValue = 0;
    public sliderValueHigh = 0;

    public sliderOptions = signal<SliderOptions>({
        min: 0,
        max: 0,
    });

    public state = signal<State>({
        formPriceRange: this.fb.group({}),
        formManufacturers: this.fb.group({}),
        formBooleanParams: this.fb.group({}),
        formsProductSeries: [],
        formsDictParams: [],
        isManufacturersBlockEnabled: true,
        isBooleanBlockEnabled: true,
        isPriceBlockEnabled: true,
        isProductSeriesBlockEnabled: true,
    });

    public hasManufacturers = computed<boolean>(() => {
        return Object.keys(this.state().formManufacturers.controls).length > 0;
    });

    public hasProductSeries = computed<boolean>(() => {
        return this.state().formsProductSeries.length > 0;
    });

    public hasDictParams = computed<boolean>(() => {
        return this.state().formsDictParams.length > 0;
    });

    get categoryId(): number {
        return this.category().id;
    }

    get booleanParams(): Array<CatalogUsedParamsDTO> {
        const params = this.usedParams().filter((p) => p.type === ParamsShared.ParamsType.Boolean);

        params.sort((a, b) => a.sort - b.sort);

        return params;
    }

    get productSeries(): Array<CatalogUsedProductSeriesDto> {
        return this.usedProductSeries();
    }

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly vcr: ViewContainerRef,
        private readonly urlBuilder: UrlBuilderService,
        public paramsReferencesHelper: ProductFiltersParamsReferenceService,
        private readonly filtersPreview: ProductFiltersPreviewService,
        @Inject(PLATFORM_ID) private platformId
    ) {
        this.isBrowser.set(isPlatformBrowser(platformId));
    }

    ngOnInit(): void {
        if (this.usedPriceRange()) {
            // this.options().floor = Number.parseInt(this.usedPriceRange().priceRangeMin);
            // this.options().ceil = Number.parseInt(this.usedPriceRange().priceRangeMax);

            // this.sliderValue = this.options().floor;
            // this.sliderValueHigh = this.options().ceil;

            this.sliderOptions().min = Number.parseInt(this.usedPriceRange().priceRangeMin);
            this.sliderOptions().max = Number.parseInt(this.usedPriceRange().priceRangeMax);

            this.sliderValue = this.sliderOptions().min;
            this.sliderValueHigh = this.sliderOptions().max;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnChanges$.next();

        this.paramsReferencesHelper.init({
            vcr: this.vcr,
            paramsReferences: this.paramsReferences(),
            contentBundle: this.contentBundle(),
            ngOnDestroy$: this.ngOnDestroy$,
        });

        if (changes['searchRequest']) {
            this.updateManufacturerFormControlsWithESSearchQuery(this.searchRequest());
        }

        if (changes['usedPriceRange']) {
            const formGroupControls: any = { priceRangeMin: 0, priceRangeMax: 0 };

            this.state.update((prev) => ({
                ...prev,
                formPriceRange: this.fb.group(formGroupControls),
            }));

            this.updatePriceRangeFormControlsWithESSearchQuery(this.searchRequest());

            this.state.update((prev) => ({
                ...prev,
                priceRangeMin: this.searchRequest()?.filters?.priceRangeMin ?? this.usedPriceRange().priceRangeMin,
                priceRangeMax: this.searchRequest()?.filters?.priceRangeMax ?? this.usedPriceRange().priceRangeMax,
            }));
        }

        if (changes['usedManufacturers']) {
            const formGroupControls: any = {};

            for (const m of this.usedManufacturers()) {
                formGroupControls[m.id.toString()] = [false];
            }

            this.state.update((prev) => ({
                ...prev,
                formManufacturers: this.fb.group(formGroupControls),
            }));

            this.updateManufacturerFormControlsWithESSearchQuery(this.searchRequest());
        }

        if (changes['usedParams']) {
            const formGroupControls: any = {};

            for (const p of this.usedParams()) {
                if (p.type === ParamsType.Boolean) {
                    formGroupControls[p.id.toString()] = [false];
                }
            }

            this.state.update((prev) => ({
                ...prev,
                formBooleanParams: this.fb.group(formGroupControls),
            }));

            this.updateBooleanFormControlsWithESSearchQuery(this.searchRequest());
        }

        if (changes['usedProductSeries']) {
            const formGroups: Array<ProductSeries> = [];

            const manufacturers = _.uniq(this.usedProductSeries().map((pS) => pS.manufacturer));

            manufacturers.sort((a, b) => naturalCompare(a, b));

            for (const m of manufacturers) {
                const productSeriesFormControls: any = {};

                for (const ps of this.usedProductSeries().filter((psm) => psm.manufacturer === m)) {
                    productSeriesFormControls[ps.id.toString()] = [false];
                }

                formGroups.push({
                    title: m,
                    form: this.fb.group(productSeriesFormControls),
                    valuesHidden: true,
                });
            }

            this.state.update((prev) => ({
                ...prev,
                formsProductSeries: formGroups,
            }));

            this.updateProductSeriesFormControlsWithESSearchQuery(this.searchRequest());
        }

        if (changes['usedParams'] || changes['usedDictValues']) {
            const formGroups: Array<DictParam> = [];

            for (const p of this.usedParams()) {
                const dictValues = this.usedDictValues().filter((dv) => dv.paramId === p.id);

                dictValues.sort((a, b) => a.sort - b.sort);

                if (dictValues.length > 0) {
                    const formGroupControls: any = {};

                    for (const dv of dictValues) {
                        formGroupControls[dv.id] = [false];
                    }

                    formGroups.push({
                        id: p.id,
                        paramId: p.id,
                        paramTitle: p.title,
                        isBlockEnabled: true,
                        form: this.fb.group(formGroupControls),
                        dictValues,
                        dictType: p.dictType,
                        valuesHidden: true,
                    });
                }
            }

            this.state.update((prev) => ({
                ...prev,
                formsDictParams: formGroups,
            }));

            this.updateDictFormControlsWithESSearchQuery(this.searchRequest());
        }
    }

    ngOnDestroy(): void {
        this.ngOnChanges$.next();
        this.ngOnDestroy$.next();
    }

    focusInput(input: ElementRef<HTMLInputElement>): void {
        if (input && input.nativeElement) {
            input.nativeElement.focus();
        }
    }

    colorHasNoHex(dict: DictParam): boolean {
        if (dict.dictType != DictionaryShared.Type.Color) return true;

        return dict.dictValues.some((x) => x.dictColorHex);
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    resetManufacturer(id: number) {
        if (id) {
            this.resetSpecificManufacturerEvent.emit({manufacturerIds: [id]});
        }
    }

    resetSeries(id: number) {
        if (id) {
            this.resetSpecificProductSeriesEvent.emit({productSeriesIds: [id]});
        }
    }

    resetParam(id: number){
        if (id) {
            this.resetSpecificParamsEvent.emit({paramIds: [id]});
        }
    }

    resetParamsValue(paramsId: number, paramsValueId: number) {
        if (paramsValueId && paramsId) { 
            this.setParamsEvent.emit({
                requests: this.filtersParams().filter(x=> x.paramId == paramsId && x.id !== paramsValueId).map(x=> ({paramStaticValue: x.id.toString(), paramId: x.paramId})), //[{paramId: paramsId, paramStaticValue: paramsValueId.toString()}],
                resetParams: [paramsId],
            });
        }
    }

    sliderChange(event) {
        this.state().formPriceRange.patchValue({
            priceRangeMin: event.valueMin ?? this.usedPriceRange().priceRangeMin,
            priceRangeMax: event.valueMax ?? this.usedPriceRange().priceRangeMax,
        });

        const payload = this.payload();

        const applyPriceRange = () => {
            payload.priceRangeMin || payload.priceRangeMax
                ? this.setPriceRangeEvent.emit({
                      priceRangeMax: payload.priceRangeMax,
                      priceRangeMin: payload.priceRangeMin,
                  })
                : this.resetPriceRangeEvent.emit();
        };

        applyPriceRange();
    }

    t(input: string): string {
        return `product.shared.components.product_filters.${input}`;
    }

    onChange(): void {
        setTimeout(() => {
            this.nextChange$.next();

            if (this.withCountPreview() && this.withCountRequest()) {
                const payload = this.payload();

                if (payload.hasAnyFilters) {
                    this.withCountRequest()(this.payload())
                        .pipe(takeUntil(this.nextChange$))
                        .subscribe((response) => {
                            this.filtersPreview.popup({
                                count: response.count,
                                vcr: this.vcr,
                                flexibleConnectedTo: this.containerRef(),
                                onReset: () => {
                                    this.reset();
                                },
                                onSubmit: () => {
                                    this.ngSubmit();
                                },
                            });
                        });
                } else {
                    this.filtersPreview.destroyPopup();
                }
            }

            this.changeEvent.emit(this.payload());
        });
    }

    productSeriesOfManufacturer(manufacturer: string): Array<CatalogUsedProductSeriesDto> {
        return this.productSeries.filter((ps) => ps.manufacturer === manufacturer);
    }

    dictionaryRouterLink(): any {
        return this.urlBuilder.urlLink({
            type: Source.Dictionary,
            payload: {
                category: this.category().slug,
            },
        });
    }

    toggleManufacturer(manufacturerId: number): void {
        const control = this.state().formManufacturers.get(manufacturerId.toString());

        control.setValue(!control.value);
    }

    toggleBoolean(paramId: number): void {
        const control = this.state().formBooleanParams.get(paramId.toString());

        control.setValue(!control.value);
    }

    toggleProductSeries(productSeriesId: number): void {
        for (const productSeries of this.state().formsProductSeries) {
            if (productSeries.form.contains(productSeriesId.toString())) {
                const control = productSeries.form.get(productSeriesId.toString());

                control.setValue(!control.value);
            }
        }
    }

    toggleDictValue(paramId: number, dictValueId: number): void {
        const dictParam = this.state().formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return;
        }

        if (dictParam.form.contains(dictValueId.toString())) {
            const control = dictParam.form.get(dictValueId.toString());

            control.setValue(!control.value);
        }
    }

    updateManufacturerFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && Array.isArray(esSearchRequest.filters.manufacturerIds)) {
            this.state().formManufacturers.reset();

            for (const manufacturerId of esSearchRequest.filters.manufacturerIds) {
                if (this.state().formManufacturers.contains(manufacturerId.toString())) {
                    this.state().formManufacturers.get(manufacturerId.toString()).setValue(true);
                }
            }
        } else {
            this.state().formManufacturers.reset();
        }
    }

    updatePriceRangeFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && !!(esSearchRequest.filters?.priceRangeMin || esSearchRequest.filters?.priceRangeMax)) {
            this.state().formPriceRange.reset();

            if (!esSearchRequest.filters.priceRangeMin) this.state().formPriceRange.get('priceRangeMin').setValue(esSearchRequest.filters.priceRangeMin);
            if (!esSearchRequest.filters.priceRangeMax) this.state().formPriceRange.get('priceRangeMax').setValue(esSearchRequest.filters.priceRangeMax);
        } else {
            this.state().formPriceRange.reset();
        }
    }

    updateBooleanFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && Array.isArray(esSearchRequest.filters.productParams)) {
            this.state().formBooleanParams.reset();

            for (const param of esSearchRequest.filters.productParams) {
                if (this.state().formBooleanParams.contains(param.paramsId.toString())) {
                    this.state().formBooleanParams.get(param.paramsId.toString()).setValue(true);
                }
            }
        } else {
            this.state().formBooleanParams.reset();
        }
    }

    updateProductSeriesFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && Array.isArray(esSearchRequest.filters.productSeriesIds)) {
            const controlsMap: { [productSeriesId: number]: UntypedFormControl } = {};

            for (const productSeries of this.state().formsProductSeries) {
                productSeries.form.reset();

                for (const productSeriesId of Object.keys(productSeries.form.controls)) {
                    controlsMap[productSeriesId] = productSeries.form.controls[productSeriesId];
                }
            }

            for (const productSeriesId of esSearchRequest.filters.productSeriesIds) {
                if (controlsMap[productSeriesId]) {
                    controlsMap[productSeriesId].setValue(true);
                }
            }
        } else {
            for (const productSeries of this.state().formsProductSeries) {
                productSeries.form.reset();
            }
        }
    }

    updateDictFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && Array.isArray(esSearchRequest.filters.productParams)) {
            for (const productParams of esSearchRequest.filters.productParams) {
                const dictParam = this.state().formsDictParams.find((dp) => dp.paramId === productParams.paramsId);

                if (dictParam) {
                    const dictValue = dictParam.dictValues.find((dv) => dv.id.toString() === productParams.paramsStaticValue);

                    if (dictValue && dictParam.form.contains(dictValue.id.toString())) {
                        dictParam.form.get(dictValue.id.toString()).setValue(true);
                    }
                }
            }
        } else {
            for (const dictParamForm of this.state().formsDictParams) {
                dictParamForm.form.reset();
            }
        }
    }

    withFiltersParamsReset(paramsId: number){
        return this.filtersParams()?.length && this.filtersParams().length > 0 && this.filtersParams().some(x=>x.paramId == paramsId);
    }

    togglePriceRangeBlock(): void {
        this.state.update((prev) => ({
            ...prev,
            isPriceBlockEnabled: !this.state().isPriceBlockEnabled,
        }));
    }
 
    toggleManufacturersBlock(): void {
        this.navigateToParamsEvent.emit({
            filtersType: FiltersType.Manufactures,
            filterScreen: FilterScreen.MobileLvl2,
            filterName: 'Производители',
        });
    }

    toggleBooleanBlock(): void {
        this.navigateToParamsEvent.emit({
            filtersType: FiltersType.BooleanParams,
            filterScreen: FilterScreen.MobileLvl2,
            filterName: 'Другие параметры',
        });
    }

    toggleProductSeriesBlock(): void {
        this.navigateToParamsEvent.emit({
            filtersType: FiltersType.ProductSeries,
            filterScreen: FilterScreen.MobileLvl2,
            filterName: 'Серия',
        });
    }

    toggleDictBlock(paramId: number): void {
        const dictParam = this.state().formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return;
        }

        this.navigateToParamsEvent.emit({
            filtersType: FiltersType.Dicts,
            paramId: paramId,
            filterName: dictParam.paramTitle,
            filterScreen: FilterScreen.MobileLvl2,
        });
    }

    hasEnabledDictParams(paramId: number): boolean {
        const dictParam = this.state().formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return false;
        }

        const dictParamFormValue = dictParam.form.value;

        return (
            Object.keys(dictParamFormValue)
                .map((mId) => ({
                    id: parseInt(mId, 10),
                    enabled: dictParamFormValue[mId],
                }))
                .filter((value) => !!value.enabled).length > 0
        );
    }

    shouldBeTwoColumnsDictBlock(paramId: number): boolean {
        const dictParam = this.state().formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return false;
        }

        return dictParam.dictValues.every((dv) => dv.title.length <= TWO_COLUMN_STRING_LENGTH);
    }

    routerLinkManufacturer(manufacturerId: number): any {
        return this.manufacturerUrlGenerator()({
            manufacturerId,
        });
    }

    routerLinkProductSeries(productSeriesId: number): any {
        return this.urlBuilder.urlLink({
            type: Source.Catalog,
            payload: {
                productCategoryId: this.categoryId,
                esQuery: {
                    filters: {
                        productSeriesIds: [productSeriesId],
                    },
                },
            },
        });
    }

    payload(): Payload {
        const payload: Payload = {
            hasAnyFilters: false,
            template: this.category().template,
            priceRangeMin: '0',
            priceRangeMax: '0',
            esSearchFilterQuery: {},
            booleanParamIds: [],
            dictValues: [],
            manufacturerIds: [],
            productSeriesIds: [],
        };

        const applyPriceRange = () => {
            const formValue = this.state().formPriceRange.value;

            if (formValue.priceRangeMin || formValue.priceRangeMax) {
                payload.priceRangeMin = formValue.priceRangeMin;
                payload.priceRangeMax = formValue.priceRangeMax;

                payload.esSearchFilterQuery.priceRangeMax = payload.priceRangeMax;
                payload.esSearchFilterQuery.priceRangeMin = payload.priceRangeMin;
            }
        };

        const applyManufacturers = () => {
            const manufacturersFormValue = this.state().formManufacturers.value;

            const enabledManufacturers = Object.keys(manufacturersFormValue)
                .map((mId) => ({
                    id: parseInt(mId, 10),
                    enabled: manufacturersFormValue[mId],
                }))
                .filter((value) => !!value.enabled);

            if (enabledManufacturers.length > 0) {
                payload.manufacturerIds = enabledManufacturers.map((value) => value.id);
            }

            if (enabledManufacturers.length > 0) {
                payload.esSearchFilterQuery.manufacturerIds = [...payload.manufacturerIds];
            }
        };

        const applyBooleanParams = () => {
            const booleanFormValue = this.state().formBooleanParams.value;

            const allBooleanParams = Object.keys(booleanFormValue).map((pId) => ({
                id: parseInt(pId, 10),
                enabled: booleanFormValue[pId],
            }));

            payload.booleanParamIds = allBooleanParams.filter((value) => !!value.enabled).map((ebp) => ebp.id);

            if (payload.booleanParamIds.length > 0) {
                payload.esSearchFilterQuery.productParams = payload.esSearchFilterQuery.productParams || [];

                payload.esSearchFilterQuery.productParams.push(
                    ...payload.booleanParamIds.map((bp) => ({
                        paramsId: bp,
                        paramsStaticValue: '1',
                    }))
                );
            }
        };

        const applyProductSeries = () => {
            if (this.state().formsProductSeries.length > 0) {
                const productSeriesIds: Array<number> = [];

                for (const productSeries of this.state().formsProductSeries) {
                    const formValue = productSeries.form.value;

                    for (const productSeriesId of Object.keys(formValue)) {
                        if (formValue[productSeriesId]) {
                            productSeriesIds.push(parseInt(productSeriesId, 10));
                        }
                    }
                }

                payload.productSeriesIds = productSeriesIds;
            } else {
                payload.productSeriesIds = [];
            }

            if (payload.productSeriesIds) {
                payload.esSearchFilterQuery.productSeriesIds = [...payload.productSeriesIds];
            }
        };

        const applyDictParams = () => {
            payload.dictValues = [];

            if (this.state().formsDictParams.length > 0) {
                for (const dictParam of this.state().formsDictParams) {
                    const dictParamFormValue = dictParam.form.value;

                    const allDictValues = Object.keys(dictParamFormValue).map((dvId) => ({
                        dictValueId: parseInt(dvId, 10),
                        enabled: dictParamFormValue[dvId],
                    }));

                    const enabledDictValues = allDictValues.filter((p) => p.enabled);

                    if (enabledDictValues.length > 0) {
                        payload.dictValues = [
                            ...payload.dictValues,
                            {
                                paramId: dictParam.paramId,
                                paramStaticValue: enabledDictValues.map((eP) => eP.dictValueId.toString()),
                            },
                        ];
                    }
                }
            }

            if (payload.dictValues.length > 0) {
                payload.esSearchFilterQuery.productParams = payload.esSearchFilterQuery.productParams || [];

                for (const dv of payload.dictValues) {
                    if (Array.isArray(dv.paramStaticValue)) {
                        for (const paramStaticValue of dv.paramStaticValue) {
                            payload.esSearchFilterQuery.productParams.push({
                                paramsId: dv.paramId,
                                paramsStaticValue: paramStaticValue,
                            });
                        }
                    } else {
                        payload.esSearchFilterQuery.productParams.push({
                            paramsId: dv.paramId,
                            paramsStaticValue: dv.paramStaticValue,
                        });
                    }
                }
            }
        };

        const applyHasAnyFilters = () => {
            payload.hasAnyFilters = !!(payload.priceRangeMin || payload.priceRangeMax);
            payload.dictValues.length > 0 || payload.booleanParamIds.length > 0 || payload.productSeriesIds.length > 0 || payload.manufacturerIds.length > 0;
        };

        applyPriceRange();
        applyManufacturers();
        applyBooleanParams();
        applyProductSeries();
        applyDictParams();
        applyHasAnyFilters();

        return payload;
    }

    ngSubmit(): void {
        const payload = this.payload();

        const applyPriceRange = () => {
            payload.priceRangeMin || payload.priceRangeMax
                ? this.setPriceRangeEvent.emit({
                      priceRangeMax: payload.priceRangeMax,
                      priceRangeMin: payload.priceRangeMin,
                  })
                : this.resetPriceRangeEvent.emit();
        };

        const applyManufacturers = () => {
            payload.manufacturerIds.length
                ? this.setManufacturersEvent.emit({
                      manufacturerIds: payload.manufacturerIds,
                  })
                : this.resetManufacturersEvent.emit();
        };

        const applyBooleanParams = () => {
            this.setParamsEvent.emit({
                requests: payload.booleanParamIds.map((paramId) => ({
                    paramId,
                    paramStaticValue: '1',
                })),
                resetParams: Object.keys(this.state().formBooleanParams.value).map((pId) => parseInt(pId, 10)),
            });
        };

        const applyProductSeries = () => {
            payload.productSeriesIds.length > 0
                ? this.setProductSeriesEvent.emit({
                      productSeriesIds: payload.productSeriesIds,
                  })
                : this.resetProductSeriesEvent.emit();
        };

        const applyDictParams = () => {
            this.setParamsEvent.emit({
                requests: payload.dictValues,
                resetParams: this.state().formsDictParams.map((d) => d.paramId),
            });
        };

        applyPriceRange();
        applyManufacturers();
        applyBooleanParams();
        applyProductSeries();
        applyDictParams();

        this.submitEvent.emit();
    }

    reset(): void {
        this.resetManufacturers();
        this.resetBooleanParams();
        this.resetProductSeries();
        this.resetAllDictParams();

        this.ngSubmit();
    }

    resetPriceRange(): void {
        this.state().formPriceRange.reset();

        this.sliderValue = Number.parseInt(this.usedPriceRange().priceRangeMin);
        this.sliderValueHigh = Number.parseInt(this.usedPriceRange().priceRangeMax);
    }

    resetManufacturers(): void {
        this.state().formManufacturers.reset();
    }

    resetBooleanParams(): void {
        this.state().formBooleanParams.reset();
    }

    resetProductSeries(): void {
        for (const productSeries of this.state().formsProductSeries) {
            productSeries.form.reset();
        }
    }

    resetAllDictParams(): void {
        for (const dp of this.state().formsDictParams) {
            dp.form.reset();
        }
    }

    resetDictParams(paramId: number): void {
        const dictParam = this.state().formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return;
        }

        dictParam.form.reset();
    }

    trackById<T = number | string>(index: number, input: { id: T }): T {
        return input.id;
    }
}
