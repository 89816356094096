import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { SearchArticleReviewsWebRequest, SearchArticleReviewsWebResponse, SearchArticlesWebRequest, SearchArticlesWebResponse, SearchAutocompleteWebRequest, SearchAutocompleteWebResponse, SearchProductReviewsWebRequest, SearchProductReviewsWebResponse, SearchProductWebRequest, SearchProductWebResponse, SearchStatsWebRequest, SearchStatsWebResponse, SearchWebEndpoint, SearchWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebSearchDataAccess implements SearchWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    stats(request: SearchStatsWebRequest): Observable<SearchStatsWebResponse> {
        return this.http.post(SearchWebEndpointPaths.stats, request);
    }

    autocomplete(request: SearchAutocompleteWebRequest): Observable<SearchAutocompleteWebResponse> {
        return this.http.post(SearchWebEndpointPaths.autocomplete, request);
    }

    searchProducts(request: SearchProductWebRequest): Observable<SearchProductWebResponse> {
        return this.http.post(SearchWebEndpointPaths.searchProducts, request);
    }

    searchProductReviews(request: SearchProductReviewsWebRequest): Observable<SearchProductReviewsWebResponse> {
        return this.http.post(SearchWebEndpointPaths.searchProductReviews, request);
    }

    searchArticles(request: SearchArticlesWebRequest): Observable<SearchArticlesWebResponse> {
        return this.http.post(SearchWebEndpointPaths.searchArticles, request);
    }

    searchArticleReviews(request: SearchArticleReviewsWebRequest): Observable<SearchArticleReviewsWebResponse> {
        return this.http.post(SearchWebEndpointPaths.searchArticleReviews, request);
    }
}
