import { IsBoolean, IsEnum, IsInt, IsNotEmpty, IsObject, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductCategorySeoLinkShared } from '../models/shared/product-category-seo-link.shared';
import { SourceDefinition } from '../models/shared/common.shared';
import { ProductCategorySeoLinkDto } from '../models/entities/product-category-seo-link.dto';
import { CrudSetSortOrderRequestDto } from '../core/crud';
import { Observable } from 'rxjs';

export enum ProductCategorySeoLinkEndpointPaths {
    list = '/api/v1/product-category-seo-links/list',
    create = '/api/v1/product-category-seo-links/create',
    edit = '/api/v1/product-category-seo-links/edit',
    delete = '/api/v1/product-category-seo-links/delete',
    setSortOrder = '/api/v1/product-category-seo-links/setSortOrder',
}

export interface ProductCategorySeoLinkEndpoint {
    create(request: CreateProductCategorySeoLinkRequest): Promise<ProductCategorySeoLinkDto> | Observable<ProductCategorySeoLinkDto>;
    edit(request: EditProductCategorySeoLinkRequest): Promise<ProductCategorySeoLinkDto> | Observable<ProductCategorySeoLinkDto>;
    delete(request: DeleteProductCategorySeoLinkRequest): Promise<void> | Observable<void>;
    list(request: ListProductCategorySeoLinksRequest): Promise<ListProductCategorySeoLinksResponse> | Observable<ListProductCategorySeoLinksResponse>;
    setSortOrder(request: SetSortOrderProductCategorySeoLinkRequest): Promise<void> | Observable<void>;
}

export class ProductCategorySeoLinkEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productCategoryId: number;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isDisplayInCatalogMenu: boolean;
 
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    alt: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uri: string;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    source: SourceDefinition;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProductCategorySeoLinkShared.Placement),
    })
    @IsEnum(ProductCategorySeoLinkShared.Placement)
    @IsNotEmpty()
    placement: ProductCategorySeoLinkShared.Placement;
}

export class ListProductCategorySeoLinksRequest {
    filters?: {
        productCategoryId?: number;
    };
}

export type ListProductCategorySeoLinksResponse = Array<ProductCategorySeoLinkDto>;

export class CreateProductCategorySeoLinkRequest extends ProductCategorySeoLinkEntityBody {
}

export class EditProductCategorySeoLinkRequest extends ProductCategorySeoLinkEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteProductCategorySeoLinkRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderProductCategorySeoLinkRequest extends CrudSetSortOrderRequestDto {}
