import { Observable } from 'rxjs';
import { ParamsCategoryListDto } from '../models/entities/params-category.dto';

export enum ParamsCategoryWebEndpointPaths {
    listParamsCategories = '/api/v1/params-category/list',
}

export interface ParamsWebCategoryEndpoint {
    listParamsCategories(): Promise<ParamsWebCategoryListAllResponse> | Observable<ParamsWebCategoryListAllResponse>;
}

export type ParamsWebCategoryListAllResponse = Array<ParamsCategoryListDto>;