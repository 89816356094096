<div class="c">
  @for (productParams of helper.params.cardProductParams; track trackByProductParamsId($index, productParams)) {
    <div class="params">
      @if (viewmode === 'card') {
        <div class="param">
          <div class='param-name param-name-delimeter-colon'><span>{{ productParams.paramsName }}:&nbsp;</span></div>
          <div class='param-name param-name-delimeter-dots'><span>{{ productParams.paramsName }}</span></div>
          <div class='param-delimeter'></div>
          <div class='param-value'>
            <div class='common-value'><span>{{ helper.params.paramDisplayValue(productParams) }}</span></div>
          </div>
        </div>
      }
      @if (viewmode === 'list') {
        <div class="param">
          <div class='param-name'><span>{{ productParams.paramsName }}: {{ helper.params.paramDisplayValue(productParams) }}</span></div>
        </div>
      }
    </div>
  }
</div>
