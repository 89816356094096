import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { defaultOverlayConfig } from '@interid/interid-site-web/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductCardHelper } from '../../helpers/product-card.helper';
import { ProductFavoriteShareComponent } from '../product-favorite-share/product-favorite-share.component';

@Component({
    selector: 'app-shared-product-card-assets-favorite-compare-topbar',
    templateUrl: './product-card-favorite-compare-topbar.component.html',
    styleUrls: ['./product-card-favorite-compare-topbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardFavoriteCompareTopbarComponent implements OnDestroy {

    @ViewChild('attachProductSharePopup') attachProductSharePopupRef: ElementRef<HTMLDivElement>;
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    constructor(public readonly helper: ProductCardHelper, private readonly cdr: ChangeDetectorRef, private readonly overlay: Overlay) {}

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    shareClick() {
        const detach$: Subject<void> = new Subject<void>();

        const overlay = this.overlay.create({
            ...defaultOverlayConfig,
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.attachProductSharePopupRef.nativeElement)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        offsetX: -16,
                        offsetY: 0,
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        offsetX: -16,
                        offsetY: 0,
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                ])
                .withFlexibleDimensions(false)
                .withPush(false),

            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.reposition({
                autoClose: true,
                scrollThrottle: 0,
            }),
        });

        const componentPortal = new ComponentPortal(ProductFavoriteShareComponent);
        const overlayRef = overlay.attach(componentPortal);

        overlayRef.instance.productId = this.helper.product.id;

        overlayRef.instance.closeEvent.pipe(takeUntil(detach$)).subscribe(() => { 
            this.cdr.markForCheck();
            detach$.next();
            overlay.detach();
        });

        overlay
            .backdropClick()
            .pipe(takeUntil(detach$))
            .subscribe(() => {
                detach$.next();
                overlay.detach();
            });
    }
}
