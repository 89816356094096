import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum AnalyticsEndpointPaths {
    reportsCategoryStatistics = '/api/Reports/Category/GoodsStatistics',
    providerSets = '/api/ProvidersSet',
    providers = '/api/Provider',
    goodsCategory = '/api/GoodsCategory/GetByProductNumber',
}

export interface AnalyticsEndpoint {
    reportsCategoryStatistics(request: ReportsCategoryStatisticsRequest): Promise<ReportsCategoryStatisticsResponse> | Observable<ReportsCategoryStatisticsResponse>;
    providerSets(): Promise<ProviderSetsResponse> | Observable<ProviderSetsResponse>;
    providers(): Promise<ProvidersResponse> | Observable<ProvidersResponse>;
    goodsCategory(request: GoodsCategoryRequest): Promise<GoodsCategoryResponse> | Observable<GoodsCategoryResponse>;
}

export class GoodsCategoryRequest {
    @ApiModelProperty()
    id: number;
}

export class ReportsCategoryStatisticsRequest {
    @ApiModelProperty()
    productId: number;
}

export class ProvidersResponseData {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    name: string;

    @ApiModelProperty()
    custom: boolean;

    @ApiModelProperty()
    type: number;

    @ApiModelProperty()
    priceType: number;

    @ApiModelProperty()
    personalAreaUrl: string;
}

export class ProviderSetsResponseData {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    name: string;

    @ApiModelProperty()
    providersIds: Array<number>;

    @ApiModelProperty()
    providerNames: Array<string>;
}

export class ReportsCategoryStatisticsResponseData {
    @ApiModelProperty()
    rrc: string;

    @ApiModelProperty()
    rrcCount: string;

    @ApiModelProperty()
    changedRRC: string;

    @ApiModelProperty()
    finprom: string;

    @ApiModelProperty()
    finpromCount: string;

    @ApiModelProperty()
    changedFinprom: string;

    @ApiModelProperty()
    ocs: string;

    @ApiModelProperty()
    ocsCount: string;

    @ApiModelProperty()
    changedOCS: string;

    @ApiModelProperty()
    treolan: string;

    @ApiModelProperty()
    treolanCount: string;

    @ApiModelProperty()
    changedTreolan: string;

    @ApiModelProperty()
    scancode: string;

    @ApiModelProperty()
    scancodeCount: string;

    @ApiModelProperty()
    changedScancode: string;

    @ApiModelProperty()
    scancity: string;

    @ApiModelProperty()
    scancityCount: string;

    @ApiModelProperty()
    changedScancity: string;

    @ApiModelProperty()
    marvel: string;

    @ApiModelProperty()
    marvelCount: string;

    @ApiModelProperty()
    changedMarvel: string;

    @ApiModelProperty()
    interIdAvailable: string;

    @ApiModelProperty()
    interIdTotalAmount: string;

    @ApiModelProperty()
    changedInterIdAmount: string;
}

export class ReportsCategoryStatisticsResponse {
    @ApiModelProperty()
    data: Array<ReportsCategoryStatisticsResponseData>;
}

export class ProviderSetsResponse {
    @ApiModelProperty()
    data: Array<ProviderSetsResponseData>;
}

export class ProvidersResponse {
    @ApiModelProperty()
    data: Array<ProvidersResponseData>;
}

export class GoodsCategoryResponse {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    name: string;

    @ApiModelProperty()
    providersSetName: string;

    @ApiModelProperty()
    providersSetId: number;

    @ApiModelProperty()
    yandexMetrikaEnabled: boolean;

    @ApiModelProperty()
    extraCharge: number;

    @ApiModelProperty()
    number: number;

    @ApiModelProperty()
    isVisibleInTables: boolean;

    @ApiModelProperty()
    modified: string;
}
