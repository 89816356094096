import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { lastValueFrom, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ApiErrorHandlerService, AppBootstrapDataService, DocumentCookieService, EnvironmentWebService, JwtService } from '@interid/interid-site-web/core';
import { RoistatQuotationRequestContext } from '@interid/interid-site-shared';
import { InteridWebQuotationRequestDataAccess, InteridWebMindboxDataAccess } from '@interid/interid-site-data-access/web';
import { ReCaptchaV3Service } from 'ng-recaptcha';

type Screen = 'form' | 'success';

interface FormValue {
    name: string;
    phone: string;
    message: string;
    email: string;
    inn: string;
}

interface State {
    screen: Screen;
    form: UntypedFormGroup;
}

interface ModalRequest {
    withMessage?: string;
    isOneClick?: boolean;
    context: RoistatQuotationRequestContext;
    productId?: number;
    productPrice?: string;
}

export { ModalRequest as QuotationRequestModalRequest };

@Component({
    templateUrl: './quotation-request-modal.component.html',
    styleUrls: ['./quotation-request-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotationRequestModalComponent implements OnInit, OnDestroy {
    private nextSubmit$: Subject<void> = new Subject<void>();
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state = signal<State>({
        screen: 'form',
        form: this.fb.group({
            name: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.required]],
            inn: [''],
            message: [''],
        }),
    });

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        @Inject(MAT_DIALOG_DATA) private readonly modalRequest: ModalRequest,
        private readonly fb: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly env: EnvironmentWebService,
        private readonly matDialogRef: MatDialogRef<QuotationRequestModalComponent>,
        private readonly endpoint: InteridWebQuotationRequestDataAccess,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly router: Router,
        private readonly appBootstrap: AppBootstrapDataService,
        private readonly mindboxDataAccess: InteridWebMindboxDataAccess,
        private readonly documentCookieService: DocumentCookieService,
        private readonly jwtService: JwtService,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
    ) {
    }

    get formValue(): FormValue {
        return this.state().form.value;
    }

    ngOnInit(): void {
        if (this.appBootstrap.data.account) {
            this.state().form.patchValue({
                name: this.appBootstrap.data.account.fullName,
                phone: this.appBootstrap.data.account.phone,
                email: this.appBootstrap.data.account.email,
            } as Partial<FormValue>);
        }
    }

    ngOnDestroy(): void {
        this.nextSubmit$.next();
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `shared.components.quotation_request_modal.${input}`;
    }

    close(): void {
        this.matDialogRef.close();
    }

    ngSubmit(): void {
        this.nextSubmit$.next();

        this.recaptchaV3Service.execute('QuotationRequest').pipe(
            takeUntil(this.nextSubmit$),
        ).subscribe((recaptchaV3Token) => this.submitForm(recaptchaV3Token));
    }

    submitForm(recaptchaV3Token: string): void {
        if (! this.state().form.valid) {
            return;
        }

        const formValue = this.formValue;

        this.state().form.disable();

        let sessionId;

        try {
            sessionId = window['ct']('calltracking_params', 'y88mwzmo').sessionId;
        } catch (err) {
            console.log(err);
        }

        this.endpoint.send({
            sessionId: sessionId,
            phone: formValue.phone,
            productId: this.modalRequest.productId,
            name: formValue.name,
            context: this.modalRequest.context,
            createdFromURL: `${this.env.current.baseDomainProtocol}://${this.env.current.baseDomain}${this.router.url}`,
            message: formValue.message,
            inn: formValue.inn,
            email: formValue.email
        }, recaptchaV3Token).pipe(
            finalize(() => this.state().form.enable()),
            finalize(() => this.cdr.markForCheck()),
            takeUntil(this.nextSubmit$),
        ).subscribe({
            next: async (response) => {
                if (isPlatformBrowser(this.platformId)) {
                    if (window && window['gtag']) {
                        window['gtag']('event', 'submit', {
                            event_category: 'forms',
                            event_label: (this.modalRequest && this.modalRequest.isOneClick) ? 'oneclick' : 'callback',
                        });
                    }

                    if (window && window['ym'] && this.env.current.ymId) {
                        window['ym'](this.env.current.ymId, 'reachGoal', 'kp');
                    }

                    if (this.jwtService.hasJwt()) {
                        const body = {
                            customer: {
                                fullName: this.formValue.name,
                                subscriptions: [
                                    {
                                        brand: 'lk2185',
                                        pointOfContact: 'Email',
                                    },
                                ],
                            },
                            order: {
                                ids: {
                                    websiteID: `https://cms.interid.ru/quotation-request?id=${response.id}`,
                                },
                                lines: [
                                    {
                                        basePricePerItem: this.modalRequest.productPrice,
                                        quantity: 1,
                                        product: {
                                            ids: {
                                                website: this.modalRequest.productId,
                                            },
                                        },
                                    },
                                ],
                            },
                            executionDateTimeUtc: new Date().toISOString(),
                        };

                        if (this.jwtService.jwtPayload.accountEmail) {
                            body.customer['email'] = this.jwtService.jwtPayload.accountEmail;
                        }

                        if (this.formValue.email) {
                            body.order['email'] = this.formValue.email;
                        }
                        if (this.formValue.phone) {
                            body.order['mobilePhone'] = this.formValue.phone;
                        }

                        await lastValueFrom(this.mindboxDataAccess
                            .request({
                                operation: 'Website.CreateAuthorizedOrder',
                                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                                body: body,
                            }));
                    } else {
                        const body = {
                            customer: {
                                fullName: this.formValue.name,
                                subscriptions: [
                                    {
                                        brand: 'lk2185',
                                        pointOfContact: 'Email',
                                    },
                                ],
                            },
                            order: {
                                ids: {
                                    websiteID: `https://cms.interid.ru/quotation-request?id=${response.id}`,
                                },
                                lines: [
                                    {
                                        basePricePerItem: this.modalRequest.productPrice,
                                        quantity: 1,
                                        product: {
                                            ids: {
                                                website: this.modalRequest.productId,
                                            },
                                        },
                                    },
                                ],
                            },
                            executionDateTimeUtc: new Date().toISOString(),
                        };

                        if (this.formValue.email) {
                            body.customer['email'] = this.formValue.email;
                        }
                        if (this.formValue.phone) {
                            body.customer['mobilePhone'] = this.formValue.phone;
                        }

                        await lastValueFrom(this.mindboxDataAccess
                            .request({
                                operation: 'Website.CreateUnauthorizedOrder',
                                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                                body: body,
                            }));
                    }
                }

                this.state.update((prev) => ({
                    ...prev,
                    screen: 'success',
                }));

                this.state().form.reset();
            },
            error: (error) => {
                this.errorHandler.handle(error);
            },
        });
    }
}
