import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';

export class CityVariants {
    @ApiModelProperty()
    nominative: string;

    @ApiModelProperty()
    genitive: string;

    @ApiModelProperty()
    dative: string;

    @ApiModelProperty()
    accusative: string;

    @ApiModelProperty()
    instrumental: string;

    @ApiModelProperty()
    prepositional: string;

    @ApiModelProperty()
    address: string;
 
    @ApiModelProperty()
    mapCode: string;
  
    @ApiModelProperty()
    phone: string; 
}

export enum CityDeliveryIcon {
    Person = 'Person',
    Location = 'Location',
    Truck = 'Truck',
}

export class CityDeliveryEntry {
    @ApiModelProperty()
    icon: CityDeliveryIcon;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    description: string;

    @ApiModelProperty()
    hideDeliveryPeriod: boolean;
}

export class CityDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    domain: string;

    @ApiModelProperty()
    googleVerificationTag: string;

    @ApiModelProperty()
    yandexVerificationTag: string;

    @ApiModelProperty()
    variants: CityVariants;

    @ApiModelProperty()
    mapRouteAttachment?: AttachmentDto;

    @ApiModelProperty()
    deliveries: Array<CityDeliveryEntry>;

    @ApiModelProperty()
    isDefault: boolean;

    @ApiModelProperty()
    robotstxt: string;
}
