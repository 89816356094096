<div class="c" #container>
  <div class="c-blocks">

    <!-- цена от до -->
    @if (withPriceRange() && usedPriceRange() && usedPriceRange().priceRangeMin !== null && usedPriceRange().priceRangeMax !== null) {
      <div class="c-block price-range">
        <div class="c-header __app-fluid-container-control-padding-bottom">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('price.title') | translate }}</span>
            </div>
          </div>
          <div class="c-header-right">
            <div class="c-header-toggle">
              <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !state().isPriceBlockEnabled }" (click)="togglePriceRangeBlock()">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        @if (state().isPriceBlockEnabled) {
          <div class="c-contents">
            <app-filters-slider
              [(valueMin)]="sliderValue"
              [(valueMax)]="sliderValueHigh"
              [options]="sliderOptions()"
              (sliderChange)="sliderChange($event)"
              [hideSlider]="! isBrowser()"
            />
          </div>
        }
      </div>
    }

    <!-- производители -->
    @if (withManufacturers() && hasManufacturers()) {
      <div class="c-block">
        <div class="c-header __app-fluid-container-control-padding-bottom">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('manufacturers.title') | translate }}</span>
            </div>
          </div>
          <div class="c-header-right">
            <div class="c-header-toggle">
              <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !state().isManufacturersBlockEnabled }" (click)="toggleManufacturersBlock()">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        @if (state().isManufacturersBlockEnabled) {
          <div class="c-contents">
            <div class="flags" [formGroup]="state().formManufacturers">
              @for (manufacturer of manufacturers() | slice: 0:8; track trackById($index, manufacturer)) {
                <a class="flag-link-wrapper" [href]="routerLinkManufacturer(manufacturer.id) | trustUrl">
                  <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleManufacturer(manufacturer.id); onChange()">
                    <div class="flag-control" #control>
                      <app-shared-ui-checkbox [formControlName]="manufacturer.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                    </div>
                    <div class="flag-label">
                      <span>{{ manufacturer.title }}</span>
                    </div>
                  </div>
                </a>
              }
              @if (manufacturersExpanded()) {
                @for (manufacturer of manufacturers(); track trackById(i, manufacturer); let i = $index) {
                  @if (i >= 8) {
                    <a class="flag-link-wrapper" [href]="routerLinkManufacturer(manufacturer.id) | trustUrl">
                      <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleManufacturer(manufacturer.id); onChange()">
                        <div class="flag-control" #control>
                          <app-shared-ui-checkbox [formControlName]="manufacturer.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                        </div>
                        <div class="flag-label">
                          <span>{{ manufacturer.title }}</span>
                        </div>
                      </div>
                    </a>
                  }
                }
              }
            </div>
          </div>
        }
        @if (manufacturers().length > 8) {
          <div class="view-more" (click)="setManufacturersExpanded()">
            @if (!manufacturersExpanded()) {
              <span>{{ t('viewMore') | translate }}</span>
            }
            @if (manufacturersExpanded()) {
              <span>{{ t('viewLess') | translate }}</span>
            }
          </div>
        }
      </div>
    }

    <!-- параметры - словари, словари множества -->
    @if (withParams() && hasDictParams()) {
      @for (dict of state().formsDictParams; track trackById($index, dict)) {
        <ng-container [formGroup]="dict.form">
          @if (colorHasNoHex(dict)) {
            <div class="c-block">
              <div class="c-header __app-fluid-container-control-padding-bottom">
                <div class="c-header-left">
                  <div class="c-header-title">
                    <span>{{ dict.paramTitle }}</span>
                  </div>
                </div>
                <div class="c-header-right">
                  @if (paramsReferencesHelper.hasParamsReference(dict.paramId)) {
                    <a class="c-header-help" #helpElement [title]="paramsReferencesHelper.getParamsReference(dict.paramId).alt" [href]="dictionaryRouterLink() | trustUrl">
                      <div class="icon icon-primary-dark-hover icon-20x20px" (click)="$event.stopPropagation(); $event.preventDefault(); paramsReferencesHelper.openParamsReference(dict.paramId, helpElement)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#a)"><path d="M9.29 13.29a1.578 1.578 0 0 0-.12.15.76.76 0 0 0-.09.18.64.64 0 0 0-.06.18 1.36 1.36 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 11 14a1 1 0 0 0-1.71-.71ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 10 7a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.18A3 3 0 0 0 10 5Z" fill="#B0BFC6" /></g>
                          <defs>
                            <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
                          </defs>
                        </svg>
                      </div>
                    </a>
                  }
                  <div class="c-header-toggle">
                    <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !dict.isBlockEnabled }" (click)="toggleDictBlock(dict.paramId)">
                      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
                    </div>
                  </div>
                </div>
              </div>
              @if (dict.isBlockEnabled) {
                <div class="c-contents">
                  @if (dict.dictType === 'text') {
                    <div class="flags" [formGroup]="dict.form" [ngClass]="{ 'is-two-columns': shouldBeTwoColumnsDictBlock(dict.paramId) }">
                      @for (dictValue of dict.dictValues; track trackById(i, dictValue); let i = $index) {
                        @if (i > 6 ? !dict.valuesHidden : true) {
                          <div class="flag-link-wrapper">
                            <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleDictValue(dict.paramId, dictValue.id); onChange()">
                              <div class="flag-control" #control (click)="$event.stopPropagation()">
                                <app-shared-ui-checkbox [formControlName]="dictValue.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                              </div>
                              <div class="flag-label">
                                <span>{{ dictValue.title }}</span>
                              </div>
                            </div>
                          </div>
                        }
                      }
                    </div>
                  }
                  @if (dict.dictType === 'color') {
                    <div class="flags-colors" [formGroup]="dict.form">
                      @for (dictValue of dict.dictValues; track trackById($index, dictValue)) {
                        @if (dictValue.dictColorHex) {
                          <div (click)="$event.stopPropagation(); $event.preventDefault(); toggleDictValue(dict.paramId, dictValue.id); onChange()">
                            <div #control (click)="$event.stopPropagation()">
                              <app-shared-ui-checkbox-color [color]="dictValue.dictColorHex" [formControlName]="dictValue.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox-color>
                            </div>
                          </div>
                        }
                      }
                    </div>
                  }
                  @if (dict.dictType === 'range') {
                    <div class="dict-range">
                      <app-filters-slider
                        [id]='"slider" + dict.id'
                        [valueMin]="minDictValue(dict.dictValues)"
                        [valueMax]="maxDictValue(dict.dictValues)"
                        [options]="dictValuesOptions(dict)"
                        (sliderChange)="dictValuesSliderChange($event, dict)"
                        [hideSlider]="! isBrowser()"
                      />
                    </div>
                  }
                  @if (dict.dictType === 'text' && dict.dictValues.length > 6) {
                    <div class="view-more" (click)="dict.valuesHidden = !dict.valuesHidden">
                      @if (dict.valuesHidden) {
                        <span>{{ t('viewMore') | translate }}</span>
                      }
                      @if (!dict.valuesHidden) {
                        <span>{{ t('viewLess') | translate }}</span>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          }
        </ng-container>
      }
    }

    <!-- параметры - да/нет -->
    @if (withParams() && hasBooleanParams()) {
      <div class="c-block">
        <div class="c-header __app-fluid-container-control-padding-bottom">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('boolean.title') | translate }}</span>
            </div>
          </div>
          <div class="c-header-right">
            <div class="c-header-toggle">
              <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !state().isBooleanBlockEnabled }" (click)="toggleBooleanBlock()">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        @if (state().isBooleanBlockEnabled) {
          <div class="c-contents">
            <div class="flags" [formGroup]="state().formBooleanParams">
              @for (param of booleanParams(); track trackById($index, param)) {
                <div class="flag-link-wrapper">
                  <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleBoolean(param.id); onChange()">
                    <div class="flag-control" #control (click)="$event.stopPropagation()">
                      <app-shared-ui-checkbox [formControlName]="param.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                    </div>
                    <div class="flag-label">
                      <span>{{ param.title }}</span>
                    </div>
                    @if (paramsReferencesHelper.hasParamsReference(param.id)) {
                      <a class="flag-help" #helpElement [title]="paramsReferencesHelper.getParamsReference(param.id).alt" [href]="dictionaryRouterLink(categoryId) | trustUrl">
                        <div class="icon icon-primary-dark-hover icon-20x20px" (click)="$event.stopPropagation(); $event.preventDefault(); paramsReferencesHelper.openParamsReference(param.id, helpElement)">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#a)"><path d="M9.29 13.29a1.578 1.578 0 0 0-.12.15.76.76 0 0 0-.09.18.64.64 0 0 0-.06.18 1.36 1.36 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 11 14a1 1 0 0 0-1.71-.71ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 10 7a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.18A3 3 0 0 0 10 5Z" fill="#B0BFC6" /></g>
                            <defs>
                              <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
                            </defs>
                          </svg>
                        </div>
                      </a>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }

    <!-- серии товаров -->
    @if (withProductSeries() && hasProductSeries()) {
      <div class="c-block">
        <div class="c-header __app-fluid-container-control-padding-bottom">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('series.title') | translate }}</span>
            </div>
          </div>
          <div class="c-header-right">
            <div class="c-header-toggle">
              <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !state().isProductSeriesBlockEnabled }" (click)="toggleProductSeriesBlock()">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        @if (state().isProductSeriesBlockEnabled) {
          <div class="c-contents">
            @for (productSeries of state().formsProductSeries; track trackById($index, productSeries)) {
              <div class="flags-wrapper">
                <div class="flags-wrapper-title">
                  <span>{{ productSeries.title }}</span>
                </div>
                <div class="flags-wrapper-contents">
                  <div class="flags is-two-columns" [formGroup]="productSeries.form">
                    @for (productSeriesEntry of productSeriesOfManufacturer(productSeries.title); track trackById(i, productSeriesEntry); let i = $index) {
                      <ng-container [href]="routerLinkProductSeries(productSeriesEntry.id) | trustUrl">
                        @if (i > 6 ? !productSeries.valuesHidden : true) {
                          <a class="flag-link-wrapper">
                            <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleProductSeries(productSeriesEntry.id); onChange()">
                              <div class="flag-control" #control (click)="$event.stopPropagation()">
                                <app-shared-ui-checkbox [formControlName]="productSeriesEntry.id.toString()" (checkbox-change)="onChange()"></app-shared-ui-checkbox>
                              </div>
                              <div class="flag-label">
                                <span>{{ productSeriesEntry.title }}</span>
                              </div>
                            </div>
                          </a>
                        }
                      </ng-container>
                    }
                  </div>
                  @if (productSeriesOfManufacturer(productSeries.title).length > 6) {
                    <div class="view-more" (click)="productSeries.valuesHidden = !productSeries.valuesHidden">
                      @if (productSeries.valuesHidden) {
                        <span>{{ t('viewMore') | translate }}</span>
                      }
                      @if (!productSeries.valuesHidden) {
                        <span>{{ t('viewLess') | translate }}</span>
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }
      </div>
    }

    <div class="c-actions">
      <div class="c-action">
        <app-shared-ui-button buttonStyle="default" buttonColor="primary-dark" buttonSize="catalog-filters" buttonFontStyle="bold" (click)="ngSubmit()">
          <span>{{ t('actions.submit') | translate }}</span>
        </app-shared-ui-button>
      </div>
      <div class="c-action">
        <app-shared-ui-button buttonStyle="default" buttonColor="blue-gray" buttonSize="catalog-filters" buttonFontStyle="bold" (click)="reset()"
          ><span>{{ t('actions.reset') | translate }}</span>
        </app-shared-ui-button>
      </div>
    </div>
  </div>
</div>
