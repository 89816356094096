<div class="c">
  <div class="c-frame">
    @for (item of payload().items; track item) {
      <div class="c-item">
        <div class="c-item-image"><app-shared-attachment-image [attachment]="item.icon" objectFit="cover"></app-shared-attachment-image></div>
        <div class="c-item-frame">
          <span class="title">{{ item.title }}</span>
          <span class="subtitle">{{ item.subtitle }}</span>
        </div>
      </div>
    }
  </div>
</div>
