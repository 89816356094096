import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ContactsWebEndpoint, ContactsWebEndpointPaths, ContactsWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebContactsDataAccess implements ContactsWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    contacts(): Observable<ContactsWebResponse> {
        return this.http.post(ContactsWebEndpointPaths.contacts);
    }
}
