import { RegistryShared } from '../models/shared/registry.shared';
import { Observable } from 'rxjs';

export enum RegistryWebEndpointPaths {
    current = '/api/v1/registry/current',
}

export interface RegistryWebEndpoint {
    currentSchema(): Promise<CurrentSchemaWebResponse> | Observable<CurrentSchemaWebResponse>;
}

export type CurrentSchemaWebResponse = RegistryShared.RegistrySchema;
