export const interidSiteWebCoreFavoriteI18n = {
    favorites: {
        shared: {
            components: {
                favorites_list: {
                    title: 'Избранное',
                    empty: 'В избранном пока нет товаров',
                    allCategories: 'Все категории',
                    deleteAll: {
                        button: 'Удалить все',
                        confirm: {
                            title: 'Подтверждение',
                            message: 'Вы действительно хотите удалить все товары из избранного?',
                            proceed: 'Да, удалить все',
                            reject: 'Отмена',
                        },
                        success: 'Из вашего избранного были удалены все товары',
                    },
                },
            },
        },
    },
};
