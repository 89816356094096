import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ProductRequestWebEndpoint, ProductRequestSendRequest, ProductRequestWebEndpointPaths, ProductRequestSendResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebProductRequestDataAccess implements ProductRequestWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    send(request: ProductRequestSendRequest): Observable<ProductRequestSendResponse> {
        return this.http.post(ProductRequestWebEndpointPaths.send, request);
    }
}
