import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductEntryBasketDto } from './product-basket.dto';
import { AccountSettingsShared } from '../shared/account.shared';

type DTO_VERSION = number;

export const LOCAL_SESSION_DOCUMENT_COOKIE = 'local.session.sid';

export const LOCAL_SESSION_DTO_CURRENT_DTO_VERSION = 6;
export const LOCAL_SESSION_DTO_MAX_BASKET_SIZE = 20;

export class LocalStorageFavoriteDTO {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    category: number;
}

export class LocalStorageViewedDTO {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    category: number;
}

export class LocalStorageProfileDTO {
    @ApiModelProperty()
    fullName?: string;

    @ApiModelProperty()
    email?: string;

    @ApiModelProperty()
    phone?: string;

    @ApiModelProperty()
    deliveryAddress?: string;

    @ApiModelProperty()
    requisitesAttachmentId?: number;
}

export class LocalSessionBasketDTO {
    @ApiModelProperty()
    entries: Array<AppSessionBasketEntry>;
}

export class AppSessionBasketEntry {
    @ApiModelProperty()
    product: ProductEntryBasketDto;

    @ApiModelProperty()
    amount: number;

    @ApiModelProperty()
    price: string;
}

export class LocalSessionDTO {
    @ApiModelProperty()
    DTO_VERSION: DTO_VERSION;

    @ApiModelProperty()
    profile: LocalStorageProfileDTO;

    @ApiModelProperty()
    settings: AccountSettingsShared.AccountSettingsDto;

    @ApiModelProperty()
    favorites: Array<LocalStorageFavoriteDTO>;

    @ApiModelProperty()
    viewed: Array<LocalStorageViewedDTO>;

    @ApiModelProperty()
    compares: Array<number>;
  
    @ApiModelProperty()
    selectedCity: number;

    @ApiModelProperty()
    isSubscribed: boolean;

    @ApiModelProperty()
    isCookiePolicyAccepted: boolean;

    @ApiModelProperty()
    basket: LocalSessionBasketDTO;

    @ApiModelProperty()
    catalogPageSize: number;
}

export function initialLocalSessionDTO(): LocalSessionDTO {
    return {
        DTO_VERSION: LOCAL_SESSION_DTO_CURRENT_DTO_VERSION,
        profile: {},
        settings: AccountSettingsShared.defaultAccountSettings(),
        selectedCity: undefined,
        favorites: [],
        viewed: [],
        compares: [],
        isCookiePolicyAccepted: false,
        isSubscribed: false,
        basket: {
            entries: [],
        },
        catalogPageSize: 44,
    };
}
