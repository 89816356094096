import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, input, Input, OnDestroy, PLATFORM_ID, signal, viewChild, ViewChild } from '@angular/core';
import { ContentBlockHTML } from '@interid/interid-site-shared';
import { H1Service, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

const MAX_OFFSET_BEFORE_CUT = 150;

interface State {
    shouldBeCutted: boolean;
}

@Component({
    selector: 'app-content-block-html',
    templateUrl: './content-block-html.component.html',
    styleUrls: ['./content-block-html.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockHtmlComponent implements AfterViewInit, OnDestroy {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public payload = input<ContentBlockHTML>();
    public useCutoff = input<boolean>();
    public isArticle = input(false);
    
    public contentsRef = viewChild<ElementRef<HTMLDivElement>>('contents');

    public state = signal<State>({
        shouldBeCutted: false,
    });

    get html$() {
        const payload: [Observable<string>] = [this.h1.h1$];

        return combineLatest(payload).pipe(
            map(([h1]) => {
                return this.payload().contents.split('{{ h1 }}').join(h1);
            })
        );
    }

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly viewBreakpoint: ViewBreakpointsService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly h1: H1Service
    ) {}
 
    ngAfterViewInit(): void {
        if (this.useCutoff()) {
            this.viewBreakpoint.currentLayout$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((next) => {
                this.shouldBeCutted();
            });
        }
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    shouldBeCutted() {
        if (isPlatformBrowser(this.platformId) && this.contentsRef() && this.contentsRef().nativeElement) {
            this.state.update((prev) => ({
                ...prev,
                shouldBeCutted: this.contentsRef().nativeElement.clientHeight > MAX_OFFSET_BEFORE_CUT,
            }));
        }
    }

    t(input: string): string {
        return `content.components.content.${input}`;
    }

    viewMore() {
        this.state.update((prev) => ({
            ...prev,
            shouldBeCutted: !this.state().shouldBeCutted,
        }));
    }
}
