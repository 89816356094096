import { Observable } from 'rxjs';
import { IsEnum, IsInt, IsNotEmpty, IsPositive, IsOptional, IsString, IsArray } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductRequestDto } from '../models/entities/product-request.dto';
import { ProductRequestShared } from '../models/shared/product-request.shared';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';
import { ProductRequestSendRequest } from '../endpoints-web/product-request.web-endpoint';

export enum ProductRequestEndpointPaths {
    send = '/api/v1/product-request/send',
    setStatus = '/api/v1/product-request/setStatus',
    delete = '/api/v1/product-request/delete',
    assign = '/api/v1/product-request/assign',
    unassign = '/api/v1/product-request/unassign',
    list = '/api/v1/product-request/list',
    getById = '/api/v1/product-request/getById',
}

export interface ProductRequestEndpoint {
    send(request: ProductRequestSendRequest): Promise<ProductRequestDto> | Observable<ProductRequestDto>;
    setStatus(request: ProductRequestSetStatusRequest): Promise<ProductRequestDto> | Observable<ProductRequestDto>;
    delete(request: ProductRequestDeleteRequest): Promise<void> | Observable<void>;
    assign(request: ProductRequestAssignRequest): Promise<ProductRequestDto> | Observable<ProductRequestDto>;
    unassign(request: ProductRequestUnassignRequest): Promise<ProductRequestDto> | Observable<ProductRequestDto>;
    list(request: ProductRequestListRequest): Promise<ProductRequestListResponse> | Observable<ProductRequestListResponse>;
    getById(request: ProductRequestGetByIdRequest): Promise<ProductRequestDto> | Observable<ProductRequestDto>;
}

export class ProductRequestSetStatusRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProductRequestShared.Status),
    })
    @IsNotEmpty()
    newStatus: ProductRequestShared.Status;

    @IsOptional()
    @ApiModelProperty()
    @IsString()
    comment?: string;
}

export class ProductRequestDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductRequestAssignRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    accountEmail: string;
}

export class ProductRequestUnassignRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductRequestListRequest extends CrudListRequestDto<ProductRequestDto> {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProductRequestShared.Status),
        isArray: true,
        required: false,
    })
    @IsArray()
    @IsEnum(ProductRequestShared.Status, {
        each: true,
    })
    @IsOptional()
    statuses?: Array<ProductRequestShared.Status>;
}

export class ProductRequestListResponse extends CrudListResponseDto<ProductRequestDto> {
    @ApiModelProperty({
        type: ProductRequestDto,
    })
    items: Array<ProductRequestDto>;
}

export class ProductRequestGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
