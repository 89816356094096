import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { AttachmentDto, CreateProductReviewRequest, ProductReviewDto, ProductReviewsWebEndpoint, ProductReviewsWebEndpointPaths, ProductsReviewsWebRequest, ProductsReviewsWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebProductReviewsDataAccess implements ProductReviewsWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    review(request: CreateProductReviewRequest, recaptchaV3Token: string): Observable<ProductReviewDto> {
        return this.http.post(ProductReviewsWebEndpointPaths.review, request, {
            headers: {
                'recaptcha': recaptchaV3Token,
            },
        });
    }

    upload(file: any): Observable<AttachmentDto> {
        const formData = new FormData();

        formData.append('file', file);

        return this.http.post(ProductReviewsWebEndpointPaths.upload, formData);
    }

    reviews(request: ProductsReviewsWebRequest): Observable<ProductsReviewsWebResponse> {
        return this.http.post(ProductReviewsWebEndpointPaths.reviews, request);
    }
}
