import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { CurrentSchemaWebResponse, RegistryWebEndpoint, RegistryWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebRegistryDataAccess implements RegistryWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    currentSchema(): Observable<CurrentSchemaWebResponse> {
        return this.http.get(RegistryWebEndpointPaths.current);
    }
}
