import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { DeliveryDto } from '../models/entities/delivery.dto';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { SiteColors } from '../models/util/site-colors';
import { AccountWebDto } from '../models/entities/account.dto';
import { AccountSettingsShared } from '../models/shared/account.shared';
import { CityDto } from '../models/entities/city.dto';
import { ContentDTO } from '../models/shared/common-content.shared';
import { ProductCategorySeoLinkDto } from '../models/entities/product-category-seo-link.dto';
import { ProductDto } from '../models/entities/product.dto';

export enum AppBootstrapWebEndpointPaths {
    appBootstrap = '/api/v1/web/homepage/appBootstrap',
}

export interface AppBootstrapWebEndpoint {
    getBootstrap(req?: any): Promise<AppBootstrapWebResponse> | Observable<AppBootstrapWebResponse>;
}

export class AppBootstrapWebResponseConstants {
    @ApiModelProperty()
    mobilePhone: string;

    @ApiModelProperty()
    multichannelPhone: string;

    @ApiModelProperty()
    phoneForOrders: string;

    @ApiModelProperty()
    workTime: string;

    @ApiModelProperty()
    managersWorkTime: string;

    @ApiModelProperty()
    address: string;

    @ApiModelProperty()
    emailForQuestions: string;

    @ApiModelProperty()
    emailForOrders: string;

    @ApiModelProperty()
    telegram: string;

    @ApiModelProperty()
    telegramText: string;

    @ApiModelProperty()
    whatsapp: string;

    @ApiModelProperty()
    whatsappText: string;

    @ApiModelProperty()
    viber: string;

    @ApiModelProperty()
    viberText: string;

    @ApiModelProperty()
    freeShippingThreshold: string;

    @ApiModelProperty()
    rutube: string;

    @ApiModelProperty()
    rutubeText: string;

    @ApiModelProperty()
    youtube: string;

    @ApiModelProperty()
    youtubeText: string;

    @ApiModelProperty()
    dzen: string;

    @ApiModelProperty()
    dzenText: string;

    @ApiModelProperty()
    footerCopyright: string;

    @ApiModelProperty({
        type: () => ContentDTO,
    })
    confidentialPolicy: ContentDTO;

    @ApiModelProperty()
    footer: string;

    @ApiModelProperty()
    pp: string;

    @ApiModelProperty()
    ppText: string;

    @ApiModelProperty()
    slogan: string;
}

export class AppBootstrapMessage {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    sideMessageId?: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isSiteMessageEnabled: boolean;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(SiteColors),
    })
    @IsEnum(SiteColors)
    @IsNotEmpty()
    siteMessageBgColor: SiteColors;

    @ApiModelProperty({
        isArray: true,
        type: 'string',
        enum: Object.values(SiteColors),
    })
    @IsEnum(SiteColors)
    @IsNotEmpty()
    siteMessageTextColor: SiteColors;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    siteMessage?: string;
}

export class AppBootstrapWebResponse {
    @ApiModelProperty()
    constants: AppBootstrapWebResponseConstants;

    @ApiModelProperty({
        type: ProductCategoryListDto,
        isArray: true,
    })
    productCategories: Array<ProductCategoryListDto>;

    @ApiModelProperty({
        type: ProductDto,
        isArray: true,
    })
    productCategoriesHighRankedProducts: Array<ProductDto>;

    @ApiModelProperty({
        type: () => ProductCategoryListDto,
        isArray: true,
    })
    popularProductCategories: Array<ProductCategoryListDto>;

    @ApiModelProperty({
        type: () => ProductCategorySeoLinkDto,
        isArray: true,
    })
    productCategorySeoLinks: Array<ProductCategorySeoLinkDto>;

    @ApiModelProperty({
        type: CityDto,
        isArray: true,
    })
    cities: Array<CityDto>;

    @ApiModelProperty()
    isSubscribed?: boolean;

    @ApiModelProperty()
    discountedStats: number;

    @ApiModelProperty()
    isCookiePolicyAccepted?: boolean;

    @ApiModelProperty({
        type: DeliveryDto,
        isArray: true,
    })
    deliveries: Array<DeliveryDto>;

    @ApiModelProperty({
        required: false,
    })
    selectedCity?: number;

    @ApiModelProperty({
        required: false,
    })
    numFavorites?: number;

    @ApiModelProperty({
        required: false,
    })
    numCompares?: number;

    @ApiModelProperty({
        required: false,
    })
    numBasket?: number;

    @ApiModelProperty({
        required: false,
    })
    account?: AccountWebDto;

    @ApiModelProperty({
        required: false,
    })
    accountSettings?: AccountSettingsShared.AccountSettingsDto;

    @ApiModelProperty({
        required: false,
    })
    message?: AppBootstrapMessage;

    @ApiModelProperty({
        required: false,
    })
    vacancies?: number;
}
