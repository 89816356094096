import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ProductCompareDto, ProductCompareEndpoint, ProductCompareEndpointPaths, ProductCompareExcludeRequest, ProductCompareIncludeRequest, ProductCompareListResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebProductCompareDataAccess implements ProductCompareEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    include(request: ProductCompareIncludeRequest): Observable<Array<ProductCompareDto>> {
        return this.http.post(ProductCompareEndpointPaths.include, request);
    }

    exclude(request: ProductCompareExcludeRequest): Observable<Array<ProductCompareDto>> {
        return this.http.post(ProductCompareEndpointPaths.exclude, request);
    }

    list(): Observable<ProductCompareListResponse> {
        return this.http.post(ProductCompareEndpointPaths.list);
    }
}
