import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GlobalBrowserScrollService {
    private _enabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    get enabled$(): Observable<boolean> {
        return this._enabled$.asObservable();
    }

    enable(): void {
        this._enabled$.next(true);
    }

    disable(): void {
        this._enabled$.next(false);
    }
}
