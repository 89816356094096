import { IsEmail, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { OrderDto } from '../entities/order.dto';

export namespace OrderShared {
  export enum Status {
    NotProcessed = 'NotProcessed',
    InProgress = 'InProgress',
    Cancelled = 'Cancelled',
    Processed = 'Processed',
  }

  export const allStatuses: Array<Status> = Object.values(Status);
}

export namespace OrderHistoryShared {
  export enum Type {
    Created = 'Created',
    Updated = 'Updated',
    UpdateStatus = 'UpdateStatus',
    AssignTo = 'AssignTo',
    Unsassigned = 'Unsassigned',
  }

  export type OrderHistoryEntry =
    { type: Type.Created; source: EventSource; payload: CreatedUpload } |
    { type: Type.Updated; source: EventSource; payload: UpdatedPayload } |
    { type: Type.UpdateStatus; source: EventSource; payload: UpdateStatusPayload } |
    { type: Type.AssignTo; source: EventSource; payload: AssignToPayload } |
    { type: Type.Unsassigned; source: EventSource; payload: UnsassignedPayload }
  ;

  export class EventSource {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    date: string;

    @IsPositive()
    @IsInt()
    @IsOptional()
    @ApiModelProperty()
    userId?: number;

    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    userName: string;

    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    userEmail: string;
  }

  export class CreatedUpload {
    head: OrderDto;
  }

  export class UpdatedPayload {
    head: OrderDto;
    orig: OrderDto;
  }

  export class UpdateStatusPayload {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsEnum(OrderShared.Status)
    origStatus: OrderShared.Status;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsEnum(OrderShared.Status)
    headStatus: OrderShared.Status;

    @IsOptional()
    @ApiModelProperty()
    @IsString()
    comment: string;
  }

  export class AssignToPayload {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    assignedToName: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsEmail()
    assignedToEmail: string;

    @IsOptional()
    @ApiModelProperty()
    @IsString()
    comment: string;
  }

  export class UnsassignedPayload {
  }
}

