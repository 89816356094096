import { ProductPriceLogDto } from '../dto/product-price-log.dto';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNumber, IsOptional, IsPositive } from 'class-validator';
import { CrudListRequestDto, CrudListResponseDto } from '../../../../core/crud';

export enum ProductPriceLogEndpointPaths {
  list = '/api/v2/product-price-log/list'
}

export interface ProductPriceLogEndpoint {
  list(request: ProductPriceLogListRequest): Promise<ProductPriceLogListResponse> | Observable<ProductPriceLogListResponse>;
}

export class ProductPriceLogListRequest extends CrudListRequestDto<ProductPriceLogDto> {
  @ApiModelProperty({
    type: 'Filter with price delta more than x %',
  })
  @IsPositive()
  @IsNumber()
  @IsOptional()
  deltaPriceMoreThan?: number;

  @ApiModelProperty({
    type: 'Filter with basePrice delta more than x %',
  })
  @IsPositive()
  @IsNumber()
  @IsOptional()
  deltaBasePriceMoreThan?: number;
}

export class ProductPriceLogListResponse extends CrudListResponseDto<ProductPriceLogDto> {
  @ApiModelProperty({
    type: ProductPriceLogDto,
    isArray: true,
  })
  items: Array<ProductPriceLogDto>;
}
