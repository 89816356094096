import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export namespace TelegramShared {
  export enum TargetTelegramGroup {
    Common = 'Common',
    Orders = 'Orders',
    UserActivity = 'UserActivity',
  }

  export const alLTelegramGroups: Array<{
    target: TargetTelegramGroup;
    env: string;
  }> = [
    { target: TargetTelegramGroup.Common, env: 'TELEGRAM_GROUP_NOTIFICATIONS' },
    { target: TargetTelegramGroup.Orders, env: 'TELEGRAM_GROUP_NOTIFICATIONS_ORDERS' },
    { target: TargetTelegramGroup.UserActivity, env: 'TELEGRAM_GROUP_NOTIFICATIONS_USER_ACTIVITY' },
  ];

  export enum TelegramParseMode {
    None = 'None',
    MarkdownV2 = 'MarkdownV2',
    HTML = 'HTML',
  }

  export interface SendTelegramMessageRequest {
    target: TargetTelegramGroup;
    parseMode: TelegramParseMode;
    message: string;
  }

  export class Settings {
    @ApiModelProperty()
    isAvailable: boolean;

    @ApiModelProperty()
    targetGroups: Array<TargetGroupSettings>;
  }

  export class TargetGroupSettings {
    @ApiModelProperty()
    target: TargetTelegramGroup;

    @ApiModelProperty()
    groupId: number;

    @ApiModelProperty()
    inviteLink: string;
  }
}
