import { IsNotEmpty, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductCategoryDto, ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ParamsReferenceDto } from '../models/entities/params-reference.dto';

export enum DictionaryWebEndpointPaths {
    dictionary = '/api/v1/web/dictionary',
}

export interface DictionaryWebEndpoint {
    dictionary(request: DictionaryWebRequest): Promise<DictionaryWebResponse> | Observable<DictionaryWebResponse>;
}

export class DictionaryWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    category: string;
}

export class DictionaryWebResponse {
    @ApiModelProperty()
    productCategory: ProductCategoryDto;

    @ApiModelProperty({
        type: ProductCategoryListDto,
        isArray: true,
    })
    productCategoriesWithParamReferences: Array<ProductCategoryListDto>;

    @ApiModelProperty({
        type: ParamsReferenceDto,
        isArray: true,
    })
    paramReferences: Array<ParamsReferenceDto>;
}
