import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { FrontpageCategoryDto } from '../models/entities/frontpage-category.dto';
import { CrudSetSortOrderRequestDto } from '../core/crud';

export enum FrontpageCategoryEndpointPaths {
    list = '/api/v1/frontpage-categories/list',
    create = '/api/v1/popula-categories/create',
    edit = '/api/v1/frontpage-categories/edit',
    delete = '/api/v1/frontpage-categories/delete',
    setSortOrder = '/api/v1/frontpage-categories/setSortOrder',
}

export interface FrontpageCategoryEndpoint {
    create(request: CreateFrontpageCategoryRequest): Promise<FrontpageCategoryDto> | Observable<FrontpageCategoryDto>;
    edit(request: EditFrontpageCategoryRequest): Promise<FrontpageCategoryDto> | Observable<FrontpageCategoryDto>;
    delete(request: DeleteFrontpageCategoryRequest): Promise<void> | Observable<void>;
    list(): Promise<ListFrontpageCategoriesResponse> | Observable<ListFrontpageCategoriesResponse>;
    setSortOrder(request: SetSortOrderFrontpageCategoryRequest): Promise<void> | Observable<void>;
}

export class FrontpageCategoryEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productCategoryId: number;
}

export type ListFrontpageCategoriesResponse = Array<FrontpageCategoryDto>;

export class CreateFrontpageCategoryRequest extends FrontpageCategoryEntityBody {
}

export class EditFrontpageCategoryRequest extends FrontpageCategoryEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteFrontpageCategoryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderFrontpageCategoryRequest extends CrudSetSortOrderRequestDto {}
