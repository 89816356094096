import { Observable } from 'rxjs';
import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ContentDTO } from '../models/shared/common-content.shared';
import { ParamsReferenceDto } from '../models/entities/params-reference.dto';
import { CrudSetSortOrderRequestDto } from '../core/crud';

export enum ParamsReferenceEndpointPaths {
    create = '/api/v1/params-reference/create',
    edit = '/api/v1/params-reference/edit',
    delete = '/api/v1/params-reference/delete',
    list = '/api/v1/params-reference/list',
    setSortOrder = '/api/v1/params-reference/setSortOrder',
}

export interface ParamsReferenceEndpoint {
    create(request: ParamsReferenceCreateRequest): Promise<ParamsReferenceDto> | Observable<ParamsReferenceDto>;
    edit(request: ParamsReferenceEditRequest): Promise<ParamsReferenceDto> | Observable<ParamsReferenceDto>;
    delete(request: ParamsReferenceDeleteRequest): Promise<void> | Observable<void>;
    setSortOrder(request: ParamsReferenceSetSortOrderRequest): Promise<void> | Observable<void>;
    list(request: ParamsReferenceListRequest): Promise<ParamsReferenceListResponse> | Observable<ParamsReferenceListResponse>;
}

export class ParamsReferenceEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productCategoryId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    paramsId: number;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    alt: string;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    descriptionForTooltip: ContentDTO;

    @ApiModelProperty({
        type: () => ContentDTO,
    })
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    descriptionFull: ContentDTO;
}

export class ParamsReferenceCreateRequest extends ParamsReferenceEntityBody {
}

export class ParamsReferenceEditRequest extends ParamsReferenceEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ParamsReferenceDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ParamsReferenceSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class ParamsReferenceListRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productCategoryId?: number;
}

export class ParamsReferenceListResponse {
    @ApiModelProperty({
        type: ParamsReferenceDto,
        isArray: true,
    })
    entities: Array<ParamsReferenceDto>;
}

