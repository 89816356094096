import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountRequisitesShared } from '../shared/account-requisites.shared';

export class AccountRequisitesDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    accountId: number;

    @ApiModelProperty()
    definitions: Array<AccountRequisitesShared.RequisitesDefinition>;
}
