<div class="c" [formGroup]="state().form">
  <div class="c-left">
    <div class="c-sort __hide-app-mobile __hide-app-tablet">
      <app-ui-select formControlName="sort" [options]="state().sortOptions" [labels]="state().sortPlaceholdersDesktop" placeholder="{{ t('sortPlaceholder') | translate }}" size="thinner"></app-ui-select>
    </div>
    <div class="c-sort-mobile __hide-app-wide __hide-app-desktop">
      <app-ui-select formControlName="sort" [variant]="'sort'" [singleselect]="true" [options]="state().sortOptions" [labels]="state().sortPlaceholdersMobile" placeholder="{{ t('sortPlaceholder') | translate }}" size="thinner"></app-ui-select>
    </div>
    <div class="c-filter-mobile __hide-app-wide __hide-app-desktop">
      <div class="c-filter-mobile-button" (click)="filterScreen()">
        <div class="c-label">
          <span class="placeholder">{{ t('filtersPlaceholder') | translate }}</span>
        </div>
        <div class="c-icon">
          <div class="icon">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 8.18V3a1 1 0 1 0-2 0v5.18a3 3 0 0 0 0 5.64V21a1 1 0 0 0 2 0v-7.18a3 3 0 0 0 0-5.64ZM19 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm-6 2.18V3a1 1 0 1 0-2 0v11.18a3 3 0 0 0 0 5.64V21a1 1 0 0 0 2 0v-1.18a3 3 0 0 0 0-5.64ZM12 18a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM6 6.18V3a1 1 0 1 0-2 0v3.18a3 3 0 0 0 0 5.64V21a1 1 0 1 0 2 0v-9.18a3 3 0 0 0 0-5.64ZM5 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" fill="#1269FF" /></svg>
          </div>
        </div>
      </div>
    </div>
    <div class="c-listview-mobile __hide-app-wide __hide-app-desktop">
      @if (isViewModeActive$('Card') | async) {
        <span (click)="setViewMode('List')" class="icon icon-primary-hover-custom is-icon-active">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_302_109097)">
              <circle cx="6.5" cy="7" r="3.5" fill="#253238" />
              <circle cx="17.5" cy="7" r="3.5" fill="#253238" />
              <circle cx="17.5" cy="18" r="3.5" fill="#253238" />
              <circle cx="6.5" cy="18" r="3.5" fill="#253238" />
            </g>
            <defs>
              <clipPath id="clip0_302_109097">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        </span>
      }
      @if (isViewModeActive$('List') | async) {
        <span (click)="setViewMode('Card')" class="icon icon-primary-hover is-icon-active">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="10.5" width="20" height="4" rx="2" fill="#D0D9DE" />
            <rect x="2" y="17.5" width="20" height="4" rx="2" fill="#D0D9DE" />
            <rect x="2" y="3.5" width="20" height="4" rx="2" fill="#D0D9DE" />
          </svg>
        </span>
      }
    </div>
    <div class="c-flags">
      <div class="c-flag">
        <app-shared-ui-checkbox formControlName="onlyAvailableOnDepot" label="{{ t('onlyAvailableOnDepot') | translate }}"></app-shared-ui-checkbox>
      </div>
    </div>
  </div>

  <div class="c-right">
    <div class="c-view-mode">
      <div class="c-view-mode-option" (click)="setViewMode('List')" matTooltip="{{ t('view_mode_list') | translate }}">
        <div class="icon icon-primary-hover" [ngClass]="{ 'is-icon-active': isViewModeActive$('List') | async }">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="10.5" width="20" height="4" rx="2" fill="#D0D9DE" />
            <rect x="2" y="17.5" width="20" height="4" rx="2" fill="#D0D9DE" />
            <rect x="2" y="3.5" width="20" height="4" rx="2" fill="#D0D9DE" />
          </svg>
        </div>
      </div>
      <div class="c-view-mode-option" (click)="setViewMode('Card')" matTooltip="{{ t('view_mode_card') | translate }}">
        <div class="icon icon-primary-hover-custom" [ngClass]="{ 'is-icon-active': isViewModeActive$('Card') | async }">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g class="hover-fill" clip-path="url(#clip0_302_109097)" fill="#D0D9DE">
              <circle cx="6.5" cy="7" r="3.5" />
              <circle cx="17.5" cy="7" r="3.5" />
              <circle cx="17.5" cy="18" r="3.5" />
              <circle cx="6.5" cy="18" r="3.5" />
            </g>
            <defs>
              <clipPath id="clip0_302_109097"><path fill="#fff" d="M0 .5h24v24H0z" /></clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
