<div class="c" [formGroup]="state().form">
  <div class="c-box">
    <div class="c-box-left">
      <div class="c-box-left-promo">
        <div class="c-box-left-promo-title">
          <span>{{ t('title') | translate }}</span>
        </div>
      </div>
    </div>
    <div class="c-box-center">
      <div class="c-box-center-icons-line"></div>
      <div class="c-box-center-icons">
        @for (image of state().images; track image) {
          <div class="c-box-center-icons-image"><img loading="lazy" [src]="image.url" [alt]="image.title" /></div>
        }
      </div>
      <div class="c-box-center-icons-line"></div>
    </div>
    <div class="c-box-right">
      <div class="c-box-right-wrapper">
        <div class="c-box-right-form">
          <input type="email" formControlName="email" placeholder="{{ t('email') | translate }}" (keyup.enter)="ngSubmit()" />
          <button type="button" (click)="ngSubmit()" matRipple="matRipple" [disabled]="this.state().form.disabled">
            <span>{{ t('submit') | translate }}</span>
          </button>
        </div>
        <div class="c-box-right-agree">
          <span
            >{{ t('agree') | translate }} <a [routerLink]="['/privacy']">{{ t('agreeLinkText') | translate }}</a></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
