import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { AttachmentDto, CreateProductQuestionRequest, ProductQuestionDto, ProductQuestionsWebEndpoint, ProductQuestionsWebEndpointPaths, ProductsQuestionsWebRequest, ProductsQuestionsWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebProductQuestionsDataAccess implements ProductQuestionsWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    question(request: CreateProductQuestionRequest): Observable<ProductQuestionDto> {
        return this.http.post(ProductQuestionsWebEndpointPaths.question, request);
    }

    upload(file: any): Observable<AttachmentDto> {
        const formData = new FormData();

        formData.append('file', file);

        return this.http.post(ProductQuestionsWebEndpointPaths.upload, formData);
    }

    questions(request: ProductsQuestionsWebRequest): Observable<ProductsQuestionsWebResponse> {
        return this.http.post(ProductQuestionsWebEndpointPaths.questions, request);
    }
}
