
@if (attachment()) {
  <picture>
    @if (state().isDisplayed) {
      @if (state().srcSetWebp) {
        <source type="image/webp" [srcset]="state().srcSetWebp" [sizes]="state().srcSetWebpSizes"/>
      }
      @if (state().srcSet) {
        <source [srcset]="state().srcSet" [sizes]="state().srcSetSizes"/>
        }@if (!! state) {
        <img
          #img loading="lazy"
          [alt]="state().alt"
          [src]="state().src"
          [ngStyle]="imgNgStyle()"
          [attr.data-srcset]="state().srcSet"
          [attr.data-sizes]="state().srcSetSizes"/>
      }
    }
  </picture>
}
