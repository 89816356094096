import * as nodeQs from 'querystring';
import * as nodeUrl from 'url';
import { Source, SourceDefinition } from '../shared/common.shared';
import { ProductEsSearchUrlGeneratorHelper } from './product-es-search-url-generator.helper';
import { UrlGenerator } from './url-generator.helper';
import { withoutTrailingSlash } from '../functions/without-trailing-slash.function';

interface UrlBuildOptions {
  origUrl?: string;
}

interface RouterLinkDefinition {
  route: any;
  queryParams?: any;
}

export { RouterLinkDefinition as UrlBuilderRouterLinkDefinition };
export { UrlBuildOptions as UrlBuilderBuildOptions };

export function routerLinkBuildFromDefinitionHelper(urlSource: SourceDefinition, options?: UrlBuildOptions): RouterLinkDefinition {
  const url = urlBuildFromDefinitionHelper(urlSource, options);
  const parsed = nodeUrl.parse(url);

  return {
    route: [parsed.pathname],
    queryParams: nodeQs.decode(parsed.query),
  };
}

export function urlBuildFromDefinitionHelper(urlSource: SourceDefinition, options?: UrlBuildOptions): string {
  const url = buildUrlFromDefinition(urlSource, options);

  return url
    .split('%2C')
    .join(',');
}

function buildUrlFromDefinition(urlSource: SourceDefinition, options?: UrlBuildOptions): string {
  const urlType = urlSource.type;

  const baseUrl = (() => {
    switch (urlSource.type) {
      default: {
        throw new Error(`Unknown url source type "${urlType}"`);
      }

      case Source.Uri: {
        let url = options && options.origUrl
          ? options.origUrl
          : '#';

        if (! url.startsWith('/') && ! (url.startsWith('http://') || url.startsWith('https://'))) {
          url = '/' + url;
        }

        return url;
      }

      case Source.ArticleCategory: {
        return UrlGenerator.generateArticleCategoryUrl(urlSource.payload.articleCategoryUri);
      }

      case Source.Articles: {
        return UrlGenerator.generateArticlesUrl();
      }

      case Source.Solutions: {
        return UrlGenerator.generateSolutionsUrl();
      }

      case Source.Article: {
        return urlSource.payload.articleUri
          ? UrlGenerator.generateArticleUrlWithSlug(urlSource.payload.articleCategoryUri, urlSource.payload.articleUri)
          : UrlGenerator.generateArticleUrl(urlSource.payload.articleId);
      }

      case Source.Product: {
        return UrlGenerator.generateProductUrl(urlSource.payload.productId);
      }

      case Source.Manufacturer: {
        const queryParams = (() => {
          if (Object.keys(urlSource.payload).length > 0) {
            const helper = new ProductEsSearchUrlGeneratorHelper();

            helper.parseProductESSearchRequest(urlSource.payload.options?.esQuery);

            return helper.toPlainObject();
          } else {
            return {};
          }
        })();

        if (urlSource.payload.options && urlSource.payload.options.pageSize) {
            queryParams['limit'] = urlSource.payload.options.pageSize.toString();
        }

        return UrlGenerator.generateManufacturerUrl(
          urlSource.payload.vendorUrl,
          urlSource.payload.categoryUrl,
          {
              ...urlSource.payload.options,
              esQuerySerialized: nodeQs.encode(queryParams).replace('%2C', ','),
          },
        );
      }

      case Source.Solution: {
        return urlSource.payload.solutionUri
          ? UrlGenerator.generateSolutionUrlWithSlug(urlSource.payload.solutionCategoryUri, urlSource.payload.solutionUri)
          : UrlGenerator.generateSolutionUrl(urlSource.payload.solutionId);
      }

      case Source.SolutionCategory: {
        return UrlGenerator.generateSolutionCategoryUrl(urlSource.payload.solutionCategoryUri);
      }

      case Source.Dictionary: {
        return UrlGenerator.generateDictionaryUrl(urlSource.payload.category);
      }

      case Source.Catalog: {
        const queryParams = (() => {
          if (Object.keys(urlSource.payload).length > 0) {
            const helper = new ProductEsSearchUrlGeneratorHelper();

            helper.parseProductESSearchRequest(urlSource.payload.esQuery);

            return helper.toPlainObject();
          } else {
            return undefined;
          }
        })();

        return UrlGenerator.generateCatalogQueryURL(
          urlSource.payload.productCategoryId, nodeQs.encode(queryParams).replace('%2C', ','), urlSource.payload.page,
        );
      }

      case Source.ProductSearch: {
        const helper = new ProductEsSearchUrlGeneratorHelper();

        helper.parseProductESSearchRequest(urlSource.payload.esQuery);

        return UrlGenerator.generateProductSearchURL(
          urlSource.payload.queryString,
          helper.toQueryString(),
        );
      }

      case Source.ArticlesSearch: {
        return UrlGenerator.generateArticlesSearchURL(
          urlSource.payload.queryString,
          urlSource.payload.articleCategoryId,
        );
      }

      case Source.ArticleReviewsSearch: {
        return UrlGenerator.generateArticleReviewsSearchURL(
          urlSource.payload.queryString,
          urlSource.payload.articleCategoryId,
        );
      }

      case Source.ProductReviewsSearch: {
        return UrlGenerator.generateProductReviewsSearchURL(
          urlSource.payload.queryString,
          urlSource.payload.productCategoryId,
        );
      }

      case Source.Sales: {
        const queryParams = (() => {
          if (Object.keys(urlSource.payload).length > 0) {
            const helper = new ProductEsSearchUrlGeneratorHelper();

            helper.parseProductESSearchRequest(urlSource.payload.esQuery);

            return helper.toPlainObject();
          } else {
            return undefined;
          }
        })();

        if (urlSource.payload.page) {
          queryParams['page'] = urlSource.payload.page.toString();
        }

        return UrlGenerator.generateSalesUrl(
          urlSource.payload.productCategoryUri, nodeQs.encode(queryParams).replace('%2C', ','),
        );
      }

      case Source.Discounted: {
        const queryParams = (() => {
          if (Object.keys(urlSource.payload).length > 0) {
            const helper = new ProductEsSearchUrlGeneratorHelper();

            helper.parseProductESSearchRequest(urlSource.payload.esQuery);

            return helper.toPlainObject();
          } else {
            return undefined;
          }
        })();

        if (urlSource.payload.page) {
          queryParams['page'] = urlSource.payload.page.toString();
        }

        return UrlGenerator.generateDiscountedUrl(
          urlSource.payload.productCategoryUri, nodeQs.encode(queryParams).replace('%2C', ','),
        );
      }

      case Source.Markingkits: {
        const queryParams = (() => {
          if (Object.keys(urlSource.payload).length > 0) {
            const helper = new ProductEsSearchUrlGeneratorHelper();

            helper.parseProductESSearchRequest(urlSource.payload.esQuery);

            return helper.toPlainObject();
          } else {
            return undefined;
          }
        })();

        if (urlSource.payload.page) {
          queryParams['page'] = urlSource.payload.page.toString();
        }

        return UrlGenerator.generateMarkingkitsUrl(
          urlSource.payload.productCategoryUri, nodeQs.encode(queryParams).replace('%2C', ','),
        );
      }
    }
  })();

  if (urlSource.queryParams) {
    const encodeGetParams = (p: any) => Object.entries(p)
      .map(kv => kv.map(encodeURIComponent).map((qv) => qv.replace('%2C', ',')).join('='))
      .join('&');

    return `${baseUrl}?${encodeGetParams(urlSource.queryParams)}`;
  } else {
    return baseUrl;
  }
}

export function routerLinkDefinitionToHref(input: RouterLinkDefinition): string {
  const baseUrl = withoutTrailingSlash(input.route.join('/'));

  if (input.queryParams && Object.keys(input.queryParams).length > 0) {
    return `/${baseUrl}?${nodeQs.encode(input.queryParams)}`;
  } else {
    return `/${baseUrl}`;
  }
}
