import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { AttachmentDto, DevFeedbackWebCreateRequest, DevFeedbackWebEndpoint, DevFeedbackWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebDevFeedbackRequestDataAccess implements DevFeedbackWebEndpoint {
    constructor(private readonly http: InteridDataAccessService) {}

    create(request: DevFeedbackWebCreateRequest): Observable<void> {
        return this.http.post(DevFeedbackWebEndpointPaths.create, request);
    }

    upload(file: any): Observable<AttachmentDto> {
        const formData = new FormData();

        formData.append('file', file);

        return this.http.post(DevFeedbackWebEndpointPaths.upload, formData);
    }
}
