export const interidSiteWebFeatureHomepageI18n = {
    homepage: {
        components: {
            homepage_about_company: {
                title: 'О компании',
                partners: 'Мы являемся официальными партнерами ведущих производителей',
                certified: 'Весь товар сертифицирован',
                delivery: 'Удобная и быстрая доставка',
                warranty: 'Специальные условия для постоянных покупателей',
                services: 'Оказываем услуги по установке оборудования',
            },
            homepage_brands: {
                title: 'Бренды',
            },
            homepage_articles: {
                title1: 'Читайте наш блог',
                title2: 'Смотрите нас на', 
                title2_2: 'YouTube',
                all: 'Все записи',
                all2: 'Все видео',
                reviews: 'Обзоры',
                news: 'Новости',
                articles: 'Статьи',
                description: 'Официальный канал «Интер АйДи». Делаем обзоры оборудования и программ, инструкции по использованию, предлагаем полезные советы и делимся честным фидбэком о наших товарах и услугах'
            },
            homepage_product_categories: {
                title: 'Популярные категории',
            },
            homepage_products: {
                sales: 'Выгодные предложения',
                new: 'Новинки',
                hit: 'Хиты продаж'
            },
        },
    },
};
