<div class="c">
  <div class="c-menu">
    <div class="c-root-categories">
      @for (category of rootCategories; track trackById($index, category)) {
        <a class="c-root-category" [routerLink]="pcRouterLink(category.id).route" [title]="category.alt" (mouseenter)="onHoverRootCategoryMouseover(category)" (mouseleave)="onHoverRootCategoryMouseout(category)"  (click)="close()">
          <app-shared-attachment-image [attachment]="category.menuIcon" [maxImageWidth]="24"></app-shared-attachment-image>
          <div class="c-root-category-title">
            <span>{{ category.menuTitle }}</span>
          </div>
        </a>
      }

      <a class="c-root-category" [routerLink]="['/sales']" (click)="close()">
        <img loading="lazy" src="/assets/images/catalog_menu_sales.svg" />
        <div class="c-root-category-title">
          <span>Акции и скидки</span>
        </div>
      </a>

      <a class="c-root-category" [routerLink]="['/about/company']" (click)="close()">
        <img loading="lazy" src="/assets/images/catalog_menu_logo.svg" />
        <div class="c-root-category-title">
          <span>О компании «Inter ID»</span>
        </div>
      </a>
    </div>
    @if (state().isShowChild && !!state().hoveredCategory) {
      <div class="c-child-categories">
        <div class="c-child-categories-frame">
          <div class="c-root-category">
            <a class="c-category-lvl3" [routerLink]="pcRouterLink(state().hoveredCategory.id).route" [title]="state().hoveredCategory.alt" (click)="close()"
              ><span>{{ state().hoveredCategory.menuTitle }}</span></a
              >
            </div>
            @for (lvl2Category of categoriesSeoLinks$(state().hoveredCategory?.id) | async; track trackById($index, lvl2Category)) {
              <div class="c-category-lvl2">
                <a class="c-category-lvl2-title" [routerLink]="lvl2Category.uri" [title]="lvl2Category.alt" (click)="close()"
                  ><span>{{ lvl2Category.title }}</span></a
                  >
                </div>
              }
              @for (lvl2Category of childCategories$(state().hoveredCategory?.id) | async; track trackById($index, lvl2Category)) {
                @if ((childCategories$(lvl2Category.id) | async).length > 0) {
                  <div class="c-category-lvl2">
                    <a class="c-category-lvl2-title" [routerLink]="pcRouterLink(lvl2Category.id).route" [title]="lvl2Category.alt" (click)="close()">
                      <span>{{ lvl2Category.menuTitle }}</span></a
                      >
                      <div class="c-categories-lvl3" [ngClass]="{ 'as-grid': (childCategories$(lvl2Category.id) | async).length > 10 }">
                        @for (lvl3Category of childCategories$(lvl2Category.id) | async; track trackById($index, lvl3Category)) {
                          <a class="c-category-lvl3" [routerLink]="pcRouterLink(lvl3Category.id).route" [title]="lvl3Category.alt" (click)="close()"
                            ><span>{{ lvl3Category.menuTitle }}</span></a
                            >
                          }
                        </div>
                      </div>
                    }
                    @if ((childCategories$(lvl2Category.id) | async).length === 0) {
                      <a class="c-category-lvl2-title" [routerLink]="pcRouterLink(lvl2Category.id).route" [title]="lvl2Category.alt" (click)="close()">
                        <span>{{ lvl2Category.menuTitle }}</span></a
                        >
                      }
                    }
                  </div>
                </div>
              }

              @if (state().isShowChild && !!state().hoveredCategory && state().hoveredCategoryHighRankedProduct) {
                <div class="c-child-categories-product">
                  <div class="product">
                    <app-shared-product-card-g (redirected)="productClicked()" [variant]="'tiny'" [withParametersBadges]="true" [product]="state().hoveredCategoryHighRankedProduct" [withBadges]="true" [withParams]="true" [withRating]="true" [withReviews]="true" [withBasket]="true"></app-shared-product-card-g>
                  </div>
                </div>
              }


            </div>

            <diV class="c-controls">
              <div class="c-control" (click)="close()">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.67733 17.4799C6.30056 17.0921 6.30022 16.4751 6.67658 16.0869L10.6366 12.0025L6.67658 7.91811C6.30022 7.52994 6.30056 6.91292 6.67733 6.52515L6.71088 6.49063C7.1036 6.08646 7.75256 6.08646 8.14527 6.49063L12.8238 11.3056C13.2009 11.6937 13.2009 12.3113 12.8238 12.6994L8.14527 17.5144C7.75256 17.9186 7.1036 17.9186 6.71088 17.5144L6.67733 17.4799Z" fill="#90A4AF" />
                  <path d="M17.8246 6.52515C18.2013 6.91292 18.2017 7.52994 17.8253 7.91811L13.8653 12.0025L17.8253 16.0869C18.2017 16.4751 18.2013 17.0921 17.8246 17.4799L17.791 17.5144C17.3983 17.9186 16.7493 17.9186 16.3566 17.5144L11.6781 12.6994C11.301 12.3113 11.301 11.6937 11.6781 11.3056L16.3566 6.49063C16.7493 6.08646 17.3983 6.08646 17.791 6.49063L17.8246 6.52515Z" fill="#90A4AF" />
                </svg>
              </div>
              @if (!!state().hoveredCategory) {
                <div class="c-control" [ngClass]="{ back: state().isShowChild }" (click)="toggleShowChild()">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.0736 4.52264C13.4504 4.9104 13.4507 5.52742 13.0744 5.9156L9.11437 10L13.0744 14.0844C13.4507 14.4726 13.4504 15.0896 13.0736 15.4774L13.0401 15.5119C12.6474 15.9161 11.9984 15.9161 11.6057 15.5119L6.92712 10.6969C6.55005 10.3088 6.55005 9.6912 6.92712 9.30313L11.6057 4.48811C11.9984 4.08394 12.6474 4.08394 13.0401 4.48811L13.0736 4.52264Z" fill="#90A4AF" />
                  </svg>
                </div>
              }
            </diV>
          </div>
