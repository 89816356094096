import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class EmailSubscriptionDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  dateSubscribed: string;

  @ApiModelProperty()
  ip: string;

  @ApiModelProperty()
  userAgent: string;

  @ApiModelProperty()
  email: string;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  isActive: boolean;

  @ApiModelProperty()
  isAddedToSendPulse: boolean;
}
