import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductRevisionDto } from '../dto/product-revision.dto';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';
import { CrudListRequestDto, CrudListResponseDto } from '../../../../core/crud';

export enum ProductRevisionEndpointPaths {
    list = '/api/v2/product-revision/list',
}

export interface ProductRevisionEndpoint {
    list(request: ProductRevisionListRequest): Promise<ProductRevisionListResponse> | Observable<ProductRevisionListResponse>;
}

export class ProductRevisionListRequest extends CrudListRequestDto<ProductRevisionDto> {
    @ApiModelProperty({
        description: 'Product Id',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;
}

export class ProductRevisionListResponse extends CrudListResponseDto<ProductRevisionDto> {
    @ApiModelProperty({
        description: 'Revisions',
        type: ProductRevisionDto,
        isArray: true,
    })
    items: Array<ProductRevisionDto>;
}
