import { ProductCardParamsHelper } from './product-card/product-card-params.helper';
import { ProductCardHelper } from './product-card.helper';
import { ProductCardReviewHelper } from './product-card/product-card-review.helper';
import { ProductCardBasketHelper } from './product-card/product-card-basket.helper';
import { ProductCardSource } from './product-card.source';
import { ProductCardFavoritesHelper } from './product-card/product-card-favorites.helper';
import { ProductCardCompareHelper } from './product-card/product-card-compare.helper';
import { ProductCardPriceHelper } from './product-card/product-card-price.helper';
import { ProductCardQuestionHelper } from './product-card/product-card-question.helper';
import { ProductCardDeliveryHelper } from './product-card/product-card-delivery.helper';

export const PRODUCT_CARD_HELPER_PROVIDERS = [
    ProductCardHelper,
    ProductCardSource,
    ProductCardParamsHelper,
    ProductCardReviewHelper,
    ProductCardQuestionHelper,
    ProductCardBasketHelper,
    ProductCardFavoritesHelper,
    ProductCardDeliveryHelper,
    ProductCardCompareHelper,
    ProductCardPriceHelper,
];
