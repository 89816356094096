import { NgModule } from '@angular/core';
import { WsSliderComponent } from './ws-slider.component';
import { WsSliderThumbComponent } from './components/ws-slider-thumb/ws-slider-thumb.component';
import { WsBaseSliderDirective } from './directives/base-slider.directive';
import { WsRangeSliderDirective } from './directives/range-slider.directive';

@NgModule({
  imports: [
    WsSliderComponent,
    WsSliderThumbComponent,
    WsBaseSliderDirective,
    WsRangeSliderDirective,
  ],
  exports: [
    WsSliderComponent,
    WsSliderThumbComponent,
    WsBaseSliderDirective,
    WsRangeSliderDirective,
  ]
})
export class WsSliderModule {}