import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { getChildProductCategoryIds, getRootProductCategoryId, initialLocalSessionDTO, LOCAL_SESSION_DOCUMENT_COOKIE, LOCAL_SESSION_DTO_CURRENT_DTO_VERSION, LOCAL_SESSION_DTO_MAX_BASKET_SIZE, LocalSessionDTO, ProductBasketErrorCodes, ProductCategoryListDto, ProductSearchResponse, ProductSearchShared } from '@interid/interid-site-shared';
import { AppSessionAccountProfile, AppSessionAccountSettings, AppSessionAddToBasketRequest, AppSessionAddToComparesRequest, AppSessionAddToFavoritesRequest, AppSessionBasket, AppSessionBasketEntry, AppSessionListFavoritesCategory, AppSessionListFavoritesRequest, AppSessionListFavoritesResponse, AppSessionRemoveComparesRequest, AppSessionRemoveFavoritesRequest, AppSessionRemoveFromBasketRequest, AppSessionSetAccountProfile, AppSessionSetCityRequest, AppSessionStrategy, AppSessionUpdateAccountSettings, AppSessionSetProductBasketRequest, AppSessionListProductsToCompareResponse, AppSessionUpdateCountersRequest, AppSessionSetCatalogPageSize, AppSessionAddToViewedRequest, AppSessionListViewedRequest, AppSessionListViewedResponse } from '../interfaces/app-session-strategy.interface';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { isPlatformBrowser } from '@angular/common';
import { AppBootstrapDataService, AppBusEvent, AppBusService, DocumentCookieService, generateRandomString, LocalStorageService, MessagesService } from '@interid/interid-site-web/core';
import { InteridWebFavoriteDataAccess, InteridWebLocalSessionDataAccess, InteridWebMindboxDataAccess, InteridWebProductBasketDataAccess, InteridWebProductDataAccess, InteridWebViewedDataAccess } from '@interid/interid-site-data-access/web';

const LS_KEY = 'app-session';

type LocalStorageState = LocalSessionDTO;

@Injectable({
    providedIn: 'root',
})
export class LocalStorageAppSessionStrategy implements AppSessionStrategy {
    private destroy$: Subject<void> = new Subject<void>();
    private nextState$: Subject<void> = new Subject<void>();

    private _state$: BehaviorSubject<LocalStorageState> = new BehaviorSubject(initialLocalSessionDTO());

    constructor(private readonly mindboxDataAccess: InteridWebMindboxDataAccess, @Inject(PLATFORM_ID) private platformId: Object, private readonly appBus: AppBusService, private readonly localStorage: LocalStorageService, private readonly messages: MessagesService, private readonly appBootstrap: AppBootstrapDataService, private readonly documentCookieService: DocumentCookieService, private readonly localSessionWebEndpoint: InteridWebLocalSessionDataAccess, private readonly basketWebEndpoint: InteridWebProductBasketDataAccess, private readonly favoriteWebEndpoint: InteridWebFavoriteDataAccess, private readonly viewedWebEndpoint: InteridWebViewedDataAccess, private readonly productsEndpoint: InteridWebProductDataAccess) {}

    init(): void {
        this.bootstrap();

        this._state$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((next) => {
            if (![15, 44, 60].some((x) => x == next.catalogPageSize)) {
                next = {
                    ...next,
                    catalogPageSize: 44,
                };
            }
            if (next.compares.some((c) => isNaN(c))) {
                next = {
                    ...next,
                    compares: [],
                };
            }

            this.localStorage.set(LS_KEY, JSON.stringify(next));
        });

        if (isPlatformBrowser(this.platformId)) {
            if (!this.documentCookieService.check(LOCAL_SESSION_DOCUMENT_COOKIE)) {
                this.documentCookieService.set(LOCAL_SESSION_DOCUMENT_COOKIE, generateRandomString(32), new Date().getTime() + 365 * 24 * 60 * 60 * 1000);
                this.syncWithSSR();
            }
        }

        this.appBus.events$.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            switch (event.type) {
                case AppBusEvent.DocumentVisible: {
                    this.bootstrap();

                    break;
                }
            }
        });

        this.appBus.events$.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            switch (event.type) {
                case AppBusEvent.SignedIn:
                case AppBusEvent.SignedOut: {
                    this.clear();

                    break;
                }

                case AppBusEvent.OrderCreated: {
                    this.state = {
                        ...this.state,
                        basket: {
                            ...this.state.basket,
                            entries: this.state.basket.entries
                                .map((p) => {
                                    const found = event.payload.response.productsPurchased.find((pp) => pp.productId === p.product.id);

                                    if (found) {
                                        return {
                                            ...p,
                                            amount: p.amount - found.amount,
                                            price: p.price,
                                        };
                                    } else {
                                        return p;
                                    }
                                })
                                .filter((p) => p.amount > 0),
                        },
                    };
                }
            }
        });
    }

    destroy(): void {
        this.destroy$.next();
        this.nextState$.next();
        this._state$.next(initialLocalSessionDTO());
    }

    bootstrap(): void {
        const cached = this.localStorage.get(LS_KEY);

        if (cached) {
            try {
                const parsed: LocalStorageState = JSON.parse(cached);

                if (parsed.DTO_VERSION === LOCAL_SESSION_DTO_CURRENT_DTO_VERSION) {
                    this._state$.next(parsed);
                } else {
                    this._state$.next(initialLocalSessionDTO());
                }
            } catch (err) {
                console.warn('Failed to parse Local Storage session');
                console.warn(err);
            }
        }
    }

    clear(): void {
        this._state$.next(initialLocalSessionDTO());

        if (isPlatformBrowser(this.platformId)) {
            if (!this.documentCookieService.check(LOCAL_SESSION_DOCUMENT_COOKIE)) {
                this.documentCookieService.delete(LOCAL_SESSION_DOCUMENT_COOKIE);
            }
        }
    }

    get accountSettings$(): Observable<AppSessionAccountSettings> {
        return this.state$.pipe(map((next) => next.settings));
    }

    get state$(): Observable<LocalStorageState> {
        return this._state$.asObservable();
    }

    get state(): LocalStorageState {
        return this._state$.getValue();
    }

    set state(newState: LocalStorageState) {
        this._state$.next(newState);
        this.syncWithSSR();
    }

    listViewed(request: AppSessionListViewedRequest): Observable<AppSessionListViewedResponse> {
        const allViewed = this.state.viewed;

        const productCategoriesStats = () => {
            const stats: { [categoryId: number]: AppSessionListFavoritesCategory } = {};

            for (const viewed of allViewed) {
                const categoryId = getRootProductCategoryId(viewed.category, this.appBootstrap.data.productCategories);

                if (!stats[categoryId]) {
                    stats[categoryId] = {
                        category: this.appBootstrap.data.productCategories.find((pc) => pc.id === categoryId),
                        total: 0,
                    };
                }

                stats[categoryId].total++;
            }

            const statsArr = Object.values(stats);

            statsArr.sort((a, b) => a.category.sortOrder - b.category.sortOrder);

            return statsArr;
        };

        const emptyResponse = () => {
            const response: AppSessionListViewedResponse = {
                request,
                total: allViewed.length,
                products: [],
                productCategories: productCategoriesStats(),
            };

            return of(response);
        };

        if (request.offset > allViewed.length) {
            return emptyResponse();
        }

        let selectedViewed = [...allViewed];

        if (request.category) {
            const category = this.appBootstrap.data.productCategories.filter((pc) => !pc.parent).find((pc) => pc.slug === request.category);

            const childCategoryIds = getChildProductCategoryIds(category.id, this.appBootstrap.data.productCategories);

            if (category) {
                selectedViewed = selectedViewed.filter((viewed) => childCategoryIds.includes(viewed.category));
            } else {
                return emptyResponse();
            }
        }

        const viewSelectedViewed = _.first(_.rest(selectedViewed, request.offset), request.limit);

        if (viewSelectedViewed.length === 0) {
            return emptyResponse();
        }

        return this.viewedWebEndpoint
            .listProducts({
                productIds: viewSelectedViewed.map((viewed) => viewed.id),
            })
            .pipe(
                map((response) => {
                    return {
                        request,
                        total: selectedViewed.length,
                        products: response,
                        productCategories: productCategoriesStats(),
                    };
                })
            );
    }

    destroyViewed(): Observable<void> {
        this.state = {
            ...this.state,
            viewed: [],
        };

        return of(undefined);
    }

    addToViewed(request: AppSessionAddToViewedRequest): Observable<void> {
        this.state = {
            ...this.state,
            viewed: _.uniq(
                [
                    ...(this.state.viewed ?? []),
                    {
                        id: request.productId,
                        category: request.productCategoryId,
                    },
                ],
                (p) => p.id
            ),
        };

        return of(null);
    }

    syncWithSSR(): void {
        const sessionId = this.documentCookieService.get(LOCAL_SESSION_DOCUMENT_COOKIE);

        if (sessionId) {
            this.nextState$.next();

            this.localSessionWebEndpoint
                .set({
                    id: sessionId,
                    session: this.state,
                })
                .pipe(takeUntil(this.nextState$))
                .subscribe();
        }
    }

    getAccountProfile(): Observable<AppSessionAccountProfile> {
        return this.state$.pipe(map((state) => state.profile));
    }

    updateAccountProfile(request: AppSessionSetAccountProfile): Observable<void> {
        this.state = {
            ...this.state,
            profile: {
                ...this.state.profile,
                ...request,
            },
        };

        return of(undefined);
    }

    getAccountSettings(): AppSessionAccountSettings {
        return this.state.settings;
    }

    getAccountSettings$(): Observable<AppSessionAccountSettings> {
        return this.state$.pipe(map((state) => state.settings));
    }

    updateAccountSettings(request: AppSessionUpdateAccountSettings): Observable<void> {
        this.state = {
            ...this.state,
            settings: {
                ...this.state.settings,
                ...request,
            },
        };

        return of(undefined);
    }

    get numFavorites$(): Observable<number> {
        return this.state$.pipe(
            map((next) => next.favorites.length),
            distinctUntilChanged()
        );
    }

    addToFavorites(request: AppSessionAddToFavoritesRequest): Observable<void> {
        this.state = {
            ...this.state,
            favorites: _.uniq([
                ...this.state.favorites,
                {
                    id: request.productId,
                    category: request.productCategoryId,
                },
            ]),
        };

        this.mindboxDataAccess
            .request({
                operation: 'SetIzbrannoeItemList',
                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                body: {
                    productList: this.state.favorites.map((x) => {
                        return {
                            product: {
                                ids: {
                                    website: x.id,
                                },
                            },
                            count: 1,
                            pricePerItem: 0,
                        };
                    }),
                },
            })
            .toPromise()
            .then();

        return of(null);
    }

    deleteFromFavorites(request: AppSessionRemoveFavoritesRequest): Observable<void> {
        this.state = {
            ...this.state,
            favorites: this.state.favorites.filter((favorite) => favorite.id !== request.productId),
        };

        this.mindboxDataAccess
            .request({
                operation: 'SetIzbrannoeItemList',
                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                body: {
                    productList: this.state.favorites.map((x) => {
                        return {
                            product: {
                                ids: {
                                    website: x.id,
                                },
                            },
                            count: 1,
                            pricePerItem: 0,
                        };
                    }),
                },
            })
            .toPromise()
            .then();

        return of(null);
    }

    listFavorites(request: AppSessionListFavoritesRequest): Observable<AppSessionListFavoritesResponse> {
        const allFavorites = this.state.favorites;

        const productCategoriesStats = () => {
            const stats: { [categoryId: number]: AppSessionListFavoritesCategory } = {};

            for (const favorite of allFavorites) {
                const categoryId = getRootProductCategoryId(favorite.category, this.appBootstrap.data.productCategories);

                if (!stats[categoryId]) {
                    stats[categoryId] = {
                        category: this.appBootstrap.data.productCategories.find((pc) => pc.id === categoryId),
                        total: 0,
                    };
                }

                stats[categoryId].total++;
            }

            const statsArr = Object.values(stats);

            statsArr.sort((a, b) => a.category.sortOrder - b.category.sortOrder);

            return statsArr;
        };

        const emptyResponse = () => {
            const response: AppSessionListFavoritesResponse = {
                request,
                total: allFavorites.length,
                products: [],
                productCategories: productCategoriesStats(),
            };

            return of(response);
        };

        if (request.offset > allFavorites.length) {
            return emptyResponse();
        }

        let selectedFavorites = [...allFavorites];

        if (request.category) {
            const category = this.appBootstrap.data.productCategories.filter((pc) => !pc.parent).find((pc) => pc.slug === request.category);

            const childCategoryIds = getChildProductCategoryIds(category.id, this.appBootstrap.data.productCategories);

            if (category) {
                selectedFavorites = selectedFavorites.filter((favorite) => childCategoryIds.includes(favorite.category));
            } else {
                return emptyResponse();
            }
        }

        const viewSelectedFavorites = _.first(_.rest(selectedFavorites, request.offset), request.limit);

        if (viewSelectedFavorites.length === 0) {
            return emptyResponse();
        }

        return this.favoriteWebEndpoint
            .listProducts({
                productIds: viewSelectedFavorites.map((favorite) => favorite.id),
            })
            .pipe(
                map((response) => {
                    return {
                        request,
                        total: selectedFavorites.length,
                        products: response,
                        productCategories: productCategoriesStats(),
                    };
                })
            );
    }

    destroyFavorites(): Observable<void> {
        this.state = {
            ...this.state,
            favorites: [],
        };

        this.mindboxDataAccess
            .request({
                operation: 'SetIzbrannoeItemList',
                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                body: {
                    productList: [],
                },
            })
            .toPromise()
            .then();

        return of(undefined);
    }

    get numCompares$(): Observable<number> {
        return this.state$.pipe(
            map((next) => next.compares.length),
            distinctUntilChanged()
        );
    }

    addToCompares(request: AppSessionAddToComparesRequest): Observable<void> {
        this.state = {
            ...this.state,
            compares: _.uniq([...this.state.compares.map((x) => x), request.productId]),
        };

        this.mindboxDataAccess
            .request({
                operation: 'SetSravnenieItemList',
                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                body: {
                    productList: this.state.favorites.map((x) => {
                        return {
                            product: {
                                ids: {
                                    website: x.id,
                                },
                            },
                            count: 1,
                            pricePerItem: 0,
                        };
                    }),
                },
            })
            .toPromise()
            .then();

        return of(null);
    }

    deleteFromCompares(request: AppSessionRemoveComparesRequest): Observable<void> {
        this.state = {
            ...this.state,
            compares: this.state.compares.filter((product) => product !== request.productId),
        };

        this.mindboxDataAccess
            .request({
                operation: 'ResetSravnenieItemList',
                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                body: {},
            })
            .toPromise()
            .then();

        return of(null);
    }

    listProductsToCompare(): Observable<AppSessionListProductsToCompareResponse> {
        const products = [...this.state.compares];

        if (products.length > 0) {
            const observables: [Observable<ProductSearchResponse>] = [
                this.productsEndpoint.search({
                    shape: ProductSearchShared.Shape.WebProductCard,
                    filters: {
                        ids: products,
                    },
                    view: {
                        limit: products.length,
                    },
                }),
            ];

            return forkJoin(observables).pipe(
                map(([searchResponse]) => {
                    const products = searchResponse.products;
                    const categories: Array<ProductCategoryListDto> = _.uniq(
                        products.map((p) => p.productCategory),
                        (pc) => pc.id
                    );

                    return {
                        categories,
                        products,
                        items: this.state.compares,
                    };
                }),
                takeUntil(this.destroy$)
            );
        } else {
            return of({
                categories: [],
                products: [],
                items: [],
            });
        }
    }

    setCity(request: AppSessionSetCityRequest): Observable<void> {
        this.state = {
            ...this.state,
            selectedCity: request.cityId,
        };

        return of(null);
    }

    hasSelectedCity$(): Observable<boolean> {
        return this._state$.pipe(map((next) => !!next.selectedCity));
    }

    getSelectedCityId$(): Observable<number | undefined> {
        return this._state$.pipe(map((next) => next.selectedCity));
    }

    hasSelectedCity(): boolean {
        return !!this.state.selectedCity;
    }

    getSelectedCityId(): number {
        if (this.hasSelectedCity()) {
            return this.state.selectedCity;
        } else {
            return -1;
        }
    }

    isSubscribed$(): Observable<boolean> {
        return this._state$.pipe(map((data) => data.isSubscribed));
    }

    markAccountAsSubscribed(): void {
        this.state = {
            ...this.state,
            isSubscribed: true,
        };
    }

    markAccountAsUnsubscribed(): void {
        this.state = {
            ...this.state,
            isSubscribed: false,
        };
    }

    isCookiePolicyAccepted$(): Observable<boolean> {
        return this._state$.pipe(map((data) => data.isCookiePolicyAccepted));
    }

    markCookiePolicyAccepted(): void {
        this.state = {
            ...this.state,
            isCookiePolicyAccepted: true,
        };
    }

    get numProductsInBasket$(): Observable<number> {
        return this.state$.pipe(
            map((next) => next.basket.entries),
            distinctUntilChanged(),
            map((next) => {
                let result = 0;

                for (const product of next) {
                    result += product.amount;
                }

                return result;
            })
        );
    }

    addProductToBasket(request: AppSessionAddToBasketRequest): Observable<AppSessionBasketEntry> {
        if (this.state.basket && this.state.basket.entries.length > LOCAL_SESSION_DTO_MAX_BASKET_SIZE) {
            this.messages.error({
                message: '__errors.ProductBasketIsTooBig',
                translate: true,
            });

            return throwError(ProductBasketErrorCodes.ProductBasketIsTooBig);
        }

        return this.basketWebEndpoint.getProductEntryDto({ productId: request.productId }).pipe(
            map((product) => {
                const existingEntity = this.state.basket.entries.find((e) => e.product.id === request.productId);

                if (existingEntity) {
                    existingEntity.amount = existingEntity.amount + request.amount;
                    existingEntity.price = product.price;
                    existingEntity.product = product;

                    this.state = {
                        ...this.state,
                        basket: {
                            ...this.state.basket,
                            entries: this.state.basket.entries.map((e) => (e.product.id === request.productId ? { ...existingEntity } : e)),
                        },
                    };

                    this.mindboxDataAccess
                        .request({
                            operation: 'Website.SetCart',
                            uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                            body: {
                                productList: this.state.basket.entries.map((x) => {
                                    return {
                                        product: {
                                            ids: {
                                                website: x.product.id,
                                            },
                                        },
                                        count: x.amount,
                                        pricePerItem: x.price,
                                    };
                                }),
                            },
                        })
                        .toPromise()
                        .then();

                    return existingEntity;
                } else {
                    const entity: AppSessionBasketEntry = {
                        product,
                        price: product.price,
                        amount: request.amount,
                    };

                    this.state = {
                        ...this.state,
                        basket: {
                            ...this.state.basket,
                            entries: [...this.state.basket.entries, entity],
                        },
                    };

                    this.mindboxDataAccess
                        .request({
                            operation: 'Website.SetCart',
                            uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                            body: {
                                productList: this.state.basket.entries.map((x) => {
                                    return {
                                        product: {
                                            ids: {
                                                website: x.product.id,
                                            },
                                        },
                                        count: x.amount,
                                        pricePerItem: x.price,
                                    };
                                }),
                            },
                        })
                        .toPromise()
                        .then();

                    return entity;
                }
            })
        );
    }

    removeProductFromBasket(request: AppSessionRemoveFromBasketRequest): Observable<void> {
        this.state = {
            ...this.state,
            basket: {
                ...this.state.basket,
                entries: this.state.basket.entries.filter((e) => e.product.id !== request.productId),
            },
        };

        this.mindboxDataAccess
            .request({
                operation: 'Website.SetCart',
                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                body: {
                    productList: this.state.basket.entries.map((x) => {
                        return {
                            product: {
                                ids: {
                                    website: x.product.id,
                                },
                            },
                            count: x.amount,
                            pricePerItem: x.price,
                        };
                    }),
                },
            })
            .toPromise()
            .then();

        return of(undefined);
    }

    clearProductBasket(): Observable<void> {
        this.state = {
            ...this.state,
            basket: {
                entries: [],
            },
        };

        this.mindboxDataAccess
            .request({
                operation: 'Website.ClearCart',
                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                body: {
                    executionDateTimeUtc: new Date().toISOString(),
                },
            })
            .toPromise()
            .then();

        return of(undefined);
    }

    setProductBasket(request: AppSessionSetProductBasketRequest): Observable<AppSessionBasketEntry | void> {
        if (request.setAmount === 0) {
            return this.removeProductFromBasket({
                productId: request.productId,
            });
        } else {
            const entry = this.state.basket.entries.find((e) => e.product.id === request.productId);

            if (!entry) {
                return this.addProductToBasket({
                    productId: request.productId,
                    amount: request.setAmount,
                });
            } else {
                if (request.setAmount) {
                    entry.amount = request.setAmount;
                }

                this.state = {
                    ...this.state,
                    basket: {
                        ...this.state.basket,
                        entries: this.state.basket.entries.map((e) => {
                            return e.product.id === request.productId ? { ...entry } : e;
                        }),
                    },
                };

                this.mindboxDataAccess
                    .request({
                        operation: 'Website.SetCart',
                        uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                        body: {
                            productList: this.state.basket.entries.map((x) => {
                                return {
                                    product: {
                                        ids: {
                                            website: x.product.id,
                                        },
                                    },
                                    count: x.amount,
                                    pricePerItem: x.price,
                                };
                            }),
                        },
                    })
                    .toPromise()
                    .then();

                return of(entry);
            }
        }
    }

    getProductBasket(): Observable<AppSessionBasket> {
        return this._state$.pipe(
            filter((response) => !!response && !!response.basket && Array.isArray(response.basket.entries)),
            take(1),
            map((response) => response.basket)
        );
    }

    updateCounters(request: AppSessionUpdateCountersRequest): void {
        this.init();
    }

    setCatalogPageSize(request: AppSessionSetCatalogPageSize): Observable<void> {
        this.state = {
            ...this.state,
            catalogPageSize: request.catalogPageSize,
        };

        return of(undefined);
    }
}
