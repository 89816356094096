import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AboutCompanyBanner, ContentDTO } from './common-content.shared';
import { AttachmentDto } from '../entities/attachment.dto';

export namespace PagesShared {
    export enum Page {
        Contacts = 'Contacts',
        Delivery = 'Delivery',
        AboutCompany = 'AboutCompany',
        Requisites = 'Requisites',
        TradePosts = 'TradePosts',
        PrivacyAgreement = 'PrivacyAgreement',
        Vacancies = 'Vacancies',
        HowMakerOrder = 'HowMakerOrder',
    }

    export class ContactsPage {
        @ApiModelProperty({
            type: () => PagesContactShared.Block,
            isArray: true,
        })
        blocks: Array<PagesContactShared.Block>;

        @ApiModelProperty()
        telegram: string;

        @ApiModelProperty()
        whatsapp: string;

        @ApiModelProperty()
        viber: string;
    }

    export class DeliveryPage {
        @ApiModelProperty({
            type: () => ContentDTO,
        })
        contents: ContentDTO;
    }

    export class AboutCompanyPage {
        @ApiModelProperty({
            type: () => ContentDTO,
        })
        contents: ContentDTO;

        @ApiModelProperty({
            type: () => AboutCompanyBanner,
            required: false
        })
        banner?: AboutCompanyBanner;
    }
 
    export class RequisitesPage {
        @ApiModelProperty({
            type: () => ContentDTO,
        })
        contents: ContentDTO;
    }

    export class TradePostsPage {
        @ApiModelProperty({
            type: () => ContentDTO,
        })
        contents: ContentDTO;
    }

    export class PrivacyAgreementPage {
        @ApiModelProperty({
            type: () => ContentDTO,
        })
        contents: ContentDTO;
    }

    export class VacanciesPage {
        @ApiModelProperty({
            type: () => ContentDTO,
        })
        contents: ContentDTO;
    }
}

export namespace PagesContactShared {
    export class BlockEntry {
        @ApiModelProperty({
            nullable: true,
        })
        title?: string;

        @ApiModelProperty({
            nullable: true,
        })
        description?: string;

        @ApiModelProperty({
            nullable: true,
        })
        contactName?: string;
    }

    export class Block {
        @ApiModelProperty()
        title: string;

        @ApiModelProperty()
        addresses: Array<BlockEntry>;

        @ApiModelProperty()
        phones: Array<BlockEntry>;

        @ApiModelProperty()
        emails: Array<BlockEntry>;

        @ApiModelProperty()
        schedule: string;

        @ApiModelProperty()
        mapHtmlCode: string;

        @ApiModelProperty()
        drivingDirections?: string;

        @ApiModelProperty()
        drivingDirectionsDescription?: string;

        @ApiModelProperty()
        drivingDirectionsImage?: AttachmentDto;
    }
}
