export function moneyPipe(value: any): string {
    const ceil = Math.abs(Math.ceil(parseFloat(value))).toString();
    const sign = Math.ceil(parseFloat(value)) >= 0
        ? ''
        : '-';

    const sections = ceil.match(/(\d+?)(?=(\d{3})+(?!\d)|$)/g);

    return `${sign}${(sections || []).join(' ')}`;
}
