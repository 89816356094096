<div class='c'>
  <table>
    <tbody>
      @for (row of payload().data; track row) {
        <tr>
          <td class='row row-1'><span>{{ row[0] }}</span></td>
          <td class='row row-2'><span>{{ row[1] }}</span></td>
        </tr>
      }
    </tbody>
    @if (!! payload().footer) {
      <tfoot>
        <tr>
          <td colspan='2'><span>{{ payload().footer }}</span></td>
        </tr>
      </tfoot>
    }
  </table>
</div>
