import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '../core/crud';
import { ParamsCategoryDto, ParamsCategoryListDto } from '../models/entities/params-category.dto';
import { ParamsDto } from '../models/entities/params.dto';

export enum ParamsCategoryEndpointPaths {
    createParamsCategory = '/api/v1/params-category/create',
    editParamsCategory = '/api/v1/params-category/edit',
    deleteParamsCategory = '/api/v1/params-category/delete',
    deleteParamsCategoryParams = '/api/v1/params-category-params/delete',
    listParamsCategories = '/api/v1/params-category/list',
    listParamsCategoriesParams = '/api/v1/params-category-params/list',
    getParamsCategory = '/api/v1/params-category/get',
    setSortOrder = '/api/v1/params-category/setSortOrder',
    setSortOrderParams = '/api/v1/params-category-params/setSortOrder',
}

export interface ParamsCategoryEndpoint {
    createParamsCategory(request: CreateParamsCategoryRequest): Promise<ParamsCategoryDto> | Observable<ParamsCategoryDto>;
    editParamsCategory(request: EditParamsCategoryRequest): Promise<ParamsCategoryDto> | Observable<ParamsCategoryDto>;
    deleteParamsCategory(request: DeleteParamsCategoryRequest): Promise<void> | Observable<void>;
    deleteParamsCategoryParams(request: DeleteParamsCategoryParamsRequest): Promise<void> | Observable<void>;
    listParamsCategories(): Promise<ParamsCategoryListAllResponse> | Observable<ParamsCategoryListAllResponse>;
    listParamsCategoriesParams(request: ListParamsCategoryParamsRequest): Promise<ListParamsCategoryParamsResponse> | Observable<ListParamsCategoryParamsResponse>;
    getParamsCategory(request: GetParamsCategoryRequest): Promise<ParamsCategoryDto> | Observable<ParamsCategoryDto>;
    setSortOrder(request: SetSortOrderParamsCategoryRequest): Promise<void> | Observable<void>;
}

export class CreateParamsCategoryRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsInt()
    @IsPositive()
    @IsOptional()
    sortOrder?: number | undefined;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;
}

export class EditParamsCategoryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    sortOrder?: number | undefined;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;
}

export class DeleteParamsCategoryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteParamsCategoryParamsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    paramsId: number;
}

export class ListParamsCategoryParamsRequest extends CrudListRequestDto<ParamsDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    paramsCategoryId: number;
}

export class ListParamsCategoryParamsResponse extends CrudListResponseDto<ParamsDto> {
    @ApiModelProperty({
        type: () => ParamsDto,
        isArray: true,
    })
    items: Array<ParamsDto>;
}

export type ListParamsCategoryResponse = Array<ParamsCategoryDto>;

export class GetParamsCategoryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderParamsCategoryRequest extends CrudSetSortOrderRequestDto {}


export type ParamsCategoryListAllResponse = Array<ParamsCategoryListDto>;

export class SetSortOrderParamsCategoryParamsResponse extends CrudSetSortOrderResponseDto<ParamsDto> {
    @ApiModelProperty({
        type: () => ParamsDto,
    })
    entity: ParamsDto;

    @ApiModelProperty({
        type: () => ParamsDto,
        isArray: true,
    })
    affected: Array<ParamsDto>;
}
