import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { AppErrorHandlerService, DocumentCookieService } from '@interid/interid-site-web/core';
import { InteridWebLocalSessionDataAccess } from '@interid/interid-site-data-access/web';
import { LocalStorageAppSessionStrategy } from '../strategies/local-storage.app-session-strategy';
import { LOCAL_SESSION_DOCUMENT_COOKIE } from '@interid/interid-site-shared';

@Injectable({
    providedIn: 'root',
})
export class LocalSessionResolve  {
    constructor(
        private readonly appErrorHandler: AppErrorHandlerService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly endpoint: InteridWebLocalSessionDataAccess,
        private readonly documentCookie: DocumentCookieService,
        private readonly localStorageAppSessionStrategy: LocalStorageAppSessionStrategy,
    ) {
    }

    resolve(): Observable<void> | Promise<void> | void {
        if (isPlatformServer(this.platformId)) {
            const sessionId = this.documentCookie.get(LOCAL_SESSION_DOCUMENT_COOKIE);

            if (sessionId) {
                return this.endpoint.get({
                    id: sessionId,
                }).pipe(
                    tap((response) => {
                        this.localStorageAppSessionStrategy.state = response.session;
                    }),
                    map(() => undefined),
                    this.appErrorHandler.pipe({
                        withNavigation: false,
                    }),
                );
            }
        }
    }
}
