import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString } from 'class-validator';

export enum VacanciesRequestWebEndpointPaths {
    send = '/api/v1/web/vacancies-request/send',
}

export interface VacanciesRequestWebEndpoint {
    send(file: File | any, request: VacanciesRequestSendRequest, ...args: any): Promise<void> | Observable<void>;
}

export class VacanciesRequestSendRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    phone: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    id: number;
}
