import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO, SourceDefinition } from '../shared/common.shared';

export class ProductCategoryLinkDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  isEnabled: boolean;

  @ApiModelProperty()
  categoryId: number;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  definition: SourceDefinition;

  @ApiModelProperty({
    type: () => PageMetadataSEO,
  })
  seo: PageMetadataSEO;
}
