
<div class="c" [formGroup]="state().form">
  <div class="c-title"><span>{{ t('title') | translate }}</span></div>
  <div class="c-form">
    <div class="c-form-input">
      <app-shared-ui-input formControlName="phone" leftIcon="phone" placeholder="{{ t('phone') | translate }}"></app-shared-ui-input>
    </div>
    <div class="c-form-submit">
      <app-shared-ui-button (click)="ngSubmit()" [disabled]="state().form.disabled || state().form.invalid"><span>{{ t('submit') | translate }}</span></app-shared-ui-button>
    </div>
    <div class="c-privacy"><span>{{ t('privacy') | translate }}</span></div>
  </div>
</div>
