import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import * as url from 'url';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PaginatorState } from './paginator.state';
import { LinkService, ResolvedRouteService } from '@interid/interid-site-web/core';
import { routerLinkDefinitionToHref, UrlBuilderRouterLinkDefinition, withoutTrailingSlash } from '@interid/interid-site-shared';
import { Request } from 'express';
import { REQUEST } from '@interid/interid-site-web/ui-shared';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class PaginatorService {
    private _state$: BehaviorSubject<PaginatorState> = new BehaviorSubject<PaginatorState>({
        page: 1,
        pageSize: 1,
        total: 0,
        shouldFollowUrl: false,
    });

    constructor(
        private readonly router: Router,
        private readonly links: LinkService,
        private readonly resolvedRoute: ResolvedRouteService,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Optional() @Inject(REQUEST) protected request: Request
    ) {
    }

    get state$(): Observable<PaginatorState> {
        return this._state$.asObservable();
    }

    get state(): PaginatorState {
        return this._state$.getValue();
    }

    set state(newState: PaginatorState) {
        this._state$.next(newState);
    }

    get maxPage(): number {
        return Math.ceil(this.state.total / this.state.pageSize);
    }

    setupRelPrevNextAndCanonical(): void {
        const hasCanonicalFromResolved = !! this.resolvedRoute.current
            && !! this.resolvedRoute.current.seo
            && !! this.resolvedRoute.current.seo.canonical
            && !! this.resolvedRoute.current.seo.canonical.uri;

        if (hasCanonicalFromResolved) {
            return;
        }

        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.links.deleteAllTagsOfRel('canonical');
        this.links.deleteAllTagsOfRel('prev');
        this.links.deleteAllTagsOfRel('next');

        if (this.state.canonicalUrlGenerator && ! (this.resolvedRoute.current && this.resolvedRoute.current.canonicalUrl)) {
            this.state.canonicalUrlGenerator().pipe(
                take(1),
                map((routerLink) => routerLinkDefinitionToHref(routerLink)),
                takeUntil(this.router.events.pipe(filter((e) => e instanceof NavigationStart))),
            ).subscribe((href) => {
                const parsed = url.parse(href);

                this.links.addTag({
                    rel: 'canonical',
                    href: `https://${hostname}/${withoutTrailingSlash(parsed.pathname)}`,
                });
            });
        }

        if (this.state.page > 1) {
            this.state.urlGenerator(this.state.page - 1).pipe(
                take(1),
                map((routerLink) => routerLinkDefinitionToHref(routerLink)),
                takeUntil(this.router.events.pipe(filter((e) => e instanceof NavigationStart))),
            ).subscribe((href) => {
                this.links.addTag({
                    rel: 'prev',
                    href: `/${withoutTrailingSlash(href)}`,
                });
            });
        }

        if (this.state.page < this.maxPage) {
            this.state.urlGenerator(this.state.page + 1).pipe(
                take(1),
                map((routerLink) => routerLinkDefinitionToHref(routerLink)),
                takeUntil(this.router.events.pipe(filter((e) => e instanceof NavigationStart))),
            ).subscribe((href) => {
                this.links.addTag({
                    rel: 'next',
                    href: `/${withoutTrailingSlash(href)}`,
                });
            });
        }
    }

    get canGoPrevPage(): boolean {
        return this.state.page > 1;
    }

    get canGoNextPage(): boolean {
        return this.state.page < this.maxPage;
    }

    routerLink$(page: number): Observable<UrlBuilderRouterLinkDefinition> {
        if (this.state.urlGenerator) {
            return this.state.urlGenerator(page);
        } else {
            const parsed = url.parse(this.router.url);

            return of({
                route: [parsed.pathname],
                queryParams: {
                    page,
                },
            });
        }
    }

    get routerLinkPrevPage$(): Observable<UrlBuilderRouterLinkDefinition> {
        if (this.canGoPrevPage) {
            return this.routerLink$(this.state.page - 1);
        } else {
            return of({
                route: '#',
            });
        }
    }

    get routerLinkNextPage$(): Observable<UrlBuilderRouterLinkDefinition> {
        if (this.canGoNextPage) {
            return this.routerLink$(this.state.page + 1);
        } else {
            return of({
                route: '#',
            });
        }
    }
}
