import { IsInt, IsOptional, IsPositive } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { BillDto } from '../models/entities/bill.dto';

export enum BillWebEndpointPaths {
    list = '/api/v1/web/bill/list',
}

export interface BillWebEndpoint {
    list(request: BillListWebRequest, req?: any): Promise<BillListWebResponse> | Observable<BillListWebResponse>;
}

export class BillListWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;
}

export class BillListWebResponse {
    @ApiModelProperty({
        type: BillDto,
        isArray: true,
    })
    bills: Array<BillDto>;

    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty()
    total: number;
}
