export const interidSiteWebFeatureProductReviewsI18n = {
    productReviews: {
        components: {
            productReviews: {
                title: 'Отзывы',
                reviews: 'Отзывы',
                noReviews: 'В этой категории еще нет отзывов на товары',
                ratingTotals: {
                    one: 'Рейтинг: {{ avgRating }} из 5',
                    many: 'Рейтинг: {{ avgRating }} из 5 на основе {{ totalRatings }} отзывов',
                },
                readMoreReviews: 'Все отзывы',
                seo: {
                    h1: 'Отзывы – {{ title }}',
                    title: 'Отзывы – {{ title }} – Интер АйДи',
                    description: 'Прочитайте отзывы покупателей о {{ totalProducts }}  моделях в категории "{{ title }}". Узнайте преимущества и недостатки товаров, о качестве изготовления и удобстве использования на основе реального опыта владельцев.',
                },
            },
        },
    },
};
