@if (service.maxPage > 1) {
  <div class="c">
    <div class="c-center">
      @if (state().layout === 'all') {
        <ng-container *ngTemplateOutlet="pagesTemplate; context: { pages: state().all }"></ng-container>
      }
      @if (state().layout === 'many-pages') {
        <div class="c-center-current">
          @if (! service.canGoPrevPage) {
            <div class="c-button c-prev">
              <button (click)="goPrevPage($event)" [disabled]="! service.canGoPrevPage" matRipple="matRipple"
                [matRippleDisabled]="! service.canGoPrevPage">
                <div>
                  <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.972.634.456 7.487a1.602 1.602 0 0 0-.337.52 1.652 1.652 0 0 0 .337 1.748l6.516 6.772c.143.15.313.27.5.35a1.488 1.488 0 0 0 1.682-.35 1.63 1.63 0 0 0 .447-1.126c0-.422-.16-.826-.447-1.126l-5.44-5.654 5.44-5.655a1.652 1.652 0 0 0 .332-1.731 1.604 1.604 0 0 0-.332-.521 1.54 1.54 0 0 0-.488-.368 1.49 1.49 0 0 0-1.694.288Z"
                      fill="#253238" />
                  </svg>
                </div>
              </button>
            </div>
          }
          @if (service.canGoPrevPage) {
            <a class="c-button c-prev"
              [routerLink]="(service.routerLinkPrevPage$ | async).route"
              [queryParams]="(service.routerLinkPrevPage$ | async).queryParams">
              <button (click)="goPrevPage($event)" [disabled]="! service.canGoPrevPage" matRipple="matRipple"
                [matRippleDisabled]="! service.canGoPrevPage">
                <div>
                  <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.972.634.456 7.487a1.602 1.602 0 0 0-.337.52 1.652 1.652 0 0 0 .337 1.748l6.516 6.772c.143.15.313.27.5.35a1.488 1.488 0 0 0 1.682-.35 1.63 1.63 0 0 0 .447-1.126c0-.422-.16-.826-.447-1.126l-5.44-5.654 5.44-5.655a1.652 1.652 0 0 0 .332-1.731 1.604 1.604 0 0 0-.332-.521 1.54 1.54 0 0 0-.488-.368 1.49 1.49 0 0 0-1.694.288Z"
                      fill="#253238" />
                  </svg>
                </div>
              </button></a>
            }
            <ng-container *ngTemplateOutlet="pagesTemplate; context: { pages: state().left }"></ng-container>
            @if (state().center.length === state().layoutOptions.MAX_PAGES_CENTER && state().center[0] - state().left[state().left.length - 1] > state().layoutOptions.MAX_PAGES_LEFT) {
              <div
                class="c-button c-divider ___disable-user-select" #divider (click)="popup()"><span>...</span></div>
              }
              <ng-container *ngTemplateOutlet="pagesTemplate; context: { pages: state().center }"></ng-container>
              <div class="c-button c-divider ___disable-user-select" #divider (click)="popup()"><span>...</span></div>
              <ng-container *ngTemplateOutlet="pagesTemplate; context: { pages: state().right }"></ng-container>
              @if (! service.canGoNextPage) {
                <div class="c-button c-next">
                  <button (click)="goNextPage($event)" [disabled]="! service.canGoNextPage" matRipple="matRipple"
                    [matRippleDisabled]="! service.canGoNextPage">
                    <div>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="m3.028 16.366 6.516-6.853c.144-.148.259-.325.337-.52a1.652 1.652 0 0 0-.337-1.748L3.028.472a1.535 1.535 0 0 0-.5-.35 1.487 1.487 0 0 0-1.182 0 1.55 1.55 0 0 0-.5.35A1.63 1.63 0 0 0 .399 1.6c0 .422.16.826.447 1.126l5.44 5.654-5.44 5.655a1.652 1.652 0 0 0-.332 1.731c.077.195.19.372.332.521.138.155.304.28.488.368a1.49 1.49 0 0 0 1.694-.288Z"
                          fill="#253238" />
                      </svg>
                    </div>
                  </button>
                </div>
              }
              @if (service.canGoNextPage) {
                <a class="c-button c-next"
                  [routerLink]="(service.routerLinkNextPage$ | async).route"
                  [queryParams]="(service.routerLinkNextPage$ | async).queryParams">
                  <button (click)="goNextPage($event)" [disabled]="! service.canGoNextPage" matRipple="matRipple"
                    [matRippleDisabled]="! service.canGoNextPage">
                    <div>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="m3.028 16.366 6.516-6.853c.144-.148.259-.325.337-.52a1.652 1.652 0 0 0-.337-1.748L3.028.472a1.535 1.535 0 0 0-.5-.35 1.487 1.487 0 0 0-1.182 0 1.55 1.55 0 0 0-.5.35A1.63 1.63 0 0 0 .399 1.6c0 .422.16.826.447 1.126l5.44 5.654-5.44 5.655a1.652 1.652 0 0 0-.332 1.731c.077.195.19.372.332.521.138.155.304.28.488.368a1.49 1.49 0 0 0 1.694-.288Z"
                          fill="#253238" />
                      </svg>
                    </div>
                  </button></a>
                }
              </div>
            }
          </div>
        </div>
      }
      <ng-template #pagesTemplate let-pages="pages">@for (page of pages; track page) {
        <a class="c-button c-page ___disable-user-select"
          [ngClass]="{ 'is-active': isActive(page + 1) }"
          [routerLink]="(service.routerLink$(page + 1) | async).route"
          [queryParams]="(service.routerLink$(page + 1) | async).queryParams">
          <button (click)="goPage(page + 1, $event)" matRipple="matRipple"><span>{{ page + 1 }}</span></button></a>
        }
      </ng-template>