import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'ruDateFrom' })
export class RuDateFromPipe implements PipeTransform {
    constructor(
        private readonly i18n: TranslateService,
    ) {
    }

    t(input: string): string {
        return `shared.pipes.ruDateFrom.${input}`;
    }

    transform(value: any): any {
        const date = new Date(value);

        return this.i18n.instant(this.t('format'), {
            day: date.getDay(),
            year: date.getFullYear(),
            month: this.i18n.instant(this.t(`month.${date.getMonth() + 1}`)),
        });
    }
}
