import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { RoistatProductRequestContext } from '../models/shared/roistat.shared';

export enum ProductRequestWebEndpointPaths {
    send = '/api/v1/web/product-request/send',
}

export interface ProductRequestWebEndpoint {
    send(request: ProductRequestSendRequest, ...args: any): Promise<ProductRequestSendResponse> | Observable<ProductRequestSendResponse>;
}

export class ProductRequestSendResponse {
    @ApiModelProperty()
    id?: number;

}

export class ProductRequestSendRequest {
    @ApiModelProperty()
    @IsString()
    @IsOptional()
    createdFromURL: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    phone: string;

    @ApiModelProperty()
    productId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    name?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    email?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    message?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(RoistatProductRequestContext),
        required: false,
    })
    @IsEnum(RoistatProductRequestContext)
    @IsString()
    @IsOptional()
    context?: RoistatProductRequestContext;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    roistat?: string;

    @ApiModelProperty()
    sessionId?: number;
}
