import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

type LoadingResult = undefined | 'success' | 'fail' | 'complete';

export class LoadingHandler {
    private _isFinished = false;

    constructor(
        private readonly subject: BehaviorSubject<LoadingResult>,
    ) {
    }

    get isFinished(): boolean {
        return this._isFinished;
    }

    fail(): void {
        if (! this._isFinished) {
            this.subject.next('fail');
        }

        this._isFinished = true;
    }

    success(): void {
        if (! this._isFinished) {
            this.subject.next('success');
        }

        this._isFinished = true;
    }

    complete(): void {
        if (! this._isFinished) {
            this.subject.next('complete');
        }

        this._isFinished = true;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AppLoadingService {
    private loaders: Array<LoadingHandler> = [];
    private readonly _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    addLoading(): LoadingHandler {
        const subject = new BehaviorSubject<LoadingResult>(undefined);
        const loader = new LoadingHandler(subject);

        this.loaders.push(loader);

        subject.pipe(
            filter((v) => v !== undefined),
            take(1),
        ).subscribe(() => {
            this.loaders = this.loaders.filter((l) => l !== loader);
            this.updateLoadingStatus();
        });

        this.updateLoadingStatus();

        return loader;
    }

    completeAll(): void {
        this.loaders.forEach((loader) => {
            if (! loader.isFinished) {
                loader.complete();
            }
        });
    }

    get isLoading$(): Observable<boolean> {
        return this._isLoading$;
    }

    get isLoading(): boolean {
        return this._isLoading$.getValue();
    }

    updateLoadingStatus(): void {
        this._isLoading$.next(this.loaders.length > 0);
    }
}
