import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ArticleLikesWebEndpoint, ArticleLikesWebEndpointPaths, ArticleLikesWebRequest, ArticleLikesWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebArticleLikesDataAccess implements ArticleLikesWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    save(request: ArticleLikesWebRequest): Observable<ArticleLikesWebResponse> {
        return this.http.post(ArticleLikesWebEndpointPaths.save, request);
    } 

    getByArticleId(request: ArticleLikesWebRequest): Observable<ArticleLikesWebResponse> {
        return this.http.post(ArticleLikesWebEndpointPaths.getByArticleId, request);
    }
}
