export const interidSiteWebFeatureDiscountedI18n = {
    discounted: {
        components: {
            discounted: {
                title: 'Уцененный товар',
                category: 'Категория'
            },
        },
    },
};
