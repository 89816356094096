import { Observable } from 'rxjs';
import { TelegramShared } from '../models/shared/telegram.shared';

export enum TelegramEndpointPaths {
    getSettings = '/api/v1/index/getSettings',
}

export interface TelegramEndpoint {
    getSettings(): Promise<TelegramShared.Settings> | Observable<TelegramShared.Settings>;
}
