export const interidSiteWebCoreSearchI18n = {
    search_shared: {
        shared: {
            components: {
                search: {
                    placeholder: 'Партномер или код товара',
                },
                search_popup: {
                    categories: 'Категории',
                    products: 'Товары',
                    articles: 'Статьи',
                    brands: 'Бренды',
                    more: 'Показать все',
                },
            },
        },
    },
};
