import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ProductBasketAddRequest, ProductBasketDto, ProductBasketGetBasketResponse, ProductBasketGetProductEntryDtoRequest, ProductBasketRemoveRequest, ProductBasketUpdateRequest, ProductBasketUpdateResponse, ProductBasketWebEndpoint, ProductBasketWebEndpointPaths, ProductEntryBasketDto } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebProductBasketDataAccess implements ProductBasketWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    add(request: ProductBasketAddRequest, recaptchaV3Token: string): Observable<Array<ProductBasketDto>> {
        return this.http.post(ProductBasketWebEndpointPaths.add, request, {
            headers: {
                'recaptcha': recaptchaV3Token,
            },
        });
    }

    remove(request: ProductBasketRemoveRequest): Observable<Array<ProductBasketDto>> {
        return this.http.post(ProductBasketWebEndpointPaths.remove, request);
    }

    update(request: ProductBasketUpdateRequest): Observable<ProductBasketUpdateResponse> {
        return this.http.post(ProductBasketWebEndpointPaths.update, request);
    }

    clear(): Observable<void> {
        return this.http.post(ProductBasketWebEndpointPaths.clear);
    }

    getBasket(): Observable<ProductBasketGetBasketResponse> {
        return this.http.post(ProductBasketWebEndpointPaths.getBasket);
    }

    getProductEntryDto(request: ProductBasketGetProductEntryDtoRequest): Observable<ProductEntryBasketDto> {
        return this.http.post(ProductBasketWebEndpointPaths.getProductEntryDto, request);
    }
}
