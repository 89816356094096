@if (hasDefinitions()) {
  <div class="c">
    <div class="c-nodes ___hide-mobile-scroll" [ngClass]="{'is-article': style() === 'article'}">
      @for (node of definition().nodes; track node) {
        @if (hasRouterLink(node)) {
          <a class="c-nodes-link" [routerLink]="node.routerLink" [queryParams]="node.queryParams"
            >@if (node.name.translate) {
            <span ngx-translate="ngx-translate" [translateParams]="node.name.translateParams">{{ t(node.name.title) }}</span
              >
              }@if (!node.name.translate) {
              <span>{{ node.name.title }}</span>
              }</a
              >
            }
            @if (!hasRouterLink(node)) {
              @if (node.name.translate) {
                <span class="c-nodes-link" ngx-translate="ngx-translate" [translateParams]="node.name.translateParams">{{ t(node.name.title) }}</span
                  >
                  }@if (!node.name.translate) {
                  <span class="c-nodes-link">{{ node.name.title }}</span>
                }
              }
              <span class="c-node-link-arrow">/</span>
            }
          </div>
        </div>
      }
