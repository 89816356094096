import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emailHref' })
export class EmailHrefPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
    ) {
    }

    transform(value: any): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`mailto:${value}`);
    }
}
