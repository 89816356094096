import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-shared-blocker',
    templateUrl: './blocker.component.html',
    styleUrls: ['./blocker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockerComponent {
}
