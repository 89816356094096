import { Observable } from 'rxjs';
import { IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, Min, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SystemTaskShared } from '../models/shared/system-task.shared';
import { PageMetadataDto } from '../models/entities/page-metadata.dto';
import { SystemTaskDto } from '../models/entities/system-task.dto';
import { PageMetadataSEO, SourceDefinition, Source } from '../models/shared/common.shared';

export enum PageMetadataEndpointPaths {
    createPageMetadata = '/api/v1/page-metadata/createPageMetadata',
    editPageMetadata = '/api/v1/page-metadata/editPageMetadata',
    deletePageMetadata = '/api/v1/page-metadata/deletePageMetadata',
    listPageMetadata = '/api/v1/page-metadata/listPageMetadata',
    getPageMetadata = '/api/v1/page-metadata/getPageMetadata',
    startCleanUpPageMetadata = '/api/v1/page-metadata/startCleanUpPageMetadata',
}

export interface PageMetadataEndpoint {
    createPageMetadata(request: CreatePageMetadataRequest): Promise<PageMetadataDto> | Observable<PageMetadataDto>;
    editPageMetadata(request: EditPageMetadataRequest): Promise<PageMetadataDto> | Observable<PageMetadataDto>;
    deletePageMetadata(request: DeletePageMetadataRequest): Promise<void> | Observable<void>;
    listPageMetadata(request: ListPageMetadataRequest): Promise<ListPageMetadataResponse> | Observable<ListPageMetadataResponse>;
    getPageMetadata(request: GetPageMetadataRequest): Promise<PageMetadataDto> | Observable<PageMetadataDto>;
    startCleanUp(): Promise<PageMetadataStartCleanUpResponse> | Observable<PageMetadataStartCleanUpResponse>;
}

export class PageMetadataEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    url: string;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    urlSource: SourceDefinition;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    metadata: PageMetadataSEO;
}

export class ListPageMetadataRequest {
    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    search?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Source),
    })
    @IsEnum(Source)
    @IsOptional()
    urlType?: Source;
}

export class ListPageMetadataResponse {
    @ApiModelProperty({
        type: PageMetadataDto,
        isArray: true,
    })
    entities: Array<PageMetadataDto>;

    @ApiModelProperty()
    total: number;
}

export class CreatePageMetadataRequest extends PageMetadataEntityBody {
}

export class EditPageMetadataRequest extends PageMetadataEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeletePageMetadataRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class GetPageMetadataRequest extends PageMetadataEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type PageMetadataStartCleanUpResponse = SystemTaskDto<SystemTaskShared.TaskType.PageMetadataCleanUp, SystemTaskShared.MultiEntriesResponse>;
