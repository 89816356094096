import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO } from '../shared/common.shared';
import { SortOrderShared } from '../shared/sort-order.shared';
import { ContentDTO } from '../shared/common-content.shared';

export class ArticleCategoryDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  alt: string;

  @ApiModelProperty()
  slug: string;

  @ApiModelProperty({
    type: () => ContentDTO,
  })
  contents: ContentDTO;

  @ApiModelProperty({
    type: () => ContentDTO,
  })
  contentsFooter: ContentDTO;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty({
    type: () => PageMetadataSEO,
  })
  seo: PageMetadataSEO;
}
