import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsObject, IsPositive } from 'class-validator';
import { CrudListRequestDto, CrudListResponseDto } from '../../../../core/crud';
import { Observable } from 'rxjs';
import { Link } from '../../../../models/shared/common.shared';
import { HttpRedirectDto } from '../dto/http-redirect.dto';

export enum HttpRedirectEndpointPaths {
    all = '/api/v2/http-redirect/all',
    list = '/api/v2/http-redirect/list',
    getById = '/api/v2/http-redirect/getById',
    create = '/api/v2/http-redirect/create',
    edit = '/api/v2/http-redirect/edit',
    delete = '/api/v2/http-redirect/delete',
}

export interface HttpRedirectEndpoint {
    all(): Promise<HttpRedirectAllResponse> | Observable<HttpRedirectAllResponse>;
    list(request: HttpRedirectListRequest): Promise<HttpRedirectListResponse> | Observable<HttpRedirectListResponse>;
    getById(request: HttpRedirectGetByIdRequest): Promise<HttpRedirectGetByIdResponse> | Observable<HttpRedirectGetByIdResponse>;
    create(request: HttpRedirectCreateRequest): Promise<HttpRedirectCreateResponse> | Observable<HttpRedirectCreateResponse>;
    edit(request: HttpRedirectEditRequest): Promise<HttpRedirectEditResponse> | Observable<HttpRedirectEditResponse>;
    delete(request: HttpRedirectDeleteRequest): Promise<void> | Observable<void>;
}

export class HttpRedirectListRequest extends CrudListRequestDto<HttpRedirectDto> {
}

export class HttpRedirectListResponse extends CrudListResponseDto<HttpRedirectDto> {
}

export class HttpRedirectAllResponse {
    @ApiModelProperty({
        type: HttpRedirectDto,
        isArray: true,
    })
    httpRedirects: Array<HttpRedirectDto>;
}

export class HttpRedirectGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type HttpRedirectGetByIdResponse = HttpRedirectDto;

export class HttpRedirectEntityBody {
    @ApiModelProperty({
        description: 'URL (redirect from)',
    })
    @IsObject()
    @IsNotEmpty()
    url: Link;

    @ApiModelProperty({
        description: 'URL (redirect to)',
    })
    @IsObject()
    @IsNotEmpty()
    redirectTo: Link;
}

export class HttpRedirectCreateRequest extends HttpRedirectEntityBody {
}

export type HttpRedirectCreateResponse = HttpRedirectDto;

export class HttpRedirectEditRequest extends HttpRedirectEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type HttpRedirectEditResponse = HttpRedirectDto;

export class HttpRedirectDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
