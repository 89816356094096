/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, input, } from '@angular/core';
import { CommonContentWebBundleShared, ContentBlockDto, ContentDTO, urlRemoveBlacklistQp, urlWithoutPagePostfix } from '@interid/interid-site-shared';
import { Location } from '@angular/common';

@Component({
    selector: 'app-shared-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent {
    public dto = input<ContentDTO>();
    public useHtmlCutoff = input<boolean>();
    public isArticle = input(false);
    public bundle = input<CommonContentWebBundleShared.Bundle>({
        bundles: [],
    });

    get isMarketplacePage() {
        let url = '/about/marketplaces';

        if (!url.startsWith('/') && !(url.startsWith('http://') || url.startsWith('https://'))) {
            url = '/' + url;
        }

        return urlRemoveBlacklistQp(urlWithoutPagePostfix(this.location.path())) === urlRemoveBlacklistQp(urlWithoutPagePostfix(url));
    }

    constructor(private readonly location: Location) {}
 
    trackByBlockSID(index: number, block: ContentBlockDto): any {
        return block.sid;
    }

    bundleFor(block: ContentBlockDto): CommonContentWebBundleShared.BundlePayloads {
        const bundle = this.bundle().bundles.find((b) => b.sid === block.sid);

        return bundle ? bundle.payload : undefined;
    }
}
