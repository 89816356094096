/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  booleanAttribute,
  ChangeDetectorRef,
  Directive,
  effect,
  ElementRef,
  forwardRef,
  inject,
  Input,
  NgZone,
  numberAttribute,
  OnDestroy,
  output,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WS_BASE_SLIDER_DIRECTIVE, WS_SLIDER_COMPONENT } from '../common/ws-slider.constants';
import { IWsBaseSliderDirective, IWsSlider, IWsSliderDragEvent } from '../common/ws-slider.interfaces';
import { Subject } from 'rxjs';
import { WsThumbEnum } from '../common/ws-slider.enum';
import { numberToString } from '../common/ws-slider.functions';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[wsSlider]',
  exportAs: 'wsSlider',
  host: {
    'class': 'ws-slider--input',
    'type': 'range',
    '(change)': 'onChange()',
    '(input)': 'onInput()',
    '(blur)': 'onBlur()',
    '(focus)': 'onFocus()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WsBaseSliderDirective),
      multi: true,
    },
    {
      provide: WS_BASE_SLIDER_DIRECTIVE, useExisting: WsBaseSliderDirective
    },
  ],
  standalone: true,
})
export class WsBaseSliderDirective implements IWsBaseSliderDirective, OnDestroy, ControlValueAccessor {
  protected readonly cdr = inject(ChangeDetectorRef);
  private readonly ngZone = inject(NgZone);
  private readonly renderer = inject(Renderer2);
  private readonly elementRef = inject(ElementRef<HTMLInputElement>);
  // private readonly _platform = inject(Platform);
  protected slider = inject<IWsSlider>(WS_SLIDER_COMPONENT);

  private readonly destroyed = new Subject<void>();

  /** Used to remove event listeners (Renderer2). */
  private unListen: (() => void)[] = [];

  /**
   * Установлено ли начальное значение.
   * Это происходит потому, что начальное значение не может быть установлено немедленно,
   * поскольку минимальное и максимальное значения сначала должны быть переданы из
   * родительского компонента 'WsSlider', что может произойти только позже
   * в жизненном цикле компонента.
   */
  private hasSetInitialValue = false;

  /** The stored initial value. */
  private initialValue: string | undefined;

  /** Определяется, когда пользователь использует form control для управления
   * slider value & validation.
   * */
  private formControl: FormControl | undefined;

  /** Расстояние в пикселях от начала дорожки слайдера до первой отметки. */
  protected tickMarkOffset = 3;

  /** Находится ли курсор пользователя в данный момент в состоянии мыши 'down' на <input>. */
  protected isActive = false;

  /** Находится ли в данный момент в фокусе <input> (посредством 'tab' или после щелчка). */
  isFocused = false;

  /**
   * Indicates whether UI updates should be skipped.
   *
   * Этот флаг используется для предотвращения мерцания при корректировке значений
   * при перемещении указателя вверх/вниз.
   */
  skipUIUpdate = false;

  /**
   * Была ли инициализирована 'NgModel'.
   *
   * Этот флаг используется для игнорирования фиктивных нулевых вызовов 'writeValue',
   * которые могут нарушить инициализацию слайдера.
   *
   * See https://github.com/angular/angular/issues/14988.
   */
  protected isControlInitialized = false;

  /**
   * Указывает, является ли этот 'thumb' начальным или конечным.
   */
  public thumbPosition: WsThumbEnum = WsThumbEnum.END;

  /** The host native HTML input element. */
  public hostElement: HTMLInputElement;

  /* ---------------------------------------------------------------------- */
  /**
   * The current translateX in px of the slider visual thumb.
   */
  get translateX(): number {
    if (this.slider.min() >= this.slider.max()) {
      this._translateX = this.tickMarkOffset;
      return this._translateX;
    }
    if (this._translateX === undefined) {
      this._translateX = this.calcTranslateXByValue();
    }
    return this._translateX;
  }
  set translateX(v: number) {
    this._translateX = v;
  }
  private _translateX: number | undefined;

  get min(): number {
    return numberAttribute(this.hostElement.min, 0);
  }
  set min(v: number) {
    this.hostElement.min = v + '';
    this.cdr.detectChanges();
  }

  get max(): number {
    return numberAttribute(this.hostElement.max, 0);
  }
  set max(v: number) {
    this.hostElement.max = v + '';
    this.cdr.detectChanges();
  }

  get step(): number {
    return numberAttribute(this.hostElement.step, 0);
  }
  set step(v: number) {
    this.hostElement.step = v + '';
    this.cdr.detectChanges();
  }

  get disabled(): boolean {
    return booleanAttribute(this.hostElement.disabled);
  }
  set disabled(v: boolean) {
    this.hostElement.disabled = v;
    // this.cdr.detectChanges();

    if (this.slider.disabled() !== this.disabled) {
      this.slider.disabled.set(this.disabled);
    }
  }

  /** The percentage of the slider that coincides with the value. */
  get percentage(): number {
    if (this.slider.min() >= this.slider.max()) {
      return 0;
    }
    return (this.value - this.slider.min()) / (this.slider.max() - this.slider.min());
  }

  get fillPercentage(): number {
    if (!this.slider.cachedWidth()) {
      return 0;
    }
    if (this._translateX === 0) {
      return 0;
    }
    return this.translateX / this.slider.cachedWidth();
  }

  @Input({transform: numberAttribute})
  get value(): number {
    return numberAttribute(this.hostElement.value, 0);
  }
  set value(value: number) {
    const stringValue = numberToString(value);

    if (!this.hasSetInitialValue) {
      this.initialValue = stringValue;
      return;
    }

    if (this.isActive) {
      return;
    }
    
    this.setValue(stringValue);
  }

  /* ---------------------------------------------------------------------- */
  /** Событие генерируется при изменении `value`. */
  protected readonly valueChange = output<number>();

  /** Событие генерируется, когда ползунок начинает перетаскиваться. */
  protected readonly dragStart = output<IWsSliderDragEvent>();

  /** Событие генерируется, когда ползунок перестает перетаскиваться. */
  protected readonly dragEnd = output<IWsSliderDragEvent>();

  /* ---------------------------------------------------------------------- */  
  constructor(
  ) {
    this.hostElement = this.elementRef.nativeElement;

    this.ngZone.runOutsideAngular(() => {
      this.unListen.push(this.renderer.listen(this.hostElement, 'pointermove', this.onPointerMove.bind(this)));
      this.unListen.push(this.renderer.listen(this.hostElement, 'pointerdown', this.onPointerDown.bind(this)));
      this.unListen.push(this.renderer.listen(this.hostElement, 'pointerup', this.onPointerUp.bind(this)));
    });

    effect(() => {
      if (!this.hasSetInitialValue) {
        this.hostElement.value = numberToString(this.value);
      }
    });
  }

  ngOnDestroy(): void {
    this.unListen.forEach(func => func());
    this.destroyed.next();
    this.destroyed.complete();
  }

  /* ------------------ Implements ControlValueAccessor ------------------- */

  /** Обратный вызов вызывается при изменении 'value' <input> слайдера. */
  protected onChangeFn: ((value: any) => void) | undefined;

  /** Обратный вызов происходит при касании ползунка ввода. */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTouchedFn: () => void = () => {};

  /**
   * Sets the input's value.
   * @param value The new value of the input
   */
  writeValue(value: any): void {
    if (this.isControlInitialized || value !== null) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.value = value;
        }, 10);
      });
    }
  }

  /**
   * Registers a callback to be invoked when the input's value changes from user input.
   * @param fn The callback to register
   */
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
    this.isControlInitialized = true;
  }

  /**
   * Registers a callback to be invoked when the input is blurred by the user.
   * @param fn The callback to register
   */
  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  /**
   * Sets the disabled state of the slider.
   * @param isDisabled The new disabled state
   */
  setDisabledState(isDisabled: boolean): void {
    if (this.disabled !== isDisabled) {
      this.disabled = isDisabled;
    }
  }

  /* ---------------------------------------------------------------------- */
  initProps(): void {
    this.updateWidthInactive();

    // If this or the parent slider is disabled, just make everything disabled.
    if (this.disabled !== this.slider.disabled()) {
      // The WsSlider setter for disabled will relay this and disable both inputs.
      this.slider.disabled.set(true);
    }

    this.step = this.slider.step();
    this.min = this.slider.min();
    this.max = this.slider.max();
    this.initValue();
  }

  initUI(): void {
    this.updateThumbUIByValue();
  }

  private initValue(): void {
    this.hasSetInitialValue = true;

    if (this.initialValue === undefined) {
      this.value = this.getDefaultValue();
    } else {
      this.hostElement.value = this.initialValue;
      this.updateThumbUIByValue();
      this.cdr.detectChanges();
    }
  }

  protected getDefaultValue(): number {
    return this.min;
  }

  protected onBlur(): void {
    this.setIsFocused(false);
    this.onTouchedFn();
  }

  protected onFocus(): void {
    this.slider.updateTrackUI(this);
    this.setIsFocused(true);
  }

  protected onChange(): void {
    this.valueChange.emit(this.value);
    // only used to handle the edge case where user
    // mousedown on the slider then uses arrow keys.
    if (this.isActive) {
      this.updateThumbUIByValue();
    }
  }

  protected onInput(): void {
    this.onChangeFn?.(this.value);
    // handles arrowing and updating the value when
    // a step is defined.
    if (this.slider.step() || !this.isActive) {
      this.updateThumbUIByValue();
    }
  }

  protected onNgControlValueChange(): void {
    // only used to handle when the value change
    // originates outside of the slider.
    if (!this.isActive || !this.isFocused) {
      this.updateThumbUIByValue();
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.slider.disabled.set(this.formControl!.disabled);
  }

  protected onPointerDown(event: PointerEvent): void {
    if (this.disabled || event.button !== 0) {
      return;
    }

    // On IOS, dragging only works if the pointer down happens on the
    // slider thumb and the slider does not receive focus from pointer events.
    // if (this._platform.IOS) {
    //   const isCursorOnSliderThumb = this._slider.isCursorOnSliderThumb(
    //     event,
    //     this._slider.getThumb(this.thumbPosition).hostElement.getBoundingClientRect(),
    //   );

    //   this.isActive.set(isCursorOnSliderThumb);
    //   this._updateWidthActive();
    //   this._slider.updateDimensions();
    //   return;
    // }

    this.isActive = true;
    this.setIsFocused(true);
    this.updateWidthActive();
    this.slider.updateDimensions();

    // Does nothing if a step is defined because we
    // want the value to snap to the values on input.
    if (!this.slider.step()) {
      this.updateThumbUIByPointerEvent(event);
    }

    if (!this.disabled) {
      this.handleValueCorrection(event);
      this.dragStart.emit({source: this, parent: this.slider, value: this.value});
    }
  }

  protected onPointerMove(event: PointerEvent): void {
    // Again, does nothing if a step is defined because
    // we want the value to snap to the values on input.
    if (!this.slider.step() && this.isActive) {
      this.updateThumbUIByPointerEvent(event);
    }
  }

  protected onPointerUp(): void {
    if (this.isActive) {
      this.isActive = false;

      // if (this._platform.SAFARI) {
      //   this.setIsFocused(false);
      // }
      
      this.dragEnd.emit({source: this, parent: this.slider, value: this.value});

      // Этот 'setTimeout' не позволяет 'pointerup' вызывать изменение 'value'
      // 'input'  на основе 'неактивной' ширины. Неясно, почему, но по какой-то
      // причине на 'IOS' это состояние гонки встречается еще чаще,
      // поэтому тайм-аут нужно увеличить.
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => this.updateWidthInactive()/*, this._platform.IOS ? 10 : 0*/);
      });
    }
  }

  /**
   * Корректирует 'value' слайдера при перемещении указателя вверх/вниз.
   *
   * Вызывается при перемещении указателя вниз и вверх,
   * поскольку значение устанавливается на основе 'неактивной' ширины,
   * а не 'активной'.
   */
  protected handleValueCorrection(event: PointerEvent): void {
    // Не обновляйте 'UI' текущим 'value'! Значение 'pointerdown' и 'pointerup'
    // вычисляется за долю секунды до изменения размера <input(s)>.
    // Подробнее см. 'updateWidthInactive()' и 'updateWidthActive()'.
    this.skipUIUpdate = true;

    // Обратите внимание, что эта функция срабатывает до обновления фактического
    // 'value' слайдера. Это означает, что если бы мы установили здесь 'value',
    // оно было бы немедленно перезаписано. Использование 'setTimeout' гарантирует,
    // что установка 'value' произойдет после того, как 'value' будет обновлено
    // событием 'pointerdown'.
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.skipUIUpdate = false;
        this.fixValue(event);
      }, 0);
    });
  }

  /** Corrects the value of the slider based on the pointer event's position. */
  protected fixValue(event: PointerEvent): void {
    const xPos = event.clientX - this.slider.cachedLeft();
    const width = this.slider.cachedWidth();
    const step = this.slider.step() === 0 ? 1 : this.slider.step();
    const numSteps = Math.floor((this.slider.max() - this.slider.min()) / step);
    const percentage = xPos / width;

    // To ensure the percentage is rounded to the necessary number of decimals.
    const fixedPercentage = Math.round(percentage * numSteps) / numSteps;

    const impreciseValue =
      fixedPercentage * (this.slider.max() - this.slider.min()) + this.slider.min();
    const value = Math.round(impreciseValue / step) * step;
    const prevValue = this.value;

    if (value === prevValue) {
      // Because we prevented UI updates, if it turns out that the race
      // condition didn't happen and the value is already correct, we
      // have to apply the ui updates now.
      this.slider.step() > 0
        ? this.updateThumbUIByValue()
        : this.updateThumbUIByPointerEvent(event);
      return;
    }

    this.value = value;
    this.onChangeFn?.(this.value);
    this.slider.step() > 0
      ? this.updateThumbUIByValue()
      : this.updateThumbUIByPointerEvent(event);
  }

  protected clamp(v: number): number {
    const min = this.tickMarkOffset;
    const max = this.slider.cachedWidth() - this.tickMarkOffset;
    return Math.max(Math.min(v, max), min);
  }

  calcTranslateXByValue(): number {
    return (
      this.percentage * (this.slider.cachedWidth() - this.tickMarkOffset * 2) +
      this.tickMarkOffset
    );
  }

  protected calcTranslateXByPointerEvent(event: PointerEvent): number {
    return event.clientX - this.slider.cachedLeft();
  }

  /**
   * Used to set the slider width to the correct
   * dimensions while the user is dragging.
   */
  updateWidthActive(): void {
    //
  }

  /**
   * Sets the slider input to disproportionate dimensions to allow for touch
   * events to be captured on touch devices.
   */
  updateWidthInactive(): void {
    this.hostElement.style.padding = `0 ${this.slider.inputPadding()}px`;
    this.hostElement.style.width = `calc(100% + ${
      this.slider.inputPadding() - this.tickMarkOffset * 2
    }px)`;
    this.hostElement.style.left = `-${this.slider.clickAreaRadius() - this.tickMarkOffset}px`;
  }

  updateThumbUIByValue(): void {
    this.translateX = this.clamp(this.calcTranslateXByValue());
    this.updateThumbUI();
  }

  protected updateThumbUIByPointerEvent(event: PointerEvent): void {
    this.translateX = this.clamp(this.calcTranslateXByPointerEvent(event));
    this.updateThumbUI();
  }

  updateThumbUI() {
    this.slider.onTranslateXChange(this);
  }

  /**
   * Handles programmatic value setting. This has been split out to
   * allow the range thumb to override it and add additional necessary logic.
   */
  protected setValue(value: string) {
    this.hostElement.value = value;
    this.updateThumbUIByValue();
    this.cdr.detectChanges();
    this.slider.cdr.markForCheck();
  }

  /** Используется для передачи обновлений isFocused на визуальные превью слайдера. */
  protected setIsFocused(v: boolean): void {
    this.isFocused = v;
  }

  /* ---------------------------------------------------------------------- */
  focus(): void {
    this.hostElement.focus();
  }

  blur(): void {
    this.hostElement.blur();
  }
}
