import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { PurchasedProductsSearchWebRequest, PurchasedProductsSearchWebResponse, PurchasedProductsWebEndpoint, PurchasedProductsWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebPurchasesProductsDataAccess implements PurchasedProductsWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    search(request: PurchasedProductsSearchWebRequest): Observable<PurchasedProductsSearchWebResponse> {
        return this.http.post(PurchasedProductsWebEndpointPaths.search, request);
    }
}
