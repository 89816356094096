import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { MindboxEndpoint } from '@interid/interid-site-shared';
import { MindboxEndpointPaths, MindboxWebsiteRequest } from 'libs/interid-site-shared/src/lib/endpoints/mindbox.endpoint';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebMindboxDataAccess implements MindboxEndpoint {
    constructor(private readonly http: InteridDataAccessService) {}

    request(request: MindboxWebsiteRequest): Observable<void> {
        return this.http.post(MindboxEndpointPaths.request, request);
    }
}
