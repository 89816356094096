<div class='c'>
  @if (payload().items.length > 0) {
    <div class='c-features' [ngClass]='ngClass'>
      @for (item of payload().items; track item; let i = $index) {
        <div class='c-feature'>
          <div class='c-feature-icon'>
            <div class='icon-container'><img [src]='item.icon.url' /></div>
          </div>
          <div class='c-feature-info'>
            @if (!! item.title) {
              <div class='c-feature-title'><span>{{ item.title }}</span></div>
            }
            @if (!! item.description) {
              <div class='c-feature-description'>
                <div [innerHTML]='item.description | trustHtml'></div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
</div>
