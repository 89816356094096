import { IsInt, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export namespace AccountShared {
    export const TOKEN_LENGTH = 16;

    export const V_PASSWORD_MIN = 3;
    export const V_PASSWORD_MAX = 32;

    export enum AccountRole {
        Default,
        Admin,
    }

    export enum RequisitesType {
        Legal = 'legal',
    }
}

export namespace AccountSettingsShared {
    export enum ViewMode {
        Card = 'Card',
        List = 'List',
    }

    export const defaultCatalogViewMode = ViewMode.List;
    export const defaultCatalogPageSize = 44;

    export const availablePageSizes = [15, 44, 60];

    export function defaultAccountSettings(): AccountSettingsDto {
        return {
            catalogViewModeR2: ViewMode.List,
        };
    }

    export class AccountSettingsDto {
        @ApiModelProperty({
            enum: Object.values(ViewMode),
        })
        @IsString()
        @IsOptional()
        catalogViewModeR2?: ViewMode;

        @ApiModelProperty()
        @IsPositive()
        @IsInt()
        @IsOptional()
        catalogPageSize?: number;
    }
}
