<div class="c">
  <div class="c-close" (click)="close()">
    <img loading="lazy" src="/assets/images/close_city_modal.svg" />
  </div>
  <div class="c-group">
    <div class="c-frame-1">
      <span class="title" translate>app.components.app_header_desktop_city.question</span>
      <span class="subtitle">{{ city().variants.nominative }}</span>
    </div>

    <div class="c-frame-2">
      <div class="c-frame-1787" style="width: 120px; height: 43px">
        <div class="c-button blue" (click)="confirmCity()">
          <span translate>app.components.app_header_desktop_city.confirm</span>
        </div>
      </div>

      <div class="c-frame-1787">
        <div class="c-button transparent" (click)="selectCity()">
          <span translate>app.components.app_header_desktop_city.selectCity</span>
        </div>
      </div>
    </div>
  </div>
</div>
