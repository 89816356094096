import { ChangeDetectionStrategy, Component, HostListener, Injector, OnDestroy, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ApiOnlineStatusService, AppBusEvent, AppBusService, AppLoadingService, JwtService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { connectToSignal, signalGetFrom } from '@interid/interid-site-web/ui-signals';

interface State {
    isAppOnline: boolean;
    isSignedIn: boolean;
}

@Component({
  selector: 'app-web-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly ngOnDestroy$: Subject<void> = new Subject<void>();
  private readonly injector = inject(Injector);
  private readonly router = inject(Router);
  protected appBus =  inject(AppBusService);
  protected appLoading = inject(AppLoadingService);
  protected appOnlineStatusService =  inject(ApiOnlineStatusService);
  protected jwtService = inject(JwtService);
  protected viewBreakpointsService = inject(ViewBreakpointsService);

  state: WritableSignal<State> = signal({
    isAppOnline: true,
    isSignedIn: false,
  });
  
  isLoading = signalGetFrom(this.appLoading.isLoading$, { requireSync: true });
  ngClass = signalGetFrom(this.viewBreakpointsService.ngClass$, { requireSync: true });

  ngOnInit(): void {
    connectToSignal(
      this.state, 
      this.appOnlineStatusService.isUp$.pipe(distinctUntilChanged()),
      (state, onlineStatus) => ({...state, isAppOnline: onlineStatus}),
      this.injector
    );

    connectToSignal(
      this.state, 
      this.jwtService.jwtVerified$,
      (state, isVerified) => isVerified
        ? { ...state, isSignedIn: true}
        : { ...state, isSignedIn: false}
      ,
      this.injector
    );

    this.router.events.pipe(
        takeUntil(this.ngOnDestroy$),
    ).subscribe((event) => {
        if (event instanceof NavigationStart) {
            this.appLoading.addLoading();
        } else if (event instanceof NavigationCancel || event instanceof NavigationError || event instanceof NavigationEnd) {
            this.appLoading.completeAll();
        }
    });
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
  }

  @HostListener('document:visibilitychange', [])
  visibilitychange() {
    if (window && ! window.document.hidden) {
        this.appBus.emit({
            type: AppBusEvent.DocumentVisible,
        });
    }
  }
}
