@if (variant === 1) {
  <div class="c c-variant-1">
    @if (withFavorite) {
      <div class="c-control c-favorite" [ngClass]="{ 'is-active': helper.favorite.isInFavorites$ | async }" (click)="helper.favorite.toggleFavorites()">
        <div class="c-icon">
          <div class="icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" stroke="#617E8C" />
              <path class="hover-fill" d="M26.8 14.168a5.242 5.242 0 0 0-6.8-.534 5.225 5.225 0 0 0-6.8 7.9l5.175 5.184a2.317 2.317 0 0 0 3.25 0l5.175-5.184a5.225 5.225 0 0 0 0-7.366Zm-1.175 6.216L20.45 25.56a.633.633 0 0 1-.9 0l-5.175-5.2a3.575 3.575 0 0 1 0-5 3.558 3.558 0 0 1 5 0 .832.832 0 0 0 1.183 0 3.559 3.559 0 0 1 5 0 3.575 3.575 0 0 1 .067 5v.025Z" fill="#617E8C" />
            </svg>
          </div>
        </div>
      </div>
    }
    @if (withCompare) {
      <div class="c-control c-compare" [ngClass]="{ 'is-active': helper.compare.isInCompares$ | async }" (click)="helper.compare.toggleCompares()">
        <div class="c-icon">
          <div class="icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" stroke="#617E8C" />
              <path class="hover-fill" d="M14.167 22.503a.833.833 0 0 0-.833.833v4.167a.833.833 0 0 0 1.667 0v-4.167a.833.833 0 0 0-.834-.833Zm4.167-2.5a.833.833 0 0 0-.833.833v6.667a.833.833 0 1 0 1.666 0v-6.667a.833.833 0 0 0-.833-.833Zm8.333 4.166a.833.833 0 0 0-.833.834v2.5a.833.833 0 0 0 1.667 0v-2.5a.833.833 0 0 0-.834-.834Zm-4.166-8.333a.833.833 0 0 0-.834.833v10.834a.833.833 0 0 0 1.667 0V16.669a.833.833 0 0 0-.833-.833Zm-5.001-.839H15v-2.5a.833.833 0 0 0-1.667 0v2.5h-2.5a.833.833 0 0 0 0 1.667h2.5v2.5a.833.833 0 0 0 1.667 0v-2.5h2.5a.833.833 0 0 0 0-1.667Z" fill="#617E8C" />
            </svg>
          </div>
        </div>
      </div>
    }
    @if (withShare) {
      <div class="c-control c-share" (click)="shareClick()">
        <div class="c-icon">
          <div class="icon" #attachProductSharePopup>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" stroke="#617E8C" />
              <path d="M25 28.336a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM15 22.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm2.158-1.242 5.692 3.317M25 16.664a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm-2.159-1.242-5.683 3.316" stroke="#617E8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </div>
    }
  </div>
}
@if (variant === 2) {
  <div class="c c-variant-2">
    @if (withCompare) {
      <div class="c-control c-compare" [ngClass]="{ 'is-active': helper.compare.isInCompares$ | async }" (click)="helper.compare.toggleCompares()" [matTooltip]="helper.compare.labelCompare" [matTooltipDisabled]="withLabels">
        <div class="c-icon">
          <div class="icon icon-18x17px">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect class="hover-fill" x="4" y="13" width="2" height="4" fill="#8D96B9"></rect>
              <rect class="hover-fill" x="4" width="2" height="10" fill="#8D96B9"></rect>
              <rect class="hover-fill" x="10" y="4" width="2" height="10" transform="rotate(90 10 4)" fill="#8D96B9"></rect>
              <rect class="hover-fill" x="8" y="11" width="2" height="6" fill="#8D96B9"></rect>
              <rect class="hover-fill" x="16" y="9" width="2" height="8" fill="#8D96B9"></rect>
              <rect class="hover-fill" x="12" y="7" width="2" height="10" fill="#8D96B9"></rect>
            </svg>
          </div>
        </div>
        @if (withLabels) {
          <div class="c-label">
            <span>{{ helper.compare.labelCompare }}</span>
          </div>
        }
      </div>
    }
    @if (withFavorite) {
      <div class="c-control c-favorite" [ngClass]="{ 'is-active': helper.favorite.isInFavorites$ | async }" (click)="helper.favorite.toggleFavorites()" [matTooltip]="helper.favorite.labelFavorite" [matTooltipDisabled]="withLabels">
        <div class="c-icon">
          <div class="icon icon-18x18px">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="hover-stroke" d="M8.148 4.04979L8.99989 5.43582L9.85186 4.04984C10.5341 2.93993 11.7503 2.20386 13.1391 2.20386C15.2714 2.20386 17 3.93238 17 6.06466C17 7.22347 16.4879 8.26089 15.6741 8.97078L15.6484 8.99317L15.6243 9.01727L8.99994 15.6419L2.37557 9.01727L2.35149 8.99318L2.32582 8.97079C1.51207 8.26092 1 7.22351 1 6.06466C1 3.93222 2.72812 2.20386 4.86029 2.20386C6.24919 2.20386 7.46593 2.94006 8.148 4.04979Z" stroke="#8D96B9" stroke-width="2"></path>
            </svg>
          </div>
        </div>
        @if (withLabels) {
          <div class="c-label">
            <span>{{ helper.favorite.labelFavorite }}</span>
          </div>
        }
      </div>
    }
    @if (withBasket && helper.price.hasPrice) {
      <div class="c-control c-basket" [ngClass]="{ 'is-active': helper.product.inBasket }" (click)="helper.basket.addToBasket()" [matTooltip]="helper.basket.labelBasket" [matTooltipDisabled]="withLabels">
        <div class="c-icon">
          <div class="icon icon-23x23px">
            <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="hover-fill" d="M9.1665 19C9.99493 19 10.6665 18.3284 10.6665 17.5C10.6665 16.6716 9.99493 16 9.1665 16C8.33808 16 7.6665 16.6716 7.6665 17.5C7.6665 18.3284 8.33808 19 9.1665 19Z" fill="#0049FF"></path>
              <path class="hover-fill" d="M16.1665 19C16.9949 19 17.6665 18.3284 17.6665 17.5C17.6665 16.6716 16.9949 16 16.1665 16C15.3381 16 14.6665 16.6716 14.6665 17.5C14.6665 18.3284 15.3381 19 16.1665 19Z" fill="#0049FF"></path>
              <path class="hover-stroke" d="M1 1H4.39394L6.72241 11.712C6.8 12.0803 7.01252 12.4112 7.32276 12.6466C7.63299 12.8821 8.02119 13.0072 8.41938 13H16.6667C17.0649 13.0072 17.453 12.8821 17.7633 12.6466C18.0735 12.4112 18.286 12.0803 18.3636 11.712L19.6667 5H12.4545H5.26342" stroke="#0049FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      </div>
    }
  </div>
}
@if (variant === 3) {
  <div class="c c-variant-3">
    @if (withFavorite) {
      <div class="c-control c-favorite" [ngClass]="{ 'is-active': helper.favorite.isInFavorites$ | async }" (click)="helper.favorite.toggleFavorites()">
        <div class="c-icon">
          <div class="icon">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.16 5.5A6.29 6.29 0 0 0 12 4.86a6.27 6.27 0 0 0-8.16 9.48l6.21 6.22a2.78 2.78 0 0 0 3.9 0l6.21-6.22a6.27 6.27 0 0 0 0-8.84Zm-1.41 7.46-6.21 6.21a.76.76 0 0 1-1.08 0l-6.21-6.24a4.29 4.29 0 0 1 0-6 4.27 4.27 0 0 1 6 0 .999.999 0 0 0 1.42 0 4.27 4.27 0 0 1 6 0 4.29 4.29 0 0 1 .08 6v.03Z" fill="#617E8C"/></svg>
          </div>
        </div>
      </div>
    }
    @if (withCompare) {
      <div class="c-control c-compare" [ngClass]="{ 'is-active': helper.compare.isInCompares$ | async }" (click)="helper.compare.toggleCompares()">
        <div class="c-icon">
          <div class="icon">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 15.5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-5a1 1 0 0 0-1-1Zm5-3a1 1 0 0 0-1 1v8a1 1 0 1 0 2 0v-8a1 1 0 0 0-1-1Zm10 5a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3a1 1 0 0 0-1-1Zm-5-10a1 1 0 0 0-1 1v13a1 1 0 0 0 2 0v-13a1 1 0 0 0-1-1Zm-6-1H6v-3a1 1 0 0 0-2 0v3H1a1 1 0 0 0 0 2h3v3a1 1 0 1 0 2 0v-3h3a1 1 0 0 0 0-2Z" fill="#617E8C"/></svg>
          </div>
        </div>
      </div>
    }
  </div>
}
