import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { RoistatPhoneCallRequestContext } from '@interid/interid-site-shared';
import { ProductCardHelper } from '../../helpers/product-card.helper';
import { PhoneCallRequestModalComponent, PhoneCallRequestModalRequest } from '@interid/interid-site-web/ui-shared';
import { EnvironmentWebService, defaultModalConfig } from '@interid/interid-site-web/core';

@Component({
    selector: 'app-shared-product-card-assets-one-click-buy',
    templateUrl: './product-card-one-click-buy.component.html',
    styleUrls: ['./product-card-one-click-buy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardOneClickBuyComponent {
    constructor(private readonly i18n: TranslateService, private readonly matDialog: MatDialog, private readonly helper: ProductCardHelper, private readonly env: EnvironmentWebService) {}

    t(input: string): string {
        return `product_shared.assets.product_card_one_click_buy.${input}`;
    }

    oneClickBuy(): void {
        this.matDialog.open<PhoneCallRequestModalComponent, PhoneCallRequestModalRequest>(PhoneCallRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            data: {
                withMessage: this.i18n.instant(this.t('message'), {
                    product: this.helper.product,
                    url: `${this.env.current.baseUrl}/kupit/${this.helper.product.id}`,
                }),
                context: RoistatPhoneCallRequestContext.ProductOneClickBuy,
                productId: this.helper.product.id,
                productPrice: this.helper.product.price
            },
        });
    }
}
