export const interidSiteWebFeatureAboutI18n = {
    about: {
        components: {
            about: {
                title: 'О компании',
                nav: {
                    company: 'О нас',
                    requisites: 'Реквизиты',
                    vacancies: 'Вакансии',
                    marketplaces: 'Электронные торговые площадки',
                    contacts: 'Контакты'
                },
            },
            about_vacancies: {
                no_vacancies: 'На данный момент нет активных вакансий',
            },
        },
    },
};
