export namespace ViewBreakpointsShared {
    export enum ViewBreakpoints {
        XS = 'XS',
        SM = 'SM',
        MD = 'MD',
        LG = 'LG',
        LG2 = 'LG2',
        XL = 'XL',
        XXL = 'XXL',
    }

    export const imageSizeXS = 360;
    export const imageSizeSM = 480;
    export const imageSizeMD = 768;
    export const imageSizeLG = 1000;
    export const imageSizeLG2 = 1280;
    export const imageSizeXL = 1440;
    export const imageSizeXXL = 1600;

    export const breakpointsXS = 360;
    export const breakpointsSM = 480;
    export const breakpointsMD = 768;
    export const breakpointsLG = 1000;
    export const breakpointsLG2 = 1280;
    export const breakpointsXL = 1440;
    export const breakpointsXXL = 1600;

    export const mapSizes: Array<{ breakpoint: ViewBreakpoints; size: number }> = [
        { breakpoint: ViewBreakpoints.XS, size: imageSizeXS },
        { breakpoint: ViewBreakpoints.SM, size: imageSizeSM },
        { breakpoint: ViewBreakpoints.MD, size: imageSizeMD },
        { breakpoint: ViewBreakpoints.LG, size: imageSizeLG },
        { breakpoint: ViewBreakpoints.LG2, size: imageSizeLG2 },
        { breakpoint: ViewBreakpoints.XL, size: imageSizeXL },
        { breakpoint: ViewBreakpoints.XXL, size: imageSizeXXL },
    ];

    export const allViewBreakpoints: Array<ViewBreakpoints> = [ViewBreakpoints.XS, ViewBreakpoints.SM, ViewBreakpoints.MD, ViewBreakpoints.LG, ViewBreakpoints.XL, ViewBreakpoints.XXL];

    export const allViewBreakpointsReverse: Array<ViewBreakpoints> = [...allViewBreakpoints].reverse();

    export enum Layout {
        Mobile = 'mobile',      // 360, 480     (отступ 16px)   XS, SM
        Tablet = 'tablet',      // 768          (отступ 24px)   MD
        Desktop = 'desktop',    // 1000, 1280   (отступ 24px)   LG, LG2
        Wide = 'wide',          // 1440, 1600   (отступ 80px)   XL, XXL
    }

    export const allViewBreakpointsLayouts: Array<Layout> = Object.values(Layout);

    export const breakpointsLayoutMap = [
        { breakpoint: ViewBreakpoints.XS, layout: Layout.Mobile },
        { breakpoint: ViewBreakpoints.SM, layout: Layout.Mobile },
        { breakpoint: ViewBreakpoints.MD, layout: Layout.Tablet },
        { breakpoint: ViewBreakpoints.LG, layout: Layout.Desktop },
        { breakpoint: ViewBreakpoints.LG2, layout: Layout.Desktop },
        { breakpoint: ViewBreakpoints.XL, layout: Layout.Wide },
        { breakpoint: ViewBreakpoints.XXL, layout: Layout.Wide },
    ];

    export const userAgentsMap: Array<{
        breakpoint: ViewBreakpointsShared.Layout;
        userAgents: Array<(userAgent: string) => boolean>;
    }> = [
        {
            breakpoint: ViewBreakpointsShared.Layout.Wide,
            userAgents: [(userAgent) => !['mobile', 'iphone', 'android'].some((s) => (userAgent || '').toLowerCase().includes(s))],
        },
        {
            breakpoint: ViewBreakpointsShared.Layout.Mobile,
            userAgents: [(userAgent) => ['mobile', 'iphone', 'android'].some((s) => (userAgent || '').toLowerCase().includes(s))],
        },
        // TODO: There is no proper way to detect tablet layout
    ];

    export function detectLayoutFromUserAgent(userAgent: string): ViewBreakpointsShared.Layout {
        for (const config of userAgentsMap) {
            if (config.userAgents.some((detector) => detector(userAgent))) {
                return config.breakpoint;
            }
        }

        return Layout.Desktop;
    }
}
