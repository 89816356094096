import { AttachmentShared } from './../shared/attachment.shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CrudSortableEntity } from '../../core/crud';

export class AttachmentDto implements CrudSortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  originalFilename: string;

  @ApiModelProperty()
  path: string;

  @ApiModelProperty()
  url?: string;

  @ApiModelProperty()
  mimeType: string;

  @ApiModelProperty()
  size: number;

  @ApiModelProperty()
  response: AttachmentShared.Strategies.PairResponse;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  type: AttachmentShared.AttachmentType;

  @ApiModelProperty()
  language: AttachmentShared.AttachmentLanguage;

  @ApiModelProperty()
  jsonMetadata: any;

  @ApiModelProperty()
  previewForVideo: AttachmentDto; 

  @ApiModelProperty()
  videoForPreview: AttachmentDto; 

  @ApiModelProperty()
  createdAt: Date;
}
