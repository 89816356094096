import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, inject, input, signal } from '@angular/core';
import { ProductCategoryListDto, ProductCategorySeoLinkDto, ProductDto, Source, UrlBuilderRouterLinkDefinition } from '@interid/interid-site-shared';
import { AppBootstrapDataService, UrlBuilderService } from '@interid/interid-site-web/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface State {
    hoveredCategory?: ProductCategoryListDto;
    productCategories: ProductCategoryListDto[];
    isShowChild: boolean;
    hoveredCategoryHighRankedProduct?: ProductDto;
}

@Component({
    templateUrl: './app-header-desktop-categories.component.html',
    styleUrls: ['./app-header-desktop-categories.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderDesktopCategoriesComponent implements OnInit {
    private readonly renderer = inject(Renderer2);
    private readonly cdr = inject(ChangeDetectorRef);
    protected appBootstrap = inject(AppBootstrapDataService);
    protected urlBuilder = inject(UrlBuilderService);

    
    public fitToContainer = input.required<ElementRef<HTMLDivElement>>();
    
    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
    touchstartTimer;

    // public state: State = {
    //     productCategories: [],
    //     isShowChild: true,
    // };

    public state = signal<State>({
        productCategories: [],
        isShowChild: true,
    });

    toggleShowChild() {
        this.state.update((prev) => ({
            ...prev,
            isShowChild: !prev.isShowChild,
        }));
    }

    ngOnInit(): void {
        this.state.update((prev) => ({
            ...prev,
            productCategories: this.appBootstrap.data.productCategories,
        }));
    }

    productClicked() {
        this.close();
    }

    get rootCategories(): Array<ProductCategoryListDto> {
        const rootCategories = this.state().productCategories.filter((pc) => !pc.parent && pc.isDisplayInCatalogMenu);

        rootCategories.sort((a, b) => a.sortOrder - b.sortOrder);

        return rootCategories;
    }

    change(category: ProductCategoryListDto) {
        this.onHoverRootCategory(category);
    }

    onHoverRootCategoryMouseover(category: ProductCategoryListDto): void {
        this.touchstartTimer = setTimeout(() => this.change(category), 200);
    }

    onHoverRootCategoryMouseout(): void {
        clearTimeout(this.touchstartTimer);
    }

    hoveredCategoryHighRankedProduct(category: ProductCategoryListDto): ProductDto | undefined {
        const productCategoriesHighRankedProducts = this.appBootstrap.data.productCategoriesHighRankedProducts;

        if (productCategoriesHighRankedProducts && productCategoriesHighRankedProducts.length > 0) {
            return productCategoriesHighRankedProducts.find((x) => category.id == x.productCategoryId);
        }
    }

    onHoverRootCategory(category: ProductCategoryListDto): void {
        this.state.update((prev) => ({
            ...prev,
            hoveredCategory: category,
            isShowChild: true,
            hoveredCategoryHighRankedProduct: this.hoveredCategoryHighRankedProduct(category),
        }));
    }

    // Warning!: It is not possible to transfer this functionality to Signals; the application logic must be changed.
    categoriesSeoLinks$(categoryId: number): Observable<Array<ProductCategorySeoLinkDto>> {
        if (!categoryId) return;

        return this.appBootstrap.data$.pipe(
            map((next) => {
                const categories = next.productCategorySeoLinks.filter((c) => c.productCategoryId === categoryId && c.isDisplayInCatalogMenu);

                categories.sort((a, b) => a.sortOrder - b.sortOrder);
                return categories;
            })
        );
    }

    // Warning!: It is not possible to transfer this functionality to Signals; the application logic must be changed.
    childCategories$(categoryId: number): Observable<Array<ProductCategoryListDto>> {
        return this.appBootstrap.data$.pipe(
            map((next) => {
                const categories = next.productCategories.filter((c) => c.parent === categoryId && c.isDisplayInCatalogMenu);

                categories.sort((a, b) => a.sortOrder - b.sortOrder);

                return categories;
            })
        );
    }

    // Warning!: It is not possible to transfer this functionality to Signals; the application logic must be changed.
    pcRouterLink(productCategoryId: number): UrlBuilderRouterLinkDefinition {
        return this.urlBuilder.routerLink({
            type: Source.Catalog,
            payload: {
                productCategoryId,
            },
        });
    }

    close(): void {
        this.closeEvent.emit(undefined);

        this.renderer.setStyle(document.body, 'overflow', 'auto');
        this.renderer.setStyle(document.body, 'padding-right', '0px');
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }
}
