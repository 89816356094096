import { IsEmail, IsNotEmpty, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum EmailSubscriptionsWebEndpointPaths {
    subscribe = '/api/v1/web/email-subscriptions/subscribe',
    unsubscribe = '/api/v1/web/email-subscriptions/unsubscribe',
}

export interface EmailSubscriptionsWebEndpoint {
    subscribe(request: EmailSubscriptionsSubscribeWebRequest, reqNestJs?: any): Promise<void> | Observable<void>;
    unsubscribe(request: EmailSubscriptionsWebUnsubscribeRequest): Promise<void> | Observable<void>;
}

export class EmailSubscriptionsSubscribeWebRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export class EmailSubscriptionsWebUnsubscribeRequest {
    @ApiModelProperty()
    @IsNotEmpty()
    @IsString()
    unsubscribeToken: string;
}
