import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { CatalogCountWebRequest, CatalogCountWebResponse, CatalogProductsWebRequest, CatalogProductsWebResponse, CatalogWebEndpoint, CatalogWebEndpointPaths, CatalogWebRequest, CatalogWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebCatalogDataAccess implements CatalogWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    category(request: CatalogWebRequest): Observable<CatalogWebResponse> {
        return this.http.post(CatalogWebEndpointPaths.category, request);
    }

    products(request: CatalogProductsWebRequest): Observable<CatalogProductsWebResponse> {
        return this.http.post(CatalogWebEndpointPaths.products, request);
    }

    count(request: CatalogCountWebRequest): Observable<CatalogCountWebResponse> {
        return this.http.post(CatalogWebEndpointPaths.count, request);
    }
}
