<div class="c" [formGroup]="state().form" #attachPopup (click)="onPlaceholderClick()">
  <div class="c-search-control" (click)="focus()" [ngClass]="{ 'is-focused': state().isFocused }">
    <div class="c-search-input">
      @if (!state().showPlaceholder) {
        <input #input type="text" formControlName="queryString" (focus)="onInputFocus()" (blur)="onInputBlur()" (keyup.enter)="onKeyUpEnter()" (keyup.escape)="onKeyUpEscape()" />
      }
      @if (state().showPlaceholder) {
        <div class="c-search-input-placeholder">
          <span>Поиск по партномеру, коду товара или названию, например, <a (click)="$event.stopPropagation()" [routerLink]="['/samokleyashiesya-etiketki']">этикетки,</a> <a (click)="$event.stopPropagation()" [routerLink]="['/printery-etiketok']">принтеры</a></span>
        </div>
      }
    </div>
    <div class="c-search-controls">
      <div class="search-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m15.765 14.573-3.808-3.96a6.44 6.44 0 0 0 1.516-4.153A6.467 6.467 0 0 0 7.013 0a6.467 6.467 0 0 0-6.46 6.46 6.467 6.467 0 0 0 6.46 6.46 6.39 6.39 0 0 0 3.7-1.169l3.838 3.99a.838.838 0 0 0 1.191.023.844.844 0 0 0 .023-1.19ZM7.013 1.685a4.78 4.78 0 0 1 4.774 4.775 4.78 4.78 0 0 1-4.774 4.775A4.78 4.78 0 0 1 2.238 6.46a4.78 4.78 0 0 1 4.775-4.775Z" fill="#B0BFC6" /></svg>
      </div>
    </div>
  </div>
</div>
