import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { RoistatPhoneCallRequestContext } from '../models/shared/roistat.shared';

export enum PhoneCallRequestWebEndpointPaths {
    send = '/api/v1/web/phone-call-request/send',
}

export interface PhoneCallRequestWebEndpoint {
    send(request: PhoneCallRequestSendRequest, ...args: any): Promise<PhoneCallRequestSendResponse> | Observable<PhoneCallRequestSendResponse>;
}

export class PhoneCallRequestSendResponse {
    @ApiModelProperty()
    id: number;
}

export class PhoneCallRequestSendRequest {
    @ApiModelProperty()
    @IsString()
    @IsOptional()
    createdFromURL: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    phone: string;

    @ApiModelProperty()
    productId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    name?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    email?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    message?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(RoistatPhoneCallRequestContext),
        required: false,
    })
    @IsEnum(RoistatPhoneCallRequestContext)
    @IsString()
    @IsOptional()
    context?: RoistatPhoneCallRequestContext;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    roistat?: string;

    @ApiModelProperty()
    sessionId?: number;
}
