import { afterNextRender, ChangeDetectionStrategy, Component, effect, inject, input, model, numberAttribute, output, signal } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

export interface SliderOptions {
  min?: number;
  max?: number;
  step?: number;
}

export interface SliderEventChange {
  valueMin: number;
  valueMax: number;
}

@Component({
  selector: 'app-filters-slider',
  templateUrl: './product-filters-slider.html',
  styleUrl: './product-filters-slider.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFiltersSliderComponent {
  private readonly fb = inject(FormBuilder);

  private valueStart = '';
  private valueEnd = '';

  public options = input<SliderOptions>({
    min: 100,
    max: 500,
    step: 1,
  });

  public disabled = input<boolean>(false);
  public hideSlider = input<boolean>(false);

  public valueMin = model<number>();
  public valueMax = model<number>();

  public sliderChange = output<SliderEventChange>();

  public sliderControls = this.fb.group({
    valueStart: [`${this.options().min}`],
    valueEnd: [`${this.options().max}`],
    sliderStart: [this.options().min],
    sliderEnd: [this.options().max],
  });

  public warnRangeMin = signal<boolean>(false);
  public warnRangeMax = signal<boolean>(false);

  constructor() {
    afterNextRender(() => {      
      this.sliderControls.controls.valueStart.valueChanges.subscribe(
        ((value) => {

          if (this.valueStart !== value) {
            if (+value < this.options().min) {
              value = `${this.options().min}`;
            }

            if (+value > +this.sliderControls.value.valueEnd) {
              value = this.sliderControls.value.valueEnd;
            }

            this.sliderControls.patchValue({
              sliderStart: +(value ?? '0'),
            });
          }
      }));

      this.sliderControls.controls.valueEnd.valueChanges.subscribe(
        ((value) => {
          if (this.valueEnd !== value) {
            if (+value > this.options().max) {
              value = `${this.options().max}`;
            }

            if (+value < +this.sliderControls.value.valueStart) {
              value = this.sliderControls.value.valueStart;
            }
            
            this.sliderControls.patchValue({
              sliderEnd: +(value ?? '0'),
            });
          }
      }));
    });

    effect (() => {
      this.sliderControls.patchValue({
        valueStart: `${this.valueMin()}`,
      })
    });

    effect (() => {
      this.sliderControls.patchValue({
        valueEnd: `${this.valueMax()}`,
      })
    });
  }

  onChange() {
    this.sliderChange.emit({
      valueMin: +this.sliderControls.value.valueStart,
      valueMax: +this.sliderControls.value.valueEnd,
    });
  }

  onInput(_event: Event, position: string) {
    if (_event.target) {
      const value = Number((_event.target as HTMLInputElement).value);

      switch(position) {
        case 'start':
          value > Number(this.sliderControls.value.valueEnd)
            ? this.warnRangeMin.set(true)
            : this.warnRangeMin.set(false);
          break;
        case 'end':
          value < Number(this.sliderControls.value.valueStart)
            ? this.warnRangeMax.set(true)
            : this.warnRangeMax.set(false);
          break;
      }
    }

    this.onChange();
  }

  onInputSlider(_event: Event, position: string) {
    if (_event.target) {
      const value = `${(_event.target as HTMLInputElement).value}`;

      switch(position) {
        case 'start':
          this.valueStart = value;
          this.sliderControls.patchValue({
            valueStart: value,
          });
          break;
        case 'end':
          this.valueEnd = value;
          this.sliderControls.patchValue({
            valueEnd: value,
          });
          break;
      }
    }
  }

  reset() {
    this.sliderControls.reset();
  }

  dragStart() {
    !this.warnRangeMin() || this.warnRangeMin.set(false);
  }

  dragEnd() {
    !this.warnRangeMax() || this.warnRangeMax.set(false);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  t(input: string): string {
    return `product.shared.components.product_filters.${input}`;
  }
}