import { Observable } from 'rxjs';
import { CreateProductReviewRequest } from '../endpoints/product-review.endpoint';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsOptional, IsPositive } from 'class-validator';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';
import { AttachmentDto } from '../models/entities/attachment.dto';
import { ProductReviewDto } from '../models/entities/product-review.dto';
import { ProductDto } from '../models/entities/product.dto';
import { ProductCategoryDto } from '../models/entities/product-category.dto';
import { ProductCategorySeoLinkDto } from '../models/entities/product-category-seo-link.dto';

export enum ProductReviewsWebEndpointPaths {
    review = '/api/v1/web/product-reviews/review',
    upload = '/api/v1/web/product-reviews/upload',
    reviews = '/api/v1/web/product-reviews/reviews',
}

export interface ProductReviewsWebEndpoint {
    review(request: CreateProductReviewRequest, ...args: unknown[]): Promise<ProductReviewDto> | Observable<ProductReviewDto>;
    upload(file: File | any): Promise<AttachmentDto> | Observable<AttachmentDto>;
    reviews(request: ProductsReviewsWebRequest, req?: any): Promise<ProductsReviewsWebResponse> | Observable<ProductsReviewsWebResponse>;
}

export class ProductsReviewsWebRequest extends CrudListRequestDto<ProductReviewDto> {
    @ApiModelProperty({
        required: false,
        description: 'Product Category Id',
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productCategoryId?: number;

    @ApiModelProperty({
        required: false,
        description: 'Product Category Seo Link Id',
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productCategorySeoLinkId?: number;
}

export class ProductsReviewsWebResponse extends CrudListResponseDto<ProductReviewDto> {
    @ApiModelProperty({
        description: 'Base URL for page',
    })
    url: string;

    @ApiModelProperty({
        description: 'Title of page',
    })
    title: string;

    @ApiModelProperty({
        type: ProductReviewDto,
        isArray: true,
        description: 'Products reviews',
    })
    items: Array<ProductReviewDto>;

    @ApiModelProperty({
        type: ProductDto,
        isArray: true,
        description: 'Products',
    })
    products: Array<ProductDto>;

    @ApiModelProperty({
        description: 'Total Products Count',
    })
    totalProducts: number;

    @ApiModelProperty({
        description: 'Average Rating',
    })
    avgRating: number;

    @ApiModelProperty({
        description: 'Total Ratings',
    })
    totalRatings: number;

    @ApiModelProperty({
        description: 'Product Category',
    })
    productCategory: ProductCategoryDto;

    @ApiModelProperty({
        description: 'Product SEO Category',
        required: false,
    })
    seoLink?: ProductCategorySeoLinkDto;
}
