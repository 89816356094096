import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO, SourceDefinition } from '../shared/common.shared';

export class RouteDto {
  @ApiModelProperty()
  redirect?: string;

  @ApiModelProperty()
  redirectSource?: SourceDefinition;

  @ApiModelProperty()
  redirectSkipLocationChange?: boolean;

  @ApiModelProperty()
  seo?: PageMetadataSEO;
}
