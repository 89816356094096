import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';
import { DevFeedbackDto } from '../models/entities/dev-feedback.dto';
import { Observable } from 'rxjs';
import { DevFeedbackStatus } from '../models/shared/dev-feedback.shared';

export enum DevFeedbackEndpointPaths {
    create = '/api/v1/admin/dev-feedback/create',
    update = '/api/v1/admin/dev-feedback/update',
    delete = '/api/v1/admin/dev-feedback/delete',
    getById = '/api/v1/admin/dev-feedback/getById',
    list = '/api/v1/admin/dev-feedback/list',
    setStatus = '/api/v1/admin/dev-feedback/setStatus',
}

export interface DevFeedbackEndpoint {
    create(request: DevFeedbackCreateRequest): Promise<DevFeedbackDto> | Observable<DevFeedbackDto>;
    update(request: DevFeedbackUpdateRequest): Promise<DevFeedbackDto> | Observable<DevFeedbackDto>;
    delete(request: DevFeedbackDeleteRequest): Promise<void> | Observable<void>;
    getById(request: DevFeedbackGetByIdRequest): Promise<DevFeedbackDto> | Observable<DevFeedbackDto>;
    list(request: DevFeedbackListRequest): Promise<DevFeedbackListResponse> | Observable<DevFeedbackListResponse>;
    setStatus(request: DevFeedbackSetStatusRequest): Promise<DevFeedbackDto> | Observable<DevFeedbackDto>;
}

export class DevFeedbackEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    description: string;

    @ApiModelProperty({
        type: 'string',
        default: 'Regular'
    })
    @IsOptional()
    type?: string = 'Regular';

    @ApiModelProperty()
    @IsOptional()
    productPartNumber?: string;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    fileIds: Array<number>;
}

export class DevFeedbackCreateRequest extends DevFeedbackEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    author: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    authorEmail: string;
}

export class DevFeedbackUpdateRequest extends DevFeedbackEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    comment?: string;
}

export class DevFeedbackDeleteRequest {
    @ApiModelProperty()
    @IsNotEmpty()
    ids: Array<number>;
}

export class DevFeedbackGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DevFeedbackSetStatusRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DevFeedbackStatus),
    })
    @IsEnum(DevFeedbackStatus)
    @IsNotEmpty()
    status: DevFeedbackStatus;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    comment?: string;
}

export class DevFeedbackListRequest extends CrudListRequestDto<DevFeedbackDto> {}

export class DevFeedbackListResponse extends CrudListResponseDto<DevFeedbackDto> {
    @ApiModelProperty({
        type: DevFeedbackDto,
        isArray: true,
    })
    items: Array<DevFeedbackDto>;
}
