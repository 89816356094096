import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface State<T> {
    isDisabled: boolean;
    isSelected: boolean;
}

@Component({
    selector: 'app-shared-ui-radio-button',
    templateUrl: './ui-radio-button.component.html',
    styleUrls: ['./ui-radio-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class UIRadioButtonComponent<T = any> implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    @Input() value: T;
    @Input() label: string;

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
    private onChange: Function;

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
    private onTouched: Function;

    public state: State<T> = {
        isDisabled: false,
        isSelected: false,
    };

    constructor(
        public ngControl: NgControl,
        private readonly cdr: ChangeDetectorRef,
    ) {
        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.ngControl.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$),
        ).subscribe(() => this.update());
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    get ngClass(): any {
        return {
            'is-disabled': this.state.isDisabled,
            'is-selected': this.state.isSelected,
        };
    }

    ngOnChanges(): void {
        this.update();
    }

    update(): void {
        this.state = {
            ...this.state,
            isSelected: this.ngControl.value === this.value,
        };

        this.cdr.markForCheck();
    }

    writeValue(obj: T | undefined | null): void {
        this.update();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.state = {
            ...this.state,
            isDisabled: isDisabled,
        };
    }

    select(): void {
        if (this.state.isDisabled) {
            return;
        }

        this.onChange(this.value);

        this.update();
    }
}
