import { Observable } from 'rxjs';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DictionaryDto } from '../models/entities/dictionary.dto';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';
import { DictionaryShared } from '../models/shared/dictionary.shared';

export enum DictionaryEndpointPaths {
    createDictionary = '/api/v1/dictionaries/create',
    editDictionary = '/api/v1/dictionaries/edit',
    deleteDictionary = '/api/v1/dictionaries/delete',
    listDictionaries = '/api/v1/dictionaries/list',
    getDictionary = '/api/v1/dictionaries/get',
}

export interface DictionaryEndpoint {
    createDictionary(request: CreateDictionaryRequest): Promise<DictionaryDto> | Observable<DictionaryDto>;
    editDictionary(request: EditDictionaryRequest): Promise<DictionaryDto> | Observable<DictionaryDto>;
    deleteDictionary(request: DeleteDictionaryRequest): Promise<void> | Observable<void>;
    listDictionaries(request: ListDictionariesRequest): Promise<ListDictionariesResponse> | Observable<ListDictionariesResponse>;
    getDictionary(request: GetDictionaryRequest): Promise<DictionaryDto> | Observable<DictionaryDto>;
}

export class DictionaryEntityBody {
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    @IsOptional()
    parent?: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    type: DictionaryShared.Type;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isDisplayed: boolean;
}

export class CreateDictionaryRequest extends DictionaryEntityBody {
}

export class EditDictionaryRequest extends DictionaryEntityBody {
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    @IsNotEmpty()
    id: number;
}

export class DeleteDictionaryRequest {
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    @IsNotEmpty()
    id: number;
}

export class ListDictionariesRequest extends CrudListRequestDto<DictionaryDto> {}

export class ListDictionariesResponse extends CrudListResponseDto<DictionaryDto> {
    @ApiModelProperty({
        type: DictionaryDto,
        isArray: true,
    })
    items: Array<DictionaryDto>;
}

export class GetDictionaryRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    id: number;
}
