import {
  afterNextRender,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  contentChild,
  contentChildren,
  effect,
  ElementRef,
  inject,
  input,
  model,
  numberAttribute,
  signal,
  viewChild,
  viewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { WS_BASE_SLIDER_DIRECTIVE, WS_RANGE_SLIDER_DIRECTIVE, WS_SLIDER_COMPONENT, WS_SLIDER_THUMB_COMPONENT } from './common/ws-slider.constants';
import { WsSliderThumbComponent } from './components/ws-slider-thumb/ws-slider-thumb.component';
import { IWsSliderThumbComponent, IWsBaseSliderDirective, IWsRangeSliderDirective, IWsSlider } from './common/ws-slider.interfaces';
import { WsThumbEnum } from './common/ws-slider.enum';

@Component({
  selector: 'ws-slider',
  standalone: true,
  imports: [
    CommonModule,
    WsSliderThumbComponent,
  ],
  exportAs: 'wsSliderComponent',
  templateUrl: './ws-slider.component.html',
  styleUrl: './ws-slider.component.less',
  host: {
    'class': 'ws-slider slider',
    '[class.slider--range]': 'isRange()',
    '[class.slider--disabled]': 'this.disabled()',
  },
  providers: [
    {
      provide: WS_SLIDER_COMPONENT, useExisting: WsSliderComponent
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WsSliderComponent implements IWsSlider {
  private readonly elementRef = inject(ElementRef<HTMLElement>);
  public readonly cdr = inject(ChangeDetectorRef);

  private previousMin = 1;
  private previousMax = 1;
  private previousStep = 1;

  /** Whether the slider is disabled. */
  public disabled = model(false);

  /** The minimum value that the slider can have. */
  public min = input(0, {
    transform: (value: number | string) => numberAttribute(value)
  });

  /** The maximum value that the slider can have. */
  public max = input(100, {
    transform: (value: number | string) => numberAttribute(value)
  });
  
  /** The values at which the thumb will snap. */
  public step = input(1, {
    transform: (value: number | string) => numberAttribute(value)
  });

  /* -------------------------------------------------------------------------------*/

  /** The radius of the native slider's knob. */
  private knobRadius = signal(8);

  private hasViewInitialized = signal(false);

  /** Whether or not the slider thumbs overlap. */
  private thumbsOverlap = signal(false);

  /** Stored dimensions to avoid calling getBoundingClientRect redundantly. */
  public cachedWidth = signal(0);
  public cachedLeft = signal(0);

  public clickAreaRadius = signal(24);
  public isRange = signal(false);
  public inputPadding = signal(0);

  /* -------------------------------------------------------------------------------*/
  /** The active portion of the slider track. */
  private trackActive = viewChild<ElementRef<HTMLElement>>('trackActive');

  /** The slider thumb(s). */
  private thumbs = viewChildren<IWsSliderThumbComponent>(WS_SLIDER_THUMB_COMPONENT);

  /** The sliders hidden range input(s). */
  private input = contentChild<IWsBaseSliderDirective>(WS_BASE_SLIDER_DIRECTIVE);

  /** The sliders hidden range input(s). */
  private inputs = contentChildren<IWsRangeSliderDirective>(
    WS_RANGE_SLIDER_DIRECTIVE, { descendants: false }
  );

  /* -------------------------------------------------------------------------------*/
  constructor() {
    afterNextRender(() => {
      const startInput = this.getInput(WsThumbEnum.START) as IWsRangeSliderDirective;
      const endInput = this.getInput(WsThumbEnum.END) as IWsRangeSliderDirective;

      // if (this._platform.isBrowser) {
      //   this.updateDimensions();
      // }
      this.updateDimensions();

      this.isRange.set(!! endInput && !! startInput);
      this.inputPadding.set(this.clickAreaRadius() - this.knobRadius());

      this.isRange()
        ? this.initUIRange(endInput, startInput)
        : this.initUINonRange(endInput as IWsBaseSliderDirective | IWsRangeSliderDirective);

      this.updateTrackUI(endInput  as IWsBaseSliderDirective | IWsRangeSliderDirective);
    });

    /** Set/unset Disabled */
    effect(() => {
      const endInput = this.getInput(WsThumbEnum.END);
      const startInput = this.getInput(WsThumbEnum.START);

      if (endInput) {
        endInput.disabled = this.disabled();
      }
      if (startInput) {
        startInput.disabled = this.disabled();
      }
    }, { allowSignalWrites: true });

    effect(() => {
      /** Update Min */
      if (this.min() !== this.previousMin) {
        this.isRange()
        ? this.updateMinRange({old: this.previousMin, new: this.min()})
        : this.updateMinNonRange(this.min());

        this.previousMin = this.min();
      }

      /** Update max */
      if (this.max() !== this.previousMax) {
        this.isRange()
        ? this.updateMaxRange({old: this.previousMax, new: this.max()})
        : this.updateMaxNonRange(this.max());

        this.previousMax = this.max();
      }

      /** Update step */
      if (this.step() !== this.previousStep) {
        this.previousStep = this.step();
        this.isRange() ? this.updateStepRange() : this.updateStepNonRange();
      }
    }, { allowSignalWrites: true });
  }

  /* -------------------------------------------------------------------------------*/
  // }
  private updateMinRange(min: {old: number; new: number}): void {
    const startInput = this.getInput(WsThumbEnum.START) as IWsRangeSliderDirective;
    const endInput = this.getInput(WsThumbEnum.END) as IWsRangeSliderDirective;

    startInput.min = min.new;
    endInput.min = Math.max(min.new, startInput.value);
    startInput.max = Math.min(endInput.max, endInput.value);

    startInput.updateWidthInactive();
    endInput.updateWidthInactive();

    min.new < min.old
      ? this.onTranslateXChangeBySideEffect(endInput, startInput)
      : this.onTranslateXChangeBySideEffect(startInput, endInput);
  }

  private updateMinNonRange(min: number): void {
    const input = this.getInput(WsThumbEnum.END);
    if (input) {
      input.min = min;
      input.updateThumbUIByValue();
      this.updateTrackUI(input);
    }
  }

  private updateMaxRange(max: {old: number; new: number}): void {
    const startInput = this.getInput(WsThumbEnum.START) as IWsRangeSliderDirective;
    const endInput = this.getInput(WsThumbEnum.END) as IWsRangeSliderDirective;

    endInput.max = max.new;
    startInput.max = Math.min(max.new, endInput.value);
    endInput.min = startInput.value;

    endInput.updateWidthInactive();
    startInput.updateWidthInactive();

    max.new > max.old
      ? this.onTranslateXChangeBySideEffect(startInput, endInput)
      : this.onTranslateXChangeBySideEffect(endInput, startInput);
  }

  private updateMaxNonRange(max: number): void {
    const endInput = this.getInput(WsThumbEnum.END);
    if (endInput) {
      endInput.max = max;
      endInput.updateThumbUIByValue();
      this.updateTrackUI(endInput);
    }
  }

  private updateStepRange(): void {
    const endInput = this.getInput(WsThumbEnum.END) as IWsRangeSliderDirective;
    const startInput = this.getInput(WsThumbEnum.START) as IWsRangeSliderDirective;

    const prevStartValue = startInput.value;

    endInput.min = this.previousMin;
    startInput.max = this.previousMax;

    endInput.step = this.previousStep;
    startInput.step = this.previousStep;

    endInput.min = Math.max(this.previousMin, startInput.value);
    startInput.max = Math.min(this.previousMax, endInput.value);

    startInput.updateWidthInactive();
    endInput.updateWidthInactive();

    endInput.value < prevStartValue
      ? this.onTranslateXChangeBySideEffect(startInput, endInput)
      : this.onTranslateXChangeBySideEffect(endInput, startInput);
  }

  private updateStepNonRange(): void {
    const input = this.getInput(WsThumbEnum.END);
    if (input) {
      input.step = this.previousStep;

      input.updateThumbUIByValue();
    }
  }

  private initUINonRange(endInput: IWsBaseSliderDirective): void {
    endInput.initProps();
    endInput.initUI();

    this.hasViewInitialized.set(true);
    endInput.updateThumbUIByValue();
  }

  private initUIRange(endInput: IWsRangeSliderDirective, startInput: IWsRangeSliderDirective): void {
    endInput.initProps();
    endInput.initUI();

    startInput.initProps();
    startInput.initUI();

    endInput.updateMinMax();
    startInput.updateMinMax();

    endInput.updateStaticStyles();
    startInput.updateStaticStyles();

    this.hasViewInitialized.set(true);;

    endInput.updateThumbUIByValue();
    startInput.updateThumbUIByValue();
  }

  private skipUpdate(): boolean {
    return !!(
      this.getInput(WsThumbEnum.START)?.skipUIUpdate || this.getInput(WsThumbEnum.END)?.skipUIUpdate
    );
  }

  /** Returns true if the slider knobs are overlapping one another. */
  private areThumbsOverlapping(): boolean {
    const startInput = this.getInput(WsThumbEnum.START);
    const endInput = this.getInput(WsThumbEnum.END);
    if (!startInput || !endInput) {
      return false;
    }
    return endInput.translateX - startInput.translateX < 20;
  }

  /**
   * Updates the class names of overlapping slider thumbs so
   * that the current active thumb is styled to be on "top".
   */
  private updateOverlappingThumbClassNames(source: IWsRangeSliderDirective): void {
    const sibling = source.getSibling();
    const sourceThumb = this.getThumb(source.thumbPosition);

    if (sibling) {
      const siblingThumb = this.getThumb(sibling.thumbPosition);
      siblingThumb.hostElement.classList.remove('ws-slider--thumb--top');
      sourceThumb.hostElement.classList.toggle('ws-slider--thumb--top', this.thumbsOverlap());
    }
  }

  /** Updates the UI of slider thumbs when they begin or stop overlapping. */
  private updateOverlappingThumbUI(source: IWsRangeSliderDirective): void {
    if (!this.isRange() || this.skipUpdate()) {
      return;
    }
    if (this.thumbsOverlap() !== this.areThumbsOverlapping()) {
      this.thumbsOverlap.set(! this.thumbsOverlap());
      this.updateOverlappingThumbClassNames(source);
    }
  }

  private updateTrackUIRange(source: IWsRangeSliderDirective): void {
    const sibling = source.getSibling();
    if (!sibling || !this.cachedWidth()) {
      return;
    }

    const activePercentage = Math.abs(sibling.translateX - source.translateX) / this.cachedWidth();

    if (source.isLeftThumb && this.cachedWidth()) {
      this.setTrackActiveStyles({
        left: 'auto',
        right: `${this.cachedWidth() - sibling.translateX}px`,
        transformOrigin: 'right',
        transform: `scaleX(${activePercentage})`,
      });
    } else {
      this.setTrackActiveStyles({
        left: `${sibling.translateX}px`,
        right: 'auto',
        transformOrigin: 'left',
        transform: `scaleX(${activePercentage})`,
      });
    }
  }

  private updateTrackUINonRange(source: IWsBaseSliderDirective): void {
    this.setTrackActiveStyles({
      left: '0px',
      right: 'auto',
      transformOrigin: 'left',
      transform: `scaleX(${source.fillPercentage})`,
    });
  }

  /** Stores the slider dimensions. */
  updateDimensions(): void {
    this.cachedWidth.set(this.elementRef.nativeElement.offsetWidth);
    this.cachedLeft.set(this.elementRef.nativeElement.getBoundingClientRect().left);
  }

  /** Sets the styles for the active portion of the track. */
  private setTrackActiveStyles(styles: {
    left: string;
    right: string;
    transform: string;
    transformOrigin: string;
  }): void {
    const trackStyle = this.trackActive()?.nativeElement.style;

    if (trackStyle !== undefined ) {
      trackStyle.left = styles.left;
      trackStyle.right = styles.right;
      trackStyle.transformOrigin = styles.transformOrigin;
      trackStyle.transform = styles.transform;
    }
  }

  /** Updates the translateX of the given thumb. */
  updateThumbUI(source: IWsBaseSliderDirective) {
    if (this.skipUpdate()) {
      return;
    }
    const thumb = this.getThumb(
      source.thumbPosition === WsThumbEnum.END ? WsThumbEnum.END : WsThumbEnum.START,
    );

    thumb.hostElement.style.transform = `translateX(${source.translateX}px)`;
  }

  /** Updates the scale on the active portion of the track. */
  updateTrackUI(source: IWsBaseSliderDirective): void {
    if (this.skipUpdate()) {
      return;
    }

    this.isRange()
      ? this.updateTrackUIRange(source as IWsRangeSliderDirective)
      : this.updateTrackUINonRange(source as IWsBaseSliderDirective);
  }

  /** Gets the slider thumb input of the given thumb position. */
  getInput(thumbPosition: WsThumbEnum): IWsBaseSliderDirective | IWsRangeSliderDirective | undefined {
    if (thumbPosition === WsThumbEnum.END && this.input()) {
      return this.input();
    }
    if (this.inputs()?.length) {
      return thumbPosition === WsThumbEnum.START ? this.inputs()[0] : this.inputs()[this.inputs().length - 1];
    }
    return;
  }

  /** Gets the slider thumb HTML input element of the given thumb position. */
  getThumb(thumbPosition: WsThumbEnum): IWsSliderThumbComponent {
    return thumbPosition === WsThumbEnum.END ? this.thumbs()[this.thumbs().length - 1] : this.thumbs()[0];
  }

  /** Whether the given pointer event occurred within the bounds of the slider pointer's DOM Rect. */
  isCursorOnSliderThumb(event: PointerEvent, rect: DOMRect) {
    const radius = rect.width / 2;
    const centerX = rect.x + radius;
    const centerY = rect.y + radius;
    const dx = event.clientX - centerX;
    const dy = event.clientY - centerY;
    return Math.pow(dx, 2) + Math.pow(dy, 2) < Math.pow(radius, 2);
  }

  // Handlers for updating the slider ui.
  onTranslateXChange(source: IWsBaseSliderDirective): void {
    if (!this.hasViewInitialized()) {
      return;
    }

    this.updateThumbUI(source);
    this.updateTrackUI(source);
    this.updateOverlappingThumbUI(source as IWsRangeSliderDirective);
  }

  onTranslateXChangeBySideEffect(
    input1: IWsRangeSliderDirective,
    input2: IWsRangeSliderDirective,
  ): void {
    if (!this.hasViewInitialized()) {
      return;
    }

    input1.updateThumbUIByValue();
    input2.updateThumbUIByValue();
  }
}
