export namespace SystemTaskShared {
  export type TaskId = string;

  export enum TaskType {
    ProductImportFrom1C = 'ProductImportFrom1C',
    ProductImportAccessories = 'ProductImportAccessories',
    ProductImportYandexXML = 'ProductImportYandexXML',
    ProductImportGoogleXML = 'ProductImportGoogleXML',
    ProductImportSuppliersXML = 'ProductImportSuppliersXML',
    ESReindexProducts = 'ESReindexProducts',
    ESReindexParams = 'ESReindexParams',
    ESReindexProductParams = 'ESReindexProductParams',
    ESReindexProductSeries = 'ESReindexProductSeries',
    ESReindexParamsReferences = 'ESReindexParamsReferences',
    ESReindexProductReviews = 'ESReindexProductReviews',
    ESReindexProductQuestions = 'ESReindexProductQuestions',
    ESReindexArticles = 'ESReindexArticles',
    ESReindexSolutions = 'ESReindexSolutions',
    ESReindexArticleReviews = 'ESReindexArticleReviews',
    RedirectsCleanUp = 'RedirectsCleanUp',
    PageMetadataCleanUp = 'PageMetadataCleanUp',
    ExportToYandexMarket = 'ExportToYandexMarket',
    ExportToGoogle = 'ExportToGoogle',
    ExportToPP = 'ExportToPP',
    CommitProductTransaction = 'CommitProductTransaction',
    RollbackProductTransaction = 'RollbackProductTransaction',
    ProductsExportToExcel = 'ProductsExportToExcel',
    ProductsImportFromExcel = 'ProductsImportFromExcel',
    SiteMap = 'SiteMap',
    SsrCacheUpdateFromSiteMap = 'SsrCacheUpdateFromSiteMap',
    ProductIsAvailableOnVendorDepotSync = 'ProductIsAvailableOnVendorDepotSync',
    ProductUpdateSimilar = 'ProductUpdateSimilar'
  }

  export enum ProgressType {
    Indeterminate,
    Determinate,
  }

  export type Progress =
    { type: ProgressType.Indeterminate } |
    { type: ProgressType.Determinate; total: number; processed: number };

  export interface Error {
    date: string;
    message: string;
    id?: number;
    link?: string;
  }

  export interface ErrorRequest {
    id?: number;
    link?: string;
    error: any;
  }

  export enum Status {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Completed = 'Completed',
    CompletedWithErrors = 'CompletedWithErrors',
    Interrupted = 'Interrupted',
  }

  export enum ResponseType {
    Simple,
    SimpleWithFile,
    MultiEntries,
    MultiEntriesWithFile,
  }

  export interface BaseResponse {
    type: ResponseType;
    success: boolean;
    fileUri?: string;
    linkUri?: string;
  }

  export interface SimpleResponse extends BaseResponse {
    type: ResponseType.Simple;
  }

  export interface SimpleWithFileResponse extends BaseResponse {
    type: ResponseType.SimpleWithFile;
    fileUri?: string;
  }

  export interface MultiEntriesResponse extends BaseResponse {
    resultId?: number;
    type: ResponseType.MultiEntries;
    total: number;
    processed: number;
  }

  export interface MultiEntriesWithFileResponse extends BaseResponse {
    resultId?: number;
    type: ResponseType.MultiEntriesWithFile;
    total: number;
    processed: number;
  }

  export type Response = SimpleResponse | SimpleWithFileResponse | MultiEntriesResponse | MultiEntriesWithFileResponse;

  export interface Task<T extends TaskType = any, R extends Response = any> {
    id: TaskId;
    type: T;
    progress: Progress;
    errors: Array<Error>;
    response?: R;
    status: Status;
    startedAt: string;
    completedAt?: string;
    onCleanUp?(): Promise<void>;
  }

  export function nextTaskId(): string {
    const letters = '0123456789ABCDEF';
    let result = '';

    for (let i = 0; i < 12; i++) {
      result += letters[Math.floor(Math.random() * 16)];
    }
    return result;
  }
}
