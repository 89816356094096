<span class="c">
  <div class="c-data">
    <div class="message">
      <span>Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, которые обеспечивают правильную работу сайта и соглашаетесь с нашей <a target="_blank" href="/privacy">Политикой конфиденциальности</a>.</span>
    </div>
    <div class="c-close" (click)="close()">
      <img src="/assets/images/Union.svg" loading="lazy" />
    </div>
  </div>
</span>
