import { SystemTaskShared } from './system-task.shared';

export namespace IndexShared {
    export type SystemTaskTypes =
        SystemTaskShared.TaskType.ESReindexProducts |
        SystemTaskShared.TaskType.ESReindexArticles |
        SystemTaskShared.TaskType.ESReindexSolutions |
        SystemTaskShared.TaskType.ESReindexParams |
        SystemTaskShared.TaskType.ESReindexProductParams |
        SystemTaskShared.TaskType.ESReindexParamsReferences |
        SystemTaskShared.TaskType.ESReindexProductReviews |
        SystemTaskShared.TaskType.ESReindexProductQuestions |
        SystemTaskShared.TaskType.ESReindexArticleReviews |
        SystemTaskShared.TaskType.ESReindexProductSeries;

    export enum IndexType {
        Articles = 'Articles',
        Solutions = 'Solutions',
        Params = 'Params',
        Products = 'Products',
        ProductParams = 'ProductParams',
        ProductSeries = 'ProductSeries',
        ParamsReferences = 'ParamsReferences',
        ProductReviews = 'ProductReviews',
        ProductQuestions = 'ProductQuestions',
        ArticleReviews = 'ArticleReviews',
    }

    export enum IndexDomainScope {
        ArticleAuthors = 'ArticleAuthors',
        ArticleCategories = 'ArticleCategories',
        Cities = 'Cities',
        Currencies = 'Currencies',
        Manufacturers = 'Manufacturers',
        Metatags = 'Metatags',
        ProductCategories = 'ProductCategories',
        ParamsCategories = 'ParamsCategories',
        ProductsInProductCategories = 'ProductsInProductCategories',
        ProductsInProductCategorySeoLinks = 'ProductsInProductCategorySeoLinks',
        ProductCategorySeoLinks = 'ProductCategorySeoLinks',
        Redirects = 'Redirects',
        Tags = 'Tags',
        WebDiscountedStats = 'WebDiscountedStats'
    }
}
