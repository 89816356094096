import { Observable } from 'rxjs';
import { IsEnum, IsInt, IsNotEmpty, IsPositive, IsOptional, IsString, IsArray } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';
import { QuotationRequestSendRequest } from '../endpoints-web/quotation-request.web-endpoint';
import { QuotationRequestDto } from '../models/entities/quotation-request.dto';
import { QuotationRequestShared } from '../models/shared/quotation-request.shared';

export enum QuotationRequestEndpointPaths {
    send = '/api/v1/quotation-request/send',
    setStatus = '/api/v1/quotation-request/setStatus',
    delete = '/api/v1/quotation-request/delete',
    assign = '/api/v1/quotation-request/assign',
    unassign = '/api/v1/quotation-request/unassign',
    list = '/api/v1/quotation-request/list',
    getById = '/api/v1/quotation-request/getById',
}

export interface QuotationRequestEndpoint {
    send(request: QuotationRequestSendRequest): Promise<QuotationRequestDto> | Observable<QuotationRequestDto>;
    setStatus(request: QuotationRequestSetStatusRequest): Promise<QuotationRequestDto> | Observable<QuotationRequestDto>;
    delete(request: QuotationRequestDeleteRequest): Promise<void> | Observable<void>;
    assign(request: QuotationRequestAssignRequest): Promise<QuotationRequestDto> | Observable<QuotationRequestDto>;
    unassign(request: QuotationRequestUnassignRequest): Promise<QuotationRequestDto> | Observable<QuotationRequestDto>;
    list(request: QuotationRequestListRequest): Promise<QuotationRequestListResponse> | Observable<QuotationRequestListResponse>;
    getById(request: QuotationRequestGetByIdRequest): Promise<QuotationRequestDto> | Observable<QuotationRequestDto>;
}

export class QuotationRequestSetStatusRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(QuotationRequestShared.Status),
    })
    @IsNotEmpty()
    newStatus: QuotationRequestShared.Status;

    @IsOptional()
    @ApiModelProperty()
    @IsString()
    comment?: string;
}

export class QuotationRequestDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class QuotationRequestAssignRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    accountEmail: string;
}

export class QuotationRequestUnassignRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class QuotationRequestListRequest extends CrudListRequestDto<QuotationRequestDto> {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(QuotationRequestShared.Status),
        isArray: true,
        required: false,
    })
    @IsArray()
    @IsEnum(QuotationRequestShared.Status, {
        each: true,
    })
    @IsOptional()
    statuses?: Array<QuotationRequestShared.Status>;
}

export class QuotationRequestListResponse extends CrudListResponseDto<QuotationRequestDto> {
    @ApiModelProperty({
        type: QuotationRequestDto,
    })
    items: Array<QuotationRequestDto>;
}

export class QuotationRequestGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
