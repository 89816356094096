export const interidSiteWebFeatureCatalogI18n: any = {
    catalog: {
        components: {
            catalog_param_mobile_screen: {
                title: 'Фильтры',
                reset: 'Сбросить',
                submit: 'Применить'
            },
            catalog: {
                description: 'Описание раздела',
                questions: 'Вопросы о товарах'
            },
            catalog_param_reference: {
                dictionary: 'Словарь терминов',
            },
            catalog_view_lvl1: {
                more: 'Смотреть все',
                min_price: 'от {{ price }} ₽'
            },
            catalog_category_customer_choice: {
                title: 'Самые популярные в этой категории',
            },
            catalog_category_product_reviews: {
                title: 'Популярные отзывы',
                moreReviews: 'Все отзывы',
                comments: 'Комментарии',
                positive: 'Достоинства',
                negative: 'Недостатки'
            },
            catalog_category_parent_seolinks: {
                title: 'С этими товарами ищут'
            },
            catalog_products_viewed: {
                title: 'Вы недавно смотрели',
                all: 'Смотреть все'
            }
        },
    },
};
