import { ManufacturerListDto } from './manufacturer.dto';
import { ProductCategoryListDto } from './product-category.dto';
import { AttachmentDto } from './attachment.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductDto } from './product.dto';

export class ProductSeriesFlagsDto {
  @ApiModelProperty()
  isEnabled: boolean;

  @ApiModelProperty()
  isEnabledForFilters: boolean;

  @ApiModelProperty()
  isEnabledForSearch: boolean;
}

export class ProductSeriesDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  productIds: Array<number>;

  @ApiModelProperty()
  products: Array<ProductDto>;

  @ApiModelProperty()
  relatedForManufacturerIds: Array<number>;

  @ApiModelProperty()
  relatedForManufacturers: Array<ManufacturerListDto>;

  @ApiModelProperty()
  relatedForProductCategoryIds: Array<number>;

  @ApiModelProperty()
  relatedForProductCategories: Array<ProductCategoryListDto>;

  @ApiModelProperty()
  productsInSeries?: Array<ProductDto>;

  @ApiModelProperty()
  flags: ProductSeriesFlagsDto;

  @ApiModelProperty()
  icon?: AttachmentDto;

  @ApiModelProperty()
  image?: AttachmentDto;
}

export class ProductSeriesListDto {

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  title: string;
}
