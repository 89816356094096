<!-- Inputs -->
<ng-content></ng-content>

<!-- Track -->
<div class="ws-slider--track">
  <div class="ws-slider--track--inactive"></div>
  <div class="ws-slider--track--active">
    <div #trackActive class="ws-slider--track--active_fill"></div>
  </div>
</div>

<!-- Thumbs -->
@if (isRange()) {
  <ws-slider-thumb [thumbPosition]="2"></ws-slider-thumb>
}

<ws-slider-thumb [thumbPosition]="1"></ws-slider-thumb>