export namespace DeliveryPeriodShared
{
  export enum DaysType {
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    FixedDay = 'FixedDay',
    RangeDays = 'RangeDays',
    Order = 'Order',
  }

  export type Days =
    { type: DaysType.Today } |
    { type: DaysType.Tomorrow } |
    { type: DaysType.FixedDay; day: number } |
    { type: DaysType.RangeDays; dayFrom: number; dayTo: number } |
    { type: DaysType.Order }
  ;

  export enum OrderBeforeType {
    None = 'None',
    Manual = 'Manual',
  }

  export type OrderBefore =
    { type: OrderBeforeType.None } |
    { type: OrderBeforeType.Manual; hours: number }
  ;
}
