import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SortOrderShared } from '../shared/sort-order.shared';
import { AttachmentDto } from './attachment.dto';

export class DictionaryValueDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()

  sortOrder: number;

  @ApiModelProperty()

  dictionaryId: number;

  @ApiModelProperty()
  value: string;

  @ApiModelProperty()
  tooltip: string;

  @ApiModelProperty()
  hex: string;

  @ApiModelProperty()
  icon: AttachmentDto;
}
