import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductCardHelper } from '../../helpers/product-card.helper';

type Badge = 'sale' | 'discount' | 'new' | 'hit' | 'customer-choice' | 'popular' | 'out-of-production' | 'is-available-depot' | 'is-available-vendor-depot';
type FontSize = 'default' | 'small';

export const DEFAULT_PRODUCT_BADGES: Array<Badge> = ['out-of-production', 'sale', 'new', 'hit', 'customer-choice', 'popular', 'is-available-vendor-depot', 'is-available-depot'];

export { Badge as ProductCardBadgesAComponentBadge };

@Component({
    selector: 'app-shared-product-card-assets-badges-a',
    templateUrl: './product-card-badges-a.component.html',
    styleUrls: ['./product-card-badges-a.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardBadgesAComponent {
    @Input() resolvedData: any;
    @Input() maxBadgeWidth = 180;
    @Input() filter: Array<Badge> = [...DEFAULT_PRODUCT_BADGES];
    @Input() fontSize: FontSize = 'default';
    @Input() flexPrices = true;
    @Input() analogBage = false;
    @Input() cheapestBage = false;
    @Input() product: any;

    constructor(
        public readonly helper: ProductCardHelper,
    ) {
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    get ngClass(): any {
        return [`font-size-${this.fontSize}`];
    }

    get badgeNgStyle(): any {
        return {
            'max-width': `${this.maxBadgeWidth}px`,
        };
    }

    isBadgeEnabled(badge: Badge): boolean { 
        return this.filter.includes(badge);
    }
}
