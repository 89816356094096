export namespace ProductTransactionShared
{
  export enum Status {
    Ready = 'Ready',
    CommitInProgress = 'CommitInProgress',
    RollbackInProgress = 'RollbackInProgress',
    Interrupted = 'Interrupted',
    Failed = 'Failed',
    Completed = 'Completed',
    CompletedWithErrors = 'CompletedWithErrors',
  }
}
