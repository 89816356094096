<div class="c">
  <div class="content favorites">
    <button [ngClass]="{ 'is-active': helper.favorite.isInFavorites$ | async }" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleRadius]="20" class="color-blue-gray-light" (click)="helper.favorite.toggleFavorites()">
      <div class="c-icon">
        <div class="icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="hover-fill" d="M20.16 4.996a6.29 6.29 0 0 0-8.16-.64 6.27 6.27 0 0 0-8.16 9.48l6.21 6.22a2.78 2.78 0 0 0 3.9 0l6.21-6.22a6.27 6.27 0 0 0 0-8.84Zm-1.41 7.46-6.21 6.21a.76.76 0 0 1-1.08 0l-6.21-6.24a4.29 4.29 0 0 1 0-6 4.27 4.27 0 0 1 6 0 .999.999 0 0 0 1.42 0 4.27 4.27 0 0 1 6 0 4.29 4.29 0 0 1 .08 6v.03Z" fill="#253238" /></svg>
        </div>
      </div>
    </button>
  </div>
  <div class="content">
    <button [ngClass]="{ 'is-active': helper.compare.isInCompares$ | async }" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleRadius]="20" class="color-blue-gray-light" (click)="helper.compare.toggleCompares()">
      <div class="c-icon">
        <div class="icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="hover-fill" d="M5 15a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-5a1 1 0 0 0-1-1Zm5-3a1 1 0 0 0-1 1v8a1 1 0 1 0 2 0v-8a1 1 0 0 0-1-1Zm10 5a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3a1 1 0 0 0-1-1ZM15 7a1 1 0 0 0-1 1v13a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1ZM9 6H6V3a1 1 0 0 0-2 0v3H1a1 1 0 0 0 0 2h3v3a1 1 0 1 0 2 0V8h3a1 1 0 0 0 0-2Z" fill="#253238" /></svg>
        </div>
      </div>
    </button>
  </div>
  <div></div>
</div>
