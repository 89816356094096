import { Observable } from 'rxjs';
import { IsInt, IsOptional, IsPositive } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BonusDto } from '../models/entities/bonus.dto';

export enum BonusWebEndpointPaths {
    list = '/api/v1/web/bonus/list',
}

export interface BonusWebEndpoint {
    list(request: BonusListWebRequest, req?: any): Promise<BonusListWebResponse> | Observable<BonusListWebResponse>;
}

export class BonusListWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;
}

export class BonusListWebResponse {
    @ApiModelProperty({
        type: BonusDto,
        isArray: true,
    })
    bonuses: Array<BonusDto>;

    @ApiModelProperty()
    sum: number;

    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty()
    total: number;
}
