import { Pipe, PipeTransform } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { H1Service, isH1NotEmpty } from '@interid/interid-site-web/core';

export const H1_PIPE_NAVIGATION_TRIGGER: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

@Pipe({ name: 'h1$' })
export class H1Pipe implements PipeTransform {
    constructor(
        private readonly service: H1Service,
    ) {
    }

    transform(value: any, ...args: any[]): any {
        return H1_PIPE_NAVIGATION_TRIGGER.pipe(
            switchMap(() => this.service.h1$.pipe(
                switchMap(
                    (h1) => isH1NotEmpty(h1)
                        ? of(h1)
                        : of(value),
                ),
            )),
        );
    }
}
