import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { AccountEndpoint, AccountEndpointPaths, ChangePasswordRequest, ConfirmEmailRequest, ConfirmEmailResponse, CurrentAccountResponse, RegisterAccountRequest, ResetPasswordRequest, ResetPasswordResponse, SendConfirmEmailLinkRequest, SendResetPasswordLinkRequest, SetAccountSettingsRequest, SetCityAccountRequest, UpdateProfileRequest, UpdateProfileResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebAccountDataAccess implements AccountEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    currentAccount(): Observable<CurrentAccountResponse> {
        return this.http.get(AccountEndpointPaths.currentAccount)
    }

    registerAccount(request: RegisterAccountRequest, recaptchaV3Token: string): Observable<void> {
        return this.http.post(AccountEndpointPaths.registerAccount, request, {
            headers: {
                'recaptcha': recaptchaV3Token,
            },
        })
    }

    confirmEmail(request: ConfirmEmailRequest): Observable<ConfirmEmailResponse> {
        return this.http.post(AccountEndpointPaths.confirmEmail, request)
    }

    sendConfirmEmailLink(request: SendConfirmEmailLinkRequest): Observable<void> {
        return this.http.post(AccountEndpointPaths.sendConfirmEmailLink, request)
    }

    setIsCookiePolicyAccepted(): Observable<void> {
        return this.http.post(AccountEndpointPaths.setIsCookiePolicyAccepted)
    }

    changePassword(request: ChangePasswordRequest): Observable<void> {
        return this.http.post(AccountEndpointPaths.changePassword, request)
    }

    sendResetPasswordLink(request: SendResetPasswordLinkRequest, recaptchaV3Token: string): Observable<void> {
        return this.http.post(AccountEndpointPaths.sendResetPasswordLink, request, {
            headers: {
                'recaptcha': recaptchaV3Token,
            },
        })
    }

    resetPassword(request: ResetPasswordRequest): Observable<ResetPasswordResponse> {
        return this.http.post(AccountEndpointPaths.resetPassword, request)
    }

    updateProfile(request: UpdateProfileRequest): Observable<UpdateProfileResponse> {
        return this.http.post(AccountEndpointPaths.updateProfile, request)
    }

    setCity(request: SetCityAccountRequest): Observable<void> {
        return this.http.post(AccountEndpointPaths.setCity, request)
    }

    updateSettings(request: SetAccountSettingsRequest): Observable<void> {
        return this.http.post(AccountEndpointPaths.updateAccountSettings, request)
    }
}
