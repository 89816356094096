import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class ProductMarketplacesDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  isExportedToYandex: boolean;

  @ApiModelProperty()
  isExportedToGoogle: boolean;

  @ApiModelProperty()
  isExportedToSuppliers: boolean;
}
