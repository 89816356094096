import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DictionaryDto } from './dictionary.dto';
import { ProductCategoryListDto } from './product-category.dto';
import { SortOrderShared } from '../shared/sort-order.shared';
import { ParamsShared } from '../shared/params.shared';
import { ParamsCategoryDto } from './params-category.dto';

export class ParamsDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  displayInProductDetails: boolean;

  @ApiModelProperty()
  displayInProductCard: boolean;

  @ApiModelProperty()
  displayInSearchFilters: boolean;

  @ApiModelProperty()
  notDisplayInCharacteristics: boolean;

  @ApiModelProperty()
  similarProduct: boolean;
 
  @ApiModelProperty()
  displayInFilters: boolean;

  @ApiModelProperty()
  displayInModifications: boolean;

  @ApiModelProperty()
  displayDictionaryIcon: boolean;

  @ApiModelProperty()
  isExportedToMarketplaceParams: boolean;

  @ApiModelProperty()
  isSharedParams: boolean;

  @ApiModelProperty()
  productCategories: Array<ProductCategoryListDto>;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  paramsCategory: ParamsCategoryDto;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  type: ParamsShared.ParamsType;

  @ApiModelProperty()
  descriptionAnnotationForProductType: ParamsShared.DescriptionAnnotationForProductType;

  @ApiModelProperty()
  dict?: DictionaryDto;

  @ApiModelProperty()
  dicts?: Array<DictionaryDto>;

  @ApiModelProperty()
  dictId: number;
}

export class ParamsListDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  productCategoryIds: Array<number>;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  title: string;
}
