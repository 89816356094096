import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PagesShared } from '../shared/pages.shared';
import { VacancyDto } from './vacancy.dto';
import { SolutionESDto } from '@interid/interid-site-shared';

export class AboutDto {
    @ApiModelProperty()
    aboutCompanyPage: PagesShared.AboutCompanyPage;

    @ApiModelProperty()
    requisitesPage: PagesShared.RequisitesPage;

    @ApiModelProperty()
    marketplacesPage: PagesShared.TradePostsPage;

    @ApiModelProperty()
    vacanciesPage: PagesShared.VacanciesPage;

    @ApiModelProperty()
    vacancies: Array<VacancyDto>;

    @ApiModelProperty({
        type: SolutionESDto,
        isArray: true,
    })
    solutions: Array<SolutionESDto>;
}
