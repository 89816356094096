import { Injectable } from "@angular/core";
import { AboutResponse, AboutWebEndpoint, AboutWebEndpointPaths } from '@interid/interid-site-shared';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebAboutDataAccess implements AboutWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    about(): Observable<AboutResponse> {
        return this.http.post(AboutWebEndpointPaths.about);
    }
}
