
<div class="c" (click)="select()" [ngClass]="ngClass">
  <div class="c-box">
    @if (state.isSelected) {
      <div class="c-box-mark"></div>
    }
  </div>
  @if (label) {
    <div class="c-label"><span>{{ label }}</span></div>
  }
</div>