import { IsArray, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, Min, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO } from '../models/shared/common.shared';
import { SolutionBlockDto, SolutionDto, SolutionListDto } from '../models/entities/solution.dto';
import { ContentDTO } from '../models/shared/common-content.shared';
import { Observable } from 'rxjs';
import { CrudSetSortOrderRequestDto } from '../core/crud';

export enum SolutionEndpointPaths {
    list = '/api/v1/solution/list',
    listAll = '/api/v1/solution/listAll',
    getById = '/api/v1/solution/getById',
    create = '/api/v1/solution/create',
    edit = '/api/v1/solution/edit',
    publish = '/api/v1/solution/publish',
    moveToDraft = '/api/v1/solution/moveToDraft',
    delete = '/api/v1/solution/delete',
    setSortOrder = '/api/v1/solution/setSortOrder',
}

export interface SolutionEndpoint {
    list(request: SolutionListRequest): Promise<SolutionListResponse> | Observable<SolutionListResponse>;
    listAll(): Promise<Array<SolutionListDto>> | Observable<Array<SolutionListDto>>;
    getById(request: SolutionGetByIdRequest): Promise<SolutionDto> | Observable<SolutionDto>;
    create(request: SolutionCreateRequest): Promise<SolutionDto> | Observable<SolutionDto>;
    edit(request: SolutionEditRequest): Promise<SolutionDto> | Observable<SolutionDto>;
    publish(request: SolutionPublishRequest): Promise<SolutionDto> | Observable<SolutionDto>;
    moveToDraft(request: SolutionMoveToDraftRequest): Promise<SolutionDto> | Observable<SolutionDto>;
    delete(request: SolutionDeleteRequest): Promise<void> | Observable<void>;
    setSortOrder(request: SolutionSetOrderRequest): Promise<void> | Observable<void>;
}

export class SolutionEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    solutionCategoryId: number;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    relatedProductCategoryIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    relatedProductIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    tagIds: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uri: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    alt: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    annotation?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    description?: string;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    contents: ContentDTO;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    coverAttachmentId: number;

    @ApiModelProperty({
        required: false,
    })
    @IsOptional()
    @IsString()
    totalCost: string;

    @ApiModelProperty({
        type: SolutionBlockDto,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    blocks: Array<SolutionBlockDto>;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    seo: PageMetadataSEO;
}

export class SolutionCreateRequest extends SolutionEntityBody {
}

export class SolutionEditRequest extends SolutionEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SolutionPublishRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SolutionMoveToDraftRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SolutionDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SolutionListRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    solutionCategoryId?: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;
}

export class SolutionListResponse {
    @ApiModelProperty()
    total: number;

    @ApiModelProperty({
        type: SolutionDto,
        isArray: true,
    })
    entities: Array<SolutionDto>;
}

export class SolutionGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SolutionSetOrderRequest extends CrudSetSortOrderRequestDto {}
