import { ExtraOptions } from "@angular/router";

export class RouterConfiguration implements ExtraOptions {
    enableTracing: false = false;
    scrollPositionRestoration: 'disabled' | 'top' = 'disabled';
    anchorScrolling: 'enabled' = 'enabled';
    onSameUrlNavigation: 'reload' = 'reload';
    urlUpdateStrategy: 'eager' = 'eager';
    relativeLinkResolution: 'corrected' = 'corrected';
}
