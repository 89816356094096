import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsOptional } from 'class-validator';
import { Observable } from 'rxjs';
import { SystemTaskDto } from '../models/entities/system-task.dto';
import { SystemTaskShared } from '../models/shared/system-task.shared';

export enum ProductUpdateSimilarEndpointPaths {
  sync = '/api/v1/product-update-similar/sync',
}

export interface ProductUpdateSimilarEndpoint {
  sync(request: ProductUpdateSimilarRequest): Promise<ProductUpdateSimilarResponse> | Observable<ProductUpdateSimilarResponse>;
}

export class ProductUpdateSimilarResponse {
  @ApiModelProperty({
      type: SystemTaskDto,
  })
  systemTask: SystemTaskDto<SystemTaskShared.TaskType.ProductUpdateSimilar, SystemTaskShared.MultiEntriesResponse>;
}

export class ProductUpdateSimilarRequest {
  @ApiModelProperty()
  @IsArray()
  @IsOptional()
  productIds?: Array<number>;

  @ApiModelProperty({
      required: false,
  })
  @IsBoolean()
  @IsOptional()
  skipWait?: boolean;
}