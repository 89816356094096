<div class="c">
  <div class="c-items">
    @for (item of payload().items; track item) {
      <div class="c-item">
        <span class="title">{{item.title}}</span>
        <span class="subtitle">{{item.subtitle}}</span>
      </div>
    }
  </div>
</div>
