/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, EventEmitter, input, Input, Output, signal } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl } from '@angular/forms';

interface FormValue {
    enabled: boolean;
}

interface State {
    form: UntypedFormGroup;
}

@Component({
    selector: 'app-shared-ui-checkbox-color',
    templateUrl: './ui-checkbox-color.component.html',
    styleUrls: ['./ui-checkbox-color.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UICheckboxColorComponent implements ControlValueAccessor {
    public color = input<string>();

    @Output('checkbox-change') changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    private onChange: Function;
    private onTouched: Function;

    public state = signal<State>({
        form: this.fb.group({
            enabled: [undefined],
        }),
    });

    get formValue(): FormValue {
        return this.state().form.value;
    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state().form.patchValue(formValue);
    }

    constructor(
        public ngControl: NgControl,
        private readonly fb: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef
    ) {
        ngControl.valueAccessor = this;
    }

    ngStyle(color): any {
        if (color.match(/^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i)) {
            return { 'background-color': color, border: '1px solid grey' };
        } else {
            return { 'background-color': color };
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.state().form.disable() : this.state().form.enable();
    }

    writeValue(obj: any): void {
        this.patchFormValue = {
            enabled: obj === null || obj === undefined ? undefined : !!obj,
        };

        this.cdr.markForCheck();
    }

    toggle(): void {
        this.onTouched();

        this.patchFormValue = {
            enabled: !this.formValue.enabled,
        };

        this.onChange(this.formValue.enabled);
        this.changeEvent.emit(this.formValue.enabled);
    }
}
