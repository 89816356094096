import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ContentDTO } from '../shared/common-content.shared';
import { ProductCategoryDto } from './product-category.dto';
import { ParamsDto } from './params.dto';

export class ParamsReferenceDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty()
    isEnabled: boolean;

    @ApiModelProperty()
    productCategoryId: number;

    @ApiModelProperty()
    productCategory: ProductCategoryDto;

    @ApiModelProperty()
    webUrl: string;

    @ApiModelProperty()
    paramsId: number;

    @ApiModelProperty()
    params: ParamsDto;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    alt: string;

    @ApiModelProperty({
        type: () => ContentDTO,
    })
    descriptionForTooltip: ContentDTO;

    @ApiModelProperty({
        type: () => ContentDTO,
    })
    descriptionFull: ContentDTO;
}
