
<div class="c" [ngClass]="ngClass" #container>
  <div class="c-options">
    @for (option of state.options; track option) {
      <div class="c-option" [ngClass]="{ 'is-selected': isValueSelected(option.value), 'is-current-value': isValueCurrent(option.value) }" (click)="select(option)">
        <div class="c-option-label"><span>{{ valueToLabel(option.value) }}</span></div>
      </div>
    }
  </div>
  @if (multiple || singleselect) {
    <div class="c-submit">
      <app-shared-ui-button buttonColor="primary" (click)="submit()"><span>{{ t('submit') | translate }}</span></app-shared-ui-button>
    </div>
  }
</div>
