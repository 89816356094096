import { Injectable } from '@angular/core';
import { genericRetryStrategy, MessagesService } from '@interid/interid-site-web/core';
import { AppSessionAddToComparesRequest, AppSessionRemoveComparesRequest, AppSessionService } from '@interid/interid-site-web/core-session';
import { combineLatest, Observable } from 'rxjs';
import { map, retryWhen, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CompareService {
    constructor(
        private readonly appSession: AppSessionService,
        private readonly messages: MessagesService,
    ) {}

    private t(input: string): string {
        return `product.shared.services.compares.${input}`;
    }

    addProductToCompare(request: AppSessionAddToComparesRequest): Observable<void> {
        return combineLatest([this.appSession.addToCompares(request), this.appSession.numCompares$]).pipe(
            retryWhen(genericRetryStrategy()),
            take(1),
            map(([response, count]) => {
                this.messages.basketCompare({
                    translate: true,
                    message: this.t('added'),
                    translateParams: {
                        title: request.productTitle,
                    },
                }, 'compare', count);

                return response;
            }),
        );
    }

    removeProductFromCompare(request: AppSessionRemoveComparesRequest): Observable<void> {
        return combineLatest([this.appSession.deleteFromCompares(request), this.appSession.numCompares$]).pipe(
            retryWhen(genericRetryStrategy()),
            take(1),
            map(([response, count]) => {
                this.messages.basketCompare({
                    translate: true,
                    message: this.t('delete'),
                    translateParams: {
                        title: request.productTitle,
                    },
                }, 'compare', count);

                return response;
            }),
        );
    }
}
