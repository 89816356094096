import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CommonContentWebBundleShared } from '../models/shared/common-content.shared';
import { PagesShared } from '../models/shared/pages.shared';
import { CityDto } from '../models/entities/city.dto';

export enum DeliveryWebEndpointPaths {
    delivery = '/api/v1/web/delivery',
}

export interface DeliveryWebEndpoint {
    delivery(request: DeliveryWebRequest): Promise<DeliveryWebResponse> | Observable<DeliveryWebResponse>;
}


export class DeliveryWebRequest {
    @ApiModelProperty()
    city: CityDto;
}

export class DeliveryWebResponse {
    @ApiModelProperty()
    dto: PagesShared.DeliveryPage;

    @ApiModelProperty()
    bundle: CommonContentWebBundleShared.Bundle;
}
