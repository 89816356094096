import * as url from 'url';
import * as qs from 'querystring';

export const QP_BLACKLIST = [
  'limit',
  'category_id',
  'category_ids',
  'categories_id',
  'categories_ids',
  'utm',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_campaign',
  'utm_content',
  'roistat',
  'roistat_referrer',
  'roistat_pos',
  'calltouch_tm',
  'yclid',
  'etext',
  '_openstat'
];

export function urlRemoveBlacklistQp(input: string): string {
  const parsed = url.parse(input);

  if (parsed.query) {
    const qp = qs.decode(parsed.query);

    for (const blacklist of QP_BLACKLIST) {
      if (qp[blacklist]) {
        delete qp[blacklist];
      }
    }

    if (Object.keys(qp).length > 0) {
      return `${parsed.pathname}?${qs.encode(qp)}`;
    } else {
      return `${parsed.pathname}`;
    }
  } else {
    return input;
  }
}
