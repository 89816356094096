import { AttachmentImageStrategyShared, AttachmentShared } from '../shared/attachment.shared';
import { ProductSearchSortRequest } from '../../endpoints/product.endpoint';
import { ProductShared } from '../shared/product.shared';

export const productDefaultPriceTax = 20;

export const productImagePairRequest: AttachmentShared.Strategies.PairRequest = {
    strategy: AttachmentShared.Strategies.Strategy.Image,
    payload: {
        minFileSize: 1,
        maxFileSize: 1024 /* b */ * 1024 /* kb */ * 250 /* mb */,
        sizesSet: AttachmentImageStrategyShared.SizesSet.Product,
    },
};

export const productFilesPairRequest: AttachmentShared.Strategies.PairRequest = {
    strategy: AttachmentShared.Strategies.Strategy.Common,
    payload: {},
};

export const productsDefaultSortOrder: Array<ProductSearchSortRequest> = [
    {
        field: ProductShared.ProductSearchSort.RankGroup,
        direction: ProductShared.ProductSearchSortDirection.Asc,
    },
    {
        field: ProductShared.ProductSearchSort.Rank,
        direction: ProductShared.ProductSearchSortDirection.Desc,
    },
    {
        field: ProductShared.ProductSearchSort.DatePublished,
        direction: ProductShared.ProductSearchSortDirection.Asc,
    },
];
