import { ChangeDetectionStrategy, Component, input, Input } from '@angular/core';
import { ContentBlockSimpleImageTable } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-simple-image-table',
    templateUrl: './content-block-simple-image-table.component.html',
    styleUrls: ['./content-block-simple-image-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockSimpleImageTableComponent {
    public payload = input<ContentBlockSimpleImageTable>();
}
