<div class='c'>
  <div class='c-photos' [ngStyle]='cPhotosNgStyle'>
    @for (photo of payload().images; track trackByAttachmentId(photo)) {
      <div class='c-photo'
        (click)='openImageGallery(photo)'>
        <div class='c-photo-container'><img [src]='photo.url' [ngStyle]='cImgNgStyle' /></div>
      </div>
    }
  </div>
</div>
