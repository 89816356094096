import { IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ArticleAuthorDto } from '../models/entities/article-author.dto';
import { CrudSetSortOrderRequestDto } from '../core/crud';

export enum ArticleAuthorEndpointPaths {
    list = '/api/v1/article-author/list',
    create = '/api/v1/article-author/create',
    edit = '/api/v1/article-author/edit',
    delete = '/api/v1/article-author/delete',
    setSortOrder = '/api/v1/article-author/setSortOrder',
}

export interface ArticleAuthorEndpoint {
    list(): Promise<ArticleAuthorListResponse> | Observable<ArticleAuthorListResponse>;
    create(request: ArticleAuthorCreateRequest): Promise<ArticleAuthorDto> | Observable<ArticleAuthorDto>;
    edit(request: ArticleAuthorEditRequest): Promise<ArticleAuthorDto> | Observable<ArticleAuthorDto>;
    delete(request: ArticleAuthorDeleteRequest): Promise<void> | Observable<void>;
    setSortOrder(request: ArticleAuthorSortOrderRequest): Promise<void> | Observable<void>;
}

export class ArticleAuthorEntityBody {
    @ApiModelProperty()
    @IsNotEmpty()
    @IsString()
    fullName: string;
}

export type ArticleAuthorListResponse = Array<ArticleAuthorDto>;

export class ArticleAuthorCreateRequest extends ArticleAuthorEntityBody {
}

export class ArticleAuthorEditRequest extends ArticleAuthorEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ArticleAuthorDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ArticleAuthorSortOrderRequest extends CrudSetSortOrderRequestDto {}
