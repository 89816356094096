const orderWithQuotationRequest = `
Запрос на дешевле

Товар: {{ product.title }} ({{ product.productCategory.title }})
Код товара: {{ product.id }}
Партномер: {{ product.partNumber }}

URL: {{ url }}
`;


export const interidSiteWebFeatureProductI18n = {
    product_details: {
        _: {
            tab: {
                description: 'Описание',
                params: 'Характеристики',
                modifications: 'Модификации',
                consumables: 'Расходные материалы',
                accessories: 'Аксессуары',
                reviewsAndQuestions: 'Отзывы и вопросы',
                videos: 'Видео и обзоры',
                documentation: 'Инструкции и драйвера',
            },
        },
        layouts: {
            product_details_desktop: {
                same: 'Похожие товары',
                analogs: 'Аналоги для товара {{title}}',
                useful: 'Может пригодиться',
                sets: 'Этот товар из подборок',
                recommended: 'Сопутствующие товары',
                preview: 'Предпросмотр: этот товар не опубликован на сайте и скрыт от других посетителей',
                archived: 'Этот товар находится в архиве и скрыт от других посетителей'
            },
            product_details_mobile: {
                same: 'Похожие товары',
                analogs: 'Аналоги для товара {{title}}',
                useful: 'Может пригодиться',
                sets: 'Этот товар из подборок',
                recommended: 'Сопутствующие товары',
                preview: 'Предпросмотр: этот товар не опубликован на сайте и скрыт от других посетителей',
                archived: 'Этот товар находится в архиве и скрыт от других посетителей'
            },
        },
        components: {
            product_details_gallery: {
                warranty: 'Гарантия производителя {{warranty}}'
            },
            product_details_subheader: {
                productCode: 'Код товара',
                partNumber: 'Артикул',
                reviews_0: '{{ count }} комментариев',
                reviews_1: '{{ count }} комментарий',
                reviews_2: '{{ count }} комментария',
                purchased_1: 'Купили {{ count }} раз',
                purchased_2: 'Купили {{ count }} раза',
                purchased_3: 'Купили более {{ count }} раз',
            },
            product_details_attachments:{
                title: 'Инструкции и драйвера',
                drivers: 'Драйверы и ПО',
                instructions: "Инструкции",
                table: {
                    name: 'Наименование',
                    extension: 'Расширение',
                    length: 'Размер',
                    date: 'Опубликовано',
                    language: 'Язык'
                }
            },
            product_details_description: {
                title: 'Описание',
                allParams: 'Все характеристики',
                manufacturer: 'Производитель',
                count_products_0: '{{ count }} товаров',
                count_products_1: '{{ count }} товар',
                count_products_2: '{{ count }} товара',
            },
            product_details_modifications:{
                allmodifications: 'Все модификации ({{count}})',
                available: 'В наличии',
                vendordepot: 'На складе производителя'
            },
            product_details_useful: {
                title: 'Может пригодиться'
            },
            product_details_category_seo_links: {
                title: 'Часто ищут'
            },
            product_details_documentation: {
                title: 'Документация',
            },
            product_details_basket: {
                outOfProduction: 'Снято с производства',
                isCustomOrder: 'Наличие уточняйте у менеджера',
                isAvailableDepot: 'В наличии на складе в Москве',
                isAvailableVendorDepot: 'В наличии на складе производителя в Москве',
                isStaticAvailableVendorDepot: 'Зафиксировать наличие у производителя',
                isShowedOnHomepage: 'Выводить на главную',
                warranty: 'Гарантия производителя',
            },
            product_details_quotation: {
                quotation: 'Нашли предложение дешевле?',
                message: orderWithQuotationRequest
            },
            product_details_delivery: {
                label: 'Доставка',
                more: 'Подробнее',
                delivery: {
                    foreignRegionWithDeliveryPeriod: '{{ title }}, {{ description }}',
                    foreignRegionWithoutDeliveryPeriod: '{{ title }}',
                    homeRegionIsAvailable: 'Курьер — {{ cost }} руб.',
                    homeRegionIsAvailableDepot: 'Курьер — {{ cost }} руб.',
                    homeRegionIsNotAvailable: 'Курьер — {{ cost }} руб.',
                    homeRegionPeriodIsAvailable: 'в течение 1-3 дней',
                    homeRegionPeriodIsAvailableDepot: 'в течение 2-4 дней'
                },
                pickup: {
                    homeRegionIsAvailable: 'Самовывоз — бесплатно',
                    homeRegionIsAvailableDepot: 'Самовывоз — бесплатно',
                    homeRegionIsNotAvailable: ' Самовывоз — бесплатно',
                    homeRegionPeriodIsAvailable: 'сегодня',
                    homeRegionPeriodIsAvailableDepot: 'через 2-4 дня'
                },
            },
            product_details_tab_params: {
                manufacturer: 'Производитель',
                viewMore: 'Развернуть характеристики',
                viewLess: 'Скрыть характеристики',
                params_annotation: 'Информация о технических характеристиках, комплекте поставки, стране изготовления и внешнем виде товара носит справочный характер и основывается на последних доступных сведениях от производителя',
                params_annotation_link: 'Нашли неточность в описании?'
            },
            product_details_tab_modifications: {
                no_results: 'Нет модификаций с выбранным набором параметров',
                empty: 'Не выбрано',
                yes: 'Да',
                no: 'Нет',
                sort_by: 'Сортировать по:',
                available: 'В наличии',
                more: 'Показать ещё {{count}}',
                isMore: 'Скрыть ещё {{count}}',
                sort: {
                    Popular: 'По популярности',
                    HasSale: 'По скидке',
                    PriceDesc: 'Сначала дорогие',
                    PriceAsc: 'Сначала дешевые',
                    DatePublished: 'По обновлению', 
                    Commented: 'По рейтингу',
                }
            },
            product_details_tab_modifications_entry: {
                isCurrent: 'Текущая модификация',
                isAvailable: 'В наличии'
            },
            product_details_tab_reviews: {
                title: 'Отзывы и вопросы',
                reviews: 'Отзывы',
                questions: 'Вопросы',
                addReview: 'Оставить отзыв',
                photos: {
                    more: '+{{count}} фото',
                    more_mobile: 'Смотреть все',
                    title: 'Фотографии покупателей',
                },
                sort: {
                    sort_by: 'Сортировать по:',
                    date: 'Дате',
                    rating: 'Оценке',
                    likes: 'Полезности',
                    with_photo: 'Есть фото'
                },
                sort_mobile: {
                    date: 'По дате',
                    rating: 'По рейтингу',
                    likes: 'По полезности',
                    with_photo: 'Есть фото',
                    rating_2: 'Рейтинг',
                },
                sort_mobile_menu: {
                    date_asc: 'По дате min',
                    date_desc: 'По дате max',
                    likes_asc: 'По полезности min',
                    likes_desc: 'По полезности max',
                    rating_asc: 'По рейтингу min',
                    rating_desc: 'По рейтингу max'
                },
                noReviews: 'У товара еще нет отзывов — ваш может стать первым. Поделитесь своим мнением.',
                mark_1: '1 звезда',
                mark_2: '2 звезды',
                mark_3: '3 звезды',
                mark_4: '4 звезды',
                mark_5: '5 звезд',
                reviews_: 'Нет отзывов',
                reviews_0: '{{ count }} отзывов',
                reviews_1: '{{ count }} отзыв',
                reviews_2: '{{ count }} отзыва',
                based_on: 'На основе {{ count }} отзывов'
            },
            product_details_tab_questions: {
                addQuestion: 'Оставить вопрос',
                noQuestions: 'Вопросов пока нет ...',
                sort: {
                    sort_by: 'Сортировать по:',
                    date: 'Дате',
                    likes: 'Полезности',
                },
                sort_mobile: {
                    date: 'По дате',
                    likes: 'По полезности',
                },
                sort_mobile_menu: {
                    date_asc: 'По дате min',
                    date_desc: 'По дате max',
                    likes_asc: 'По полезности min',
                    likes_desc: 'По полезности max'
                },
            },
            product_details_tab_consumables: {
                loadMore: 'Показать еще',
            },
            product_details_tab_accessories: {
                loadMore: 'Показать еще',
            },
            product_details_same: {
                title: 'Похожие товары',
                loadMore: 'Показать еще',
            },
            product_details_recommended: {
                loadMore: 'Показать еще',
            },
            product_details_tab_description: {
                share: 'Поделиться:',
            },
            product_details_review_form: {
                title: 'Написать отзыв',
                loginMessage: 'Добавьте отзыв, заполнив личнные данные, или сначала войдите на сайт, тогда информация будет автоматически взята из профиля',
                loginButton: 'Войти',
                rating: 'Ваша оценка',
                photos: 'Ваши фотографии товара',
                photosTitle: 'Перетащите файл или',
                photosTitle2: 'выберите на устройстве',
                photosHint: 'до 10 изображений в формате PNG, JPG',
                submit: 'Оставить отзыв',
                failed: 'Не удалось отправить отзыв',
                tooManyFiles: 'Вы можете загрузить не более 10 файлов',
                failedToUpload: 'Не удалось загрузить файлы',
                annotation: 'Нажимая кнопку «Оставить отзыв», я подтверждаю согласие',
                annotation_link: 'обработку персональных данных',
                close: 'Отмена',
                fields: {
                    author: 'Ваше имя *',
                    email: 'Email',
                    anonymous: 'Оставить отзыв анонимно',
                    positive: 'Плюсы',
                    negative: 'Минусы',
                    contents: 'Отзыв *',
                },
                placeholders: {
                    author: 'Введите имя',
                    email: 'Введите email',
                    positive: 'Опишите преимущества',
                    negative: 'Опишите недостатки',
                    contents: 'Напишите общие впечатления, срок использования, критерии при выборе товара',
                },
            },
            product_details_question_form: {
                title: 'Написать вопрос',
                loginMessage: 'Добавьте вопрос, заполнив личнные данные, или сначала войдите на сайт, тогда информация будет автоматически взята из профиля',
                loginButton: 'Войти',
                rating: 'Ваша оценка',
                submit: 'Оставить вопрос',
                failed: 'Не удалось отправить вопрос',
                close: 'Отмена',
                annotation: 'Нажимая кнопку «Оставить вопрос», я подтверждаю согласие',
                annotation_link: 'обработку персональных данных',
                fields: {
                    author: 'Ваше имя *',
                    email: 'Email',
                    anonymous: 'Оставить вопрос анонимно',
                    contents: 'Вопрос *',
                },
                placeholders: {
                    author: 'Введите имя',
                    email: 'Введите email',
                    contents: 'Напишите вопрос',
                },
            },
        },
        modals: {
            product_details_modal_documentation: {
                title: 'Документация',
            },
            product_details_modal_share: {
                copy: 'Скопировать ссылку',
                whatsapp: 'WhatsApp',
                vk: 'Вконтакте',
                facebook: 'Facebook',
                twitter: 'Twitter',
            },
        },
    },
};
