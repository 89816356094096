
<div class="c">
  <div class="c-search-input" #searchInputContainer>
    <app-shared-search #search (nextSearchResults)="onSearchResults($event)" (nextQuery)="onSearchQuery($event)"></app-shared-search>
  </div>
  @if (hasSearchResults) {
    <div class="c-search-results">
      <app-shared-search-popup [fitToContainer]="searchInputContainer" [queryString]="state().lastSearchQuery" [response]="state().lastSearchResults"></app-shared-search-popup>
    </div>
  }
</div>
