/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Input, Output } from '@angular/core';
import { CommonContentWebBundleShared, ParamsReferenceDto, withTrailingSlash } from '@interid/interid-site-shared';

@Component({
    selector: 'app-product-param-reference',
    templateUrl: './product-param-reference.component.html',
    styleUrls: ['./product-param-reference.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductParamReferenceComponent {
    public paramReference = input<ParamsReferenceDto>();
    public contentBundle = input<CommonContentWebBundleShared.Bundle>();

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output('close') closeEvent: EventEmitter<void> = new EventEmitter<void>();

    public routerLink = computed<string[]>(() => {
        return [withTrailingSlash(this.paramReference().webUrl)];
    });

    t(input: string): string {
        return `catalog.components.catalog_param_reference.${input}`;
    }

    close(): void {
        this.closeEvent.emit();
    }
}
