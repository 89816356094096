import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountShared } from './models/shared/account.shared';
import { AccountAccessShared } from './models/shared/account-access.shared';

export class CommonResponseDto {
  @ApiModelProperty({
    title: 'Indicates that request was success',
  })
  success: boolean;

  @ApiModelProperty({
    title: 'HTTP Status code',
    nullable: true,
  })
  statusCode?: number;

  @ApiModelProperty({
    title: 'API Error Code',
  })
  code?: string;

  @ApiModelProperty({
    title: 'Error message',
  })
  message?: string;
}

export class InteridApiErrorDto {
  @ApiModelProperty()
  success: false;

  @ApiModelProperty()
  statusCode: number;

  @ApiModelProperty()
  code: string;

  @ApiModelProperty()
  message: string;
}

export class JwtPayload {
  @ApiModelProperty()
  accountId: number;

  @ApiModelProperty()
  accountFullName: string;

  @ApiModelProperty()
  accountEmail: string;

  @ApiModelProperty({
    type: 'string',
    enum: Object.values(AccountShared.AccountRole),
  })
  accountRole: AccountShared.AccountRole;

  @ApiModelProperty({
    type: 'string',
    isArray: true,
    enum: Object.values(AccountAccessShared.AccessNode),
  })
  accessNodes: Array<AccountAccessShared.AccessNode>;

  @ApiModelProperty()
  accountCityId?: number;

  @ApiModelProperty()
  iat?: number;
}

export interface CommonReplaceUrlExports {
  url: string;
  replaceUrl: string;
}
