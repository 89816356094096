import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { VacanciesRequestSendRequest, VacanciesRequestWebEndpoint, VacanciesRequestWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebVacanciesRequestDataAccess implements VacanciesRequestWebEndpoint {
    constructor(private readonly http: InteridDataAccessService) {}

    send(file: File, request: VacanciesRequestSendRequest): Observable<void> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('phone', request.phone);
        formData.append('name', request.name);
        formData.append('email', request.email);
        formData.append('id', request.id.toString());

        const headers = new Headers();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        return this.http.post(VacanciesRequestWebEndpointPaths.send, formData, { headers: headers });
    }
}
