import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ApiErrorHandlerService, AppErrorHandlerService, JwtService, MessagesService } from '@interid/interid-site-web/core';
import { InteridWebAccountDataAccess } from '@interid/interid-site-data-access/web';
import { ConfirmEmailResponse } from '@interid/interid-site-shared';

@Injectable({
    providedIn: 'root',
})
export class AuthConfirmEmailResolver  {
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly router: Router,
        private readonly appErrorHandler: AppErrorHandlerService,
        private readonly endpoint: InteridWebAccountDataAccess,
        private readonly messages: MessagesService,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly jwtService: JwtService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ConfirmEmailResponse | void> | void {
        if (isPlatformBrowser(this.platformId)) {
            if (route.queryParams['confirmEmail'] === '1' && route.queryParams['token']) {
                return this.endpoint.confirmEmail({
                    token: route.queryParams['token'],
                }).pipe(
                    tap(() => {
                        this.messages.success({
                            message: 'auth.shared.resolvers.auth_confirm_email_resolver.success',
                            translate: true,
                        });
                    }),
                    tap((response) => {
                        this.jwtService.setJwt(
                            response.jwt,
                            response.jwtPayload,
                        );
                    }),
                    catchError((err) => {
                        this.errorHandler.handle(err);

                        this.router.navigate(['/']);

                        return of(undefined);
                    }),
                    tap(() => {
                        this.router.navigate(['/']);
                    }),
                    this.appErrorHandler.pipe(),
                );
            }
        }
    }
}
