import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { DictionaryWebEndpoint, DictionaryWebEndpointPaths, DictionaryWebRequest, DictionaryWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebDictionaryDataAccess implements DictionaryWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    dictionary(request: DictionaryWebRequest): Observable<DictionaryWebResponse> {
        return this.http.post(DictionaryWebEndpointPaths.dictionary, request);
    }
}
