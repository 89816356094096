import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DictionaryDto } from '../models/entities/dictionary.dto';
import { ParamsDto, ParamsListDto } from '../models/entities/params.dto';
import { DictionaryValueDto } from '../models/entities/dictionary-value.dto';
import { ParamsShared } from '../models/shared/params.shared';
import { CrudSetSortOrderRequestDto } from '../core/crud';

export enum ParamsEndpointPaths {
    createParams = '/api/v1/params/create',
    editParams = '/api/v1/params/edit',
    deleteParams = '/api/v1/params/delete',
    listParams = '/api/v1/params/list',
    listAllParams = '/api/v1/params/listAllParams',
    getParams = '/api/v1/params/get',
    setSortOrder = '/api/v1/params/setSortOrder',
    getParamsMetadata = '/api/v1/params/getParamsMetadata',
    getParamsMetadataByIds = '/api/v1/params/getParamsMetadataByIds',
    search = '/api/v1/params/search',
}

export interface ParamsEndpoint {
    createParams(request: CreateParamsRequest): Promise<ParamsDto> | Observable<ParamsDto>;
    editParams(request: EditParamsRequest): Promise<ParamsDto> | Observable<ParamsDto>;
    deleteParams(request: DeleteParamsRequest): Promise<void> | Observable<void>;
    listParams(): Promise<ListParamsResponse> | Observable<ListParamsResponse>;
    listAllParams(): Promise<ParamsListAllResponse> | Observable<ParamsListAllResponse>;
    getParams(request: GetParamsRequest): Promise<ParamsDto> | Observable<ParamsDto>;
    setSortOrder(request: SetSortOrderParamsRequest): Promise<void> | Observable<void>;
    getParamsMetadata(request: GetParamsMetadataRequest): Promise<GetParamsMetadataResponse> | Observable<GetParamsMetadataResponse>;
    getParamsMetadataByIds(request: GetParamsMetadataByIdsRequest): Promise<GetParamsMetadataByIdsResponse> | Observable<GetParamsMetadataByIdsResponse>;
    search(request: ParamsSearchRequest): Promise<ParamsSearchResponse> | Observable<ParamsSearchResponse>;
}

export class CreateParamsRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsInt()
    @IsPositive()
    @IsOptional()
    sortOrder?: number | undefined;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productCategoryIds: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    description?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsOptional()
    paramsCategoryId?: number;

    @ApiModelProperty()
    @IsEnum(ParamsShared.ParamsType)
    @IsNotEmpty()
    type: ParamsShared.ParamsType;

    @ApiModelProperty()
    @IsEnum(ParamsShared.DescriptionAnnotationForProductType)
    @IsOptional()
    descriptionAnnotationForProductType: ParamsShared.DescriptionAnnotationForProductType = ParamsShared.DescriptionAnnotationForProductType.None;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInProductDetails: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInProductCard: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInFilters: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInSearchFilters: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    notDisplayInCharacteristics: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    similarProduct: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInModifications: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayDictionaryIcon: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isExportedToMarketplaceParams: boolean;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    dictId?: number | undefined;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    dictIds?: Array<number>;
}

export class EditParamsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    sortOrder?: number | undefined;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productCategoryIds: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    description?: string;

    @IsNotEmpty()
    @ApiModelProperty()
    @IsBoolean()
    displayInProductDetails: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInSearchFilters: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    notDisplayInCharacteristics: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    similarProduct: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInProductCard: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInFilters: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayInModifications: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    displayDictionaryIcon: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isExportedToMarketplaceParams: boolean;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    dictId?: number | undefined;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    dictIds?: Array<number>;

    @ApiModelProperty()
    @IsEnum(ParamsShared.ParamsType)
    @IsOptional()
    type?: ParamsShared.ParamsType;

    @ApiModelProperty()
    @IsEnum(ParamsShared.DescriptionAnnotationForProductType)
    @IsOptional()
    descriptionAnnotationForProductType?: ParamsShared.DescriptionAnnotationForProductType;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    paramsCategoryId?: number | undefined;
}

export class DeleteParamsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type ListParamsResponse = Array<ParamsDto>;

export class GetParamsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderParamsRequest extends CrudSetSortOrderRequestDto {}

export class GetParamsMetadataRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productCategoryId?: number | undefined;
}

export class GetParamsMetadataResponse {
    @ApiModelProperty({
        type: ParamsDto,
        isArray: true,
    })
    params: Array<ParamsDto>;

    @ApiModelProperty({
        type: DictionaryDto,
        isArray: true,
    })
    dictionaries: Array<DictionaryDto>;

    @ApiModelProperty({
        type: DictionaryValueDto,
        isArray: true,
    })
    dictionariesValues: Array<DictionaryValueDto>;
}

export class GetParamsMetadataByIdsRequest {
    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsNotEmpty()
    paramIds: Array<number>;
}

export class GetParamsMetadataByIdsResponse {
    @ApiModelProperty({
        type: ParamsDto,
        isArray: true,
    })
    params: Array<ParamsDto>;

    @ApiModelProperty({
        type: DictionaryDto,
        isArray: true,
    })
    dictionaries: Array<DictionaryDto>;

    @ApiModelProperty({
        type: DictionaryValueDto,
        isArray: true,
    })
    dictionariesValues: Array<DictionaryValueDto>;
}

export class ParamsSearchFilterRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    ids?: Array<number>;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isSharedParams?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsNotEmpty()
    productCategoryIds?: Array<number>;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsNotEmpty()
    nestedProductCategoryIds?: Array<number>;
}

export class ParamsViewFilterRequest {
    @ApiModelProperty()
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;
}

export class ParamsSearchRequest {
    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    filter?: ParamsSearchFilterRequest;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    view: ParamsViewFilterRequest;
}

export class ParamsSearchResponse {
    @ApiModelProperty({
        type: ParamsDto,
        isArray: true,
    })
    params: Array<ParamsDto>;
}

export type ParamsListAllResponse = Array<ParamsListDto>;
