import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { PhoneCallRequestSendRequest, PhoneCallRequestSendResponse, PhoneCallRequestWebEndpoint, PhoneCallRequestWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebPhoneCallRequestDataAccess implements PhoneCallRequestWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    send(request: PhoneCallRequestSendRequest, recaptchaV3Token: string): Observable<PhoneCallRequestSendResponse> {
        return this.http.post(PhoneCallRequestWebEndpointPaths.send, request, {
            headers: {
                'recaptcha': recaptchaV3Token,
            },
        });
    }
}
