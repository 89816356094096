import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryPeriodShared } from '../shared/delivery-period.shared';

export class DeliveryPeriodDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  isEnabled: boolean;

  @ApiModelProperty()
  deliveryCostId: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  days: DeliveryPeriodShared.Days;

  @ApiModelProperty()
  orderBefore: DeliveryPeriodShared.OrderBefore;
}
