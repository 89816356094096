import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, Min, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductAnnotationDescriptionParameter, ProductCategoryDto, ProductCategoryListDto, ProductCategorySaleDto, ProductCategoryTemplate } from '../models/entities/product-category.dto';
import { ContentDTO } from '../models/shared/common-content.shared';
import { PageMetadataSEO } from '../models/shared/common.shared';
import { CrudSetSortOrderRequestDto } from '../core/crud';

export enum ProductCategoryEndpointPaths {
    createProductCategory = '/api/v1/product-categories/create',
    editProductCategory = '/api/v1/product-categories/edit',
    deleteProductCategory = '/api/v1/product-categories/delete',
    listProductCategories = '/api/v1/product-categories/list',
    listAllProductCategories = '/api/v1/product-categories/listAll',
    setSortOrder = '/api/v1/product-categories/setSortOrder',
}

export interface ProductCategoryEndpoint {
    createProductCategory(request: CreateProductCategoryRequest): Promise<ProductCategoryDto> | Observable<ProductCategoryDto>;
    editProductCategory(request: EditProductCategoryRequest): Promise<ProductCategoryDto> | Observable<ProductCategoryDto>;
    deleteProductCategory(request: DeleteProductCategoryRequest): Promise<void> | Observable<void>;
    listProductCategories(): Promise<ListProductCategoriesResponse> | Observable<ListProductCategoriesResponse>;
    listAllProductCategories(): Promise<ListAllProductCategoriesResponse> | Observable<ListAllProductCategoriesResponse>;
    setSortOrder(request: ProductCategorySetSortOrderRequest): Promise<void> | Observable<void>;
}

export class ProductCategoryEntityBody {
    @ApiModelProperty({
        required: false,
    })
    @Min(0)
    @IsInt()
    @IsOptional()
    sortOrder?: number | undefined;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    parent?: number | undefined;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    menuTitle?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    alt: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    slug: string;

    @ApiModelProperty({
        type: () => ContentDTO,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    description: ContentDTO;

    @ApiModelProperty({
        type: () => ContentDTO,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    descriptionFooter: ContentDTO;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    usefulProductCategoryIds: Array<number>;

    @ApiModelProperty({
        required: false
    })
    @IsArray()
    @IsObject({ each: true })
    productAnnotationDescriptionParameters: Array<ProductAnnotationDescriptionParameter>;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    imageAttachmentId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    iconAttachmentId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    menuIconAttachmentId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    iconMobileAttachmentId?: number;

    @ApiModelProperty({
        type: () => PageMetadataSEO,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    seo: PageMetadataSEO;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    supplierPPId?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    productAnnotationDescriptionDivider?: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isExportedToYandexMarket: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    makeDescriptionAnnotationForProduct: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isDisplayInCatalogMenu: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isExportedToGoogleMarket: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    hasSale: boolean;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    sale?: ProductCategorySaleDto;

    @ApiModelProperty({
        required: false,
    }) 
    @IsInt()
    @IsOptional()
    template?: ProductCategoryTemplate;
}

export class CreateProductCategoryRequest extends ProductCategoryEntityBody {
}

export class EditProductCategoryRequest extends ProductCategoryEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteProductCategoryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type ListProductCategoriesResponse = Array<ProductCategoryDto>;
export type ListAllProductCategoriesResponse = Array<ProductCategoryListDto>;

export class ProductCategorySetSortOrderRequest extends CrudSetSortOrderRequestDto {}
