export namespace PhoneCallRequestShared {
  export enum Status {
    Unprocessed = 'Unprocessed',
    InProgress = 'InProgress',
    Processed = 'Processed',
    Unavailable = 'Unavailable',
    Outdated = 'Outdated',
  }

  export class StatusHistoryEntry {
    updatedBy: string;
    updatedAt: string;
    fromStatus: Status;
    toStatus: Status;
    comment?: string | undefined;
  }

  export const allStatuses: Array<Status> = [
    Status.Unprocessed,
    Status.InProgress,
    Status.Processed,
    Status.Unavailable,
    Status.Outdated,
  ];
}
