import { Observable } from 'rxjs';
import { IsEmail, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Length, MaxLength, MinLength } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AppShared } from '../models/shared/app.shared';
import { AccountSettingsShared, AccountShared } from '../models/shared/account.shared';
import { AccountDto } from '../models/entities/account.dto';
import { AuthResponse } from './auth.endpoint';

export enum AccountEndpointPaths {
    currentAccount = '/api/v1/account/currentAccount',
    registerAccount = '/api/v1/account/registerAccount',
    confirmEmail = '/api/v1/account/confirmEmail',
    sendConfirmEmailLink = '/api/v1/account/sendConfirmEmailLink',
    changePassword = '/api/v1/account/changePassword',
    sendResetPasswordLink = '/api/v1/account/sendResetPasswordLink',
    resetPassword = '/api/v1/account/resetPassword',
    updateProfile = '/api/v1/account/updateProfile',
    setCity = '/api/v1/account/setCity',
    updateAccountSettings = '/api/v1/account/updateAccountSettings',
    setIsCookiePolicyAccepted = '/api/v1/account/setIsCookiePolicyAccepted',
}

// TODO: Fix endpoints
export interface AccountEndpoint {
    currentAccount(): Promise<CurrentAccountResponse> | Observable<CurrentAccountResponse>;
    registerAccount(request: RegisterAccountRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    confirmEmail(request: ConfirmEmailRequest): Promise<ConfirmEmailResponse> | Observable<ConfirmEmailResponse>;
    sendConfirmEmailLink(request: SendConfirmEmailLinkRequest): Promise<void> | Observable<void>;
    changePassword(request: ChangePasswordRequest): Promise<void> | Observable<void>;
    sendResetPasswordLink(request: SendResetPasswordLinkRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    resetPassword(request: ResetPasswordRequest): Promise<ResetPasswordResponse> | Observable<ResetPasswordResponse>;
    updateProfile(request: UpdateProfileRequest): Promise<UpdateProfileResponse> | Observable<UpdateProfileResponse>;
    setCity(request: SetCityAccountRequest): Promise<void> | Observable<void>;
    updateSettings(request: SetAccountSettingsRequest): Promise<void> | Observable<void>;
    setIsCookiePolicyAccepted(): Promise<void> | Observable<void>;
}

export type CurrentAccountResponse = AccountDto;

export class RegisterAccountRequest {
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    email: string;

    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    fullName: string;

    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    password: string;
}

export class ConfirmEmailRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    token: string;
}

export type ConfirmEmailResponse = AuthResponse;

export class SendConfirmEmailLinkRequest {
    @IsEmail()
    @IsNotEmpty()
    @ApiModelProperty()
    email: string;
}

export class ChangePasswordRequest {
    @MinLength(AccountShared.V_PASSWORD_MIN)
    @MaxLength(AccountShared.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    oldPassword: string;

    @MinLength(AccountShared.V_PASSWORD_MIN)
    @MaxLength(AccountShared.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    newPassword: string;
}

export class SendResetPasswordLinkRequest {
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    email: string;

    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    app: AppShared.App;
}

export class ResetPasswordRequest {
    @Length(AccountShared.TOKEN_LENGTH)
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    token: string;

    @MinLength(AccountShared.V_PASSWORD_MIN)
    @MaxLength(AccountShared.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    newPassword: string;
}

export type ResetPasswordResponse = AuthResponse;

export class UpdateProfileRequest {
    @IsInt()
    @IsPositive()
    @IsOptional()
    @ApiModelProperty()
    accountId?: number;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    fullName?: string;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    phone?: string;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    deliveryAddress?: string;
}

export type UpdateProfileResponse = AccountDto;

export class SetCityAccountRequest {
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    @ApiModelProperty()
    cityId: number;
}


export type SetAccountSettingsRequest = Partial<AccountSettingsShared.AccountSettingsDto>;
