import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class ProductPriceLogDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  reason: string;

  @ApiModelProperty()
  productId: number;

  @ApiModelProperty()
  productTitle: string;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  createdBy: string;

  @ApiModelProperty()
  currentPrice: string;

  @ApiModelProperty()
  oldAmount: string;

  @ApiModelProperty()
  newAmount: string;
}
