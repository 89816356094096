import { Observable } from 'rxjs';
import { IsArray, IsEnum, IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountAccessTemplateDto } from '../models/entities/account-access-template.dto';
import { AccountAccessShared } from '../models/shared/account-access.shared';

export enum AccountAccessTemplateEndpointPaths {
    createAccountAccessTemplate = '/api/v1/admin/account-access-template/create',
    editAccountAccessTemplate = '/api/v1/admin/account-access-template/edit',
    deleteAccountAccessTemplate = '/api/v1/admin/account-access-template/delete',
    listAccountAccessTemplate = '/api/v1/admin/account-access-template/list',
}

export interface AccountAccessTemplateEndpoint {
    create(request: CreateAccountAccessTemplateRequest): Promise<AccountAccessTemplateDto> | Observable<AccountAccessTemplateDto>;
    edit(request: EditAccountAccessTemplateRequest): Promise<AccountAccessTemplateDto> | Observable<AccountAccessTemplateDto>;
    delete(request: DeleteAccountAccessTemplateRequest): Promise<void> | Observable<void>;
    list(): Promise<ListAccountAccessTemplateResponse> | Observable<ListAccountAccessTemplateResponse>;
}

export class AccountAccessTemplateBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountAccessShared.AccessNode),
    })
    @IsEnum(AccountAccessShared.AccessNode, { each: true })
    @IsArray()
    @IsNotEmpty()
    accessNodes: Array<AccountAccessShared.AccessNode>;
}

export class CreateAccountAccessTemplateRequest extends AccountAccessTemplateBody {
}

export class EditAccountAccessTemplateRequest extends AccountAccessTemplateBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteAccountAccessTemplateRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type ListAccountAccessTemplateResponse = Array<AccountAccessTemplateDto>;
