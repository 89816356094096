import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsObject, IsPositive } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BonusShared } from '../models/shared/bonus.shared';
import { BonusDto } from '../models/entities/bonus.dto';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';

export enum BonusEndpointPaths {
    push = '/api/v1/bonus/push',
    list = '/api/v1/bonus/list',
    delete = '/api/v1/bonus/delete',
}

export interface BonusEndpoint {
    push(request: BonusPushRequest): Promise<BonusPushResponse> | Observable<BonusPushResponse>;
    list(request: BonusListRequest): Promise<BonusListResponse> | Observable<BonusListResponse>;
    delete(request: BonusDeleteRequest): Promise<void> | Observable<void>;
}

export class BonusPushRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty({
        type: BonusShared.BonusSourceRequest,
    })
    @IsObject()
    @IsNotEmpty()
    source: BonusShared.BonusSource;
}

export class BonusPushResponse {
    @ApiModelProperty()
    bonus: BonusDto;
}

export class BonusListRequest extends CrudListRequestDto<BonusDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;
}

export class BonusListResponse extends CrudListResponseDto<BonusDto> {
    @ApiModelProperty({
        type: BonusDto,
        isArray: true,
    })
    items: Array<BonusDto>;

    @ApiModelProperty({
        description: 'Total sum of bonuses',
    })
    sum: number;
}

export class BonusDeleteRequest {
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
