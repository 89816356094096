export const interidSiteWebCoreAppI18n = {
    app: {
        components: {
            app: {
                app_offline: 'Приложение не может по каким-то причинам подключиться к API',
            },
            app_header_desktop_city: {
                question: 'Ваш город',
                confirm: 'Да',
                selectCity: 'Указать другой город',
            },
            app_header_desktop: {
                main_menu: {
                    solutions: 'Услуги и решения',
                    delivery: 'Доставка и оплата',
                    articles: 'Блог',
                    about: 'О компании',
                    contacts: 'Контакты',
                    vacancies: 'Вакансии',
                    callrequest: 'Запрос звонка',
                },
                menu_1: {
                    header: 'Покупателям',
                    delivery: 'Доставка',
                    paymentMethods: 'Способы оплаты',
                    returningGoods: 'Возврат товаров',
                    howMakeOrder: 'Как сделать заказ',
                    privacy: 'Политика конфиденциальности',
                },
                menu_2: {
                    header: 'Компания',
                    solutions: 'Услуги и решения',
                    vacancies: 'Вакансии',
                    blog: 'Блог',
                    about: 'О нас',
                    contacts: 'Контакты',
                },
                signIn: 'Войти',
                profile: 'Профиль',
                emailForQuestions: 'По всем вопросам',
                orderCall: 'Заказать звонок',
                catalog: 'Каталог',
                sales: 'Акции',
                compares: 'Сравнение',
                favorites: 'Избранное',
                basket: 'Корзина',
                basketIsEmpty: 'Пустая',
                tagline: 'Оборудование для автоматической идентификации и сбора данных',
            },
            app_footer_desktop: {
                privacy: 'Политика конфиденциальности',
                tagline: 'Оборудование для автоматической идентификации и сбора данных',
                menu_1: {
                    header: 'Покупателям',
                    delivery: 'Доставка',
                    paymentMethods: 'Способы оплаты',
                    returningGoods: 'Возврат товаров',
                    howMakeOrder: 'Как сделать заказ',
                    privacy: 'Политика конфиденциальности',
                },
                menu_2: {
                    header: 'Компания',
                    solutions: 'Услуги и решения',
                    vacancies: 'Вакансии',
                    blog: 'Блог',
                    about: 'О нас',
                    contacts: 'Контакты',
                },
                categories: {
                    header: 'Популярные категории',
                },
                favorites: {
                    header: 'Полезные ссылки',
                },
                contacts: {
                    header: 'Контакты',
                    multichannel: 'Многоканальный',
                    mobile: 'Для тех, кому удобно на мобильный',
                    email: 'Для заказов по электронной почте',
                    store: 'Склад • Пункты выдачи',
                },
            },
            app_header_desktop_phones: {
                mobilePhone: 'Мобильный телефон',
                multichannelPhone: 'Мультиканальный телефон',
                phoneForOrders: 'Телефон для заказов',
            },
            app_footer_mobile: {
                privacy: 'Политика конфиденциальности',
                menu: {
                    header: 'Компания',
                    solutions: 'Услуги и решения',
                    delivery: 'Доставка и оплата',
                    articles: 'Обзоры',
                    about: 'О компании',
                    contacts: 'Контакты',
                },
                categories: {
                    header: 'Популярные категории',
                },
                favorites: {
                    header: 'Полезные ссылки',
                },
                contacts: {
                    header: 'Контакты',
                    multichannel: 'Многоканальный',
                    mobile: 'Для тех, кому удобно на мобильный',
                    email: 'Для заказов по электронной почте',
                    store: 'Склад • Пункты выдачи',
                },
            },
            app_floatbar_mobile: {
                home: 'Главная',
                catalog: 'Каталог',
                favorites: 'Избранное',
                compares: 'Сравнение',
                cart: 'Корзина',
                menu: 'Меню',
                profile: 'Профиль'
            },
            app_header_mobile: {
                catalog: 'Каталог',
                sales: 'Акции',
            },
            app_header_mobile_main: {
                signIn: 'Войти',
                favorites: 'Избранное',
                compares: 'Сравнение',
                main_menu: {
                    solutions: 'Услуги и решения',
                    delivery: 'Доставка и оплата',
                    articles: 'Блог',
                    about: 'О компании',
                    contacts: 'Контакты',
                    vacancies: 'Вакансии'
                },
            },
            app_header_mobile_catalog: {},
            app_header_mobile_search: {},
            app_not_found: {
                title: '404: Страница не найдена',
                message_1: 'Но если Вы считаете, что эта страница обязана быть тут - ',
                message_2: 'или вернитесь',
                write_us: 'напишите нам',
                go_home: 'на главную',
            },
        },
    },
};
