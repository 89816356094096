import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { ManufacturerDto } from '../models/entities/manufacturer.dto';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { CreateProductReviewRequest } from '../endpoints/product-review.endpoint';
import { ProductESDto } from '../models/entities/product.dto';
import { CommonContentWebBundleShared } from '../models/shared/common-content.shared';
import { ProductReviewDto } from '../models/entities/product-review.dto';
import { ProductSearchRequest, ProductSearchResponse } from '../endpoints/product.endpoint';
import { ProductReviewLikeDto } from '../models/entities/product-review-like.dto';
import { ProductQuestionDto } from '../models/entities/product-question.dto';
import { CreateProductQuestionRequest } from '../endpoints/product-question.endpoint';
import { ProductQuestionLikeDto } from '../models/entities/product-question-like.dto';
import { ProductCategorySeoLinkDto } from '../models/entities/product-category-seo-link.dto';
import { ParamsReferenceDto } from '../models/entities/params-reference.dto';
import { ParamsCategoryListDto } from '../models/entities/params-category.dto';
import { DeliveryDto } from '../models/entities/delivery.dto';

export enum ProductWebEndpointPaths {
    get = '/api/v1/web/product-details/get',
    getProduct = '/api/v1/web/product-details/get-product',
    hit = '/api/v1/web/product-details/hit',
    review = '/api/v1/web/product-details/review',
    question = '/api/v1/web/product-details/question',
    search = '/api/v1/web/product/search',
}

export interface ProductWebEndpoint {
    get(request: ProductGetWebRequest, req?: any): Promise<ProductGetResponse> | Observable<ProductGetResponse>;
    getProduct(request: ProductGetWebRequest, req?: any): Promise<ProductGetProductResponse> | Observable<ProductGetProductResponse>;
    hit(request: ProductHitWebRequest): Promise<void> | Observable<void>;
    review(request: CreateProductReviewRequest, req?: any): Promise<ProductReviewDto> | Observable<ProductReviewDto>;
    question(request: CreateProductQuestionRequest, req?: any): Promise<ProductQuestionDto> | Observable<ProductQuestionDto>;
    search(request: ProductSearchRequest): Promise<ProductSearchResponse> | Observable<ProductSearchResponse>;
}

export class ProductGetWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uri: string;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    preview?: string;
}

export class ProductGetProductResponse {
    @ApiModelProperty({
        type: () => ProductESDto,
    })
    product: ProductESDto;

    @ApiModelProperty({
        type: () => ProductReviewDto,
        isArray: true,
    })
    productReviews: Array<ProductReviewDto>;
}

export class ProductGetResponse {
    @ApiModelProperty({
        type: () => ProductESDto,
    })
    product: ProductESDto;

    @ApiModelProperty({
        type: ParamsCategoryListDto,
        isArray: true,
    })
    paramsCategories: Array<ParamsCategoryListDto>;

    @ApiModelProperty({
        type: () => DeliveryDto,
        isArray: true,
    })
    delivery: Array<DeliveryDto>;

    @ApiModelProperty({
        type: ParamsReferenceDto,
        isArray: true,
    })
    paramsReferences: Array<ParamsReferenceDto>;

    @ApiModelProperty({
        type: () => ProductReviewDto,
        isArray: true,
    })
    productReviews: Array<ProductReviewDto>;

    @ApiModelProperty({
        type: () => ProductReviewLikeDto,
        isArray: true,
    })
    productReviewsLikes: Array<ProductReviewLikeDto>;

    @ApiModelProperty({
        type: () => ProductQuestionDto,
        isArray: true,
    })
    productQuestions: Array<ProductQuestionDto>;

    @ApiModelProperty({
        type: () => ProductQuestionLikeDto,
        isArray: true,
    })
    productQuestionsLikes: Array<ProductQuestionLikeDto>;

    @ApiModelProperty({
        type: () => ManufacturerDto,
    })
    manufacturer: ManufacturerDto;

    @ApiModelProperty({
        type: () => ProductReviewDto,
        isArray: true,
    })
    countProductsForSameManufacturers: number;

    @ApiModelProperty({
        type: () => CommonContentWebBundleShared.Bundle,
    })
    contentBundle: CommonContentWebBundleShared.Bundle;

    @ApiModelProperty({
        type: () => ProductESDto,
        isArray: true,
    })
    modifications: Array<ProductESDto>;

    @ApiModelProperty({
        type: () => ProductCategoryListDto,
        isArray: true,
    })
    usefulProductCategories: Array<ProductCategoryListDto>;

    @ApiModelProperty({
        type: () => ProductCategorySeoLinkDto,
        isArray: true,
    })
    productCategorySeoLinks: Array<ProductCategorySeoLinkDto>

    @ApiModelProperty({
        description: 'Default delivery cost',
    })
    deliveryDefaultCost: string;
}

export class ProductHitWebRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

