import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';
import { ArticleReviewShared } from '../shared/article-review.shared';

export class ArticleReviewDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  articleId: number;

  @ApiModelProperty()
  articleCategoryId: number;

  @ApiModelProperty()
  articleCategoryUri: string;

  @ApiModelProperty()
  articleUri: string;

  @ApiModelProperty()
  articleTitle: string;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  processedAt?: string;

  @ApiModelProperty()
  responsedAt?: string;

  @ApiModelProperty()
  status: ArticleReviewShared.ReviewStatus;

  @ApiModelProperty()
  rating: number;

  @ApiModelProperty()
  author: string;

  @ApiModelProperty()
  email: string;

  @ApiModelProperty()
  contents: string;

  @ApiModelProperty()
  contentsAttachments: Array<AttachmentDto>;

  @ApiModelProperty()
  response: string;

  @ApiModelProperty()
  responseAttachments: Array<AttachmentDto>;
}
