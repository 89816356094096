import { ChangeDetectionStrategy, Component, ElementRef, inject, Inject, input, Input, OnDestroy, PLATFORM_ID, signal, viewChild, ViewChild } from '@angular/core';
import { interval, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

const UPDATE_INTERVAL_MS = 500;

const MOBILE_WIDTH = 320;
const MOBILE_HEIGHT = 240;

@Component({
    selector: 'app-shared-youtube-player-flex',
    templateUrl: './youtube-player-flex.component.html',
    styleUrls: ['./youtube-player-flex.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YoutubePlayerFlexComponent implements OnDestroy {
    private platformId = inject(PLATFORM_ID);

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public videoId = input<string>();
    public autoplay = input(false);

    public viewPlayerContainer = viewChild<ElementRef<HTMLDivElement>>('viewPlayerContainer');

    public isBrowser = signal<boolean>(isPlatformBrowser(this.platformId));

    get hasViewPlayerContainer(): boolean {
        return isPlatformBrowser(this.platformId)
            && !! this.videoId()
            && this.viewPlayerContainer()
            && this.viewPlayerContainer().nativeElement
            && !! this.viewPlayerContainer().nativeElement.getBoundingClientRect;
    }

    get isAvailable$(): Observable<boolean> {
        return interval(UPDATE_INTERVAL_MS).pipe(
            startWith(0),
            map(() => {
                if (this.hasViewPlayerContainer) {
                    return this.viewPlayerContainer().nativeElement.getBoundingClientRect().width > 0
                        && this.viewPlayerContainer().nativeElement.getBoundingClientRect().height > 0;
                } else {
                    return false;
                }
            }),
            distinctUntilChanged(),
            takeUntil(this.ngOnDestroy$),
        );
    }

    get width$(): Observable<number> {
        return interval(UPDATE_INTERVAL_MS).pipe(
            startWith(0),
            map(() => {
                if (this.hasViewPlayerContainer) {
                    return this.viewPlayerContainer().nativeElement.getBoundingClientRect().width;
                } else {
                    return MOBILE_WIDTH;
                }
            }),
            distinctUntilChanged(),
            takeUntil(this.ngOnDestroy$),
        );
    }

    get height$(): Observable<number> {
        return interval(UPDATE_INTERVAL_MS).pipe(
            startWith(0),
            map(() => {
                if (this.hasViewPlayerContainer) {
                    return this.viewPlayerContainer().nativeElement.getBoundingClientRect().height;
                } else {
                    return MOBILE_HEIGHT;
                }
            }),
            distinctUntilChanged(),
            takeUntil(this.ngOnDestroy$),
        );
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }
}
