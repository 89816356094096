import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { AccountRequisitesDto, AccountRequisitesEndpoint, AccountRequisitesEndpointPaths, AccountRequisitesGetRequest, AccountRequisitesSetRequest } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebAccountRequisitesDataAccess implements AccountRequisitesEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    setAccountRequisites(request: AccountRequisitesSetRequest): Observable<AccountRequisitesDto> {
        return this.http.post(AccountRequisitesEndpointPaths.setAccountRequisites, request);
    }

    getAccountRequisites(request: AccountRequisitesGetRequest): Observable<AccountRequisitesDto> {
        return this.http.post(AccountRequisitesEndpointPaths.getAccountRequisites);
    }
}
