import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ParamsReferencesListResponse, ParamsReferencesWebEndpoint, ParamsReferencesWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebParamsReferencesDataAccess implements ParamsReferencesWebEndpoint {
    constructor(private readonly http: InteridDataAccessService) {}

    listParamsReferences(): Observable<ParamsReferencesListResponse> {
        return this.http.post(ParamsReferencesWebEndpointPaths.listParamsReferences);
    }
}
