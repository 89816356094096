import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';
import { DeliveryCostDto } from './delivery-cost.dto';
import { BillDto } from './bill.dto';
import { AccountRequisitesDto } from './account-requisites.dto';
import { OrderHistoryShared, OrderShared } from '../shared/order.shared';
import { AccountDto } from './account.dto';
import { ProductDto } from './product.dto';
import { AccountRequisitesShared } from '../shared/account-requisites.shared';

export class OrderProductDto {
  static LAST_DTO_VERSION = '1';

  @ApiModelProperty()
  DTO_VERSION: string = OrderProductDto.LAST_DTO_VERSION;

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  product: ProductDto;

  @ApiModelProperty()
  price: string;

  @ApiModelProperty()
  amount: number;
}

export class OrderDto {
  static LAST_DTO_VERSION = '1';

  @ApiModelProperty()
  DTO_VERSION: string = OrderDto.LAST_DTO_VERSION;

  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  updatedAt: string;

  @ApiModelProperty()
  status: OrderShared.Status;

  @ApiModelProperty()
  paymentMethodType: AccountRequisitesShared.PaymentMethod;

  @ApiModelProperty()
  history: Array<OrderHistoryShared.OrderHistoryEntry>;

  @ApiModelProperty()
  assignedTo?: AccountDto;

  @ApiModelProperty()
  products: Array<OrderProductDto>;

  @ApiModelProperty()
  delivery?: DeliveryCostDto;

  @ApiModelProperty()
  deliveryTitle: string;

  @ApiModelProperty()
  autoCalculateOrderCost: boolean;

  @ApiModelProperty()
  deliveryCost: string;

  @ApiModelProperty()
  productsCost: string;

  @ApiModelProperty()
  orderCost: string;

  @ApiModelProperty()
  name: string;

  @ApiModelProperty()
  email: string;

  @ApiModelProperty()
  phone: string;

  @ApiModelProperty()
  documents: Array<AttachmentDto>;

  @ApiModelProperty()
  deliveryAddress: string;

  @ApiModelProperty()
  entrance: string;

  @ApiModelProperty()
  customerType: string;

  @ApiModelProperty()
  floor: string;

  @ApiModelProperty()
  room: string;

  @ApiModelProperty()
  deliveryCompanies: string;

  @ApiModelProperty()
  accountRequisites: AccountRequisitesDto;

  @ApiModelProperty()
  comment: string;

  @ApiModelProperty()
  annotation: string;

  @ApiModelProperty({
      required: false,
  })
  bills?: Array<BillDto>;
}
