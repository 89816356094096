import { Injectable } from '@angular/core';
import { AppBootstrapDataService, LocalStorageService } from '@interid/interid-site-web/core';

const LOCAL_STORAGE_KEY = `interid_smId`;

@Injectable({
    providedIn: 'root',
})
export class AppMessageService {
    constructor(
        private readonly appBootstrap: AppBootstrapDataService,
        private readonly localStorage: LocalStorageService,
    ) {
    }

    hide(): void {
        this.localStorage.set(`${LOCAL_STORAGE_KEY}_${this.appBootstrap.data.message.sideMessageId}`, '1');

        this.appBootstrap.patchData = {
            message: {
                ...this.appBootstrap.data.message,
                isSiteMessageEnabled: false,
            },
        };
    }

    get isHidden(): boolean {
        return !! this.localStorage.get(`${LOCAL_STORAGE_KEY}_${this.appBootstrap.data.message.sideMessageId}`);
    }
}
