import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsEmail, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Max, Min } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductReviewDto } from '../models/entities/product-review.dto';
import { ProductReviewShared } from '../models/shared/product-review.shared';

export enum ProductReviewEndpointPaths {
    create = '/api/v1/product-review/create',
    edit = '/api/v1/product-review/edit',
    delete = '/api/v1/product-review/delete',
    approve = '/api/v1/product-review/approve',
    reject = '/api/v1/product-review/reject',
    reply = '/api/v1/product-review/reply',
    list = '/api/v1/product-review/list',
}

export interface ProductReviewEndpoint {
    create(request: CreateProductReviewRequest): Promise<ProductReviewDto> | Observable<ProductReviewDto>;
    edit(request: EditProductReviewRequest): Promise<ProductReviewDto> | Observable<ProductReviewDto>;
    delete(request: DeleteProductReviewRequest): Promise<void> | Observable<void>;
    approve(request: ApproveProductReviewRequest): Promise<ProductReviewDto> | Observable<ProductReviewDto>;
    reject(request: RejectProductReviewRequest): Promise<ProductReviewDto> | Observable<ProductReviewDto>;
    reply(request: ReplyProductReviewRequest): Promise<ProductReviewDto> | Observable<ProductReviewDto>;
    list(request: ListProductReviewRequest): Promise<ListProductReviewResponse> | Observable<ListProductReviewResponse>;
}

export class ProductReviewEntityBody {
 
    @ApiModelProperty()
    @IsString()
    author: string;

    @ApiModelProperty()
    @IsString()
    email: string;

    @ApiModelProperty()
    @IsString()
    positive: string;

    @ApiModelProperty()
    @IsString()
    negative: string;

    @ApiModelProperty()
    @IsString()
    contents: string;
}

export class EditProductReviewRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    date?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    author?: string;

    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsOptional()
    email?: string;

    @ApiModelProperty({
        required: false
    })
    @IsString()
    @IsOptional()
    negative: string;

    @ApiModelProperty({
        required: false
    })
    @IsString()
    @IsOptional()
    positive: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    contents: string;
}


export class CreateProductReviewRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty()
    @Max(5)
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    rating: number;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    author?: string;

    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsOptional()
    email?: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    isAnonymous?: boolean;

    @ApiModelProperty({
        required: false
    })
    @IsString()
    @IsOptional()
    negative: string;

    @ApiModelProperty({
        required: false
    })
    @IsString()
    @IsOptional()
    positive: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    contents: string;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    contentAttachmentIds: Array<number>;
}

export class DeleteProductReviewRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ApproveProductReviewRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class RejectProductReviewRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ReplyProductReviewRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    response: string;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    responseAttachmentIds: Array<number>;
}

export type ListProductReviewRequest = ProductReviewShared.SearchRequest;
export type ListProductReviewResponse = ProductReviewShared.SearchResponse;
