import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'trustUrl' })
export class TrustUrlPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
    ) {
    }

    transform(value: any): any {
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }
}
