import { Observable } from 'rxjs';
import { CreateProductQuestionRequest } from '../endpoints/product-question.endpoint';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsOptional, IsPositive } from 'class-validator';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';
import { AttachmentDto } from '../models/entities/attachment.dto';
import { ProductQuestionDto } from '../models/entities/product-question.dto';
import { ProductDto } from '../models/entities/product.dto';
import { ProductCategoryDto } from '../models/entities/product-category.dto';
import { ProductCategorySeoLinkDto } from '../models/entities/product-category-seo-link.dto';

export enum ProductQuestionsWebEndpointPaths {
    question = '/api/v1/web/product-questions/question',
    upload = '/api/v1/web/product-questions/upload',
    questions = '/api/v1/web/product-questions/questions',
}

export interface ProductQuestionsWebEndpoint {
    question(request: CreateProductQuestionRequest, req?: any): Promise<ProductQuestionDto> | Observable<ProductQuestionDto>;
    upload(file: File | any): Promise<AttachmentDto> | Observable<AttachmentDto>;
    questions(request: ProductsQuestionsWebRequest, req?: any): Promise<ProductsQuestionsWebResponse> | Observable<ProductsQuestionsWebResponse>;
}

export class ProductsQuestionsWebRequest extends CrudListRequestDto<ProductQuestionDto> {
    @ApiModelProperty({
        required: false,
        description: 'Product Category Id',
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productCategoryId?: number;

    @ApiModelProperty({
        required: false,
        description: 'Product Category Seo Link Id',
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    productCategorySeoLinkId?: number;
}

export class ProductsQuestionsWebResponse extends CrudListResponseDto<ProductQuestionDto> {
    @ApiModelProperty({
        description: 'Base URL for page',
    })
    url: string;

    @ApiModelProperty({
        description: 'Title of page',
    })
    title: string;

    @ApiModelProperty({
        type: ProductQuestionDto,
        isArray: true,
        description: 'Products question',
    })
    items: Array<ProductQuestionDto>;

    @ApiModelProperty({
        type: ProductDto,
        isArray: true,
        description: 'Products',
    })
    products: Array<ProductDto>;

    @ApiModelProperty({
        description: 'Total Products Count',
    })
    totalProducts: number;

    @ApiModelProperty({
        description: 'Average Rating',
    })
    avgRating: number;

    @ApiModelProperty({
        description: 'Total Ratings',
    })
    totalRatings: number;

    @ApiModelProperty({
        description: 'Product Category',
    })
    productCategory: ProductCategoryDto;

    @ApiModelProperty({
        description: 'Product SEO Category',
        required: false,
    })
    seoLink?: ProductCategorySeoLinkDto;
}
