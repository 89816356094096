import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlockHint } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-hint',
    templateUrl: './content-block-hint.component.html',
    styleUrls: ['./content-block-hint.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockHintComponent {
    @Input() payload: ContentBlockHint;
}
