import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { ApiOnlineStatusService } from '../services/api-online-status.service';
import { DocumentCookieService } from '../services/document-cookie.service';
import { AuthErrorCodes, LOCAL_SESSION_DOCUMENT_COOKIE } from '@interid/interid-site-shared';

@Injectable({
    providedIn: 'root',
})
export class JwtHttpInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
        private readonly jwtService: JwtService,
        private readonly appOnlineStatus: ApiOnlineStatusService,
        private readonly documentCookieService: DocumentCookieService,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.jwtService.hasJwt()) {
            return next.handle(req.clone({
                headers: req.headers
                    .set('Authorization', `Bearer ${this.jwtService.jwt}`),
            })).pipe(
                tap((response: HttpEvent<any>) => {
                    if ('status' in response) {
                        if (this.appOnlineStatus) {
                            this.appOnlineStatus.markApiAsOnline();
                        }
                    }
                }),
                catchError((err) => {
                    if (err.status && typeof err.error === 'object' && err.error !== null && Object.values(AuthErrorCodes).includes(err.error.code)) {
                        this.jwtService.destroy();

                        this.router.navigate(['/auth/sign-in']);
                    }

                    return throwError(err);
                }),
            );
        } else {
            return next.handle(req.clone({
                headers: req.headers
                    .set('X-Local-Session-Id', this.documentCookieService.get(LOCAL_SESSION_DOCUMENT_COOKIE)),
            }));
        }
    }
}
