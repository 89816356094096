import { Observable } from 'rxjs';
import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO } from '../models/shared/common.shared';
import { ManufacturerDto } from '../models/entities/manufacturer.dto';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto } from '../core/crud';
import { CurrencyDto } from '../models/entities/currency.dto';

export enum ManufacturerEndpointPaths {
    createManufacturer = '/api/v1/manufacturer/create',
    editManufacturer = '/api/v1/manufacturer/edit',
    deleteManufacturer = '/api/v1/manufacturer/delete',
    listManufacturers = '/api/v1/manufacturer/list',
    getManufacturer = '/api/v1/manufacturer/get',
    setSortOrder = '/api/v1/manufacturer/setSortOrder',
}

export interface ManufacturerEndpoint {
    createManufacturer(request: CreateManufacturerRequest): Promise<ManufacturerDto> | Observable<ManufacturerDto>;
    editManufacturer(request: EditManufacturerRequest): Promise<ManufacturerDto> | Observable<ManufacturerDto>;
    deleteManufacturer(request: DeleteManufacturerRequest): Promise<void> | Observable<void>;
    listManufacturers(request: ListManufacturersRequest): Promise<ListManufacturersResponse> | Observable<ListManufacturersResponse>;
    getManufacturer(request: GetManufacturerRequest): Promise<ManufacturerDto> | Observable<ManufacturerDto>;
    setSortOrder(request: SetSortOrderManufacturerRequest): Promise<void> | Observable<void>;
}

export class ManufacturerEntityBody {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    imageAttachmentId?: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    uri?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    description: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isDealer: boolean;

    @ApiModelProperty({
        required: false
    })
    @IsString()
    @IsOptional()
    dealerDescription?: string;
 
    @ApiModelProperty({
        required: false,
        nullable: true
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    manufacturerCountryParamDictValueId?: number;

    @ApiModelProperty({
        required: false,
        nullable: true
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    brandCountryParamDictValueId?: number;
 
    @ApiModelProperty({
        required: false,
        nullable: true
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    basePriceCurrencyValue?: number;

    @ApiModelProperty({
        required: false,
        nullable: true
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    basePriceCurrencyId?: number;

    @ApiModelProperty({
        type: () => CurrencyDto,
    })
    @IsObject()
    @IsOptional()
    basePriceCurrency?: CurrencyDto;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    dealerImageAttachmentId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsObject()
    @ValidateNested()
    @IsOptional()
    seo?: PageMetadataSEO;
}

export class CreateManufacturerRequest extends ManufacturerEntityBody {
}

export class EditManufacturerRequest extends ManufacturerEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteManufacturerRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListManufacturersRequest extends CrudListRequestDto<ManufacturerDto> {
}

export class ListManufacturersResponse extends CrudListResponseDto<ManufacturerDto> {
    @ApiModelProperty({
        type: ManufacturerDto,
        isArray: true,
    })
    items: Array<ManufacturerDto>;
}

export class GetManufacturerRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderManufacturerRequest extends CrudSetSortOrderRequestDto { }
