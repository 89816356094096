import { tsToTranslations } from '@interid/interid-site-web/core';

import { interidSiteWebCoreAuthI18n } from './interid-site-web-core-auth.i18n';
import { interidSiteWebCoreFavoriteI18n } from './interid-site-web-core-favorite.i18n';
import { interidSiteWebCoreViewedI18n } from './interid-site-web-core-viewed.i18n';
import { interidSiteWebCoreAppI18n } from './interid-site-web-core-app.i18n';
import { interidSiteWebCoreBreadcrumbsI18n } from './interid-site-web-core-breadcrumbs.i18n';
import { interidSiteWebCoreModelsI18n } from './interid-site-web-core-models.i18n';
import { interidSiteWebCoreSearchI18n } from './interid-site-web-core-search.i18n';
import { interidSiteWebCoreSharedI18n } from './interid-site-web-core-shared.i18n';
import { interidSiteWebCoreShareModelsdI18n } from './interid-site-web-core-shared-models.i18n';

export const interidSiteWebCoreI18n = {
    ...tsToTranslations(interidSiteWebCoreSharedI18n),
    ...tsToTranslations(interidSiteWebCoreShareModelsdI18n),
    ...tsToTranslations(interidSiteWebCoreAuthI18n),
    ...tsToTranslations(interidSiteWebCoreFavoriteI18n),
    ...tsToTranslations(interidSiteWebCoreViewedI18n),
    ...tsToTranslations(interidSiteWebCoreAppI18n),
    ...tsToTranslations(interidSiteWebCoreBreadcrumbsI18n),
    ...tsToTranslations(interidSiteWebCoreModelsI18n),
    ...tsToTranslations(interidSiteWebCoreSearchI18n),
};
