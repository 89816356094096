import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneHref' })
export class PhoneHrefPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
    ) {
    }

    transform(value: any): any {
        const filtered = value
            .split('')
            .filter((symbol: any) => [
                '+', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
            ].includes(symbol))
            .join('');

        return this.sanitizer.bypassSecurityTrustResourceUrl(`tel:${filtered}`);
    }
}
