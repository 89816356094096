import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlockOrderConstructor } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-order-constructor',
    templateUrl: './content-block-order-constructor.component.html',
    styleUrls: ['./content-block-order-constructor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockOrderConstructorComponent {
    @Input() payload: ContentBlockOrderConstructor;
}
