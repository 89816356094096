<app-shared-dialog-close (close)='close()'></app-shared-dialog-close>
<div class='c' [formGroup]='state().form'>
  <div class='c-title'><span>{{ t('title') | translate }}</span></div>
  <div class='c-email'>
    <app-shared-ui-input type='email' formControlName='email' leftIcon='email' placeholder="{{ t('fields.email') | translate }}" (keyup.enter)='ngSubmit()'></app-shared-ui-input>
  </div>
  <div class='c-password'>
    <app-shared-ui-input type='password' formControlName='password' leftIcon='password' placeholder="{{ t('fields.password') | translate }}" (keyup.enter)='ngSubmit()'></app-shared-ui-input>
  </div>
  <div class='c-forgot-password'>
    <div class='link' (click)='forgotPassword()'><span>{{ t('actions.forgot') | translate }}</span></div>
  </div>
  <div class='c-submit'>
    <app-shared-ui-button (click)='ngSubmit()' [disabled]='state().form.disabled'>
      <span>{{ t('actions.submit') | translate }}</span></app-shared-ui-button>
  </div>
  <div class='c-register'>
    <app-shared-ui-button (click)='register()' [disabled]='state().form.disabled' buttonStyle='stroked'>
      <span>{{ t('actions.register') | translate }}</span></app-shared-ui-button>
  </div>
</div>
