import { EventEmitter, Injectable, Output, ViewContainerRef } from '@angular/core';
import { ImageGalleryModalComponent, ImageGalleryModalComponentModalRequest } from './image-gallery-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class ImageGalleryModalService {
    constructor(private readonly matDialog: MatDialog) {}

    @Output('beforeClosed') beforeClosedEvent: EventEmitter<void> = new EventEmitter<void>();

    open(vcr: ViewContainerRef, modalRequest: ImageGalleryModalComponentModalRequest): void {
        const modalRef = this.matDialog.open<ImageGalleryModalComponent, ImageGalleryModalComponentModalRequest>(ImageGalleryModalComponent, {
            data: modalRequest,
            closeOnNavigation: true,
            disableClose: false,
            width: '100vw',
            maxWidth: '100vw',
            viewContainerRef: vcr,
            position: { top: '0px', left: '0px', right: '0px', bottom: '0px' },
            panelClass: '__app-shared-image-modal-panel',
            backdropClass: '__app-shared-image-modal-backdrop',
        });

        modalRef.beforeClosed().subscribe(() => {
            modalRef.componentInstance.beforeClosed();
            this.beforeClosedEvent.emit();
        });
    }
}
