import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { CityDto, CityEndpoint, CityEndpointPaths, CreateCityRequest, DeleteCityRequest, EditCityRequest, ListCitiesResponse, SetCityAsDefaultRequest } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridCmsCityDataAccess implements CityEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    cityByGeolocation(): Observable<CityDto> {
         return this.http.post(CityEndpointPaths.cityByGeolocation);
    }

    listCities(): Observable<ListCitiesResponse> {
        return this.http.post(CityEndpointPaths.listCities);
    }

    createCity(request: CreateCityRequest): Observable<CityDto> {
        return this.http.post(CityEndpointPaths.createCity, request);
    }

    deleteCity(request: DeleteCityRequest): Observable<void> {
        return this.http.post(CityEndpointPaths.deleteCity, request);
    }

    editCity(request: EditCityRequest): Observable<CityDto> {
        return this.http.post(CityEndpointPaths.editCity, request);
    }

    setCityAsDefault(request: SetCityAsDefaultRequest): Observable<CityDto> {
        return this.http.post(CityEndpointPaths.setCityAsDefault, request);
    }
}
