import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AppErrorHandlerService, JwtService } from '@interid/interid-site-web/core';
import { InteridWebAuthDataAccess } from '@interid/interid-site-data-access/web';
import { isApiErrorResponse } from '@interid/interid-site-shared';

let lastTimeVerified: Date;

const INTERVAL_REFRESH = 3 /* seconds */ * 1000 /* ms */;

@Injectable({
    providedIn: 'root',
})
export class VerifyJwtGuard  {
    constructor(
        private readonly appErrorHandler: AppErrorHandlerService,
        private readonly jwtService: JwtService,
        private readonly endpoint: InteridWebAuthDataAccess,
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.verifyJwt();
    }

    canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.verifyJwt();
    }

    private verifyJwt(): Observable<boolean> | boolean {
        if (lastTimeVerified) {
            const now = (new Date()).getTime();
            const last = lastTimeVerified.getTime();

            if (now - last <= INTERVAL_REFRESH) {
                return true;
            }
        }

        if (this.jwtService.hasJwt()) {
            return this.endpoint.verify({
                jwt: this.jwtService.jwt,
            }).pipe(
                catchError((err: HttpErrorResponse) => {
                    const isHttpErrorResponse = !! err && (typeof err === 'object') && !! (err.error);
                    const isApiError = isHttpErrorResponse && isApiErrorResponse(err.error);

                    if (isApiError) {
                        this.jwtService.markJwtAsUnverifiedOrOutdated();
                    } else {
                        return throwError(err);
                    }

                    return of(true);
                }),
                tap(() => this.jwtService.markJwtAsVerified()),
                tap(() => lastTimeVerified = new Date()),
                map(() => true),
                this.appErrorHandler.pipe(),
            );
        } else {
            return true;
        }
    }
}
