import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocationChangedService {
  public urlChanged = new Subject();

  constructor(private location: Location) {

    location.onUrlChange((url, state) => {
      this.urlChanged.next({ url, state });
    });
  }
}