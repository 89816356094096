<div class='c'>
  @for (delivery of bundle().deliveries; track trackByDeliveryId($index, delivery)) {
    <div class='c-delivery'>
      <div class='c-delivery-title is-accent'><span>{{ delivery.title }}</span></div>
      @if (delivery.shortDescription) {
        <div class='c-delivery-description'>
          <div [innerHTML]='delivery.shortDescription | trustHtml'></div>
        </div>
      }
    </div>
  }
</div>
