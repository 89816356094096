import { IsArray, IsNotEmpty, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductSearchRequest } from '../../endpoints/product.endpoint';
import { AttachmentDto } from '../entities/attachment.dto';
import { CommonContentShared, CommonContentWebBundleShared, ContentDTO } from './common-content.shared';

export class Link {
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    uri: string;

    @IsObject()
    @IsNotEmpty()
    @ApiModelProperty()
    source: SourceDefinition;
}

export enum Source {
    Uri = 'Uri',
    Article = 'Article',
    Articles = 'Articles',
    ArticleCategory = 'ArticleCategory',
    Product = 'Product',
    Manufacturer = 'Manufacturer',
    Solution = 'Solution',
    Solutions = 'Solutions',
    SolutionCategory = 'SolutionCategory',
    Catalog = 'Catalog',
    ProductSearch = 'ProductSearch',
    ArticlesSearch = 'ArticlesSearch',
    ProductReviewsSearch = 'ProductReviewsSearch',
    ArticleReviewsSearch = 'ArticleReviewsSearch',
    Dictionary = 'Dictionary',
    Sales = 'Sales',
    Discounted = 'Discounted',
    Markingkits = 'Markingkits',
}

export type SourceDefinition =
    { type: Source.Uri; queryParams?: any; payload?: undefined } |
    { type: Source.Article; queryParams?: any; payload: CommonUrlBuilderShared.SourceArticlePayload } |
    { type: Source.Articles; queryParams?: any; payload?: undefined } |
    { type: Source.Solutions; queryParams?: any; payload?: undefined } |
    { type: Source.ArticleCategory; queryParams?: any; payload: CommonUrlBuilderShared.SourceArticleCategoryPayload } |
    { type: Source.Product; queryParams?: any; payload: CommonUrlBuilderShared.SourceProductPayload } |
    { type: Source.Manufacturer; queryParams?: any; payload: CommonUrlBuilderShared.SourceManufacturerPayload } |
    { type: Source.Solution; queryParams?: any; payload: CommonUrlBuilderShared.SourceSolutionPayload } |
    { type: Source.SolutionCategory; queryParams?: any; payload: CommonUrlBuilderShared.SourceSolutionCategoryPayload } |
    { type: Source.Dictionary; queryParams?: any; payload: CommonUrlBuilderShared.SourceDictionaryPayload } |
    { type: Source.Catalog; queryParams?: any; payload: CommonUrlBuilderShared.SourceCatalogQueryPayload } |
    { type: Source.ProductSearch; queryParams?: any; payload: CommonUrlBuilderShared.SourceProductSearchQueryPayload } |
    { type: Source.ArticlesSearch; queryParams?: any; payload: CommonUrlBuilderShared.SourceArticlesSearchQueryPayload } |
    { type: Source.ProductReviewsSearch; queryParams?: any; payload: CommonUrlBuilderShared.SourceProductReviewsSearchQueryPayload } |
    { type: Source.ArticleReviewsSearch; queryParams?: any; payload: CommonUrlBuilderShared.SourceArticleReviewsSearchQueryPayload } |
    { type: Source.Sales; queryParams?: any; payload: CommonUrlBuilderShared.SourceSalesPayload } |
    { type: Source.Discounted; queryParams?: any; payload: CommonUrlBuilderShared.SourceDiscountedPayload } | 
    { type: Source.Markingkits; queryParams?: any; payload: CommonUrlBuilderShared.SourceMarkingkitsPayload }
;

export namespace CommonUrlBuilderShared {
    export function emptyLink(): Link {
        return {
            uri: '',
            source: emptySourceDefinition(),
        };
    }
    export function emptySourceDefinition(): SourceDefinition {
        return {
            type: Source.Uri,
        };
    }

    export const allSourceTypes: Array<Source> = Object.values(Source);

    export class SourceArticlePayload {
        articleId?: number;
        articleCategoryUri?: string;
        articleUri?: string;
    }

    export class SourceArticleCategoryPayload {
        articleCategoryUri?: string;
        page?: number;
    }

    export class SourceProductPayload {
        productId: number;
        productUri?: string;
    }

    export class SourceManufacturerPayload {
        vendorUrl: string;
        categoryUrl?: string;
        options?: {
            page?: number;
            pageSize?: number;
            esQuery?: ProductSearchRequest;
        };
    }

    export class SourceSolutionPayload {
        solutionId?: number;
        solutionCategoryUri?: string;
        solutionUri?: string;
    }

    export class SourceSolutionCategoryPayload {
        solutionCategoryUri?: string;
    }

    export class SourceCatalogQueryPayload {
        productCategoryId?: number;
        esQuery?: ProductSearchRequest;
        page?: number;
    }

    export class SourceProductSearchQueryPayload {
        queryString: string;
        esQuery?: ProductSearchRequest;
        // TODO: Support!
        page?: number;
    }

    export class SourceArticlesSearchQueryPayload {
        queryString: string;
        articleCategoryId?: number;
        // TODO: Support!
        page?: number;
    }

    export class SourceProductReviewsSearchQueryPayload {
        queryString: string;
        productCategoryId?: number;
        // TODO: Support!
        page?: number;
    }

    export class SourceArticleReviewsSearchQueryPayload {
        queryString: string;
        articleCategoryId?: number;

        // TODO: Support!
        page?: number;
    }

    export class SourceDictionaryPayload {
        category: string;
    }

    export class SourceSalesPayload {
        productCategoryUri?: string;
        esQuery?: ProductSearchRequest;
        page?: number;
    }

    export class SourceDiscountedPayload {
        productCategoryUri?: string;
        esQuery?: ProductSearchRequest;
        page?: number;
    }

    export class SourceMarkingkitsPayload {
        productCategoryUri?: string;
        esQuery?: ProductSearchRequest;
        page?: number;
    }
}

export type PageMetadataTitle = string;

export class PageMetadataSEOEntry {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsString()
    name: string;

    @IsOptional()
    @ApiModelProperty()
    @IsString()
    value: string;
}

export class PageMetadataCardSEO {
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    title: string;

    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    description: string;

    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    @ApiModelProperty()
    url: Link;

    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    @ApiModelProperty({
        type: () => AttachmentDto,
    })
    cover: AttachmentDto;
}

export class PageMetadataSEO {
    @IsString()
    @IsOptional()
    @ApiModelProperty()
    title?: PageMetadataTitle;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    h1?: string;

    @ApiModelProperty()
    @ValidateNested()
    @IsOptional()
    canonical?: Link;

    @ValidateNested()
    @IsObject()
    @IsOptional()
    @ApiModelProperty({
        type: () => ContentDTO,
    })
    additionalTextTop?: ContentDTO;

    @ValidateNested()
    @IsObject()
    @IsOptional()
    @ApiModelProperty()
    additionalTextTopBundle?: CommonContentWebBundleShared.Bundle;

    @ValidateNested()
    @IsObject()
    @IsOptional()
    @ApiModelProperty({
        type: () => ContentDTO,
    })
    additionalTextBottom?: ContentDTO;

    @ValidateNested()
    @IsObject()
    @IsOptional()
    @ApiModelProperty()
    additionalTextBottomBundle?: CommonContentWebBundleShared.Bundle;

    @IsNotEmpty()
    @IsArray()
    @IsObject({ each: true })
    @ValidateNested({ each: true })
    @ApiModelProperty()
    metatags?: Array<PageMetadataSEOEntry>;

    @ValidateNested()
    @IsObject()
    @IsOptional()
    @ApiModelProperty()
    card?: PageMetadataCardSEO;
}

export namespace CommonPageSeoShared {
    export const defaultPageMetadataSEO: () => PageMetadataSEO = () => {
        return {
            title: '',
            metatags: [],
            additionalTextTop: CommonContentShared.emptyContent(),
            additionalTextBottom: CommonContentShared.emptyContent(),
        };
    };

    export function mergePageMetadata(...input: Array<PageMetadataSEO>): PageMetadataSEO {
        const result: PageMetadataSEO = {
            title: undefined,
            card: undefined,
            metatags: [],
            additionalTextTop: CommonContentShared.emptyContent(),
            additionalTextBottom: CommonContentShared.emptyContent(),
        };

        for (const seo of input.filter((e) => !! e)) {
            if (seo.title) {
                result.title = seo.title;
            }

            if (seo.h1) {
                result.h1 = seo.h1;
            }

            if (seo.card) {
                result.card = seo.card;
            }

            if (Array.isArray(seo.metatags)) {
                for (const meta of seo.metatags) {
                    const found = result.metatags.find((m) => m.name === meta.name);

                    if (found) {
                        found.value = meta.value;
                    } else {
                        result.metatags.push(meta);
                    }
                }
            }

            if (! CommonContentShared.isEmpty(seo.additionalTextTop)) {
                result.additionalTextTop = seo.additionalTextTop;
            }

            if (! CommonContentShared.isEmpty(seo.additionalTextBottom)) {
                result.additionalTextBottom = seo.additionalTextBottom;
            }
        }

        return result;
    }

    export enum PageMetadataTemplateSet {
        Common = 'Common',
        Product = 'Product',
        Article = 'Article',
        Articles = 'Articles',
        ArticleCategory = 'ArticleCategory',
        Solution = 'Solution',
        Solutions = 'Solutions',
        SolutionCategory = 'SolutionCategory',
        Manufacturer = 'Manufacturer',
        Catalog = 'Catalog',
        CatalogProductSeries = 'CatalogProductSeries',
        CatalogManufacturer = 'CatalogManufacturer',
        ProductSearch = 'ProductSearch',
        ArticlesSearch = 'ArticlesSearch',
        ArticleReviewsSearch = 'ArticleReviewsSearch',
        ProductReviewsSearch = 'ProductReviewsSearch',
        Dictionary = 'Dictionary',
        Sales = 'Sales',
        SalesWithCategory = 'SalesWithCategory',
    }

    export enum PageMetadataTemplatePage {
        Common = 'Common',
        Product = 'Product',
        ProductWithSale = 'ProductWithSale',
        Article = 'Article',
        Articles = 'Articles',
        ArticleCategory = 'ArticleCategory',
        Solution = 'Solution',
        Solutions = 'Solutions',
        SolutionCategory = 'SolutionCategory',
        Manufacturer = 'Manufacturer',
        Dealer = 'Dealer',
        CatalogLvl0 = 'CatalogLvl0',
        CatalogLvl1 = 'CatalogLvl1',
        CatalogLvl2 = 'CatalogLvl2',
        CatalogLvl3 = 'CatalogLvl3',
        CatalogProductSeries = 'CatalogProductSeries',
        CatalogManufacturer = 'CatalogManufacturer',
        ProductSearch = 'ProductSearch',
        ArticlesSearch = 'ArticlesSearch',
        ArticleReviewsSearch = 'ArticleReviewsSearch',
        ProductReviewsSearch = 'ProductReviewsSearch',
        Dictionary = 'Dictionary',
        Sales = 'Sales',
        SalesWithCategory = 'SalesWithCategory',
    }

    export const pageMetadataTemplateDefinition: Array<{ template: PageMetadataTemplatePage; templateSet: PageMetadataTemplateSet }> = [
        {
            template: PageMetadataTemplatePage.Common,
            templateSet: PageMetadataTemplateSet.Common,
        },
        {
            template: PageMetadataTemplatePage.Product,
            templateSet: PageMetadataTemplateSet.Product,
        },
        {
            template: PageMetadataTemplatePage.ProductWithSale,
            templateSet: PageMetadataTemplateSet.Product,
        },
        {
            template: PageMetadataTemplatePage.Article,
            templateSet: PageMetadataTemplateSet.Article,
        },
        {
            template: PageMetadataTemplatePage.Articles,
            templateSet: PageMetadataTemplateSet.Articles,
        },
        {
            template: PageMetadataTemplatePage.ArticleCategory,
            templateSet: PageMetadataTemplateSet.ArticleCategory,
        },
        {
            template: PageMetadataTemplatePage.Solution,
            templateSet: PageMetadataTemplateSet.Solution,
        },
        {
            template: PageMetadataTemplatePage.Solutions,
            templateSet: PageMetadataTemplateSet.Solutions,
        },
        {
            template: PageMetadataTemplatePage.SolutionCategory,
            templateSet: PageMetadataTemplateSet.SolutionCategory,
        },
        {
            template: PageMetadataTemplatePage.Manufacturer,
            templateSet: PageMetadataTemplateSet.Manufacturer,
        },
        {
            template: PageMetadataTemplatePage.Dealer,
            templateSet: PageMetadataTemplateSet.Manufacturer,
        },
        {
            template: PageMetadataTemplatePage.CatalogLvl0,
            templateSet: PageMetadataTemplateSet.Common,
        },
        {
            template: PageMetadataTemplatePage.CatalogLvl1,
            templateSet: PageMetadataTemplateSet.Catalog,
        },
        {
            template: PageMetadataTemplatePage.CatalogLvl2,
            templateSet: PageMetadataTemplateSet.Catalog,
        },
        {
            template: PageMetadataTemplatePage.CatalogLvl3,
            templateSet: PageMetadataTemplateSet.Catalog,
        },
        {
            template: PageMetadataTemplatePage.CatalogProductSeries,
            templateSet: PageMetadataTemplateSet.CatalogProductSeries,
        },
        {
            template: PageMetadataTemplatePage.CatalogManufacturer,
            templateSet: PageMetadataTemplateSet.CatalogManufacturer,
        },
        {
            template: PageMetadataTemplatePage.CatalogLvl3,
            templateSet: PageMetadataTemplateSet.Catalog,
        },
        {
            template: PageMetadataTemplatePage.ProductSearch,
            templateSet: PageMetadataTemplateSet.ProductSearch,
        },
        {
            template: PageMetadataTemplatePage.ArticlesSearch,
            templateSet: PageMetadataTemplateSet.ArticlesSearch,
        },
        {
            template: PageMetadataTemplatePage.ArticleReviewsSearch,
            templateSet: PageMetadataTemplateSet.ArticleReviewsSearch,
        },
        {
            template: PageMetadataTemplatePage.ProductReviewsSearch,
            templateSet: PageMetadataTemplateSet.ProductReviewsSearch,
        },
        {
            template: PageMetadataTemplatePage.Dictionary,
            templateSet: PageMetadataTemplateSet.Dictionary,
        },
        {
            template: PageMetadataTemplatePage.Sales,
            templateSet: PageMetadataTemplateSet.Sales,
        },
        {
            template: PageMetadataTemplatePage.SalesWithCategory,
            templateSet: PageMetadataTemplateSet.SalesWithCategory,
        },
    ];

    export interface PageMetadataTemplateDefinition {
        template: string;
    }

    export const pageMetadataTemplateSets: Array<{ templateSet: PageMetadataTemplateSet; definitions: Array<PageMetadataTemplateDefinition> }> = [
        {
            templateSet: PageMetadataTemplateSet.Common,
            definitions: [
                {
                    template: 'date',
                },
                {
                    template: 'uri',
                },
                {
                    template: 'url',
                },
                {
                    template: 'city',
                },
                {
                    template: 'city_genitive',
                },
                {
                    template: 'city_dative',
                },
                {
                    template: 'city_accusative',
                },
                {
                    template: 'city_instrumental',
                },
                {
                    template: 'city_prepositional',
                },
                {
                    template: 'city_prepositional*',
                },
                {
                    template: 'city_address',
                },
                {
                    template: 'city_phone',
                },
                {
                    template: 'product_img',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.Product,
            definitions: [
                {
                    template: 'catalog_category',
                },
                {
                    template: 'product_title',
                },
                {
                    template: 'product_annotation',
                },
                {
                    template: 'product_category',
                },
                {
                    template: 'product_price',
                },
                {
                    template: 'product_sale',
                },
                {
                    template: 'product_sale_card',
                },
                {
                    template: 'product_manufacturer',
                },
                {
                    template: 'product_partNumber',
                },
                {
                    template: 'product_vendorCode',
                },
                {
                    template: 'product_vendorCode1C',
                },
                {
                    template: 'product_series',
                },
                {
                    template: 'product_tags',
                },
                {
                    template: 'product_options',
                },
                {
                    template: 'product_params',
                },
                {
                    template: 'product_category_level_1',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.Article,
            definitions: [
                {
                    template: 'article_category_title',
                },
                {
                    template: 'article_category_alt',
                },
                {
                    template: 'article_title',
                },
                {
                    template: 'article_alt',
                },
                {
                    template: 'article_related_categories',
                },
                {
                    template: 'article_related_products',
                },
                {
                    template: 'article_authors',
                },
                {
                    template: 'article_tags',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.Articles,
            definitions: [],
        },
        {
            templateSet: PageMetadataTemplateSet.ArticleCategory,
            definitions: [
                {
                    template: 'article_category_title',
                },
                {
                    template: 'article_category_alt',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.Solutions,
            definitions: [],
        },
        {
            templateSet: PageMetadataTemplateSet.Solution,
            definitions: [
                {
                    template: 'solution_title',
                },
                {
                    template: 'solution_alt',
                },
                {
                    template: 'solution_cost',
                },
                {
                    template: 'solution_tags',
                },
                {
                    template: 'solution_category_title',
                },
                {
                    template: 'solution_category_alt',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.SolutionCategory,
            definitions: [
                {
                    template: 'solution_category_title',
                },
                {
                    template: 'solution_category_alt',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.Manufacturer,
            definitions: [
                {
                    template: 'manufacturer_title',
                },
                {
                    template: 'manufacturer_description',
                },
                {
                    template: 'manufacturer_dealer_description',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.Catalog,
            definitions: [
                {
                    template: 'catalog_category',
                },
                {
                    template: 'min_price',
                },
                {
                    template: 'count',
                },
                {
                    template: 'min_price_category',
                },
                {
                    template: 'count_category',
                },
                {
                    template: 'product_category_level_1',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.CatalogProductSeries,
            definitions: [
                {
                    template: 'catalog_category',
                },
                {
                    template: 'min_price',
                },
                {
                    template: 'count',
                },
                {
                    template: 'min_price_category',
                },
                {
                    template: 'count_category',
                },
                {
                    template: 'product_series',
                },
                {
                    template: 'manufacturer_title',
                },
                {
                    template: 'manufacturer_description',
                },
                {
                    template: 'manufacturer_dealer_description',
                },
                {
                    template: 'product_category_level_1',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.CatalogManufacturer,
            definitions: [
                {
                    template: 'catalog_category',
                },
                {
                    template: 'min_price',
                },
                {
                    template: 'count',
                },
                {
                    template: 'min_price_category',
                },
                {
                    template: 'count_category',
                },
                {
                    template: 'manufacturer_title',
                },
                {
                    template: 'manufacturer_description',
                },
                {
                    template: 'manufacturer_dealer_description',
                },
                {
                    template: 'product_category_level_1',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.ProductSearch,
            definitions: [
                {
                    template: 'search_count',
                },
                {
                    template: 'search_query_string',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.ArticlesSearch,
            definitions: [
                {
                    template: 'search_count',
                },
                {
                    template: 'search_query_string',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.ArticleReviewsSearch,
            definitions: [
                {
                    template: 'search_count',
                },
                {
                    template: 'search_query_string',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.ProductReviewsSearch,
            definitions: [
                {
                    template: 'search_count',
                },
                {
                    template: 'search_query_string',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.Dictionary,
            definitions: [
                {
                    template: 'dictionary_title',
                },
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.Sales,
            definitions: [
            ],
        },
        {
            templateSet: PageMetadataTemplateSet.SalesWithCategory,
            definitions: [
                {
                    template: 'catalog_category',
                },
            ],
        },
    ];
}
