/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, computed, ElementRef, EventEmitter, input, Input, OnDestroy, OnInit, Output, signal, viewChild, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

type Type = 'text' | 'email' | 'password';
type Icon = 'phone' | 'email' | 'person' | 'password' | 'location' | 'comment';
type InputStyle = 'default';
type InputColor = 'primary';

interface State {
    form: UntypedFormGroup;
    isFocused: boolean;
}

interface FormValue {
    value: string;
}

@Component({
    selector: 'app-shared-ui-input',
    templateUrl: './ui-input.component.html',
    styleUrls: ['./ui-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
    public inputRef = viewChild<ElementRef<HTMLInputElement>>('input');

    public type = input<Type>('text');
    public leftIcon = input<Icon>();
    public placeholder = input('');
    public inputStyle = input<InputStyle>('default');
    public inputColor = input<InputColor>('primary');
    public withDefaultErrors = input(false);
    public inputId = input<string>();
    public withFocus = input(true);

    @Output('enter') enterEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('escape') escapeEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('inputBlur') inputBlurEvent: EventEmitter<void> = new EventEmitter<void>();
    
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    private onChange: Function;
    private onTouched: Function;

    public state = signal<State>({
        isFocused: false,
        form: this.fb.group({
            value: [''],
        }),
    });

    get ngClass(): any {
        const styles = [
            `style-${this.inputStyle()}`,
            `color-${this.inputColor()}`,
            this.state().isFocused ? 'is-focused' : 'is-not-focused',
        ];

        if (this.state().form.disabled) {
            styles.push('is-disabled');
        }

        if (this.ngControl.invalid && this.ngControl.touched) {
            styles.push('is-invalid');
        }

        return styles;
    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state().form.patchValue(formValue);
    }

    constructor(
        public ngControl: NgControl,
        private readonly fb: UntypedFormBuilder,
    ) {
        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.state().form.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.ngOnDestroy$),
        ).subscribe((next: FormValue) => {
            this.onChange(next.value);
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled
            ? this.state().form.disable()
            : this.state().form.enable();
    }

    writeValue(obj: any): void {
        this.patchFormValue = {
            value: obj,
        };
    }

    focusInput(): void {
        if (this.withFocus() && this.inputRef() && this.inputRef().nativeElement) {
            this.inputRef().nativeElement.focus();
        }
    }

    onInputBlur(): void {
        this.onTouched();

        this.state.update((prev) => ({
            ...prev,
            isFocused: false,
        }));
        
        this.inputBlurEvent.emit();
    }

    onInputFocus(): void {
        this.state.update((prev) => ({
            ...prev,
            isFocused: true,
        }));
    }
}
