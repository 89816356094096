import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UrlBuilderService } from '../services/url-builder.service';

const REPLACES_KEY = '___replaces';

@Injectable({
    providedIn: 'root',
})
export class ReplacesHttpInterceptor implements HttpInterceptor {
    constructor(
        private readonly urlBuilder: UrlBuilderService,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((response: HttpResponse<any>) => {
                if ((response instanceof HttpResponse) && response.body && response.body[REPLACES_KEY]) {
                    this.urlBuilder.import(response.body[REPLACES_KEY]);
                }
            }) as any,
        );
    }
}
