import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { PrivacyWebEndpoint, PrivacyWebEndpointPaths, PrivacyWebResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebPrivacyDataAccess implements PrivacyWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    privacy(): Observable<PrivacyWebResponse> {
        return this.http.post(PrivacyWebEndpointPaths.privacy);
    }
}
