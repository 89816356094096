import { DevFeedbackStatus } from '../shared/dev-feedback.shared';
import { AttachmentDto } from './attachment.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class DevFeedbackDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    author: string;

    @ApiModelProperty()
    authorEmail: string;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    description: string;

    @ApiModelProperty({
        required: false,
    })
    comment?: string;

    @ApiModelProperty({
        type: AttachmentDto,
        isArray: true,
    })
    files: Array<AttachmentDto>;

    @ApiModelProperty()
    dateCreatedAt: string;

    @ApiModelProperty()
    dateUpdatedAt: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DevFeedbackStatus),
    })
    status: DevFeedbackStatus;
}
