import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { isContentDTOEmpty, PageMetadataCardSEO, PageMetadataSEO, ProductESDto } from '@interid/interid-site-shared';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { H1Service } from './h1.service';
import { AdditionalTextService } from './additional-text.service';
import { EnvironmentWebService } from './environment-web.service';

export enum SeoMetaSource {
    Local,
    External,
}

interface ApplyMetaRequest {
    source: SeoMetaSource;
    title?: string;
    meta?: [string, string][];
    metaCard?: PageMetadataCardSEO;
    metaSeoObject?: PageMetadataSEO;
}

interface MetaSession {
    name: string;
    element: HTMLMetaElement;
    setFrom: SeoMetaSource;
}

interface TitleSession {
    isSetFromExternal: boolean;
}

interface MicrodataSession {
    isSetFromExternal: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class SeoMetaService {
    private stop$: Subject<void> = new Subject<void>();

    private titleSession: TitleSession = { isSetFromExternal: false };
    private microdataSession: MicrodataSession = { isSetFromExternal: false };
    private metaSession: Array<MetaSession> = []; 

    constructor(private readonly env: EnvironmentWebService, private readonly router: Router, private readonly htmlTitle: Title, private readonly htmlMeta: Meta, private readonly h1Service: H1Service, private readonly additionalTextService: AdditionalTextService) {
        this.watchForRouterChanges(); 
    }

    t(input: string): string {
        return `app.components.app_not_found.${input}`;
    }

    watchForRouterChanges(): void {
        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationStart),
                takeUntil(this.stop$)
            )
            .subscribe(() => {
                let nextMetaTag: MetaSession;

                // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                while ((nextMetaTag = this.metaSession.pop())) {
                    if (!nextMetaTag) {
                        continue;
                    }

                    this.htmlMeta.removeTagElement(nextMetaTag.element);
                }

                this.titleSession = {
                    isSetFromExternal: false,
                };
            });
    }

    stopWatchForRouterChanges(): void {
        this.stop$.next();
    }

    isMetaSetFromExternal(name: string): boolean {
        return !!this.metaSession.find((m) => m.setFrom === SeoMetaSource.External && m.name === name);
    }

    canUpdateMeta(source: SeoMetaSource, name: string): boolean {
        return source === SeoMetaSource.External || !this.isMetaSetFromExternal(name);
    }

    canUpdateTitle(source: SeoMetaSource): boolean {
        return source === SeoMetaSource.External || !this.titleSession.isSetFromExternal;
    }

    canUpdateMicrodata(source: SeoMetaSource): boolean {
        return source === SeoMetaSource.External || !this.microdataSession.isSetFromExternal;
    }

    setTitle(source: SeoMetaSource, title: string): void {
        if (!this.canUpdateTitle(source)) {
            return;
        }

        this.htmlTitle.setTitle(title);

        if (!!title && source === SeoMetaSource.External) {
            this.titleSession.isSetFromExternal = true;
        }
    }

    setMeta(source: SeoMetaSource, name: string, content: string): void {
        if (!this.canUpdateMeta(source, name)) {
            return;
        }

        this.metaSession.push({
            setFrom: source,
            name,
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            element: this.htmlMeta.updateTag({
                name,
                content,
            }),
        });
    }

    applyMeta(request: ApplyMetaRequest, product?: ProductESDto): void {
        if (request.title && this.canUpdateTitle(request.source)) {
            this.setTitle(request.source, request.title);
        }

        if (request.meta) {
            for (const metaTag of request.meta) {
                this.setMeta(request.source, metaTag[0], metaTag[1]);
            }
        }

        if (request.metaCard) {
            this.applyMetaCard(request.source, request.metaCard);
        }

        if (request.metaSeoObject && request.metaSeoObject.title) {
            this.setTitle(request.source, request.metaSeoObject.title);
        }

        if (request.metaSeoObject && Array.isArray(request.metaSeoObject.metatags) && request.metaSeoObject.metatags.length > 0) {
            for (const metaTag of request.metaSeoObject.metatags) {
                this.setMeta(request.source, metaTag.name, metaTag.value);
            }
        }

        if (request.metaSeoObject && request.metaSeoObject.card) {
            this.applyMetaCard(request.source, request.metaSeoObject.card);
        }

        if (request.metaSeoObject && request.metaSeoObject.h1) {
            this.h1Service.setH1(request.metaSeoObject.h1);
        }

        if (request.metaSeoObject && request.metaSeoObject.additionalTextTop && !isContentDTOEmpty(request.metaSeoObject.additionalTextTop)) {
            this.additionalTextService.additionalTextTop = {
                content: request.metaSeoObject.additionalTextTop,
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                bundle: request.metaSeoObject.additionalTextTopBundle,
            };
        }

        if (request.metaSeoObject && request.metaSeoObject.additionalTextBottom && !isContentDTOEmpty(request.metaSeoObject.additionalTextBottom)) {
            this.additionalTextService.additionalTextBottom = {
                content: request.metaSeoObject.additionalTextBottom,
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                bundle: request.metaSeoObject.additionalTextBottomBundle,
            };
        } 
    }

    applyMetaCard(source: SeoMetaSource, card: PageMetadataCardSEO): void {
        const url = `${this.env.current.baseUrl}${card.url.uri}`;

        const meta = [
            ['og:title', card.title],
            ['og:description', card.description],
            ['og:url', url],
            ['twitter:title', card.title],
            ['twitter:description', card.description],
            ['twitter:card', 'summary_large_image'],
        ];

        if (card.cover && card.cover.path) {
            const imageUrl = card.cover.url;

            meta.push(
                ...([
                    ['og:image', imageUrl],
                    ['twitter:image', imageUrl],
                ] as any)
            );
        }

        for (const metaTag of meta) {
            this.setMeta(source, metaTag[0], metaTag[1]);
        }
    }
}
