import { ChangeDetectionStrategy, Component, OnInit, PLATFORM_ID, Signal, inject, signal } from '@angular/core';
import { AppBootstrapDataService, EnvironmentWebService, UrlBuilderService } from '@interid/interid-site-web/core';
import { CityDto, Source } from '@interid/interid-site-shared';
import { APP_LAYOUT_RESOLVE_KEY, AppLayoutResolveData } from '../app-layout/app-layout.resolve';
import { isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { signalFromRouteData } from '@interid/interid-site-web/ui-signals';
import { REQUEST } from '@interid/interid-site-web/ui-shared';

interface ProductCategoryLink {
    id: number;
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    link: any;
}

interface State {
    isMenuOpened: boolean;
    isCategoriesOpened: boolean;
    categories: Array<ProductCategoryLink>;
    city: CityDto;
}

@Component({
    selector: 'app-footer-mobile',
    templateUrl: './app-footer-mobile.component.html',
    styleUrls: ['./app-footer-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterMobileComponent implements OnInit {
    protected env = inject(EnvironmentWebService);
    protected urlBuilder = inject(UrlBuilderService);
    protected appBootstrap = inject(AppBootstrapDataService);
    protected platformId = inject(PLATFORM_ID);
    protected request: Request = inject(REQUEST, { optional: true });

    public state = signal<State>({
        isMenuOpened: false,
        isCategoriesOpened: false,
        categories: [],
        city: new CityDto()
    });

    public resolvedData: Signal<AppLayoutResolveData> = signalFromRouteData(APP_LAYOUT_RESOLVE_KEY);

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.state.update((prev) => ({
            ...prev,
            city: this.appBootstrap.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find(x=>x.isDefault)

        }));

        this.state.update((prev) => ({
            ...prev,
            categories: this.resolvedData().popularProductCategories.map((next) => ({
                id: next.id,
                title: next.title,
                link: this.urlBuilder.urlLink({
                    type: Source.Catalog,
                    payload: {
                        productCategoryId: next.id,
                    },
                }),
            })),
        }));
    }

    toggleMenu(): void {
        this.state.update((prev) => ({
            ...prev,
            isMenuOpened: ! prev.isMenuOpened,
            isCategoriesOpened: false,
        }));
    }

    toggleCategories(): void {
        this.state.update((prev) => ({
            ...prev,
            isMenuOpened: false,
            isCategoriesOpened: ! prev.isCategoriesOpened,
        }));
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }
}
