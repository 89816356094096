import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { MyReviewsListWebRequest, MyReviewsListWebResponse, MyReviewsWebEndpoint, MyReviewsWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebMyReviewsDataAccess implements MyReviewsWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    list(request: MyReviewsListWebRequest): Observable<MyReviewsListWebResponse> {
        return this.http.post(MyReviewsWebEndpointPaths.list, request);
    }
}
