import { Observable } from 'rxjs';
import { IsEnum, IsNotEmpty } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SystemTaskShared } from '../models/shared/system-task.shared';
import { SystemTaskDto } from '../models/entities/system-task.dto';
import { IndexStatusDto } from '../models/entities/index.dto';
import { IndexShared } from '../models/shared/index.shared';

export enum IndexEndpointPaths {
    indexStatus = '/api/v1/index/indexStatus',
    resetIndex = '/api/v1/index/resetIndex',
    resetRedirectIndex = '/api/v1/index/resetRedirectIndex',
    resetMetatagsIndex = '/api/v1/index/resetMetatagIndex',
    resetProductCategoriesIndex = '/api/v1/index/resetProductCategoriesIndex',
    dropIndexes = '/api/v1/index/dropIndexes',
    updateMappings = '/api/v1/index/updateMappings',
    esReindex = '/api/v1/index/esReindex',
    esUpdate = '/api/v1/index/esUpdate',
    flushAllRedis = '/api/v1/index/flushAllRedis',
}

export interface IndexEndpoint {
    indexStatus(): Promise<IndexStatusDto> | Observable<IndexStatusDto>;
    resetIndex(): Promise<IndexStatusDto> | Observable<IndexStatusDto>;
    resetRedirectIndex(): Promise<IndexStatusDto> | Observable<IndexStatusDto>;
    resetMetatagsIndex(): Promise<IndexStatusDto> | Observable<IndexStatusDto>;
    resetProductCategoriesIndex(): Promise<IndexStatusDto> | Observable<IndexStatusDto>;
    dropIndexes(): Promise<void> | Observable<void>;
    updateMappings(): Promise<void> | Observable<void>;
    esReindex(): Promise<void> | Observable<void>;
    esUpdate(request: EsUpdateRequest): Promise<EsUpdateResponse> | Observable<EsUpdateResponse>;
    flushAllRedis(): Promise<void> | Observable<void>;
}

export class EsUpdateRequest {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(IndexShared.IndexType),
    })
    @IsEnum(IndexShared.IndexType)
    @IsNotEmpty()
    index: IndexShared.IndexType;
}

export type EsUpdateResponse = SystemTaskDto<IndexShared.SystemTaskTypes, SystemTaskShared.MultiEntriesResponse>;
