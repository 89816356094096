<div class="c">
  <div class="c-options">
    @for (link of state.links; track link) {
      <div class="c-option" (click)="clickLink(link)">
        @if (link.type === 'telegram') {
          <div class="c-option-label">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#a)"><path d="M17.22 3.096 2.447 8.793c-1.009.405-1.003.968-.185 1.219l3.793 1.183 8.777-5.537c.415-.253.794-.117.482.16l-7.11 6.417H8.2h.002l-.262 3.91c.383 0 .552-.175.767-.382l1.843-1.792 3.832 2.83c.707.39 1.215.19 1.39-.653L18.29 4.29c.258-1.033-.394-1.5-1.068-1.195Z" fill="#617E8C" /></g>
              <defs>
                <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
              </defs>
            </svg>
          </div>
        }
        @if (link.type === 'vk') {
          <div class="c-option-label">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.934 5.156c.139-.44 0-.76-.616-.76h-2.045c-.516 0-.755.28-.894.58 0 0-1.052 2.562-2.522 4.224-.476.48-.694.64-.953.64-.139 0-.318-.16-.318-.6V5.136c0-.52-.158-.761-.595-.761H7.773a.495.495 0 0 0-.516.48c0 .5.735.621.814 2.022v3.045c0 .66-.117.781-.377.781-.695 0-2.383-2.582-3.396-5.525-.196-.583-.396-.803-.913-.803H1.32c-.596 0-.695.28-.695.58 0 .541.695 3.263 3.237 6.867 1.688 2.46 4.09 3.783 6.255 3.783 1.31 0 1.47-.3 1.47-.8v-1.862c0-.6.119-.7.536-.7.297 0 .834.159 2.045 1.34 1.39 1.401 1.628 2.042 2.403 2.042h2.045c.596 0 .874-.3.715-.88-.179-.581-.854-1.422-1.728-2.423-.476-.56-1.191-1.18-1.41-1.481-.297-.4-.218-.56 0-.92-.02 0 2.483-3.564 2.74-4.766" fill="#617E8C" /></svg>
          </div>
        }
        @if (link.type === 'whatsapp') {
          <div class="c-option-label">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.722 14.06c-.255-.131-1.528-.755-1.765-.839-.236-.089-.41-.131-.58.131-.174.26-.667.84-.821 1.015-.15.174-.302.195-.558.066-1.519-.76-2.515-1.355-3.516-3.073-.264-.457.265-.424.76-1.41.084-.174.042-.322-.024-.453-.065-.131-.58-1.402-.797-1.92-.208-.504-.424-.433-.58-.443-.15-.009-.322-.009-.495-.009a.956.956 0 0 0-.69.321c-.236.26-.904.886-.904 2.156s.926 2.501 1.052 2.675c.132.173 1.821 2.78 4.416 3.902 1.64.708 2.283.769 3.103.647.5-.075 1.528-.624 1.741-1.23.214-.605.214-1.123.15-1.231-.063-.115-.236-.18-.492-.305Z" fill="#617E8C" />
              <path d="M21.684 7.931a10.505 10.505 0 0 0-5.615-5.615A10.45 10.45 0 0 0 12 1.5h-.047A10.475 10.475 0 0 0 1.5 12.05a10.533 10.533 0 0 0 1.125 4.684v3.563c0 .595.483 1.078 1.078 1.078h3.565a10.532 10.532 0 0 0 4.685 1.125h.05c1.403 0 2.765-.272 4.047-.804a10.418 10.418 0 0 0 3.347-2.231 10.473 10.473 0 0 0 3.103-7.418 10.459 10.459 0 0 0-.816-4.116Zm-3.54 10.266A8.673 8.673 0 0 1 12 20.719h-.04a8.75 8.75 0 0 1-4.057-1.02l-.197-.105h-3.3v-3.3l-.105-.197a8.75 8.75 0 0 1-1.02-4.057 8.665 8.665 0 0 1 2.522-6.183 8.653 8.653 0 0 1 6.16-2.576h.04c1.171 0 2.308.228 3.38.678a8.662 8.662 0 0 1 2.788 1.875 8.683 8.683 0 0 1 2.552 6.206 8.686 8.686 0 0 1-2.58 6.157Z" fill="#617E8C" />
            </svg>
          </div>
        }
        @if (link.type === 'link') {
          <div class="c-option-label">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m15.802 12.848-1.441-1.442 1.441-1.441a4.078 4.078 0 0 0-5.767-5.767L8.594 5.639 7.152 4.198l1.442-1.442a6.117 6.117 0 0 1 8.65 8.65l-1.442 1.442Zm-2.884 2.884-1.441 1.441a6.116 6.116 0 1 1-8.65-8.65l1.441-1.441L5.71 8.523 4.268 9.965a4.078 4.078 0 1 0 5.767 5.767l1.442-1.442 1.441 1.442Zm0-10.093 1.443 1.443-7.209 7.207-1.442-1.441 7.208-7.208Z" fill="#617E8C" /></svg>
          </div>
        }
        <div class="c-option-text">
          <span>{{ link.title }}</span>
        </div>
      </div>
    }
  </div>
</div>
