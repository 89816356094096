import { Injectable } from '@angular/core';
import { AppSessionAccountProfile, AppSessionAccountSettings, AppSessionAddToBasketRequest, AppSessionAddToComparesRequest, AppSessionAddToFavoritesRequest, AppSessionBasket, AppSessionBasketEntry, AppSessionListFavoritesRequest, AppSessionListFavoritesResponse, AppSessionRemoveComparesRequest, AppSessionRemoveFavoritesRequest, AppSessionRemoveFromBasketRequest, AppSessionSetAccountProfile, AppSessionSetCityRequest, AppSessionStrategy, AppSessionUpdateAccountSettings, AppSessionSetProductBasketRequest, AppSessionListProductsToCompareResponse, AppSessionUpdateCountersRequest, AppSessionSetCatalogPageSize, AppSessionAddToViewedRequest, AppSessionListViewedRequest, AppSessionListViewedResponse } from './interfaces/app-session-strategy.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AppBootstrapDataService, JwtService } from '@interid/interid-site-web/core';
import { LocalStorageAppSessionStrategy } from './strategies/local-storage.app-session-strategy';
import { ApiEndpointAppSessionStrategy } from './strategies/api-endpoint.app-session-strategy';
import { CityDto } from '@interid/interid-site-shared';

@Injectable({
    providedIn: 'root',
})
export class AppSessionService implements AppSessionStrategy {
    private _strategy$: BehaviorSubject<AppSessionStrategy> = new BehaviorSubject(this.localStorageStrategy);

    constructor(readonly jwt: JwtService, private readonly appBootstrap: AppBootstrapDataService, private readonly localStorageStrategy: LocalStorageAppSessionStrategy, private readonly apiEndpointStrategy: ApiEndpointAppSessionStrategy) {
        if (this.jwt.hasJwt()) {
            this.switchToApiEndpointStrategy();
        }

        jwt.jwt$
            .pipe(
                distinctUntilChanged(),
                switchMap(() => jwt.jwtPayload$)
            )
            .subscribe((next) => {
                if (next) {
                    this.switchToApiEndpointStrategy();
                } else {
                    this.switchToLocalStrategy();
                }
            });
    }

    init(): void {
        this.allStrategies.forEach((s) => s.init());
    }

    private get strategy(): AppSessionStrategy {
        return this._strategy$.getValue();
    }

    switchToLocalStrategy(): void {
        this._strategy$.next(this.localStorageStrategy);
    }

    switchToApiEndpointStrategy(): void {
        this._strategy$.next(this.apiEndpointStrategy);
    }

    get strategy$(): Observable<AppSessionStrategy> {
        return this._strategy$.asObservable();
    }

    get allStrategies(): Array<AppSessionStrategy> {
        return [this.localStorageStrategy, this.apiEndpointStrategy];
    }

    get isLocalStorageStrategy(): boolean {
        return this._strategy$.getValue() instanceof LocalStorageAppSessionStrategy;
    }

    get city$(): Observable<CityDto> {
        return this._strategy$.pipe(
            switchMap((strategy) => strategy.getSelectedCityId$()),
            map((cityId) => {
                const defaultCity = this.appBootstrap.data.cities.find((c) => c.isDefault);

                if (!defaultCity) {
                    console.warn('Default city is not available. Please setup it in CMS!');
                }

                if (cityId) {
                    const selectedCity = this.appBootstrap.data.cities.find((c) => c.id === cityId);

                    return selectedCity ? selectedCity : defaultCity;
                } else {
                    return defaultCity;
                }
            })
        );
    }

    getAccountProfile(): Observable<AppSessionAccountProfile> {
        return this.strategy.getAccountProfile();
    }

    updateAccountProfile(request: AppSessionSetAccountProfile): Observable<void> {
        return this.strategy.updateAccountProfile(request);
    }

    get accountSettings$(): Observable<AppSessionAccountSettings> {
        return this._strategy$.pipe(switchMap((strategy) => strategy.accountSettings$));
    }

    getAccountSettings(): AppSessionAccountSettings {
        return this.strategy.getAccountSettings();
    }

    getAccountSettings$(): Observable<AppSessionAccountSettings> {
        return this.strategy.getAccountSettings$();
    }

    updateAccountSettings(request: AppSessionUpdateAccountSettings): Observable<void> {
        return this.strategy.updateAccountSettings(request);
    }

    get numFavorites$(): Observable<number> {
        return this._strategy$.pipe(switchMap((strategy) => strategy.numFavorites$));
    }

    listViewed(request: AppSessionListViewedRequest): Observable<AppSessionListViewedResponse> {
        return this.strategy.listViewed(request);
    }

    destroyViewed(): Observable<void> {
        return this.strategy.destroyViewed();
    }

    addToViewed(request: AppSessionAddToViewedRequest): Observable<void> {
        return this.strategy.addToViewed(request);
    }

    addToFavorites(request: AppSessionAddToFavoritesRequest): Observable<void> {
        return this.strategy.addToFavorites(request);
    }

    deleteFromFavorites(request: AppSessionRemoveFavoritesRequest): Observable<void> {
        return this.strategy.deleteFromFavorites(request);
    }

    listFavorites(request: AppSessionListFavoritesRequest): Observable<AppSessionListFavoritesResponse> {
        return this.strategy.listFavorites(request);
    }

    destroyFavorites(): Observable<void> {
        return this.strategy.destroyFavorites();
    }

    get numCompares$(): Observable<number> {
        return this._strategy$.pipe(switchMap((strategy) => strategy.numCompares$));
    }

    addToCompares(request: AppSessionAddToComparesRequest): Observable<void> {
        return this.strategy.addToCompares(request);
    }

    deleteFromCompares(request: AppSessionRemoveComparesRequest): Observable<void> {
        return this.strategy.deleteFromCompares(request);
    }

    listProductsToCompare(): Observable<AppSessionListProductsToCompareResponse> {
        return this.strategy.listProductsToCompare();
    }

    getSelectedCityId$(): Observable<number | undefined> {
        return this._strategy$.pipe(switchMap((strategy) => strategy.getSelectedCityId$()));
    }

    hasSelectedCity$(): Observable<boolean> {
        return this._strategy$.pipe(switchMap((strategy) => strategy.hasSelectedCity$()));
    }

    setCity(request: AppSessionSetCityRequest): Observable<void> {
        return this.strategy.setCity(request);
    }

    isSubscribed$(): Observable<boolean> {
        return this.strategy.isSubscribed$();
    }

    markAccountAsSubscribed(): void {
        this.strategy.markAccountAsSubscribed();
    }

    markAccountAsUnsubscribed(): void {
        this.strategy.markAccountAsUnsubscribed();
    }

    isCookiePolicyAccepted$(): Observable<boolean> {
        return this.strategy.isCookiePolicyAccepted$();
    }

    markCookiePolicyAccepted(): void {
        this.strategy.markCookiePolicyAccepted();
    }

    get numProductsInBasket$(): Observable<number> {
        return this._strategy$.pipe(switchMap((strategy) => strategy.numProductsInBasket$));
    }

    addProductToBasket(request: AppSessionAddToBasketRequest): Observable<AppSessionBasketEntry> {
        return this.strategy.addProductToBasket(request);
    }

    removeProductFromBasket(request: AppSessionRemoveFromBasketRequest): Observable<void> {
        return this.strategy.removeProductFromBasket(request);
    }

    setProductBasket(request: AppSessionSetProductBasketRequest): Observable<AppSessionBasketEntry | void> {
        return this.strategy.setProductBasket(request);
    }

    clearProductBasket(): Observable<void> {
        return this.strategy.clearProductBasket();
    }

    getProductBasket(): Observable<AppSessionBasket> {
        return this.strategy.getProductBasket();
    }

    updateCounters(request: AppSessionUpdateCountersRequest): void {
        this.strategy.updateCounters(request);
    }

    setCatalogPageSize(request: AppSessionSetCatalogPageSize): Observable<void> {
        return this.strategy.setCatalogPageSize(request);
    }
}
