const orderWithPhoneCallRequest = `
Обратный звонок

Товар: {{ product.title }} ({{ product.productCategory.title }})
Код товара: {{ product.id }}
Партномер: {{ product.partNumber }}

URL: {{ url }}
`;

const orderWithProductRequest = `
Заявка на товар

Товар: {{ product.title }} ({{ product.productCategory.title }})
Код товара: {{ product.id }}
Партномер: {{ product.partNumber }}

URL: {{ url }}
`;


export const interidSiteWebCoreShareModelsdI18n = {
    __shared: {
        __models: {
            BooleanYesNo: {
                Yes: 'Да',
                No: 'Нет',
            },
        },
        product: {
            components: {
                product_card: {
                    reviews_0: 'отзывов',
                    reviews_1: 'отзыв',
                    reviews_2: 'отзыва',
                    addToBasket: 'Добавить в корзину',
                    inBasket: 'Добавлен в корзину ({{ amount }})',
                    addToBasketShort: 'В корзину',
                    orderWithPhoneCallRequest: 'Отправить запрос',
                    orderWithPhoneCallRequestMessage: orderWithPhoneCallRequest,
                    orderWithProductRequest: 'Отправить запрос',
                    orderWithProductRequestMessage: orderWithProductRequest,
                    inBasketShort: 'В корзине ({{ amount }})',
                    sendReviewSuccess: 'Благодарим Вас за оставленный отзыв! После модерации отзыв появится на сайте!',
                    sendQuestionSuccess: 'Благодарим Вас за оставленный вопрос! После модерации вопрос появится на сайте!',
                    compare: 'Сравнить',
                    productCode: 'Код товара',
                    inCompares: 'В сравнении',
                    favorite: 'В избранное',
                    inFavorites: 'В избранном',
                    tradePrice: 'Оптовая цена',
                    isAvailable: 'В наличии',
                    isAvailableOnDepot: 'В наличии',
                    isAvailableOnVendorDepot: 'В наличии на складе производителя',
                    isStaticAvailableVendorDepot: 'Зафиксировать наличие у производителя',
                    isShowedOnHomepage: 'Выводить на главную',
                    badge: {
                        analogBadge: {
                            title: 'Аналог',
                            alt: 'Аналог'
                        },
                        isNew: {
                            title: 'Новинка',
                            alt: 'Новый товар от производителя',
                        },
                        isHit: {
                            title: 'Хит',
                            alt: 'Товар часто покупают в последнее время',
                        },
                        isCustomerChoice: {
                            title: 'Выбор покупателей',
                            alt: 'Об этом товаре часто поступают хорошие отзывы от покупателей',
                        },
                        isPopular: {
                            title: 'Популярный',
                            alt: 'Этот товар часто заказывают на нашем сайте',
                        },
                        isOutOfProduction: {
                            title: 'Снято с производства',
                            alt: 'Этот товар снят с производства',
                        },
                        isAvailableDepot: {
                            title: 'В наличии',
                            alt: 'В наличии на складе',
                        },
                        isAvailableVendorDepot: {
                            title: 'В наличии',
                            alt: 'В наличии на складе производителя',
                        },
                    },
                    buy_button: {
                        inBasketNone: 'В корзину',
                        inBasketSingle: 'В корзине',
                        inBasketMulti: 'В корзине ({{ count }})',
                    },
                },
            },
        },
    },
};
