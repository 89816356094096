import { IsInt, IsObject, IsOptional, IsPositive, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { ProductSearchRequest, ProductSearchResponse } from '../endpoints/product.endpoint';

export enum PurchasedProductsWebEndpointPaths {
    search = '/api/v1/web/purchased-products/search',
}

export interface PurchasedProductsWebEndpoint {
    search(request: PurchasedProductsSearchWebRequest, req?: any): Promise<PurchasedProductsSearchWebResponse> | Observable<PurchasedProductsSearchWebResponse>;
}

export class PurchasedProductsSearchWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    esSearchRequest?: ProductSearchRequest;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;
}

export class PurchasedProductsSearchWebResponse {
    @ApiModelProperty({
        required: false,
    })
    esSearchRequest?: ProductSearchRequest;

    @ApiModelProperty({
        required: false,
    })
    esResponse?: ProductSearchResponse;

    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty()
    total: number;
}
