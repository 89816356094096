import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductSearchFiltersRequest, ProductSearchRequest, ProductSearchResponse } from '../endpoints/product.endpoint';
import { ProductCategoryDto, ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ProductDto, ProductESDto } from '../models/entities/product.dto';
import { ManufacturerListDto } from '../models/entities/manufacturer.dto';
import { ParamsShared } from '../models/shared/params.shared';
import { ProductCategoryLinkDto } from '../models/entities/product-category-link.dto';
import { ProductCategorySeoLinkDto } from '../models/entities/product-category-seo-link.dto';
import { ParamsReferenceDto } from '../models/entities/params-reference.dto';
import { CommonContentWebBundleShared } from '../models/shared/common-content.shared';
import { ProductReviewDto } from '../models/entities/product-review.dto';
import { BannerDto } from '../models/entities/banner.dto';

export enum CatalogWebEndpointPaths {
    category = '/api/v1/web/catalog/category',
    products = '/api/v1/web/catalog/products',
    count = '/api/v1/web/catalog/count',
}

export interface CatalogWebEndpoint {
    category(request: CatalogWebRequest, req?: any): Promise<CatalogWebResponse> | Observable<CatalogWebResponse>;
    products(request: CatalogProductsWebRequest, req?: any): Promise<CatalogProductsWebResponse> | Observable<CatalogProductsWebResponse>;
    count(request: CatalogCountWebRequest): Promise<CatalogCountWebResponse> | Observable<CatalogCountWebResponse>;
}

export class CatalogWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    categoryId?: number;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    filters?: ProductSearchFiltersRequest;
}

export class CatalogUsedParamsDTO {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sort: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ParamsShared.ParamsType),
    })
    type: ParamsShared.ParamsType;

    @ApiModelProperty()
    title: string;
}

export class CatalogUsedDictValuesDTO {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sort: number;

    @ApiModelProperty()
    paramId: number;

    @ApiModelProperty()
    title: string;
}

export class CatalogUsedProductSeriesDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    manufacturer: string;
}

export class CatalogUsedPriceRangeDTO { 
    @ApiModelProperty()
    priceRangeMin?: string;

    @ApiModelProperty()
    priceRangeMax?: string;
}
 
export class CategoryMinPriceDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    minPrice: string;
}


export class CatalogSeoLinkRouteResolveDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    uri: string;
}

export class CatalogWebResponse {
    @ApiModelProperty({
        required: false,
    })
    category?: ProductCategoryDto;


    @ApiModelProperty({
        type: BannerDto,
        isArray: true,
    })
    banners: Array<BannerDto>;

    @ApiModelProperty()
    categoryLevel: number;

    @ApiModelProperty({
        type: ProductCategoryListDto,
        isArray: true,
    })
    categories: Array<ProductCategoryListDto>;

    @ApiModelProperty({
        type: ProductCategoryLinkDto,
        isArray: true,
    })
    categoryLinks: Array<ProductCategoryLinkDto>;

    @ApiModelProperty({
        type: ProductCategorySeoLinkDto,
        isArray: true,
    })
    parentCategorySeoLinks: Array<ProductCategorySeoLinkDto>;

    @ApiModelProperty({
        type: ProductCategorySeoLinkDto,
        isArray: true,
    })
    categoryTopSeoLinks: Array<ProductCategorySeoLinkDto>;

    @ApiModelProperty({
        type: ProductCategorySeoLinkDto,
        isArray: true,
    })
    categoryBottomSeoLinks: Array<ProductCategorySeoLinkDto>;

    @ApiModelProperty({
        type: CatalogSeoLinkRouteResolveDto,
        isArray: true,
    })
    categorySeoLinksRouteResolves: Array<CatalogSeoLinkRouteResolveDto>;

    @ApiModelProperty({
        type: ParamsReferenceDto,
        isArray: true,
    })
    paramsReferences: Array<ParamsReferenceDto>;

    @ApiModelProperty({
        type: ManufacturerListDto,
        isArray: true,
    })
    usedManufacturers: Array<ManufacturerListDto>;

    @ApiModelProperty({
        type: CatalogUsedParamsDTO,
        isArray: true,
    })
    usedParams: Array<CatalogUsedParamsDTO>;

    @ApiModelProperty({
        type: CatalogUsedDictValuesDTO,
        isArray: true,
    })
    usedDictValues: Array<CatalogUsedDictValuesDTO>;

    @ApiModelProperty({
        type: CatalogUsedProductSeriesDto,
        isArray: true,
    })
    usedProductSeries: Array<CatalogUsedProductSeriesDto>;

    @ApiModelProperty({
        type: CatalogUsedPriceRangeDTO,
        isArray: true,
    })
    usedPriceRange: CatalogUsedPriceRangeDTO;

    @ApiModelProperty({
        type: CategoryMinPriceDto,
        isArray: true,
    })
    categoriesMinPrices: Array<CategoryMinPriceDto>;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    customerChoice: Array<ProductESDto>;

    @ApiModelProperty()
    contentBundle: CommonContentWebBundleShared.Bundle;

    @ApiModelProperty()
    shouldDisplayCustomerChoice: boolean;
}

export class CatalogProductsWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    url: string;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    categoryId: number;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    esSearchRequest?: ProductSearchRequest;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;
}

export class CatalogProductsWebResponse {
    @ApiModelProperty()
    categoryId: number;

    @ApiModelProperty({
        required: false,
    })
    esResponse?: ProductSearchResponse;

    @ApiModelProperty({
        required: false,
        type: ProductDto,
        isArray: true,
    })
    productsForEachSubCategory?: Array<ProductDto>;

    @ApiModelProperty({
        type: ProductReviewDto,
        isArray: true,
    })
    productReviews: Array<ProductReviewDto>;

    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;
}


export class CatalogCountWebRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    categoryId: number;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    esSearchRequest?: ProductSearchRequest;
}

export class CatalogCountWebResponse {
    @ApiModelProperty()
    count: number;
}
