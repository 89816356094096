import { IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { EmailSubscriptionDto } from '../models/entities/email-subscription.dto';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';

export enum EmailSubscriptionsEndpointPaths {
    subscribe = '/api/v1/email-subscriptions/subscribe',
    unsubscribe = '/api/v1/email-subscriptions/unsubscribe',
    list = '/api/v1/email-subscriptions/list',
}

export interface EmailSubscriptionsEndpoint {
    subscribe(request: EmailSubscriptionsSubscribeRequest, reqNestJs?: any): Promise<EmailSubscriptionsSubscribeResponse> | Observable<EmailSubscriptionsSubscribeResponse>;
    unsubscribe(request: EmailSubscriptionsUnsubscribeRequest): Promise<void> | Observable<void>;
    list(request: EmailSubscriptionsListRequest): Promise<EmailSubscriptionsListResponse> | Observable<EmailSubscriptionsListResponse>;
}

export class EmailSubscriptionsSubscribeRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export type EmailSubscriptionsSubscribeResponse = EmailSubscriptionDto;

export class EmailSubscriptionsUnsubscribeRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export class EmailSubscriptionsListRequest extends CrudListRequestDto<EmailSubscriptionDto> {
    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    onlyActiveSubscriptions?: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    onlyInactiveSubscriptions?: boolean;
}

export class EmailSubscriptionsListResponse extends CrudListResponseDto<EmailSubscriptionDto> {
    @ApiModelProperty({
        type: EmailSubscriptionDto,
        isArray: true,
    })
    items: Array<EmailSubscriptionDto>;
}
