import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryCostShared } from '../shared/delivery-cost.shared';

export class DeliveryCostDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  deliveryId: number;

  @ApiModelProperty()
  isEnabled: boolean;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  variant: DeliveryCostShared.CostVariant;
}
