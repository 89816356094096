import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiOnlineStatusSubject } from '../subjects/api-online-status.subject';

@Injectable({
    providedIn: 'root',
})
export class ApiOnlineStatusService {
    private _isUp$ = apiOnlineStatusSubject;

    get isUp$(): Observable<boolean> {
        return this._isUp$;
    }

    markApiAsOnline(): void {
        this._isUp$.next(true);
    }

    markApiAsOffline(): void {
        this._isUp$.next(false);
    }
}
