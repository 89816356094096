import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SortOrderShared } from '../shared/sort-order.shared';

export class ArticleAuthorDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  fullName: string;

  @ApiModelProperty()
  sortOrder: number;
}
