<app-shared-dialog-close (close)='close()'></app-shared-dialog-close>
<div class='c' [formGroup]='state().form'>
  <div class='c-title'><span translate>auth.shared.components.auth_forgot_password.title</span></div>
  <div class='c-email'>
    <app-shared-ui-input #email type='email' formControlName='email' leftIcon='email' placeholder="{{ t('fields.email') | translate }}" (keyup.enter)='ngSubmit()'></app-shared-ui-input>
  </div>
  <div class='c-submit'>
    <app-shared-ui-button (click)='ngSubmit()' [disabled]='state().form.disabled'>
      <span translate>auth.shared.components.auth_forgot_password.actions.submit</span></app-shared-ui-button>
  </div>
  <div class='c-sign-in'>
    <app-shared-ui-button (click)='signIn()' [disabled]='state().form.disabled' buttonStyle='plain'>
      <span translate>auth.shared.components.auth_forgot_password.actions.signIn</span></app-shared-ui-button>
  </div>
</div>
