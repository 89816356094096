import { IsArray, IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ArticleReviewDto } from '../entities/article-review.dto';
import { ArticleShared } from './article.shared';

export namespace ArticleReviewShared {
    export enum ReviewStatus {
        NotProcessed = 'NotProcessed',
        Approved = 'Approved',
        Rejected = 'Rejected',
    }
}

export namespace ArticleReviewSearchShared {
    export enum SortBy {
        ElasticScore = '_score',
        Id = 'id',
        CreatedAt = 'CreatedAt',
        ProcessedAt = 'ProcessedAt',
    }

    export enum SortDirection {
        Asc = 'asc',
        Desc = 'desc',
    }

    export enum Shape {
        WebSearch = 'WebSearch',
        WebArticle = 'WebArticle',
    }

    export class Filters {
        @IsPositive({ each: true })
        @IsInt({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        articleIds?: Array<number>;

        @IsPositive({ each: true })
        @IsInt({ each: true })
        @IsArray()
        @IsOptional()
        @ApiModelProperty()
        articleCategoryIds?: Array<number>;

        @ApiModelProperty()
        @IsEnum(ArticleShared.ArticleStatus, { each: true })
        @IsArray()
        @IsOptional()
        status?: Array<ArticleReviewShared.ReviewStatus>;
    }

    export class View {
        @IsPositive()
        @IsInt()
        @IsNotEmpty()
        @ApiModelProperty()
        limit: number;

        @IsInt()
        @IsNotEmpty()
        @ApiModelProperty()
        offset: number;
    }

    export class CountRequest {
        @IsString()
        @IsOptional()
        @ApiModelProperty()
        queryString?: string;
    }

    export class Request {
        @IsString()
        @IsOptional()
        @ApiModelProperty()
        queryString?: string;

        @ValidateNested()
        @IsObject()
        @IsOptional()
        @ApiModelProperty()
        filters?: Filters;

        @IsEnum(SortBy)
        @IsOptional()
        @ApiModelProperty({
            type: 'string',
            enum: Object.values(SortBy),
        })
        sort?: SortBy;

        @IsEnum(SortDirection)
        @IsOptional()
        @ApiModelProperty({
            type: 'string',
            enum: Object.values(SortDirection),
        })
        sortDirection?: SortDirection;

        @IsEnum(Shape)
        @IsOptional()
        @ApiModelProperty({
            type: 'string',
            enum: Object.values(Shape),
        })
        shape?: Shape;

        @ValidateNested()
        @IsObject()
        @IsOptional()
        @ApiModelProperty()
        view?: View;
    }

    export class Response {
        @IsInt()
        @IsNotEmpty()
        @ApiModelProperty()
        total: number;

        @ValidateNested({ each: true })
        @IsObject({ each: true })
        @IsArray()
        @IsNotEmpty()
        @ApiModelProperty()
        articleReviews: Array<ArticleReviewDto>;
    }

    export class Stats {
        articleCategories: Array<{
            id: number;
            count: number;
        }>;
    }
}
