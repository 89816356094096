import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';
import { PageMetadataSEO } from '../shared/common.shared';

export class SolutionCategoryDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  isEnabled: boolean;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  alt: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  image: AttachmentDto;

  @ApiModelProperty({
    type: () => PageMetadataSEO,
  })
  seo: PageMetadataSEO;
}
