import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export const ROISTAT_CONFIG_REGISTRY_KEY = 'roistat.config';

export enum RoistatPhoneCallRequestContext {
    Header = 'header',
    PageNotFound = 'page-not-found',
    ProductNotAvailable = 'product-not-available',
    ProductOneClickBuy = 'product-one-click-buy',
    ContactUs = 'contact-us',
    Solution = 'solution',
    Solutions = 'solutions',
}

export enum RoistatProductRequestContext {
    Header = 'header',
    PageNotFound = 'page-not-found',
    ProductNotAvailable = 'product-not-available',
    ProductOneClickBuy = 'product-one-click-buy',
    ContactUs = 'contact-us',
    Solution = 'solution',
    Solutions = 'solutions',
}

export enum RoistatQuotationRequestContext {
    Header = 'header',
    PageNotFound = 'page-not-found',
    ProductNotAvailable = 'product-not-available',
    ProductOneClickBuy = 'product-one-click-buy',
    ContactUs = 'contact-us',
    Solution = 'solution',
    Solutions = 'solutions',
}

export const roistatContextI18n = {
    order: 'Заказ',
};

export const roistatPhoneCallRequestContextI18n = {
    [RoistatPhoneCallRequestContext.Header]: 'Шапка сайта',
    [RoistatPhoneCallRequestContext.PageNotFound]: 'Страница 404',
    [RoistatPhoneCallRequestContext.ProductNotAvailable]: 'Товар недоступен на складах',
    [RoistatPhoneCallRequestContext.ProductOneClickBuy]: 'Покупка в один клик',
    [RoistatPhoneCallRequestContext.ContactUs]: 'Контакты',
    [RoistatPhoneCallRequestContext.Solution]: 'Услуги и решения (статья)',
    [RoistatPhoneCallRequestContext.Solutions]: 'Услуги и решения (основная страница)',
};

export const roistatProductRequestContextI18n = {
    [RoistatProductRequestContext.Header]: 'Шапка сайта',
    [RoistatProductRequestContext.PageNotFound]: 'Страница 404',
    [RoistatProductRequestContext.ProductNotAvailable]: 'Товар недоступен на складах',
    [RoistatProductRequestContext.ProductOneClickBuy]: 'Покупка в один клик',
    [RoistatProductRequestContext.ContactUs]: 'Контакты',
    [RoistatProductRequestContext.Solution]: 'Услуги и решения (статья)',
    [RoistatProductRequestContext.Solutions]: 'Услуги и решения (основная страница)',
};

export const roistatQuotationRequestContextI18n = {
    [RoistatQuotationRequestContext.Header]: 'Шапка сайта',
    [RoistatQuotationRequestContext.PageNotFound]: 'Страница 404',
    [RoistatQuotationRequestContext.ProductNotAvailable]: 'Товар недоступен на складах',
    [RoistatQuotationRequestContext.ProductOneClickBuy]: 'Покупка в один клик',
    [RoistatQuotationRequestContext.ContactUs]: 'Контакты',
    [RoistatQuotationRequestContext.Solution]: 'Услуги и решения (статья)',
    [RoistatQuotationRequestContext.Solutions]: 'Услуги и решения (основная страница)',
};

export class RoistaiLeadConfig {
    @ApiModelProperty({
        description: 'Включить Calltouch-интеграцию для этого блока',
    })
    enabled: boolean;

    @ApiModelProperty({
        description: 'Название сделки; Здесь могут быть использованы шаблонные переменные',
    })
    title: string;

    @ApiModelProperty({
        description: 'Способ создания сделки (необязательный параметр). Укажите то значение, которое затем должно отображаться в аналитике в группировке "Способ создания заявки"',
        required: false,
    })
    orderCreationMethod?: string;

    @ApiModelProperty({
        description: 'После того, как в Roistat создается заявка, Roistat инициирует обратный звонок на номер клиента, если значение параметра равно 1 и в Ловце лидов включен индикатор обратного звонка.',
    })
    isNeedCallback: boolean;

    @ApiModelProperty({
        description: 'Переопределяет номер, указанный в настройках обратного звонка',
        required: false,
    })
    callbackPhone?: string;

    @ApiModelProperty({
        description: "По умолчанию значение параметра sync равно 0 ( 'sync' => '0'). В этом случае порядок событий следующий: данные о заявке отправляются в Roistat → Roistat отправляет отчет об их получении → Roistat отправляет данные о заявке в CRM → номер созданной в CRM заявки отправляется в Roistat. Если заявку в CRM не удается создать, ее номер не передается, сообщение об ошибке не выводится.\n" + '\n' + "Если установить значение параметра sync равным 1 ( 'sync' => '1' ), порядок событий следующий: данные о заявке отправляются в Roistat → Roistat отправляет отчет об их получении → Roistat отправляет данные о заявке в CRM → далее возможны 2 варианта событий:\n" + '\n' + '> Если заявку в CRM не удается создать, выводится ошибка;\n' + '> Если заявка в CRM создана, ее номер отправляется в Roistat.',
    })
    sync: boolean;

    @ApiModelProperty({
        description: 'Включение проверки заявок на дубли',
    })
    isNeedCheckOrderInProcessing: boolean;

    @ApiModelProperty({
        description: 'Если создана дублирующая заявка, в нее будет добавлен комментарий об этом',
    })
    isNeedCheckOrderInProcessingAppend: boolean;

    @ApiModelProperty({
        description: 'Не отправлять заявку в CRM.',
    })
    isSkipSending: boolean;
}

export class RoistatPhoneCallRequestConfig {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(RoistatPhoneCallRequestContext),
    })
    context: RoistatPhoneCallRequestContext;

    @ApiModelProperty()
    lead: RoistaiLeadConfig;
}

export class RoistatProductRequestConfig {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(RoistatProductRequestContext),
    })
    context: RoistatProductRequestContext;

    @ApiModelProperty()
    lead: RoistaiLeadConfig;
}

export class RoistatQuotationRequestConfig {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(RoistatQuotationRequestContext),
    })
    context: RoistatQuotationRequestContext;

    @ApiModelProperty()
    lead: RoistaiLeadConfig;
}

export class RoistatConfig {
    @ApiModelProperty({
        description: 'Включить Calltouch-интеграцию для сайта',
    })
    enabled: boolean;

    @ApiModelProperty({
        description: 'API-ключ',
    })
    apiKey: string;

    @ApiModelProperty()
    order: RoistaiLeadConfig;

    @ApiModelProperty({
        type: RoistatPhoneCallRequestConfig,
        isArray: true,
    })
    phoneCallRequests: Array<RoistatPhoneCallRequestConfig>;

    @ApiModelProperty({
        type: RoistatProductRequestConfig,
        isArray: true,
    })
    productRequests: Array<RoistatProductRequestConfig>;

    @ApiModelProperty({
        type: RoistatQuotationRequestConfig,
        isArray: true,
    })
    quotationRequests: Array<RoistatQuotationRequestConfig>;
}

export function roistatEmptyPhoneCallRequestConfig(context: RoistatPhoneCallRequestContext): RoistatPhoneCallRequestConfig {
    return {
        context,
        lead: {
            enabled: false,
            title: 'Форма обратного звонка - {{ context }} - №{{ id }}',
            isNeedCallback: false,
            isNeedCheckOrderInProcessing: true,
            isNeedCheckOrderInProcessingAppend: true,
            sync: false,
            isSkipSending: false,
        },
    };
}

export function roistatEmptyProductRequestConfig(context: RoistatProductRequestContext): RoistatProductRequestConfig {
    return {
        context,
        lead: {
            enabled: false,
            title: 'Форма заявки на товар - {{ context }} - №{{ id }}',
            isNeedCallback: false,
            isNeedCheckOrderInProcessing: true,
            isNeedCheckOrderInProcessingAppend: true,
            sync: false,
            isSkipSending: false,
        },
    };
}

export function roistatEmptyQuotationRequestConfig(context: RoistatQuotationRequestContext): RoistatQuotationRequestConfig {
    return {
        context,
        lead: {
            enabled: false,
            title: 'Форма запроса на дешевле - {{ context }} - №{{ id }}',
            isNeedCallback: false,
            isNeedCheckOrderInProcessing: true,
            isNeedCheckOrderInProcessingAppend: true,
            sync: false,
            isSkipSending: false,
        },
    };
}

export function roistatEmptyOrderConfig(): RoistaiLeadConfig {
    return {
        enabled: false,
        title: 'Заказ - {{ context }} - №{{ id }}',
        isNeedCallback: false,
        isNeedCheckOrderInProcessing: true,
        isNeedCheckOrderInProcessingAppend: true,
        sync: true,
        isSkipSending: false,
    };
}

export function roistatEmptyConfig(): RoistatConfig {
    return {
        enabled: true,
        apiKey: '<Укажите API-ключ>',
        order: roistatEmptyOrderConfig(),
        phoneCallRequests: Object.values(RoistatPhoneCallRequestContext).map((context) => roistatEmptyPhoneCallRequestConfig(context)),
        productRequests: Object.values(RoistatProductRequestContext).map((context) => roistatEmptyProductRequestConfig(context)),
        quotationRequests: Object.values(RoistatQuotationRequestContext).map((context) => roistatEmptyQuotationRequestConfig(context)),
    };
}
