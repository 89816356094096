import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountAccessShared } from '../shared/account-access.shared';

export class AccountAccessTemplateDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  accessNodes: Array<AccountAccessShared.AccessNode>;
}
