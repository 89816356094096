<div class="c" #container>
  @if (response().productCategories.length > 0) {
    <div class="c-product-categories">
      <div class="c-header">
        <span>{{ t('categories') | translate }}</span>
      </div>
      <div class="c-contents">
        <div class="categories">
          @for (category of response().productCategories; track trackById($index, category)) {
            <a class="category" [routerLink]="productCategoryLink(category)" (click)="closeAndEmitOnNavigate()"
              ><span>{{ category.title }}</span></a
              >
            }
          </div>
        </div>
      </div>
    }
    @if (response().products.length > 0) {
      <div class="c-products">
        <div class="c-header">
          <span>{{ t('products') | translate }}</span>
        </div>
        <div class="c-contents">
          <div class="products">
            @for (product of response().products; track trackById($index, product)) {
              <a class="product" [routerLink]="productLink(product).route" (click)="closeAndEmitOnNavigate()" [title]="product.description.annotation">
                <div class="product-preview">
                  <div class="image-container">
                    <app-shared-attachment-image [attachment]="product.preview" objectFit="scale-down" [maxImageWidth]="520"></app-shared-attachment-image>
                  </div>
                </div>
                <div class="product-info">
                  <div class="product-title">
                    <span>{{ product.title }}</span>
                  </div>
                  <div class="product-price">
                    <span>{{ product.price | money }} ₽</span>
                  </div>
                </div>
              </a>
            }
          </div>
        </div>
      </div>
    }
    @if (response().articles.length > 0) {
      <div class="c-articles">
        <div class="c-header">
          <span>{{ t('articles') | translate }}</span>
        </div>
        <div class="c-contents">
          <div class="articles">
            @for (article of response().articles; track trackById($index, article)) {
              <div class="article" [routerLink]="articleLink(article).route" (click)="closeAndEmitOnNavigate()" [title]="article.alt">
                <span>{{ article.title }}</span>
              </div>
            }
          </div>
        </div>
      </div>
    }
    @if (response().manufacturers.length > 0) {
      <div class="c-brands">
        <div class="c-header">
          <span>{{ t('brands') | translate }}</span>
        </div>
        <div class="c-contents">
          <div class="brands">
            @for (brand of response().manufacturers; track trackById($index, brand)) {
              <a class="brand" [routerLink]="manufacturerLink(brand).route" (click)="closeAndEmitOnNavigate()">
                <div class="image-container">
                  <app-shared-attachment-image [attachment]="brand.image" objectFit="scale-down" [maxImageWidth]="520"></app-shared-attachment-image></div
                ></a>
              }
            </div>
          </div>
        </div>
      }
    </div>
