export enum SiteColors {
  Gray = '#eeeeee',
  GrayLight = '#f5f5f5',
  GrayDark = '#aaaaaa',
  Black = '#020202',
  White = '#ffffff',
  Primary = '#0049FF',
  PrimaryDark = '#031B4E',
  PrimaryLight = '#F3F6FE',
  Warn = '#E72A2A',
  WarnLight = '#f6acac',
  WarnDark = '#C71F1F',
  Warning = '#ffa000',
  WarningLight = '#fdd835',
  WarningDark = '#b53d00',
  Success = '#4caf50',
  SuccessLight = '#c8e6c9',
  SuccessDark = '#1b5e20',
  BlueGray = '#6A7286',
}

export enum SiteColorsCSSVars {
  Gray = '--color-gray',
  GrayLight = '--color-gray-light',
  GrayDark = '--color-gray-dark',
  Black = '--color-black',
  White = '--color-white',
  Primary = '--color-accent',
  PrimaryDark = '--color-primary-dark',
  PrimaryLight = '--color-primary-light',
  Warn = '--color-warn',
  WarnLight = '--color-warn-light',
  WarnDark = '--color-warn-dark',
  Warning = '--color-warning',
  WarningLight = '--color-warning-light',
  WarningDark = '--color-warning-dark',
  Success = '--color-success',
  SuccessLight = '--color-success-light',
  SuccessDark = '--color-success-dark',
  BlueGray = '--color-text-primary-dark',
}

export const allColors: Array<SiteColors> = Object.values(SiteColors);

export const defaultColor = SiteColors.Primary;
export const defaultContrastColor = SiteColors.PrimaryLight;

