import { Observable } from 'rxjs';
import { ParamsReferenceDto } from '../models/entities/params-reference.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum ParamsReferencesWebEndpointPaths {
    listParamsReferences = '/api/v1/web/params-references/list',
}

export interface ParamsReferencesWebEndpoint {
    listParamsReferences(): Promise<ParamsReferencesListResponse> | Observable<ParamsReferencesListResponse>;
}

export class ParamsReferencesListResponse {
    @ApiModelProperty({
        type: ParamsReferenceDto,
        isArray: true,
    })
    entities: Array<ParamsReferenceDto>;
}
