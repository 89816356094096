import { Injectable } from '@angular/core';
import { MessagesService } from './messages.service';

export enum SocialNetworks {
    CopyLink = 'CopyLink',
    WhatsApp = 'WhatsApp',
    Vk = 'vk',
    Facebook = 'facebook',
    Twitter = 'twitter',
}

interface OpenPopupRequest {
    url: string;
    width: number;
    height: number;
}

interface NavigateToLinkRequest {
    url: string;
}

@Injectable({
    providedIn: 'root',
})
export class ShareWithSocialNetworksService {
    constructor(
        private readonly messages: MessagesService,
    ) {
    }

    shareWith(network: SocialNetworks, url: string): void {
        switch (network) {
            default: {
                throw new Error(`Unknown social network "${network}"`);
            }

            case SocialNetworks.CopyLink: {
                if (navigator && navigator.clipboard) {
                    navigator.clipboard.writeText(url).then(() => {
                        this.messages.info({
                            translate: true,
                            message: `shared.services.ShareWithSocialNetworksService.linkIsCopied`,
                        });
                    });
                }

                break;
            }

            case SocialNetworks.WhatsApp: {
                this.navigateToLink({
                    url: `whatsapp://send?text=${encodeURIComponent(url)}`,
                });

                break;
            }

            case SocialNetworks.Vk: {
                this.openPopupOrMoveTo({
                    url: `https://vk.com/share.php?url=` + url,
                    width: 650,
                    height: 480,
                });

                break;
            }

            case SocialNetworks.Facebook: {
                this.openPopupOrMoveTo({
                    url: `https://www.facebook.com/sharer/sharer.php?u=` + url,
                    width: 650,
                    height: 480,
                });

                break;
            }

            case SocialNetworks.Twitter: {
                this.openPopupOrMoveTo({
                    url: `https://www.twitter.com/share?url=` + url,
                    width: 650,
                    height: 480,
                });

                break;
            }
        }
    }

    navigateToLink(request: NavigateToLinkRequest): void {
        if (location && location.href) {
            location.href = request.url;
        }
    }

    openPopupOrMoveTo(request: OpenPopupRequest): void {
        const goToURL = () => {
            if (location && location.href) {
                location.href = request.url;
            }
        };

        if (window && window.open) {
            const y = window.top.outerHeight / 2 + window.top.screenY - (request.height / 2);
            const x = window.top.outerWidth / 2 + window.top.screenX - (request.width / 2);

            const popup = window.open(
                request.url,
                'share',
                `toolbar=no,location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes,
        top=${y}, left=${x},
        width=${request.width}, height=${request.height}`,
            );

            setTimeout(() => {
                if (! popup || popup.closed || popup.closed === undefined) {
                    goToURL();
                }
            });
        } else {
            goToURL();
        }
    }
}
