import { ChangeDetectionStrategy, Component } from '@angular/core';
   
@Component({
    selector: 'app-shared-menu-for-customers',
    templateUrl: './menu-for-customers.component.html',
    styleUrls: ['./menu-for-customers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuForCustomersComponent {
 
    constructor() {}


}