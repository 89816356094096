import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { BillDto } from '../models/entities/bill.dto';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';
import { Observable } from 'rxjs';

export enum BillEndpointPaths {
    set = '/api/v1/bill/for-orders/set',
    create = '/api/v1/bill/for-orders/create',
    delete = '/api/v1/bill/for-orders/delete',
    list = '/api/v1/bill/for-orders/list',
}

export interface BillEndpoint {
    set(request: BillSetRequest): Promise<BillSetResponse> | Observable<BillSetResponse>;
    create(request: BillCreateRequest): Promise<BillCreateResponse> | Observable<BillCreateResponse>;
    delete(request: BillDeleteRequest): Promise<void> | Observable<void>;
    list(request: BillListRequest): Promise<BillListResponse> | Observable<BillListResponse>;
}

export class BillSetRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    currencyId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    orderId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    attachmentId: number;
}

export class BillSetResponse {
    @ApiModelProperty()
    bill: BillDto;
}

export class BillCreateRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    currencyId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    orderId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    attachmentId: number;
}

export class BillCreateResponse {
    @ApiModelProperty()
    bill: BillDto;
}

export class BillDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BillListRequest extends CrudListRequestDto<BillDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    orderId: number;
}

export class BillListResponse extends CrudListResponseDto<BillDto> {
    @ApiModelProperty({
        type: BillDto,
        isArray: true,
    })
    items: Array<BillDto>;
}
