import { ChangeDetectionStrategy, Component, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { defaultModalConfig } from '@interid/interid-site-web/core';
import { PhoneCallRequestModalComponent } from '../phone-call-request-modal/phone-call-request-modal.component';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsComponent {
    constructor(
        private readonly vcr: ViewContainerRef,
        private readonly matDialog: MatDialog,
    ) {
    }

    t(input: string): string {
        return `shared.components.contact_us.${input}`;
    }

    contactUs(): void {
        this.matDialog.open(PhoneCallRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            viewContainerRef: this.vcr,
        });
    }
}
