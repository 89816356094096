export namespace DeliveryMarketplaceShared
{
  export enum CostType {
    Free = 'Free',
    Paid = 'Paid',
  }

  export const allCostTypes = Object.values(CostType);

  export type Cost =
    { type: CostType.Free; payload: FreePayload } |
    { type: CostType.Paid; payload: PaidPayload }
  ;

  export interface FreePayload {
  }

  export interface PaidPayload {
    costs: string;
  }

  export class IndividualPayload {}

  export enum DaysType {
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    FixedDay = 'FixedDay',
    RangeDays = 'RangeDays',
    Order = 'Order',
  }

  export type Days =
    { type: DaysType.Today; payload: {} } |
    { type: DaysType.Tomorrow; payload: {} } |
    { type: DaysType.FixedDay; payload: FixedDayPayload  } |
    { type: DaysType.RangeDays; payload: RangeDaysPayload } |
    { type: DaysType.Order; payload: {} }
    ;

  export interface FixedDayPayload {
    day: number;
  }

  export interface RangeDaysPayload {
    dayFrom: number; dayTo: number;
  }

  export enum OrderBeforeType {
    None = 'None',
    Manual = 'Manual',
  }

  export type OrderBefore =
    { type: OrderBeforeType.None; payload: {} } |
    { type: OrderBeforeType.Manual; payload: OrderBeforePayload }
    ;

  export class OrderBeforePayload {
    hours: number;
  }
}
