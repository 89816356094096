import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { VendorCategoryWebRequest, VendorCategoryWebResponse, VendorProductsWebRequest, VendorProductsWebResponse, VendorWebEndpoint, VendorWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebVendorDataAccess implements VendorWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    category(request: VendorCategoryWebRequest): Observable<VendorCategoryWebResponse> {
        return this.http.post(VendorWebEndpointPaths.category, request);
    }

    products(request: VendorProductsWebRequest): Observable<VendorProductsWebResponse> {
        return this.http.post(VendorWebEndpointPaths.products, request);
    }
}
