import { ChangeDetectionStrategy, Component, EventEmitter, input, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ProductDto } from '@interid/interid-site-shared';
import { DEFAULT_PRODUCT_BADGES, ProductCardBadgesAComponentBadge } from '../../assets/product-card-badges-a/product-card-badges-a.component';
import { CompareTopbarVariant, ProductCardHelper, ProductCardVariant } from '../../helpers/product-card.helper';
import { PRODUCT_CARD_HELPER_PROVIDERS } from '../../helpers/product-card.providers';

@Component({
    selector: 'app-shared-product-card-g',
    templateUrl: './product-card-g.component.html',
    styleUrls: ['./product-card-g.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ...PRODUCT_CARD_HELPER_PROVIDERS,
    ],
})
export class ProductCardGComponent implements OnChanges, OnDestroy {
    public product = input<ProductDto>();
    
    public variant = input<ProductCardVariant>('default');
    public maxParams = input<number>();

    public withFavorite = input(false);
    public withParametersBadges = input(false);
    public withBadges = input(false);
    public withParams = input(false);

    public withRating = input(false);
    public withReviews = input(false);
    public withBasket = input(false);
    public withShare = input(false);
    public withCompare = input(false);
    public analogBage = input(false);
    public cheapestBage = input(false);
    public withBoldTitle = input(false);
    public withIsAvailableOnDepot = input(true);
    public compareTopbarVariant = input<CompareTopbarVariant>('none');

    public linkTarget = input('_self');
    public productBadges = input<Array<ProductCardBadgesAComponentBadge>>(DEFAULT_PRODUCT_BADGES);


    @Output('redirected') redirectEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public readonly helper: ProductCardHelper,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['product']) {
            this.helper.withProduct(this.product());
        }

        if (changes['maxParams'] && parseInt(this.maxParams() as any, 10) > 0) {
            this.helper.params.maxParams = parseInt(this.maxParams() as any, 10);
        }
    }

    click(){
        this.redirectEvent.next();
    }

    ngOnDestroy(): void {
        this.helper.ngOnDestroy();
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    get ngClass(): any {
        return {
            [`variant-${this.variant()}`]: true,
            ['variant-compare-topbar-simple']: this.compareTopbarVariant() === 'simple',
            ['variant-compare-topbar-default']: this.compareTopbarVariant() === 'default',
            'with-bold-title': this.withBoldTitle(),
        };
    }
}
