import { Injectable } from '@angular/core';
import { ProductESDto, ProductSaleShared } from '@interid/interid-site-shared';
import { ProductCardSource } from '../product-card.source';

@Injectable()
export class ProductCardPriceHelper {
    constructor(
        private readonly source: ProductCardSource,
    ) {
    }

    get product(): ProductESDto {
        return this.source.product;
    }

    get hasBadges(): boolean {
        return this.hasPercentsDiscount || this.hasSale || this.product?.flags?.isAvailableDepot || this.product?.flags?.isAvailableVendorDepot || this.product?.flags?.isHit || this.product?.flags?.isNew;
    }

    get hasPrice(): boolean {
        return !! this.product.price && parseFloat(this.product.price) > 0 && ! this.product.flags.isOutOfProduction;
    }

    get hasSale(): boolean {

        const dateFrom = !this.product?.sale?.dateFrom ? new Date(-8640000000000000) : new Date(this.product?.sale?.dateFrom);
        const dateTo = !this.product?.sale?.dateTo ? new Date(8640000000000000) : new Date(this.product?.sale?.dateTo);
        const isNow = dateFrom <= new Date() && new Date() <= dateTo;
 
        return !! this.product.sale && !! this.product.flags && this.product.flags.hasSale && isNow && !! this.product.sale.badgeForCard;
    }

    get hasDiscount(): boolean {
        return this.hasPercentsDiscount || this.hasFixedDiscount;
    }

    get hasTradePrice(): boolean {
        return !! this.product.tradePrice && parseFloat(this.product.tradePrice) > 0;
    }

    get tradePriceRub(): string {
        return (parseFloat(this.product.tradePrice) * this.product.basePriceCurrency.exchangeRate).toFixed(2);
    }

    get hasPercentsDiscount(): boolean {
        if (this.product.displayDiscountPercents) {
            return true;
        } else if (this.hasSale) {
            return [
                ProductSaleShared.ProductSaleStrategy.DiscountPercents,
            ].includes(this.product.sale.strategy.type);
        } else {
            return false;
        }
    }

    get hasFixedDiscount(): boolean {
        if (this.product.displayDiscountFrom) {
            return true;
        } else if (this.hasSale) {
            return [
                ProductSaleShared.ProductSaleStrategy.DiscountFixed,
            ].includes(this.product.sale.strategy.type);
        } else {
            return false;
        }
    }

    get percentsDiscount(): string {
        if (this.product.displayDiscountPercents) {
            return this.product.displayDiscountPercents;
        } else if (this.hasSale) {
            const strategy = this.product.sale.strategy;

            switch (strategy.type) {
                default: {
                    return '';
                }

                case ProductSaleShared.ProductSaleStrategy.DiscountPercents: {
                    return strategy.payload.discountPercents.toString();
                }
            }
        } else {
            return '';
        }
    }

    get fixedDiscount(): string {
        if (this.product.displayDiscountFrom) {
            return this.product.displayDiscountFrom;
        } else if (this.hasSale) {
            const strategy = this.product.sale.strategy;

            switch (strategy.type) {
                default: {
                    return '';
                }

                case ProductSaleShared.ProductSaleStrategy.DiscountFixed: {
                    return strategy.payload.discountFixed.toFixed(0);
                }
            }
        } else {
            return '';
        }
    }
}
