import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductParamsDto } from '@interid/interid-site-shared';
import { ProductCardHelper } from '../../helpers/product-card.helper';

export enum Viewmode {
    List = 'list',
    Card = 'card'
}

@Component({
    selector: 'app-shared-product-card-assets-params-a',
    templateUrl: './product-card-params-a.component.html',
    styleUrls: ['./product-card-params-a.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardParamsAComponent {
    @Input() viewmode: Viewmode = Viewmode.Card;
    @Input() product: any;

    constructor(
        public readonly helper: ProductCardHelper,
    ) {}

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
    trackByProductParamsId(index: number, productParams: ProductParamsDto): any {
        return productParams.id;
    }
}
