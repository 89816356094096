import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductReviewShared } from '../shared/product-review.shared';

export class ProductReviewLikeDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  type: ProductReviewShared.LikeType;

  @ApiModelProperty()
  authorAccountId: number;

  @ApiModelProperty()
  productReviewId: number;
}