export namespace AccountAccessShared {
    export enum AccessNode {
        SystemRoot = 'SystemRoot',
        AccountsAdmin = 'AccountsAdmin',
        AccountsAdminCreate = 'AccountsAdminCreate',
        AccountsAdminRevoke = 'AccountsAdminRevoke',
        AccountsAdminSetAccess = 'AccountsAdminSetAccess',
        AccountsAdminUpdateProfile = 'AccountsAdminUpdateProfile',
        AccountsAdminDelete = 'AccountsAdminDelete',
        AccountsAdminActivate = 'AccountsAdminActivate',
        AccountsAdminBonuses = 'AccountsAdminBonuses',
        Registry = 'Registry',
        RegistryEdit = 'RegistryEdit',
        Redirects = 'Redirects',
        RedirectsCreate = 'RedirectsCreate',
        RedirectsEdit = 'RedirectsEdit',
        RedirectsDelete = 'RedirectsDelete',
        PageMetadata = 'PageMetadata',
        PageMetadataCreate = 'PageMetadataCreate',
        PageMetadataEdit = 'PageMetadataEdit',
        PageMetadataDelete = 'PageMetadataDelete',
        Index = 'Index',
        Cities = 'Cities',
        CitiesCreate = 'CitiesCreate',
        CitiesEdit = 'CitiesEdit',
        CitiesDelete = 'CitiesDelete',
        Currencies = 'Currencies',
        CurrenciesCreate = 'CurrenciesCreate',
        CurrenciesEdit = 'CurrenciesEdit',
        CurrenciesDelete = 'CurrenciesDelete',
        CurrenciesSyncWithCBR = 'CurrenciesSyncWithCBR',
        DeliveryPeriods = 'DeliveryPeriods',
        DeliveryPeriodsCreate = 'DeliveryPeriodsCreate',
        DeliveryPeriodsEdit = 'DeliveryPeriodsEdit',
        DeliveryPeriodsDelete = 'DeliveryPeriodsDelete',
        Tags = 'Tags',
        TagsCreate = 'TagsCreate',
        TagsEdit = 'TagsEdit',
        TagsDelete = 'TagsDelete',
        Manufacturers = 'Manufacturers',
        ManufacturersCreate = 'ManufacturersCreate',
        ManufacturersEdit = 'ManufacturersEdit',
        ManufacturersDelete = 'ManufacturersDelete',
        Dictionaries = 'Dictionaries',
        DictionariesCreate = 'DictionariesCreate',
        DictionariesDelete = 'DictionariesDelete',
        DictionariesEdit = 'DictionariesEdit',
        Params = 'Params',
        ParamsCreate = 'ParamsCreate',
        ParamsEdit = 'ParamsEdit',
        ParamsDelete = 'ParamsDelete',
        ParamsCategories = 'ParamsCategories',
        ParamsCategoriesCreate = 'ParamsCategoriesCreate',
        ParamsCategoriesEdit = 'ParamsCategoriesEdit',
        ParamsCategoriesDelete = 'ParamsCategoriesDelete',
        ProductCategories = 'ProductCategories',
        ProductCategoriesCreate = 'ProductCategoriesCreate',
        ProductCategoriesEdit = 'ProductCategoriesEdit',
        ProductCategoriesDelete = 'ProductCategoriesDelete',
        Product = 'Product',
        ProductUpdateSimilar = 'ProductUpdateSimilar',
        ProductCreate = 'ProductCreate',
        ProductEdit = 'ProductEdit',
        ProductEditTabCommon = 'ProductEditTabCommon',
        ProductEditTabDescription = 'ProductEditTabDescription',
        ProductEditTabParams = 'ProductEditTabParams',
        ProductEditTabSale = 'ProductEditTabSale',
        ProductEditTabGallery = 'ProductEditTabGallery',
        ProductEditTabVideos = 'ProductEditTabVideos',
        ProductEditTabFiles = 'ProductEditTabFiles',
        ProductEditTabPrices = 'ProductEditTabPrices',
        ProductEditTabFlags = 'ProductEditTabFlags',
        ProductEditTabSeo = 'ProductEditTabSeo',
        ProductEditTabRelated = 'ProductEditTabRelated',
        ProductDelete = 'ProductDelete',
        ProductArchive = 'ProductArchive',
        ProductPublish = 'ProductPublish',
        ProductImport1C = 'ProductImport1C',
        ProductImportExcel = 'ProductImportExcel',
        ProductChangeMinimalPrice = 'ProductChangeMinimalPrice',
        ProductExportToMarketplaces = 'ProductExportToMarketplaces',
        ProductPrice = 'ProductPrice',
        ProductSeries = 'ProductSeries',
        ProductSeriesEdit = 'ProductSeriesEdit',
        ProductSeriesDelete = 'ProductSeriesDelete',
        ProductSeriesCreate = 'ProductSeriesCreate',
        Banners = 'Banners',
        BannersCreate = 'BannersCreate',
        BannersEdit = 'BannersEdit',
        BannersDelete = 'BannersDelete',
        Articles = 'Articles',
        ArticlesCreate = 'ArticlesCreate',
        ArticlesEdit = 'ArticlesEdit',
        ArticlesDelete = 'ArticlesDelete',
        ArticlesPublish = 'ArticlesPublish',
        ArticleCategories = 'ArticleCategories',
        ArticleCategoriesCreate = 'ArticleCategoriesCreate',
        ArticleCategoriesEdit = 'ArticleCategoriesEdit',
        ArticleCategoriesDelete = 'ArticleCategoriesDelete',
        ArticleAuthors = 'ArticleAuthors',
        ArticleAuthorsCreate = 'ArticleAuthorsCreate',
        ArticleAuthorsEdit = 'ArticleAuthorsEdit',
        ArticleAuthorsDelete = 'ArticleAuthorsDelete',
        FrontpageManufacturers = 'FrontpageManufacturers',
        FrontpageManufacturersEdit = 'FrontpageManufacturersEdit',
        FrontpageManufacturersCreate = 'FrontpageManufacturersCreate',
        FrontpageManufacturersDelete = 'FrontpageManufacturersDelete',
        FrontpageProductCategories = 'FrontpageProductCategories',
        FrontpageProductCategoriesEdit = 'FrontpageProductCategoriesEdit',
        FrontpageProductCategoriesCreate = 'FrontpageProductCategoriesCreate',
        FrontpageProductCategoriesDelete = 'FrontpageProductCategoriesDelete',
        QuotationRequest = 'QuotationRequest',
        QuotationRequestSetStatus = 'QuotationRequestSetStatus',
        QuotationRequestAssign = 'QuotationRequestAssign',
        QuotationRequestDelete = 'QuotationRequestDelete',
        PhoneCallRequest = 'PhoneCallRequest',
        PhoneCallRequestSetStatus = 'PhoneCallRequestSetStatus',
        PhoneCallRequestAssign = 'PhoneCallRequestAssign',
        PhoneCallRequestDelete = 'PhoneCallRequestDelete', 
        ProductRequest = 'ProductRequest',
        ProductRequestSetStatus = 'ProductRequestSetStatus',
        ProductRequestAssign = 'ProductRequestAssign',
        ProductRequestDelete = 'ProductRequestDelete', 
        EmailSubscription = 'EmailSubscription',
        ProductCategorySeoLink = 'ProductCategorySeoLink',
        ProductCategorySeoLinkCreate = 'ProductCategorySeoLinkCreate',
        ProductCategorySeoLinkEdit = 'ProductCategorySeoLinkEdit',
        ProductCategorySeoLinkDelete = 'ProductCategorySeoLinkDelete',
        ImportantEvents = 'ImportantEvents',
        AccountAccessTemplate = 'AccountAccessTemplate',
        AccountAccessTemplateCreate = 'AccountAccessTemplateCreate',
        AccountAccessTemplateEdit = 'AccountAccessTemplateEdit',
        AccountAccessTemplateDelete = 'AccountAccessTemplateDelete',
        ProductReview = 'ProductReview',
        ProductReviewStatus = 'ProductReviewStatus',
        ProductReviewDelete = 'ProductReviewDelete',
        ProductReviewReply = 'ProductReviewReply',
        ProductQuestion = 'ProductQuestion',
        ProductQuestionStatus = 'ProductQuestionStatus',
        ProductQuestionDelete = 'ProductQuestionDelete',
        ProductQuestionReply = 'ProductQuestionReply',
        ArticleReview = 'ArticleReview',
        ArticleReviewStatus = 'ArticleReviewStatus',
        ArticleReviewDelete = 'ArticleReviewDelete',
        ArticleReviewReply = 'ArticleReviewReply',
        Order = 'Order',
        OrderCreate = 'OrderCreate',
        OrderEdit = 'OrderEdit',
        OrderSetStatus = 'OrderSetStatus',
        OrderAssign = 'OrderAssign',
        OrderDelete = 'OrderDelete',
        OrderBill = 'OrderBill',
        Delivery = 'Delivery',
        DeliveryCreate = 'DeliveryCreate',
        DeliveryEdit = 'DeliveryEdit',
        DeliveryDelete = 'DeliveryDelete',
        Homepage = 'Homepage',
        Vacancies = 'Vacancies',
        VacanciesCreate = 'VacanciesCreate',
        VacanciesEdit = 'VacanciesEdit',
        VacanciesDelete = 'VacanciesDelete',
        ProductCategoryLink = 'ProductCategoryLink',
        ProductCategoryLinkCreate = 'ProductCategoryLinkCreate',
        ProductCategoryLinkEdit = 'ProductCategoryLinkEdit',
        ProductCategoryLinkDelete = 'ProductCategoryLinkDelete',
        SolutionCategory = 'SolutionCategory',
        SolutionCategoryCreate = 'SolutionCategoryCreate',
        SolutionCategoryEdit = 'SolutionCategoryEdit',
        SolutionCategoryDelete = 'SolutionCategoryDelete',
        Solution = 'Solution',
        SolutionCreate = 'SolutionCreate',
        SolutionEdit = 'SolutionEdit',
        SolutionPublish = 'SolutionPublish',
        SolutionDelete = 'SolutionDelete',
        SystemTasks = 'SystemTasks',
        ParamsReference = 'ParamsReference',
        ParamsReferenceCreate = 'ParamsReferenceCreate',
        ParamsReferenceEdit = 'ParamsReferenceEdit',
        ParamsReferenceDelete = 'ParamsReferenceDelete',
        Pages = 'Pages',
        DeliveryCosts = 'DeliveryCosts',
        DeliveryCostsCreate = 'DeliveryCostsCreate',
        DeliveryCostsEdit = 'DeliveryCostsEdit',
        DeliveryCostsDelete = 'DeliveryCostsDelete',
        DeliveryMarketplaces = 'DeliveryMarketplaces',
        DeliveryMarketplacesCreate = 'DeliveryMarketplacesCreate',
        DeliveryMarketplacesEdit = 'DeliveryMarketplacesEdit',
        DeliveryMarketplacesDelete = 'DeliveryMarketplacesDelete',
        ProductTransaction = 'ProductTransaction',
        ProductTransactionCreate = 'ProductTransactionCreate',
        ProductTransactionEdit = 'ProductTransactionEdit',
        ProductTransactionDelete = 'ProductTransactionDelete',
        ProductTransactionCommit = 'ProductTransactionCommit',
        ProductTransactionRollback = 'ProductTransactionRollback',
        RobotsTXT = 'RobotsTXT',
        SiteMap = 'SiteMap',
        HttpRedirect = 'HttpRedirect',
        Roistat = 'Roistat',
        DevFeedback = 'DevFeedback',
    }
}
