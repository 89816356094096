import { IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { ProductSearchRequest, ProductSearchResponse } from '../endpoints/product.endpoint';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ManufacturerDto } from '../models/entities/manufacturer.dto';

export enum VendorWebEndpointPaths {
    category = '/api/v1/web/vendor/category',
    products = '/api/v1/web/vendor/products',
}

export interface VendorWebEndpoint {
    category(request: VendorCategoryWebRequest): Promise<VendorCategoryWebResponse> | Observable<VendorCategoryWebResponse>;
    products(request: VendorProductsWebRequest, ...args: any): Promise<VendorProductsWebResponse> | Observable<VendorProductsWebResponse>;
}

export class VendorCategoryWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    vendorUrl: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    categoryUrl?: string;
}

export class VendorCategoryWebResponse {
    @ApiModelProperty({
        required: false,
    })
    category?: ProductCategoryListDto;

    @ApiModelProperty({
        type: ProductCategoryListDto,
        isArray: true,
    })
    categories: Array<ProductCategoryListDto>;

    @ApiModelProperty()
    manufacturer: ManufacturerDto;
}

export class VendorProductsWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    vendorUrl: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    categoryUrl?: string;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    esSearchRequest?: ProductSearchRequest;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    esRequest?: ProductSearchRequest;
}

export class VendorProductsWebResponse {
    @ApiModelProperty({
        required: false,
    })
    esResponse?: ProductSearchResponse;

    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;
}
