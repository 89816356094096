import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { CreateProductQuestionRequest, CreateProductReviewRequest, ProductGetProductResponse, ProductGetResponse, ProductGetWebRequest, ProductHitWebRequest, ProductQuestionDto, ProductReviewDto, ProductSearchRequest, ProductSearchResponse, ProductWebEndpoint, ProductWebEndpointPaths } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebProductDataAccess implements ProductWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    get(request: ProductGetWebRequest): Observable<ProductGetResponse> {
        return this.http.post(ProductWebEndpointPaths.get, request);
    }

    getProduct(request: ProductGetWebRequest): Observable<ProductGetProductResponse> {
        return this.http.post(ProductWebEndpointPaths.getProduct, request);
    }

    hit(request: ProductHitWebRequest): Observable<void> {
        return this.http.post(ProductWebEndpointPaths.hit, request);
    }

    review(request: CreateProductReviewRequest): Observable<ProductReviewDto> {
        return this.http.post(ProductWebEndpointPaths.review, request);
    }

    question(request: CreateProductQuestionRequest): Observable<ProductQuestionDto> {
        return this.http.post(ProductWebEndpointPaths.question, request);
    }

    search(request: ProductSearchRequest): Observable<ProductSearchResponse> {
        return this.http.post(ProductWebEndpointPaths.search, request);
    }
}
