import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CurrencyShared } from '../shared/currency.shared';

export class CurrencyDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  code: string;

  @ApiModelProperty()
  symbol: string;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  syncCBR?: string;

  @ApiModelProperty()
  exchangeRate: number;

  @ApiModelProperty()
  isForeign: boolean;

  @ApiModelProperty()
  forManufacturerId?: number;

  @ApiModelProperty()
  enableYandexMarket: boolean;

  @ApiModelProperty()
  yandexMarketSettings?: CurrencyShared.YandexMarketSettings;
}

export class CurrencyCBRDto {
  @ApiModelProperty()
  ID: string;

  @ApiModelProperty()
  NumCode: string;

  @ApiModelProperty()
  CharCode: string;

  @ApiModelProperty()
  Nominal: number;

  @ApiModelProperty()
  Name: string;

  @ApiModelProperty()
  Value: number;

  @ApiModelProperty()
  Previous: number;
}
