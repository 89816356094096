@if (data().length) {
  <div class="autocomplete-items-wrapper">
    <div class="autocomplete-items">
      @for (item of data(); track item; let i = $index) {
        <div class="autocomplele-item" (click)="onClick(item)" [id]="i + 'item'">
          {{ item.value }}
          @if (type === 'party') {
            <br />
            <span>{{ item.data()?.inn }} {{ config.partyAddress === 'full' ? item.data()?.address?.value : item.data()?.address?.data()?.city }}</span>
          }
        </div>
      }
    </div>
  </div>
}