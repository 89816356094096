import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, input, Input, Output, signal } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl } from '@angular/forms';

type LabelColor = 'default' | 'blue-gray-dark';

interface FormValue {
    enabled: boolean;
}

interface State {
    form: UntypedFormGroup;
}

@Component({
    selector: 'app-shared-ui-checkbox',
    templateUrl: './ui-checkbox.component.html',
    styleUrls: ['./ui-checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UICheckboxComponent implements ControlValueAccessor {
    public label = input<string>();
    public labelColor = input<LabelColor>('default');

    @Output('checkbox-change') changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    private onChange: Function;
    private onTouched: Function;

    public state = signal<State>({
        form: this.fb.group({
            enabled: [undefined],
        }),
    });

    get ngClass(): any {
        const ngClasses = [
            `label-color-${this.labelColor()}`,
        ];

        if (this.formValue.enabled) {
            ngClasses.push('is-active');
        }

        return ngClasses;
    }

    get formValue(): FormValue {
        return this.state().form.value;
    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state().form.patchValue(formValue);
    }

    constructor(
        public ngControl: NgControl,
        private readonly fb: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
    ) {
        ngControl.valueAccessor = this;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled
            ? this.state().form.disable()
            : this.state().form.enable();
    }

    writeValue(obj: any): void {
        this.patchFormValue = {
            enabled: obj === null || obj === undefined
                ? undefined
                : !! obj,
        };

        this.cdr.markForCheck();
    }

    toggle(): void {
        this.onTouched();

        this.patchFormValue = {
            enabled: ! this.formValue.enabled,
        };

        this.onChange(this.formValue.enabled);
        this.changeEvent.emit(this.formValue.enabled);
    }
}
