export const interidSiteWebCoreSharedI18n = {
    shared: {
        pipes: {
            ruDateFrom: {
                format: 'от {{ day }} {{ month }} {{ year }}',
                month: {
                    1: 'января',
                    2: 'февраля',
                    3: 'марта',
                    4: 'апреля',
                    5: 'мая',
                    6: 'июня',
                    7: 'июля',
                    8: 'августа',
                    9: 'сентября',
                    10: 'октября',
                    11: 'ноября',
                    12: 'декабря',
                },
            },
        },
        components: {
            dialog_close: {
                hint: 'Закрыть',
            },
            purchase_returns: {
                email: 'Введите ваш e-mail',
                phone: 'Введите ваш номер телефона',
            },
            email_subscribe_a: {
                title: 'Узнавайте о скидках и спец. предложениях первыми',
                agree: 'Нажимая кнопку "Подписаться", я подтверждаю согласие на',
                agreeLinkText: 'обработку персональных данных',
                submit: 'Подписаться',
                email: 'Введите ваш e-mail',
                subscribed: 'Вы подписаны на рассылку. Предложения об акциях и спец. предложениях будут приходить к вам на почту',
            },
            email_subscribe: {
                title: 'Подпишись на обновления',
                title_v2: 'Подпишись на обновления и узнавай о скидках первым',
                description: 'Узнавай о скидках и спец. предложениях первым',
                agree: 'Нажимая кнопку "Подписаться", я подтверждаю согласие на обработку персональных данных',
                submit: 'Подписаться',
                email: 'Введите ваш e-mail',
                subscribed: 'Вы подписаны на рассылку. Предложения об акциях и спец. предложениях будут приходить к вам на почту',
            },
            phone_call_request: {
                title: 'Мы можем вам перезвонить',
                phone: 'Ваш телефон',
                email: 'Ваш email',
                submit: 'Перезвоните мне',
                privacy: 'Нажимая кнопку "Отправить", я подтверждаю согласие на обработку персональных данных',
                success: 'Ваш запрос отправлен, ждите звонка от менеджера',
            },
            phone_call_request_v2: {
                title: 'Отправьте заявку сейчас, и мы быстро свяжемся с вами',
                fields: {
                    message: 'Информация о заказе',
                    name: 'ФИО / Компания / ИП',
                    email: 'E-mail',
                    phone: 'Телефон',
                },
                submit: 'Отправить',
                privacy: 'Согласен с условиями обработки персональных данных в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных»',
                success: 'Ваш запрос отправлен, ждите звонка от менеджера',
            },
            phone_call_request_modal: {
                form: {
                    title: 'Обратный звонок',
                    name: 'Ваше имя',
                    phone: 'Ваш телефон',
                    email: 'Ваш email',
                    submit: 'Отправить заявку',
                    agree: 'Нажимая кнопку "Отправить", я подтверждаю согласие на обработку персональных данных',
                },
                success: {
                    thanks: 'Спасибо!',
                    sent: 'Заявка отправлена',
                    response: 'В ближайшее время с вами свяжется наш менеджер',
                },
            },
            product_request_modal: {
                form: {
                    title: 'Отправить запрос',
                    name: 'Ваше имя',
                    phone: 'Ваш телефон',
                    message: 'Комментарий',
                    email: 'Ваш email',
                    submit: 'Отправить заявку',
                    agree: 'Нажимая кнопку "Отправить", я подтверждаю согласие на обработку персональных данных',
                },
                success: {
                    thanks: 'Спасибо!',
                    sent: 'Заявка отправлена',
                    response: 'В ближайшее время с вами свяжется наш менеджер',
                },
            },
            quotation_request_modal: {
                form: {
                    title: 'Нашли дешевле? Мы подберём вам более выгодное решение',
                    title2: 'Запросить коммерческое предложение',
                    name: 'Ваше имя',
                    phone: 'Ваш телефон',
                    submit: 'Отправить заявку',
                    agree: 'Нажимая кнопку "Отправить", я подтверждаю согласие на обработку персональных данных',
                    email: 'Ваш email',
                    inn: 'ИНН (опционально)',
                    message: 'Комментарий'
                },
                success: {
                    thanks: 'Спасибо!',
                    sent: 'Заявка отправлена',
                    response: 'В ближайшее время с вами свяжется наш менеджер',
                },
            },
            dev_feedback_modal: {
                photos: 'Ваши фотографии товара',
                photosTitle: 'Перетащите файл или',
                photosTitle2: 'выберите на устройстве',
                photosHint: 'до 10 изображений в формате PNG, JPG',
                tooManyFiles: 'Вы можете загрузить не более 10 файлов',
                failedToUpload: 'Не удалось загрузить файлы',
                form: {
                    title: 'Нашли неточность в описании?',
                    formTitle: 'Тема',
                    description: 'Описание',
                    submit: 'Отправить',
                },
                success: {
                    thanks: 'Спасибо!',
                    sent: 'Запрос отправлен',
                },
            },
            product_analogs_modal: {
                title: 'Аналоги',
            },
            contact_us: {
                title: 'Не нашли то, что искали? Мы можем помочь с выбором и поиском',
                button: 'Связаться с менеджером',
            },
            paginator: {
                close: 'Закрыть',
            },
            ui_errors: {
                required: 'Поле обязательно для заполнения',
                email: 'Поле должно содержать адрес электронной почты',
            },
            ui_select: {
                ui_select_options: {
                    submit: 'Готово',
                },
            },
            ui_load_more_button: {
                title: 'Показать еще',
            },
        },
        services: {
            ShareWithSocialNetworksService: {
                linkIsCopied: 'Ссылка скопирована',
            },
        },
    },
};
