import { Injectable } from '@angular/core';
import { AppSessionAccountProfile, AppSessionAccountSettings, AppSessionAddToBasketRequest, AppSessionAddToComparesRequest, AppSessionAddToFavoritesRequest, AppSessionBasket, AppSessionBasketEntry, AppSessionListFavoritesRequest, AppSessionListFavoritesResponse, AppSessionRemoveComparesRequest, AppSessionRemoveFavoritesRequest, AppSessionRemoveFromBasketRequest, AppSessionSetAccountProfile, AppSessionSetCityRequest, AppSessionStrategy, AppSessionUpdateAccountSettings, AppSessionSetProductBasketRequest, AppSessionListProductsToCompareResponse, AppSessionUpdateCountersRequest, AppSessionSetCatalogPageSize, AppSessionAddToViewedRequest, AppSessionListViewedResponse, AppSessionListViewedRequest } from '../interfaces/app-session-strategy.interface';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, retryWhen, takeUntil, tap, switchMap } from 'rxjs/operators';
import { AccountSettingsShared } from '@interid/interid-site-shared';
import { ApiErrorHandlerService, AppBootstrapDataService, AppBusEvent, AppBusService, DocumentCookieService, genericRetryStrategy } from '@interid/interid-site-web/core';
import { InteridWebAccountDataAccess, InteridWebFavoriteDataAccess, InteridWebMindboxDataAccess, InteridWebProductBasketDataAccess, InteridWebProductCompareDataAccess, InteridWebViewedDataAccess } from '@interid/interid-site-data-access/web';
import { ReCaptchaV3Service } from 'ng-recaptcha';

interface State {
    numFavorites: number;
    numViewed: number;
    numCompares: number;
    numProductsInBasket: number;
    catalogPageSize: number;
}

function initialState(): State {
    return {
        numFavorites: undefined,
        numCompares: undefined,
        numViewed: undefined,
        numProductsInBasket: undefined,
        catalogPageSize: AccountSettingsShared.defaultCatalogPageSize,
    };
}

@Injectable({
    providedIn: 'root',
})
export class ApiEndpointAppSessionStrategy implements AppSessionStrategy {
    private _destroy$: Subject<void> = new Subject<void>();

    private _accountState$: BehaviorSubject<State> = new BehaviorSubject<State>(initialState());
    private _accountSettings$: BehaviorSubject<AccountSettingsShared.AccountSettingsDto> = new BehaviorSubject<AppSessionAccountSettings>(AccountSettingsShared.defaultAccountSettings());

    constructor(
        private readonly mindboxDataAccess: InteridWebMindboxDataAccess,
        private readonly documentCookieService: DocumentCookieService,
        private readonly appBus: AppBusService,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly appBootstrap: AppBootstrapDataService,
        private readonly accountWebEndpoint: InteridWebAccountDataAccess,
        private readonly favoriteWebEndpoint: InteridWebFavoriteDataAccess,
        private readonly viewedWebEndpoint: InteridWebViewedDataAccess,
        private readonly compareWebEndpoint: InteridWebProductCompareDataAccess,
        private readonly basketWebEndpoint: InteridWebProductBasketDataAccess,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
    ) {}

    init(): void {
        this.appBootstrap.data$.pipe(filter((next) => !!next)).subscribe((next) => {
            this.accountState = {
                ...this.accountState,
                numFavorites: next.numFavorites,
                numCompares: next.numCompares,
                numProductsInBasket: next.numBasket,
            };
        });

        this.appBus.events$.pipe(takeUntil(this._destroy$)).subscribe((event) => {
            switch (event.type) {
                case AppBusEvent.DocumentVisible: {
                    this.appBootstrap.refresh().pipe(takeUntil(this._destroy$)).subscribe();

                    break;
                }
            }
        });

        this.appBus.events$.pipe(takeUntil(this._destroy$)).subscribe((event) => {
            switch (event.type) {
                case AppBusEvent.AccountSettingsFetched: {
                    this._accountSettings$.next(event.payload.settings);

                    break;
                }

                case AppBusEvent.SignedOut: {
                    this._accountState$.next(initialState());
                    this._accountSettings$.next(AccountSettingsShared.defaultAccountSettings());

                    break;
                }

                case AppBusEvent.OrderCreated: {
                    let totalPurchased = 0;

                    for (const product of event.payload.response.productsPurchased) {
                        totalPurchased += product.amount;
                    }

                    this.accountState = {
                        ...this.accountState,
                        numProductsInBasket: Math.max(0, this.accountState.numProductsInBasket - totalPurchased),
                    };

                    break;
                }
            }
        });
    }

    get accountSettings$(): Observable<AppSessionAccountSettings> {
        return this._accountSettings$.asObservable();
    }

    get accountState(): State {
        return this._accountState$.getValue();
    }

    get accountState$(): Observable<State> {
        return this._accountState$.asObservable();
    }

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    set accountState(newState: State) {
        this._accountState$.next(newState);
    }

    listViewed(request: AppSessionListViewedRequest): Observable<AppSessionListViewedResponse> {
        return this.viewedWebEndpoint
            .list({
                category: request.category,
                offset: request.offset,
                limit: request.limit,
            })
            .pipe(takeUntil(this._destroy$));
    }

    destroyViewed(): Observable<void> {
        return this.viewedWebEndpoint.destroy().pipe(
            tap(() => {
                this.accountState = {
                    ...this.accountState,
                    numFavorites: 0,
                };
            }),
            map(() => undefined)
        );
    }

    addToViewed(request: AppSessionAddToViewedRequest): Observable<void> {
        return this.viewedWebEndpoint
            .add({
                productId: request.productId,
            })
            .pipe(
                catchError((err) => {
                    this.errorHandler.handle(err);

                    return throwError(err);
                }),
                map(() => undefined),
                tap(() => {
                    this.accountState = {
                        ...this.accountState,
                        numViewed: this.accountState.numViewed + 1,
                    };
                })
            );
    }

    getAccountProfile(): Observable<AppSessionAccountProfile> {
        return this.accountWebEndpoint.currentAccount().pipe(
            map((response) => {
                return {
                    fullName: response.fullName,
                    email: response.email,
                    phone: response.phone,
                    deliveryAddress: response.deliveryAddress,
                };
            })
        );
    }

    updateAccountProfile(request: AppSessionSetAccountProfile): Observable<void> {
        return this.accountWebEndpoint.updateProfile(request).pipe(map(() => undefined));
    }

    updateAccountSettings(request: AppSessionUpdateAccountSettings): Observable<void> {
        return this.accountWebEndpoint.updateSettings(request).pipe(
            retryWhen(genericRetryStrategy()),
            tap(() =>
                this._accountSettings$.next({
                    ...this._accountSettings$.getValue(),
                    ...request,
                })
            ),
            map(() => undefined)
        );
    }

    getAccountSettings(): AppSessionAccountSettings {
        return this._accountSettings$.getValue();
    }

    getAccountSettings$(): Observable<AppSessionAccountSettings> {
        return this._accountSettings$.asObservable();
    }

    get numFavorites$(): Observable<number> {
        return this.accountState$.pipe(
            map((state) => state.numFavorites),
            distinctUntilChanged()
        );
    }

    addToFavorites(request: AppSessionAddToFavoritesRequest): Observable<void> {
        return this.favoriteWebEndpoint
            .add({
                productId: request.productId,
            })
            .pipe(
                catchError((err) => {
                    this.errorHandler.handle(err);

                    return throwError(err);
                }),
                map((response) => {
                    this.mindboxDataAccess
                        .request({
                            operation: 'SetIzbrannoeItemList',
                            uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                            body: {
                                productList: response.map((x) => {
                                    return {
                                        product: {
                                            ids: {
                                                website: x.productId,
                                            },
                                        },
                                        count: 1,
                                        pricePerItem: 0,
                                    };
                                }),
                            },
                        })
                        .toPromise()
                        .then();
                }),
                tap(() => {
                    this.accountState = {
                        ...this.accountState,
                        numFavorites: this.accountState.numFavorites + 1,
                    };
                })
            );
    }

    deleteFromFavorites(request: AppSessionRemoveFavoritesRequest): Observable<void> {
        return this.favoriteWebEndpoint
            .delete({
                productId: request.productId,
            })
            .pipe(
                catchError((err) => {
                    this.errorHandler.handle(err);

                    return throwError(err);
                }),
                map((response) => {
                    this.mindboxDataAccess
                        .request({
                            operation: 'SetIzbrannoeItemList',
                            uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                            body: {
                                productList: response.map((x) => {
                                    return {
                                        product: {
                                            ids: {
                                                website: x.productId,
                                            },
                                        },
                                        count: 1,
                                        pricePerItem: 0,
                                    };
                                }),
                            },
                        })
                        .toPromise()
                        .then();
                }),
                tap(() => {
                    this.accountState = {
                        ...this.accountState,
                        numFavorites: Math.max(this.accountState.numFavorites - 1, 0),
                    };
                })
            );
    }

    destroyFavorites(): Observable<void> {
        return this.favoriteWebEndpoint.destroy().pipe(
            tap(() => {
                this.accountState = {
                    ...this.accountState,
                    numFavorites: 0,
                };
            }),
            map(() => {
                this.mindboxDataAccess
                    .request({
                        operation: 'SetIzbrannoeItemList',
                        uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                        body: {
                            productList: [],
                        },
                    })
                    .toPromise()
                    .then();
            })
        );
    }

    get numCompares$(): Observable<number> {
        return this.accountState$.pipe(
            map((state) => state.numCompares),
            distinctUntilChanged()
        );
    }

    addToCompares(request: AppSessionAddToComparesRequest): Observable<void> {
        return this.compareWebEndpoint
            .include({
                productId: request.productId,
            })
            .pipe(
                catchError((err) => {
                    this.errorHandler.handle(err);

                    return throwError(err);
                }),
                map((response) => {
                    this.mindboxDataAccess
                        .request({
                            operation: 'SetSravnenieItemList',
                            uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                            body: {
                                productList: response.map((x) => {
                                    return {
                                        product: {
                                            ids: {
                                                website: x.productId,
                                            },
                                        },
                                        count: 1,
                                        pricePerItem: 0,
                                    };
                                }),
                            },
                        })
                        .toPromise()
                        .then();
                }),
                tap(() => {
                    this.accountState = {
                        ...this.accountState,
                        numCompares: this.accountState.numCompares + 1,
                    };
                })
            );
    }

    deleteFromCompares(request: AppSessionRemoveComparesRequest): Observable<void> {
        return this.compareWebEndpoint
            .exclude({
                productId: request.productId,
            })
            .pipe(
                catchError((err) => {
                    this.errorHandler.handle(err);

                    return throwError(err);
                }),
                map((response) => {
                    this.mindboxDataAccess
                        .request({
                            operation: 'ResetSravnenieItemList',
                            uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                            body: {},
                        })
                        .toPromise()
                        .then();
                }),
                tap(() => {
                    this.accountState = {
                        ...this.accountState,
                        numCompares: Math.max(0, this.accountState.numCompares - 1),
                    };
                })
            );
    }

    listProductsToCompare(): Observable<AppSessionListProductsToCompareResponse> {
        return this.compareWebEndpoint.list();
    }

    listFavorites(request: AppSessionListFavoritesRequest): Observable<AppSessionListFavoritesResponse> {
        return this.favoriteWebEndpoint
            .list({
                category: request.category,
                offset: request.offset,
                limit: request.limit,
            })
            .pipe(takeUntil(this._destroy$));
    }

    setCity(request: AppSessionSetCityRequest): Observable<void> {
        return this.accountWebEndpoint
            .setCity({
                cityId: request.cityId,
            })
            .pipe(
                catchError((err) => {
                    this.errorHandler.handle(err);

                    return throwError(err);
                }),
                map(() => undefined)
            );
    }

    hasSelectedCity$(): Observable<boolean> {
        return this.appBootstrap.data$.pipe(
            map((data) => !!data.selectedCity),
            distinctUntilChanged()
        );
    }

    getSelectedCityId$(): Observable<number | undefined> {
        return this.appBootstrap.data$.pipe(
            map((data) => data.selectedCity),
            distinctUntilChanged()
        );
    }

    isSubscribed$(): Observable<boolean> {
        return this.appBootstrap.data$.pipe(map((data) => data.isSubscribed));
    }

    markAccountAsSubscribed(): void {
        this.appBootstrap.patchData = {
            isSubscribed: true,
        };
    }

    markAccountAsUnsubscribed(): void {
        this.appBootstrap.patchData = {
            isSubscribed: false,
        };
    }

    isCookiePolicyAccepted$(): Observable<boolean> {
        return this.appBootstrap.data$.pipe(
            map((data) => {
                return data.account.isCookiePolicyAccepted;
            })
        );
    }

    markCookiePolicyAccepted(): void {
        this.appBootstrap.patchData = {
            isCookiePolicyAccepted: true,
        };

        this.accountWebEndpoint.setIsCookiePolicyAccepted().toPromise().then();
    }

    get numProductsInBasket$(): Observable<number> {
        return this.accountState$.pipe(
            map((state) => state.numProductsInBasket),
            distinctUntilChanged()
        );
    }

    addProductToBasket(request: AppSessionAddToBasketRequest): Observable<AppSessionBasketEntry> {
        const submitForm = (recaptchaV3Token: string) => this.basketWebEndpoint
            .add({
                productId: request.productId,
                amount: request.amount,
            }, recaptchaV3Token)
            .pipe(
                map((response) => {
                    this.mindboxDataAccess
                        .request({
                            operation: 'Website.SetCart',
                            uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                            body: {
                                productList: response.map((x) => {
                                    return {
                                        product: {
                                            ids: {
                                                website: x.product.id,
                                            },
                                        },
                                        count: x.amount,
                                        pricePerItem: x.price,
                                    };
                                }),
                            },
                        })
                        .toPromise()
                        .then();

                    const product = response.find((a) => a.product.id == request.productId);

                    return {
                        amount: product.amount,
                        price: product.price,
                        product: product.product,
                    };
                }),
                tap(() => {
                    this.accountState = {
                        ...this.accountState,
                        numProductsInBasket: this.accountState.numProductsInBasket + 1,
                    };
                })
            );

        return this.recaptchaV3Service.execute('ProductBasket').pipe(
            switchMap((recaptchaV3Token) => submitForm(recaptchaV3Token)),
        );
    }

    removeProductFromBasket(request: AppSessionRemoveFromBasketRequest): Observable<void> {
        return this.basketWebEndpoint
            .remove({
                productId: request.productId,
            })
            .pipe(
                map((response) => {
                    this.mindboxDataAccess
                        .request({
                            operation: 'Website.SetCart',
                            uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                            body: {
                                productList: response.map((x) => {
                                    return {
                                        product: {
                                            ids: {
                                                website: x.product.id,
                                            },
                                        },
                                        count: x.amount,
                                        pricePerItem: x.price,
                                    };
                                }),
                            },
                        })
                        .toPromise()
                        .then();
                }),
                tap(() => {
                    this.accountState = {
                        ...this.accountState,
                        numProductsInBasket: Math.max(0, this.accountState.numProductsInBasket - 1),
                    };
                })
            );
    }

    setProductBasket(request: AppSessionSetProductBasketRequest): Observable<AppSessionBasketEntry | void> {
        if (request.setAmount > 0) {
            return this.basketWebEndpoint
                .update({
                    productId: request.productId,
                    setAmount: request.setAmount,
                })
                .pipe(
                    tap((response) => {
                        this.accountState = {
                            ...this.accountState,
                            numProductsInBasket: response.numProductsInBasket,
                        };

                        return response;
                    }),
                    map((response) => {
                        const product = response.productBaskets.find((a) => a.product.id == request.productId);

                        return {
                            amount: product.amount,
                            price: product.price,
                            product: product.product,
                        };
                    })
                );
        } else {
            return this.removeProductFromBasket({
                productId: request.productId,
            });
        }
    }

    clearProductBasket(): Observable<void> {
        this.mindboxDataAccess
            .request({
                operation: 'Website.ClearCart',
                uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                body: {
                    executionDateTimeUtc: new Date().toISOString(),
                },
            })
            .toPromise()
            .then();

        return this.basketWebEndpoint.clear().pipe(
            map(() => undefined),
            tap(() => {
                this.accountState = {
                    ...this.accountState,
                    numProductsInBasket: 0,
                };
            })
        );
    }

    getProductBasket(): Observable<AppSessionBasket> {
        return this.basketWebEndpoint.getBasket().pipe(
            retryWhen(genericRetryStrategy()),
            map((response) => {
                return {
                    entries: response.productBasket.map((productBasket) => {
                        return {
                            amount: productBasket.amount,
                            price: productBasket.price,
                            product: response.products.find((p) => p.id === productBasket.product.id),
                        };
                    }),
                };
            })
        );
    }

    updateCounters(request: AppSessionUpdateCountersRequest): void {
        this._accountState$.next({
            ...this.accountState,
            numCompares: request.numCompare,
            numFavorites: request.numFavorite,
            numProductsInBasket: request.numBasket,
        });
    }

    setCatalogPageSize(request: AppSessionSetCatalogPageSize): Observable<void> {
        this._accountState$.next({
            ...this.accountState,
            catalogPageSize: request.catalogPageSize,
        });

        return of(undefined);
    }
}
