import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UIConfirmBoxComponent, UIConfirmBoxComponentModalRequest } from './ui-confirm-box.component';
import { defaultModalConfig } from '@interid/interid-site-web/core';

export interface UIConfirmBoxRequest {
    prefix: string;
    titleTranslateParams?: any;
    messageTranslateParams?: any;
    proceed?: Function;
    reject?: Function;
    vcr?: ViewContainerRef;
}

@Injectable({
    providedIn: 'root',
})
export class UIConfirmBoxService {
    constructor(
        private readonly matDialog: MatDialog,
    ) {
    }

    open(options: UIConfirmBoxComponentModalRequest): MatDialogRef<UIConfirmBoxComponent> {
        return this.matDialog.open(UIConfirmBoxComponent, {
            ...defaultModalConfig,
            data: options,
            closeOnNavigation: true,
            disableClose: ! options.closeOnBackgroundClick,
            viewContainerRef: options.viewContainerRef,
        });
    }

    openShortSyntax(options: UIConfirmBoxRequest): MatDialogRef<UIConfirmBoxComponent> {
        return this.open({
            title: {
                value: `${options.prefix}.title`,
                translate: true,
                translateParams: options.titleTranslateParams,
            },
            message: {
                value: `${options.prefix}.message`,
                translate: true,
                translateParams: options.messageTranslateParams,
            },
            proceed: {
                value: `${options.prefix}.proceed`,
                translate: true,
                action: options.proceed,
            },
            reject: {
                value: `${options.prefix}.reject`,
                translate: true,
                action: options.reject,
            },
            viewContainerRef: options.vcr,
        });
    }

    openConfirmReject(proceed: Function, vcr?: ViewContainerRef): MatDialogRef<UIConfirmBoxComponent> {
        const t = '_.confirmFormReject';

        return this.open({
            viewContainerRef: vcr,
            title: {
                value: `${t}.title`,
                translate: true,
            },
            message: {
                value: `${t}.message`,
                translate: true,
            },
            proceed: {
                value: `${t}.proceed`,
                translate: true,
                action: proceed,
            },
            reject: {
                value: `${t}.reject`,
                translate: true,
            },
        });
    }
}
