import { IsInt, IsNotEmpty, IsOptional, IsPositive } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { OrderDto } from '../models/entities/order.dto';

export enum MyOrdersWebEndpointPaths {
    list = '/api/v1/web/my-orders/list',
    view = '/api/v1/web/my-orders/view',
}

export interface MyOrdersWebEndpoint {
    list(request: MyOrdersListWebRequest, req?: any): Promise<MyOrdersListWebResponse> | Observable<MyOrdersListWebResponse>;
    view(request: MyOrdersViewWebRequest, req?: any): Promise<MyOrdersViewWebResponse> | Observable<MyOrdersViewWebResponse>;
}

export class MyOrdersListWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;
}

export class MyOrdersListWebResponse {
    @ApiModelProperty({
        type: OrderDto,
        isArray: true,
    })
    orders: Array<OrderDto>;

    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty()
    total: number;
}

export class MyOrdersViewWebRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class MyOrdersViewWebResponse {
    @ApiModelProperty()
    order: OrderDto;
}
