import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { AuthEndpoint, AuthEndpointPaths, AuthRequest, AuthResponse, InvalidateTokenRequest, VerifyRequest, VerifyResponse } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridCmsAuthDataAccess implements AuthEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    auth(request: AuthRequest, recaptchaV3Token: string): Observable<AuthResponse> {
        return this.http.post(AuthEndpointPaths.auth, request, {
            headers: {
                'recaptcha': recaptchaV3Token,
            },
        });
    }

    verify(request: VerifyRequest): Observable<VerifyResponse> {
        return this.http.post(AuthEndpointPaths.verify, request);
    }

    invalidateToken(request: InvalidateTokenRequest): Observable<void> {
        return this.http.post(AuthEndpointPaths.invalidateToken, request);
    }

    invalidateAllTokens(): Observable<void> {
        return this.http.post(AuthEndpointPaths.invalidateAllTokens);
    }
}
