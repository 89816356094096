import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { ProductESDto, ViewedAddRequest, ViewedDto, ViewedEndpointWebPaths, ViewedListProductsRequest, ViewedListRequest, ViewedListResponse, ViewedWebEndpoint } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebViewedDataAccess implements ViewedWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    add(request: ViewedAddRequest): Observable<ViewedDto> {
        return this.http.post(ViewedEndpointWebPaths.add, request);
    }

    list(request: ViewedListRequest): Observable<ViewedListResponse> {
        return this.http.post(ViewedEndpointWebPaths.list, request);
    }

    listProducts(request: ViewedListProductsRequest): Observable<Array<ProductESDto>> {
        return this.http.post(ViewedEndpointWebPaths.listProducts, request);
    }

    destroy(): Observable<void> {
        return this.http.post(ViewedEndpointWebPaths.destroy);
    }
}
