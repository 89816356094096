const oneClickButtonMessage = `
Покупка в один клик

Товар: {{ product.title }} ({{ product.productCategory.title }})
Код товара: {{ product.id }}
Партномер: {{ product.partNumber }}

URL: {{ url }}
`;

const orderWithPhoneCallRequest = `
Обратный звонок

Товар: {{ product.title }} ({{ product.productCategory.title }})
Код товара: {{ product.id }}
Партномер: {{ product.partNumber }}

URL: {{ url }}
`;

const orderWithProductRequest = `
Заявка на товар

Товар: {{ product.title }} ({{ product.productCategory.title }})
Код товара: {{ product.id }}
Партномер: {{ product.partNumber }}

URL: {{ url }}
`;

export const interidSiteWebUiSharedProductI18n = {
    product: {
        components: {
            product_card: {
                addToBasket: 'Добавить в корзину',
            },
        },
        shared: {
            components: {
                product_list: {
                    no_products: 'По вашему запросу не найдено товаров',
                    more: 'Показать еще',
                },
                product_list_sort_and_view: {
                    view_mode_card: 'Отображать товары в виде сетки',
                    view_mode_list: 'Отображать товары в виде списка',
                    onlyAvailableOnDepot: 'В наличии',
                    sortPlaceholder: 'Сортировка',
                    filtersPlaceholder: 'Фильтры',
                    sort: {
                       modal:{
                        Popular: 'По популярности',
                        HasSale: 'По скидке',
                        PriceDesc: 'Сначала дорогие',
                        PriceAsc: 'Сначала дешевые',
                        DatePublished: 'По обновлению', 
                        Commented: 'По рейтингу',
                       },
                       desktop: {
                        Popular: 'Сначала популярные',
                        HasSale: 'Сначала по скидке',
                        PriceDesc: 'Сначала дорогие',
                        PriceAsc: 'Сначала недорогие',
                        DatePublished: 'Сначала новинки', 
                        Commented: 'Самые обсуждаемые',
                       },
                       mobile: {
                        Popular: 'Популярные',
                        HasSale: 'Скидка',
                        PriceDesc: 'Дорогие',
                        PriceAsc: 'Недорогие',
                        DatePublished: 'Новинки', 
                        Commented: 'Обсуждаемые',
                       }
                    },
                },
                product_filters: {
                    showMore: 'Показать еще',
                    viewMore: 'Показать все',
                    viewLess: 'Скрыть все',
                    submit: 'Применить',
                    reset: 'Сбросить',
                    price: {
                        title: 'Цена, ₽',
                        min: 'От',
                        max: 'До',
                        cancel: 'Сбросить фильтр по стоимости',
                    },
                    manufacturers: {
                        title: 'Производитель',
                        cancel: 'Сбросить фильтр по производителям',
                    },
                    boolean: {
                        title: 'Другие параметры',
                        cancel: 'Сбросить фильтр по другим параметрам',
                    },
                    options: {
                        title: 'Опции',
                        cancel: 'Сбросить фильтр по опциям',
                    },
                    series: {
                        title: 'Серия',
                        cancel: 'Сбросить фильтр по сериям',
                    },
                    dict: {
                        cancel: 'Сбросить фильтр',
                    },
                    actions: {
                        submit: 'Найти',
                        reset: 'Сбросить фильтры',
                    },
                },
            },
            services: {
                basket: {
                    added: 'Товар "{{ title }}" добавлен в корзину',
                },
                favorite: {
                    added: 'Товар "{{ title }}" добавлен в избранное',
                    delete: 'Товар "{{ title }}" удалён из избранного'
                },
                compares: {
                    added: 'Товар "{{ title }}" добавлен в таблицу для сравнения товаров',
                    delete: 'Товар "{{ title }}" удалён из таблицы для сравнения товаров'
                },
            },
        },
    },
    product_shared: {
        pipes: {
            product_reviews: {
                no_reviews: 'Нет отзывов',
                reviews_0: '{{ count }} отзывов',
                reviews_1: '{{ count }} отзыв',
                reviews_2: '{{ count }} отзыва',
            },
        },
        assets: {
            product_card_price: {
                noPrice: 'Уточняйте',
                tradePrice: 'Оптовая цена',
            },
            product_card_buy_button: {
                inBasketNone: 'В корзину',
                inBasketSingle: 'В корзине',
                inBasketMulti: 'В корзине ({{ count }})',
                orderWithPhoneCallRequest: 'Отправить запрос',
                orderWithPhoneCallRequestMessage: orderWithPhoneCallRequest,
                orderWithProductRequest: 'Отправить запрос',
                orderWithProductRequestMessage: orderWithProductRequest,
                analogs: 'Аналоги на замену'
            },
            product_card_one_click_buy: {
                button: 'Купить в 1 клик',
                message: oneClickButtonMessage,
            },
            product_card_rating: {
                isAvailable: 'В наличии',
                isAvailableOnDepot: 'В наличии',
                isAvailableOnVendorDepot: 'В наличии на складе производителя',
                isStaticAvailableVendorDepot: 'Зафиксировать наличие у производителя',
                isShowedOnHomepage: 'Выводить на главную',
            },
            product_review: {
                anonymous: 'Аноним',
                positive: 'Достоинства',
                negative: 'Недостатки',
                comment: 'Комментарий',
                response: 'Представитель «Inter ID»',
            },
            product_question: {
                comment: 'Вопрос',
                response: 'Представитель «Inter ID»',
                anonymous: 'Аноним',
            },
        },
        components: {
            product_filters_preview: {
                found_1: 'Найден {{ count }} товар',
                found_2: 'Найдено {{ count }} товара',
                found_3: 'Найдено {{ count }} товаров',
                found_none: 'Найдено 0 товаров',
                submit: 'Показать',
                reset: 'Сбросить настройки фильтра',
            },
        },
    },
};
