import { Observable } from 'rxjs';
import { IsArray, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CityDeliveryEntry, CityDto, CityVariants } from '../models/entities/city.dto';

export enum CityEndpointPaths {
    listCities = '/api/v1/cities/list',
    createCity = '/api/v1/cities/create',
    deleteCity = '/api/v1/cities/delete',
    editCity = '/api/v1/cities/edit',
    setCityAsDefault = '/api/v1/cities/setAsDefault',
    cityByGeolocation = '/api/v1/cities/byGeolocation'
}

export interface CityEndpoint {
    listCities(): Promise<ListCitiesResponse> | Observable<ListCitiesResponse>;
    createCity(request: CreateCityRequest): Promise<CityDto> | Observable<CityDto>;
    deleteCity(request: DeleteCityRequest): Promise<void> | Observable<void>;
    editCity(request: EditCityRequest): Promise<CityDto> | Observable<CityDto>;
    setCityAsDefault(request: SetCityAsDefaultRequest): Promise<CityDto> | Observable<CityDto>;
    cityByGeolocation(request: Request): Promise<CityDto> | Observable<CityDto>;
}

export type ListCitiesResponse = Array<CityDto>;

export class CityEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    domain: string;

    @ApiModelProperty()
    @ValidateNested()
    @IsNotEmpty()
    variants: CityVariants;

    @ApiModelProperty()
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    deliveries: Array<CityDeliveryEntry>;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    mapRouteAttachmentId?: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    robotstxt: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    googleVerificationTag: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    yandexVerificationTag: string;
}

export class CreateCityRequest extends CityEntityBody {
}

export class DeleteCityRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    id: number;
}

export class EditCityRequest extends CityEntityBody {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    id: number;
}

export class SetCityAsDefaultRequest {
    @IsNotEmpty()
    @ApiModelProperty()
    @IsInt()
    @IsPositive()
    id: number;
}
