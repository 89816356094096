import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { TagDto } from '../models/entities/tag.dto';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '../core/crud';

export enum TagEndpointPaths {
    create = '/api/v1/tags/create',
    edit = '/api/v1/tags/edit',
    delete = '/api/v1/tags/delete',
    list = '/api/v1/tags/list',
    sort = '/api/v1/tags/sort',
}

export interface TagEndpoint {
    create(request: CreateTagRequest): Promise<TagDto> | Observable<TagDto>;
    edit(request: EditTagRequest): Promise<TagDto> | Observable<TagDto>;
    delete(request: DeleteTagRequest): Promise<void> | Observable<void>;
    list(request: ListTagsRequest): Promise<ListTagsResponse> | Observable<ListTagsResponse>;
    setSortOrder(request: SetSortOrderTagRequest): Promise<SetSortOrderTagResponse> | Observable<SetSortOrderTagResponse>;
}

export class TagEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;
}

export class CreateTagRequest extends TagEntityBody {
}

export class EditTagRequest extends TagEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteTagRequest {
    id: number;
}

export class ListTagsRequest extends CrudListRequestDto {
}

export class ListTagsResponse extends CrudListResponseDto<TagDto> {
    @ApiModelProperty({
        type: () => TagDto,
        isArray: true,
    })
    items: Array<TagDto>;
}

export class SetSortOrderTagRequest extends CrudSetSortOrderRequestDto {}

export class SetSortOrderTagResponse extends CrudSetSortOrderResponseDto<TagDto> {
    @ApiModelProperty({
        type: () => TagDto,
    })
    entity: TagDto;

    @ApiModelProperty({
        type: () => TagDto,
        isArray: true,
    })
    affected: Array<TagDto>;
}
