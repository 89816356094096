import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class ArticleLikeDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    authorAccountId: number;

    @ApiModelProperty()
    articleId: number;
}
