import { Observable } from 'rxjs';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { RouteShared } from '../models/shared/route.shared';

export enum RouteWebEndpointPaths {
    route = '/api/v1/web/route',
}

export interface RouteWebEndpoint {
    route(request: RouteWebRequest, req?: any): Promise<RouteShared.RouteResolveResult> | Observable<RouteShared.RouteResolveResult>;
}

export class RouteWebRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uri: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    hostname?: string;
}

