import { afterNextRender, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, input, OnDestroy, Output, viewChild } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { ArticleESDto, ManufacturerListDto, ProductCategoryListDto, ProductESDto, SearchAutocompleteWebResponse, Source, UrlBuilderRouterLinkDefinition } from '@interid/interid-site-shared';
import { UrlBuilderService } from '@interid/interid-site-web/core';

@Component({
    selector: 'app-shared-search-popup',
    templateUrl: './search-popup.component.html',
    styleUrls: ['./search-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SearchPopupComponent implements OnDestroy {
    public containerRef = viewChild<ElementRef>('container');

    public fitToContainer = input<ElementRef<HTMLDivElement>>();
    public queryString = input<string>();
    public response = input<SearchAutocompleteWebResponse>();

    @Output() navigateEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly urlBuilder: UrlBuilderService,
    ) {
        afterNextRender(() => {
            if (this.fitToContainer()) {
                interval(50).pipe(
                    startWith(0),
                    takeUntil(this.ngOnDestroy$),
                ).subscribe(() => {
                    if (this.containerRef() && this.containerRef().nativeElement && this.fitToContainer() && this.fitToContainer().nativeElement) {
                        this.containerRef().nativeElement.style.width = `${this.fitToContainer().nativeElement.getBoundingClientRect().width}px`;
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `search_shared.shared.components.search_popup.${input}`;
    }

    closeAndEmitOnNavigate(): void {
        this.navigateEvent.emit(undefined);
        this.closeEvent.emit(undefined);
    }

    close(): void {
        this.closeEvent.emit(undefined);
    }

    productCategoryLink(category: ProductCategoryListDto): string {
        return this.urlBuilder.urlLink({
            type: Source.Catalog,
            payload: {
                productCategoryId: category.id,
            },
        });
    }

    productLink(product: ProductESDto): UrlBuilderRouterLinkDefinition {
        return this.urlBuilder.routerLink({
            type: Source.Product,
            payload: {
                productId: product.id,
            },
        });
    }

    articleLink(article: ArticleESDto): UrlBuilderRouterLinkDefinition {
        return this.urlBuilder.routerLink({
            type: Source.Article,
            payload: {
                articleUri: article.uri,
                articleCategoryUri: article.articleCategory.slug,
            },
        });
    }

    manufacturerLink(manufacturer: ManufacturerListDto): UrlBuilderRouterLinkDefinition {
        return this.urlBuilder.routerLink({
            type: Source.Manufacturer,
            payload: {
                vendorUrl: manufacturer.uri,
            },
        });
    }
 
    trackById(index: number, input: { id: number }): number {
        return input.id;
    }
}
