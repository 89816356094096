import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { AppSessionAddToBasketRequest, AppSessionBasket, AppSessionBasketEntry, AppSessionRemoveFromBasketRequest, AppSessionService, AppSessionSetProductBasketRequest } from '@interid/interid-site-web/core-session';
import { MessagesService } from '@interid/interid-site-web/core';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class BasketService {
    constructor(
        private readonly appSession: AppSessionService,
        private readonly messages: MessagesService,
    ) {}

    private t(input: string): string {
        return `product.shared.services.basket.${input}`;
    }

    addProductToBasket(request: AppSessionAddToBasketRequest): Observable<AppSessionBasketEntry> {
        return combineLatest([this.appSession.addProductToBasket(request), this.appSession.numProductsInBasket$]).pipe( 
            take(1),
            map(([response, count]) => {
                this.messages.basketCompare({
                    translate: true,
                    message: this.t('added'),
                    translateParams: {
                        title: response.product.title,
                    },
                }, 'basket', count);

                return response;
            })
        );
    }

    setProductToBasket(request: AppSessionSetProductBasketRequest): Observable<AppSessionBasketEntry | void> {
        return this.appSession.setProductBasket(request);
    }

    removeProductFromBasket(request: AppSessionRemoveFromBasketRequest): Observable<void> {
        return this.appSession.removeProductFromBasket(request);
    }

    clearProductBasket(): Observable<void> {
        return this.appSession.clearProductBasket();
    }

    getProductBasket(): Observable<AppSessionBasket> {
        return this.appSession.getProductBasket();
    }
}
