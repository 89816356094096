import { ProductCategoryDto } from '../entities/product-category.dto';

export function getChildProductCategoryIds(rootId: number, categories: Array<Partial<ProductCategoryDto>>): Array<number> {
  const result: Array<number> = [rootId];

  const childCategories = categories.filter((c) => c.parent === rootId);

  for (const childCategory of childCategories) {
      if (childCategory.id !== rootId) {
          result.push(...getChildProductCategoryIds(childCategory.id, categories));
      } else {
          console.error('DETECTED an issue with product_categories; please check categories structure!');
          console.error(`CATEGORY CHILD (ID): ${childCategory.id}, ROOT (ID): ${rootId}`);
      }
  }

  return result;
}
