import { inject, Renderer2 } from '@angular/core';
import { IWsBaseSliderDirective, IWsRangeSliderDirective } from './ws-slider.interfaces';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function numberToString(value: any): string {
  value = isNaN(value) ? 0 : value;
  return value + '';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addListeners(elm: HTMLElement, data: { event: string, callback: (event: PointerEvent) => void }[]) {
  const renderer = inject(Renderer2);
  const unListen: (() => void)[] = [];

  data.forEach((data) => {
    unListen.push(renderer.listen(elm, data.event, data.callback));
  });

  return unListen;
}

/** Ensures that there is not an invalid configuration for the slider thumb inputs. */
export function _validateInputs(
  isRange: boolean,
  endInputElement: IWsBaseSliderDirective | IWsRangeSliderDirective,
  startInputElement?: IWsBaseSliderDirective,
): void {
  const startValid =
    !isRange || startInputElement?.hostElement.hasAttribute('wsSliderStart');
  const endValid = endInputElement.hostElement.hasAttribute(
    isRange ? 'wsSliderEnd' : 'wsSlider',
  );

  if (!startValid || !endValid) {
    _throwInvalidInputConfigurationError();
  }
}

function _throwInvalidInputConfigurationError(): void {
  throw Error(`Invalid slider thumb input configuration!

   Valid configurations are as follows:

     <ws-slider>
       <input wsSlider>
     </ws-slider>

     or

     <ws-slider>
       <input wsSliderStart>
       <input wsSliderEnd>
     </ws-slider>
   `);
}