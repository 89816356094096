import { Injectable } from '@angular/core';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { EmailSubscriptionsSubscribeWebRequest, EmailSubscriptionsWebEndpoint, EmailSubscriptionsWebEndpointPaths, EmailSubscriptionsWebUnsubscribeRequest } from '@interid/interid-site-shared';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebEmailSubscriptionsDataAccess implements EmailSubscriptionsWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    subscribe(request: EmailSubscriptionsSubscribeWebRequest): Observable<void> {
        return this.http.post(EmailSubscriptionsWebEndpointPaths.subscribe, request);
    }

    unsubscribe(request: EmailSubscriptionsWebUnsubscribeRequest): Observable<void> {
        return this.http.post(EmailSubscriptionsWebEndpointPaths.unsubscribe, request);
    }
}
