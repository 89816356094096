import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO, SourceDefinition } from '../models/shared/common.shared';
import { ProductCategoryLinkDto } from '../models/entities/product-category-link.dto';
import { CrudSetSortOrderRequestDto } from '../core/crud';

export enum ProductCategoryLinkEndpointPaths {
    create = '/api/v1/category-link/create',
    edit = '/api/v1/category-link/edit',
    delete = '/api/v1/category-link/delete',
    list = '/api/v1/category-link/list',
    setSortOrder = '/api/v1/category-link/setSortOrder',
}

export interface ProductCategoryLinkEndpoint {
    create(request: CreateProductCategoryLinkRequest): Promise<ProductCategoryLinkDto> | Observable<ProductCategoryLinkDto>;
    edit(request: EditProductCategoryLinkRequest): Promise<ProductCategoryLinkDto> | Observable<ProductCategoryLinkDto>;
    delete(request: DeleteProductCategoryLinkRequest): Promise<void> | Observable<void>;
    list(request: ListProductCategoryLinkRequest): Promise<ListProductCategoryLinkResponse> | Observable<ListProductCategoryLinkResponse>;
    setSortOrder(request: SetSortOrderProductCategoryLinkRequest): Promise<void> | Observable<void>;
}

export class ProductCategoryLinkEntityBody {
    @ApiModelProperty()
    @IsNotEmpty()
    @IsBoolean()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    categoryId: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uri: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    description: string;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    definition: SourceDefinition;

    @ApiModelProperty({
        type: () => PageMetadataSEO,
    })
    @IsObject()
    @ValidateNested()
    @IsNotEmpty()
    seo: PageMetadataSEO;
}

export class CreateProductCategoryLinkRequest extends ProductCategoryLinkEntityBody {
}

export class EditProductCategoryLinkRequest extends ProductCategoryLinkEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteProductCategoryLinkRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListProductCategoryLinkRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    categoryId: number;
}

export type ListProductCategoryLinkResponse = Array<ProductCategoryLinkDto>;

export class SetSortOrderProductCategoryLinkRequest extends CrudSetSortOrderRequestDto {}

