import { Injectable } from '@angular/core';
import { HomepageDataResponse, HomepageWebEndpoint, HomepageWebEndpointPaths } from '@interid/interid-site-shared';
import { InteridDataAccessService } from '@interid/interid-site-data-access/common';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteridWebHomepageDataAccess implements HomepageWebEndpoint {
    constructor(
        private readonly http: InteridDataAccessService,
    ) {}

    data(): Observable<HomepageDataResponse> {
        return this.http.get(HomepageWebEndpointPaths.data);
    }
}
