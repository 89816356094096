/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Directive, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { WS_RANGE_SLIDER_DIRECTIVE } from '../common/ws-slider.constants';
import { IWsRangeSliderDirective } from '../common/ws-slider.interfaces';
import { WsBaseSliderDirective } from './base-slider.directive';
import { WsThumbEnum } from '../common/ws-slider.enum';

@Directive({
  selector: 'input[wsSliderStart], input[wsSliderEnd]',
  exportAs: 'wsRangeSlider',
  host: {
    'class': 'ws-slider--input',
    'type': 'range',
    '(change)': 'onChange()',
    '(input)': 'onInput()',
    '(blur)': 'onBlur()',
    '(focus)': 'onFocus()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WsRangeSliderDirective),
      multi: true,
    },
    {
      provide: WS_RANGE_SLIDER_DIRECTIVE, useExisting: WsRangeSliderDirective
    },
  ],
  standalone: true,
})
export class WsRangeSliderDirective extends WsBaseSliderDirective implements IWsRangeSliderDirective {

  private sibling: WsRangeSliderDirective | undefined;

  /** Whether this slider corresponds to the input with greater value. */
  private isEndThumb: boolean;

  /** Whether this slider corresponds to the input on the left hand side. */
  public isLeftThumb!: boolean;

  /* ---------------------------------------------------------------------- */
  constructor(
    override readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.isEndThumb = this.hostElement.hasAttribute('wsSliderEnd');
    this.setIsLeftThumb();
    this.thumbPosition = this.isEndThumb ? WsThumbEnum.END : WsThumbEnum.START;
  }

  /* ------------------- Implements ControlValueAccessor ------------------- */
  /**s
   * Sets the input's value.
   * @param value The new value of the input
   */
  override writeValue(value: any): void {
    if (this.isControlInitialized || value !== null) {
      setTimeout(() => {
        this.value = value;
      }, 10);
      this.updateWidthInactive();
      this.updateSibling();
    }
  }

  /* ---------------------------------------------------------------------- */
  override onInput(): void {
    super.onInput();
    this.updateSibling();
    if (!this.isActive) {
      this.updateWidthInactive();
    }
  }

  override onNgControlValueChange(): void {
    super.onNgControlValueChange();
    this.getSibling()?.updateMinMax();
  }

  override onPointerDown(event: PointerEvent): void {
    if (this.disabled || event.button !== 0) {
      return;
    }
    if (this.sibling) {
      this.sibling.updateWidthActive();
      this.sibling.hostElement.classList.add('ws-slider-input-no-pointer-events');
    }
    super.onPointerDown(event);
  }

  override onPointerUp(): void {
    super.onPointerUp();
    if (this.sibling) {
      setTimeout(() => {
        this.sibling!.updateWidthInactive();
        this.sibling!.hostElement.classList.remove('ws-slider-input-no-pointer-events');
      });
    }
  }

  override onPointerMove(event: PointerEvent): void {
    super.onPointerMove(event);
    if (!this.slider.step() && this.isActive) {
      this.updateSibling();
    }
  }

  override fixValue(event: PointerEvent): void {
    super.fixValue(event);
    this.sibling?.updateMinMax();
  }

  override clamp(v: number): number {
    return Math.max(Math.min(v, this.getMaxPos()), this.getMinPos());
  }

  override updateWidthActive(): void {
    const minWidth = this.slider.inputPadding() * 2;
    const maxWidth =
      this.slider.cachedWidth() + this.slider.inputPadding() - minWidth - this.tickMarkOffset * 2;
    const percentage =
      this.slider.min() < this.slider.max()
        ? (this.max - this.min) / (this.slider.max() - this.slider.min())
        : 1;
    const width = maxWidth * percentage + minWidth;
    this.hostElement.style.width = `${width}px`;
    this.hostElement.style.padding = `0 ${this.slider.inputPadding()}px`;
  }

  override updateWidthInactive(): void {
    const sibling = this.getSibling();
    if (!sibling) {
      return;
    }
    const maxWidth = this.slider.cachedWidth() - this.tickMarkOffset * 2;
    const midValue = this.isEndThumb
      ? this.value - (this.value - sibling.value) / 2
      : this.value + (sibling.value - this.value) / 2;

    const _percentage = this.isEndThumb
      ? (this.max - midValue) / (this.slider.max() - this.slider.min())
      : (midValue - this.min) / (this.slider.max() - this.slider.min());

    const percentage = this.slider.min() < this.slider.max() ? _percentage : 1;

    // Extend the native input width by the radius of the click area
    let clickAreaPadding = this.slider.clickAreaRadius();

    // If one of the inputs is maximally sized (the value of both thumbs is
    // equal to the min or max), make that input take up all of the width and
    // make the other unselectable.
    if (percentage === 1) {
      clickAreaPadding = 48;
    } else if (percentage === 0) {
      clickAreaPadding = 0;
    }

    const width = maxWidth * percentage + clickAreaPadding;
    this.hostElement.style.width = `${width}px`;
    this.hostElement.style.padding = '0px';

    if (this.isLeftThumb) {
      this.hostElement.style.left = `-${this.slider.clickAreaRadius() - this.tickMarkOffset}px`;
      this.hostElement.style.right = 'auto';
    } else {
      this.hostElement.style.left = 'auto';
      this.hostElement.style.right = `-${this.slider.clickAreaRadius() - this.tickMarkOffset}px`;
    }
  }

  override getDefaultValue(): number {
    return this.isEndThumb && this.slider.isRange() ? this.max : this.min;
  }

  override setValue(value: string) {
    super.setValue(value);
    this.updateWidthInactive();
    this.updateSibling();
  }

  getSibling(): IWsRangeSliderDirective | undefined {
    if (!this.sibling) {
      this.sibling = this.slider.getInput(this.isEndThumb ? WsThumbEnum.START : WsThumbEnum.END) as
        | WsRangeSliderDirective
        | undefined;
    }
    return this.sibling;
  }

  /**
   * Returns the minimum translateX position allowed for this slider input's visual thumb.
   * @docs-private
   */
  getMinPos(): number {
    const sibling = this.getSibling();
    if (!this.isLeftThumb && sibling) {
      return sibling.translateX;
    }
    return this.tickMarkOffset;
  }

  /**
   * Returns the maximum translateX position allowed for this slider input's visual thumb.
   * @docs-private
   */
  getMaxPos(): number {
    const sibling = this.getSibling();
    if (this.isLeftThumb && sibling) {
      return sibling.translateX;
    }
    return this.slider.cachedWidth() - this.tickMarkOffset;
  }

  setIsLeftThumb(): void {
    this.isLeftThumb = !this.isEndThumb;
  }

  updateMinMax(): void {
    const sibling = this.getSibling();
    if (!sibling) {
      return;
    }
    if (this.isEndThumb) {
      this.min = Math.max(this.slider.min(), sibling.value);
      this.max = this.slider.max();
    } else {
      this.min = this.slider.min();
      this.max = Math.min(this.slider.max(), sibling.value);
    }
  }

  updateStaticStyles(): void {
    this.hostElement.classList.toggle('ws-ws-slider--right-input', !this.isLeftThumb);
  }

  private updateSibling(): void {
    const sibling = this.getSibling();
    if (!sibling) {
      return;
    }
    sibling.updateMinMax();
    if (this.isActive) {
      sibling.updateWidthActive();
    } else {
      sibling.updateWidthInactive();
    }
  }
}
