import { tsToTranslations } from '../functions/ts-to-translations.function';

import { interidSiteWebFeatureAccountI18n } from './interid-site-web-feature-account.i18n';
import { interidSiteWebFeatureArticleI18n } from './interid-site-web-feature-article.i18n';
import { interidSiteWebFeatureCatalogI18n } from './interid-site-web-feature-catalog.i18n';
import { interidSiteWebFeatureCompareI18n } from './interid-site-web-feature-compare.i18n';
import { interidSiteWebFeatureContactsI18n } from './interid-site-web-feature-contacts.i18n';
import { interidSiteWebFeatureDeliveryI18n } from './interid-site-web-feature-delivery.i18n';
import { interidSiteWebFeatureDictionaryI18n } from './interid-site-web-feature-dictionary.i18n';
import { interidSiteWebFeatureFavoriteI18n } from './interid-site-web-feature-favorite.i18n';
import { interidSiteWebFeatureHomepageI18n } from './interid-site-web-feature-homepage.i18n';
import { interidSiteWebFeaturePrivacyI18n } from './interid-site-web-feature-privacy.i18n';
import { interidSiteWebFeatureProductI18n } from './interid-site-web-feature-product.i18n';
import { interidSiteWebFeatureProductReviewsI18n } from './interid-site-web-feature-product-reviews.i18n';
import { interidSiteWebFeaturePurchaseI18n } from './interid-site-web-feature-purchase.i18n';
import { interidSiteWebFeatureSaleI18n } from './interid-site-web-feature-sale.i18n';
import { interidSiteWebFeatureDiscountedI18n } from './interid-site-web-feature-discounted.i18n';
import { interidSiteWebFeatureMarkingkitsI18n } from './interid-site-web-feature-markingkits.i18n';
import { interidSiteWebFeatureSearchI18n } from './interid-site-web-feature-search.i18n';
import { interidSiteWebFeatureSolutionsI18n } from './interid-site-web-feature-solutions.i18n';
import { interidSiteWebFeatureAboutI18n } from './interid-site-web-feature-about.i18n';
import { interidSiteWebFeatureContentI18n } from './interid-site-web-feature-content.i18n';

export const interidSiteWebFeatureI18n = {
    ...tsToTranslations(interidSiteWebFeatureAboutI18n),
    ...tsToTranslations(interidSiteWebFeatureAccountI18n),
    ...tsToTranslations(interidSiteWebFeatureArticleI18n),
    ...tsToTranslations(interidSiteWebFeatureCatalogI18n),
    ...tsToTranslations(interidSiteWebFeatureAccountI18n),
    ...tsToTranslations(interidSiteWebFeatureCompareI18n),
    ...tsToTranslations(interidSiteWebFeatureContactsI18n),
    ...tsToTranslations(interidSiteWebFeatureDeliveryI18n),
    ...tsToTranslations(interidSiteWebFeatureContentI18n),
    ...tsToTranslations(interidSiteWebFeatureDictionaryI18n),
    ...tsToTranslations(interidSiteWebFeatureFavoriteI18n),
    ...tsToTranslations(interidSiteWebFeatureHomepageI18n),
    ...tsToTranslations(interidSiteWebFeaturePrivacyI18n),
    ...tsToTranslations(interidSiteWebFeatureProductI18n),
    ...tsToTranslations(interidSiteWebFeatureProductReviewsI18n),
    ...tsToTranslations(interidSiteWebFeaturePurchaseI18n),
    ...tsToTranslations(interidSiteWebFeatureSaleI18n),
    ...tsToTranslations(interidSiteWebFeatureDiscountedI18n),
    ...tsToTranslations(interidSiteWebFeatureMarkingkitsI18n),
    ...tsToTranslations(interidSiteWebFeatureSearchI18n),
    ...tsToTranslations(interidSiteWebFeatureSolutionsI18n),
};
