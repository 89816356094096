import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, EventEmitter, OnDestroy, Output, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { InteridWebPhoneCallRequestDataAccess } from '@interid/interid-site-data-access/web';
import { ApiErrorHandlerService, EnvironmentWebService, MessagesService } from '@interid/interid-site-web/core';
import { RoistatPhoneCallRequestContext } from '@interid/interid-site-shared';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare let ga;

interface State {
    form: UntypedFormGroup;
    sent: boolean;
}

interface FormValue {
    phone: string;
}

@Component({
    selector: 'app-shared-phone-call-request',
    templateUrl: './phone-call-request.component.html',
    styleUrls: ['./phone-call-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneCallRequestComponent implements OnDestroy {
    @Output('sent') sentEvent: EventEmitter<void> = new EventEmitter<void>();

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state = signal<State>({
        sent: false,
        form: this.fb.group({
            phone: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
        }),
    });

    get formValue(): FormValue {
        return this.state().form.value;
    }

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly env: EnvironmentWebService,
        private readonly endpoint: InteridWebPhoneCallRequestDataAccess,
        private readonly router: Router,
        private readonly messages: MessagesService,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
    ) {
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `shared.components.phone_call_request.${input}`;
    }

    ngSubmit(): void {
        if (! this.state().form.valid) {
            return;
        }

        this.recaptchaV3Service.execute('PhoneCallRequest').pipe(
            takeUntil(this.ngOnDestroy$),
        ).subscribe((recaptchaV3Token) => this.submitForm(recaptchaV3Token));
    }

    submitForm(recaptchaV3Token: string): void {
        if (! this.state().form.valid) {
            return;
        }

        const formValue = this.formValue;

        this.state().form.disable();

        let sessionId;

        try {
            sessionId = window['ct']('calltracking_params', 'y88mwzmo').sessionId;
        } catch (err) {
            console.log(err);
        }

        this.endpoint.send({
            phone: formValue.phone,
            context: RoistatPhoneCallRequestContext.Header,
            sessionId: sessionId,
            createdFromURL: `${this.env.current.baseDomainProtocol}://${this.env.current.baseDomain}${this.router.url}`,
        }, recaptchaV3Token).pipe(
            finalize(() => this.state().form.enable()),
            finalize(() => this.cdr.markForCheck()),
            takeUntil(this.ngOnDestroy$),
        ).subscribe({
            next: () => {
                this.messages.success({
                    message: this.t('success'),
                    translate: true,
                });

                this.state.update((prev) => ({
                    ...prev,
                    sent: true,
                }));

                if (window && window['ym'] && this.env.current.ymId) {
                    window['ym'](this.env.current.ymId, 'reachGoal', 'application');
                }

                this.state().form.reset();

                this.sentEvent.emit();
            },
            error: (error) => {
                this.errorHandler.handle(error);
            },
        });
    }
}
