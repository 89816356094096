import { IsArray, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Max, Min } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ES_MAX_SEARCH_RESULTS_SIZE } from '../models/constants/es.constants';
import { ProductCategoryListDto } from '../models/entities/product-category.dto';
import { ProductESDto } from '../models/entities/product.dto';
import { FavoriteDto } from '../models/entities/favorite.dto';

export enum FavoriteEndpointWebPaths {
    add = '/api/v1/web/favorite/add',
    addBulk = '/api/v1/web/favorite/addBulk',
    delete = '/api/v1/web/favorite/delete',
    list = '/api/v1/web/favorite/list',
    listProducts = '/api/v1/web/favorite/listProducts',
    destroy = '/api/v1/web/favorite/destroy',
}

export interface FavoriteWebEndpoint {
    add(request: FavoriteAddRequest): Promise<Array<FavoriteDto>> | Observable<Array<FavoriteDto>>;
    addBulk(request: FavoriteBulkAddRequest): Promise<void> | Observable<void>;
    delete(request: FavoriteDeleteRequest): Promise<Array<FavoriteDto>> | Observable<Array<FavoriteDto>>;
    list(request: FavoriteListRequest): Promise<FavoriteListResponse> | Observable<FavoriteListResponse>;
    listProducts(request: FavoriteListProductsRequest): Promise<Array<ProductESDto>> | Observable<Array<ProductESDto>>;
    destroy(): Promise<void> | Observable<void>;
}

export class FavoriteAddRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;
}

export class FavoriteDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;
}

export class FavoriteListRequest {
    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    offset: number;

    @ApiModelProperty()
    @Min(1)
    @Max(ES_MAX_SEARCH_RESULTS_SIZE)
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    limit: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    category?: string;
}

export class FavoriteListCategoryDTO {
    @ApiModelProperty()
    category: ProductCategoryListDto;

    @ApiModelProperty()
    total: number;
}

export class FavoriteListResponse {
    @ApiModelProperty()
    request: FavoriteListRequest;

    @ApiModelProperty()
    total: number;

    @ApiModelProperty({
        type: FavoriteDto,
        isArray: true,
    })
    favorites: Array<FavoriteDto>;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    products: Array<ProductESDto>;

    @ApiModelProperty({
        type: FavoriteListCategoryDTO,
        isArray: true,
    })
    productCategories: Array<FavoriteListCategoryDTO>;
}

export class FavoriteBulkAddRequest {
    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;
}

export class FavoriteListProductsRequest {
    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;
}
