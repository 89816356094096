
<div class="c">
  <div class="c-title">@if (data.title.translate) {
    <span ngx-translate="ngx-translate" [translateParams]="data.title.translateParams">{{ data.title.value }}</span>
    }@if (! data.title.translate) {
    <span>{{ data.title.value }}</span>
  }</div>
  <div class="c-contents">
    @if (data.message.translate) {
      <p ngx-translate="ngx-translate" [translateParams]="data.message.translateParams">{{ data.message.value }}</p>
    }
    @if (! data.message.translate) {
      <p>{{ data.message.value }}</p>
    }
  </div>
  <div class="c-actions">
    <div class="c-action">
      <app-shared-ui-button buttonStyle="plain" (click)="reject()">@if (data.reject.translate) {
        <span>{{ data.reject.value | translate }}</span>
        }@if (! data.reject.translate) {
        <span>{{ data.reject.value }}</span>
      }</app-shared-ui-button>
    </div>
    <div class="c-action">
      <app-shared-ui-button buttonStyle="default" (click)="proceed()">@if (data.proceed.translate) {
        <span>{{ data.proceed.value | translate }}</span>
        }@if (! data.proceed.translate) {
        <span>{{ data.proceed.value }}</span>
      }</app-shared-ui-button>
    </div>
  </div>
</div>