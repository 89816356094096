import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO } from '../models/shared/common.shared';
import { CrudSetSortOrderRequestDto } from '../core/crud';
import { SolutionCategoryDto } from '../models/entities/solution-category.dto';

export enum SolutionCategoryEndpointPaths {
    create = '/api/v1/solution-category/create',
    edit = '/api/v1/solution-category/edit',
    delete = '/api/v1/solution-category/delete',
    list = '/api/v1/solution-category/list',
    setSortOrder = '/api/v1/solution-category/setSortOrder',
    getById = '/api/v1/solution-category/getById',
}

export interface SolutionCategoryEndpoint {
    create(request: SolutionCategoryCreateRequest): Promise<SolutionCategoryDto> | Observable<SolutionCategoryDto>;
    edit(request: SolutionCategoryEditRequest): Promise<SolutionCategoryDto> | Observable<SolutionCategoryDto>;
    delete(request: SolutionCategoryDeleteRequest): Promise<void> | Observable<void>;
    list(): Promise<Array<SolutionCategoryDto>> | Observable<Array<SolutionCategoryDto>>;
    setSortOrder(request: SolutionCategoriesSortOrderRequest): Promise<void> | Observable<void>;
    getById(request: SolutionCategoriesGetByIdRequest): Promise<SolutionCategoryDto> | Observable<SolutionCategoryDto>;
}

export class SolutionCategoryEntityBody {
    @ApiModelProperty()
    @IsNotEmpty()
    @IsBoolean()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    alt: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    uri: string;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    imageAttachmentId: number;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    seo: PageMetadataSEO;
}

export class SolutionCategoryCreateRequest extends SolutionCategoryEntityBody {
}

export class SolutionCategoryEditRequest extends SolutionCategoryEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SolutionCategoryDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SolutionCategoriesSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class SolutionCategoriesGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
