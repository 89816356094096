import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, input, Input, OnChanges, OnDestroy, OnInit, Output, signal, viewChild, ViewChild, ViewContainerRef } from '@angular/core';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { PaginatorService } from './paginator.service';
import { PaginatorCanonicalUrlGenerator, PaginatorUrlGenerator } from './paginator.state';
import { Subject } from 'rxjs';
import { UrlBuilderRouterLinkDefinition, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { PaginatorPopupService } from './paginator-popup/paginator-popup.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ViewBreakpointsService } from '@interid/interid-site-web/core';

interface LayoutOptions {
    MAX_PAGES_ALL: number;
    MAX_PAGES_LEFT: number;
    MAX_PAGES_CENTER: number;
    MAX_PAGES_RIGHT: number;
}

const desktopLayoutOptions: LayoutOptions = {
    MAX_PAGES_ALL: 6,
    MAX_PAGES_LEFT: 3,
    MAX_PAGES_CENTER: 4,
    MAX_PAGES_RIGHT: 2,
};

const mobileLayoutOptions: LayoutOptions = {
    MAX_PAGES_ALL: 6,
    MAX_PAGES_LEFT: 2,
    MAX_PAGES_CENTER: 2,
    MAX_PAGES_RIGHT: 1,
};

function pages(count: number, offset: number): Array<number> {
    const result: Array<number> = [];

    for (let i = offset; i < (offset + count); i++) {
        result.push(i);
    }

    return result;
}

export interface OnPaginatorChangeEvent {
    page: number;
    pageSize: number;
    total: number;
    routerLink: UrlBuilderRouterLinkDefinition;
    pagesSectionLeft: Array<number>;
    pagesSectionCenter: Array<number>;
    pagesSectionRight: Array<number>;
}

enum Layout {
    All = 'all',
    ManyPages = 'many-pages',
}

interface State {
    layout: Layout;
    layoutOptions: LayoutOptions;
    all: Array<number>;
    left: Array<number>;
    center: Array<number>;
    right: Array<number>;
}

@Component({
    selector: 'app-shared-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        PaginatorService,
        PaginatorPopupService,
    ],
})
export class PaginatorComponent implements OnChanges, OnInit, OnDestroy {
    public divider = viewChild.required<ElementRef<HTMLDivElement>>('divider');

    public page = input<number>();
    public pageSize = input<number>();
    public total = input<number>();

    public shouldFollowUrl = input(false);

    public urlGenerator = input<PaginatorUrlGenerator>();
    public canonicalUrlGenerator = input<PaginatorCanonicalUrlGenerator>();

    @Output('numPages') numPagesEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output('next') nextEvent: EventEmitter<OnPaginatorChangeEvent> = new EventEmitter<OnPaginatorChangeEvent>();

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public state = signal<State>({
        layout: Layout.All,
        layoutOptions: mobileLayoutOptions,
        all: [],
        left: [],
        center: [],
        right: [],
    });

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly vcr: ViewContainerRef,
        public readonly service: PaginatorService,
        private readonly popupService: PaginatorPopupService,
        private readonly viewBreakpoints: ViewBreakpointsService,
    ) {
    }

    ngOnChanges(): void {
        this.service.state = {
            ...this.service.state,
            page: this.page(),
            pageSize: this.pageSize(),
            total: this.total(),
            shouldFollowUrl: this.shouldFollowUrl(),
            urlGenerator: this.urlGenerator(),
            canonicalUrlGenerator: this.canonicalUrlGenerator(),
        };

        this.numPagesEvent.emit(this.service.maxPage);

        this.service.setupRelPrevNextAndCanonical();

        this.setupLayout();
    }

    ngOnInit(): void {
        this.service.state$.pipe(
            distinctUntilChanged(),
            takeUntil(this.ngOnDestroy$),
        ).subscribe(() => {
            this.setupLayout();

            this.cdr.markForCheck();
        });

        this.viewBreakpoints.currentLayout$.pipe(
            takeUntil(this.ngOnDestroy$),
        ).subscribe((next) => {
            this.state.update((prev) => ({
                ...prev,
                layoutOptions: [ViewBreakpointsShared.Layout.Desktop, ViewBreakpointsShared.Layout.Wide, ViewBreakpointsShared.Layout.Tablet].includes(next)
                    ? desktopLayoutOptions
                    : mobileLayoutOptions,
            }));

            this.setupLayout();
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    setupLayout(): void {
        const {
            MAX_PAGES_ALL,
            MAX_PAGES_LEFT,
            MAX_PAGES_RIGHT,
            MAX_PAGES_CENTER,
        } = this.state().layoutOptions;

        if (this.service.maxPage <= MAX_PAGES_ALL) {
            this.state.update((prev) => ({
                ...prev,
                layout: Layout.All,
                all: pages(this.service.maxPage, 0),
                left: [],
                center: [],
                right: [],
            }));
        } else if (this.service.state.page < MAX_PAGES_LEFT) {
            this.state.update((prev) => ({
                ...prev,
                layout: Layout.ManyPages,
                left: pages(MAX_PAGES_LEFT, 0),
                right: pages(MAX_PAGES_RIGHT, Math.max(0, this.service.maxPage - MAX_PAGES_RIGHT)),
                center: [],
                all: [],
            }));
        } else if (this.service.state.page > (Math.max(0, this.service.maxPage - MAX_PAGES_RIGHT))) {
            this.state.update((prev) => ({
                ...prev,
                layout: Layout.ManyPages,
                left: pages(MAX_PAGES_LEFT, 0),
                right: pages(MAX_PAGES_RIGHT, Math.max(0, this.service.maxPage - MAX_PAGES_RIGHT)),
                center: [],
                all: [],
            }));
        } else {
            this.state.update((prev) => ({
                ...prev,
                layout: Layout.ManyPages,
                left: pages(MAX_PAGES_LEFT, 0),
                right: pages(MAX_PAGES_RIGHT, Math.max(0, this.service.maxPage - MAX_PAGES_RIGHT)),
                center: pages(MAX_PAGES_CENTER, this.service.state.page - 2),
                all: [],
            }));

            this.state.update((prev) => ({
                ...prev,
                layout: Layout.ManyPages,
                center: this.state().center.filter((page) => ! this.state().left.includes(page) && ! this.state().right.includes(page)),
            }));
        }
    }

    popup(): void {
        this.popupService.open({
            vcr: this.vcr,
            trigger: this.divider(),
            onPage: (page) => this.goPage(page),
        });
    }

    preventDefaults($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
    }

    emit(): void {
        this.urlGenerator()(this.service.state.page).pipe(
            take(1),
        ).subscribe((routerLink) => {
            this.nextEvent.emit({
                page: this.service.state.page,
                pageSize: this.service.state.pageSize,
                total: this.service.state.page,
                pagesSectionLeft: [],
                pagesSectionCenter: [],
                pagesSectionRight: [],
                routerLink,
            });
        });
    }

    goPage(page: number, $event?: MouseEvent): void {
        if (! this.service.state.shouldFollowUrl) {
            if ($event) {
                this.preventDefaults($event);
            }

            this.service.state = {
                ...this.service.state,
                page,
            };

            this.emit();
        }
    }

    goPrevPage($event: MouseEvent): void {
        if (! this.service.state.shouldFollowUrl) {
            this.preventDefaults($event);

            if (! this.service.canGoPrevPage) {
                return;
            }

            this.service.state = {
                ...this.service.state,
                page: this.service.state.page - 1,
            };

            this.emit();
        }
    }

    goNextPage($event: MouseEvent): void {
        if (! this.service.state.shouldFollowUrl) {
            this.preventDefaults($event);

            if (! this.service.canGoNextPage) {
                return;
            }

            this.service.state = {
                ...this.service.state,
                page: this.service.state.page + 1,
            };

            this.emit();
        }
    }

    isActive(page: number): boolean {
        return this.service.state.page === page;
    }
}
