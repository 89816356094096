import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, PLATFORM_ID, inject, signal } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppBootstrapDataService, EnvironmentWebService } from '@interid/interid-site-web/core';
import { CityDto } from '@interid/interid-site-shared';
import { isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { REQUEST } from '@interid/interid-site-web/ui-shared';
import { toSignal } from '@angular/core/rxjs-interop';

interface State {
    city: CityDto;
}

@Component({
    selector: 'app-header-mobile-main',
    templateUrl: './app-header-mobile-main.component.html',
    styleUrls: ['./app-header-mobile-main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderMobileMainComponent implements OnInit {
    protected request: Request = inject(REQUEST, { optional: true});
    private readonly env = inject(EnvironmentWebService);
    private readonly appBootstrapDataService = inject(AppBootstrapDataService);
    private platformId = inject(PLATFORM_ID);

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output('close') closeEvent: EventEmitter<void> = new EventEmitter<void>();

    public appBootstrap = toSignal(this.appBootstrapDataService.data$);
    public vacancies = toSignal(this.appBootstrapDataService.data$.pipe(
        map((x) => x.vacancies)
    ));

    public state = signal<State>({
        city: new CityDto(),
    });

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.state.update((prev) => ({
            ...prev,
            city: this.appBootstrapDataService.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrapDataService.data.cities.find(x=>x.isDefault),
        }));
    }

    close(): void {
        this.closeEvent.emit();
    }
}
