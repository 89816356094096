<div class="c">
  <div class="c-header">
    <div class="c-header-name-and-date">
      <div class="c-header-name">
        @if (!question.isAnonymous) {
          <span>{{ question.author }}</span>
        }
        @if (question.isAnonymous) {
          <span>{{ t('anonymous') | translate }}</span>
        }
      </div>
      <div class="c-header-date">
        <span>{{ question.createdAt | ruDate }}</span>
      </div>
    </div>
  </div>
  <div class="c-contents">
    <div class="c-contents-label">
      <span>{{ t('comment') | translate }}</span>
    </div>
    <div class="c-contents-body">
      <div [innerHTML]="question.contents | rtfMultiline"></div>
    </div>
  </div>
  @if (question.response) {
    <div class="c-response">
      <div class="c-response-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_302_114656)">
            <circle cx="12" cy="12" r="12" fill="#253238" />
            <path d="M5.29983 11.7443C5.26162 11.4453 5.24252 11.2958 5.28913 11.1799C5.33006 11.0781 5.40463 10.9933 5.50042 10.9398C5.60952 10.8789 5.76022 10.8789 6.06163 10.8789H7.61734C7.91962 10.8789 8.07077 10.8789 8.18002 10.9401C8.27595 10.9938 8.35054 11.0788 8.39131 11.1809C8.43776 11.2971 8.41811 11.447 8.37882 11.7467L7.59559 17.7217H6.0638L5.29983 11.7443Z" fill="#F6F7F8" />
            <path d="M10.1808 6.37906C11.0895 6.21862 12.2714 6.12109 13.4979 6.12109C15.5885 6.12109 16.9486 6.52691 17.9791 7.35113C19.1135 8.24456 19.8262 9.66336 19.8262 11.7113C19.8262 13.926 19.066 15.4455 18.0266 16.3893C16.8744 17.4022 15.1104 17.8773 12.9752 17.8773C11.7012 17.8773 10.7717 17.7892 10.1719 17.7074V6.37906H10.1808ZM12.6367 15.7915C12.8416 15.8419 13.1979 15.8419 13.4979 15.8419C15.7489 15.8607 17.2189 14.5521 17.2189 11.79C17.2189 9.38652 15.8885 8.11558 13.7592 8.11558C13.2247 8.11558 12.8505 8.16592 12.6426 8.21625V15.7947H12.6367V15.7915Z" fill="#F6F7F8" />
            <path d="M8.42286 7.71534C8.42286 8.58502 7.71783 9.29005 6.84815 9.29005C5.97846 9.29005 5.27344 8.58502 5.27344 7.71534C5.27344 6.84565 5.97846 6.14062 6.84815 6.14062C7.71783 6.14062 8.42286 6.84565 8.42286 7.71534Z" fill="#69A042" />
          </g>
          <defs>
            <clipPath id="clip0_302_114656">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="c-response-contents">
        <span>{{ t('response') | translate }}</span>
        <div class="c-response-contents-text" [innerHTML]="question.response"></div>
      </div>
    </div>
  }
  @if (isBrowser) {
    <div class="c-likes">
      <div class="c-like">
        <span [ngClass]="state.likesStats.positive.active ? 'c-like-icon active' : 'c-like-icon'" (click)="like('Positive')">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 21H3a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3m7-2V4a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3H13Z" stroke="#B0BFC6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
        <span class="c-like-counter" [innerHTML]="state.likesStats.positive.count"></span>
      </div>
      <div class="c-like">
        <span [ngClass]="state.likesStats.negative.active ? 'c-like-icon active' : 'c-like-icon'" (click)="like('Negative')">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 1h2.67A2.31 2.31 0 0 1 21 3v7a2.31 2.31 0 0 1-2.33 2H16m-7 2v4a3 3 0 0 0 3 3l4-9V1H4.72a2 2 0 0 0-2 1.7l-1.381 9a2 2 0 0 0 2 2.3H9Z" stroke="#B0BFC6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
        <span class="c-like-counter" [innerHTML]="state.likesStats.negative.count"></span>
      </div>
    </div>
  }
</div>
