import { ChangeDetectionStrategy, Component, Injector, OnInit, PLATFORM_ID, Signal, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { JwtService, defaultModalConfig } from '@interid/interid-site-web/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { Source, urlDetectDefinition } from '@interid/interid-site-shared';
import { isPlatformBrowser } from '@angular/common';
import { AuthSignInComponent } from '@interid/interid-site-web/core-auth';
import { MatDialog } from '@angular/material/dialog';
import { AppMenuStateService } from '../app-menu-state.service';
import { connectToSignal } from '@interid/interid-site-web/ui-signals';
import { toSignal } from '@angular/core/rxjs-interop';

enum Screen {
    None = 'none',
    Main = 'main',
    Catalog = 'catalog',
    Search = 'search',
    City = 'city',
}

interface State {
    isProductRoute: boolean;
    isEmptyBasket: boolean;
}

@Component({
    selector: 'app-floatbar-mobile',
    templateUrl: './app-floatbar-mobile.component.html',
    styleUrls: ['./app-floatbar-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFloatbarMobileComponent implements OnInit {
    private readonly injector = inject(Injector);
    private readonly router = inject(Router);
    private readonly matDialog = inject(MatDialog);
    protected appMenuStateService = inject(AppMenuStateService);    
    protected appSession = inject(AppSessionService);    
    protected jwtService = inject(JwtService);
    protected platformId: Object = inject(PLATFORM_ID)

    public state = signal<State>({
        isProductRoute: false,
        isEmptyBasket: false,
    });

    public screen: Signal<Screen> = toSignal(this.appMenuStateService.current$);
    public basketCount: Signal<number> = toSignal(this.appSession.numProductsInBasket$);
    public isSignedId = signal<boolean>(false);
    public isBrowser = signal<boolean>(false);

    ngOnInit(): void {
        this.isBrowser.set(isPlatformBrowser(this.platformId));

        this.state.update(prev => ({
            ...prev,
            isProductRoute: this.isProductRoute(this.router.url),
            isEmptyBasket: this.isEmptyBasketRoute(this.router.url),
        }));

        connectToSignal(this.state, this.router.events, (prev) => {
            this.close();

            return {
                ...prev,
                isProductRoute: this.isProductRoute(this.router.url),
                isEmptyBasket: this.isEmptyBasketRoute(this.router.url),
            }
        }, this.injector);

        connectToSignal(this.isSignedId, this.jwtService.jwt$.pipe(
            map(jwt => jwt !== undefined)
        ), this.injector)
    }   

    isEmptyBasketRoute(url: string): boolean {
        if (!url.startsWith('/') && !(url.startsWith('http://') || url.startsWith('https://'))) {
            url = '/' + url;
        }

        return url === '/purchase/empty';
    }

    isProductRoute(url: string): boolean {
        if (!url.startsWith('/') && !(url.startsWith('http://') || url.startsWith('https://'))) {
            url = '/' + url;
        }

        return urlDetectDefinition(url).type == Source.Product;
    }

    signIn(): void {
        this.matDialog.open(AuthSignInComponent, {
            ...defaultModalConfig,
        });
    }

    openCatalogMenu($event: MouseEvent): void {
        if (!($event.metaKey || $event.shiftKey || $event.altKey || $event.ctrlKey)) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if (this.appMenuStateService.current === Screen.Catalog) {
            this.close();
        } else {
            this.appMenuStateService.switchMenuState(Screen.Catalog);
        }
    }

    toggleMainMenu(): void {
        if (this.appMenuStateService.current == Screen.Main) {
            this.close();
        } else {
            this.appMenuStateService.switchMenuState(Screen.Main);
        }
    }

    close(): void {
        this.appMenuStateService.switchMenuState(Screen.None);
    }
}