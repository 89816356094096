
<div class="c" [ngClass]="ngClass" (click)="$event.stopPropagation(); $event.preventDefault(); toggle()">
  <div class="c-checkbox">
    @if (state().form.value.enabled) {
      <div class="icon">
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2 1.704 13.51 0 5.6 7.908 2.505 4.824.8 6.516l4.8 4.788 9.6-9.6Z" fill="#fff"/></svg>
      </div>
    }
  </div>
  @if (label()) {
    <div class="c-label"><span>{{ label() }}</span></div>
  }
</div>