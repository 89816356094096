import { IsArray, IsEnum, IsInt, IsOptional, IsPositive, IsString, Min } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export namespace AttachmentImageStrategyShared {
  export enum SizesSet {
    Article,
    ArticleBlock,
    Product,
    Solution,
    SolutionBlock,
    SolutionContent,
    SolutionCategory,
    ProductSeriesImage,
    ProductSeriesIcon,
    ManufacturersImage,
    ManufacturersDealerImage,
    Banner,
    BannerMobile,
    ProductCategory,
    ProductCategoryIcon,
    ProductCategoryIconMobile,
    ContentEditorBlockOrderConstructor,
    ContentEditorBlockFeatures,
    ContentEditorBlockSimpleImageTable,
    HomepageLinkBlock,
    SeoCover,
    Contacts,
    ProductReview,
    ProductQuestion,
  }

  export const sizesBase = [375, 414, 520, 768, 1024, 1440, 2880];

  export const sizesSetMap = [
    { sizeSet: SizesSet.Article, sizes: sizesBase },
    { sizeSet: SizesSet.ArticleBlock, sizes: sizesBase },
    { sizeSet: SizesSet.Product, sizes: sizesBase },
    { sizeSet: SizesSet.Solution, sizes: sizesBase },
    { sizeSet: SizesSet.SolutionBlock, sizes: sizesBase },
    { sizeSet: SizesSet.SolutionContent, sizes: sizesBase },
    { sizeSet: SizesSet.SolutionCategory, sizes: sizesBase },
    { sizeSet: SizesSet.ProductSeriesImage, sizes: sizesBase },
    { sizeSet: SizesSet.ProductSeriesIcon, sizes: sizesBase },
    { sizeSet: SizesSet.ManufacturersImage, sizes: sizesBase },
    { sizeSet: SizesSet.ManufacturersDealerImage, sizes: sizesBase },
    { sizeSet: SizesSet.Banner, sizes: [1440] },
    { sizeSet: SizesSet.BannerMobile, sizes: [720] },
    { sizeSet: SizesSet.ProductCategory, sizes: sizesBase },
    { sizeSet: SizesSet.ProductCategoryIcon, sizes: sizesBase },
    { sizeSet: SizesSet.ContentEditorBlockOrderConstructor, sizes: sizesBase },
    { sizeSet: SizesSet.ContentEditorBlockFeatures, sizes: sizesBase },
    { sizeSet: SizesSet.ContentEditorBlockSimpleImageTable, sizes: sizesBase },
    { sizeSet: SizesSet.HomepageLinkBlock, sizes: sizesBase },
    { sizeSet: SizesSet.SeoCover, sizes: sizesBase },
    { sizeSet: SizesSet.Contacts, sizes: [...sizesBase, 578] },
    { sizeSet: SizesSet.ProductReview, sizes: [...sizesBase, 1440] },
    { sizeSet: SizesSet.ProductQuestion, sizes: [...sizesBase, 1440] },
  ];
}

export namespace AttachmentShared {

  export enum AttachmentType {
    manual = 'manual',
    driver = 'driver'
  }

  export enum AttachmentLanguage {
    ru = 'ru',
    en = 'en'
  }

  export namespace Strategies {
    export enum Strategy {
      Common = 'Common',
      Image = 'Image',
      Document = 'Document',
    }

    export const availableStrategies: Array<Strategy> = [
      Strategy.Common,
      Strategy.Image,
      Strategy.Document,
    ];

    export class AbstractStrategy {
      fileSize: number;
      fileMIMEType: string;
    }

    export type PairRequest =
      { strategy: Strategy.Common; payload: Request.Common } |
      { strategy: Strategy.Image; payload: Request.Image } |
      { strategy: Strategy.Document; payload: Request.Document }
    ;

    export type PairResponse =
      { strategy: Strategy.Common; payload: Response.Common } |
      { strategy: Strategy.Image; payload: Response.Image } |
      { strategy: Strategy.Document; payload: Response.Document }
    ;

    export namespace Request {

      export class ImageAttachment {
        id: number;
        previewForVideoId?: number; 
      }

      export class Common {
        @IsOptional()
        @ApiModelProperty()
        @IsArray()
        @IsString({ each: true })
        acceptMimeTypes?: Array<string>;

        @IsOptional()
        @ApiModelProperty()
        @IsInt()
        @Min(1)
        minFileSize?: number;

        @IsOptional()
        @ApiModelProperty()
        @IsInt()
        @Min(0)
        maxFileSize?: number;
      }

      export class Image {
        @IsOptional()
        @ApiModelProperty()
        @IsInt()
        @Min(1)
        minFileSize?: number;

        @IsOptional()
        @ApiModelProperty()
        @IsInt()
        @Min(0)
        maxFileSize?: number;

        @IsOptional()
        @ApiModelProperty()
        @IsArray()
        @IsInt({each: true })
        @IsPositive({each: true })
        sizes?: Array<number>;

        @IsOptional()
        @ApiModelProperty()
        @IsEnum(AttachmentImageStrategyShared.SizesSet)
        sizesSet?: AttachmentImageStrategyShared.SizesSet;
      }

      export class Document {
        @IsOptional()
        @ApiModelProperty()
        @IsInt()
        @Min(1)
        minFileSize?: number;

        @IsOptional()
        @ApiModelProperty()
        @IsInt()
        @Min(0)
        maxFileSize?: number;
      }
    }

    export namespace Response {
      export class Common extends AbstractStrategy {
      }

      export class Image extends AbstractStrategy {
        fileSize: number;
        fileMIMEType: string;
        width: number;
        height: number;
        webpPath: string | undefined;
        variants: Array<ImageVariant>;
        webpVariants: Array<ImageVariant>;
      }

      export class ImageVariant {
        size: number;
        path: string;
        url?: string;
      }

      export class Document extends AbstractStrategy {
      }
    }
  }
}
