import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class IndexStatusIndexedDto {
  @ApiModelProperty()
  redirects: number;

  @ApiModelProperty()
  metatags: number;

  @ApiModelProperty()
  categories: number;
}

export class IndexStatusDto {
  @ApiModelProperty()
  memorySize: number;

  @ApiModelProperty()
  updateTime: string;

  @ApiModelProperty()
  indexed: IndexStatusIndexedDto;
}
