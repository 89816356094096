<div class="c" #attachCategories>
  <div class="c-button catalog"
       [ngClass]="{ 'is-active': !state().isOnSalesRoute, 'is-highlight': state().areCategoriesOpened }"
       (click)="openCategories()">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.399 10.665h19.2a1.067 1.067 0 0 0 0-2.134h-19.2a1.067 1.067 0 1 0 0 2.134Zm19.2 10.666h-19.2a1.066 1.066 0 0 0 0 2.134h19.2a1.067 1.067 0 0 0 0-2.134Zm0-6.4h-19.2a1.066 1.066 0 0 0 0 2.134h19.2a1.067 1.067 0 0 0 0-2.134Z"
        fill="#fff"/>
    </svg>
    <span>Каталог</span>
  </div>

  <a class="c-button" [routerLink]="['/sales']">
    <div class="c-frame-1223"><img src="/assets/images/u_pizza-slice.svg"/><span>Акции</span></div>
  </a>

  @if (discountedStats() !== 0) {
    <a class="c-button discounted" [routerLink]="['/discounted']">
      <div class="c-frame-1223"><img src="/assets/images/fi_percent.svg"/><span>Уцененный товар</span>
      </div>
    </a>
  }

  <a class="c-button" [routerLink]="['/marking-kits']">
    <div class="c-frame-1223"><img src="/assets/images/u_create-dashboard.svg"/>
      <span>Комплекты для маркировки</span>
    </div>
  </a>
</div>
