import { Observable } from 'rxjs';
import { IsEnum, IsInt, IsNotEmpty, IsPositive, IsOptional, IsString, IsArray } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PhoneCallRequestDto } from '../models/entities/phone-call-request.dto';
import { PhoneCallRequestShared } from '../models/shared/phone-call-request.shared';
import { CrudListRequestDto, CrudListResponseDto } from '../core/crud';
import { PhoneCallRequestSendRequest } from '../endpoints-web/phone-call-request.web-endpoint';

export enum PhoneCallRequestEndpointPaths {
    send = '/api/v1/phone-call-request/send',
    setStatus = '/api/v1/phone-call-request/setStatus',
    delete = '/api/v1/phone-call-request/delete',
    assign = '/api/v1/phone-call-request/assign',
    unassign = '/api/v1/phone-call-request/unassign',
    list = '/api/v1/phone-call-request/list',
    getById = '/api/v1/phone-call-request/getById',
}

export interface PhoneCallRequestEndpoint {
    send(request: PhoneCallRequestSendRequest): Promise<PhoneCallRequestDto> | Observable<PhoneCallRequestDto>;
    setStatus(request: PhoneCallRequestSetStatusRequest): Promise<PhoneCallRequestDto> | Observable<PhoneCallRequestDto>;
    delete(request: PhoneCallRequestDeleteRequest): Promise<void> | Observable<void>;
    assign(request: PhoneCallRequestAssignRequest): Promise<PhoneCallRequestDto> | Observable<PhoneCallRequestDto>;
    unassign(request: PhoneCallRequestUnassignRequest): Promise<PhoneCallRequestDto> | Observable<PhoneCallRequestDto>;
    list(request: PhoneCallRequestListRequest): Promise<PhoneCallRequestListResponse> | Observable<PhoneCallRequestListResponse>;
    getById(request: PhoneCallRequestGetByIdRequest): Promise<PhoneCallRequestDto> | Observable<PhoneCallRequestDto>;
}

export class PhoneCallRequestSetStatusRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(PhoneCallRequestShared.Status),
    })
    @IsNotEmpty()
    newStatus: PhoneCallRequestShared.Status;

    @IsOptional()
    @ApiModelProperty()
    @IsString()
    comment?: string;
}

export class PhoneCallRequestDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class PhoneCallRequestAssignRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    accountEmail: string;
}

export class PhoneCallRequestUnassignRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class PhoneCallRequestListRequest extends CrudListRequestDto<PhoneCallRequestDto> {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(PhoneCallRequestShared.Status),
        isArray: true,
        required: false,
    })
    @IsArray()
    @IsEnum(PhoneCallRequestShared.Status, {
        each: true,
    })
    @IsOptional()
    statuses?: Array<PhoneCallRequestShared.Status>;
}

export class PhoneCallRequestListResponse extends CrudListResponseDto<PhoneCallRequestDto> {
    @ApiModelProperty({
        type: PhoneCallRequestDto,
    })
    items: Array<PhoneCallRequestDto>;
}

export class PhoneCallRequestGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
