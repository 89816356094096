import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, input, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges, viewChild, ViewChild } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ViewBreakpointsService, UrlBuilderService, AppBusService } from '@interid/interid-site-web/core';
import { InteridWebSearchDataAccess } from '@interid/interid-site-data-access/web';

interface State {
    form: UntypedFormGroup;
    showPlaceholder: boolean;
}

interface FormValue {
    queryString: string;
}

@Component({
    selector: 'app-shared-search-popup-placeholder',
    templateUrl: './search-popup-placeholder.component.html',
    styleUrls: ['./search-popup-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SearchPopupPlaceholderComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {
    public containerRef = viewChild<ElementRef>('container');
    public inputRef = viewChild<ElementRef<HTMLInputElement>>('input');

    public fitToContainer = input<ElementRef<HTMLDivElement>>();
    public queryString = input<string>();

    @Output('nextQuery') nextQueryEvent: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

    @Output() navigateEvent: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();
    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state = signal<State>({
        form: this.fb.group({
            queryString: [''],
        }),
        showPlaceholder: false,
    });

    get formValue(): FormValue {
        return this.state().form.value;
    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state().form.patchValue(formValue);
    }

    get hasQueryString(): boolean {
        return (this.formValue.queryString || '').toString().trim().length > 0;
    }

    constructor(private readonly cdr: ChangeDetectorRef, private readonly fb: UntypedFormBuilder, private readonly router: Router, private readonly appBus: AppBusService, private readonly viewBreakpoints: ViewBreakpointsService, private readonly endpoint: InteridWebSearchDataAccess, private readonly urlBuilder: UrlBuilderService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['queryString']) {
            this.patchFormValue = {
                queryString: this.queryString(),
            };
        }
    }

    ngOnInit(): void {
        this.patchFormValue = {
            queryString: this.queryString(),
        };

        this.state().form
            .get('queryString')
            .valueChanges.pipe(
                filter(() => this.hasQueryString),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe((next) => {
                this.nextQueryEvent.emit(next);
            });
    }

    focus(): void {
        if (this.inputRef() && this.inputRef().nativeElement) {
            this.inputRef().nativeElement.focus();
        }
    }

    ngAfterViewInit(): void {
        if (this.fitToContainer()) {
            interval(50)
                .pipe(startWith(0), takeUntil(this.ngOnDestroy$))
                .subscribe(() => {
                    if (this.containerRef() && this.containerRef().nativeElement && this.fitToContainer() && this.fitToContainer().nativeElement) {
                        this.containerRef().nativeElement.style.width = `${this.fitToContainer().nativeElement.getBoundingClientRect().width}px`;
                    }
                });
        }

        this.focus();
    }

    onPlaceholderClick(): void {
        this.state.update((prev) => ({
            ...prev,
            showPlaceholder: false,
        }));

        const observer = new MutationObserver((mutations, mutationInstance) => {
            if (this.inputRef() && this.inputRef().nativeElement) {
                this.focus();
                mutationInstance.disconnect();
            }
        });

        observer.observe(document, {
            childList: true,
            subtree: true,
        });
    }

    onInputBlur(): void {
        this.state.update((prev) => ({
            ...prev,
            showPlaceholder: !this.hasQueryString,
        }));
    }

    onKeyUpEscape(): void {
        if (this.hasQueryString) {
            this.patchFormValue = {
                queryString: '',
            };
        } else {
            this.blur();
        }

        this.close();
    }

    onKeyUpEnter(): void {
        if (!this.hasQueryString) {
            return;
        }

        this.navigateEvent.emit(this.formValue.queryString);
    }

    blur(): void {
        if (this.inputRef() && this.inputRef().nativeElement) {
            this.inputRef().nativeElement.blur();
        }
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    closeAndEmitOnNavigate(): void {
        this.navigateEvent.emit(undefined);
        this.closeEvent.emit(undefined);
    }

    close(): void {
        this.closeEvent.emit(undefined);
    }
}
