/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, inject, signal } from '@angular/core';
import { AppBootstrapDataService } from '@interid/interid-site-web/core';
import { AppMessageService } from './app-message.service';
import { AppBootstrapMessage } from '@interid/interid-site-shared';
import { connectToSignal } from '@interid/interid-site-web/ui-signals';

interface State {
    message?: AppBootstrapMessage;
}

@Component({
    selector: 'app-message',
    templateUrl: './app-message.component.html',
    styleUrls: ['./app-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMessageComponent implements OnInit {
    private readonly injector = inject(Injector);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly appBootstrap = inject(AppBootstrapDataService);
    private readonly appMessageService = inject(AppMessageService);

    public state = signal<State>({
        message: undefined,
    });

    ngOnInit(): void {
        connectToSignal(
            this.state,
            this.appBootstrap.data$,
            (prev, next) => ({
                ...prev,
                message: next.message,
            }),
            this.injector
        );
    }

    get ngStyle(): any {
        return {
            'background-color': this.state().message.siteMessageBgColor,
            'color': this.state().message.siteMessageTextColor,
        };
    }

    get iconNgStyle(): any {
        return {
            'fill': this.state().message.siteMessageTextColor,
        };
    }

    hide(): void {
        this.appMessageService.hide();
    }
}
