import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Inject, input, Input, OnInit, Output, Renderer2, signal, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { unwrapHtmlForSink } from 'safevalues';
import { createHtml } from 'safevalues/implementation/html_impl';
import { DadataSuggestion, DadataConfig, DadataConfigDefault, DadataType, NgxDadataService, DadataResponse } from '@interid/interid-site-web/core';

export function createDaDataValidator(value) {
    return (c: UntypedFormControl) => {
        const err = {
            rangeError: {
                given: c.value,
                expected: value,
            },
        };

        return c.value !== value ? err : null;
    };
}

let uniqueDadataIdCounter = 0;

@Component({
    selector: 'app-ngx-dadata-city',
    templateUrl: './purchase-basket-autocomplete-city.component.html',
    styleUrls: ['./purchase-basket-autocomplete-city.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NgxDadataCityComponent),
            multi: true,
        },
    ],
})
export class NgxDadataCityComponent implements OnInit, ControlValueAccessor {
    public config = input<DadataConfig>(DadataConfigDefault);
    public apiKey = input<string>();
    public disabled = input(null);
    public type = input(DadataType.address);
    public limit = input(DadataConfigDefault.limit);
    public placeholder = input('');
    public locations = input(null);
    public styleDefault = input(true);
    public inputId = input<string>();
    public withItems = input(true);

    @Output() geolocation: EventEmitter<void> = new EventEmitter<void>();
    @Output() cityClear: EventEmitter<void> = new EventEmitter<void>();

    @Output() selectedSuggestion: DadataSuggestion;
    @Output() selected: EventEmitter<DadataSuggestion> = new EventEmitter<DadataSuggestion>();
    @Output() selectedData = new EventEmitter<any>();

    @ViewChild('inputValue', { static: true }) inputValue: ElementRef;

    private inputString$ = new Subject<string>();

    protected v: any = '';
    protected currentFocus = -1;
    protected opened = false;
    protected onTouched = () => {};
    protected propagateChange: any = () => {};
    protected validateFn: any = () => {};

    /** Unique ID to be used by autocomplete trigger's "aria-owns" property. */
    protected id = `ngx-dadata-${uniqueDadataIdCounter++}`;

    public data = signal<DadataSuggestion[]>([]);
    public disable = signal(this.disabled());
    public daDataType = signal(this.type());
    public loc = signal(this.locations());

    get value(): any {
        return this.v;
    }

    set value(v: any) {
        if (v !== this.v) {
            this.v = v;
            this.propagateChange(v);
        }
    }

    @HostListener('document:click', ['$event'])
    onOutsideClick($event: MouseEvent) {
        if (!this.opened) {
            return;
        }
        if (!this.elRef.nativeElement.contains($event.target)) {
            this.data.set([]);
            this.opened = false;
        }
    }

    constructor(private dataService: NgxDadataService, private r: Renderer2, private elRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

    ngOnInit() {
        this.daDataType.set(this.config().type);
        this.loc.set(this.config().locations);
        this.dataService.setApiKey(this.apiKey() ? this.apiKey() : this.config().apiKey);
        this.inputString$.pipe(debounce(() => timer(this.config().delay ? this.config().delay : 500))).subscribe((x) => {
            this.dataService.getData(x, this.daDataType(), this.config()).subscribe((y: DadataResponse) => {
                this.data.set(y.suggestions);
                if (this.data.length) {
                    this.opened = true;
                    this.selectedData.emit(this.data());
                }
            });
        });
    }

    onGeolocation(){
        this.geolocation.emit();
    }

    onCityClear(){
        this.cityClear.emit();
    }

    getData(value: string) {
        this.inputString$.next(value);
        this.currentFocus = -1;
    }

    onClick(item: DadataSuggestion) { 
        this.inputValue.nativeElement.value = item.value;
        this.propagateChange(item.value);
        this.inputValue.nativeElement.focus();
        this.selectedSuggestion = item;
        this.data.set([]);
        this.currentFocus = -1;
        this.opened = false;
        this.selected.emit(item);
    }

    onArrowDown() {
        this.removeFocus(this.currentFocus);
        if (this.currentFocus >= this.data().length - 1) {
            this.currentFocus = 0;
        } else {
            this.currentFocus++;
        }
        this.setFocus(this.currentFocus);
    }

    onArrowUp() {
        this.removeFocus(this.currentFocus);
        if (this.currentFocus === 0) {
            this.currentFocus = this.data().length - 1;
        } else {
            this.currentFocus--;
        }
        this.setFocus(this.currentFocus);
    }

    onEnter(event: KeyboardEvent) {
        this.selectedSuggestion = this.data()[this.currentFocus];
        this.inputValue.nativeElement.value = this.selectedSuggestion.value;
        this.data.set([]);
        this.currentFocus = -1;
        this.propagateChange(this.selectedSuggestion.value);
        this.selected.emit(this.selectedSuggestion);
    }

    setFocus(id: number) {
        const activeEl = this.document.getElementById(id + 'item');
        this.r.addClass(activeEl, 'active');
    }

    removeFocus(id: number) {
        if (id !== -1) {
            const activeEl = this.document.getElementById(id + 'item');
            this.r.removeClass(activeEl, 'active');
        }
    }

    writeValue(value: any): void {
        if (value !== undefined && value !== null) {
            this.v = value;
        } else {
            this.v = '';
        }

        this.r.setProperty(this.inputValue.nativeElement, 'innerHTML', unwrapHtmlForSink(createHtml(this.v)));
    }

    registerOnChange(fn: any): void {
        // this.onSuggestionSelected = fn;
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disable.set(isDisabled);
    }
}
