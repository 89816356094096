import { Observable } from 'rxjs';
import { IsArray, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentShared } from '../models/shared/attachment.shared';
import { AttachmentDto } from '../models/entities/attachment.dto';

export enum AttachmentEndpointPaths {
    getAttachmentsByIds = '/api/v1/attachment/getAttachmentsByIds',
    uploadAttachment = '/api/v1/attachment/upload',
    deleteAttachment = '/api/v1/attachment/delete',
    uploadImageCKEditor = '/api/v1/attachment/uploadImageCKEditor',
    updateAttachment = '/api/v1/attachment/update',
}

export interface AttachmentEndpoint {
    getAttachmentsByIds(request: GetAttachmentsByIdsRequest): Promise<GetAttachmentsByIdsResponse> | Observable<GetAttachmentsByIdsResponse>;
    uploadAttachment(file: File | any, request: UploadAttachmentRequest): Promise<AttachmentDto> | Observable<AttachmentDto>;
    deleteAttachment(request: DeleteAttachmentRequest): Promise<void> | Observable<void>;
    uploadImageCKEditor(file: File | any): Promise<UploadImageCKEditorResponse> | Observable<UploadImageCKEditorResponse>;
    updateAttachment(request: UpdateAttachmentRequest): Promise<AttachmentDto> | Observable<AttachmentDto>;
}

export class GetAttachmentsByIdsRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    ids: Array<number>;
}

export type GetAttachmentsByIdsResponse = Array<AttachmentDto>;

export class UploadAttachmentRequest {
    @ApiModelProperty()
    @IsNotEmpty()
    requestPair: AttachmentShared.Strategies.PairRequest;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    sortOrder?: number;
}

export class DeleteAttachmentRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type UploadImageCKEditorResponse = UploadImageCKEditorSuccessResponse | UploadImageCKEditorErrorResponse;

export class UploadImageCKEditorSuccessResponse {
    urls: { [size: string]: string };
}

export class UploadImageCKEditorErrorResponse {
    error: {
        message: string;
    };
}

export class UpdateAttachmentRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    description?: string;

    @ApiModelProperty()
    @IsOptional()
    jsonMetadata?: any;

    @ApiModelProperty()
    @IsOptional()
    type?: any;

    @ApiModelProperty()
    @IsOptional()
    language?: any;
}
