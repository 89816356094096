import { Injectable } from '@angular/core';
import { ProductESDto } from '@interid/interid-site-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { InteridWebProductQuestionsDataAccess } from '@interid/interid-site-data-access/web';
import { ApiErrorHandlerService, MessagesService } from '@interid/interid-site-web/core';
import { ProductCardSource } from '../product-card.source';

@Injectable()
export class ProductCardQuestionHelper {
    constructor(
        private readonly source: ProductCardSource,
        private readonly productQuestionEndpoint: InteridWebProductQuestionsDataAccess,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly messagesService: MessagesService,
    ) {
    }

    get product(): ProductESDto {
        return this.source.product;
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    sendProductQuestion(request: {
        contents: string;
        author?: string;
        email?: string;
        isAnonymous: boolean;
        contentAttachmentIds?: Array<number>;
    }): Observable<void> {
        return this.productQuestionEndpoint.question(
            request.isAnonymous
                ? {
                    productId: this.product.id,
                    contents: request.contents,
                    isAnonymous: true,
                    contentAttachmentIds: request.contentAttachmentIds || [],
                }
                : {
                    productId: this.product.id,
                    author: request.author,
                    email: request.email
                        ? request.email
                        : undefined,
                    contents: request.contents,
                    isAnonymous: false,
                    contentAttachmentIds: request.contentAttachmentIds || [],
                },
        ).pipe(
            catchError((err) => {
                this.errorHandler.handle(err);

                return throwError(err);
            }),
            map(() => undefined),
            tap(() => this.messagesService.info({
                message: this.t('sendQuestionSuccess'),
                translate: true,
            })),
        );
    }
}
