import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';
import { ProductReviewShared } from '../shared/product-review.shared';

export class ProductReviewPayload {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  categoryId: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  preview: AttachmentDto;
}

export class ProductReviewDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  product: ProductReviewPayload;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  processedAt?: string;

  @ApiModelProperty()
  responsedAt?: string;

  @ApiModelProperty()
  status: ProductReviewShared.ReviewStatus;

  @ApiModelProperty()
  rating: number;

  @ApiModelProperty({
    required: false,
  })
  author?: string;

  @ApiModelProperty()
  authorAccountId?: number;

  @ApiModelProperty({
    required: false,
  })
  email?: string;

  @ApiModelProperty()
  isAnonymous?: boolean;

  @ApiModelProperty({
    required: false,
  })
  positive: string;

  @ApiModelProperty({
    required: false,
  })
  negative: string;

  @ApiModelProperty()
  contents: string;

  @ApiModelProperty()
  contentsAttachments: Array<AttachmentDto>;

  @ApiModelProperty()
  response: string;

  @ApiModelProperty()
  responseAttachments: Array<AttachmentDto>;
}
