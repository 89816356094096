import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BonusShared } from '../shared/bonus.shared';

export class BonusDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  accountId: number;

  @ApiModelProperty()
  amount: number;

  @ApiModelProperty()
  source: BonusShared.BonusSource;
}
