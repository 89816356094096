<div class='c'>
  <div class='c-left'>
    <div class='c-rating-stars'>
      <app-shared-stars-rating [current]='helper.product.rating'></app-shared-stars-rating>
    </div>
    @if (withReviews) {
      <div class='c-rating-desc'>@if (helper.product.ratingCount !== 0) {
        <span>{{ helper.product.ratingCount }}</span>
        }@if (helper.product.ratingCount === 0) {
        <span>Нет отзывов</span>
      }
    </div>
  }
</div>
</div>
