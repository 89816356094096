import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductESDto } from '@interid/interid-site-shared';
import { AppSessionService, LocalStorageAppSessionStrategy } from '@interid/interid-site-web/core-session';

@Injectable()
export class ProductCardSource {
    private _product$: BehaviorSubject<ProductESDto> = new BehaviorSubject<ProductESDto>(undefined);

    constructor(
        private readonly appSession: AppSessionService,
        private readonly localStorageStrategy: LocalStorageAppSessionStrategy,
    ) {
    }

    withProduct(product: ProductESDto): void {
        if (! product) {
            return;
        }

        if (this.appSession.isLocalStorageStrategy) {
            const inBasketEntry = this.localStorageStrategy.state.basket.entries.find((e) => e.product.id === product.id);

            if (inBasketEntry) {
                product.inBasket = inBasketEntry.amount;
            }

            product.inFavorites = !! this.localStorageStrategy.state.favorites.find((favorite) => favorite.id === product.id);
            product.inCompares = !! this.localStorageStrategy.state.compares.find((x) => x === product.id);
        }

        this._product$.next(product);
    }

    patchProduct(product: Partial<ProductESDto>): void {
        this._product$.next({
            ...this.product,
            ...product,
        });
    }

    get product(): ProductESDto {
        return this._product$.getValue();
    }

    get product$(): Observable<ProductESDto> {
        return this._product$.asObservable();
    }
}
