import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountDto } from './account.dto';
import { ProductRequestShared } from '../shared/product-request.shared';

export class ProductRequestDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  createdAt: string;

  @ApiModelProperty()
  createdFromURL: string;

  @ApiModelProperty()
  assignedTo: AccountDto;

  @ApiModelProperty()
  phone: string;

  @ApiModelProperty()
  name?: string;

  @ApiModelProperty()
  email?: string;

  @ApiModelProperty()
  message?: string;

  @ApiModelProperty()
  status: ProductRequestShared.Status;

  @ApiModelProperty()
  statusHistory: Array<ProductRequestShared.StatusHistoryEntry>;
}
