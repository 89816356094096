import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsOptional, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';
import { ProductESDto } from '../models/entities/product.dto';
import { ProductReviewDto } from '../models/entities/product-review.dto';

export enum MyReviewsWebEndpointPaths {
    list = '/api/v1/web/my-reviews/list',
}

export interface MyReviewsWebEndpoint {
    list(request: MyReviewsListWebRequest, req?: any): Promise<MyReviewsListWebResponse> | Observable<MyReviewsListWebResponse>;
}

export class MyReviewsListWebRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    page?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    pageSize?: number;
}

export class MyReviewsListWebResponse {
    @ApiModelProperty({
        type: ProductReviewDto,
        isArray: true,
    })
    reviews: Array<ProductReviewDto>;

    @ApiModelProperty({
        type: ProductESDto,
        isArray: true,
    })
    products: Array<ProductESDto>;

    @ApiModelProperty()
    page: number;

    @ApiModelProperty()
    pageSize: number;

    @ApiModelProperty()
    total: number;
}
