import { Observable } from 'rxjs';
import { RobotsTxtDto } from '../dto/robots-txt.dto';

export enum RobotsTxtEndpointPaths {
    get = '/api/v1/robots-txt/get',
    set = '/api/v1/robots-txt/set',
}

export interface RobotsTxtEndpoint {
    get(): Promise<RobotsTxtDto> | Observable<RobotsTxtDto>;
    set(robots: RobotsTxtDto): Promise<void> | Observable<void>;
}
