<div class='c'>
  <app-shared-dialog-close (close)='close()'></app-shared-dialog-close>
  <div class='c-title'><span>{{ state().title }}</span></div>
  <div class='c-submit'>
    <button class='___disable-user-select' type='button' (click)='submit()' [disabled]='state().count === 0'>
      <span>{{ t('submit') | translate }}</span></button>
  </div>
  <div class='c-reset'>
    <button type='button' (click)='reset()'><span>{{ t('reset') | translate }}</span></button>
  </div>
</div>
