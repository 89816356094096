/* eslint-disable @typescript-eslint/ban-types */
import { InjectionToken } from '@angular/core';

/**
 * DI provider injection token for 'WsSliderComponent', which is the parent component
 * for 'WsBaseSliderDirective' and 'WsRangeSliderDirective'.
 * Used to prevent circular imports.
 */
export const WS_SLIDER_COMPONENT = new InjectionToken<{}>('WsSlider');

/**
 * DI provider injection token for 'WsBaseSliderDirective'.
 * Used to prevent circular imports.
 */
export const WS_BASE_SLIDER_DIRECTIVE = new InjectionToken<{}>('WsBaseSliderDirective');

/**
 * DI provider injection token for 'WsRangeSliderDirective'..
 * Used to prevent circular imports.
 */
export const WS_RANGE_SLIDER_DIRECTIVE = new InjectionToken<{}>('WsRangeSliderDirective');

/**
 * DI provider injection token for 'WsSliderThumbComponent'.
 * Used to prevent circular imports.
 */
export const WS_SLIDER_THUMB_COMPONENT = new InjectionToken<{}>('SliderThumbComponent');