<app-shared-dialog-close (click)="close()"></app-shared-dialog-close>
@if (modalRequest.productAnalogs && modalRequest.productAnalogs.length !== 0) {
  <div class="c">
    <div class="c-title"><span>{{ t('title') | translate }}</span></div>
    <div class='c-block'>
      <div class='c-products keen-slider' #productsSliderRef>
        @for (product of modalRequest.productAnalogs; track trackByProductId($index, product)) {
          <div class='c-product keen-slider__slide'
            >
            <app-shared-product-card-g [withParametersBadges]="true" [withParams]="true" [variant]='productCardVariant$ | async' [product]='product'
              [withBadges]='true' [withBasket]='true' [withReviews]="true" [withRating]='withRating$ | async'
            [witsAvailableOnDepot]='withAvailableOnDepot$ | async' (redirected)="productClicked()"></app-shared-product-card-g>
          </div>
        }
      </div>
      @if (modalRequest.showButtons) {
        <div class="arrow arrow--left" (click)="productsSlider.prev()"><button matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
          [matRippleRadius]="20" class="color-blue-gray-light" ><span><svg width="18"
          height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17 8.5a1 1 0 1 0 0-2v2ZM.293 6.793a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 7.5l5.657-5.657A1 1 0 0 0 6.657.43L.293 6.793ZM17 6.5H1v2h16v-2Z"
            fill="#253238" />
        </svg></span></button></div>
        <div class="arrow arrow--right" (click)="productsSlider.next()"><button matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
          [matRippleRadius]="20" class="color-blue-gray-light"><span><svg width="18"
          height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 6.5a1 1 0 0 0 0 2v-2Zm16.707 1.707a1 1 0 0 0 0-1.414L11.343.429A1 1 0 1 0 9.93 1.843L15.586 7.5l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM1 8.5h16v-2H1v2Z"
            fill="#253238" />
        </svg></span></button></div>
      }
    </div>
  </div>
}