import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryMarketplaceDto } from '../models/entities/delivery-marketplace.dto';
import { DeliveryMarketplaceShared } from '../models/shared/delivery-marketplace.shared';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '../core/crud';
import { Observable } from 'rxjs';

export enum DeliveryMarketplaceEndpointPaths {
    list = '/api/v1/delivery-marketplaces/list',
    create = '/api/v1/delivery-marketplaces/create',
    edit = '/api/v1/delivery-marketplaces/edit',
    delete = '/api/v1/delivery-marketplaces/delete',
    setSortOrder = '/api/v1/delivery-marketplaces/setSortOrder',
}

export interface DeliveryMarketplaceEndpoint {
    createDeliveryMarketplace(request: CreateDeliveryMarketplaceRequest): Promise<DeliveryMarketplaceDto> | Observable<DeliveryMarketplaceDto>;
    editDeliveryMarketplace(request: EditDeliveryMarketplaceRequest): Promise<DeliveryMarketplaceDto> | Observable<DeliveryMarketplaceDto>;
    deleteDeliveryMarketplace(request: DeleteDeliveryMarketplaceRequest): Promise<void> | Observable<void>;
    listDeliveryMarketplaces(request: ListDeliveryMarketplacesRequest): Promise<ListDeliveryMarketplacesResponse> | Observable<ListDeliveryMarketplacesResponse>;
    setSortOrder(request: SetSortOrderDeliveryMarketplaceRequest): Promise<SetSortOrderDeliveryMarketplaceResponse> | Observable<SetSortOrderDeliveryMarketplaceResponse>;
}

export class DeliveryMarketplaceBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    cost: DeliveryMarketplaceShared.Cost;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    days: DeliveryMarketplaceShared.Days;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    orderBefore: DeliveryMarketplaceShared.OrderBefore;
}

export class CreateDeliveryMarketplaceRequest extends DeliveryMarketplaceBody {
}

export class EditDeliveryMarketplaceRequest extends DeliveryMarketplaceBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteDeliveryMarketplaceRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListDeliveryMarketplacesRequest extends CrudListRequestDto {}

export class ListDeliveryMarketplacesResponse extends CrudListResponseDto<DeliveryMarketplaceDto> {
    @ApiModelProperty({
        type: () => DeliveryMarketplaceDto,
        isArray: true,
    })
    items: Array<DeliveryMarketplaceDto>;
}

export class SetSortOrderDeliveryMarketplaceRequest extends CrudSetSortOrderRequestDto {}

export class SetSortOrderDeliveryMarketplaceResponse extends CrudSetSortOrderResponseDto {
    @ApiModelProperty({
      type: () => DeliveryMarketplaceDto,
    })
    entity: DeliveryMarketplaceDto;

    @ApiModelProperty({
        type: () => DeliveryMarketplaceDto,
        isArray: true,
    })
    affected: Array<DeliveryMarketplaceDto>;
}
