
<div class="c" [ngClass]="ngClass">
  <div class="c-selector" #element (click)="open()">
    <div class="c-label">@if (! hasValue) {
      <span class="placeholder">{{ placeholder }}</span>
      }@if (hasValue) {
      <span>{{ valueInsteadOfPlaceholder }}</span>
    }</div>
    @if (variant === 'default') {
      <div class="c-icon">
        <div class="icon">
          <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976.666a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#D0D9DE"/></svg>
        </div>
      </div>
    }
    @if (variant === 'sort') {
      <div class="c-icon">
        <div class="icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.004 5.41-2.29 2.3a1.004 1.004 0 0 1-1.42-1.42l4-4a1 1 0 0 1 .33-.21.94.94 0 0 1 .76 0 1 1 0 0 1 .33.21l4 4a1.004 1.004 0 0 1-1.42 1.42l-2.29-2.3V21a1 1 0 1 1-2 0V5.41Zm14 13.18 2.29-2.3a1.004 1.004 0 1 1 1.42 1.42l-4 4a1.001 1.001 0 0 1-.33.21.94.94 0 0 1-.76 0 1 1 0 0 1-.33-.21l-4-4a1.004 1.004 0 1 1 1.42-1.42l2.29 2.3V3a1 1 0 1 1 2 0v15.59Z" fill="#1269FF"/></svg>
        </div>
      </div>
    }
  </div>
</div>
