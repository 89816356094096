<div class="c">
  <div class="c-menu-bar">
    <div class="c-menu-bar-items">
      <a class="item" rel="nofollow" [routerLink]="['/']" routerLinkActive='is-active' [routerLinkActiveOptions]="{ exact: true }">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.5 9L12.5 2L21.5 9V20C21.5 20.5304 21.2893 21.0391 20.9142 21.4142C20.5391 21.7893 20.0304 22 19.5 22H5.5C4.96957 22 4.46086 21.7893 4.08579 21.4142C3.71071 21.0391 3.5 20.5304 3.5 20V9Z" stroke="#90A4AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M9.5 22V12H15.5V22" stroke="#90A4AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span translate>app.components.app_floatbar_mobile.home</span>
      </a>
      <div class="item" (click)="openCatalogMenu($event)" [ngClass]="{ 'is-active': (screen()) === 'catalog'|| state().isProductRoute }">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 19H8" stroke="#90A4AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2 12H6" stroke="#90A4AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2 5H16" stroke="#90A4AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M22.7467 20.7689L19.4102 17.2988C20.2681 16.279 20.7382 14.9959 20.7382 13.6601C20.7382 10.5392 18.199 8 15.0781 8C11.9571 8 9.41797 10.5392 9.41797 13.6601C9.41797 16.781 11.9571 19.3202 15.0781 19.3202C16.2497 19.3202 17.3662 18.9668 18.3208 18.296L21.6826 21.7924C21.8232 21.9383 22.0122 22.0188 22.2147 22.0188C22.4064 22.0188 22.5883 21.9457 22.7263 21.8128C23.0197 21.5306 23.029 21.0625 22.7467 20.7689ZM15.0781 9.47655C17.3849 9.47655 19.2616 11.3532 19.2616 13.6601C19.2616 15.9669 17.3849 17.8436 15.0781 17.8436C12.7712 17.8436 10.8945 15.9669 10.8945 13.6601C10.8945 11.3532 12.7712 9.47655 15.0781 9.47655Z" fill="#90A4AF" />
        </svg>
        <span translate>app.components.app_floatbar_mobile.catalog</span>
      </div>
      <a class="item" rel="nofollow" [routerLink]="['/purchase/basket']" routerLinkActive='is-active' [ngClass]="{ 'is-active': state().isEmptyBasket }">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 16H7a1 1 0 0 1 0-2h10.44a3 3 0 0 0 2.91-2.27L22 5.24a1 1 0 0 0-.18-.86A1 1 0 0 0 21 4H6.76a3 3 0 0 0-2.82-2H3a1 1 0 0 0 0 2h.94a1 1 0 0 1 1 .76l.06.48L6.73 12A3.003 3.003 0 1 0 7 18h.18a3 3 0 1 0 5.64 0h2.36a3 3 0 1 0 5.64 0H21a1 1 0 0 0 0-2ZM19.72 6l-1.31 5.24a1 1 0 0 1-1 .76H8.78l-1.5-6h12.44ZM10 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm8 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" fill="#90A4AF" /></svg>
        <span translate>app.components.app_floatbar_mobile.cart</span>
        @if ((basketCount()) && (basketCount()) > 0 && isBrowser()) {
          <div class="counter"><div class="numbers"><span>{{ basketCount() }}</span></div></div>
        }
      </a>

      @if (isSignedId()) {
        <a class="item" [routerLink]="['/account']" rel="nofollow" routerLinkActive='is-active'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.71 12.71a6 6 0 1 0-7.42 0 10 10 0 0 0-6.22 8.18 1.006 1.006 0 1 0 2 .22 8 8 0 0 1 15.9 0 1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1 10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" fill="#90A4AF" />
          </svg>
          <span translate>app.components.app_floatbar_mobile.profile</span>
        </a>
      }

      @if (!isSignedId()) {
        <div class="item" (click)="signIn()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.71 12.71a6 6 0 1 0-7.42 0 10 10 0 0 0-6.22 8.18 1.006 1.006 0 1 0 2 .22 8 8 0 0 1 15.9 0 1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1 10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" fill="#90A4AF" />
          </svg>
          <span translate>app.components.app_floatbar_mobile.profile</span>
        </div>
      }

      <div class="item" (click)="toggleMainMenu()" [ngClass]="{ 'is-active': (screen()) === 'main' }">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 18h18m-18-6h18m-18-6h18" stroke="#90A4AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>
        <span translate>app.components.app_floatbar_mobile.menu</span>
      </div>
    </div>
  </div>
</div>