import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryMarketplaceShared } from '../shared/delivery-marketplace.shared';

export class DeliveryMarketplaceDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  isEnabled: boolean;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  cost: DeliveryMarketplaceShared.Cost;

  @ApiModelProperty()
  days: DeliveryMarketplaceShared.Days;

  @ApiModelProperty()
  orderBefore: DeliveryMarketplaceShared.OrderBefore;
}
