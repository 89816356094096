import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-shared-fluid-container-mobile',
    templateUrl: './fluid-container-mobile.component.html',
    styleUrls: ['./fluid-container-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidContainerMobileComponent {
}
