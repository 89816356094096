import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from './attachment.dto';
import { PageMetadataSEO } from '../shared/common.shared';
import { SortOrderShared } from '../shared/sort-order.shared';
import { CurrencyDto } from './currency.dto';

export class ManufacturerDto implements SortOrderShared.SortableEntity {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  sortOrder: number;

  @ApiModelProperty()
  image?: AttachmentDto;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  description: string;

  @ApiModelProperty()
  isDealer: boolean;

  @ApiModelProperty()
  dealerDescription: string;

  @ApiModelProperty()
  basePriceCurrencyValue?: number;

  @ApiModelProperty()
  manufacturerCountryParamDictValueId?: number;

  @ApiModelProperty()
  brandCountryParamDictValueId?: number;

  @ApiModelProperty()
  basePriceCurrencyId?: number;

  @ApiModelProperty({
    type: () => CurrencyDto,
  })
  basePriceCurrency?: CurrencyDto;

  @ApiModelProperty()
  dealerImage?: AttachmentDto;

  @ApiModelProperty({
    type: () => PageMetadataSEO,
  })
  seo: PageMetadataSEO;
}

export class ManufacturerListDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty()
  title: string;

  @ApiModelProperty()
  uri: string;

  @ApiModelProperty()
  image?: AttachmentDto;
}
