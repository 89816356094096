export enum AccountErrorCodes {
  AccountDuplicate = 'AccountDuplicate',
  AccountNotFound = 'AccountNotFound',
  InvalidCredentials = 'InvalidCredentials',
  AccountEmailAlreadyConfirmed = 'AccountEmailAlreadyConfirmed',
  TokenNotFound = 'TokenNotFound',
}

export const accountErrorCodesI18n = {
    [AccountErrorCodes.AccountDuplicate]: 'Пользователь с таким E-mail уже существует',
    [AccountErrorCodes.AccountNotFound]: 'Пользователь не найден',
    [AccountErrorCodes.InvalidCredentials]: 'Пароль не подходит',
    [AccountErrorCodes.AccountEmailAlreadyConfirmed]: 'Ваш аккаунт уже подтвержден',
    [AccountErrorCodes.TokenNotFound]: 'Ссылка устарела',
};
