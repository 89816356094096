import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlockCostTable } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-cost-table',
    templateUrl: './content-block-cost-table.component.html',
    styleUrls: ['./content-block-cost-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockCostTableComponent {
    @Input() payload: ContentBlockCostTable;
}
