import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiErrorHandlerService, AppBootstrapDataService, EnvironmentWebService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { ProductDto, ViewBreakpointsShared } from '@interid/interid-site-shared';
import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from 'keen-slider';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ProductCardVariant } from '../../helpers/product-card.helper';

interface ModalRequest {
    productAnalogs: Array<ProductDto>;
    showButtons: boolean;
}

interface State {
    layout: ViewBreakpointsShared.Layout;
}

export { ModalRequest as ProductAnalogsModalRequest };

@Component({
    templateUrl: './product-analogs-modal.component.html',
    styleUrls: ['./product-analogs-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAnalogsModalComponent implements OnDestroy, AfterViewInit {
    private ngOnDestroy$: Subject<void> = new Subject<void>();
    @ViewChild('productsSliderRef') productsSliderRef: ElementRef<HTMLElement>;
    productsSlider: KeenSliderInstance = null;
    public state: State = {
        layout: ViewBreakpointsShared.Layout.Wide,
    };

    sliderOptions: KeenSliderOptions = {
        mode: 'free-snap',
        slides: {
            perView: 4,
            spacing: 0,
        },
        dragSpeed: 0.5,
        /*  created: (slider) => {
            slider.container.addEventListener(
                'mousedown',
                (e) => {
                    e.stopPropagation();
                },
                { capture: true }
            );
        }, */
    };

    LIMIT_MOBILE = this.modalRequest.productAnalogs.length < 1 ? this.modalRequest.productAnalogs.length : 1;
    LIMIT_TABLET = this.modalRequest.productAnalogs.length < 2 ? this.modalRequest.productAnalogs.length : 2;
    LIMIT_DESKTOP = this.modalRequest.productAnalogs.length < 2 ? this.modalRequest.productAnalogs.length : 2;
    LIMIT_WIDE = this.modalRequest.productAnalogs.length < 4 ? this.modalRequest.productAnalogs.length : 4;

    constructor(private readonly vbr: ViewBreakpointsService, @Inject(PLATFORM_ID) private readonly platformId: Object, @Inject(MAT_DIALOG_DATA) public readonly modalRequest: ModalRequest, private readonly fb: UntypedFormBuilder, private readonly cdr: ChangeDetectorRef, private readonly env: EnvironmentWebService, private readonly matDialogRef: MatDialogRef<ProductAnalogsModalComponent>, private readonly errorHandler: ApiErrorHandlerService, private readonly router: Router, private readonly appBootstrap: AppBootstrapDataService) {}

    ngAfterViewInit() {
        this.productsSlider = new KeenSlider(this.productsSliderRef.nativeElement, this.sliderOptions);

        this.vbr.currentLayout$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((next) => {
            this.state.layout = next;

            switch (next) {
                default: {
                    this.sliderOptions.slides = {
                        perView: this.LIMIT_MOBILE,
                        spacing: 0,
                    };
                    this.productsSlider.update(this.sliderOptions);
                    break;
                }

                case ViewBreakpointsShared.Layout.Desktop: {
                    this.sliderOptions.slides = {
                        perView: this.LIMIT_DESKTOP,
                        spacing: 0,
                    };
                    this.productsSlider.update(this.sliderOptions);
                    break;
                }

                case ViewBreakpointsShared.Layout.Tablet: {
                    this.sliderOptions.slides = {
                        perView: this.LIMIT_TABLET,
                        spacing: 0,
                    };
                    this.productsSlider.update(this.sliderOptions);
                    break;
                }

                case ViewBreakpointsShared.Layout.Wide: {
                    this.sliderOptions.slides = {
                        perView: this.LIMIT_WIDE,
                        spacing: 0,
                    };
                    this.productsSlider.update(this.sliderOptions);
                    break;
                }
            }
        });
    }

    productClicked() {
        this.close();
    }

    get withRating$(): Observable<boolean> {
        return this.vbr.currentLayout$.pipe(
            map((next) => [ViewBreakpointsShared.Layout.Wide, ViewBreakpointsShared.Layout.Mobile].includes(next)),
            distinctUntilChanged()
        );
    }

    get withAvailableOnDepot$(): Observable<boolean> {
        return this.vbr.currentLayout$.pipe(
            map((next) => ![ViewBreakpointsShared.Layout.Wide].includes(next)),
            distinctUntilChanged()
        );
    }

    get productCardVariant$(): Observable<ProductCardVariant> {
        return this.vbr.currentLayout$.pipe(
            map((next) => [ViewBreakpointsShared.Layout.Mobile].includes(next)),
            map((isMobile) => (isMobile ? ('tiny' as ProductCardVariant) : ('default' as ProductCardVariant))),
            distinctUntilChanged()
        );
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `shared.components.product_analogs_modal.${input}`;
    }

    close(): void {
        this.matDialogRef.close();
    }

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    trackByProductId(index: number, product: ProductDto): any {
        return product.id;
    }
}
